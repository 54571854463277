import React from 'react';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';

export interface IMessageRequire {
  message?: string;
  classWrapper?: string;
  isWarning?: boolean;
}
export default function MessageError({
  message,
  classWrapper = 'ms-2 mt-1',
  isWarning,
}: IMessageRequire): JSX.Element {
  if (message) {
    return (
      <div className={classWrapper}>
        <small className="text-cherry d-flex align-items-center">
          <ExclamationTriangleFill className={`${isWarning && 'text-dark'}`} />
          <span className={`d-block px-1 ${isWarning && 'text-dark'}`}>{message}</span>
        </small>
      </div>
    );
  }
  return <></>;
}
