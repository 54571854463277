import React, { useMemo } from 'react';

import { TableGroup, TableColumns } from 'Components/Common/Table/TableGroup';
import dayjs from 'dayjs';

import { DATE_FORMAT_11, DATE_FORMAT_12 } from 'Constant/Date';
import 'dayjs/locale/ja';
import StastiticRow from 'Components/Page/Campaign/StatisticCampaign/StastiticRow';
import { RealTimeActual } from 'Types/Page/Campaign/Campaign';
import { CourseCampaign } from 'Types/Page/Campaign/RegisterCampaign';
import { subTypeOptions } from 'Constant/Campaign';

interface TableProps {
  dataSource: RealTimeActual[];
  course: CourseCampaign;
}

export default function RealtimeActualTable({ dataSource, course }: TableProps): JSX.Element {
  const dayOfMonth = useMemo(() => {
    const dateList = dataSource.map((item) => item.date);
    return dateList.map((day) => ({
      text: dayjs(day).format(DATE_FORMAT_12),
      colSpan: 1,
    }));
  }, [dataSource]);

  const getColClass = (day: string) => {
    let classCol = 'bg-smoke';
    if (day === '土') {
      classCol = 'satColor';
    }
    if (day === '日') {
      classCol = 'sunColor';
    }
    return classCol;
  };
  const dayOfWeek = useMemo(() => {
    const dateList = dataSource.map((item) => item.date);
    return dateList.map((day) => ({
      text: dayjs(day).locale('ja').format(DATE_FORMAT_11),
      dataIndex: day?.toString(),
      className: getColClass(dayjs(day).locale('ja').format(DATE_FORMAT_11)),
    }));
  }, [dataSource]);
  const columns = useMemo(
    () =>
      [
        {
          columns: [
            { rowSpan: 2, text: ' 実績値', width: 100, className: 'standard-name' },
            ...dayOfMonth,
          ],
        },
        {
          columns: [
            {
              text: '',
              dataIndex: 'index',
              className: 'standard-name d-none',
            },
            ...dayOfWeek,
          ],
        },
      ] as TableColumns[],
    [dayOfMonth, dayOfWeek],
  );

  return (
    <>
      <TableGroup tableColumns={columns} dataSource={dataSource} tableClass="standardTable">
        <StastiticRow
          title="当選確率"
          dataTable={dataSource}
          dataIndex="winningRate"
          render={({ row }: { row: RealTimeActual }) => {
            return <div>{!row.winningRate ? '' : `${Number(row.winningRate).toFixed(2)}%`}</div>;
          }}
        />

        {course.subType === subTypeOptions[2].value && (
          <StastiticRow
            title="平均当選確率"
            dataTable={dataSource}
            dataIndex="averageWinnerRate"
            render={({ row }: { row: RealTimeActual }) => (
              <div>
                {!row.averageWinnerRate ? '' : `${Number(row.averageWinnerRate).toFixed(2)}%`}
              </div>
            )}
          />
        )}

        <StastiticRow title="応募実数" dataTable={dataSource} dataIndex="applyNumber" />

        <StastiticRow
          title="前日増減"
          dataTable={dataSource}
          dataIndex="yesterdayChange"
          render={({ row }) => (
            <div className={`${row.yesterdayChange && row.yesterdayChange < 0 && 'txt-require'}`}>
              {row.yesterdayChange && Math.round(row.yesterdayChange)}
            </div>
          )}
        />

        <StastiticRow
          title="応募累積数"
          dataTable={dataSource}
          dataIndex="applyAccumulationNumber"
        />

        <StastiticRow title="当選枠残数" dataTable={dataSource} dataIndex="remainWinningNumber" />

        <StastiticRow title="当選数" dataTable={dataSource} dataIndex="winningNumber" />

        <StastiticRow
          title="当選数累積"
          dataTable={dataSource}
          dataIndex="winningAccumulationNumber"
        />

        <StastiticRow title="景品残数" dataTable={dataSource} dataIndex="remainPrizeNumber" />
      </TableGroup>
    </>
  );
}
