/* eslint-disable react-hooks/exhaustive-deps */
import Filter from 'Components/Common/Filter/Filter';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import Tabs from 'Components/Common/Tabs/TabRevenue';
import { PAGE_SIZE } from 'Constant';
import { RecordCompanyApi } from 'Datasource/RecordCompany';
import { CostResponse, PurchaseResponse } from 'Types/Page/CompanyInformation/CompanyInformation';
import dayjs from 'dayjs';
import useMessage from 'Hooks/useMessage';
import CostManagement from 'Page/InformationAccountant/CostManagement';
import SaleManagement from 'Page/InformationAccountant/SaleManagement';
import SearchRevenue from 'Page/InformationAccountant/SearchRevenue';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';
import { exportExcel } from 'Utils/File';
import useTitlePage from 'Hooks/useTitlePage';

import {
  currentStartMonth,
  currentEnDMonth,
  handleStartDate,
  handleEndMonth,
  handleStartMonth,
  formatDateTimeUTCToLocalStartTime,
  formatDateTimeUTCToLocalEndTime,
  handleEndDate,
} from 'Utils/DateTime';
import { omit } from 'lodash';
import { AliSortType } from 'Components/Common/Table/AliTable';
import { DATE_FORMAT_EXPORT_EXCEL } from 'Constant/Date';
import useErrorBlob from 'Hooks/useErrorBlob';

export default function RevenueManagement(): JSX.Element {
  const [dataPurchase, setDataPurchase] = useState<PurchaseResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [dataCost, setDataCost] = useState<CostResponse>();
  const [exporting, setExporting] = useState<boolean>(false);

  const { openMessageError } = useMessage();
  const { openErrorBlob } = useErrorBlob();
  const { setMenuTitle } = useTitlePage();

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    queryArtist: StringParam,
    queryTitle: StringParam,
    artistId: StringParam,
    artistIds: StringParam,
    titleId: StringParam,
    titleIds: StringParam,
    status: withDefault(StringParam, '売上管理/月別', true),
    startDate: withDefault(StringParam, formatDateTimeUTCToLocalStartTime(currentStartMonth), true),
    endDate: withDefault(StringParam, formatDateTimeUTCToLocalEndTime(currentEnDMonth), true),
    variationId: StringParam,
    variationIds: StringParam,
    isOption: withDefault(NumberParam, 0, true),
    queryVariation: StringParam,
    companyIds: StringParam,
    sort: StringParam,
    order: StringParam,
    companyId: StringParam,
  });

  const btnGroupSaleOptions = useMemo(
    () => [
      { name: '月別', value: '売上管理/月別' },
      { name: '日別', value: '売上管理/日別' },
      { name: '詳細', value: '売上管理/詳細' },
      { name: '日計表', value: '売上管理/日計表' },
    ],
    [],
  );

  const btnGroupCostOptions = useMemo(
    () => [
      { name: '月別', value: '原価管理/月別' },
      { name: '日別', value: '原価管理/日別' },
      { name: '詳細', value: '原価管理/詳細' },
      { name: '日計表', value: '原価管理/日計表' },
    ],
    [],
  );

  const [tabActive, setTabActive] = useState(btnGroupSaleOptions[0].value);

  //* get previous tab active
  const prevTab = useRef<any>(null);
  useEffect(() => {
    prevTab.current = tabActive;
  }, [tabActive]);
  //* ******************** *//

  const isPurchase = useMemo(() => {
    const purchase = btnGroupSaleOptions.map((item) => item.value);
    return purchase.includes(query.status);
  }, [query.status]);

  useEffect(() => {
    if (isPurchase) {
      setMenuTitle('売上管理');
    } else {
      setMenuTitle('原価管理');
    }
  }, [isPurchase]);

  useEffect(() => {
    if (query.companyIds || query.companyId) {
      handleSearch();
    } else {
      setDataPurchase(undefined);
      setDataCost(undefined);
    }
  }, [
    query.page,
    query.artistId,
    query.artistIds,
    query.titleId,
    query.titleIds,
    query.startDate,
    query.endDate,
    query.variationId,
    query.variationIds,
    query.isOption,
    tabActive,
    query.companyIds,
    query.sort,
    query.order,
    query.companyId,
  ]);

  const handleChangeTabs = (tab: string) => {
    if (!tab) return;
    setTabActive(tab);
    const monthly = ['売上管理/月別', '原価管理/月別'];
    const daily = ['売上管理/日別', '原価管理/日別'];
    const detail = ['売上管理/詳細', '原価管理/詳細'];
    const dailyAccount = ['売上管理/日計表', '原価管理/日計表'];
    setQuery({ sort: '', order: '' });
    if (monthly.includes(tab)) {
      setQuery({
        isOption: 0,
        endDate: formatDateTimeUTCToLocalEndTime(query.startDate),
        startDate: formatDateTimeUTCToLocalStartTime(query.startDate),
      });
    }
    if (daily.includes(tab)) {
      setQuery({
        isOption: 1,
        endDate:
          !query.endDate ||
          prevTab?.current === btnGroupSaleOptions[0].value ||
          prevTab?.current === btnGroupCostOptions[0].value
            ? formatDateTimeUTCToLocalEndTime(query.startDate)
            : formatDateTimeUTCToLocalEndTime(query.endDate),
      });
    }
    if (detail.includes(tab)) {
      setQuery({
        isOption: 2,
        endDate:
          !query.endDate ||
          prevTab?.current === btnGroupSaleOptions[0].value ||
          prevTab?.current === btnGroupCostOptions[3].value
            ? formatDateTimeUTCToLocalEndTime(query.startDate)
            : formatDateTimeUTCToLocalEndTime(query.endDate),
      });
    }
    if (dailyAccount.includes(tab)) {
      setQuery({
        isOption: 3,
        endDate: formatDateTimeUTCToLocalEndTime(query.startDate),
        startDate: formatDateTimeUTCToLocalStartTime(query.startDate),
      });
    }
  };

  //* handle reset startDate when click menu sidebar
  useEffect(() => {
    setQuery({ startDate: query.startDate });
  }, [query.startDate]);

  useEffect(() => {
    if (query.status) {
      setTabActive(query.status);
    }
  }, [query.status]);

  //* handle sort from server side
  const handleManualSort = useCallback((sortBy: AliSortType[]) => {
    if (sortBy.length > 0) {
      const sortOption = sortBy[0];
      setQuery({ sort: sortOption.code, order: sortOption.order === 'desc' ? 'DESC' : 'ASC' });
    } else {
      setQuery({ sort: '', order: '' });
    }
  }, []);

  const renderTab = useMemo(() => {
    return isPurchase ? (
      <SaleManagement
        page={query.page}
        purchaseData={dataPurchase}
        option={query.isOption}
        onSort={handleManualSort}
      />
    ) : (
      <CostManagement
        page={query.page}
        costData={dataCost}
        option={query.isOption}
        onSort={handleManualSort}
      />
    );
  }, [dataPurchase, dataCost, query.page, tabActive, query.isOption, isPurchase]);

  const isFilterMonth =
    tabActive === btnGroupSaleOptions[0].value ||
    tabActive === btnGroupCostOptions[0].value ||
    tabActive === btnGroupSaleOptions[3].value ||
    tabActive === btnGroupCostOptions[3].value;

  const searchPurchase = async () => {
    try {
      setLoading(true);
      const params = omit(query, 'companyId');
      const res = await RecordCompanyApi.getAllReportPurchase({
        ...params,
        limit: PAGE_SIZE,
        page: query.page ?? 1,
        isOption: query.isOption ?? 0,
        reportCompanyId: query.companyId || '',
        endDate:
          query.isOption === 0 ? handleEndMonth(query.startDate) : handleEndDate(query.endDate),
        startDate:
          query.isOption === 0
            ? handleStartMonth(query.startDate)
            : handleStartDate(query.startDate),
        sort: query.sort || '',
        order: query.order || '',
        companyIds: query.companyIds || '',
      });
      const { data } = res.data;
      const dataMeta = {
        totalCount: data?.paginateData?.total,
        feePurchase: data.feePurchase,
      };
      setDataPurchase({
        items:
          query.isOption == 3
            ? Object.keys(data.items)?.map((d): any => ({ ...data.items?.[d], purchaseDate: d }))
            : data.items,
        meta: dataMeta,
        saleChannels: data.saleChannels,
        dataAmount: data.dataAmount,
        totalByMonth: data.totalByMonth,
      });
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const searchCost = async () => {
    try {
      setLoading(true);
      const params = omit(query, 'companyId');
      const res = await RecordCompanyApi.getAllReportCost({
        ...params,
        limit: PAGE_SIZE,
        page: query.page ?? 1,
        isOption: query.isOption ?? 0,
        reportCompanyId: query.companyId || '',
        endDate: query.isOption === 0 ? handleEndMonth(query.startDate) : query.endDate,
        startDate:
          query.isOption === 0
            ? handleStartMonth(query.startDate)
            : handleStartDate(query.startDate),
        sort: query.sort || '',
        order: query.order || '',
        companyIds: query.companyIds || '',
      });
      const { data } = res.data;
      const dataMeta = {
        totalCount: data?.paginateData?.total,
        feePurchase: data.feePurchase,
        snFee: data.snFee,
        snImportFee: data.snImportFee,
      };
      setDataCost({
        items: data.items || [],
        companyOptions: data.companyOptions,
        meta: dataMeta,
        saleChannels: data.saleChannels,
        amountTotal: data.amountTotal,
        amountTaxFeeMoneyTotal: data.amountTaxFeeMoneyTotal,
        miimCostTotal: [{ totalMoneyTax: data?.totalTax, totalMoney: data?.total }],
        amountOptionFeeTotal: data?.amountOptionFeeTotal,
        amountCardBuyFeeTotal: data?.amountCardBuyFeeTotal,
      });
    } catch (error) {
      query.companyIds && openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    if (isPurchase) {
      searchPurchase();
    } else {
      searchCost();
    }
  };

  const onExportExcel = () => {
    let optionsExcel = {
      artistIds: query.artistIds ?? undefined,
      endDate: handleEndDate(query.endDate),
      startDate: handleStartDate(query.startDate),
      titleIds: query.titleIds ?? undefined,
      variationIds: query.variationIds ?? undefined,
      isOption: query.isOption ?? 0,
      reportCompanyId: query.companyId ?? undefined,
      companyIds: query.companyIds || '',
    };
    if (!query.startDate) {
      optionsExcel = omit(optionsExcel, 'startDate') as any;
    }

    if (!query.endDate) {
      optionsExcel = omit(optionsExcel, 'endDate') as any;
    }
    try {
      setExporting(true);
      exportExcel(
        isPurchase
          ? RecordCompanyApi.exportExcelPurchase(optionsExcel)
          : RecordCompanyApi.exportExcelCost(optionsExcel),
        `${tabActive}_${dayjs().format(DATE_FORMAT_EXPORT_EXCEL).toString()}`,
      );
    } catch (error) {
      openErrorBlob(error);
    } finally {
      setExporting(false);
    }
  };

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <Container fluid className="pb-4">
        <Filter filterClass="shadow-sm">
          <Row className="align-items-end">
            <SearchRevenue
              isMonth={isFilterMonth}
              onExportExcel={onExportExcel}
              loadingExcel={exporting}
            />
            <Col className="mt-3 align-self-end mx-1">
              <div className="d-flex">
                <div className="d-flex flex-column me-4">
                  <span className="font-bold">売上管理</span>
                  <Tabs
                    groupClass="mt-1"
                    options={btnGroupSaleOptions}
                    tabActive={tabActive}
                    onChange={handleChangeTabs}
                    clickOnly
                  />
                </div>
                <div className="d-flex flex-column">
                  <span className="font-bold">原価管理</span>
                  <Tabs
                    groupClass="mt-1"
                    options={btnGroupCostOptions}
                    tabActive={tabActive}
                    onChange={handleChangeTabs}
                    clickOnly
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Filter>
        {renderTab}
      </Container>
    </>
  );
}
