import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { labelRequire } from 'Components/Common/Form/FormLabel';
import BRDateRangePicker, {
  BRDateRangePickerTypes,
} from 'Components/Common/NewDatePicker/DateRangePicker';
import { ColSpec } from 'Components/Common/Form/form';

interface FormGroupDateRangeProps extends BRDateRangePickerTypes {
  label?: string;
  subLabel?: string;
  classForm?: string;
  colClass?: string;
  labelMd: ColSpec;
  classLabel?: string;
  colMd: ColSpec;
  desc?: string;
}

export default function ECFormGroupDateRange(props: FormGroupDateRangeProps): JSX.Element {
  const {
    label,
    subLabel,
    classForm,
    colClass,
    labelMd,
    colMd,
    placeholderStart,
    placeholderEnd,
    onChangeStartDate,
    onChangeEndDate,
    startDate,
    classLabel,
    endDate,
    errorStartDate,
    errorEndDate,
    showTimeSelect = false,
    endDateDisabled = false,
    startDateDisabled = false,
    dateFormat,
    maxStartDate,
    minStartDate,
    minEndDate,
    maxEndDate,
    desc,
  } = props;

  return (
    <Form.Group as={Row} className={`mb-3 ${classForm}`}>
      <Form.Label column md={labelMd}>
        {labelRequire(label ?? '', classLabel)}
        {<p className="fs-12">{subLabel}</p>}
      </Form.Label>
      <Col md={colMd} className={colClass}>
        <BRDateRangePicker
          dateFormat={dateFormat}
          startDate={startDate}
          endDate={endDate}
          errorStartDate={errorStartDate}
          errorEndDate={errorEndDate}
          placeholderStart={placeholderStart}
          placeholderEnd={placeholderEnd}
          onChangeStartDate={onChangeStartDate}
          onChangeEndDate={onChangeEndDate}
          showTimeSelect={showTimeSelect}
          startDateDisabled={startDateDisabled}
          endDateDisabled={endDateDisabled}
          minStartDate={minStartDate}
          maxStartDate={maxStartDate}
          minEndDate={minEndDate}
          maxEndDate={maxEndDate}
        />
        {desc ? <div className="ec_dateRangePicker-desc">{desc}</div> : null}
      </Col>
    </Form.Group>
  );
}
