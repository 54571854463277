/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useRef } from 'react';
import { DropdownIcon } from 'Components/Common/Select/CustomSelect/DropdownIcon';
import { SearchIcon } from 'Components/Common/Select/CustomSelect/SearchIcon';
import { ClearIcon } from 'Components/Common/Select/CustomSelect/ClearIcon';
interface SearchInputProps {
  searchString?: string;
  setSearchString: ({ target }: { target: any }) => void;
  onBlur?: () => void;
  onEnter?: (e: React.KeyboardEvent<EventTarget>) => void;
  onFocus?: () => void;
  onClear?: () => void;
  placeholder?: string;
  showIcon?: boolean;
  showIconClear?: boolean;
}

const SearchInput = (props: SearchInputProps) => {
  const {
    searchString,
    setSearchString,
    onBlur,
    onEnter,
    onFocus,
    onClear,
    placeholder,
    showIcon = true,
    showIconClear = false,
  } = props;
  const ref = useRef<HTMLInputElement>(null);

  const handleOnFocus = () => {
    onFocus && onFocus();
    if (ref) {
      ref.current?.focus();
    }
  };

  return (
    <div className="search-input pe-5" onClick={handleOnFocus} aria-hidden>
      <SearchIcon showIcon={showIcon} />
      <input
        ref={ref}
        spellCheck={false}
        value={searchString}
        onChange={setSearchString}
        onBlur={onBlur}
        placeholder={placeholder}
        onKeyDown={onEnter}
      />
      <div onClick={onClear} onKeyDown={onClear} hidden={!showIconClear}>
        {searchString && <ClearIcon />}
      </div>
      <DropdownIcon />
    </div>
  );
};

export default SearchInput;
