import React from 'react';
import { Container } from 'react-bootstrap';
import SaleSchedule from 'Page/PromotionManager/SaleSchedule';
import Filter from 'Components/Common/Filter/Filter';
import FilterPromotion from 'Page/PromotionManager/FilterPromotion';

export default function PromotionManager(): JSX.Element {
  return (
    <>
      <Container fluid>
        <Filter filterClass="shadow-sm">
          <FilterPromotion />
        </Filter>
      </Container>
      <Container fluid>
        <SaleSchedule />
      </Container>
    </>
  );
}
