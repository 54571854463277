import React, { useState, useEffect } from 'react';
import CardContainer from 'Components/Common/CardContainer';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Container, Button, Form, Row, Col } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import ModalComponent from 'Components/Common/Modal';
import Filter from 'Components/Common/Filter/Filter';
import Tabs from 'Components/Common/Tabs/Tabs';
import PullDownCompany from 'Components/Common/PullDownCompany';
import FormLabel from 'Components/Common/Form/FormLabel';
import { emailValidation, katakanaFullSizeValidation, stringRequiredTrim } from 'Utils/Validation';
import { CuratorManagerApi, Data } from 'Datasource/CuratorManager';
import useMessage from 'Hooks/useMessage';
import { SelectValue } from 'Types/Common';
import { RoleNames } from 'Constant/Roles';
import { useAppSelector } from 'App/Store';
import { includes } from 'lodash';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { handleEnteringPhoneNumber } from 'Utils/Numbers';

interface IFormInputs {
  name: string;
  furiganaName: string;
  phone: string;
  email: string;
  companies: number[];
}

interface ISelectValue {
  id: number;
  value: string;
  label: string;
}

const schema = yup.object().shape({
  name: stringRequiredTrim({ required: '入力してください。' }),
  furiganaName: katakanaFullSizeValidation({ required: '入力してください。' }),
  email: emailValidation({ required: '入力してください。' }),
});

interface paramsURL {
  id?: string;
}

export default function CuratorRegister({
  goBackToList,
}: {
  goBackToList: () => void;
}): JSX.Element {
  const history = useHistory();
  const { id }: paramsURL = useParams();
  const btnGroupOptions = [{ name: '担当者リスト' }, { name: '担当者登録' }];
  const [tabActive, setTabActive] = useState(
    id ? btnGroupOptions[1].name : btnGroupOptions[0].name,
  );

  const [data, setData] = useState<Data>();

  const [currentSelectCompany, setCurrentSelectCompany] = useState<SelectValue | null>();
  const [inputSearchString, setInputSearchString] = useState<string>('');

  const [listCompanySelected, setListCompany] = useState<Array<ISelectValue>>([]);

  const [isManager, setIsManager] = useState<boolean>(true);

  const { role, email } = useAppSelector((state) => state.auth);

  const [isLoading, setIsloading] = useState<boolean>(false);

  useEffect(() => {
    handleFetchDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleFetchDetail = async () => {
    if (id) {
      try {
        setIsloading(true);
        const {
          data: { data },
        } = await CuratorManagerApi.getDetail(parseInt(id, 10));
        if (data) {
          setIsManager(includes(RoleNames[0], role) || email === data?.email);
          setData(data);
          if (data.recordCompanies.length) {
            const listCompany = data.recordCompanies.map((item: { name: string; id: string }) => ({
              label: item.name,
              value: item.id,
              id: item.id,
            }));
            setListCompany(listCompany);
            setValue(
              'companies',
              data.recordCompanies.map((item: { id: string }) => item.id),
            );
          }
          setValue('name', data.name);
          setValue('furiganaName', data.furiganaManagerName);
          setValue('phone', data.phone);
          setValue('email', data.email);
        }
      } catch (error) {
        openMessageError(error);
      } finally {
        setIsloading(false);
      }
    } else {
      resetForm();
    }
  };

  const resetForm = () => {
    setValue('name', '');
    setValue('furiganaName', '');
    setValue('phone', '');
    setValue('email', '');
    setValue('companies', []);
    setListCompany([]);
    setData({
      name: '',
      furiganaManagerName: '',
      email: '',
      phone: '',
      recordCompanies: [],
    });
    clearErrors();
  };

  const {
    handleSubmit,
    register,
    setError,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [showModal, setShowModal] = useState(false);

  const { openMessageError } = useMessage();

  const onSubmit = handleSubmit(async (data: IFormInputs) => {
    try {
      setIsloading(true);
      if (id) {
        await CuratorManagerApi.update(parseInt(id, 10), data);
        history.push(`/curator-management`);
      } else {
        await CuratorManagerApi.create({ ...data });
        history.push(`/curator-management`);
        goBackToList();
      }
    } catch (error) {
      if (error instanceof Error) {
        openMessageError(error);
      }
    } finally {
      setIsloading(false);
    }
  });

  const [deleteCuratorModal, setDeleteCuratorModal] = useState<boolean>(false);

  const handleDeleteCurator = async () => {
    setDeleteCuratorModal(false);
    if (id) {
      await CuratorManagerApi.delete(parseInt(id, 10));
    }
    history.push(`/curator-management`);
  };

  const onSelectCompany = (value: SelectValue): void => {
    setCurrentSelectCompany(value);
    setInputSearchString(value?.label || '');
  };

  const addCompanyToList = () => {
    if (!currentSelectCompany?.label?.trim()) {
      setError('valueSearchSelect', {
        message: '入力してください。',
      });
    } else {
      clearErrors('valueSearchSelect');
    }

    const cloneListCompanySelected = [...listCompanySelected];
    if (
      currentSelectCompany?.value &&
      !cloneListCompanySelected.some((code) => code.value === currentSelectCompany?.value || '')
    ) {
      cloneListCompanySelected.push(currentSelectCompany);
      setListCompany(cloneListCompanySelected);
      setCurrentSelectCompany(null);
      setInputSearchString('');
      clearErrors('valueSearchSelect');
    }
    const companiesId: number[] = [];
    cloneListCompanySelected.map((item) => companiesId.push(parseInt(item.value, 10)));
    setValue('companies', companiesId, { shouldValidate: true });
  };

  useEffect(() => {
    if (id && tabActive === btnGroupOptions[0].name) history.push(`/curator-management`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabActive, id]);

  const handleRemoveCompany = (value: string) => {
    const cloneListCompanySelected = [...listCompanySelected];

    const result = cloneListCompanySelected.filter((el) => {
      return el.value !== value;
    });
    setListCompany(result);
    setValue(
      'companies',
      result.map((item) => item.value),
    );
  };

  const renderListCompanySelected = () => {
    return listCompanySelected?.map((item: ISelectValue) => (
      <Row
        className="d-flex align-items-center justify-content-between position-relative underline-100"
        key={item.value}
      >
        <Col md="11" className="p-0 px-2 w-90">
          <p>{item.label}</p>
        </Col>
        {isManager && (
          <Col md="1" className="p-0 text-end">
            <Button
              variant="danger"
              className="pe-3 ps-3 my-2"
              onClick={() => {
                handleRemoveCompany(item?.value);
              }}
            >
              削除
            </Button>
          </Col>
        )}
      </Row>
    ));
  };

  const validateForm = handleSubmit(async () => {
    setShowModal(true);
  });

  const onFillInput = (fillName: string, content: string, isPhone = false) => {
    setValue(fillName, isPhone ? handleEnteringPhoneNumber(content) : content);
  };

  return (
    <>
      {id && (
        <Filter filterClass="shadow-sm">
          <Tabs
            options={btnGroupOptions}
            tabActive={tabActive}
            onChange={setTabActive}
            disabledSetQuery={true}
            btnClass="tab-width"
          />
        </Filter>
      )}

      <Container>
        <SpinnerComponent isLoading={isLoading} />
        <CardContainer className="mt-4 pb-4" title="担当者情報" darkHeader>
          <Form onSubmit={onSubmit} id="curatorManagement">
            <FormGroupInput
              value={watch('name')}
              register={{ ...register('name') }}
              label="担当者氏名*"
              labelMd="3"
              colMd="9"
              errorMessage={errors.name?.message}
              disabled={!isManager}
              onChange={(e) => onFillInput('name', e)}
            />
            <FormGroupInput
              value={watch('furiganaName')}
              register={{ ...register('furiganaName') }}
              label="フリガナ*"
              labelMd="3"
              colMd="9"
              errorMessage={errors.furiganaName?.message}
              disabled={!isManager}
              onChange={(e) => onFillInput('furiganaName', e)}
            />
            <FormGroupInput
              value={watch('phone')}
              labelMd="3"
              colMd="9"
              label="担当者電話番号"
              errorMessage={errors.phone?.message}
              register={{ ...register('phone') }}
              maxLength={13}
              isReview={!isManager}
              onChange={(e) => onFillInput('phone', e, true)}
              hintText="※「-」をつけて入力。（例）03-1234-5678 / 080-1234-5678"
            />
            <FormGroupInput
              value={watch('email')}
              register={{ ...register('email') }}
              label="担当者メールアドレス*"
              subLabel=""
              labelMd="3"
              colMd="9"
              hintText="※登録されるメールアドレスはログインに使われます。登録後にメールアドレスを編集できません。"
              errorMessage={errors.email?.message}
              disabled={!!id}
              classInput={id ? 'text-disable' : ''}
              onChange={(e) => onFillInput('email', e)}
            />
            <div className="division mt-4 mb-4" />
            <Row className="align-items-center">
              <FormLabel
                colMd="3"
                label="担当レコード会社"
                classForm="d-flex align-items-center h-100"
              />
              <Col md="9" className="d-flex">
                {isManager && (
                  <>
                    <Col md="11" className="p-0 w-90">
                      <PullDownCompany
                        defaultValue={currentSelectCompany?.label || ''}
                        onSelect={(selected: any) => onSelectCompany(selected)}
                        inputSearchString={inputSearchString}
                        label=""
                        placeholder=""
                        colClass="px-0"
                        colMd="12"
                        labelMd="2"
                        onInput={(text: string) => {
                          if (!text) {
                            setCurrentSelectCompany(null);
                            setInputSearchString('');
                          }
                        }}
                        errorMessage={
                          errors.valueSearchSelect?.message || errors.companies?.message
                        }
                      />
                    </Col>
                    <Col className="p-0 text-end">
                      <Button className="pe-3 ps-3 ms-1" onClick={addCompanyToList}>
                        追加
                      </Button>
                    </Col>
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <Col md="3"></Col>
              <Col md="9">
                <div
                  className={`artist-list-manage ${listCompanySelected.length > 0 ? 'mt-3' : ''}`}
                >
                  {renderListCompanySelected()}
                </div>
              </Col>
            </Row>
            {isManager && (
              <div className="d-flex justify-content-end txt-require mt-2">
                ご注意：＊印は必須項目です。
              </div>
            )}

            <ModalComponent
              title="登録確認"
              show={showModal}
              onCloseModal={() => setShowModal(false)}
              classNameContent="d-flex align-items-center content-body-modal justify-content-center"
              variantButtonSubmit="primary"
              submitIdForm="curatorManagement"
              onSubmitModal={() => setShowModal(false)}
            >
              <div>入力した内容で登録します。よろしいですか。</div>
            </ModalComponent>
            <ModalComponent
              title="削除確認"
              show={deleteCuratorModal}
              onCloseModal={() => setDeleteCuratorModal(false)}
              classNameContent="d-flex align-items-center content-body-modal justify-content-center"
              onSubmitModal={handleDeleteCurator}
              confirmText="削除する"
              variantButtonSubmit="danger"
            >
              <div className="fw-bold">担当者を削除します。よろしいですか。</div>
            </ModalComponent>
          </Form>
        </CardContainer>
        <div className="text-center mt-3 pb-4">
          {data?.id && includes(RoleNames[0], role) && (
            <Button
              variant="danger"
              className="me-4 btn-equal-width"
              onClick={() => setDeleteCuratorModal(true)}
            >
              担当者を削除する
            </Button>
          )}

          {isManager ? (
            <Button
              className="btn-equal-width"
              onClick={() => {
                validateForm();
              }}
            >
              登録する
            </Button>
          ) : (
            <Button onClick={() => history.push(`/curator-management`)} className="btn-focus-none">
              一覧へ戻る
            </Button>
          )}
        </div>
      </Container>
    </>
  );
}
