import React, { PropsWithChildren } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import MessageError from 'Components/Common/MessageError';
import { labelRequire } from 'Components/Common/Form/FormLabel';
import { FormInputNumberProps } from 'Components/Common/Form/FormInputNumber/input-number';

function FormInputNumber({
  label,
  classForm,
  classLabel,
  placeholder,
  labelMd,
  colMd,
  name,
  classCol,
  value,
  onChange,
  format,
  classInput,
  errorMessage,
  onBlur,
  thousandsGroupStyle,
  maxLength,
  defaultValue,
  isReview = false,
  classMessage,
  pureType,
  hintText,
  decimalScale,
  allowNegative,
  maxValue,
  minValue,
  thousandSeparator = true,
  allowLeadingZeros,
}: PropsWithChildren<FormInputNumberProps>): JSX.Element {
  const { control } = useForm();

  const handleOnchange = (number: string) => {
    if (onChange) {
      if (minValue) {
        if (+number >= +minValue) {
          onChange(number || '');
        } else {
          onChange('');
        }
      } else {
        onChange(number || '');
      }
    }
  };

  const isAllowed = (values: NumberFormatValues): boolean => {
    let isValid = true;
    if (maxLength) {
      isValid = values?.value.length <= maxLength;
    }
    if (maxValue) {
      isValid = (values?.floatValue ?? 0) <= maxValue;
    }
    return isValid;
  };

  return (
    <Form.Group as={Row} className={`mb-3 ${classForm || ''}`}>
      <Form.Label column md={labelMd}>
        {labelRequire(label, classLabel)}
      </Form.Label>
      <Col md={colMd} className={classCol}>
        <Controller
          name={name}
          control={control}
          render={() => (
            <NumberFormat
              value={value}
              onValueChange={({ formattedValue, value }) => {
                handleOnchange(pureType ? value : formattedValue);
              }}
              className={`form-control rounded position-relative ${classInput}`}
              placeholder={placeholder}
              format={format}
              onBlur={onBlur}
              thousandsGroupStyle={thousandsGroupStyle}
              thousandSeparator={thousandSeparator}
              maxLength={maxLength}
              decimalprecision=""
              defaultValue={defaultValue}
              disabled={isReview}
              decimalScale={decimalScale}
              isAllowed={isAllowed}
              allowNegative={allowNegative}
              allowLeadingZeros={allowLeadingZeros}
            />
          )}
        />
        {hintText && (
          <div>
            <small className="fs-12">{hintText}</small>
          </div>
        )}
        <MessageError classWrapper={`${classMessage} ps-2`} message={errorMessage} />
      </Col>
    </Form.Group>
  );
}
export default FormInputNumber;
