/*eslint @typescript-eslint/explicit-module-boundary-types: 0*/

export const fileToURL = (file: File) =>
  new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target && typeof e.target.result === 'string') resolve(e.target.result);
    };
    reader.readAsDataURL(file);
  });

export function checkIfFilesAreTooBig(files?: FileList): boolean {
  let valid = true;
  if (files) {
    const size = files[0].size / 1024 / 1024;
    if (size > 2) {
      valid = false;
    }
  }
  return valid;
}

export function checkIfFilesAreCorrectType(file?: File | string): boolean {
  if (typeof file === 'string') {
    return true;
  }
  let valid = true;
  if (file) {
    const type = file.type;
    if (
      !['image/jpg', 'image/jpeg', 'image/gif', 'image/png', 'application/octet-stream'].includes(
        type,
      )
    ) {
      valid = false;
    }
  }
  return valid;
}

export const exportExcel = async (api: any, nameFile: string, nameType = 'xlsx') => {
  const response = await api;

  if (response) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${nameFile}.${nameType}`);
    document.body.appendChild(link);
    link.click();
  }
};

export const calcMb = (bitSize: number): number => Number((bitSize / 1024 / 1024).toFixed(2));

interface GetSizeImageProps {
  width?: number;
  height?: number;
}

export const getSizeImage = (urlImg: string): Promise<GetSizeImageProps> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = urlImg;
    img.onload = function () {
      resolve({ width: img.width, height: img.height });
    };
  });
};

export const checkImgSquare = async (files: File | string): Promise<boolean> => {
  if (typeof files === 'string') {
    return true;
  }
  let valid = true;
  if (files && checkIfFilesAreCorrectType(files)) {
    const urlImg: string = URL.createObjectURL(files);
    const sizeImg: GetSizeImageProps = await getSizeImage(urlImg);
    valid = sizeImg.width === sizeImg.height;
  }
  return valid;
};

export const checkImgSquareAndDimension = async (
  files: File | string,
  dimension: number,
): Promise<boolean> => {
  if (typeof files === 'string') {
    return true;
  }
  let valid = true;
  if (files && checkIfFilesAreCorrectType(files)) {
    const urlImg: string = URL.createObjectURL(files);
    const sizeImg: GetSizeImageProps = await getSizeImage(urlImg);
    valid = sizeImg.width === sizeImg.height && sizeImg.width === dimension;
  }
  return valid;
};
