/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import brAxios from 'Api/Interceptors';
import { transformQuery } from 'Utils/Params';

export interface ParamExcelPrize {
  campaignId?: string;
  courseId?: string;
  startDate?: string;
  endDate?: string;
  prizeId?: string;
  sort?: string;
  order?: string;
}

interface queryCampaign {
  page?: number;
  perPage?: number;
  query?: string | number;
  type?: string;
  screen?: string;
  artistIds?: string;
  artistId?: string;
  titleId?: string;
  companyId?: string;
  action?: number;
}

export interface ParamExcelStatistic {
  courseId?: string;
  startDate?: string;
  endDate?: string;
  countJoinEnd?: string;
}

interface QueryCourse {
  page?: number;
  perPage?: number;
  query?: string;
  campaignId?: string;
  courseId?: string;
}

export const CampaignApis = {
  getCampaigns: ({
    limit,
    page,
    artistId,
    titleId,
    companyId,
    sort,
    order,
  }: {
    limit?: number;
    page?: number;
    artistId?: null | string | undefined;
    titleId?: null | string | undefined;
    companyId?: null | string | undefined;
    sort?: string;
    order?: string;
  }) => {
    return brAxios.get(
      `/bootrock-company/campaigns?page=${page ?? 1}&limit=${limit ?? 20}${
        artistId ? `&artistIds=${artistId}` : ''
      }${titleId ? `&titleIds=${titleId}` : ''}${companyId ? `&companyId=${companyId}` : ''}&sort=${
        sort || 'id'
      }&order=${order || 'DESC'}`,
    );
  },
  getCampaign: ({ id }: { id: string }) => brAxios.get(`/bootrock-company/campaigns/${id}`),
  getStatistic: () => brAxios.get('/bootrock-company/campaigns/statistic'),

  getStatisticData: ({
    campaignId,
    courseId,
    startDate,
    endDate,
    countJoinEnd,
  }: {
    campaignId: string;
    courseId: string;
    startDate: string;
    endDate: string;
    countJoinEnd?: string;
  }) =>
    brAxios.get(
      `/bootrock-company/campaigns/statistics/${campaignId}?courseId=${courseId}&startDate=${startDate}&endDate=${endDate}${
        countJoinEnd ? `&countJoinEnd=${countJoinEnd}` : ''
      }`,
    ),
  exportStatisticExcel: ({
    campaignId,
    params,
  }: {
    campaignId: string;
    params: ParamExcelStatistic;
  }) => {
    const response = brAxios.get(
      `/bootrock-company/campaigns/statistics/export-excel/${campaignId}`,
      { params, responseType: 'blob' },
    );
    return response;
  },

  getAllSuggestionCourses: ({ page, perPage, query, campaignId, courseId }: QueryCourse) => {
    const response = brAxios.get(
      `/suggestion/courses?page=${page ?? ''}&limit=${perPage ?? ''}&query=${
        query ?? ''
      }&campaignId=${campaignId ?? ''}&courseId=${courseId ?? ''}`,
    );
    return response;
  },
  getAllSuggestionPrizes: ({ page, perPage, query, campaignId, courseId }: QueryCourse) => {
    const response = brAxios.get(
      `/suggestion/prizes?page=${page ?? ''}&limit=${perPage ?? ''}&query=${
        query ?? ''
      }&campaignId=${campaignId ?? ''}&courseId=${courseId ?? ''}`,
    );
    return response;
  },

  getAllSuggestionCampaigns: ({
    page,
    perPage,
    query,
    screen,
    artistId,
    titleId,
    companyId,
  }: queryCampaign) => {
    const response = brAxios.get(
      `/suggestion/campaigns?page=${page ?? ''}&limit=${perPage ?? ''}&query=${
        query ?? ''
      }&companyId=${companyId ?? ''}&artistId=${artistId ?? ''}&titleId=${titleId ?? ''}&screen=${
        screen ?? ''
      }`,
    );
    return response;
  },

  getPrizes: (params: any) => {
    const strParam = transformQuery(params);
    const response = brAxios.get(`/bootrock-company/campaigns/prize${strParam}`);
    return response;
  },

  exportPrizeExcel: (params: ParamExcelPrize) => {
    const response = brAxios.get('/bootrock-company/campaigns/prize/export/excel', {
      params,
      responseType: 'blob',
    });
    return response;
  },
};
