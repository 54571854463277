import React, { useEffect, useMemo, useState } from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { CaretUpFill, CaretDownFill } from 'react-bootstrap-icons';
import { OverlayTrigger, Tooltip, Form } from 'react-bootstrap';
import MessageError from 'Components/Common/MessageError';
import { CourseTypes, subTypeOptions } from 'Constant/Campaign';
import { CourseCampaign } from 'Types/Page/Campaign/RegisterCampaign';

import { isEmpty } from 'lodash';
interface RowTypes {
  awardLabel: string;
  className: string;
  isShowError?: boolean;
  isDisabled?: boolean;
  prizeIndex: number;
  course: CourseCampaign;
}

interface CheckboxType {
  className: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: any) => void;
}

const RowHandler = SortableHandle(() => (
  <div className="me-3 d-flex flex-column" style={{ cursor: 'grabbing' }}>
    <CaretUpFill fontSize="12" />
    <CaretDownFill fontSize="12" />
  </div>
));

const CheckBox = ({ checked = false, className, disabled, onChange }: CheckboxType) => {
  return (
    <div className="text-center">
      <Form.Check
        type="checkbox"
        defaultChecked={checked}
        className={className}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  );
};

const TableRowView = ({
  awardLabel,
  className,
  isShowError = false,
  isDisabled,
  prizeIndex = 0,
  course,
}: RowTypes): JSX.Element => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const getWidth = React.useMemo(() => {
    return course.type === 2 && course.subType !== 2 ? 300 : 650;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course, mounted]);

  const prizes = useMemo(() => {
    return isEmpty(course.prizes) ? [] : course.prizes;
  }, [course.prizes]);

  return (
    <tr className={`tr-drag ${className}`}>
      <td
        style={{
          width: `${getWidth}px`,
        }}
      >
        <div className="d-flex align-items-center">
          <div
            className={`${
              isDisabled
                ? 'pe-none user-select-none opacity-50'
                : 'pe-auto user-select-auto opacity-100'
            }`}
          >
            <RowHandler />
          </div>
          <div className="d-flex align-items-center flex-column">
            <OverlayTrigger
              overlay={<Tooltip id={prizes[prizeIndex]?.name}>{prizes[prizeIndex]?.name}</Tooltip>}
            >
              <div
                className="text-truncate text-nowrap"
                style={{
                  maxWidth: `${getWidth}px`,
                }}
              >
                {prizes[prizeIndex]?.name}
              </div>
            </OverlayTrigger>
            <div className="d-flex flex-column align-self-start">
              {isShowError ? <MessageError message="未登録コンテンツあり" /> : <></>}
            </div>
          </div>
        </div>
      </td>
      <td>{awardLabel}</td>
      <td>
        <div className="text-center">
          {prizes[prizeIndex]?.userQuantity ? `${prizes[prizeIndex]?.userQuantity} 名` : '上限なし'}
        </div>
      </td>
      {course?.type === CourseTypes.REALTIME ? (
        <>
          {course?.subType !== subTypeOptions[2].value && (
            <td>
              <div className="text-center">
                {prizes[prizeIndex]?.winRate ? `${prizes[prizeIndex]?.winRate}%` : '0%'}
              </div>
            </td>
          )}
          <td className="view-only">
            {prizes[prizeIndex]?.isIncentive && (
              <CheckBox
                checked
                className={'checkbox-no-label check-box-size check-box-ct pe-none'}
              />
            )}
          </td>
        </>
      ) : (
        <></>
      )}
      <td></td>
    </tr>
  );
};

export default TableRowView;
