/* eslint-disable react-hooks/exhaustive-deps */
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { IPullDownItem } from 'Types/Common';
import { useQueryParams, StringParam, NumberParam } from 'use-query-params';
import {
  handleSearchArtist,
  handleSearchCD,
  handleSearchCompany,
  handleSearchSaleChannels,
  handleSearchTitle,
  handleSearchCampaigns,
  handleSearchVariation,
  PageQuery,
  updateQuery,
  handleGetIds,
} from 'Utils/Search';
import useMessage from './useMessage';

export interface SearchResponse {
  options: IPullDownItem[];
  totalPages: number;
}

interface ValidQueryCommon {
  [key: string]: string | number | undefined;
}
interface Props {
  validQueryObj?: {
    validCompany?: ValidQueryCommon;
    validArtist?: ValidQueryCommon;
    validTitle?: ValidQueryCommon;
    validVariation?: ValidQueryCommon;
    validCD?: ValidQueryCommon;
    validSale?: ValidQueryCommon;
    validTitleStatus?: ValidQueryCommon;
    validCampaign?: ValidQueryCommon;
    validStatus?: ValidQueryCommon;
  };
  fetchList: string[];
  showDraftVariation?: boolean;
  isEcSuggestion?: boolean;
}

export default function useSuggestion({
  validQueryObj,
  fetchList,
  showDraftVariation = false,
}: // isEcSuggestion = false,
Props): {
  companyOptions: SearchResponse;
  artistOptions: SearchResponse;
  titleOptions: SearchResponse;
  variationOptions: SearchResponse;
  cdOptions: SearchResponse;
  saleOptions: SearchResponse;
  titleStatusOptions: SearchResponse;
  campaignOptions: SearchResponse;
  pageObj: PageQuery;
  loadingArtist: boolean;
  loadingTitle: boolean;
  loadingVariation: boolean;
  loadingCD: boolean;
  loadingSale: boolean;
  loadingTitleStatus: boolean;
  loadingCampaign: boolean;
  loadingCompany: boolean;
  handleLoadMore: (pageKey: string) => void;
  handleFocusInput: (queryKey: string, pageKey: string) => void;
  handleEnterPrimary: (type: string) => void;
  handleClickItemPrimary: (id: string, type: string) => void;
} {
  //* some query clear with search key clear
  const currentValidQuery = {
    validCompany: {
      ...validQueryObj?.validCompany,
      companyIds: undefined,
      queryCompany: undefined,
      companyId: undefined,
    },
    validArtist: {
      ...validQueryObj?.validArtist,
      artistIds: undefined,
      queryArtist: undefined,
      artistId: undefined,
    },
    validTitle: {
      ...validQueryObj?.validTitle,
      queryTitle: undefined,
      titleIds: undefined,
      titleId: undefined,
    },
    validVariation: {
      ...validQueryObj?.validVariation,
      queryVariation: undefined,
      variationIds: undefined,
      variationId: undefined,
    },
    validCD: { ...validQueryObj?.validCD, queryCD: undefined, cdIds: undefined, cdId: undefined },
    validSale: { ...validQueryObj?.validSale, querySale: undefined, saleIds: undefined },
    validTitleStatus: {
      ...validQueryObj?.validTitleStatus,
      queryTitleStatus: undefined,
      titleStatus: undefined,
    },
    validStatus: {
      ...validQueryObj?.validStatus,
      queryStatus: undefined,
      statusIds: undefined,
      statusId: undefined,
    },
  };

  const [query, setQuery] = useQueryParams({
    queryArtist: StringParam,
    queryTitle: StringParam,
    artistIds: StringParam,
    titleIds: StringParam,
    variationIds: StringParam,
    queryVariation: StringParam,
    artistId: StringParam,
    titleId: StringParam,
    variationId: StringParam,
    queryCD: StringParam,
    cdIds: StringParam,
    saleIds: StringParam,
    querySale: StringParam,
    companyId: StringParam,
    companyIds: StringParam,
    queryCompany: StringParam,
    queryTitleStatus: StringParam,
    titleStatus: StringParam,
    queryStatus: StringParam,
    statusIds: StringParam,
    statusId: StringParam,
    queryCampaign: StringParam,
    campaignIds: StringParam,
    campaignId: StringParam,
    cdId: StringParam,
    page: NumberParam,
    saleId: StringParam,
  });

  const { openMessageError } = useMessage();

  const [queryObj, setQueryObj] = useState({
    queryArtist: query.queryArtist,
    queryTitle: query.queryTitle,
    queryVariation: query.queryVariation,
    queryCD: query.queryCD,
    querySale: query.querySale,
    queryCompany: query.queryCompany,
    queryTitleStatus: query.queryTitleStatus,
    queryCampaign: query.queryCampaign,
  });

  const [pageObj, setPageObj] = useState<PageQuery>({
    pageArtist: 1,
    pageTitle: 1,
    pageVariation: 1,
    pageCD: 1,
    pageSale: 1,
    pageCompany: 1,
    pageTitleStatus: 1,
    pageCampaign: 1,
  });

  const [artists, setArtists] = useState<SearchResponse>({ options: [], totalPages: 0 });
  const [titles, setTitles] = useState<SearchResponse>({ options: [], totalPages: 0 });
  const [variations, setVariations] = useState<SearchResponse>({ options: [], totalPages: 0 });
  const [cds, setCds] = useState<SearchResponse>({ options: [], totalPages: 0 });
  const [sales, setSales] = useState<SearchResponse>({ options: [], totalPages: 0 });
  const [companies, setCompanies] = useState<SearchResponse>({ options: [], totalPages: 0 });
  const [titleStatus, setTitleStatus] = useState<SearchResponse>({ options: [], totalPages: 0 });
  const [campaigns, setCampaigns] = useState<SearchResponse>({ options: [], totalPages: 0 });

  const [artistTmp, setArtistTmp] = useState<IPullDownItem[]>([]);
  const [titleTmp, setTitleTmp] = useState<IPullDownItem[]>([]);
  const [variationTmp, setVariationTmp] = useState<IPullDownItem[]>([]);
  const [cdTmp, setCdTmp] = useState<IPullDownItem[]>([]);
  const [saleTmp, setSaleTmp] = useState<IPullDownItem[]>([]);
  const [companyTmp, setCompanyTmp] = useState<IPullDownItem[]>([]);
  const [campaignTmp, setCampaignTmp] = useState<IPullDownItem[]>([]);
  const [titleStatusTmp, setTitleStatusTmp] = useState<IPullDownItem[]>([]);

  const [loadingArtist, setLoadingArtist] = useState<boolean>(false);
  const [loadingTitle, setLoadingTitle] = useState<boolean>(false);
  const [loadingVariation, setLoadingVariation] = useState<boolean>(false);
  const [loadingCD, setLoadingCD] = useState<boolean>(false);
  const [loadingSale, setLoadingSale] = useState<boolean>(false);
  const [loadingCompany, setLoadingCompany] = useState<boolean>(false);
  const [loadingTitleStatus, setLoadingTitleStatus] = useState<boolean>(false);
  const [loadingCampaign, setLoadingCampaign] = useState<boolean>(false);

  const handleFetchCompanies = async (fetchTmp?: boolean) => {
    try {
      setLoadingCompany(true);
      const {
        data: { data },
      } = await handleSearchCompany({
        page: pageObj.pageCompany,
        queryCompany: (fetchTmp ? query.queryCompany : queryObj.queryCompany) || '',
      });

      const options = data?.items?.map((company: IPullDownItem) => ({
        label: company.name,
        value: company.id,
      }));

      if (fetchTmp) {
        setCompanyTmp(pageObj.pageCompany === 1 ? options : [...companies.options, ...options]);
      } else {
        setCompanies({
          options: pageObj.pageCompany === 1 ? options : [...companies.options, ...options],
          totalPages: data?.meta?.totalPages,
        });
      }
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoadingCompany(false);
    }
  };

  const handleFetchArtists = async (fetchTmp?: boolean) => {
    try {
      setLoadingArtist(true);
      const {
        data: { data },
      } = await handleSearchArtist({
        queryArtist: (fetchTmp ? query.queryArtist : queryObj.queryArtist) || '',
        page: pageObj.pageArtist,
        companyId: query.companyId || '',
      });

      const options = data?.items?.map((artist: IPullDownItem) => ({
        label: artist.localName,
        value: artist.id,
      }));

      if (fetchTmp) {
        setArtistTmp(pageObj.pageArtist === 1 ? options : [...artists.options, ...options]);
      } else {
        setArtists({
          options: pageObj.pageArtist === 1 ? options : [...artists.options, ...options],
          totalPages: data?.meta?.totalPages,
        });
      }
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoadingArtist(false);
    }
  };

  const handleFetchTitles = async (fetchTmp?: boolean) => {
    try {
      setLoadingTitle(true);
      const {
        data: { data },
      } = await handleSearchTitle({
        queryTitle: (fetchTmp ? query.queryTitle : queryObj.queryTitle) || '',
        artistId: query.artistId || '',
        page: pageObj.pageTitle,
        companyId: query.companyId || '',
      });

      const options = data?.items?.map((title: IPullDownItem) => ({
        label: title.name,
        value: title.id,
      }));
      if (fetchTmp) {
        setTitleTmp(pageObj.pageTitle === 1 ? options : [...titles.options, ...options]);
      } else {
        setTitles({
          options: pageObj.pageTitle === 1 ? options : [...titles.options, ...options],
          totalPages: data?.meta?.totalPages,
        });
      }
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoadingTitle(false);
    }
  };

  const handleFetchVariations = async (fetchTmp?: boolean) => {
    try {
      setLoadingVariation(true);
      const {
        data: { data },
      } = await handleSearchVariation({
        queryVariation: (fetchTmp ? query.queryVariation : queryObj.queryVariation) || '',
        artistId: query.artistId || '',
        titleId: query.titleId || '',
        showDraft: showDraftVariation,
        page: pageObj.pageVariation,
        companyId: query.companyId || '',
      });

      const options = data?.items?.map((title: IPullDownItem) => ({
        label: title.name,
        value: title.id,
      }));

      if (fetchTmp) {
        setVariationTmp(
          pageObj.pageVariation === 1 ? options : [...variations.options, ...options],
        );
      } else {
        setVariations({
          options: pageObj.pageVariation === 1 ? options : [...variations.options, ...options],
          totalPages: data?.meta?.totalPages,
        });
      }
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoadingVariation(false);
    }
  };

  const handleFetchCDs = async (fetchTmp?: boolean) => {
    try {
      setLoadingCD(true);
      const {
        data: { data },
      } = await handleSearchCD({
        page: pageObj.pageCD,
        queryCD: (fetchTmp ? query.queryCD : queryObj.queryCD) || '',
        artistId: query.artistId || '',
        titleId: query.titleId || '',
        cdId: query.cdId || '',
        companyId: query.companyId || '',
      });

      const options = data?.items?.map((cd: IPullDownItem) => ({
        label: cd.name,
        value: cd.id,
      }));

      if (fetchTmp) {
        setCdTmp(pageObj.pageCD === 1 ? options : [...cds.options, ...options]);
      } else {
        setCds({
          options: pageObj.pageCD === 1 ? options : [...cds.options, ...options],
          totalPages: data?.meta?.totalPages,
        });
      }
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoadingCD(false);
    }
  };

  const handleFetchSales = async (fetchTmp?: boolean) => {
    try {
      setLoadingSale(true);
      const {
        data: { data },
      } = await handleSearchSaleChannels({
        // artistId: query.artistId || query.artistIds || '',
        companyId: query.companyId || '',
        // titleId: query.titleId || query.titleIds || '',
        page: pageObj.pageSale,
        querySale: (fetchTmp ? query.querySale : queryObj.querySale) || '',
      });

      const options = data?.items?.map((sale: IPullDownItem) => ({
        label: sale.channelName,
        value: sale.id,
      }));

      if (fetchTmp) {
        setSaleTmp(pageObj.pageSale === 1 ? options : [...sales.options, ...options]);
      } else {
        setSales({
          options: pageObj.pageSale === 1 ? options : [...sales.options, ...options],
          totalPages: data?.meta?.totalPages,
        });
      }
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoadingSale(false);
    }
  };
  const handleFetchTitleStatus = async (fetchTmp?: boolean) => {
    try {
      setLoadingTitleStatus(true);
      const data = {
        items: [
          {
            id: '',
            name: 'すべて',
          },
          {
            id: 0,
            name: '一時保存',
          },
          {
            id: 1,
            name: '登録済',
          },
        ],
        meta: {
          totalItems: 3,
          itemCount: 3,
          itemsPerPage: 3,
          totalPages: 1,
          currentPage: 1,
        },
      };

      const options = data?.items?.map((titleStatus: any) => ({
        label: titleStatus.name,
        value: titleStatus.id,
      }));

      if (fetchTmp) {
        setTitleStatusTmp(
          pageObj.pageTitleStatus === 1 ? options : [...titleStatus.options, ...options],
        );
      } else {
        setTitleStatus({
          options: pageObj.pageTitleStatus === 1 ? options : [...titleStatus.options, ...options],
          totalPages: data?.meta?.totalPages,
        });
      }
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoadingTitleStatus(false);
    }
  };

  const handleFetchCampaigns = async (fetchTmp?: boolean) => {
    try {
      setLoadingCampaign(true);
      const {
        data: { data },
      } = await handleSearchCampaigns({
        artistId: query.artistId || '',
        titleId: query.titleId || '',
        page: pageObj.pageCampaign,
        queryCampaign: (fetchTmp ? query.queryCampaign : queryObj.queryCampaign) || '',
      });

      const options = data?.items?.map((campaign: IPullDownItem) => ({
        label: campaign.name,
        value: campaign.id,
      }));

      if (fetchTmp) {
        setCampaignTmp(pageObj.pageCampaign === 1 ? options : [...campaigns.options, ...options]);
      } else {
        setCampaigns({
          options: pageObj.pageCampaign === 1 ? options : [...campaigns.options, ...options],
          totalPages: data?.meta?.totalPages,
        });
      }
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoadingCampaign(false);
    }
  };

  const debouceRequest = useCallback((value) => updateQuery(value, setQueryObj), []);

  //* debouce typing text 300ms
  useEffect(() => {
    debouceRequest({
      queryArtist: query.queryArtist,
      queryTitle: query.queryTitle,
      queryVariation: query.queryVariation,
      queryCD: query.queryCD,
      querySale: query.querySale,
      queryCompany: query.queryCompany,
      queryTitleStatus: query.queryTitleStatus,
      queryCampaign: query.queryCampaign,
    });
  }, [
    query.queryArtist,
    query.queryTitle,
    query.queryVariation,
    query.queryCD,
    query.queryTitle,
    query.querySale,
    query.queryCompany,
    query.queryTitleStatus,
    query.queryCampaign,
  ]);

  //* only call api when match key search
  useEffect(() => {
    if (fetchList.includes('company')) {
      handleFetchCompanies();
      handleFetchCompanies(true);
    }
  }, [queryObj, pageObj.pageCompany, query]);

  useEffect(() => {
    if (fetchList.includes('artist')) {
      handleFetchArtists();
      handleFetchArtists(true);
    }
  }, [queryObj, pageObj.pageArtist, query]);

  useEffect(() => {
    if (fetchList.includes('title')) {
      handleFetchTitles();
      handleFetchTitles(true);
    }
  }, [queryObj, pageObj.pageTitle, query]);

  useEffect(() => {
    if (fetchList.includes('variation')) {
      handleFetchVariations();
      handleFetchVariations(true);
    }
  }, [queryObj, pageObj.pageVariation, query]);

  useEffect(() => {
    if (fetchList.includes('cd')) {
      handleFetchCDs();
      handleFetchCDs(true);
    }
  }, [queryObj, pageObj.pageCD, query]);

  useEffect(() => {
    if (fetchList.includes('sale')) {
      handleFetchSales();
      handleFetchSales(true);
    }
  }, [queryObj, pageObj.pageSale, query]);

  //* clear some query if text search clear
  useEffect(() => {
    if (fetchList.includes('campaign')) {
      handleFetchCampaigns();
      handleFetchCampaigns(true);
    }
  }, [queryObj, pageObj.pageCampaign]);

  useEffect(() => {
    if (fetchList.includes('titleStatus')) {
      handleFetchTitleStatus();
      handleFetchTitleStatus(true);
    }
  }, [queryObj, pageObj.pageTitleStatus]);
  //* clear some query if text search clear
  useEffect(() => {
    let validQuery = { ...query } as any;

    if (!query.queryCompany && !isEmpty(currentValidQuery.validCompany)) {
      validQuery = currentValidQuery.validCompany;
      setQuery(validQuery);
    }

    setPageObj({ pageCompany: 1, pageArtist: 1, pageTitle: 1, pageVariation: 1 });
  }, [query.queryCompany]);

  useEffect(() => {
    let validQuery = { ...query } as any;

    if (!query.queryArtist && !isEmpty(currentValidQuery.validArtist)) {
      validQuery = currentValidQuery.validArtist;
      setQuery(validQuery);
    }

    setPageObj({ pageArtist: 1, pageTitle: 1, pageVariation: 1 });
  }, [query.queryArtist]);

  useEffect(() => {
    let validQuery = { ...query } as any;
    if (!query.queryTitle && !isEmpty(currentValidQuery.validTitle)) {
      validQuery = currentValidQuery.validTitle;
      setQuery(validQuery);
    }

    setPageObj({ ...pageObj, pageTitle: 1, pageVariation: 1 });
  }, [query.queryTitle]);

  useEffect(() => {
    let validQuery = { ...query } as any;
    if (!query.queryVariation && !isEmpty(currentValidQuery.validVariation)) {
      validQuery = currentValidQuery.validVariation;
      setQuery(validQuery);
    }

    setPageObj({ ...pageObj, pageVariation: 1, pageCD: 1 });
  }, [query.queryVariation]);

  useEffect(() => {
    let validQuery = { ...query } as any;
    if (!query.queryCD && !isEmpty(currentValidQuery.validCD)) {
      validQuery = currentValidQuery.validCD;
      setQuery(validQuery);
    }
    setPageObj({ ...pageObj, pageCD: 1 });
  }, [query.queryCD]);

  useEffect(() => {
    let validQuery = { ...query } as any;
    if (!query.querySale && !isEmpty(currentValidQuery.validSale)) {
      validQuery = currentValidQuery.validSale;
      setQuery(validQuery);
    }
    setPageObj({ ...pageObj, pageSale: 1 });
  }, [query.querySale]);

  useEffect(() => {
    let validQuery = { ...query } as any;
    if (!query.queryTitleStatus && !isEmpty(currentValidQuery.validTitleStatus)) {
      validQuery = currentValidQuery.validTitleStatus;
      setQuery(validQuery);
    }
    setPageObj({ ...pageObj, pageTitleStatus: 1 });
  }, [query.queryTitleStatus]);

  useEffect(() => {
    let validQuery = { ...query } as any;
    if (!query.queryStatus && !isEmpty(currentValidQuery.validStatus)) {
      validQuery = currentValidQuery.validStatus;
      setQuery(validQuery);
    }
    setPageObj({ ...pageObj });
  }, [query.queryStatus]);

  const handleLoadMore = (pageKey: string) => {
    if (pageKey === 'pageCompany' && pageObj.pageCompany < companies.totalPages)
      setPageObj({ ...pageObj, pageCompany: (pageObj.pageCompany += 1) });

    if (pageKey === 'pageArtist' && pageObj.pageArtist < artists.totalPages)
      setPageObj({ ...pageObj, pageArtist: (pageObj.pageArtist += 1) });

    if (pageKey === 'pageTitle' && pageObj.pageTitle < titles.totalPages)
      setPageObj({ ...pageObj, pageTitle: (pageObj.pageTitle += 1) });

    if (pageKey === 'pageVariation' && pageObj.pageVariation < variations.totalPages)
      setPageObj({ ...pageObj, pageVariation: (pageObj.pageVariation += 1) });

    if (pageKey === 'pageCD' && pageObj.pageCD < cds.totalPages)
      setPageObj({ ...pageObj, pageCD: (pageObj.pageCD += 1) });

    if (pageKey === 'pageSale' && pageObj.pageSale < sales.totalPages)
      setPageObj({ ...pageObj, pageSale: (pageObj.pageSale += 1) });
    if (pageKey === 'pageTitleStatus' && pageObj.pageTitleStatus < titleStatus.totalPages)
      setPageObj({ ...pageObj, pageTitleStatus: (pageObj.pageTitleStatus += 1) });

    if (pageKey === 'pageCampaign' && pageObj.pageCampaign < campaigns.totalPages)
      setPageObj({ ...pageObj, pageCampaign: (pageObj.pageCampaign += 1) });
  };

  //* when open menu, reset list menu all data
  const handleFocusInput = useCallback(
    (queryKey: string, pageKey: string) => {
      setQueryObj({ ...queryObj, [queryKey]: '' });
      setPageObj({ ...pageObj, [pageKey]: 1 });
    },
    [pageObj, queryObj],
  );

  const handleEnterPrimary = useCallback(
    (type: string) => {
      const res = [
        artistTmp,
        titleTmp,
        variationTmp,
        companyTmp,
        cdTmp,
        saleTmp,
        titleStatusTmp,
        campaignTmp,
      ];
      const ids = res.map((item: any) => handleGetIds(item));
      if (type === 'queryArtist') {
        const artistIds = query[type] ? (ids[0] ? ids[0] : '') : '';
        setQuery({
          artistIds: artistIds as string,
          page: 1,
        });
      }

      if (type === 'queryTitle') {
        const titleIds = query[type] ? (ids[1] ? ids[1] : '') : '';
        setQuery({
          titleIds: titleIds as string,
          page: 1,
        });
      }

      if (type === 'queryVariation') {
        const variationIds = query[type] ? (ids[2] ? ids[2] : '') : '';
        setQuery({
          variationIds: variationIds as string,
          page: 1,
        });
      }

      if (type === 'queryCompany') {
        const companyIds = query[type] ? (ids[3] ? ids[3] : '') : '';
        setQuery({
          companyIds: companyIds as string,
          page: 1,
        });
      }

      if (type === 'queryCD') {
        const cdIds = query[type] ? (ids[4] ? ids[4] : '') : '';
        setQuery({
          cdIds: cdIds as string,
          page: 1,
        });
      }

      if (type === 'querySale') {
        const saleIds = query[type] ? (ids[5] ? ids[5] : '') : '';
        setQuery({
          saleIds: saleIds as string,
          page: 1,
        });
      }

      if (type === 'queryTitleStatus') {
        const titleStatus = query[type] ? (ids[6] ? ids[6] : '') : '';
        setQuery({
          titleStatus: titleStatus as string,
          page: 1,
        });
      }

      if (type === 'queryCampaign') {
        const campaignIds = query[type] ? (ids[7] ? ids[7] : '') : '';
        setQuery({
          campaignIds: campaignIds as string,
          page: 1,
        });
      }
    },
    [
      artists.options,
      cds.options,
      query,
      setQuery,
      titles.options,
      variations.options,
      companies.options,
      sales.options,
      titleStatus.options,
    ],
  );

  const handleClickItemPrimary = useCallback(
    (id: string, type: string) => {
      if (type === 'queryArtist') {
        setQuery({ artistId: id, artistIds: id, page: 1 });
      }

      if (type === 'queryTitle') {
        setQuery({ titleId: id, page: 1, titleIds: id });
      }

      if (type === 'queryVariation') {
        setQuery({ variationId: id, page: 1, variationIds: id });
      }

      if (type === 'queryCompany') {
        setQuery({ companyId: id, page: 1, companyIds: id });
      }

      if (type === 'queryCD') {
        setQuery({ cdIds: id, page: 1, cdId: id });
      }

      if (type === 'querySale') {
        setQuery({ saleIds: id, page: 1, saleId: id });
      }

      if (type === 'queryTitleStatus') {
        setQuery({ titleStatus: id, page: 1 });
      }
    },
    [setQuery],
  );

  return {
    companyOptions: companies,
    artistOptions: artists,
    titleOptions: titles,
    variationOptions: variations,
    cdOptions: cds,
    saleOptions: sales,
    campaignOptions: campaigns,
    titleStatusOptions: titleStatus,
    pageObj,
    loadingArtist,
    loadingTitle,
    loadingVariation,
    loadingCD,
    loadingSale,
    loadingTitleStatus,
    loadingCompany,
    loadingCampaign,
    handleLoadMore,
    handleFocusInput,
    handleEnterPrimary,
    handleClickItemPrimary,
  };
}
