/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
// import PaginationComponent from 'Components/Common/Pagination/Pagination';
// import { PAGE_SIZE } from 'Constant';
// import useMessage from 'Hooks/useMessage';
// import { RecordCompanyApi } from 'Datasource/RecordCompany';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { Cell } from 'react-table';
// import NumberFormat from 'react-number-format';
import TableEC from 'Components/Common/Table/TableEC';
import { Link } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';
import { SortMethod } from 'Components/Common/Table/table';
import { ECApis } from 'Datasource/EC';
import { Download } from 'react-bootstrap-icons';
import dayjs from 'dayjs';
import { exportExcel } from 'Utils/File';
import useErrorBlob from 'Hooks/useErrorBlob';
import { formatPrice } from 'Utils/Numbers';

interface IValue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface PurchaseSummaryListData {
  code: string;
  companyName: string;
  amountOptionFeeTotal: number;
  curPrice: number;
  feeTax: number;
  amountCardBuyFeeTotal: number;
  totalPrice: number;
  revenueIncludesTax: number;
  amountTaxFeeMoneyTotal: number;
  companyId: number;
}
export default function PurchaseSummaryListTable({
  data,
  canDownload,
}: {
  data: Array<PurchaseSummaryListData>;
  canDownload?: boolean;
}): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { openErrorBlob } = useErrorBlob();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [query, setQuery] = useQueryParams({
    page: StringParam,
    sort: StringParam,
    order: StringParam,
  });

  const handleDownloadExcel = async (companyId: string, date: string, companyName: string) => {
    const formatDate = dayjs(date).format('YYMM');
    setIsLoading(true);
    try {
      await exportExcel(
        ECApis.paymentMonthyExportExcel(companyId, date),
        `${formatDate}_請求明細_${companyName.replaceAll(' ', '_')}`,
      );
    } catch (error) {
      openErrorBlob(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadPDF = async (companyId: string, date: string, companyName: string) => {
    const formatDate = dayjs(date).format('YYMM');
    const fileName = `${formatDate}_miim請求書_${companyName?.replaceAll(' ', '_')}`;
    setIsLoading(true);
    try {
      await exportExcel(ECApis.paymentMonthyExportPDF(companyId, date), fileName, 'pdf');
    } catch (error) {
      openErrorBlob(error);
    } finally {
      setIsLoading(false);
    }
  };

  const screenWidth = window?.innerWidth;

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'pe-none',
        columns: [
          {
            Header: '伝票番号',
            accessor: 'number',
            width: screenWidth <= 1500 ? '137' : '160',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <Link
                  to={{
                    pathname: `/ec/purchase-summary-detail/${row.original.companyId}`,
                    search: `?date=${row.original.date}&code=${row.original.code}&companyName=${row.original?.companyName}`,
                  }}
                >
                  <div
                    className="text-truncate text-start payment-advice-padding lg-fs-12px"
                    title={row.original?.code}
                  >
                    {row.original?.code}
                  </div>
                </Link>
              );
            },
          },
          {
            Header: 'レコード会社',
            accessor: 'companyName',
            width: screenWidth <= 1500 ? '171' : '292',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div
                  className="text-truncate payment-advice-padding"
                  title={row.original?.companyName}
                >
                  {row.original?.companyName}
                </div>
              );
            },
          },
          {
            Header: '請求金額',
            accessor: 'revenueIncludesTax',
            width: screenWidth <= 1500 ? '100' : '120',
            disableSortBy: true,
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div className="text-truncate text-right line-33px mr-6px">
                  {row.original.revenueIncludesTax
                    ? formatPrice(row.original.revenueIncludesTax)
                    : 0}
                </div>
              );
            },
          },
          {
            Header: '定額料金',
            accessor: 'amountCardBuyFeeTotal',
            width: screenWidth <= 1500 ? '100' : '120',
            disableSortBy: true,
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div className="text-truncate text-right line-33px mr-6px">
                  {row.original.amountCardBuyFeeTotal
                    ? formatPrice(row.original.amountCardBuyFeeTotal)
                    : 0}
                </div>
              );
            },
          },
          {
            Header: '定率料金',
            accessor: 'amountTaxFeeMoneyTotal',
            width: screenWidth <= 1500 ? '100' : '120',
            disableSortBy: true,
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div className="text-truncate text-right line-33px mr-6px">
                  {row.original.amountTaxFeeMoneyTotal
                    ? formatPrice(row.original.amountTaxFeeMoneyTotal)
                    : 0}
                </div>
              );
            },
          },
          {
            Header: 'オプション料金',
            accessor: 'amountOptionFeeTotal',
            width: screenWidth <= 1500 ? '100' : '120',
            className: 'lg-fs-11px',
            disableSortBy: true,
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div className="text-truncate text-right line-33px mr-6px">
                  {row.original.amountOptionFeeTotal
                    ? formatPrice(row.original.amountOptionFeeTotal)
                    : 0}
                </div>
              );
            },
          },
          {
            Header: '消費税',
            accessor: 'feeTax',
            width: screenWidth <= 1500 ? '100' : '120',
            disableSortBy: true,
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div className="text-truncate text-right line-33px mr-6px">
                  {row.original.feeTax ? formatPrice(row.original.feeTax) : 0}
                </div>
              );
            },
          },
          {
            Header: '請求書PDF',
            accessor: 'notificationPDF',
            width: '120',
            disableSortBy: true,
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div
                  className={`text-truncate text-center payment-advice-padding ${
                    canDownload === true ? '' : 'pe-none disable'
                  } `}
                  title={row.original?.notificationPDF}
                >
                  <Link
                    to="#"
                    onClick={() =>
                      handleDownloadPDF(
                        row.original?.companyId,
                        row.original?.date,
                        row.original?.companyName,
                      )
                    }
                  >
                    <Download className="mr-4px"></Download>DL
                  </Link>
                </div>
              );
            },
          },
          {
            Header: '明細EXCEL',
            accessor: 'detailExcel',
            width: '120',
            disableSortBy: true,
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div
                  className={`text-truncate text-center payment-advice-padding`}
                  title={row.original?.detailExcel}
                >
                  <Link
                    to="#"
                    onClick={() =>
                      handleDownloadExcel(
                        row.original?.companyId,
                        row.original?.date,
                        row.original?.companyName,
                      )
                    }
                  >
                    <Download className="mr-4px"></Download>DL
                  </Link>
                </div>
              );
            },
          },
        ],
      },
    ],
    [canDownload],
  );

  const handleManualSort = useCallback(
    (sortBy: SortMethod[]) => {
      if (sortBy.length > 0) {
        const sortOption = sortBy[0];
        setQuery({ sort: sortOption.id, order: sortOption.desc ? 'DESC' : 'ASC' });
      } else {
        setQuery({ sort: '', order: '' });
      }
    },
    [setQuery],
  );

  return (
    <div className="overflow-hidden mt-4">
      <Container fluid className="px-4">
        <SpinnerComponent isLoading={isLoading} />
        {data ? (
          <TableEC
            heightBody="calc(100vh - 250px)"
            columns={columns}
            data={data}
            getCellProps={() => ({
              className: 'p-0',
            })}
            manualSortBy
            onSort={handleManualSort}
          />
        ) : (
          ''
        )}
      </Container>
    </div>
  );
}
