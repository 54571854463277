import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import Logo from 'Static/Images/miim.png';
import LogoFooter from 'Static/Images/footer_logo.png';
import DeleteAcountVideo from './delete-account.mp4';

export default function GuideDeleteAccount(): JSX.Element {
  return (
    <div className="delete-account vh-layout">
      <div className="container p-4">
        <Col md="6" className="pt-4">
          <a href={process.env.REACT_APP_MIIM_APP || ''}>
            <Image src={Logo} width={158} alt="miim" className="footer-logo" />
          </a>
        </Col>
        <Row className="guide-block">
          <Col md="8">
            <div className="mw-600">
              <h3>Hướng dẫn cách yêu cầu xoá tài khoản và dữ liệu người dùng</h3>
              <h3 className="mt-3">
                Sau khi xóa tài khoản tất cả các thông tin như Tài khoản, Dữ liệu mua hàng cũng sẽ
                bị xóa
              </h3>

              <h3 className="mt-5">アカウント及びユーザー情報の削除をリクエストする方法</h3>
              <h3 className="mt-3 mb-4">
                アカウントを削除した後、アカウント情報や購入履歴など、全ての情報も削除されるようになります。
              </h3>
            </div>
            <div className="arrow-right"></div>
          </Col>
          <Col md="4">
            <video className="w-100" style={{ maxWidth: 400 }} controls>
              <track kind="captions" />
              <source src={DeleteAcountVideo} type="video/mp4" />
              Your browser does not support HTML video.
            </video>
          </Col>
        </Row>
      </div>
      <footer className="footer-miim">
        <div className="container">
          <div className="footer__top position-relative">
            <p className="text-prev-wrap">
              {'みなさまからのご質問を受け付けております\n以下よりお気軽にお問い合わせください'}
            </p>

            <a className="btn-contact" href={`${process.env.REACT_APP_MIIM_APP}/contact` || ''}>
              お問い合わせ&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <svg
                width="24"
                height="18"
                viewBox="0 0 24 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.2 1H20.8C22.01 1 23 1.9 23 3V15C23 16.1 22.01 17 20.8 17H3.2C1.99 17 1 16.1 1 15V3C1 1.9 1.99 1 3.2 1Z"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M23 3L12 10L1 3"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </a>
          </div>
          <div className="footer__bottom">
            <a href={process.env.REACT_APP_MIIM_APP || ''}>
              <Image src={LogoFooter} width={158} alt="miim" className="footer-logo" />
            </a>
            <ul className="footer__nav">
              <li>
                <a href={`${process.env.REACT_APP_MIIM_APP}/terms` || ''}>利用規約</a>
              </li>
              <li>
                <a href={`${process.env.REACT_APP_MIIM_APP}/privacy` || ''}>プライバシーポリシー</a>
              </li>
              <li>
                <a href={`${process.env.REACT_APP_MIIM_APP}/company` || ''}>運営会社</a>
              </li>
            </ul>
            <div className="footer__copy">Copyright (C) 2021 BootRock All Rights Reserved.</div>
          </div>
        </div>
      </footer>
    </div>
  );
}
