/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import brAxios from 'Api/Interceptors';
import { AxiosResponse } from 'axios';

export interface ISaleChannel {
  id: number;
  name: string;
}

export const SaleChannelApi = {
  getSaleChannels: () => brAxios.get('/suggestion/sale-channels'),
  getSaleChannelsEc: (
    page: number,
    limit: number,
    productId?: number,
    companyId?: number,
    query?: string,
  ) =>
    brAxios.get(
      `/suggestion/sale-channels?page=${page}&limit=${limit}${
        productId ? `'&productId='${productId}` : ''
      }${companyId ? `'&companyId='${companyId}` : ''}${
        query ? `'&query='${query}` : ''
      }&showAll=1`,
    ),
  getSaleChannelEcDetail: (id: string) => brAxios.get(`/ec-site/sale-channel/${id}`),
  getTableSaleChannels: () => brAxios.get('/bootrock-company/sale-channel'),
  getSaleAccount: ({
    page,
    limit,
    sort,
    order,
  }: {
    page: number | string;
    limit: number;
    sort?: string;
    order?: string;
  }): Promise<AxiosResponse> =>
    brAxios.get(
      `/ec-site/sale-channel/accounts?page=${page}&limit=${limit}&sort=${
        sort || 'companyName'
      }&order=${order || 'DESC'}`,
    ),
  createSaleChannel: (name: string) =>
    brAxios.post('/bootrock-company/sale-channel', { name: name }),
  createSaleChannelEc: (param: any) => brAxios.post(`/ec-site/sale-channel/`, param),
  updateSaleChannelEc: (id: string, param: any) =>
    brAxios.put(`/ec-site/sale-channel/${id}`, param),
  updateSaleChannel: (param: ISaleChannel) =>
    brAxios.put(`/bootrock-company/sale-channel/${param.id}`, { name: param.name }),
  deleteSaleChannel: (id: number) => brAxios.delete(`/bootrock-company/sale-channel/${id}`),
};
