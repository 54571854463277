import brAxios from 'Api/Interceptors';
import { IDataFormTokutenUploads } from 'Types/Common';
interface IQueryParams {
  page?: number;
  limit?: number;
  artistId?: string;
  titleId?: string;
  variationId?: string;
  time?: string;
  companyId?: string;
  gmt?: number;
  startDate?: string;
  endDate?: string;
}

export const PromotionManagerApi = {
  createPromotion: (formData: IDataFormTokutenUploads) =>
    brAxios.post('/bootrock-company/digital-tokutens', formData),
  getAllPromotions: (params: IQueryParams) => {
    const response = brAxios.get(`/bootrock-company/digital-tokutens`, { params });
    return response;
  },
  getDetailPromotion: (digitalID: string) => {
    const responseDetail = brAxios.get(`/bootrock-company/digital-tokutens/${digitalID}`);
    return responseDetail;
  },
  exportExcel: (params: IQueryParams) => {
    const response = brAxios.get(`/bootrock-company/digital-tokutens/export/download`, {
      params,
      responseType: 'blob',
    });
    return response;
  },
};
