const FormTypeOptions = [
  {
    label: 'レコード会社',
    checked: true,
    value: 10,
  },
  {
    label: 'miim EC',
    checked: false,
    value: 0,
  },
  {
    label: '外部EC',
    checked: false,
    value: 1,
  },
];

export { FormTypeOptions };
