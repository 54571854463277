import brAxios from 'Api/Interceptors';
import { AxiosResponse } from 'axios';

interface IQueryParams {
  startDate?: string;
  endDate?: string;
  titleIds?: string | number;
  artistIds?: string | number;
  variationIds?: string | number;
  isOption?: string | number;
  companyId?: string | number;
}

export interface IDashboard {
  saleNumberTotal: number;
  saleRate: number;
  enjoyNumberTotal: number;
  enjoyRate: number;
  giftNumberTotal: number;
  giftRate: number;
  auditionNumberTotal: number;
  auditionRate: number;
  items: IDashboardChart[];
}

export interface IDashboardChart {
  date: any;
  saleNumber: number;
  enjoyNumber: number;
  giftNumber: number;
  auditionNumber: number;
}
export const DashboardApi = {
  getDashboard: ({
    companyId,
    artistIds,
    titleIds,
    variationIds,
    startDate,
    endDate,
  }: IQueryParams): Promise<AxiosResponse> => {
    const response = brAxios.get(
      `/bootrock-company/dashboard?artistIds=${artistIds ?? ''}&titleIds=${
        titleIds ?? ''
      }&variationIds=${variationIds ?? ''}&startDate=${startDate ?? ''}&endDate=${endDate ?? ''}${
        companyId ? `&companyId=${companyId}` : ''
      }`,
    );
    return response;
  },

  exportExcel: (params: IQueryParams): Promise<AxiosResponse> => {
    const response = brAxios.get('/bootrock-company/dashboard/export-excel', {
      params,
      responseType: 'blob',
    });
    return response;
  },
};
