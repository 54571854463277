import React, { PropsWithChildren, ReactNode } from 'react';
import {
  Row,
  Col,
  FormGroup,
  FormGroupProps,
  FormLabelProps,
  ColProps,
  FormLabel,
} from 'react-bootstrap';

interface FormBlockProps extends Omit<FormGroupProps, 'as'> {
  label: string;
  value?: string | null;
  valueClassName?: string | null;
  valueProps?: Partial<ColProps>;
  valueRender?: JSX.Element | ReactNode;
  labelClassName?: string | null;
  labelProps?: Partial<FormLabelProps>;
}

function FormBlock({
  label,
  value,
  valueClassName,
  valueProps,
  valueRender,
  labelClassName,
  labelProps,
  ...props
}: PropsWithChildren<FormBlockProps>) {
  return (
    <FormGroup as={Row} {...props}>
      <FormLabel column md="3" className={`fw-bold ${labelClassName}`} {...labelProps}>
        {label}
      </FormLabel>
      <Col md="9" {...valueProps}>
        {valueRender ?? (
          <div className={`border d-flex ps-2 align-items-center iblock ${valueClassName}`}>
            {value}
          </div>
        )}
      </Col>
    </FormGroup>
  );
}
export default React.memo(FormBlock);
