import React from 'react';
import { Container } from 'react-bootstrap';
import BrLogo from 'Static/Images/br-logo.png';
import 'Page/TermAndPolicy/index.scss';

export default function Policy(): JSX.Element {
  return (
    <div className="position-relative">
      <Container className="d-flex flex-column justify-content-center align-items-center min-vh-100">
        <h1 className="text-center mb-3">プライバシーポリシー</h1>
        <p>
          株式会社INDETAIL（以下「当社」といいます。）は、お客様の個人情報保護の重要性について認識し、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）を遵守すると共に、以下のプライバシーポリシー（以下「本プライバシーポリシー」といいます。）に従い、適切な取扱い及び保護に努めます。本プライバシーポリシーと個別規約において矛盾が発生する場合は個別規約が優先されます。
        </p>
        <div className="w-100 termsAndPolicy">
          <ol>
            <li>
              <div className="mb-3 mt-4 fw-bold">個人情報の定義</div>
              <p>
                本プライバシーポリシーにおいて、個人情報とは、個人情報保護法第2条第1項により定義された個人情報、すなわち、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます。）を意味するものとします。
              </p>
            </li>

            <li>
              <div className="mb-3 mt-4 fw-bold">個人情報の利用目的</div>
              <p>当社は、お客様の個人情報を、以下の目的で利用致します。</p>
              <ol>
                <li>当社サービスの提供のため</li>
                <li>当社サービスに関するご案内、お問い合わせ等への対応のため</li>
                <li>当社の商品、サービス等のご案内のため</li>
                <li>
                  当社サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため
                </li>
                <li>当社サービスに関する規約等の変更などを通知するため</li>
                <li>当社サービスの改善、新サービスの開発等に役立てるため</li>
                <li>
                  当社サービスに関連して、個別を識別できない形式に加工した統計データを作成するため
                </li>
                <li>その他、上記利用目的に付随する目的のため</li>
              </ol>
            </li>

            <li>
              <div className="mb-3 mt-4 fw-bold">個人情報利用目的の変更</div>
              <p>
                当社は、個人情報の利用目的を相当の関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合にはお客様に通知又は公表します。
              </p>
            </li>

            <li>
              <div className="mb-3 mt-4 fw-bold">個人情報利用の制限</div>
              <p>
                当社は、個人情報保護法その他の法令に基づき開示が認められる場合、または下記の場合を除いて第三者にお客様の個人情報を提供しません。
              </p>
              <ol>
                <li>ご本人が事前に同意した場合</li>
                <li>法令に基づく場合</li>
                <li>
                  お客様が本サービスの利用規約に違反し、当社の権利、財産やサービス等を保護するために、個人情報を公開せざるを得ないと判断するに足る十分な根拠がある場合
                </li>
                <li>
                  人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
                </li>
                <li>
                  公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
                </li>
                <li>
                  国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
                </li>
                <li>
                  合併、会社分割、営業譲渡その他の事由によって個人情報の提供を含む当社の事業の継承が行われる場合
                </li>
              </ol>
            </li>
            <li>
              <div className="mb-3 mt-4 fw-bold">個人情報の適正な取得</div>
              <p>当社は、適正に個人情報を取得し、偽りその他不正の手段により取得しません。</p>
            </li>
          </ol>
        </div>
      </Container>
      <div className="w-100 text-center p-2">
        <img src={BrLogo} alt="bg logo" className="w-max-content" />
        <p className="mt-2 fs-7">Copyright ©2021 BootRock Inc. All rights reserved.</p>
      </div>
    </div>
  );
}
