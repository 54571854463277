/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { Row } from 'react-bootstrap';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import TableCommon from 'Components/Common/Table/TableCommon';
import { Cell } from 'react-table';
import {
  getComposerName,
  getInformationFee,
  getIvtType,
  getMusicianName,
  getRequestCount,
} from 'Utils/Table';

export default function TableReportVideo({
  dataTable,
  loading,
  mediaType = '歌詞',
}: {
  dataTable: any;
  loading: boolean;
  mediaType?: string;
}): JSX.Element {
  const renderValue = (value: number | string) => {
    return value ? <div className="text-break text-start">{value}</div> : <></>;
  };

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'pe-none',
        columns: [
          {
            Header: 'インターフェイスキーコード',
            accessor: 'interfaceKeyCode',
            width: '250',
            Cell: ({ row }: Cell<any>) => {
              const { interfaceKeyCode } = row.original;
              return <div className="text-break text-start">{interfaceKeyCode}</div>;
            },
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => {
              const defaultVal = desc ? 'A' : 'Z';
              const textA = rowA?.original?.interfaceKeyCode || '';
              const textB = rowB?.original?.interfaceKeyCode || '';
              return textA < textB ? -1 : textA > textB ? 1 : defaultVal;
            },
          },
          {
            Header: 'コンテンツ区分',
            accessor: 'contentType',
            Cell: ({ row }: Cell<any>) => {
              const { contentType } = row.original;
              return renderValue(contentType);
            },
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => {
              const defaultVal = desc ? 'A' : 'Z';
              const textA = rowA?.original?.contentType || '';
              const textB = rowB?.original?.contentType || '';
              return textA < textB ? -1 : textA > textB ? 1 : defaultVal;
            },
          },
          {
            Header: 'コンテンツ枝番',
            accessor: 'contentCode',
            Cell: ({ row }: Cell<any>) => {
              const { contentCode } = row.original;
              return renderValue(contentCode);
            },
          },
          {
            Header: 'メドレー区分',
            accessor: 'medleyType',
            Cell: ({ row }: Cell<any>) => {
              const { medleyType } = row.original;
              return renderValue(medleyType);
            },
          },
          {
            Header: 'メドレー枝番',
            accessor: 'medleyCode',
            Cell: ({ row }: Cell<any>) => {
              const { medleyType, medleyCode } = row.original;
              return renderValue(medleyType === 'M' ? medleyCode : '000');
            },
          },
          {
            Header: 'コレクトコード',
            accessor: 'collectCode',
            Cell: () => {
              return renderValue('');
            },
          },
          {
            Header: '作品コード',
            accessor: 'jasracCode',
            width: '200',
            Cell: ({ row }: Cell<any>) => {
              const { contentType, jasracCode } = row.original;
              return renderValue(contentType === 'Q' && !jasracCode ? '' : jasracCode);
            },
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => {
              const defaultVal = desc ? 'A' : 'Z';
              const textA =
                rowA?.original?.contentType === 'Q' && !rowA?.original?.jasracCode
                  ? ''
                  : rowA?.original?.jasracCode || '';
              const textB =
                rowB?.original?.contentType === 'Q' && !rowB?.original?.jasracCode
                  ? ''
                  : rowB?.original?.jasracCode || '';
              return textA < textB ? -1 : textA > textB ? 1 : defaultVal;
            },
          },
          {
            Header: '原題名',
            accessor: 'songName',
            Cell: ({ row }: Cell<any>) => {
              const { songName } = row.original;
              return <div className="text-break text-start">{songName}</div>;
            },
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => {
              const defaultVal = desc ? 'A' : 'Z';
              const textA = rowA?.original?.songName?.trim() || '';
              const textB = rowB?.original?.songName?.trim() || '';
              return textA < textB ? -1 : textA > textB ? 1 : defaultVal;
            },
          },
          {
            Header: '副題・邦題',
            accessor: 'subTitle',
            Cell: ({ row }: Cell<any>) => {
              const { subTitle } = row.original;
              return renderValue(subTitle);
            },
          },
          {
            Header: '作詞者名',
            accessor: 'musicianName',
            Cell: ({ row }: Cell<any>) => {
              return <div className="text-break text-start">{getMusicianName(row)}</div>;
            },
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => {
              const defaultVal = desc ? 'A' : 'Z';
              const textA = getMusicianName(rowA)?.trim();
              const textB = getMusicianName(rowB)?.trim();
              return textA < textB ? -1 : textA > textB ? 1 : defaultVal;
            },
          },
          {
            Header: '補作詞・訳詞者名',
            accessor: 'translatorName',
            Cell: ({ row }: Cell<any>) => {
              const { translatorName } = row.original;
              return renderValue(translatorName);
            },
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => {
              const defaultVal = desc ? 'A' : 'Z';
              const textA = rowA?.original?.translatorName?.trim() || '';
              const textB = rowB?.original?.translatorName?.trim() || '';

              return textA < textB ? -1 : textA > textB ? 1 : defaultVal;
            },
          },
          {
            Header: '作曲者名',
            accessor: 'composerName',
            width: '250',
            Cell: ({ row }: Cell<any>) => {
              return <div className="text-break text-start">{getComposerName(row)}</div>;
            },
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => {
              const defaultVal = desc ? 'A' : 'Z';
              const textA = getComposerName(rowA)?.trim();
              const textB = getComposerName(rowB)?.trim();
              return textA < textB ? -1 : textA > textB ? 1 : defaultVal;
            },
          },
          {
            Header: '編曲者名',
            accessor: 'arrangerName',
            Cell: ({ row }: Cell<any>) => {
              const { arrangerName } = row.original;
              return renderValue(arrangerName);
            },
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => {
              const defaultVal = desc ? 'A' : 'Z';
              const textA = rowA?.original?.arrangerName?.trim() || '';
              const textB = rowB?.original?.arrangerName?.trim() || '';

              return textA < textB ? -1 : textA > textB ? 1 : defaultVal;
            },
          },
          {
            Header: 'アーティスト名',
            accessor: 'artistName',
            Cell: ({ row }: Cell<any>) => {
              const { artistName, contentType, contentCode } = row.original;
              return renderValue(contentType === 'Q' && contentCode === '000' ? '' : artistName);
            },
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => {
              const defaultVal = desc ? 'A' : 'Z';
              const textA = rowA?.original?.artistName?.trim() || '';
              const textB = rowB?.original?.artistName?.trim() || '';
              return textA < textB ? -1 : textA > textB ? 1 : defaultVal;
            },
          },
          {
            Header: '情報料（税抜）',
            accessor: mediaType === '歌詞' ? 'lyricInformationFee' : 'informationFee',
            Cell: ({ row }: Cell<any>) => {
              return renderValue(getInformationFee(row, mediaType));
            },
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => {
              const defaultVal = desc ? 'A' : 'Z';
              const textA = +getInformationFee(rowA, '歌詞');
              const textB = +getInformationFee(rowB, '歌詞');
              return textA < textB ? -1 : textA > textB ? 1 : defaultVal;
            },
          },
          {
            Header: 'ＩＶＴ区分',
            accessor: 'ivtType',
            Cell: ({ row }: Cell<any>) => {
              return renderValue(getIvtType(row));
            },
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => {
              const defaultVal = desc ? 'A' : 'Z';
              const textA = getIvtType(rowA);
              const textB = getIvtType(rowB);
              return textA < textB ? -1 : textA > textB ? 1 : defaultVal;
            },
          },
          {
            Header: '原詞訳詞区分',
            accessor: 'lyricsClassifyType',
            Cell: ({ row }: Cell<any>) => {
              const { lyricsClassifyType } = row.original;
              return renderValue(lyricsClassifyType);
            },
          },
          {
            Header: 'IL区分',
            accessor: 'originalType',
            Cell: ({ row }: Cell<any>) => {
              const { originalType, contentType, contentCode } = row.original;
              // return renderValue(contentType === 'Q' && !jasracCode ? '' : originalType);

              // Bug #79737
              return renderValue(contentType === 'Q' && contentCode === '000' ? '' : originalType);
            },
          },
          {
            Header: 'リクエスト回数',
            accessor: 'requestCount',
            Cell: ({ row }: Cell<any>) => {
              return <p>{getRequestCount(row)}</p>;
            },
            sortType: (rowA: any, rowB: any, columnId: string, desc: boolean) => {
              const defaultVal = desc ? 'A' : 'Z';
              const textA = +getRequestCount(rowA);
              const textB = +getRequestCount(rowB);
              return textA < textB ? -1 : textA > textB ? 1 : defaultVal;
            },
          },
        ],
      },
    ],
    [mediaType],
  );

  return (
    <Row className="px-3 mt-3">
      <SpinnerComponent isLoading={loading} />
      <TableCommon
        noDataText="検索結果がありません。"
        classTable="table-jarsac"
        data={dataTable}
        columns={columns}
        hiddenScroll={false}
      />
    </Row>
  );
}
