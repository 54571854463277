import brAxios from 'Api/Interceptors';

import { getCurrentTimeZone } from 'Utils/DateTime';
import { AxiosResponse } from 'axios';

export interface QuerySaleProductsParams {
  limit?: number;
  page?: number;
  type?: null | string | undefined;
  artistId?: null | string | undefined;
  titleId?: null | string | undefined;
  campaignId?: null | string | undefined;
  statusId?: null | string | undefined;
  startDate?: null | string | undefined;
  endDate?: null | string | undefined;
  saleIds?: null | string | undefined;
  sort?: string;
  order?: string;
  artistIds?: null | string | undefined;
  titleIds?: null | string | undefined;
  campaignIds?: null | string | undefined;
}

export interface QueryPurchaseParams {
  limit?: number;
  page?: number;
  orderNumber?: null | string | undefined;
  miimId?: null | string | undefined;
  realName?: null | string | undefined;
  email?: null | string | undefined;
  artistId?: null | string | undefined;
  titleId?: null | string | undefined;
  saleId?: null | string | undefined;
  variationId?: null | string | undefined;
  campaignId?: null | string | undefined;
  startDate?: null | string | undefined;
  endDate?: null | string | undefined;
  sort?: string;
  order?: string;
  artistIds?: null | string | undefined;
  titleIds?: null | string | undefined;
  variationIds?: null | string | undefined;
  campaignIds?: null | string | undefined;
}

export interface ParamBulkEmail {
  subject?: string;
  body?: string;
  operators?: Array<string>;
  isTest: boolean;
}

const timeZoneOffSet = getCurrentTimeZone() * 60;

export const ECApis = {
  getSaleProducts: ({
    page,
    limit,
    type,
    artistId,
    titleId,
    campaignId,
    saleIds,
    statusId,
    startDate,
    endDate,
    sort,
    order,
    artistIds,
    titleIds,
    campaignIds,
  }: QuerySaleProductsParams): Promise<AxiosResponse> =>
    brAxios.get(
      `/ec-site/products/ec-sales-product-list?page=${page ?? 1}&limit=${limit ?? 50}${
        type ? `&type=${type}` : ''
      }${artistId ? `&artistId=${artistId}` : ''}${titleId ? `&titleId=${titleId}` : ''}${
        campaignId ? `&campaignId=${campaignId}` : ''
      }${statusId ? `&status=${statusId}` : ''}${startDate ? `&startDate=${startDate}` : ''}${
        endDate ? `&endDate=${endDate}` : ''
      }${saleIds ? `&channelId=${saleIds}` : ''}${sort ? `&sort=${sort}` : ''}&order=${
        order || 'DESC'
      }${artistIds ? `&artistIds=${artistIds}` : ''}${titleIds ? `&titleIds=${titleIds}` : ''}${
        campaignIds ? `&campaignIds=${campaignIds}` : ''
      }`,
    ),
  saleProductDownloadExcel: ({
    type,
    artistId,
    titleId,
    campaignId,
    statusId,
    startDate,
    saleIds,
    endDate,
  }: QuerySaleProductsParams): Promise<AxiosResponse> => {
    const curTimeZone = getCurrentTimeZone();
    return brAxios.get(
      `/ec-site/products/export-excel?${type ? `&type=${type}` : ''}${
        artistId ? `&artistId=${artistId}` : ''
      }${titleId ? `&titleId=${titleId}` : ''}${campaignId ? `&campaignId=${campaignId}` : ''}${
        statusId ? `&status=${statusId}` : ''
      }${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}${
        saleIds ? `&channelId=${saleIds}` : ''
      }${`&timezone=${curTimeZone}`}`,
      {
        responseType: 'blob',
      },
    );
  },
  getSaleProductDetail: (id: string | number): Promise<AxiosResponse> =>
    brAxios.get(`/ec-site/products/ec-sales-product-detail/${id}`),
  updateSaleProductDetail: ({
    id,
    params,
  }: {
    id: string | number;
    params: any;
  }): Promise<AxiosResponse> =>
    brAxios.put(`/ec-site/products/ec-sales-product-detail/${id}`, params),
  changeAccount: (param: any): Promise<AxiosResponse> => brAxios.post(`/run-as`, param),
  getPurchase: ({
    limit,
    page,
    orderNumber,
    miimId,
    realName,
    startDate,
    endDate,
    artistId,
    titleId,
    saleId,
    variationId,
    campaignId,
    sort,
    order,
    artistIds,
    titleIds,
    variationIds,
    campaignIds,
  }: QueryPurchaseParams): Promise<AxiosResponse> =>
    brAxios.get(
      `/ec-site/purchase?page=${page ?? 1}&limit=${limit ?? 50}${
        orderNumber ? `&orderNumber=${orderNumber}` : ''
      }${miimId ? `&miimId=${miimId}` : ''}${realName ? `&realName=${realName}` : ''}${
        saleId ? `&ecCompanyId=${saleId}` : ''
      }${artistId ? `&artistId=${artistId}` : ''}${titleId ? `&titleId=${titleId}` : ''}${
        variationId ? `&variationId=${variationId}` : ''
      }${campaignId ? `&campaignId=${campaignId}` : ''}${
        startDate ? `&startDate=${startDate}` : ''
      }${endDate ? `&endDate=${endDate}` : ''}${sort ? `&sort=${sort}` : ''}&order=${
        order || 'DESC'
      }${artistIds ? `&artistIds=${artistIds}` : ''}${titleIds ? `&titleIds=${titleIds}` : ''}${
        variationIds ? `&variationIds=${variationIds}` : ''
      }${campaignIds ? `&campaignIds=${campaignIds}` : ''}`,
    ),
  getPurchaseDetail: (id: string | number): Promise<AxiosResponse> =>
    brAxios.get(`/ec-site/purchase/${id}`),
  purchaseDownloadExcel: ({
    saleId,
    orderNumber,
    miimId,
    realName,
    artistId,
    titleId,
    variationId,
    campaignId,
    startDate,
    endDate,
  }: QueryPurchaseParams): Promise<AxiosResponse> => {
    const curTimeZone = getCurrentTimeZone();
    return brAxios.get(
      `/ec-site/purchase/export-excel?${saleId ? `&ecCompanyId=${saleId}` : ''}${
        orderNumber ? `&orderNumber=${orderNumber}` : ''
      }${miimId ? `&miimId=${miimId}` : ''}${realName ? `&realName=${realName}` : ''}${
        artistId ? `&artistId=${artistId}` : ''
      }${titleId ? `&titleId=${titleId}` : ''}${variationId ? `&variationId=${variationId}` : ''}${
        campaignId ? `&campaignId=${campaignId}` : ''
      }${startDate ? `&startDate=${startDate}` : ''}${
        endDate ? `&endDate=${endDate}` : ''
      }${`&timezone=${curTimeZone}`}`,
      {
        responseType: 'blob',
      },
    );
  },
  purchaseRefund: ({ purchaseId }: { purchaseId: string | number }): Promise<AxiosResponse> =>
    brAxios.put(`/ec-site/purchase/${purchaseId}/refund`),

  purchaseRefundAll: ({
    channelOrderId,
  }: {
    channelOrderId: string | number;
  }): Promise<AxiosResponse> => brAxios.put(`/ec-site/purchase/${channelOrderId}/refund-all`),

  purchaseUpdateDescription: ({
    id,
    params,
  }: {
    id: string | number;
    params: any;
  }): Promise<AxiosResponse> => brAxios.put(`/ec-site/purchase/${id}/update-description`, params),

  sendBlukEmail: (params: ParamBulkEmail): Promise<AxiosResponse> =>
    brAxios.post(`/ec-site/mail-template/send-mail-to-operator`, params),

  getSections: () => brAxios.get('/record-company/sections'),

  getPaymentMonthly: (
    date: string,
    order: string | null | undefined,
    sort: string | null | undefined,
  ): Promise<AxiosResponse> =>
    brAxios.get(
      `/ec-site/statistic/payment-monthly?date=${date}&page=1&limit=100${
        order ? `&order=${order}` : ''
      }${sort ? `&sort=${sort}` : ''}`,
    ),

  getPaymentMonthlyDetail: (
    id: string,
    page: number | undefined | null,
    limit: number,
    date: string | undefined | null,
    order: string | undefined | null,
    sort: string | undefined | null,
  ): Promise<AxiosResponse> =>
    brAxios.get(
      `/ec-site/statistic/payment-monthly/${id}?date=${date}${
        page ? `&page=${page}` : '&page=1'
      }&limit=${limit}${order ? `&order=${order}` : ''}${sort ? `&sort=${sort}` : ''}`,
    ),

  getPaymentSummaryDate: (): Promise<AxiosResponse> =>
    brAxios.get('/ec-site/statistic/payment-monthly/group-date-only'),

  getTranferGroupDate: (): Promise<AxiosResponse> =>
    brAxios.get(`/ec-site/transfers/group-date-only`),

  getTranferList: (
    year: string,
    order: string | undefined | null,
    sort: string | undefined | null,
  ): Promise<AxiosResponse> =>
    brAxios.get(
      `/ec-site/transfers?year=${year}${order ? `&order=${order}` : ''}${
        sort ? `&sort=${sort}` : ''
      }`,
    ),

  getTranferDetail: (
    id: string,
    page: number | undefined | null,
    limit: number,
  ): Promise<AxiosResponse> =>
    brAxios.get(`/ec-site/transfers/${id}${page ? `?page=${page}&limit=${limit}` : ''}`),

  paymentMonthyExportZipFile: (date: string | null | undefined): Promise<AxiosResponse> =>
    brAxios.get(
      `ec-site/statistic/payment-monthly/zip-file?date=${date}&timezoneOffset=${timeZoneOffSet}`,
      {
        responseType: 'blob',
      },
    ),

  paymentMonthyExportExcel: (
    companyId: string,
    date: string | null | undefined,
  ): Promise<AxiosResponse> =>
    brAxios.get(
      `ec-site/statistic/payment-monthly/${companyId}/export-excel?date=${date}&timezoneOffset=${timeZoneOffSet}`,
      {
        responseType: 'blob',
      },
    ),

  paymentMonthyExportPDF: (companyId: string, date: string): Promise<AxiosResponse> =>
    brAxios.get(
      `ec-site/statistic/payment-monthly/${companyId}/export-pdf?date=${date}&timezoneOffset=${timeZoneOffSet}`,
      {
        responseType: 'blob',
      },
    ),

  tranferDetailExportExcel: (transferId: string, paymentDate: string): Promise<AxiosResponse> =>
    brAxios.get(
      `ec-site/transfers/${transferId}/export-excel?date=${paymentDate}&timezoneOffset=${timeZoneOffSet}`,
      {
        responseType: 'blob',
      },
    ),

  tranferDetailExportPDF: (transferId: string, paymentDate: string): Promise<AxiosResponse> =>
    brAxios.get(
      `ec-site/transfers/${transferId}/export-pdf?date=${paymentDate}&timezoneOffset=${timeZoneOffSet}`,
      {
        responseType: 'blob',
      },
    ),
};
