import React from 'react';

const CopyPasteIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 32 32">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#005CD9"
          fillRule="nonzero"
          d="M4,8 L4,28 L24,28 L24,8 L4,8 Z M4,6 L24,6 C25.1045695,6 26,6.8954305 26,8 L26,28 C26,29.1045695 25.1045695,30 24,30 L4,30 C2.8954305,30 2,29.1045695 2,28 L2,8 C2,6.8954305 2.8954305,6 4,6 Z"
        />
        <path
          fill="#005CD9"
          fillRule="nonzero"
          d="M8,6 L6,6 L6,4 C6,2.8954305 6.8954305,2 8,2 L28,2 C29.1045695,2 30,2.8954305 30,4 L30,24 C30,25.1045695 29.1045695,26 28,26 L26,26 L26,24 L28,24 L28,4 L8,4 L8,6 Z"
        />
      </g>
    </svg>
  );
};
export default CopyPasteIcon;
