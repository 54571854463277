import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { TableList } from 'Components/Page/RecordCompanyList';
import Tabs from 'Components/Common/EC/Tabs';
import Filter from 'Components/Common/Filter/Filter';
import { NewRecordCompany } from 'Components/Page/RecordCompanyList';

export default function RecordCompany(): JSX.Element {
  const btnGroupOptions = [{ name: 'レコード会社リスト' }, { name: 'アカウント作成' }];
  const [tabActive, setTabActive] = useState(btnGroupOptions[0].name);

  return (
    <Container fluid>
      <Filter
        filterClass={`shadow-sm text-end ${
          tabActive === btnGroupOptions[1].name ? 'visually-hidden' : ''
        }`}
      >
        <Tabs
          options={btnGroupOptions}
          tabActive={tabActive}
          onChange={setTabActive}
          btnClass="tab-width"
        />
      </Filter>
      <div>
        {tabActive === btnGroupOptions[0].name ? (
          <TableList />
        ) : (
          <NewRecordCompany onChangeTab={() => setTabActive(btnGroupOptions[0].name)} />
        )}
      </div>
    </Container>
  );
}
