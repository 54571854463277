import React from 'react';
import { Row } from 'react-bootstrap';
import Filter from 'Components/Common/Filter/Filter';
import CDSearch from 'Components/Page/CD/CDSearch';

export default function CDFilter(): JSX.Element {
  return (
    <div>
      <Filter filterClass="shadow-sm cd-search px-0">
        <Row>
          <CDSearch />
        </Row>
      </Filter>
    </div>
  );
}
