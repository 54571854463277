import { TableGroup, TableColumns } from 'Components/Common/Table/TableGroup';

import React, { useCallback, useMemo } from 'react';
import { formatCurrency } from 'Utils/Numbers';
import { COL_END_CLASS } from 'Constant';
import AliTable, { AliColumn, AliSortType } from 'Components/Common/Table/AliTable';

const TableDailyAccount = ({
  dataTotalByMonth,
  dataSource,
  saleChannels,
  onSort,
}: {
  dataTotalByMonth: any[];
  dataSource: any[];
  saleChannels: any[];
  onSort?: (sortingState: AliSortType[]) => void;
}): JSX.Element => {
  const validSaleChannels = useMemo(() => {
    const notValidIndex: number[] = [];

    if (!!dataTotalByMonth && !!dataTotalByMonth[0]) {
      Object.keys(dataTotalByMonth[0]).forEach((dataTotal: any) => {
        const dataTotalIndex = dataTotal.replace(/total/g, '');

        if (!isNaN(dataTotalIndex)) {
          if (
            !dataTotalByMonth[0][`total${dataTotalIndex}`] &&
            !dataTotalByMonth[0][`totalMoney${dataTotalIndex}`]
          ) {
            notValidIndex.push(Number(dataTotalIndex));
          }
        }
      });
    }

    if (notValidIndex.length > 0)
      return saleChannels.map((sale, index) => {
        if (notValidIndex.includes(index)) {
          return null;
        }

        return sale;
      });

    return saleChannels;
  }, [saleChannels, dataTotalByMonth]);

  const renderColumnSaleChannelDay = useCallback(
    () =>
      [
        {
          name: '販売日',
          width: 100,
          code: 'purchaseDate',
          features: { sortable: true },
          lock: false,
          align: 'right',
          render: (value: any) => {
            return <div className="p-0">{value}</div>;
          },
        },
      ] as AliColumn[],
    [],
  );

  const renderColumnSaleChannel = useCallback(
    (index) => [
      {
        columns: [
          {
            name: '',
            text: '販売数',
            width: 70,
            dataIndex: `total${index}`,
            className: 'mw-150 text-truncate',
            render: ({ item }: { item: any }) => {
              return (
                <div className={`${COL_END_CLASS}`}>
                  {formatCurrency(item[`total${index}`] || 0)}
                </div>
              );
            },
          },
          {
            name: '',
            text: '販売金額',
            width: 80,
            dataIndex: `totalMoney${index}`,
            className: 'mw-150 text-truncate',
            render: ({ item }: { item: any }) => {
              return (
                <div className={`${COL_END_CLASS}`}>
                  {formatCurrency(item[`totalMoney${index}`] || 0)}
                </div>
              );
            },
          },
        ],
      },
    ],
    [],
  );

  const renderColumnSaleChannelAll = useCallback(
    () => [
      {
        columns: [
          {
            name: '',
            text: '販売数',
            width: 70,
            dataIndex: `total`,
            className: 'mw-150 text-truncate',
            render: ({ item }: { item: any }) => {
              return <div className={`${COL_END_CLASS}`}>{formatCurrency(item[`total`] || 0)}</div>;
            },
          },
          {
            name: '',
            text: '販売金額',
            width: 80,
            dataIndex: `totalMoney`,
            className: 'mw-150 text-truncate',
            render: ({ item }: { item: any }) => {
              return (
                <div className={`${COL_END_CLASS}`}>{formatCurrency(item[`totalMoney`] || 0)}</div>
              );
            },
          },
        ],
      },
    ],
    [],
  );

  const renderSaleChannel = useCallback(
    (index) =>
      [
        {
          columns: [
            {
              text: '販売数合計',
              width: 80,
              dataIndex: `total${index}`,
              className: 'mw-150 text-truncate',
              render: ({ item }: { item: any }) => {
                return (
                  <div className={`${COL_END_CLASS}`}>
                    {formatCurrency(item[`total${index}`] || 0)}
                  </div>
                );
              },
            },
            {
              text: '販売金額合計',
              width: 80,
              dataIndex: `totalMoney${index}`,
              className: 'mw-150 text-truncate',
              render: ({ item }: { item: any }) => {
                return (
                  <div className={`${COL_END_CLASS}`}>
                    {formatCurrency(item[`totalMoney${index}`] || 0)}
                  </div>
                );
              },
            },
          ],
        },
      ] as TableColumns[],
    [],
  );

  const renderSaleChannelAll = useCallback(
    () =>
      [
        {
          columns: [
            {
              text: '販売数合計',
              width: 80,
              dataIndex: `total`,
              className: 'mw-150 text-truncate',
              render: ({ item }: { item: any }) => {
                return (
                  <div className={`${COL_END_CLASS}`}>{formatCurrency(item[`total`] || 0)}</div>
                );
              },
            },
            {
              text: '販売金額合計',
              width: 80,
              dataIndex: `totalMoney`,
              className: 'mw-150 text-truncate',
              render: ({ item }: { item: any }) => {
                return (
                  <div className={`${COL_END_CLASS}`}>
                    {formatCurrency(item[`totalMoney`] || 0)}
                  </div>
                );
              },
            },
          ],
        },
      ] as TableColumns[],
    [],
  );

  return (
    <>
      {Array.isArray(validSaleChannels) &&
      validSaleChannels.length > 0 &&
      !!dataTotalByMonth[0]?.['total'] &&
      !!dataTotalByMonth[0]?.['totalMoney'] ? (
        <div className="overflow-auto mt-3 d-flex">
          {/* <div className="bg-dark text-white text-center d-flex align-items-center fw-bold px-4">
        販売
      </div> */}
          <div className="me-3" style={{ marginTop: '130px' }}>
            <AliTable
              wrapperClass="table-ali_dailyAccount w-100px"
              dataSource={dataSource}
              columns={renderColumnSaleChannelDay()}
              noDataText={null}
              // heightBody="calc(100vh - 450px)"
              onSort={onSort}
            />
            {/* <TableGroup
          dataSource={dataSource}
          tableColumns={renderColumnSaleChannelDay()}
          //   onSort={onSort}
        /> */}
          </div>
          {validSaleChannels.map((sale, index) => (
            <>
              {sale && (
                <div key={index}>
                  <div className="me-3">
                    <div className="text-center d-flex align-items-center text-truncate fw-bold">
                      {sale.channelName}
                    </div>
                    <TableGroup
                      noContentText={null}
                      dataSource={dataTotalByMonth}
                      tableColumns={renderSaleChannel(index)}
                    />
                  </div>
                  <div className="mt-3 me-3">
                    <TableGroup
                      dataSource={dataSource}
                      tableColumns={renderColumnSaleChannel(index)}
                      noContentText={null}
                      //   onSort={onSort}
                    />
                  </div>
                </div>
              )}
            </>
          ))}

          <div className="me-3">
            <div className="text-center d-flex align-items-center text-truncate fw-bold">
              全チャネル合計
            </div>
            <TableGroup
              noContentText={null}
              dataSource={dataTotalByMonth}
              tableColumns={renderSaleChannelAll()}
            />
            <div className="mt-3">
              <TableGroup
                dataSource={dataSource}
                tableColumns={renderColumnSaleChannelAll()}
                noContentText={null}
                //   onSort={onSort}
              />
            </div>
          </div>
          {/* <Row className="mt-3">
        <AliTable
          dataSource={dataSource}
          columns={renderColumn}
          heightBody="calc(100vh - 450px)"
          //   onSort={onSort}
        />
      </Row> */}
        </div>
      ) : (
        <div className="vh-100-final d-flex align-items-center justify-content-center w-100">
          該当のデータはありません
        </div>
      )}
    </>
  );
};
export default TableDailyAccount;
