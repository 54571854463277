/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import brAxios from 'Api/Interceptors';

interface ParamsArtist {
  localName: string;
  furiganaName: string;
  globalName: string;
  officialSite: string;
  image: File;
}

interface QueryParams {
  page?: number;
  perPage?: number;
  queryArtist?: string;
  type?: string;
  companyId?: string;
}

interface GetArtistsParams {
  page?: number;
  perPage?: number;
  queryArtist?: string | number;
  companyIds?: string;
  artistIds?: string;
}

const formDataRegister = (params: ParamsArtist) => {
  const { furiganaName, globalName, image, localName, officialSite } = params;
  const dataArray = new FormData();
  dataArray.append('furiganaName', furiganaName);
  dataArray.append('globalName', globalName);
  dataArray.append('localName', localName);
  if (officialSite) {
    dataArray.append('officialSite', officialSite);
  }
  if (image.size > 0) {
    dataArray.append('file', image);
  }
  return dataArray;
};

export const ArtistApi = {
  getAllSuggestionArtist: ({ page, perPage, queryArtist, companyId }: QueryParams) => {
    const response = brAxios.get(
      `/suggestion/artists?page=${page ?? ''}&limit=${perPage ?? ''}&query=${
        queryArtist ?? ''
      }&companyId=${companyId}`,
    );
    return response;
  },
  getArtists: ({ page, perPage, companyIds, artistIds }: GetArtistsParams) =>
    brAxios.get(
      `/bootrock-company/artists?page=${page ?? ''}&limit=${perPage ?? ''}&companyId=${
        companyIds ?? ''
      }&artistId=${artistIds ?? ''}`,
    ),
  getArtistById: (id: number | string) => {
    const response = brAxios.get(`/bootrock-company/artists/${id}`);
    return response;
  },
  handlerRegisterArtist: (params: ParamsArtist) => {
    const formData = formDataRegister(params);
    const response = brAxios.postFormData('/bootrock-company/artists', formData);
    return response;
  },
  handlerEditArtist: (params: ParamsArtist, id: string) => {
    const { furiganaName, globalName, localName, officialSite, image } = params;
    const newParams = {
      furiganaName,
      globalName,
      localName,
      officialSite,
      image,
    };

    const formData = formDataRegister(newParams);
    const response = brAxios.putFormData(`/bootrock-company/artists/${id}`, formData);
    return response;
  },
};
