import { REGEX_PHONE_NUMBER, REGEX_NUMBER, REGEX_IP, REGEX_DECIMAL } from 'Utils/Validation';

const dotRegex = /\./g;

export const handleEnteringPhoneNumber = (phoneNumber: string): string => {
  return phoneNumber.replace(REGEX_PHONE_NUMBER, '');
};

export const handleEnteringNumber = (number: string): string => {
  return number.replace(REGEX_NUMBER, '');
};

export const handleEnteringDecimal = (number: string | any): string => {
  if (number.match(dotRegex)?.length > 1) {
    return number.replace('.', '');
  } else if (number.includes('.') && number.split('.')[1].length > 2) {
    const dotIndex = number.indexOf('.');
    return number.replace(REGEX_DECIMAL, '').slice(0, dotIndex + 3);
  } else {
    return number.replace(REGEX_DECIMAL, '');
  }
};

export const handleEnteringIp = (number: string): string => {
  return number.replace(REGEX_IP, '');
};

export const formatCurrency = (value: string | number): string => {
  if (!value || value === 0) return '';
  return new Intl.NumberFormat('ja-JP', { currency: 'JPY' }).format(
    typeof value === 'string' ? Number(value) : value,
  );
};

export const formatDecimal = (value: string | number, fixed?: number): string => {
  if (!value || value === 0) return '';
  return String(
    typeof value === 'string' ? parseFloat(value).toFixed(fixed) : value.toFixed(fixed),
  );
};

export const formatFixedDecimalAndNumb = (value: string | number, fixed?: number): string => {
  if (value && Number(value) % 1 !== 0) {
    return String(
      typeof value === 'string' ? parseFloat(value)?.toFixed(fixed) : value?.toFixed(fixed),
    );
  }

  return String(value);
};

export const getSum = (data: any[], key: string): number => {
  const sum = data.reduce((total, currentValue) => {
    return total + +currentValue[key];
  }, 0);
  return sum;
};

export const formatPrice = (value: number | string) => {
  return value && value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const formatCurrencyUS = (value: string | number): string => {
  if (!value || value === 0) return '';
  return new Intl.NumberFormat('en-US', { currency: 'USD' }).format(
    typeof value === 'string' ? Number(value) : value,
  );
};
