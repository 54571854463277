import {
  DEFAULT_VARIATION_NAME,
  INCLUDE_JARSAC_CONTENT,
  PRICE_JPY,
  TitleTypes,
} from 'Constant/Title';
import { toNumber } from 'lodash';

/**
 * Get label from type.
 * @param value 0, 1, 2, null, undefined
 * @return シングル
 */
export function getTitleTypeLabel(value?: number | null): string {
  return TitleTypes[value!]?.label ?? '';
}

/**
 * Get file name with extension from path.
 * @param path Record_Company/2/Variations/paper.pdf
 * @return paper.pdf
 */
export function getLyricCardFileNameFromPath(path?: string | null): string {
  if (!path) return '';

  const parts = path.split('/');
  return parts[parts.length - 1];
}

export const convertPriceApple = (price: number): number => {
  return PRICE_JPY.find((element: number) => element >= price) || 0;
};

export const convertToSeconds = (time: number) => {
  if (!time) return 0;
  const value = time.toString().replace(/:/g, '');
  const mm = value.substring(0, 2);
  const ss = value.substring(2, 4);
  const mls = value.substring(4, 7);
  return +mm * 60 + +ss + +`0.${mls}`;
};

export const convertToClock = (seconds: number) => {
  if (!seconds) return '';
  let secondsFloat = seconds | 0;
  const mm = ((secondsFloat / 60) | 0).toString().padStart(2, '0');
  const ss = (secondsFloat %= 60).toString().padStart(2, '0');
  const mls = `${seconds}`.split('.')[1].toString().slice(0, 3);

  return `${mm}:${ss}:${mls}`;
};

export const convertToClockMMSS = (seconds: number) => {
  if (!seconds) return '';
  let secondsFloat = seconds | 0;
  const mm = ((secondsFloat / 60) | 0).toString().padStart(2, '0');
  const ss = (secondsFloat %= 60).toString().padStart(2, '0');

  return `${mm}:${ss}`;
};

export const LINK_JMD_TYPE_CONTENT = {
  NOT_WORK_TOGETHER: 0,
  WORK_TOGETHER: 1,
};

export const handleConvertJmdCooperation = (value: number) => {
  return value === LINK_JMD_TYPE_CONTENT.WORK_TOGETHER ? '連携する' : '連携しない';
};

const handleConvertSongs = (songs: any) => {
  const cloneSongs = songs.map((song: any) => ({
    id: song.id,
    index: song.index,
    name: song.name,
    furiganaName: song.furiganaName,
    englishName: song.englishName,
    musicianName: song.musicianName,
    musicianFuriganaName: song.musicianFuriganaName,
    musicianEnglishName: song.musicianEnglishName,
    composerName: song.composerName,
    composerFuriganaName: song.composerFuriganaName,
    composerEnglishName: song.composerEnglishName,
    arrangerName: song.arrangerName,
    arrangerFuriganaName: song.arrangerFuriganaName,
    arrangerEnglishName: song.arrangerEnglishName,
    versionIdentificationType: song.versionIdentificationType,
    showTime: song.showTime,
    ivtType: song.ivtType,
    liveType: song.liveType,
    authorizedGroupType: song.authorizedGroupType,
    authorizedGroup: song.authorizedGroup,
    isrcCode: song.isrcCode,
    collectCode: song.collectCode,
    jasracCode: song.jasracCode,
    otherJasracCode: song.otherJasracCode,
    lyricsClassifyType: song.lyricsClassifyType,
    sourceAudioUrl: song.sourceAudioUrl,
    size: song.size,
    lyrics: song.lyrics,
    fileName: song.fileName || '',
    informationFee: song?.informationFee ? toNumber(song.informationFee) : 0,
    lyricInformationFee: song.lyricInformationFee ? toNumber(song.lyricInformationFee) : 0,
    riajGenre: song.riajGenre ?? null,
  }));
  return cloneSongs;
};

const handleConvertVariations = (
  variations: any,
  isDuplicate?: boolean,
): Record<string, unknown> => {
  const cloneVariations = variations?.map((variation: Record<string, unknown>, index: number) => {
    const dpMedias: any = variation?.dpMedias || [];
    const videos = dpMedias
      .filter((item: any) => +item.type == 1)
      .map((video: any) => ({
        ...video,
        mediaInformationFee: video?.mediaInformationFee ? toNumber(video.mediaInformationFee) : 0,
      }));
    const images = dpMedias.filter((item: any) => item.type == 0);
    return {
      index,
      id: variation.id,
      type: variation.name === DEFAULT_VARIATION_NAME ? 0 : 1,
      name: variation.name,
      songQuantity: variation.songQuantity,
      productCode: variation.productCode,
      saleTokenDate: !isDuplicate ? variation.saleTokenDate : '',
      downloadDate: !isDuplicate ? variation.downloadDate : '',
      isDisplayName: variation.isDisplayName,
      janCode: variation.janCode,
      price: variation.price,
      coverImageUrl: variation.coverImageUrl,
      songs: handleConvertSongs(variation.songs),
      option: variation.option,
      musicFormat: variation.musicFormat,
      booklets: variation.booklets,
      notiDownloadTime: variation.notiDownloadTime,
      videos: videos,
      images: images,
      includeJasracContent:
        variation?.includeJasracContent !== null
          ? variation?.includeJasracContent
          : INCLUDE_JARSAC_CONTENT.INCLUDE,
      jmdCooperation: variation.jmdCooperation ?? LINK_JMD_TYPE_CONTENT.WORK_TOGETHER,
      riajGenre: variation.riajGenre ?? null,
      jmdMusicGenre: variation.jmdMusicGenre ?? null,
      jmdVideoGenre: variation.jmdVideoGenre ?? null,
    };
  });
  return cloneVariations;
};

export const handleConvertTitleInfo = (
  title: Record<string, unknown>,
  isDuplicate?: boolean,
): Record<string, unknown> => {
  const titleInfo = {
    id: title.id,
    artistId: title.artistId,
    artistName: title.artistName,
    name: title.name,
    type: title.type,
    label: title.label,
    musicGenreId: title.musicGenreId,
    furiganaName: title.furiganaName,
    musicGenreName: title.musicGenreName,
    variations: handleConvertVariations(title.variations, isDuplicate),
    isAuthor: title.isAuthor,
    status: title.status,
    managerList: title?.managerList || [],
    sellIap: title.sellIap,
    globalName: title.globalName,
  };
  return titleInfo;
};

export const padNum = (num: number, length = 3) => {
  return `${Array(length + 1 - num.toString().length).join('0') + num}`;
};

//* cut text if text too long
export const truncateText = (text: string, length: number): string => {
  return text.length > length ? `${text.slice(0, length)}...` : text;
};

export function randomId(): string {
  const S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return `uuid-${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
}
