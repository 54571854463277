import React, { useMemo } from 'react';
import TableCommon from 'Components/Common/Table/TableCommon';
import { Cell } from 'react-table';
import { isEmpty } from 'lodash';
import { IPrize } from 'Types/Page/Campaign/Campaign';
import dayjs from 'dayjs';
import { DATE_FORMAT_6 } from 'Constant/Date';
import { SortMethod } from 'Components/Common/Table/table';
interface Props {
  dataSource: IPrize[];
  onSort?: (sortingState: SortMethod[]) => void;
}
export default function TablePrize({ dataSource, onSort }: Props): JSX.Element {
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'pe-none',
        columns: [
          {
            Header: '申込番号',
            accessor: 'id',
            Cell: ({ row }: Cell<IPrize>) => {
              return <p className="text-truncate">{row.original.id}</p>;
            },
          },
          {
            Header: 'ユーザーID',
            accessor: 'miimId',
            Cell: ({ row }: Cell<IPrize>) => {
              return <p className="text-truncate">{row.original.miimId}</p>;
            },
          },
          {
            Header: 'ニックネーム',
            accessor: 'userName',
            Cell: ({ row }: Cell<IPrize>) => {
              return <div className="text-truncate">{row.original.userName}</div>;
            },
          },
          {
            Header: 'お名前',
            accessor: 'realName',
            Cell: ({ row }: Cell<IPrize>) => {
              return <div className="text-truncate">{row.original.realName}</div>;
            },
          },
          {
            Header: 'メールアドレス',
            accessor: 'email',
            Cell: ({ row }: Cell<IPrize>) => {
              return <div className="text-truncate">{row.original.email}</div>;
            },
          },
          {
            Header: 'コース',
            accessor: 'courseName',
            Cell: ({ row }: Cell<IPrize>) => {
              return <div className="text-truncate">{row.original.courseName}</div>;
            },
          },
          {
            Header: '当選景品',
            accessor: 'prizeName',
            Cell: ({ row }: Cell<IPrize>) => {
              return <div className="text-truncate">{row.original.prizeName}</div>;
            },
          },
          {
            Header: '整理番号',
            accessor: 'seatNumberRef',
            Cell: ({ row }: Cell<IPrize>) => {
              return <div>{row.original.seatNumberRef}</div>;
            },
          },
          {
            Header: '申込時間',
            accessor: 'applyTime',
            Cell: ({ row }: Cell<IPrize>) => {
              return (
                <div className="text-break">
                  {row.original.applyTime
                    ? dayjs(row.original.applyTime).format(DATE_FORMAT_6)
                    : ''}
                </div>
              );
            },
          },
        ],
      },
    ],
    [],
  );
  return (
    <>
      {!isEmpty(dataSource) ? (
        <TableCommon
          classTable="table-prize"
          data={dataSource}
          columns={columns}
          getHeaderProps={() => {
            return {
              className: `text-white d-flex align-item-center bg-dark text-center`,
            };
          }}
          manualSortBy
          onSort={onSort}
        />
      ) : (
        <div className="vh-100-final d-flex align-items-center justify-content-center">
          該当のデータはありません
        </div>
      )}
    </>
  );
}
