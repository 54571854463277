import MessageError from 'Components/Common/MessageError';
import { CampaignStatus } from 'Constant/Campaign';
import { DATE_FORMAT_1, DATE_FORMAT_2 } from 'Constant/Date';
import dayjs from 'dayjs';
import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatPrice } from 'Utils/Numbers';

export const renderId = (row: any) => {
  const { id } = row.original;
  return <span>{id}</span>;
};

export const renderSalesChannel = (row: any) => {
  const { titleId, type, titleStatus, productChannel = [], name } = row.original;
  return (
    <div className="ps-2">
      {type === 0 ? (
        <div className="pt-2">
          <p>マスタ</p>
        </div>
      ) : (
        <Col>
          <Link
            title=""
            to={{
              pathname: `/create-products/${titleId}`,
              state: row.original,
              search: `?productId=${row.original.id}`,
            }}
          >
            <span className="d-block text-truncate text-start pt-2">{name || ''}</span>
          </Link>
          {titleStatus === 0 && (
            <MessageError classWrapper="text-dark-red" message="タイトルが一時保存中です" />
          )}

          {productChannel && (
            <div className="d-flex mt-3 flex-wrap">
              {productChannel.map((item: any, index: number) => {
                return (
                  <span
                    key={index}
                    className="text-item px-2 me-2 fs-11 text-truncate box-channel mb-2 text-center"
                  >
                    {item.channelName}
                  </span>
                );
              })}
            </div>
          )}
        </Col>
      )}
    </div>
  );
};

export const renderCampaignInformation = (row: any) => {
  const { type, info, titleId } = row.original;
  const { campaignAndCards = [] } = info;
  return (
    <>
      {type === 0 ? (
        <Link className="p-2 d-flex" to={`/detail-title/${titleId}`}>
          <span className="d-block text-truncate text-start">
            {info.titleName} / {info.artistName}
          </span>
        </Link>
      ) : (
        <div>
          <>
            {campaignAndCards &&
              campaignAndCards?.map((item: any, index: number) => {
                const {
                  name = '',
                  startDate,
                  endDate,
                  artistName,
                  titleName,
                  titleId,
                  artistId,
                  id,
                  status,
                } = item.campaignInfo;
                const heightConvert = +item.cards?.length * 35;
                return (
                  <div
                    key={index}
                    style={{ height: heightConvert }}
                    className={`${index && `border-top border-2`} p-2 info-products`}
                  >
                    <Link key={index} title="" to={`/campaigns/${id}`}>
                      <span className="d-block text-truncate text-start fw-bold">{name || ''}</span>
                    </Link>
                    {status === CampaignStatus.NoDigital && (
                      <MessageError
                        classWrapper="my-2 ms-2 text-dark-red"
                        message="未登録コンテンツがあります"
                      />
                    )}
                    <span className="d-flex fw-bold fs-11">販売期間</span>
                    <p className="d-flex fw-bold  ms-2">
                      {dayjs(startDate).format(DATE_FORMAT_2)}~
                      {dayjs(endDate).format(DATE_FORMAT_2)}
                    </p>
                    <p className="d-flex mt-2 fs-11">アーティスト</p>
                    <Link to={`/artist/detail-artist/${artistId}`}>
                      <span className="d-block text-truncate text-start ms-2">{artistName}</span>
                    </Link>
                    <p className="d-flex mt-2 fs-11">タイトル</p>
                    <Link to={`/detail-title/${titleId}`}>
                      <span className="d-block text-truncate text-start ms-2">{titleName}</span>
                    </Link>
                  </div>
                );
              })}
          </>
        </div>
      )}
    </>
  );
};

export const renderSaleId = (row: any) => {
  const { cardCd, campaignAndCards } = row.original.info;
  const { titleId } = row.original;
  return (
    <>
      {cardCd ? (
        <div className="d-flex px-2 pt-1 mt-1 justify-content-center" key={cardCd}>
          <Link
            title={cardCd}
            to={`/products-detail/${titleId}`}
            className={`${!cardCd && 'text-decoration-none text-black pe-none'}`}
          >
            <span className="d-block text-truncate text-center">{cardCd}</span>
          </Link>
        </div>
      ) : (
        <>
          {campaignAndCards?.map((data: any, key: number) => {
            const { cards } = data;
            return (
              <div key={key} className={`${key && `border-top border-2`} info-products`}>
                {cards.map((item: any, index: number) => {
                  return (
                    <div
                      className={`${
                        index && `border-top border-2`
                      } h-35px d-flex px-2 pt-2 justify-content-center`}
                      key={index}
                    >
                      {item.cardCd ? (
                        <Link
                          title={item.cardCd}
                          to={`/products-detail/${titleId}`}
                          className={`${!item.cardCd && 'text-decoration-none text-black pe-none'}`}
                        >
                          <span className="d-block text-truncate text-center">{item.cardCd}</span>
                        </Link>
                      ) : (
                        <span className="d-block text-truncate text-center text-muted">
                          物販商品
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export const renderVariation = (row: any) => {
  const { variationName, campaignAndCards } = row.original.info;
  return (
    <>
      {variationName ? (
        <div className="h-35px d-flex px-2 pt-2">
          <span className="d-block text-truncate text-start">{variationName}</span>
        </div>
      ) : (
        <>
          {campaignAndCards?.map((data: any, key: number) => {
            const { cards } = data;
            return (
              <div key={key} className={`${key && `border-top border-2`} info-products`}>
                {cards.map((item: any, index: number) => {
                  return (
                    <div
                      className={`${index && `border-top border-2`} h-35px d-flex px-2 pt-2`}
                      key={index}
                    >
                      <span className="d-block text-truncate text-start">{item.name}</span>
                      <span hidden={item?.cardCd} className="text-item px-2 fs-11 h-18 ms-2">
                        CD
                      </span>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export const renderStartDate = (row: any) => {
  const { downloadDate, campaignAndCards } = row.original.info;
  return (
    <>
      {downloadDate ? (
        <div className="px-2 pt-1 mt-1">
          <span className="d-block text-truncate text-center">
            {downloadDate ? dayjs(downloadDate).format(DATE_FORMAT_2) : ''}
          </span>
        </div>
      ) : (
        <>
          {campaignAndCards?.map((data: any, key: number) => {
            const { cards } = data;
            return (
              <div key={key} className={`${key && `border-top border-2`} info-products`}>
                {cards.map((item: any, index: number) => {
                  return (
                    <div
                      className={`${index && `border-top border-2`} h-35px px-2 pt-2`}
                      key={index}
                    >
                      <span className="d-block text-truncate text-center">
                        {item.downloadDate ? dayjs(item.downloadDate).format(DATE_FORMAT_2) : ''}
                      </span>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </>
      )}
    </>
  );
};
export const renderPrice = (row: any) => {
  const { price, campaignAndCards } = row.original.info;
  return (
    <>
      {price ? (
        <p className="text-truncate text-end mt-1 px-2">{formatPrice(price || 0)}</p>
      ) : (
        <>
          {campaignAndCards?.map((data: any, key: number) => {
            const { cards } = data;
            return (
              <div key={key} className={`${key && `border-top border-2`} info-products`}>
                {cards.map((item: any, index: number) => {
                  return (
                    <div className={`${index && `border-top border-2`} h-35px pt-2`} key={index}>
                      <p className="text-truncate text-end px-2">{formatPrice(item.price || 0)}</p>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </>
      )}
    </>
  );
};
export const renderProductsCode = (row: any) => {
  const { productCode = '', campaignAndCards = [] } = row.original.info;
  return (
    <>
      {campaignAndCards?.length === 0 ? (
        <p className="text-truncate text-start mt-2 px-2">{productCode || ''}</p>
      ) : (
        <>
          {campaignAndCards?.map((data: any, key: number) => {
            const { cards } = data;
            return (
              <div key={key} className={`${key && `border-top border-2`} info-products`}>
                {cards.map((item: any, index: number) => {
                  return (
                    <div
                      className={`${index && `border-top border-2`} h-35px d-flex px-2 pt-2`}
                      key={index}
                    >
                      <span className="d-block text-truncate text-start">
                        {item?.productCode || ''}
                      </span>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export const renderJanCode = (row: any) => {
  const { janCode = '', campaignAndCards = [] } = row.original.info;
  return (
    <>
      {campaignAndCards?.length === 0 ? (
        <p className="text-truncate text-center mt-2 px-2">{janCode || ''}</p>
      ) : (
        <>
          {campaignAndCards?.map((data: any, key: number) => {
            const { cards } = data;
            return (
              <div key={key} className={`${key && `border-top border-2`} info-products`}>
                {cards.map((item: any, index: number) => {
                  return (
                    <div
                      className={`${index && `border-top border-2`} h-35px px-2 pt-2`}
                      key={index}
                    >
                      <p className="text-truncate text-center">{item?.janCode || ''}</p>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export const renderUpdate = (row: any) => {
  const { updatedAt } = row.original;
  return (
    <>
      <span>{dayjs(updatedAt).format(DATE_FORMAT_1)}</span>
    </>
  );
};

export const renderSaleTokenDate = (row: any) => {
  const { saleTokenDate, campaignAndCards } = row.original.info;
  return (
    <>
      {saleTokenDate ? (
        <div className="px-2 pt-1 mt-1">
          <span className="d-block text-truncate text-center">
            {dayjs(saleTokenDate).format(DATE_FORMAT_2)}
          </span>
        </div>
      ) : (
        <>
          {campaignAndCards?.map((data: any, key: number) => {
            const { cards } = data;
            return (
              <div key={key} className={`${key && `border-top border-2`} info-products`}>
                {cards.map((item: any, index: number) => {
                  return (
                    <div
                      className={`${index && `border-top border-2`} h-35px px-2 pt-2`}
                      key={index}
                    >
                      <span className="d-block text-truncate text-center">
                        {item.saleTokenDate && dayjs(item.saleTokenDate).format(DATE_FORMAT_2)}
                      </span>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </>
      )}
    </>
  );
};
