import React from 'react';
import { Button } from 'react-bootstrap';
import { EC_EMAIL_TEMPLATE } from 'Constant/EC';

const GroupButton = ({ option, handleAddTextToContent }: any) => {
  //   const [textAdd, setTextAdd] = useState('');

  const handleClickBtn = (btnText: any) => {
    handleAddTextToContent(btnText);
  };
  return (
    <>
      {option == EC_EMAIL_TEMPLATE.TITLE_REGISTER_COMPLETE ? (
        <>
          <Button
            className="btn btn-dark d-flex border-radius-right-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__USER_NAME__]]')}
          >
            担当者氏名
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__TITLE_LINK__]]')}
          >
            マスタタイトル Link
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__TITLE_OVERVIEW__]]')}
          >
            マスタタイトル概要
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-left-unset ec-border-none"
            onClick={() => handleClickBtn('[[__FOOTER__]]')}
          >
            Footer
          </Button>
        </>
      ) : option == EC_EMAIL_TEMPLATE.TITLE_CONTENT_CHANGE_NOTIFICATION ? (
        <>
          <Button
            className="btn btn-dark d-flex border-radius-right-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__USER_NAME__]]')}
          >
            担当者氏名
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__TITLE_LINK__]]')}
          >
            マスタタイトル Link
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__TITLE_OVERVIEW__]]')}
          >
            マスタタイトル概要
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-left-unset ec-border-none"
            onClick={() => handleClickBtn('[[__FOOTER__]]')}
          >
            Footer
          </Button>
        </>
      ) : option == EC_EMAIL_TEMPLATE.CAMPAIGN_REGISTER_COMPLETION_NOTIFICATION ? (
        <>
          <Button
            className="btn btn-dark d-flex border-radius-right-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__USER_NAME__]]')}
          >
            担当者氏名
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__CAMPAIGN_LINK__]]')}
          >
            キャンペーン Link
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__CAMPAIGN_INFORMATION__]]')}
          >
            キャンペーン情報
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__CAMPAIGN_OVERVIEW__]]')}
          >
            キャンペーン概要
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-left-unset ec-border-none"
            onClick={() => handleClickBtn('[[__FOOTER__]]')}
          >
            Footer
          </Button>
        </>
      ) : option == EC_EMAIL_TEMPLATE.NOTIFICATION_OF_CHANGE_IN_CAMPAIGN_CONTENT ? (
        <>
          <Button
            className="btn btn-dark d-flex border-radius-right-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__USER_NAME__]]')}
          >
            担当者氏名
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__CAMPAIGN_LINK__]]')}
          >
            キャンペーン Link
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__CAMPAIGN_INFORMATION__]]')}
          >
            キャンペーン情報
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__CAMPAIGN_OVERVIEW__]]')}
          >
            キャンペーン概要
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-left-unset ec-border-none"
            onClick={() => handleClickBtn('[[__FOOTER__]]')}
          >
            Footer
          </Button>
        </>
      ) : option == EC_EMAIL_TEMPLATE.REQUEST_FOR_MASTER_CONTENT_REGISTER_2_WEEKS_BEFORE ? (
        <>
          <Button
            className="btn btn-dark border-radius-right-unset d-flex ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__USER_NAME__]]')}
          >
            担当者氏名
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__VARIATION__]]')}
          >
            バリエーション情報
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__DELIVERY_TIME__]]')}
          >
            配信日時
          </Button>

          <Button
            className="btn btn-dark d-flex border-radius-left-unset ec-border-none"
            onClick={() => handleClickBtn('[[__FOOTER__]]')}
          >
            Footer
          </Button>
        </>
      ) : option == EC_EMAIL_TEMPLATE.REQUEST_FOR_MASTER_CONTENT_REGISTER_1_WEEK_BEFORE ? (
        <>
          <Button
            className="btn btn-dark border-radius-right-unset d-flex ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__USER_NAME__]]')}
          >
            担当者氏名
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__VARIATION__]]')}
          >
            バリエーション情報
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__DELIVERY_TIME__]]')}
          >
            配信日時
          </Button>

          <Button
            className="btn btn-dark d-flex border-radius-left-unset ec-border-none"
            onClick={() => handleClickBtn('[[__FOOTER__]]')}
          >
            Footer
          </Button>
        </>
      ) : option == EC_EMAIL_TEMPLATE.REQUEST_FOR_MASTER_CONTENT_REGISTER_4_DAYS_BEFORE ? (
        <>
          <Button
            className="btn btn-dark border-radius-right-unset d-flex ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__USER_NAME__]]')}
          >
            担当者氏名
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__VARIATION__]]')}
          >
            バリエーション情報
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__DELIVERY_TIME__]]')}
          >
            配信日時
          </Button>

          <Button
            className="btn btn-dark d-flex border-radius-left-unset ec-border-none"
            onClick={() => handleClickBtn('[[__FOOTER__]]')}
          >
            Footer
          </Button>
        </>
      ) : option == EC_EMAIL_TEMPLATE.REQUEST_FOR_CAMPAIGN_CONTENT_REGISTRATION_1_WEEK_BEFORE ? (
        <>
          <Button
            className="btn btn-dark border-radius-right-unset d-flex ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__USER_NAME__]]')}
          >
            担当者氏名
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__CAMPAIGN__]]')}
          >
            キャンペーン情報
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__DOWNLOAD_TIME__]]')}
          >
            ダウンロード日時
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-left-unset ec-border-none"
            onClick={() => handleClickBtn('[[__FOOTER__]]')}
          >
            Footer
          </Button>
        </>
      ) : option == EC_EMAIL_TEMPLATE.REQUEST_FOR_CAMPAIGN_CONTENT_REGISTRATION_4_DAYS_BEFORE ? (
        <>
          <Button
            className="btn btn-dark border-radius-right-unset d-flex ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__USER_NAME__]]')}
          >
            担当者氏名
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__CAMPAIGN__]]')}
          >
            キャンペーン情報
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__DOWNLOAD_TIME__]]')}
          >
            ダウンロード日時
          </Button>

          <Button
            className="btn btn-dark d-flex border-radius-left-unset ec-border-none"
            onClick={() => handleClickBtn('[[__FOOTER__]]')}
          >
            Footer
          </Button>
        </>
      ) : option == EC_EMAIL_TEMPLATE.RECORD_COMPANY_MASTER_ACCOUNT_INSSUANCE_NOTIFICATION ? (
        <>
          <Button
            className="btn btn-dark border-radius-right-unset d-flex ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__USER_NAME__]]')}
          >
            担当者氏名
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__LOGIN_URL__]]')}
          >
            ログインURL
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__LOGIN_MAIL__]]')}
          >
            ログインメールアドレス
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__LOGIN_PASSWORD__]]')}
          >
            ログインパスワード
          </Button>

          <Button
            className="btn btn-dark d-flex border-radius-left-unset ec-border-none"
            onClick={() => handleClickBtn('[[__FOOTER__]]')}
          >
            Footer
          </Button>
        </>
      ) : option ==
        EC_EMAIL_TEMPLATE.RECORD_COMPANY_ARTIST_PERSON_IN_CHARGE_ACCOUNT_ISSUANCE_NOTIFICATION ? (
        <>
          <Button
            className="btn btn-dark border-radius-right-unset d-flex ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__USER_NAME__]]')}
          >
            担当者氏名
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__LOGIN_URL__]]')}
          >
            ログインURL
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__LOGIN_MAIL__]]')}
          >
            ログインメールアドレス
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__LOGIN_PASSWORD__]]')}
          >
            ログインパスワード
          </Button>

          <Button
            className="btn btn-dark d-flex border-radius-left-unset ec-border-none"
            onClick={() => handleClickBtn('[[__FOOTER__]]')}
          >
            Footer
          </Button>
        </>
      ) : option ==
        EC_EMAIL_TEMPLATE.BOOTROCK_RECORD_COMPANY_REPRESENTATIVE_ACCOUNT_ISSUANCE_NOTIFICATION ? (
        <>
          <Button
            className="btn btn-dark border-radius-right-unset d-flex ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__USER_NAME__]]')}
          >
            担当者氏名
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__LOGIN_URL__]]')}
          >
            ログインURL
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__LOGIN_MAIL__]]')}
          >
            ログインメールアドレス
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__LOGIN_PASSWORD__]]')}
          >
            ログインパスワード
          </Button>

          <Button
            className="btn btn-dark d-flex border-radius-left-unset ec-border-none"
            onClick={() => handleClickBtn('[[__FOOTER__]]')}
          >
            Footer
          </Button>
        </>
      ) : option == EC_EMAIL_TEMPLATE.PASSWORD_RESET_NOTIFICATION ? (
        <>
          <Button
            className="btn btn-dark border-radius-right-unset d-flex ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__USER_NAME__]]')}
          >
            担当者氏名
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__RESET_PASSWORD_URL__]]')}
          >
            パスワードの再設定URL
          </Button>

          <Button
            className="btn btn-dark d-flex border-radius-left-unset ec-border-none"
            onClick={() => handleClickBtn('[[__FOOTER__]]')}
          >
            Footer
          </Button>
        </>
      ) : option == EC_EMAIL_TEMPLATE.MIIM_EC_MASTER_REGISTER_COMPLETE ? (
        <>
          <Button
            className="btn btn-dark border-radius-right-unset d-flex ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__USER_NAME__]]')}
          >
            担当者氏名
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__LOGIN_URL__]]')}
          >
            ログインURL
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__LOGIN_MAIL__]]')}
          >
            ログインメールアドレス
          </Button>

          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__LOGIN_PASSWORD__]]')}
          >
            ログインパスワード
          </Button>

          <Button
            className="btn btn-dark d-flex border-radius-left-unset ec-border-none"
            onClick={() => handleClickBtn('[[__FOOTER__]]')}
          >
            Footer
          </Button>
        </>
      ) : option == EC_EMAIL_TEMPLATE.MIIM_EC_REPRESENTATIVE_REGISTER_COMPLETE ? (
        <>
          <Button
            className="btn btn-dark border-radius-right-unset d-flex ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__USER_NAME__]]')}
          >
            担当者氏名
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__LOGIN_URL__]]')}
          >
            ログインURL
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__LOGIN_MAIL__]]')}
          >
            ログインメールアドレス
          </Button>

          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__LOGIN_PASSWORD__]]')}
          >
            ログインパスワード
          </Button>

          <Button
            className="btn btn-dark d-flex border-radius-left-unset ec-border-none"
            onClick={() => handleClickBtn('[[__FOOTER__]]')}
          >
            Footer
          </Button>
        </>
      ) : option == EC_EMAIL_TEMPLATE.EXTERNAL_MIIM_EC_MASTER_REGISTER_COMPLETE ? (
        <>
          <Button
            className="btn btn-dark border-radius-right-unset d-flex ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__USER_NAME__]]')}
          >
            担当者氏名
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__LOGIN_URL__]]')}
          >
            ログインURL
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__LOGIN_MAIL__]]')}
          >
            ログインメールアドレス
          </Button>

          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__LOGIN_PASSWORD__]]')}
          >
            ログインパスワード
          </Button>

          <Button
            className="btn btn-dark d-flex border-radius-left-unset ec-border-none"
            onClick={() => handleClickBtn('[[__FOOTER__]]')}
          >
            Footer
          </Button>
        </>
      ) : option == EC_EMAIL_TEMPLATE.EXTERNAL_MIIM_EC_REPRESENTATIVE_REGISTER_COMPLETE ? (
        <>
          <Button
            className="btn btn-dark border-radius-right-unset d-flex ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__USER_NAME__]]')}
          >
            担当者氏名
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__LOGIN_URL__]]')}
          >
            ログインURL
          </Button>
          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__LOGIN_MAIL__]]')}
          >
            ログインメールアドレス
          </Button>

          <Button
            className="btn btn-dark d-flex border-radius-bothside-unset ec-border-none ec-mr-1"
            onClick={() => handleClickBtn('[[__LOGIN_PASSWORD__]]')}
          >
            ログインパスワード
          </Button>

          <Button
            className="btn btn-dark d-flex border-radius-left-unset ec-border-none"
            onClick={() => handleClickBtn('[[__FOOTER__]]')}
          >
            Footer
          </Button>
        </>
      ) : option == EC_EMAIL_TEMPLATE.FOOTER ? (
        <></>
      ) : (
        ''
      )}
    </>
  );
};

export default GroupButton;
