import React from 'react';
import { ReactComponent as VisaIcon } from 'Static/Images/visa.svg';
import { ReactComponent as MasterCardIcon } from 'Static/Images/master-card.svg';
import { ReactComponent as JCBIcon } from 'Static/Images/jcb.svg';
import { ReactComponent as DiscoverIcon } from 'Static/Images/discover.svg';
import { ReactComponent as AmericanExpressIcon } from 'Static/Images/american-express.svg';
import { ReactComponent as DinersClubIcon } from 'Static/Images/diners-club.svg';

const SALES_STATUS = [
  { value: undefined, label: 'すべて' },
  { value: 0, label: '公開未設定', bgColor: '#e6e6e6', borderColor: '#C4C4C4' },
  { value: 1, label: '公開前', bgColor: '#77a6e5', borderColor: '#7C86B1' },
  { value: 2, label: '公開中販売前', bgColor: '#77d8e5', borderColor: '#7C9FB1' },
  { value: 3, label: '公開中販売可', bgColor: '#90e577', borderColor: '#95B17C' },
  { value: 4, label: '公開中販売終了', bgColor: '#fcc369', borderColor: '#D69313' },
  { value: 5, label: '公開終了', bgColor: '#e6e6e6', borderColor: '#C4C4C4' },
  { value: 6, label: '非公開', bgColor: '#ff9696', borderColor: '#9D0404' },
];

const PURCHASE_STATUS = [
  { value: 0, label: 'キャンセル', bgColor: '#ff9696' },
  { value: 1, label: '部分キャンセル', bgColor: '#fcc369' },
  { value: 2, label: '購入済み', bgColor: '#e6e6e6' },
  { value: 3, label: '決済エラー', bgColor: '#ff9696' },
];

const PURCHASE_DETAIL_STATUS = {
  CANCEL: 'CANCEL',
  PURCHASED: 'PURCHASED',
  PARTIAL_CANCELLATION: 'PARTIAL_CANCELLATION',
  NOT_PAYMENT: 'NOT_PAYMENT',
  ERROR: 'ERROR',
};
export { SALES_STATUS, PURCHASE_STATUS, PURCHASE_DETAIL_STATUS };

export const EC_EMAIL_TEMPLATE = {
  TITLE_REGISTER_COMPLETE: 'タイトル登録完了',
  TITLE_CONTENT_CHANGE_NOTIFICATION: 'タイトル内容変更通知',
  CAMPAIGN_REGISTER_COMPLETION_NOTIFICATION: 'キャンペーン登録完了通知',
  NOTIFICATION_OF_CHANGE_IN_CAMPAIGN_CONTENT: 'キャンペーン内容変更通知',
  REQUEST_FOR_MASTER_CONTENT_REGISTER_2_WEEKS_BEFORE:
    '1)【重要】マスタコンテンツ登録のお願い（2週間前）',
  REQUEST_FOR_MASTER_CONTENT_REGISTER_1_WEEK_BEFORE:
    '1)【重要】マスタコンテンツ登録のお願い（1週間前）',
  REQUEST_FOR_MASTER_CONTENT_REGISTER_4_DAYS_BEFORE:
    '1)【重要】マスタコンテンツ登録のお願い（４日前）',
  REQUEST_FOR_CAMPAIGN_CONTENT_REGISTRATION_1_WEEK_BEFORE:
    '2)【重要】キャンペーンコンテンツ登録のお願い（１週間前）',
  REQUEST_FOR_CAMPAIGN_CONTENT_REGISTRATION_4_DAYS_BEFORE:
    '2)【重要】キャンペーンコンテンツ登録のお願い（4日前）',
  RECORD_COMPANY_MASTER_ACCOUNT_INSSUANCE_NOTIFICATION: '3) レコード会社マスタアカウント発行通知',
  RECORD_COMPANY_ARTIST_PERSON_IN_CHARGE_ACCOUNT_ISSUANCE_NOTIFICATION:
    '4) (レコード会社)アーティスト担当者アカウント発行通知',
  BOOTROCK_RECORD_COMPANY_REPRESENTATIVE_ACCOUNT_ISSUANCE_NOTIFICATION:
    '5) (ブートロック)レコード会社担当者アカウント発行通知',
  PASSWORD_RESET_NOTIFICATION: '6) パスワードリセット通知',
  MIIM_EC_MASTER_REGISTER_COMPLETE: '7)miimECマスタアカウント発行通知',
  MIIM_EC_REPRESENTATIVE_REGISTER_COMPLETE: '7) miimEC担当者アカウント発行通知',
  EXTERNAL_MIIM_EC_MASTER_REGISTER_COMPLETE: '8) miim外部ECマスタアカウント発行通知',
  EXTERNAL_MIIM_EC_REPRESENTATIVE_REGISTER_COMPLETE: '8) miim外部EC担当者アカウント発行通知',
  FOOTER: 'フッター',
};

interface PaymentCard {
  [key: string]: { name: string; logo: any };
}

export const PAYMENT_CARD: PaymentCard = {
  Visa: {
    name: 'Visa',
    logo: <VisaIcon width={60} />,
  },
  MasterCard: {
    name: 'MasterCard',
    logo: <MasterCardIcon width={50} />,
  },
  JCB: {
    name: 'JCB',
    logo: <JCBIcon width={50} />,
  },
  Discover: {
    name: 'Discover',
    logo: <DiscoverIcon width={50} />,
  },
  AmericanExpress: {
    name: 'AmericanExpress',
    logo: <AmericanExpressIcon width={50} height={50} />,
  },
  DinersClub: {
    name: 'DinersClub',
    logo: <DinersClubIcon width={58} />,
  },
};
