/* eslint-disable react-hooks/exhaustive-deps */
import Filter from 'Components/Common/Filter/Filter';
import ApplyStatus from 'Components/Page/Campaign/StatisticCampaign/ApplyStatus';
import CampaignDetail from 'Page/Campaign/CampaignDetail';
import SearchCourse from 'Components/Page/Campaign/StatisticCampaign/SearchCourse';
import WinningResult from 'Components/Page/Campaign/StatisticCampaign/WinningResult';
import { statisticTab, CourseTypes } from 'Constant/Campaign';
import { DATE_FORMAT_7, DATE_FORMAT_YMD } from 'Constant/Date';
import { CampaignApis, ParamExcelStatistic } from 'Datasource/Campaign';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { exportExcel } from 'Utils/File';
import useTitlePage from 'Hooks/useTitlePage';
import useErrorBlob from 'Hooks/useErrorBlob';
export default function StatisticCampaign(): JSX.Element {
  const { id }: { id: string } = useParams();
  const history = useHistory();
  const { setMenuTitle } = useTitlePage();
  const [exporting, setExporting] = useState<boolean>(false);
  const { openErrorBlob } = useErrorBlob();

  useEffect(() => {
    return history.listen(() => {
      if (history.action === 'POP') {
        history.replace('/campaigns');
      }
    });
  }, []);

  const [query] = useQueryParams({
    courseId: StringParam,
    prizeId: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    courseType: NumberParam,
    countJoinEnd: StringParam,
    status: StringParam,
    order: StringParam,
    sort: StringParam,
  });

  useEffect(() => {
    setMenuTitle(query.status || statisticTab.APPLY_STATUS);
  }, [query.status]);

  const btnGroupOptions = useMemo(
    () => [
      { name: statisticTab.CAMPAIGN_DETAIL, value: 0 },
      { name: statisticTab.APPLY_STATUS, value: 1 },
      { name: statisticTab.WINNING_RESULT, value: 2 },
    ],
    [],
  );

  const renderTab = useMemo(() => {
    switch (query.status) {
      case statisticTab.CAMPAIGN_DETAIL:
        return <CampaignDetail />;
      case statisticTab.APPLY_STATUS:
        return <ApplyStatus campaignId={id} />;
      case statisticTab.WINNING_RESULT:
        return <WinningResult campaignId={id} />;
      default:
        return <ApplyStatus campaignId={id} />;
    }
  }, [query.status]);

  const exportStatisticExcel = async () => {
    let params = {
      courseId: query.courseId,
    } as ParamExcelStatistic;
    if (query.courseType === CourseTypes.REALTIME) {
      params = {
        courseId: query.courseId || undefined,
        countJoinEnd: query.countJoinEnd || undefined,
      };
    } else {
      params = {
        courseId: query.courseId || undefined,
        startDate: query.startDate
          ? dayjs(query.startDate).startOf('date').format(DATE_FORMAT_7)
          : undefined,
        endDate: query.endDate
          ? dayjs(query.endDate).endOf('date').format(DATE_FORMAT_7)
          : undefined,
      };
    }
    try {
      setExporting(true);
      await exportExcel(
        CampaignApis.exportStatisticExcel({
          campaignId: id,
          params,
        }),
        `商品リスト_${dayjs().format(DATE_FORMAT_YMD).toString()}`,
      );
    } catch (error) {
      openErrorBlob(error);
    } finally {
      setExporting(false);
    }
  };

  const exportPrizeExcel = async () => {
    try {
      setExporting(true);
      await exportExcel(
        CampaignApis.exportPrizeExcel({
          campaignId: id,
          courseId: query.courseId || undefined,
          prizeId: query.prizeId || undefined,
          startDate: query.startDate
            ? dayjs(query.startDate).startOf('date').format(DATE_FORMAT_7)
            : undefined,
          endDate: query.endDate
            ? dayjs(query.endDate).endOf('date').format(DATE_FORMAT_7)
            : undefined,
          sort: query.sort || 'id',
          order: query.order || 'DESC',
        }),
        `当選結果_${dayjs().format(DATE_FORMAT_YMD).toString()}`,
      );
    } catch (error) {
      openErrorBlob(error);
    } finally {
      setExporting(false);
    }
  };

  return (
    <>
      <Filter filterClass="shadow-sm">
        <Row>
          <SearchCourse
            tabs={btnGroupOptions}
            onExportExcel={
              query.status === statisticTab.WINNING_RESULT ? exportPrizeExcel : exportStatisticExcel
            }
            loadingExcel={exporting}
          />
        </Row>
      </Filter>
      {renderTab}
    </>
  );
}
