/* eslint-disable react-hooks/exhaustive-deps */
import Search from 'Components/Common/Search/Search';
import useSuggestionSample from 'Hooks/useSuggestionSample';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

export default function SearchSampleMasterDp(): JSX.Element {
  const [query] = useQueryParams({
    page: NumberParam,
    queryArtist: StringParam,
    queryTitle: StringParam,
    artistId: StringParam,
    artistIds: StringParam,
    titleId: StringParam,
    variationId: StringParam,
    queryVariation: StringParam,
    queryCompany: StringParam,
    companyId: StringParam,
    variationIds: StringParam,
    titleIds: StringParam,
    companyIds: StringParam,
  });

  const {
    companyOptions,
    artistOptions,
    titleOptions,
    variationOptions,
    loadingArtist,
    loadingTitle,
    loadingVariation,
    loadingCompany,
    handleLoadMore,
    handleFocusInput,
    handleEnterPrimary,
    handleClickItemPrimary,
  } = useSuggestionSample({
    fetchList: ['company', 'artist', 'title', 'variation'],
    validQueryObj: {
      validCompany: {
        companyIds: undefined,
        queryCompany: undefined,
        companyId: undefined,
        artistIds: undefined,
        queryArtist: undefined,
        artistId: undefined,
        queryTitle: undefined,
        titleIds: undefined,
        titleId: undefined,
        queryVariation: undefined,
        variationIds: undefined,
        variationId: undefined,
      },
      validArtist: {
        artistIds: undefined,
        queryArtist: undefined,
        artistId: undefined,
        queryTitle: undefined,
        titleIds: undefined,
        titleId: undefined,
        queryVariation: undefined,
        variationIds: undefined,
        variationId: undefined,
      },
      validTitle: {
        queryTitle: undefined,
        titleIds: undefined,
        titleId: undefined,
        queryVariation: undefined,
        variationIds: undefined,
        variationId: undefined,
      },
      validVariation: {
        queryVariation: undefined,
        variationIds: undefined,
        variationId: undefined,
      },
    },
  });

  return (
    <Row className="px-2">
      <Col md="3" className="px-1 pe-3">
        <Search
          label="レコード会社"
          labelMd="12"
          colMd="12"
          placeholder="検索"
          showIcon
          classLabel="px-0"
          rowClass="w-100"
          colClass="px-0"
          searchKey="queryCompany"
          onEnter={() => handleEnterPrimary('queryCompany')}
          onClickResult={handleClickItemPrimary}
          options={companyOptions.options || []}
          loading={loadingCompany}
          onLoadMore={() => handleLoadMore('pageCompany')}
          onFocusInput={() => {
            handleFocusInput('queryCompany', 'pageCompany');
          }}
        />
      </Col>
      <Col md="3" className="px-1 pe-3">
        <Search
          label="アーティスト"
          labelMd="12"
          colMd="12"
          placeholder="検索"
          showIcon
          classLabel="px-0"
          rowClass="w-100"
          colClass="px-0"
          searchKey="queryArtist"
          isDisabled={!query?.queryCompany}
          onEnter={() => handleEnterPrimary('queryArtist')}
          onClickResult={handleClickItemPrimary}
          options={artistOptions.options || []}
          loading={loadingArtist}
          onLoadMore={() => handleLoadMore('pageArtist')}
          onFocusInput={() => {
            handleFocusInput('queryArtist', 'pageArtist');
          }}
        />
      </Col>
      <Col md="3" className="px-1 pe-3">
        <Search
          label="タイトル"
          labelMd="12"
          colMd="12"
          placeholder="検索"
          showIcon
          classLabel="px-0"
          rowClass="w-100"
          colClass="px-0"
          searchKey="queryTitle"
          isDisabled={!query?.queryArtist}
          onEnter={() => handleEnterPrimary('queryTitle')}
          onClickResult={handleClickItemPrimary}
          options={titleOptions.options || []}
          loading={loadingTitle}
          onLoadMore={() => handleLoadMore('pageTitle')}
          onFocusInput={() => {
            handleFocusInput('queryTitle', 'pageTitle');
          }}
        />
      </Col>
      <Col md="3" className="px-1 pe-3">
        <Search
          label="バリエーション"
          labelMd="12"
          colMd="12"
          placeholder="検索"
          showIcon
          classLabel="px-0"
          rowClass="w-100"
          colClass="px-0"
          searchKey="queryVariation"
          isDisabled={!query?.queryTitle}
          onEnter={() => handleEnterPrimary('queryVariation')}
          onClickResult={handleClickItemPrimary}
          options={variationOptions.options || []}
          loading={loadingVariation}
          onLoadMore={() => handleLoadMore('pageVariation')}
          onFocusInput={() => {
            handleFocusInput('queryVariation', 'pageVariation');
          }}
        />
      </Col>
    </Row>
  );
}
