import React, { useEffect, useState } from 'react';
import { Button, Container, Row } from 'react-bootstrap';
import SearchProductsDetail from './SearchProductsDetail';
import Filter from 'Components/Common/Filter/Filter';
import Tabs from 'Components/Common/Tabs/Tabs';
import ConfirmationComment from './TabProducts/ConfirmationComment';
import CampaignInformation from './TabProducts/CampaignInformation';
import TitleReview from './TabProducts/TitleReview';
import { StringParam, useQueryParams } from 'use-query-params';
import { useHistory, useParams } from 'react-router-dom';
import SampleMasterDp from './TabProducts/SampleMasterDp';

const btnGroupOptions = [
  { name: 'タイトル情報', value: 0 },
  { name: 'キャンペーン情報', value: 1 },
  { name: '確認コメント', value: 2 },
  { name: 'サンプル配信', value: 3 },
];

export default function ProductsDetail(): JSX.Element {
  const [tabActive, setTabActive] = useState(btnGroupOptions[2].name);
  const { id }: { id: string } = useParams();

  const history = useHistory();
  const handleChangeTabs = (tab: string) => {
    if (!tab) return;
    setQuery({ ...query, titleIds: query.titleIds || id });
    setTabActive(tab);
  };

  const [query, setQuery] = useQueryParams({
    queryArtist: StringParam,
    queryTitle: StringParam,
    queryCompany: StringParam,
    artistId: StringParam,
    artistIds: StringParam,
    titleIds: StringParam,
    companyIds: StringParam,
    companyId: StringParam,
  });

  useEffect(() => {
    id &&
      setQuery({
        queryArtist: query?.queryArtist,
        queryTitle: query?.queryTitle,
        queryCompany: query?.queryCompany,
        artistId: query?.artistId,
        artistIds: query?.artistId,
        titleIds: id,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const renderTab = () => {
    switch (tabActive) {
      case btnGroupOptions[0].name:
        return <TitleReview />;

      case btnGroupOptions[1].name:
        return <CampaignInformation />;

      default:
        return <ConfirmationComment />;
    }
  };

  return (
    <>
      <Filter
        filterClass={`shadow-sm ${
          tabActive === btnGroupOptions[0].name ? 'd-block' : 'd-flex align-items-end'
        }`}
      >
        <div
          className={` ${
            tabActive === btnGroupOptions[0].name
              ? 'product-detail-wrapper-search justify-content-between'
              : tabActive === btnGroupOptions[1].name || tabActive === btnGroupOptions[2].name
              ? 'd-flex align-items-end w-100 justify-content-between'
              : 'd-flex align-items-end w-100 justify-content-end'
          }`}
        >
          <Row
            className={`px-0  ${
              tabActive === btnGroupOptions[0].name
                ? 'd-flex align-items-end left-column-product-detail'
                : tabActive === btnGroupOptions[1].name || tabActive === btnGroupOptions[2].name
                ? 'w-product-55-md'
                : ''
            }`}
          >
            {tabActive !== btnGroupOptions[3].name ? <SearchProductsDetail /> : <></>}
          </Row>
          <div
            className={` ${
              tabActive === btnGroupOptions[0].name
                ? 'right-column-product-detail'
                : 'd-flex align-items-end d-flex'
            }`}
          >
            {tabActive === btnGroupOptions[0].name && (
              <Button
                onClick={() => history.push(`/detail-title/${query.titleIds}`)}
                className="me-3"
                disabled={!query.titleIds}
              >
                タイトルを編集
              </Button>
            )}
            <Tabs
              options={btnGroupOptions}
              tabActive={tabActive}
              onChange={handleChangeTabs}
              indexDefault={2}
            />
          </div>
        </div>
      </Filter>
      <Container fluid className="mt-4 px-4">
        {query?.titleIds && tabActive !== btnGroupOptions[3].name ? renderTab() : <></>}
        {tabActive === btnGroupOptions[3].name && <SampleMasterDp />}
      </Container>
    </>
  );
}
