import React, { useEffect } from 'react';
import { BarChart, Bar, XAxis, Cell, ResponsiveContainer, LabelList } from 'recharts';

import { GraphUp } from 'react-bootstrap-icons';
import {
  CHART_COLUMN_COLOR_1,
  CHART_FILL_COLOR_1,
  CHART_STROKE_COLOR_1,
  TEXT_DARK,
} from 'Constant/Colors';
import { IDashboardChart } from 'Datasource/DashboardApi';
import dayjs from 'dayjs';
import { DATE_FORMAT_10 } from 'Constant/Date';

interface ChartProps {
  title?: string;
  subtitle?: string;
  data: IDashboardChart[];
  dataIndex: string;
}
export default function StatisticChart({
  title,
  subtitle,
  data,
  dataIndex,
}: ChartProps): JSX.Element {
  const dataChart = data.map((item: IDashboardChart) => ({
    date: dayjs(item.date).format(DATE_FORMAT_10),
    saleNumber: item.saleNumber,
    enjoyNumber: item.enjoyNumber,
    auditionNumber: item.auditionNumber,
    giftNumber: item.giftNumber,
  }));

  useEffect(() => {
    const ele: any = document.getElementsByClassName('statistic-chart');
    ele[0].scrollLeft = 0;
    ele[1].scrollLeft = 0;
    ele[2].scrollLeft = 0;
    ele[3].scrollLeft = 0;
  }, [data]);

  return (
    <div className="mb-3">
      <div className="d-flex align-items-baseline">
        <GraphUp size={16} />
        <span className="fw-bold fs-18 ms-1">{title?.toUpperCase()}</span>
        <span className="fs-12 ms-3">{subtitle}</span>
      </div>
      <div className="statistic-chart" style={{ width: '100%', overflow: 'auto hidden' }}>
        <ResponsiveContainer
          height={300}
          width={dataChart.length > 40 ? dataChart.length * 30 : 1500}
        >
          <BarChart
            data={dataChart}
            margin={{
              top: 40,
              right: 20,
              bottom: 20,
              left: 20,
            }}
            style={{ width: dataChart.length > 30 ? `${dataChart.length * 30}px` : '1500px' }}
          >
            <Bar
              dataKey={dataIndex}
              fill={CHART_FILL_COLOR_1}
              stroke={CHART_STROKE_COLOR_1}
              strokeWidth={1}
              barSize={32}
              isAnimationActive={false}
            >
              {dataChart.map((entry: any, index: number) => {
                return <Cell key={`cell-${index}`} fill={CHART_COLUMN_COLOR_1} />;
              })}
              <LabelList dataKey={dataIndex} position="top" fill={TEXT_DARK} />
            </Bar>
            <XAxis height={60} dataKey="date" angle={-90} textAnchor="end" interval={0} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
