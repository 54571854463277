import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { map } from 'lodash';
import MessageError from 'Components/Common/MessageError';
import { CheckboxProps } from 'Components/Common/Form/GroupCheckBox/checkbox';
import { labelRequire } from 'Components/Common/Form/FormLabel';

function GroupCheckBox(props: CheckboxProps): JSX.Element {
  const {
    id,
    name,
    labelForm,
    classForm,
    colClass,
    errorMessage,
    options,
    labelMd,
    colMd,
    classLabel,
    onChange,
    value,
    onValue,
    register,
    disabled = false,
    isReview = false,
    classBoxErr,
    classOption = '',
    hintText,
  } = props;

  const handleOnchange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const checked = e.currentTarget.checked;
    let newOptions = null;
    if (!onChange) {
      return;
    }
    newOptions = map(options, (option, optionKey) => ({
      ...option,
      checked: optionKey === index ? checked : false,
    }));
    return onChange(newOptions);
  };

  return (
    <Form.Group as={Row} className={`${classForm ?? ''}`}>
      {labelForm && (
        <Form.Label column md={labelMd}>
          {labelRequire(labelForm, classLabel)}
        </Form.Label>
      )}
      <Col md={colMd} className={colClass}>
        <div className="d-flex w-100 align-items-center box-checkBox flex-wrap">
          {options &&
            options.map((item, index) => {
              return (
                <Form.Check
                  {...register}
                  key={index}
                  inline
                  label={item.label}
                  name={name || 'check-box-group'}
                  type="radio"
                  value={item.value}
                  isValid={item.subLabel ? true : false}
                  checked={value ? value === item.value : item.checked}
                  onChange={(e) => {
                    if (isReview) return;
                    onValue ? onValue(item.value ?? '', index) : handleOnchange(e, index);
                  }}
                  id={`${id}-${index}`}
                  feedback={item.subLabel}
                  className={`check-box-ct mb-0 ${classOption}`}
                  disabled={disabled}
                />
              );
            })}
        </div>
        {hintText && <p className="fs-12">{hintText}</p>}
        <div className={classBoxErr}>
          <MessageError message={errorMessage} />
        </div>
      </Col>
    </Form.Group>
  );
}

export default GroupCheckBox;
