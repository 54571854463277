import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CAMPAIGN_INITIAL_STATE } from 'Constant/Campaign';

export enum CampaignSteps {
  Overview = 0,
  CourseAndMethod = 1,
  SignUpRewards = 2,
  WinningConditions = 3,
  Confirm = 4,
  Success = 5,
}

const initialState: any = { ...CAMPAIGN_INITIAL_STATE };

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    updateCampaign: (state, data: PayloadAction<any>) => {
      const { overview, courses } = data.payload;
      state.overview = overview;
      state.courses = courses;
    },
    updateStep1State: (state, data: PayloadAction<any>) => {
      const { overview } = data.payload;
      state.overview = overview;
    },
    updateStep2State: (state, data: PayloadAction<any>) => {
      const { courses } = data.payload;
      state.courses = courses;
    },
    resetData: (state) => {
      state = { ...CAMPAIGN_INITIAL_STATE };
      return state;
    },
  },
});

export const { updateStep1State, updateStep2State, resetData, updateCampaign } =
  campaignSlice.actions;
export default campaignSlice.reducer;
