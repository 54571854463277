import React from 'react';
import Filter from 'Components/Common/Filter/Filter';
import PaginationComponent from 'Components/Common/Pagination/Pagination';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import TableBT from 'Components/Common/Table/TableBT';
import ECListSaleProductSearch from 'Components/Page/EC/ECListSaleProductSearch';
import Tabs from 'Components/Page/EC/ECListSaleProductTabs';
import { PER_PAGE_EC } from 'Constant';
import { DATE_FORMAT_2 } from 'Constant/Date';
import { ECApis, QuerySaleProductsParams } from 'Datasource/EC';
import useTitlePage from 'Hooks/useTitlePage';
import { endOfDayToGMT, startOfDayToGMT } from 'Utils/DateTime';
import { exportExcel } from 'Utils/File';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import { SortMethod } from 'Components/Common/Table/table';
import ECListSaleStatus from 'Components/Page/EC/ECListSaleStatus';
import useMessage from 'Hooks/useMessage';
import dayjs from 'dayjs';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { toast } from 'react-toastify';

const btnGroupOptions = [
  { name: 'タイトルベース一覧', value: 0 },
  { name: 'キャンペーンベース一覧', value: 1 },
];

export default function ECListSaleProducts(): JSX.Element {
  const [indexTabDefault] = useState<number>(0);
  const [saleProducts, setSaleProducts] = useState([]);
  const [saleProductsTotal, setSaleProductsTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const [dataQuery, setDataQuery] = useState<QuerySaleProductsParams>({});

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    queryArtist: StringParam,
    queryTitle: StringParam,
    artistId: StringParam,
    titleId: StringParam,
    campaignId: StringParam,
    statusId: StringParam,
    saleIds: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    type: withDefault(StringParam, '0', true),
    sort: StringParam,
    order: StringParam,
    artistIds: StringParam,
    titleIds: StringParam,
    campaignIds: StringParam,
  });

  const { setMenuTitle } = useTitlePage();

  const [tabActive, setTabActive] = useState(btnGroupOptions[0].name);

  const [activePage, setActivePage] = useState(query.page ?? 1);
  const [exporting, setExporting] = useState<boolean>(false);

  const { openMessageError } = useMessage();

  const handleChangeTabs = (tab: string) => {
    if (!tab) return;
    setTabActive(tab);
  };

  const columnsTitle = useMemo(
    () => [
      {
        Header: 'ID',
        className: 'd-flex align-items-center justify-content-center',
        accessor: 'id',
        width: 100,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="d-flex align-items-center justify-content-center h-100 p-2">
              <span className="text-start">{row.original?.productId}</span>
            </div>
          );
        },
      },
      {
        Header: '販売用ID',
        className: 'd-flex align-items-center justify-content-center',
        accessor: 'cardCd',
        width: 300,
        Cell: ({ row }: Cell<any>) => {
          return row.original?.cdId === null ? (
            <div className="d-flex align-items-center h-100 p-2">
              <Link
                to={`/ec/sale-product-detail/${row.original?.ecProductsChannelDetailId}`}
                // to={`/campaigns/${row.original?.id}`}
                // onClick={() => {
                //   dispatch(updateMailList({ mailList: [], isSuccess: false }));
                // }}
                className="d-block text-break"
              >
                <span className="text-start">{row.original?.cardCd}</span>
              </Link>
            </div>
          ) : (
            <div className="d-block text-break h-100 p-2">
              <span className="text-start">物販商品</span>
            </div>
          );
        },
      },
      {
        Header: '管理名称',
        className: 'd-flex align-items-center',
        accessor: 'productName',
        width: 234,
        Cell: ({ row }: Cell<any>) => {
          return row.original?.productType === 0 ? (
            <div className="d-flex align-items-center h-100 p-2">
              <span className="text-start">マスタ</span>
            </div>
          ) : (
            <div className="d-flex align-items-center h-100 p-2">
              <span className="text-start text-truncate">{row.original?.productName}</span>
            </div>
          );
        },
      },
      {
        Header: '販売チャネル',
        className: 'd-flex align-items-center',
        accessor: 'channelName',
        width: 234,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="d-flex align-items-center h-100 p-2">
              <span className="text-start text-truncate">{row.original?.channelName}</span>
            </div>
          );
        },
      },
      {
        Header: 'アーティスト',
        className: 'd-flex align-items-center',
        accessor: 'artistName',
        width: 234,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="d-flex align-items-center h-100 p-2">
              <span className="text-start text-truncate">{row.original?.artistName}</span>
            </div>
          );
        },
      },
      {
        Header: 'タイトル',
        className: 'd-flex align-items-center',
        accessor: 'titleName',
        width: 359,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="d-flex align-items-center h-100 p-2">
              {/* <Link
                to={`/title-detail/${row.original?.titleId}`}
                // to={`/campaigns/${row.original?.id}`}
                // onClick={() => {
                //   dispatch(updateMailList({ mailList: [], isSuccess: false }));
                // }}
                className="d-block text-break"
              > */}
              <span className="text-start text-truncate">{row.original?.titleName}</span>
              {/* </Link> */}
            </div>
          );
        },
      },
      {
        Header: 'バリエーション',
        className: 'd-flex align-items-center',
        accessor: 'variationName',
        width: 234,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="d-flex align-items-center h-100 p-2">
              <span className="text-start text-truncate">{row.original?.variationName}</span>
            </div>
          );
        },
      },
      {
        Header: '情報公開解禁日時',
        className: 'd-flex align-items-center',
        accessor: 'saleStartDate',
        width: 150,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="d-flex align-items-center justify-content-center h-100 p-2">
              <span className="text-start">
                {row.original?.saleStartDate
                  ? dayjs(row.original?.saleStartDate).format(DATE_FORMAT_2)
                  : null}
              </span>
            </div>
          );
        },
      },
      {
        Header: 'ステータス',
        className: 'd-flex align-items-center',
        accessor: 'status',
        width: 140,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="d-flex align-items-center h-100 p-2">
              <span className="text-start">
                <ECListSaleStatus status={row.original?.status} />
              </span>
            </div>
          );
        },
      },
      {
        Header: 'プレビュー',
        className: 'd-flex align-items-center',
        width: 140,
        disableSortBy: true,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="d-flex align-items-center justify-content-center h-100 p-2">
              <CopyToClipboard
                onCopy={() => {
                  toast('クリップボードにコピーしました。', { type: 'success' });
                }}
                text={`${row.original?.preview}`}
              >
                <div>
                  <svg
                    style={{ marginRight: '5px', cursor: 'pointer' }}
                    xmlns="http://www.w3.org/2000/svg"
                    width={22}
                    height={22}
                    viewBox="0 0 32 32"
                  >
                    <g fill="none" fillRule="evenodd">
                      <path
                        fill="#005CD9"
                        fillRule="nonzero"
                        d="M4,8 L4,28 L24,28 L24,8 L4,8 Z M4,6 L24,6 C25.1045695,6 26,6.8954305 26,8 L26,28 C26,29.1045695 25.1045695,30 24,30 L4,30 C2.8954305,30 2,29.1045695 2,28 L2,8 C2,6.8954305 2.8954305,6 4,6 Z"
                      />
                      <path
                        fill="#005CD9"
                        fillRule="nonzero"
                        d="M8,6 L6,6 L6,4 C6,2.8954305 6.8954305,2 8,2 L28,2 C29.1045695,2 30,2.8954305 30,4 L30,24 C30,25.1045695 29.1045695,26 28,26 L26,26 L26,24 L28,24 L28,4 L8,4 L8,6 Z"
                      />
                    </g>
                  </svg>
                </div>
              </CopyToClipboard>
              <a
                href={`${row.original?.preview}`}
                target="_blank"
                rel="noreferrer"
                className="d-block text-break"
              >
                <span className="text-start">preview</span>
              </a>
            </div>
          );
        },
      },
      {
        Header: '本番URL',
        className: 'd-flex align-items-center',
        width: 300,
        disableSortBy: true,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="d-flex align-items-center h-100 p-2">
              <CopyToClipboard
                onCopy={() => {
                  toast('クリップボードにコピーしました。', { type: 'success' });
                }}
                text={`${row.original?.urlProduction}`}
              >
                <div>
                  <svg
                    style={{ marginRight: '5px', cursor: 'pointer' }}
                    xmlns="http://www.w3.org/2000/svg"
                    width={22}
                    height={22}
                    viewBox="0 0 32 32"
                  >
                    <g fill="none" fillRule="evenodd">
                      <path
                        fill="#005CD9"
                        fillRule="nonzero"
                        d="M4,8 L4,28 L24,28 L24,8 L4,8 Z M4,6 L24,6 C25.1045695,6 26,6.8954305 26,8 L26,28 C26,29.1045695 25.1045695,30 24,30 L4,30 C2.8954305,30 2,29.1045695 2,28 L2,8 C2,6.8954305 2.8954305,6 4,6 Z"
                      />
                      <path
                        fill="#005CD9"
                        fillRule="nonzero"
                        d="M8,6 L6,6 L6,4 C6,2.8954305 6.8954305,2 8,2 L28,2 C29.1045695,2 30,2.8954305 30,4 L30,24 C30,25.1045695 29.1045695,26 28,26 L26,26 L26,24 L28,24 L28,4 L8,4 L8,6 Z"
                      />
                    </g>
                  </svg>
                </div>
              </CopyToClipboard>
              <a
                href={`${row.original?.urlProduction}`}
                onClick={(e) => {
                  e.preventDefault();
                  if (!row.original?.isPublic || [0, 1, 5].includes(row.original?.status)) {
                    toast('商品は非公開中です。', { type: 'error' });
                  } else {
                    window.open(`${row.original?.urlProduction}`, '_blank');
                  }
                }}
                className="d-block text-break text-truncate"
              >
                <span className="text-start">{`${row.original?.urlProduction}`}</span>
              </a>
            </div>
          );
        },
      },
    ],
    [],
  );

  const columnsCampaign = useMemo(
    () => [
      {
        Header: 'ID',
        className: 'd-flex align-items-center justify-content-center',
        accessor: 'id',
        width: 100,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="d-flex align-items-center justify-content-center h-100 p-2">
              <span className="text-start">{row.original?.productId}</span>
            </div>
          );
        },
      },
      {
        Header: '販売用ID',
        className: 'd-flex align-items-center justify-content-center',
        accessor: 'cardCd',
        width: 300,
        Cell: ({ row }: Cell<any>) => {
          return row.original?.cdId === null ? (
            <div className="d-flex align-items-center h-100 p-2">
              <Link
                to={`/ec/sale-product-detail/${row.original?.ecProductsChannelDetailId}`}
                // to={`/campaigns/${row.original?.id}`}
                // onClick={() => {
                //   dispatch(updateMailList({ mailList: [], isSuccess: false }));
                // }}
                className="d-block text-break"
              >
                <span className="text-start">{row.original?.cardCd}</span>
              </Link>
            </div>
          ) : (
            <div className="d-block text-break h-100 p-2">
              <span className="text-start">物販商品</span>
            </div>
          );
        },
      },
      {
        Header: '管理名称',
        className: 'd-flex align-items-center',
        accessor: 'productName',
        width: 234,
        Cell: ({ row }: Cell<any>) => {
          return row.original?.productType === 0 ? (
            <div className="d-flex align-items-center h-100 p-2">
              <span className="text-start">マスタ</span>
            </div>
          ) : (
            <div className="d-flex align-items-center h-100 p-2">
              <span className="text-start text-truncate">{row.original?.productName}</span>
            </div>
          );
        },
      },
      {
        Header: '販売チャネル',
        className: 'd-flex align-items-center',
        accessor: 'channelName',
        width: 234,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="d-flex align-items-center h-100 p-2">
              <span className="text-start text-truncate">{row.original?.channelName}</span>
            </div>
          );
        },
      },
      {
        Header: 'キャンペーン',
        className: 'd-flex align-items-center',
        accessor: 'campaignName',
        width: 234,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="d-flex align-items-center h-100 p-2">
              <span className="text-start text-truncate">{row.original?.campaignName}</span>
            </div>
          );
        },
      },
      {
        Header: 'アーティスト',
        className: 'd-flex align-items-center',
        accessor: 'artistName',
        width: 234,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="d-flex align-items-center h-100 p-2">
              <span className="text-start text-truncate">{row.original?.artistName}</span>
            </div>
          );
        },
      },
      {
        Header: 'タイトル',
        className: 'd-flex align-items-center',
        accessor: 'titleName',
        width: 359,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="d-flex align-items-center h-100 p-2">
              {/* <Link
                to={`/title-detail/${row.original?.titleId}`}
                // to={`/campaigns/${row.original?.id}`}
                // onClick={() => {
                //   dispatch(updateMailList({ mailList: [], isSuccess: false }));
                // }}
                className="d-block text-break"
              > */}
              <span className="text-start text-truncate">{row.original?.titleName}</span>
              {/* </Link> */}
            </div>
          );
        },
      },
      {
        Header: 'バリエーション',
        className: 'd-flex align-items-center',
        accessor: 'variationName',
        width: 234,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="d-flex align-items-center h-100 p-2">
              <span className="text-start text-truncate">{row.original?.variationName}</span>
            </div>
          );
        },
      },
      {
        Header: 'ステータス',
        className: 'd-flex align-items-center',
        accessor: 'status',
        width: 140,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="d-flex align-items-center h-100 p-2">
              <span className="text-start">
                <ECListSaleStatus status={row.original?.status} />
              </span>
            </div>
          );
        },
      },
      {
        Header: 'プレビュー',
        className: 'd-flex align-items-center',
        width: 140,
        disableSortBy: true,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="d-flex align-items-center justify-content-center h-100 p-2">
              <CopyToClipboard
                onCopy={() => {
                  toast('クリップボードにコピーしました。', { type: 'success' });
                }}
                text={`${row.original?.preview}`}
              >
                <div>
                  <svg
                    style={{ marginRight: '5px', cursor: 'pointer' }}
                    xmlns="http://www.w3.org/2000/svg"
                    width={22}
                    height={22}
                    viewBox="0 0 32 32"
                  >
                    <g fill="none" fillRule="evenodd">
                      <path
                        fill="#005CD9"
                        fillRule="nonzero"
                        d="M4,8 L4,28 L24,28 L24,8 L4,8 Z M4,6 L24,6 C25.1045695,6 26,6.8954305 26,8 L26,28 C26,29.1045695 25.1045695,30 24,30 L4,30 C2.8954305,30 2,29.1045695 2,28 L2,8 C2,6.8954305 2.8954305,6 4,6 Z"
                      />
                      <path
                        fill="#005CD9"
                        fillRule="nonzero"
                        d="M8,6 L6,6 L6,4 C6,2.8954305 6.8954305,2 8,2 L28,2 C29.1045695,2 30,2.8954305 30,4 L30,24 C30,25.1045695 29.1045695,26 28,26 L26,26 L26,24 L28,24 L28,4 L8,4 L8,6 Z"
                      />
                    </g>
                  </svg>
                </div>
              </CopyToClipboard>
              <a
                href={`${row.original?.preview}`}
                // to={`/campaigns/${row.original?.id}`}
                // onClick={() => {
                //   dispatch(updateMailList({ mailList: [], isSuccess: false }));
                // }}
                target="_blank"
                rel="noreferrer"
                className="d-block text-break"
              >
                <span className="text-start">preview</span>
              </a>
            </div>
          );
        },
      },
      {
        Header: '本番URL',
        className: 'd-flex align-items-center',
        width: 300,
        disableSortBy: true,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="d-flex align-items-center h-100 p-2">
              <CopyToClipboard
                onCopy={() => {
                  toast('クリップボードにコピーしました。', { type: 'success' });
                }}
                text={`${row.original?.urlProduction}`}
              >
                <div>
                  <svg
                    style={{ marginRight: '5px', cursor: 'pointer' }}
                    xmlns="http://www.w3.org/2000/svg"
                    width={22}
                    height={22}
                    viewBox="0 0 32 32"
                  >
                    <g fill="none" fillRule="evenodd">
                      <path
                        fill="#005CD9"
                        fillRule="nonzero"
                        d="M4,8 L4,28 L24,28 L24,8 L4,8 Z M4,6 L24,6 C25.1045695,6 26,6.8954305 26,8 L26,28 C26,29.1045695 25.1045695,30 24,30 L4,30 C2.8954305,30 2,29.1045695 2,28 L2,8 C2,6.8954305 2.8954305,6 4,6 Z"
                      />
                      <path
                        fill="#005CD9"
                        fillRule="nonzero"
                        d="M8,6 L6,6 L6,4 C6,2.8954305 6.8954305,2 8,2 L28,2 C29.1045695,2 30,2.8954305 30,4 L30,24 C30,25.1045695 29.1045695,26 28,26 L26,26 L26,24 L28,24 L28,4 L8,4 L8,6 Z"
                      />
                    </g>
                  </svg>
                </div>
              </CopyToClipboard>
              <a
                href={`${row.original?.urlProduction}`}
                onClick={(e) => {
                  e.preventDefault();
                  if (!row.original?.isPublic || [0, 1, 5].includes(row.original?.status)) {
                    toast('商品は非公開中です。', { type: 'error' });
                  } else {
                    window.open(`${row.original?.urlProduction}`, '_blank');
                  }
                }}
                className="d-block text-break text-truncate"
              >
                <span className="text-start">{`${row.original?.urlProduction}`}</span>
              </a>
            </div>
          );
        },
      },
    ],
    [],
  );

  const handleGetSaleProducts = async (queries: QuerySaleProductsParams) => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await ECApis.getSaleProducts(queries);

      setSaleProducts(data.items);
      setSaleProductsTotal(data?.meta?.itemCount);
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleExportExcel = async () => {
    try {
      setExporting(true);
      const newDataQuery = { ...dataQuery };

      if (newDataQuery?.page) {
        delete newDataQuery.page;
      }

      if (newDataQuery?.sort) {
        delete newDataQuery.sort;
      }
      if (query?.type === '0') {
        await exportExcel(
          ECApis.saleProductDownloadExcel(newDataQuery),
          `EC販売商品リスト_タイトルベース一覧_${dayjs().format('YYYYMMDDHHmmss')}`,
        );
      }
      if (query?.type === '1') {
        await exportExcel(
          ECApis.saleProductDownloadExcel(newDataQuery),
          `EC販売商品リスト_キャンペーンベース一覧_${dayjs().format('YYYYMMDDHHmmss')}`,
        );
      }
    } catch (error) {
      // openErrorBlob(error);
    } finally {
      setExporting(false);
    }
  };

  useEffect(() => {
    setMenuTitle('EC販売商品リスト');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const queries = {
      limit: PER_PAGE_EC,
      page: activePage,
      artistId: query.artistId,
      titleId: query.titleId,
      campaignId: query.campaignId,
      statusId: query.statusId,
      saleIds: query.saleIds,
      startDate: query.startDate ? startOfDayToGMT(query.startDate) : null,
      endDate: query.endDate ? endOfDayToGMT(query.endDate) : null,
      type: query.type,
      sort: query.sort || '',
      order: query.order || '',
      artistIds: query.artistIds,
      titleIds: query.titleIds,
      campaignIds: query.campaignIds,
    };
    setDataQuery(queries);
    handleGetSaleProducts(queries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activePage,
    query.artistId,
    query.titleId,
    query.campaignId,
    query.statusId,
    query.type,
    query.startDate,
    query.endDate,
    query.saleIds,
    query.sort,
    query.order,
    query.artistIds,
    query.titleIds,
    query.campaignIds,
  ]);

  //* handle sort from server side
  const handleManualSort = useCallback(
    (sortBy: SortMethod[]) => {
      if (sortBy.length > 0) {
        const sortOption = sortBy[0];
        const sortKey = sortOption.id;
        setQuery({ sort: sortKey, order: sortOption.desc ? 'DESC' : 'ASC' });
      } else {
        setQuery({ sort: '', order: '' });
      }
    },
    [setQuery],
  );

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <Container fluid>
        <Filter filterClass="shadow-sm">
          <div className="mb-3">
            <Tabs
              options={btnGroupOptions}
              tabActive={tabActive}
              onChange={handleChangeTabs}
              indexDefault={indexTabDefault}
            />
          </div>
          <Row className="align-items-end justify-content-end">
            <Col md="10" className="px-0">
              <ECListSaleProductSearch />
            </Col>
            <Col md="2" className="text-end mt-3 px-0">
              <Button
                variant="primary"
                className={`btn-excel w-btn btn-focus-none ms-4 ${exporting ? 'pe-none' : ''}`}
                onClick={handleExportExcel}
              >
                {exporting ? (
                  <Spinner
                    animation="border"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  'Excel ダウンロード'
                )}
              </Button>
            </Col>
          </Row>
        </Filter>
        <Row className="mt-3">
          <Col md={12} className="d-flex justify-content-end p-0 px-4">
            <PaginationComponent
              activePage={activePage}
              total={saleProductsTotal}
              perPage={PER_PAGE_EC}
              onClick={(newPage) => setActivePage(newPage)}
            />
          </Col>
        </Row>
        <div className="table-nft-container">
          <Row className="mt-3">
            <Col md={12} className="d-flex justify-content-center p-0 px-4">
              <TableBT
                heightBody="calc(100vh - 250px)"
                columns={query?.type === '1' ? columnsCampaign : columnsTitle}
                data={saleProducts}
                getCellProps={() => ({
                  className: 'p-0',
                })}
                manualSortBy
                onSort={handleManualSort}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}
