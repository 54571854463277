import React from 'react';
import { Container } from 'react-bootstrap';
import BrLogo from 'Static/Images/br-logo.png';
import 'Page/TermAndPolicy/index.scss';

export default function Terms(): JSX.Element {
  return (
    <div className="position-relative">
      <Container className="d-flex flex-column justify-content-center align-items-center min-vh-100">
        <h3 className="text-center mb-2">miim利用規約</h3>
        <div className="w-100 mt-1 termsAndPolicy">
          <ol>
            <div className="mb-2 mt-2">第1条 （本利用規約について）</div>

            <li>
              miim利用規約（以下「本利用規約」といいます）は、株式会社ブートロック（以下「当社」といいます）が提供するサービス及びアプリケーション「miim」（以下「本サービス」といいます）をユーザーが利用する際の一切の行為に適用されます。
            </li>
            <li>
              ユーザーは、本サービスを利用することにより、本利用規約の全ての記載内容について同意したものとみなされます。また、ユーザーは、本サービスを利用する毎に、本サービス上に表示される注意事項を確認の上、同意するものとします
            </li>
          </ol>
        </div>
        <div className="w-100 mt-1 termsAndPolicy">
          <ol>
            <div className="mb-2 mt-2">第２条（未成年者のサービス利用）</div>

            <li>
              未成年者が本サービスを利用する場合、商品役務の購入等有償サービスの利用を含む本サービスの一切の利用（本条各項を含む本利用規約への同意を含みます）について、法定代理人（親権者等）の承諾を要するものとします。
            </li>
            <li>
              未成年者が、本利用規約に定める法定代理人の承諾がないにもかかわらず、承諾があると偽って本サービスを利用した場合、また、年齢について成年と偽って本サービスを利用した場合、その他「行為能力者であることを信じさせるため詐術を用いたとき」に該当する場合、当該未成年者は、本サービスにおける一切の法律行為を取消すことができません。
            </li>
            <li>
              本利用規約合意時に未成年であったユーザーが、成年に達した後に本サービスを利用した場合、これにより、当該ユーザーは、本サービスの利用に係る一切の法律行為について、追認したものとみなされます。
            </li>
          </ol>
        </div>
        <div className="w-100 mt-1 termsAndPolicy">
          <ol>
            <div className="mb-2 mt-2">第３条（登録）</div>

            <li>
              本サービスの利用には、本サービスへの登録が必要となる場合があります。ユーザーは、登録にあたり、虚偽の情報を入力し、又は他人へのなりすまし等を行ってはならないものとし、また、変更がある場合には速やかに修正を行うものとします。
            </li>
            <li>
              ユーザーは、本サービスにおけるID・パスワード等を善良なる管理者として管理するものとし、これらを用いた本サービス上の意思表示は、当該ユーザーによる有効なものとみなされます。
            </li>
            <li>
              ユーザーは、アカウント・ID・パスワード等の譲渡・貸与・名義貸し・売買等を行ってはならないものとします。
            </li>

            <li>
              当社は、次に掲げる事由がある場合には、登録を認めない、あるいは取り消すことができるものとします。
              <ul>・ユーザーが本利用規約のいずれかに違反する場合、又は違反したことがある場合</ul>
              <ul>
                ・ユーザーが過去に本サービスの登録・利用資格等を停止・抹消等されたことがある場合
              </ul>
              <ul>・既に登録されているIDやメールアドレスで新たに会員登録を申し込んだ場合</ul>
              <ul>・その他当社が登録を認めないことが適切と判断した場合</ul>
            </li>
          </ol>
        </div>

        <div className="w-100 mt-1 termsAndPolicy">
          <ol>
            <div className="mb-1 mt-2">第４条（利用地域）</div>
            <div className="mb-2">本サービスの利用地域・提供対象地域は、日本国内とします。</div>
          </ol>
        </div>

        <div className="w-100 mt-1 termsAndPolicy">
          <ol>
            <div className="mb-2 mt-2">第５条（本サービス）</div>

            <li>
              本サービスは、当社の取引先（以下「販売者」といいます）からユーザーが購入したデジタルパッケージ商品（以下「ＤＰ」といいます）を視聴等するためのサービスです。なお、本サービス内でＤＰを購入することはできません。ＤＰの販売主体は販売者であり、ＤＰに関する購入契約は販売者とユーザー間において成立します。
            </li>
            <li>
              本サービスにおいて、当社・販売者その他第三者の指定する特定のコンテンツについて、その使用権を他のユーザーに譲渡する機能（以下「ギフト機能」といいます）をユーザーが利用する場合、ユーザーは、当社が別途定めるギフト機能のルールを遵守するものとします。
            </li>
            <li>
              当社は、ユーザーへの事前の許可・通知等なく、本サービスの仕様等の変更を行うことができます。
            </li>
            <li>
              ＤＰの販売に伴い提供される「特典」のうち別途指定する一部のもの（特典券、入場券、シリアル番号等）の利用等については、本サービスの登録・使用等が必須となる場合があります。本サービスの未登録・不使用しないこと等により特典の利用ができない場合があることを、ユーザーはあらかじめ了解するものとします。
            </li>
          </ol>
        </div>

        <div className="w-100 mt-1 termsAndPolicy">
          <ol>
            <div className="mb-1 mt-2">第６条（利用環境）</div>
            <div className="mb-2">
              本サービスを利用するために必要な、ハードウェア・ソフトウェア・通信回線等の準備・費用については、ユーザーの負担とします。なお、ハードウェア等の必要仕様条件等については、別途当社が本サービス上にて掲示します。また、ユーザーは、必要に応じて、ＤＰ・特典等のバックアップを自らの責任で行うものとします。
            </div>
          </ol>
        </div>

        <div className="w-100 mt-1 termsAndPolicy">
          <ol>
            <div className="mb-2 mt-2">第７条（保証）</div>

            <li>
              本サービスは、当社がその時点で提供可能なものとします。当社は提供する情報、コンテンツおよびソフトウェア等の情報・サービス等についてその完全性、正確性、適用性、有用性、利用可能性、安全性、確実性等につきいかなる保証も一切しません。また、当社は、ユーザーと販売者間におけるＤＰに関する取引について、一切関与しないものとし、ユーザーは予めこれを了承します。
            </li>
            <li>
              ユーザーが、本サービスを利用することにより、第三者に対し損害を与えた場合、
              ユーザーは自己の費用と責任においてこれを賠償するものとします。
            </li>
            <li>
              当社は、本サービスに発生した不具合、エラー、障害等により本サービスが利用できないことによって引き起こされた損害について一切の賠償責任を負いません。
            </li>
            <li>
              当社は、ユーザーのハードウェア・ソフトウェア・通信環境等に起因して、本サービスを正常に利用できないことにより被った損害について一切の賠償責任を負わないものとし、かつ一切の対応を行わないものとします。また、当社は、ユーザーが登録情報を自ら紛失、忘却したことにより被った損害について一切の賠償責任を負わないものとし、かつ本サービスの利用復帰等の一切の対応を行わないものとします。
            </li>
            <li>
              当社は、以下のいずれかに該当する損害等については、一切責任を負わないものとします。
              <ul>・仕様書（マニュアル）等に反した使用により生じた損害等</ul>
              <ul>・推奨していない環境又は条件における本サービスの使用により生じた損害等</ul>
              <ul>・使用利益の損失、逸失利益、データ・無体財産の喪失により生じた損害等</ul>
              <ul>・派生的または結果的損失、間接損害、特別損害</ul>
              <ul>・バックアップ等を行わなかったことにより生じる損害等</ul>
              <ul>・ＤＰ及び特典の内容等に関する問題等</ul>
            </li>
            <li>
              当社はユーザーに対して、適宜情報提供やアドバイスを行うことがありますが、その結果について責任を負わないものとします。
            </li>
            <li>
              当社は、本サービスのバグその他を補修する義務および本サービスを改良または改善する義務は負いません。
            </li>
            <li>
              本利用規約が消費者契約法第２条第３項の消費者契約に該当し、かつ、当社が債務不履行または不法行為に基づき損害賠償責任を負う場合については、当社に故意または重大な過失がある場合を除いて、当社は、当該ユーザーが直接かつ現実に被った損害につき当該債務不履行・不法行為に係るＤＰの取引額を上限として損害賠償責任を負うものとし、特別な事情から生じた損害等（損害発生につき予見し、または予見し得た場合を含みます）については責任を負わないものとします。
            </li>
            <li>
              本サービスの利用に関連して提供される、第三者の提供する商品役務等につき、当社は、一切の保証を行いません。
            </li>
            <li>
              販売者が販売するＤＰ等その他商品役務につき、その品質、材質、機能、性能、他のＤＰとの適合性、及びこれらが原因となり生じた損害、損失、不利益等については、当社は一切保証を行いません。
            </li>
            <li>
              当社は、本サービスについて、以下のいずれかに該当する場合（これらに類する事情が生じた場合を含む）、本サービスの提供の中断その他必要となる措置を採る場合があり、この場合何らの責任を負わないものとし、ユーザーは予めこれを承諾します。
              <ul>① システムの保守を定期的又は緊急に行う場合。</ul>
              <ul>② ネットワーク障害が発生した場合。</ul>
              <ul>③ 火災、停電等によりサービスの提供ができなくなった場合。</ul>
              <ul>④ 地震、噴火、洪水、津波等の天災によりサービスの提供ができなくなった場合。</ul>
              <ul>
                ⑤ 戦争、テロ、動乱、暴動、騒乱、労働争議等によりサービスの提供ができなくなった場合。
              </ul>
              <ul>⑥ ユーザーが本契約に違反する行為を行った場合。</ul>
              <ul>
                ⑦ コンピュータウイルス、ハッキングなどに対するセキュリティ上の緊急対策を行う場合。
              </ul>
              <ul>⑧ その他、運用上あるいは技術上サービスの一時的な中断が必要と判断した場合。</ul>
              <ul>
                ⑨
                利用するクラウドサービス等において障害発生・規約変更・仕様変更・サービス中断その他サービスの提供ができなくなる事情があった場合。
              </ul>
            </li>
            <li>
              本サービスに不具合等が発見された場合において、本サービスの当該不具合部分又はその原因が外部の開発者・メーカー・関連するサービス（クラウドサービスを含む）の提供者等（以下「開発元メーカー」といいます）により提供されるものに依拠するものである場合において、開発元メーカーによる改善が実施されない場合には、その不具合等を本サービスの制約とすることができるものとします。
            </li>
            <li>
              本サービスに関連して、ユーザーが第三者の提供する商品役務等の利用を行う場合、当該利用等については当該第三者の定める利用規約その他規定・合意等が適用されます。
            </li>
          </ol>
        </div>

        <div className="w-100 mt-1 termsAndPolicy">
          <ol>
            <div className="mb-2 mt-2">第８条（個人情報）</div>
            <li>
              当社は、別途定める個人情報保護方針に基づき、ユーザーから提供を受けた個人情報を厳重な管理体制のもとで管理・保管し、明示的に定める場合を除き、第三者に開示・漏えいすることのないように、個人情報の保護に努めます。
            </li>
            <li>
              当社は、本サービスに基づく取引を通じてユーザーから収集した個人を識別できる情報を、本サービスを提供する目的以外に、以下に定める目的のために利用することがあります。
              <ul>・ユーザーに対して当社から意見等の提供を求めるため</ul>
              <ul>・ユーザーからの問い合わせに対応するため</ul>
              <ul>・当社・販売者又は提携先の他の商品・サービス案内等の送付</ul>
              <ul>・当社のマーケティング・商品開発・サービス改善に利用するため</ul>
            </li>
            <li>
              当社は、以下に該当する場合を除き、ユーザーの個人情報を第三者に開示しません。
              <ul>
                ・ユーザーに本サービスを提供するうえで必要となる業務を第三者に委託する場合（なお、決済代行会社において必要となる情報を保管・管理等する場合がございます）
              </ul>
              <ul>・事前にユーザーの承諾を得た場合</ul>
              <ul>・法令により開示が要求された場合</ul>
              <ul>
                ・当社・ユーザーまたは第三者の生命・財産その他の権利を保護するために開示する必要のある場合
              </ul>
            </li>
            <li>
              ユーザー本人の個人情報の開示・訂正・削除等に係る手続その他ご相談については、当社所定の問い合わせフォームまでご通知下さい。なお、当社所定の本人確認手続が必要となる場合があります。
            </li>
            support@mi-im.com
            <li>
              当社は、ユーザーの個人情報について、特定の個人を識別することおよび作成に用いる個人情報を復元することができないよう適切な保護措置を講じたうえで、匿名加工情報の作成と第三者への提供を法令で認められた範囲で実施します。なお、匿名加工情報に含まれる個人に関する情報は、ユーザーの属性等、本サービスの利用履歴等その他プライバシーポリシー等に定める事項となります。
            </li>
          </ol>
        </div>

        <div className="w-100 mt-1 termsAndPolicy">
          <ol>
            <div className="mb-2 mt-2">第９条（その他利用条件等）</div>

            <li>
              本サービスに係る著作権・その他一切の権利は、当社又は当社の指定する第三者に帰属します。
            </li>
            <li>
              本利用規約は、明示的に定めがある場合を除き、ユーザーに、本サービス上表示されるコンテンツを含め、著作権その他いかなる権利も移転することを許諾するものではありません。
            </li>
            <li>
              当社は、相当期間を定めた事前の通知の上、本サービスの提供の中断・終了等を行う場合があり、ユーザーはあらかじめこれを了承します。
            </li>
            <li>
              ユーザーは、本サービスにおけるユーザーによる書込み等が行われる場合（本サービスに関連する外部の連動・提携先における書込み等を含みます）、当社又は当社の指定する者が日本国内外問わず対価の支払いなく非独占的にいかなる制約も受けずに自由に使用する（複製、公開、送信、頒布、譲渡、貸与、翻訳、翻案を含みます）権利（サブライセンス権も含みます）を、当該コンテンツに係る著作権その他一切の権利の存続期間が満了するまでの間、許諾したものとみなされるものとし、これをあらかじめ承諾します。
            </li>
            <li>
              ユーザーは、以下の各号に該当する行為、または該当するもしくはそのおそれがあると当社が判断する行為を行ってはならないものとします。
              <ul>・当社その他第三者に対して次に掲げるいずれかの行為を行うこと</ul>
              <ul> ① 知的財産権その他の権利を侵害する行為、または侵害する恐れのある行為</ul>
              <ul>
                ② 財産、プライバシーもしくは肖像権を侵害する行為、または侵害する恐れのある行為
              </ul>
              <ul>
                ③
                不当に差別もしくは誹謗中傷し、他者への不当な差別を助長し、またはその名誉もしくは信用を毀損する行為
              </ul>
              <ul>④ 業務に支障をきたし、または迷惑を及ぼす行為</ul>
              <ul>⑤ 同意の有無を問わず、他人のアカウントを利用する行為</ul>
              <ul>⑥ その他方法の如何を問わず嫌がらせをする行為</ul>
              <ul>
                ・自分以外の人物を名乗ったり、代表権や代理権がないにもかかわらずあるものと装ったり、または他の人物や組織と提携、協力関係にあると偽って本サービスを利用する行為
              </ul>
              <ul>
                ・電子計算機使用詐欺を含む詐欺、電子計算機損壊等業務妨害を含む業務妨害、不正アクセス行為、規制薬物の濫用、児童売買春、預貯金口座および携帯端末の違法な売買等その他の犯罪行為、犯罪に結びつく、または結びつく恐れのある行為
              </ul>
              <ul>
                ・違法行為（賭博、けん銃等の譲渡、爆発物の製造、児童ポルノの提供、公文書偽造、殺人、脅迫等）を請け負い、仲介、誘引する行為
              </ul>
              <ul>・異性・同性を問わず、わいせつな行為、出会い等を目的として利用する行為</ul>
              <ul>
                ・次に掲げる内容のコメント等の情報を本サービスにおいて伝達し、又はしようとする行為
              </ul>
              <ul>① ユーザー自身を特定可能な個人情報等を含む情報</ul>
              <ul>② 商業用の広告、宣伝または勧誘を目的とするコメント等の情報</ul>
              <ul>
                ③
                営利・非営利を問わず、全ての医療および医療類似行為（その形態において薬剤の利用、スピリチュアル、パワーストーン、カウンセリング、占い行為、等を問わない）に勧誘する情報
              </ul>
              <ul>④ アフィリエイトのリンクを含むコメント等の情報</ul>
              <ul>
                ⑤
                無限連鎖講（ネズミ講）、チェーンメール、ＭＬＭ、リードメール等他人を勧誘する内容のコメント等の情報
              </ul>
              <ul>⑥ 金融機関等の口座番号・暗証番号を含むコメント等の情報</ul>
              <ul>
                ⑦
                アダルトサイト、ワンクリック詐欺サイト、ウィルス等の有害なコンピュータプログラム等を流布させることを目的とするサイト等当社が不適切と判断するサイトに誘導するコメント等の情報（単にリンクを張る行為を含みます。）
              </ul>
              <ul>
                ⑧
                グロテスクな画像、他のユーザーが不快を感じる可能性が高いと当社が判断するコメント等の情報
              </ul>
              <ul>⑨ 未成年者へ悪影響を及ぼしかねないコメント等の情報</ul>
              <ul>
                ⑩ 暴力的、グロテスクな写真、およびその他一般ユーザーが不快に感じるコメント等の情報
              </ul>
              <ul>⑪ 当社のサポートメール等の全部又は一部の転載</ul>
              <ul>⑫ その他当社が不適切と判断するコメント等の情報</ul>
              <ul>・スパム行為を行うこと</ul>
              <ul>
                ・本サービスを逆アセンブル、逆コンパイル、リバースエンジニアリング、その他本サービスのソースコード、構造、アイデア等を解析するような行為
              </ul>
              <ul>
                ・本サービスを複製、送信、譲渡、貸与、翻訳、翻案、改変、他のソフトウェアと結合等する行為
              </ul>
              <ul>
                ・本サービスに組み込まれているセキュリティデバイスまたはセキュリティコードを破壊するような行為
              </ul>
              <ul>・本サービスの誤動作（予定されていない動作の一切を含みます。）を誘引する行為</ul>
              <ul>
                ・その他、本サービスに関して当社又は当社の指定する者が有する権利を侵害する行為
              </ul>
              <ul>・その他、当社が不適切と判断する行為</ul>
            </li>
          </ol>
        </div>
        <div className="w-100 mt-1 termsAndPolicy">
          <ol>
            <div className="mb-2 mt-2">第１０条 本利用規約違反行為等への対処</div>

            <li>
              ユーザーが本利用規約に違反したと認められる場合、その他当社が必要と認める場合は、当社は当該ユーザーに対し以下の対処を含む当社が必要と判断する措置・処分等を講ずることがあります。また、これらの処分によりユーザーに不利益・損害が発生した場合においても、当社はその責任を負いません。
              <ul>
                ・本利用規約に違反する行為等を止め、同様の行為を繰り返さないことを要求すること
              </ul>
              <ul>
                ・刑事事件、行政事件その他に該当する可能性がある場合の警察その他の公的機関への通報を含む、違反事実の本サービス内外での開示
              </ul>
              <ul>・一時的に利用を停止すること</ul>
              <ul>・ユーザーを強制退会処分とすること</ul>
            </li>
            <li>
              当社は、強制退会処分を受けたユーザーについて、本サービスを提供しないこと（サービス提供の拒絶、既に登録している他のサービスの強制退会処分を含みます）ができるものとし、また、当該処分に必要となる範囲で個人情報等を保存する場合があります。
            </li>
            <li>
              ユーザーは、当社が本利用規約に基づいて行った本利用規約に違反する行為等への対処について、異議を申し立てることはできません。
            </li>
            <li>
              ユーザーの行為によって当社に損害が発生した場合、当社が第１項の措置をとったか否かにかかわらず、当社はそのユーザーに対して損害賠償請求をすることができるものとします。
            </li>
            <li>
              本利用規約に違反する行為より生じた結果について、当社は一切の責任を負うものではありません。
            </li>
          </ol>
        </div>

        <div className="w-100 mt-1 termsAndPolicy">
          <ol>
            <div className="mb-2 mt-2">第１１条（変更）</div>

            <li>
              当社は、相当の事由があると判断した場合には、ユーザーの事前の承諾を得ることなく、当社の判断により、本規約をいつでも、契約目的に反せず、かつ、変更の必要性・変更後の内容の相当性その他変更に係る事情に照らして合理性の認められる範囲にて、変更することができるものとします。変更後の本規約は、当社が別途定める場合を除いて、本サービスにかかる当社の指定するウェブサイト又はアプリケーション上に表示し、当社が定める時点（表示から２週間以上とします）より効力を生じるものとします。
            </li>
            <li>
              ユーザーは、本サービスの利用許諾について解約することにより、本規約の変更への不同意を行うことができます。
            </li>
            <li>
              本規約の変更の効力が生じた後、ユーザーが本サービスを利用した場合には、変更後の本規約に同意したものとみなされます。
            </li>
            <li>
              一定期間アクセスがない等、当社が別途本サービス上に表示して定める条件を満たす場合、本サービスに係るユーザーの登録は、自動的に解除される場合があります。
            </li>
          </ol>
        </div>

        <div className="w-100 mt-1 termsAndPolicy">
          <ol>
            <div className="mb-2 mt-2">第１２条（本利用規約の有効性）</div>

            <li>
              本利用規約の各条項の全部又は一部が法令に基づいて無効と判断されても、当該条項の当該部分以外及び本利用規約のその他の規定は有効とします。
            </li>
            <li>
              本利用規約の規定の一部が、あるユーザーとの関係で無効とされ、または取り消された場合でも、本利用規約はその他のユーザーとの関係では有効とします。
            </li>
          </ol>
        </div>

        <div className="w-100 mt-1 termsAndPolicy">
          <ol>
            <div className="mb-2 mt-2">第１３条（準拠法及び裁判管轄）</div>

            <li>本利用規約は、日本法に準拠し、日本法によって解釈されるものとします。</li>
            <li>
              本利用規約に関する紛争については、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
            </li>
          </ol>
        </div>
      </Container>
      <div className="w-100 text-center p-2">
        <img src={BrLogo} alt="bg logo" className="w-max-content" />
        <p className="mt-2 fs-7">Copyright ©2021 BootRock Inc. All rights reserved.</p>
      </div>
    </div>
  );
}
