import React, { useMemo } from 'react';
import { find, isEmpty } from 'lodash';
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import TableCommon from 'Components/Common/Table/TableCommon';
import { SerialTypeOptions } from 'Constant/SerialNumber';
import { SortMethod } from 'Components/Common/Table/table';
interface IListCD {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export default function SerialNumberTable({
  dataSource,
  onSort,
}: {
  dataSource: IListCD;
  onSort?: (sortingState: SortMethod[]) => void;
}): JSX.Element {
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'pe-none',
        columns: [
          {
            Header: 'ID',
            accessor: 'id',
            width: '100',
            Cell: ({ row }: Cell<IListCD>) => {
              return <div>{row?.original?.id}</div>;
            },
          },
          {
            Header: 'シリアルコードマスタ名',
            accessor: 'name',
            width: '350',
            Cell: ({ row }: Cell<IListCD>) => {
              return (
                <Link
                  to={{
                    pathname: `/serial-numbers/${row.original.id}`,
                    state: { id: row.original.id },
                  }}
                  className={`${!row.original.name && 'text-decoration-none text-black pe-none'}`}
                >
                  <span className="d-block text-truncate text-start mw-35">
                    {row.original.name}
                  </span>
                </Link>
              );
            },
          },
          {
            Header: '対象商品',
            accessor: 'cd',
            width: '100',
            Cell: ({ row }: Cell<IListCD>) => {
              return (
                <>
                  <div>{row.original?.cd?.name ?? '-'}</div>
                </>
              );
            },
          },
          {
            Header: '品番',
            accessor: 'codeCd',
            width: '100',
            Cell: ({ row }: Cell<IListCD>) => {
              return (
                <>
                  <div className="d-block text-break">{row.original?.cd?.productCode ?? '-'}</div>
                </>
              );
            },
          },
          {
            Header: '種別',
            accessor: 'type',
            width: '200',
            Cell: ({ row }: Cell<IListCD>) => {
              const label =
                find(SerialTypeOptions, (opt) => opt.value === row.original?.type)?.label ?? '';
              return (
                <>
                  <div>{label ?? '-'}</div>
                </>
              );
            },
          },
          {
            Header: '数量',
            accessor: 'quantity',
            width: '100',
          },
        ],
      },
    ],
    [],
  );

  return (
    <Row className="px-4 pb-3">
      {!isEmpty(dataSource) ? (
        <TableCommon
          classTable="table-serial-number"
          heightBody="calc(100vh - 205px)"
          data={dataSource}
          columns={columns}
          manualSortBy
          onSort={onSort}
        />
      ) : (
        <div className="vh-100-final d-flex align-items-center justify-content-center">
          検索結果がありません。
        </div>
      )}
    </Row>
  );
}
