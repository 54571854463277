/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import ECFormGroupTextArea from 'Components/Page/EC/Form/FormGroupTextArea';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { isEmpty, union } from 'lodash';

import { yupResolver } from '@hookform/resolvers/yup';
import ECSendBulkEmailFormSchema from 'Components/Page/EC/Schemas/ECSendBulkEmailFormSchema';
import useMessage from 'Hooks/useMessage';
import { findKey } from 'lodash';
import { stringRequired, validateEmailsStringWithSemicolons, maxMsg } from 'Utils/Validation';

import { ECApis } from 'Datasource/EC';
import { toast } from 'react-toastify';
import { EmailManagementApi } from 'Datasource/EmailManagement';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import ModalComponent from 'Components/Common/Modal';
import BoxFormEc from 'Components/Common/EC/BoxForm';
import { EC_EMAIL_TEMPLATE } from 'Constant/EC';
import GroupButton from './GroupButton';

const EcEmailTemplateUpdateSchema = yup.object().shape({
  subject: stringRequired({}).max(100, maxMsg(100)).nullable(),
  body: stringRequired({}).nullable(),
  // operators: stringRequired({}).max(500, maxMsg(500)).nullable(),
});

const EcSendBulkEmailForm = ({ name }: { name: string }) => {
  const mailContentRef = useRef<any>(null);
  const [textAddToContent, setTextAddToContent] = useState('');
  const [emailTemplateType, setEmailTemplateType] = useState('TITLE_REGISTER_COMPLETE');
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const { openMessageError, openMessage } = useMessage();

  const methods = useForm<any>({
    mode: 'onSubmit',
    resolver: yupResolver(EcEmailTemplateUpdateSchema),
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = methods;

  const handleAddTextToContent = (btnText: string) => {
    const cursorPosition = mailContentRef.current?.selectionStart;
    const textBeforeCursorPosition = mailContentRef.current?.value.substring(0, cursorPosition);
    const textAfterCursorPosition = mailContentRef.current?.value.substring(
      cursorPosition,
      mailContentRef.current?.value.length,
    );

    const textAfterAdd = `${textBeforeCursorPosition}${btnText}${textAfterCursorPosition}`;

    setValue('body', textAfterAdd);

    setTimeout(() => {
      mailContentRef.current?.focus();
      mailContentRef.current.selectionStart = cursorPosition + btnText.length;
    }, 100);
  };

  // useEffect(() => {
  //   handleAddTextToContent();
  // }, [textAddToContent]);

  const fetchEmailTemplate = async () => {
    try {
      setIsloading(true);
      const response = await EmailManagementApi.getEmailTemplate(emailTemplateType);
      setValue('subject', response.data.data.subject);
      setValue('body', response.data.data.body);
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    if (name) {
      clearErrors();
      const key = findKey(EC_EMAIL_TEMPLATE, (eTemp) => eTemp === name);
      if (key) {
        setEmailTemplateType(key);
      }
    }
  }, [name]);

  useEffect(() => {
    fetchEmailTemplate();
  }, [emailTemplateType]);

  //   const onSendBlukTestMail = async (values: any) => {
  //     try {
  //       // if (!validateEmailsStringWithSemicolons(values.operators)) {
  //       //   setError('operators', {
  //       //     message: '「；」セミコロンで区切られた有効なメールアドレスを入力してください',
  //       //   });
  //       //   return;
  //       // }
  //       setLoading(true);
  //       const params = {
  //         subject: values.subject,
  //         body: values.body,
  //         operators: values.operators?.split(';'),
  //         isTest: true,
  //       };
  //       await ECApis.sendBlukEmail(params);
  //       if (callback) {
  //         callback();
  //       }
  //       onCloseModal();
  //       toast('テストメールを送信しました。');
  //     } catch (error) {
  //       openMessageError(error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   const onSendBlukMail = async (values: any) => {
  //     try {
  //       const operatorsData = data?.map((d: any) => d.email);

  //       const operatorCombine: Array<string> = union(values.operators?.split(';'), operatorsData);

  //       setLoading(true);
  //       const params = {
  //         subject: values.subject,
  //         body: values.body,
  //         operators: operatorCombine,
  //         isTest: false,
  //       };
  //       await ECApis.sendBlukEmail(params);
  //       if (callback) {
  //         callback();
  //       }
  //       onCloseModal();
  //       toast('メールを一括送信しました。');
  //     } catch (error) {
  //       openMessageError(error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  const onSubmit = handleSubmit(() => {
    setShowModal(true);
  });

  const onSubmitModal = async () => {
    setIsloading(true);
    const params = {
      type: emailTemplateType,
      subject: watch('subject'),
      body: watch('body'),
    };
    try {
      await EmailManagementApi.updateEmailTemplate(params);
      setShowModal(false);
      return false;
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsloading(false);
    }
  };

  const onSendEmailTest = async () => {
    if (watch('subject') == '') {
      setError('subject', { type: 'required', message: '入力してください。' });
    } else {
      clearErrors('subject');
    }

    if (watch('body') == '') {
      setError('body', { type: 'required', message: '入力してください。' });
    } else {
      clearErrors('body');
    }

    if (watch('operators') == '') {
      setError('operators', { type: 'required', message: '入力してください。' });
    } else {
      if (validateEmailsStringWithSemicolons(watch('operators'))) {
        clearErrors('operators');
      } else {
        setError('operators', {
          type: 'custom',
          message: '「；」セミコロンで区切られた有効なメールアドレスを入力してください',
        });
      }
    }

    clearErrors('initialErr');

    if (isEmpty(errors)) {
      setIsloading(true);
      const params = {
        subject: watch('subject'),
        body: watch('body'),
        operators: watch('operators').split(';'),
        isTest: true,
        type: emailTemplateType,
      };
      try {
        await EmailManagementApi.sendEmailTest(params);
        openMessage({
          variant: 'success',
          message: `テストメールを送信しました。`,
        });
      } catch (error) {
        openMessageError(error);
      } finally {
        setIsloading(false);
      }
    }
  };

  useEffect(() => {
    setError('initialErr', { type: 'custom', message: 'initialErr' });
  }, []);

  return (
    <Container className="py-4">
      <SpinnerComponent isLoading={isLoading} />
      <ModalComponent
        title="登録確認"
        show={showModal}
        onCloseModal={() => setShowModal(false)}
        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
        variantButtonSubmit="primary"
        onSubmitModal={onSubmitModal}
        confirmText="保存する"
      >
        <div>入力した内容で保存します。よろしいですか。</div>
      </ModalComponent>
      <Row>
        <Col className="m-auto" md="11">
          <Form
            className="d-flex justify-content-center"
            id="bulkEmail"
            onSubmit={() => {
              return false;
            }}
          >
            <BoxFormEc classBox="text-white w-70" title={name}>
              <div className="d-flex w-30 justify-content-between">
                {/* <span>送信数</span> <span>{data.length}件</span> */}
              </div>

              <div className="mt-2">
                <FormGroupInput
                  value={watch('subject')}
                  onChange={(val: any) => {
                    setValue('subject', val);
                  }}
                  register={{ ...register('subject') }}
                  labelMd="12"
                  colMd="12"
                  classLabel="fs-16 fw-bold"
                  maxLength={100}
                  label="メールタイトル"
                  classForm="row-dpadding text-dark"
                  errorMessage={errors.subject?.message}
                />
              </div>

              <div className="mt-2">
                <ECFormGroupTextArea
                  value={watch('body')}
                  onChange={(val: any) => setValue('body', val)}
                  register={{ ...register('body') }}
                  labelMd="12"
                  colMd="12"
                  ref={mailContentRef}
                  label={
                    <div className="d-flex flex-column fs-16 fw-bold">
                      メール本文
                      <div className="d-flex mt-2 mb-2">
                        <GroupButton
                          option={name}
                          setTextAddToContent={setTextAddToContent}
                          handleAddTextToContent={handleAddTextToContent}
                        />
                      </div>
                    </div>
                  }
                  classForm="row-dpadding text-dark"
                  rows={10}
                  maxLength={2000}
                  errorMessage={errors.body?.message}
                  //   register={{ ...register('bankAccountNumber') }}
                />
              </div>
              <div className="mt-2 position-relative">
                <FormGroupInput
                  value={watch('operators')}
                  onChange={(val) => setValue('operators', val)}
                  register={{ ...register('operators') }}
                  labelMd="12"
                  colMd="12"
                  maxLength={500}
                  label="運営者へ同じメールを送る"
                  classLabel="fs-16 fw-bold"
                  classForm="row-dpadding text-dark mb-0"
                  hintText="「；」セミコロンで区切り複数設定することが可能です"
                  errorMessage={errors.operators?.message}
                  //   register={{ ...register('bankAccountNumber') }}
                />
                <Button className="ec_send_bluk_mail-test_btn" onClick={onSendEmailTest}>
                  テスト送信
                </Button>
              </div>

              <div className="d-flex justify-content-center mt-5">
                <Button className="w-200px" onClick={onSubmit}>
                  保存する
                </Button>
              </div>
            </BoxFormEc>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default EcSendBulkEmailForm;
