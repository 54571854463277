import React from 'react';
import ReactDOM from 'react-dom';
import 'Static/Styles/index.scss';
import App from 'App';
import { persistStore } from 'redux-persist';
import reportWebVitals from 'reportWebVitals';
import { Provider } from 'react-redux';
import { store } from 'App/Store';
import { PersistGate } from 'redux-persist/integration/react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';

const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (feslint --fixor example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
