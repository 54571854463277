import React from 'react';
import { Container } from 'react-bootstrap';
import { NFTFilter, TableList } from 'Components/Page/NFTList';

export default function NFTList(): JSX.Element {
  return (
    <>
      <Container fluid>
        <NFTFilter />
        <div>
          <TableList />
        </div>
      </Container>
    </>
  );
}
