import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Filter from 'Components/Common/Filter/Filter';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import SearchProducts from './SearchProducts';
import PaginationComponent from 'Components/Common/Pagination/Pagination';
import { PAGE_SIZE } from 'Constant';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { ProductsApi } from 'Datasource/ProductsApi';
import { exportExcel } from 'Utils/File';
import useMessage from 'Hooks/useMessage';
import { TableListProducts } from 'Components/Page/ListProducts';
import { useDispatch } from 'react-redux';
import { setProductsFilter } from 'App/Features/NFT';
import { getUserGMT } from 'Utils/Params';
import { SortMethod } from 'Components/Common/Table/table';
import dayjs from 'dayjs';
import { DATE_FORMAT_EXPORT_EXCEL } from 'Constant/Date';
import useErrorBlob from 'Hooks/useErrorBlob';

export default function ListProducts(): JSX.Element {
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    status: StringParam,
    queryCompany: StringParam,
    queryArtist: StringParam,
    queryTitle: StringParam,
    companyIds: StringParam,
    artistIds: StringParam,
    titleIds: StringParam,
    saleIds: StringParam,
    querySale: StringParam,
    cardCd: StringParam,
    isMaster: StringParam,
    sort: StringParam,
    order: StringParam,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [activePage, setActivePage] = useState(query.page ?? 1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [dataList, setDataList] = useState([]);
  const [dataQuery, setDataQuery] = useState({});
  const dispatch = useDispatch();
  const [exporting, setExporting] = useState<boolean>(false);

  const { openMessageError } = useMessage();
  const { openErrorBlob } = useErrorBlob();

  const handleOnchangeList = async (ids: any) => {
    const queryArr = Object.values(query);
    const arrLookupNoData = queryArr.filter((q: any) => {
      return !!q && q === 'no-data';
    });

    if (arrLookupNoData.length > 0) {
      setDataList([]);
      return;
    }
    try {
      setLoading(true);
      const param = {
        page: activePage,
        limit: PAGE_SIZE,
        query: '',
        artistId: ids.artistId,
        titleId: ids.titleId,
        channelId: ids.saleId,
        cardCD: ids.cardCd,
        companyId: ids.companyId,
        isMaster: ids.isMaster,
        status: ids.status === 0 ? 0 : null,
        sort: query.sort || '',
        order: query.order || 'DESC',
      };
      ids.isMaster || delete param['isMaster'];
      const {
        data: { data },
      } = await ProductsApi.getCards(param);
      setDataList(data?.items);
      setNumberOfPages(data?.meta?.totalItems);
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const ids = {
      artistId: query.artistIds ?? '',
      titleId: query.titleIds ?? '',
      saleId: query.saleIds ?? '',
      cardCd: query.cardCd ?? '',
      companyId: query.companyIds ?? '',
      isMaster: query.isMaster,
      status: query.status === '0' ? 0 : null,
      gmt: getUserGMT(),
    };
    query.isMaster || delete ids['isMaster'];
    handleOnchangeList(ids);

    if (query.companyIds) {
      dispatch(setProductsFilter({ companyId: query.companyIds }));
    }
    setDataQuery(ids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activePage,
    query.status,
    query.saleIds,
    query.artistIds,
    query.titleIds,
    query.cardCd,
    query.companyIds,
    query.isMaster,
    query.sort,
    query.order,
  ]);

  //* handle sort from server side
  const handleManualSort = useCallback(
    (sortBy: SortMethod[]) => {
      if (sortBy.length > 0) {
        const sortOption = sortBy[0];
        setQuery({ sort: sortOption.id, order: sortOption.desc ? 'DESC' : 'ASC' });
      } else {
        setQuery({ sort: '', order: '' });
      }
    },
    [setQuery],
  );

  const handleExportExcel = async () => {
    try {
      setExporting(true);
      await exportExcel(
        ProductsApi.downloadExcel(dataQuery),
        `販売商品リスト_${dayjs().format(DATE_FORMAT_EXPORT_EXCEL)}`,
      );
    } catch (error) {
      openErrorBlob(error);
    } finally {
      setExporting(false);
    }
  };
  return (
    <>
      <SpinnerComponent isLoading={loading} />

      <Filter filterClass="d-flex align-items-center shadow-sm">
        <SearchProducts exportExcel={handleExportExcel} loadingExcel={exporting} />
      </Filter>
      <Container fluid>
        <Row>
          <Col className="d-flex justify-content-end mt-2 me-3 pe-1">
            <PaginationComponent
              activePage={activePage}
              total={numberOfPages}
              perPage={PAGE_SIZE}
              onClick={(newPage) => setActivePage(newPage)}
            />
          </Col>
        </Row>
        <TableListProducts data={dataList} onSort={handleManualSort} />
      </Container>
    </>
  );
}
