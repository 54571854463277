/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import ECFormGroupTextArea from 'Components/Page/EC/Form/FormGroupTextArea';
import { ECApis } from 'Datasource/EC';
import { toast } from 'react-toastify';
import { useForm, FormProvider } from 'react-hook-form';
import useMessage from 'Hooks/useMessage';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { Col, Row, Form, Button } from 'react-bootstrap';
import BoxForm from 'Components/Common/Form/BoxForm';
import GroupTextProps from 'Components/Page/Title/FormTextGroup';
import CardContainer from 'Components/Common/CardContainer';
import dayjs from 'dayjs';
import { DATE_FORMAT_6 } from 'Constant/Date';
import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';
import { Link } from 'react-router-dom';
import Modal from 'Components/Common/Modal';
import ECPurchaseStatus from 'Components/Page/EC/ECPurchaseStatus';

import { formatFixedDecimalAndNumb } from 'Utils/Numbers';

import { formatPrice } from 'Utils/Numbers';
import { PURCHASE_DETAIL_STATUS, PAYMENT_CARD } from 'Constant/EC';
import useTitlePage from 'Hooks/useTitlePage';
import { useHistory, useParams } from 'react-router-dom';
import { renderShopName } from 'Constant/Shop';

const PAYMENT_DATA_STATUS: any = {
  1: '課金',
  2: '返金',
};

export default function ECPurchaseDetail(): JSX.Element | null {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ECPurchaseDetailData, setECPurchaseDetailData] = useState<any>(null);

  const [isShowCancelAllModal, setIsShowCancelAllModal] = useState<boolean>(false);
  const [isShowCancelModal, setIsShowCancelModal] = useState<boolean>(false);

  const [selectedCancelItem, setSelectedCancelItem] = useState<any>(null);

  const history = useHistory();
  const { id }: { id: string } = useParams();

  const { openMessageError } = useMessage();

  const { setMenuTitle } = useTitlePage();

  const methods = useForm<any>({
    defaultValues: {
      description: null,
    },
    // resolver: yupResolver(ECSaleProductDetailSchema),
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const handleGetPurchaseDetail = async (ids: any) => {
    try {
      setIsLoading(true);
      const {
        data: { data },
      } = await ECApis.getPurchaseDetail(ids);

      setECPurchaseDetailData(data);
      if (data.description) {
        setValue('description', data.description);
      }
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdatePurchaseDescription = async (value: any) => {
    try {
      if (!value.description) {
        return;
      }
      setIsLoading(true);

      const params = {
        description: value.description,
      };

      const {
        data: { statusCode },
      } = await ECApis.purchaseUpdateDescription({ id, params });

      if (statusCode === 200) {
        toast('管理者備考を更新しました。', { type: 'success' });
        handleGetPurchaseDetail(id);
      }
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      handleGetPurchaseDetail(id);
    }
  }, [id]);

  useEffect(() => {
    setMenuTitle('EC購入データ詳細');
  }, []);

  // if (!ECPurchaseDetailData) {
  //   return null;
  // }

  return (
    <div className="pt-4 lg-pt-8px px-4 position-relative">
      <SpinnerComponent isLoading={isLoading} />
      <div className="d-flex">
        <div className="position-fixed ec_purchase_detail-left">
          <CardContainer title="購入情報" darkHeader className="mb-3 lg-mb-4px">
            <GroupTextProps
              mdLable={5}
              mdValue={7}
              label="ステータス"
              value={<ECPurchaseStatus status={ECPurchaseDetailData?.purchaseInfo?.status} />}
            />
            <GroupTextProps
              mdLable={5}
              mdValue={7}
              label="注文番号"
              value={ECPurchaseDetailData?.purchaseInfo?.ecOrderId}
            />
            <GroupTextProps
              mdLable={5}
              mdValue={7}
              label="注文日時"
              value={dayjs(ECPurchaseDetailData?.purchaseInfo?.createdAt).format(DATE_FORMAT_6)}
            />
            <GroupTextProps
              mdLable={5}
              mdValue={7}
              label="ユーザーID"
              value={ECPurchaseDetailData?.purchaseInfo?.userMiimId}
            />
            <GroupTextProps
              mdLable={5}
              mdValue={7}
              label="ニックネーム"
              value={ECPurchaseDetailData?.purchaseInfo?.userName}
            />
            <GroupTextProps
              mdLable={5}
              mdValue={7}
              label="本名"
              value={ECPurchaseDetailData?.purchaseInfo?.userRealName}
            />
            <GroupTextProps
              mdLable={5}
              mdValue={7}
              label="メールアドレス"
              value={ECPurchaseDetailData?.purchaseInfo?.email}
            />
          </CardContainer>
          <CardContainer title="支払い方法" darkHeader className="mb-3 lg-mb-4px">
            <GroupTextProps
              mdLable={5}
              mdValue={7}
              label="支払い金額"
              value={`¥ ${formatPrice(ECPurchaseDetailData?.paymentMethod?.total)}`}
            />
            <GroupTextProps
              mdLable={5}
              mdValue={7}
              label="クレジット決済"
              value={
                <div className="d-flex align-items-center">
                  <div className="ec_purchase_detail-payment_card_logo">
                    {
                      PAYMENT_CARD[
                        ECPurchaseDetailData?.paymentMethod?.cardPayment?.replace(/\s/g, '')
                      ]?.logo
                    }
                  </div>
                  <span className="ms-2">{ECPurchaseDetailData?.paymentMethod?.numCard}</span>
                </div>
              }
            />
            <GroupTextProps
              mdLable={5}
              mdValue={7}
              label="決済日時"
              value={dayjs(ECPurchaseDetailData?.paymentMethod?.createdAt).format(DATE_FORMAT_6)}
            />
          </CardContainer>

          {ECPurchaseDetailData?.purchaseInfo?.status !== '決済エラー' ? (
            <CardContainer title="決済データ" darkHeader className="mb-3 lg-mb-4px">
              <GroupTextProps
                mdLable={3}
                mdValue={9}
                label="決済ID"
                className="mt-0"
                // classNameVal="text-truncate"
                value={ECPurchaseDetailData?.purchaseInfo?.channelOrderId}
              />

              <div className="ec_purchase_detail-paymentData_list">
                {Array.isArray(ECPurchaseDetailData?.paymentData?.items) &&
                  ECPurchaseDetailData?.paymentData?.items.map((item: any) => {
                    return (
                      <div key={item.paymentDate} className="ec_purchase_detail-paymentData_item">
                        <GroupTextProps
                          mdLable={3}
                          mdValue={9}
                          className="mt-0"
                          label="決済日時"
                          value={dayjs(item.paymentDate).format(DATE_FORMAT_6)}
                        />
                        <GroupTextProps
                          className="mt-0"
                          mdLable={3}
                          mdValue={9}
                          label="種別"
                          value={
                            PAYMENT_DATA_STATUS?.[item.paymentStatus]
                              ? PAYMENT_DATA_STATUS[item.paymentStatus]
                              : '-'
                          }
                        />
                        <GroupTextProps
                          mdLable={3}
                          mdValue={9}
                          className="mt-0"
                          label="決済額"
                          value={
                            <div className="d-flex align-items-center">
                              {' '}
                              {item.paymentStatus === 2 && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={12}
                                  height={12}
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M24 22h-24l12-20z" />
                                </svg>
                              )}
                              {`¥ ${formatPrice(item.paymentPrice)}`}
                            </div>
                          }
                        />
                      </div>
                    );
                  })}
              </div>

              <div className="ec_purchase_detail-paymentData_item ps-3 lg-pl-12px">
                <div className="d-flex">
                  <div className="d-flex flex-column me-3">
                    <span>入金額</span>
                    <span className="ec_fs-16 fw-bold lg-fs-11px">{`¥ ${formatPrice(
                      formatFixedDecimalAndNumb(ECPurchaseDetailData?.paymentData?.total, 2),
                    )}`}</span>
                  </div>
                  <div className="d-flex flex-column me-3">
                    <span>決済手数料</span>
                    <span className="ec_fs-16 fw-bold lg-fs-11px">{`¥ ${formatPrice(
                      formatFixedDecimalAndNumb(ECPurchaseDetailData?.paymentData?.cost, 2),
                    )}`}</span>
                  </div>
                  <div className="d-flex flex-column">
                    <span>プラットフォーム利用料</span>
                    <span className="ec_fs-16 fw-bold lg-fs-11px">{`¥ ${formatPrice(
                      formatFixedDecimalAndNumb(
                        ECPurchaseDetailData?.paymentData?.totalPlatformUsageFee,
                        2,
                      ),
                    )}`}</span>
                  </div>
                </div>
              </div>
            </CardContainer>
          ) : null}

          <div className="d-flex justify-content-center mt-4 lg-mt-6px">
            <Button
              className="w-80 purchase_detail-submit-btn"
              onClick={() => {
                history.goBack();
              }}
            >
              もどる
            </Button>
          </div>
        </div>
        <div className="ec_purchase_detail-right">
          <BoxForm title="購入商品明細">
            {Array.isArray(ECPurchaseDetailData?.purchaseItems?.items) &&
              ECPurchaseDetailData?.purchaseItems?.items?.map((item: any) => {
                return (
                  <div
                    className={
                      item.paymentStatus === PURCHASE_DETAIL_STATUS.CANCEL
                        ? 'ec_purchase_detail-item ec_purchase_detail-item_disabled mt-1'
                        : 'ec_purchase_detail-item mt-1'
                    }
                    key={item?.coverImageUrl}
                  >
                    <Row>
                      <Col md="5">
                        <div className="ec_purchase_detail-item_infor break-word d-flex">
                          <div>
                            {item?.coverImageUrl ? (
                              <img
                                className="ec_purchase_detail-image border"
                                alt="preview"
                                src={handleConnectStaticEndPoint(item?.coverImageUrl)}
                              />
                            ) : (
                              <div className="ec_purchase_detail-image bg-gray avatar-bank d-flex justify-content-center align-items-center">
                                <p className="gray-dark">No Image</p>
                              </div>
                            )}
                          </div>
                          <div className="d-flex flex-column ms-3">
                            <div className="mb-1 d-flex flex-column">
                              <span className="ec_fs-10">タイトル</span>
                              <span className="fw-bold ec_fs-18 lg-fs-14px">{item?.titleName}</span>
                              <span className="ec_fs-12 ms-3">{item?.artistName}</span>
                            </div>
                            <div className="mb-1 d-flex flex-column">
                              <span className="ec_fs-10">バリエーション</span>
                              <span className="fw-bold ec_fs-18 lg-fs-14px">
                                {item?.variationName}
                              </span>
                            </div>
                            <div className="mb-1">
                              <div>
                                <span className="ec_fs-10">販売用ID</span>
                              </div>
                              <div>
                                <Link
                                  to={`/ec/sale-product-detail/${item?.ecProductsChannelDetailId}`}
                                >
                                  <span className="ec_fs-14">{item?.cardCd}</span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md="7">
                        <div className="d-flex flex-column justify-content-between h-100">
                          <div>
                            <div className="ec_purchase_detail-item_price">
                              <Row className="row-dpadding">
                                <Col md="4">
                                  <div className="d-flex flex-column">
                                    <span className="ec_fs-10">品番</span>
                                    <span className="fw-bold ec_fs-18 lg-fs-14px line-break-anywhere">
                                      {item?.productCode}
                                    </span>
                                  </div>
                                </Col>
                                <Col md="3">
                                  <div className="d-flex flex-column">
                                    <span className="ec_fs-10">販売価格</span>
                                    <span className="fw-bold ec_fs-18 lg-fs-14px">{`¥${formatPrice(
                                      item?.purchasePrice,
                                    )}`}</span>
                                  </div>
                                </Col>
                                <Col md="2">
                                  <div className="d-flex flex-column">
                                    <span className="ec_fs-10">数量</span>
                                    <span className="fw-bold ec_fs-18 lg-fs-14px">
                                      {item?.amount}点
                                    </span>
                                  </div>
                                </Col>
                                <Col md="3">
                                  <div className="d-flex flex-column">
                                    <span className="ec_fs-10">小計</span>
                                    <span className="fw-bold ec_fs-18 lg-fs-14px">{`¥${formatPrice(
                                      item?.totalPrice,
                                    )}`}</span>
                                  </div>
                                </Col>
                              </Row>
                            </div>

                            <Row>
                              <Col md={7} className="px-0">
                                <div className="d-flex flex-column mt-1 lg-fs-12px">
                                  購入時キャンペーン
                                  {Array.isArray(item?.campaigns) && item?.campaigns.length > 0 ? (
                                    item?.campaigns?.map((campaign: any) => (
                                      <div
                                        key={campaign.id}
                                        className="ec_purchase_detail-campaign_link"
                                      >
                                        {campaign?.name}
                                      </div>
                                    ))
                                  ) : (
                                    <div className="ec_purchase_detail-campaign_link">なし</div>
                                  )}
                                </div>
                              </Col>

                              {item?.shopId !== undefined && item?.shopId !== null && (
                                <Col md={5} className="px-0 mt-1 lg-fs-12px">
                                  <div>
                                    <div>店舗名</div>
                                    <div className="ps-2" style={{ wordBreak: 'break-word' }}>
                                      {renderShopName(item)}
                                    </div>
                                  </div>
                                </Col>
                              )}
                            </Row>
                          </div>
                          {/* {item.paymentStatus === PURCHASE_DETAIL_STATUS.PURCHASED ? (
                            <div className="d-flex justify-content-end">
                              <Button
                                onClick={() => {
                                  setSelectedCancelItem(item);
                                  setIsShowCancelModal(true);
                                }}
                                className="btn btn-danger"
                              >
                                キャンセル
                              </Button>
                            </div>
                          ) : null}
                          {item.paymentStatus === PURCHASE_DETAIL_STATUS.CANCEL ? (
                            <div className="d-flex justify-content-end">
                              <Button className="btn btn-secondary" disabled>
                                キャンセル済み
                              </Button>
                            </div>
                          ) : null} */}
                        </div>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            <div className="d-flex mt-5">
              <Row className="w-100 align-items-center">
                <Col md="5">
                  {/* <Button className="btn btn-danger" onClick={() => setIsShowCancelAllModal(true)}>
                    オーダー全キャンセル
                  </Button> */}
                </Col>
                <Col md="7">
                  <div className="ec_purchase_detail-item_price">
                    <Row className="align-items-end row-dpadding">
                      <Col md="7">
                        <div className="d-flex flex-column">
                          <span className="fw-bold ec_fs-18">合計</span>
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="d-flex flex-column">
                          <span className="ec_fs-10">数量</span>
                          <span className="fw-bold ec_fs-18">
                            {ECPurchaseDetailData?.purchaseItems?.amount}点
                          </span>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="d-flex flex-column">
                          <span className="ec_fs-10">合計</span>
                          <span className="fw-bold ec_fs-18">{`¥${formatPrice(
                            ECPurchaseDetailData?.purchaseItems?.totalPrice,
                          )}`}</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </BoxForm>

          <BoxForm title="管理者備考" classBox="mt-4">
            <Form>
              <div>
                <ECFormGroupTextArea
                  labelMd="2"
                  colMd="12"
                  label={''}
                  // register={register('type1.description')}
                  classForm="row-dpadding"
                  rows={10}
                  maxLength={2000}
                  disabled
                  value={watch('description')}
                  onChange={(value) => setValue('description', value)}
                  // errorMessage={errors.type1?.description?.message}
                />
                {/* <div className="d-flex justify-content-end">
                  <Button onClick={handleSubmit(handleUpdatePurchaseDescription)}>保存</Button>
                </div> */}
              </div>
            </Form>
          </BoxForm>
        </div>
      </div>
    </div>
  );
}
