import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { Button, ButtonGroup, Col, Row } from 'react-bootstrap';
import Search from 'Components/Common/Search/Search';
import { DATE_FORMAT_4, DATE_FORMAT_5, DATE_FORMAT_YMD } from 'Constant/Date';
import { PromotionManagerApi } from 'Datasource/PromotionManager';
import { IPullDownItem } from 'Types/Common';
import { exportExcel } from 'Utils/File';
import { getUserGMT } from 'Utils/Params';
import {
  handleGetIds,
  handleSearchArtist,
  handleSearchCompany,
  handleSearchTitle,
  handleSearchVariation,
  ItemSearch,
  transformKey,
  updateQuery,
} from 'Utils/Search';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);

export default function FilterPromotion(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    status: StringParam,
    queryCompany: StringParam,
    queryArtist: StringParam,
    queryTitle: StringParam,
    queryVariation: StringParam,
    companyId: StringParam,
    artistId: StringParam,
    titleId: StringParam,
    companyIds: StringParam,
    artistIds: StringParam,
    titleIds: StringParam,
    variationIds: StringParam,
    dateSearch: StringParam,
    startDate: StringParam,
    endDate: StringParam,
  });
  const [queryObj, setQueryObj] = useState({
    queryCompany: query.queryCompany,
    queryArtist: query.queryArtist,
    queryTitle: query.queryTitle,
    queryVariation: query.queryVariation,
  });
  const [res, setRes] = useState<Array<ItemSearch>>([]);

  const handleGets = ({
    queryCompany = '',
    queryArtist = '',
    queryTitle = '',
    queryVariation = '',
    companyId = '',
    artistId = '',
    titleId = '',
  }) => {
    const companiesR = handleSearchCompany({ queryCompany, page: 1 });
    const artistsR = handleSearchArtist({ queryArtist, page: 1, companyId });
    const titlesR = handleSearchTitle({ queryTitle, artistId, companyId, page: 1 });
    const variationR = handleSearchVariation({
      queryVariation,
      companyId,
      artistId,
      titleId,
      page: 1,
    });
    return axios.all([companiesR, artistsR, titlesR, variationR]);
  };

  const handleSearchAll = async ({
    queryCompany = '',
    queryArtist = '',
    queryTitle = '',
    queryVariation = '',
    companyId = '',
    artistId = '',
    titleId = '',
  }) => {
    setLoading(true);
    const res = await handleGets({
      queryCompany,
      queryArtist,
      queryTitle,
      queryVariation,
      companyId,
      artistId,
      titleId,
    });
    const companies = res[0].data.data.map((company: IPullDownItem) => ({
      label: company.companies_name,
      value: company.companies_id,
    }));
    const artists = res[1].data.data.map((artist: IPullDownItem) => ({
      label: artist.localName,
      value: artist.id,
    }));
    const titles = res[2].data.data.map((title: IPullDownItem) => ({
      label: title.name,
      value: title.id,
    }));
    const variations = res[3].data.data.map((variation: IPullDownItem) => ({
      label: variation.variations_name,
      value: variation.variations_id,
    }));
    const dataSource = [companies, artists, titles, variations];
    setRes(dataSource);
    setLoading(false);
  };

  const handleEnter = useCallback(
    (type: string) => {
      const ids = res.map((item: any) => handleGetIds(item));
      if (type === 'queryCompany') {
        const companyIds = query[type] ? (ids[0] ? ids[0] : 'no-data') : '';
        setQuery({ companyIds: companyIds, page: 1 });
      }

      if (type === 'queryArtist') {
        const artistIds = query[type] ? (ids[1] ? ids[1] : 'no-data') : '';
        setQuery({ artistIds: artistIds, page: 1 });
      }

      if (type === 'queryTitle') {
        const titleIds = query[type] ? (ids[2] ? ids[2] : 'no-data') : '';
        setQuery({ titleIds: titleIds, page: 1 });
      }

      if (type === 'queryVariation') {
        const variationIds = query[type] ? (ids[3] ? ids[3] : 'no-data') : '';
        setQuery({ variationIds: variationIds, page: 1 });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [res, query, loading],
  );

  const handleClickItem = useCallback(
    (id: string, type: string) => {
      if (type === 'queryCompany') {
        setQuery({ companyId: id, companyIds: id, page: 1 });
      }

      if (type === 'queryArtist') {
        setQuery({ artistId: id, artistIds: id, page: 1 });
      }

      if (type === 'queryTitle') {
        setQuery({ titleIds: id, titleId: id, page: 1 });
      }
      if (type === 'queryVariation') {
        setQuery({ variationIds: id, page: 1 });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query],
  );

  const changeMonth = useCallback(
    (type: string) => {
      const dateTime = query.dateSearch ? query.dateSearch : dayjs().format(DATE_FORMAT_4);
      let date = dateTime;
      if (type === 'next') {
        date = dayjs(dateTime).set('date', 1).add(1, 'month').format(DATE_FORMAT_4);
      } else {
        date = dayjs(dateTime).set('date', 1).subtract(1, 'month').format(DATE_FORMAT_4);
      }
      const startOfMonth = dayjs(date).startOf('month').format();
      const startDate = dayjs(startOfMonth).utc().format();

      const endOfMonth = dayjs(date).endOf('month').format();
      const endDate = dayjs(endOfMonth).utc().format();

      setQuery({ startDate, endDate, dateSearch: date, page: 1 });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query.dateSearch],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouceRequest = useCallback((value) => updateQuery(value, setQueryObj), []);

  useEffect(() => {
    debouceRequest({
      queryCompany: query.queryCompany,
      queryArtist: query.queryArtist,
      queryTitle: query.queryTitle,
      queryVariation: query.queryVariation,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.queryCompany, query.queryArtist, query.queryTitle, query.queryVariation]);

  useEffect(() => {
    setLoading(true);
    handleSearchAll(transformKey(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryObj]);

  useEffect(() => {
    let validQuery = { ...query } as any;
    if (!query.queryCompany) {
      validQuery = {
        queryCompany: undefined,
        companyId: undefined,
        companyIds: undefined,
        artistIds: undefined,
        artistId: undefined,
        queryArtist: undefined,
        queryTitle: undefined,
        titleIds: undefined,
        queryVariation: undefined,
        variationIds: undefined,
      };

      setQuery(validQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.queryCompany]);

  useEffect(() => {
    let validQuery = { ...query } as any;
    if (!query.queryArtist) {
      validQuery = {
        artistIds: undefined,
        queryArtist: undefined,
        artistId: undefined,
        queryTitle: undefined,
        titleIds: undefined,
        queryVariation: undefined,
        variationIds: undefined,
      };

      setQuery(validQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.queryArtist]);

  useEffect(() => {
    let validQuery = { ...query } as any;
    if (!query.queryTitle) {
      validQuery = {
        queryTitle: undefined,
        titleIds: undefined,
        titleId: undefined,
        queryVariation: undefined,
        variationIds: undefined,
      };

      setQuery(validQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.queryTitle]);

  return (
    <Row>
      <Col md="2" className="px-0">
        <Search
          label="レコード会社"
          labelMd="12"
          colMd="12"
          placeholder="検索"
          showIcon
          classLabel="px-0"
          rowClass="w-100"
          colClass="px-0"
          searchKey="queryCompany"
          onEnter={() => handleEnter('queryCompany')}
          onClickResult={handleClickItem}
          options={res[0]}
          loading={loading}
        />
      </Col>
      <Col md="2" className="ps-1 pe-0">
        <Search
          label="アーティスト"
          labelMd="12"
          colMd="12"
          placeholder="検索"
          showIcon
          classLabel="px-0"
          rowClass="w-100"
          colClass="px-0"
          searchKey="queryArtist"
          onEnter={() => handleEnter('queryArtist')}
          onClickResult={handleClickItem}
          options={res[1]}
          loading={loading}
        />
      </Col>
      <Col md="2" className="ps-1 pe-0">
        <Search
          label="タイトル"
          labelMd="12"
          colMd="12"
          placeholder="検索"
          showIcon
          classLabel="px-0"
          rowClass="w-100"
          colClass="px-0"
          searchKey="queryTitle"
          onEnter={() => handleEnter('queryTitle')}
          onClickResult={handleClickItem}
          options={res[2]}
          loading={loading}
        />
      </Col>
      <Col md="2" className="ps-1 pe-0">
        <Search
          label="バリエーション"
          labelMd="12"
          colMd="12"
          placeholder="検索"
          showIcon
          classLabel="px-0"
          rowClass="w-100"
          colClass="px-0"
          searchKey="queryVariation"
          onEnter={() => handleEnter('queryVariation')}
          onClickResult={handleClickItem}
          options={res[3]}
          loading={loading}
        />
      </Col>
      <Col md="4" className="ps-1 pe-0 align-self-end">
        <Row>
          <Col md="7" className="px-0">
            <ButtonGroup>
              <Button
                onClick={() => changeMonth('prev')}
                className="bg-white text-dark custom-border"
              >
                前月
              </Button>
              <Button variant="dark" className="bg-dark custom-border">
                {query.dateSearch
                  ? dayjs(query.dateSearch).format(DATE_FORMAT_5)
                  : dayjs().format(DATE_FORMAT_5)}
              </Button>
              <Button
                onClick={() => changeMonth('next')}
                className="bg-white text-dark custom-border"
              >
                次月
              </Button>
            </ButtonGroup>
          </Col>
          <Col md={5}>
            <div className="text-end">
              <Button
                variant="primary"
                className="btn-excel"
                onClick={() =>
                  exportExcel(
                    PromotionManagerApi.exportExcel({
                      companyId: query.companyIds ?? '',
                      variationId: query.variationIds ?? '',
                      titleId: query.titleIds ?? '',
                      artistId: query.artistIds ?? '',
                      time: query.dateSearch
                        ? query.dateSearch
                        : dayjs().set('date', 1).format(DATE_FORMAT_4),
                      gmt: getUserGMT(),
                    }),
                    `デジタル特典リスト_${dayjs().format(DATE_FORMAT_YMD).toString()}`,
                  )
                }
              >
                Excelダウンロード
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
