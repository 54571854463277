/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
// import PaginationComponent from 'Components/Common/Pagination/Pagination';
// import { PAGE_SIZE } from 'Constant';
import useMessage from 'Hooks/useMessage';
// import { RecordCompanyApi } from 'Datasource/RecordCompany';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { Cell } from 'react-table';
// import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';
import { SortMethod } from 'Components/Common/Table/table';
import { ECApis } from 'Datasource/EC';
import { Download } from 'react-bootstrap-icons';
import dayjs from 'dayjs';
import { DATE_FORMAT_14 } from 'Constant/Date';
import TableBT from 'Components/Common/Table/TableBT';
import { exportExcel } from 'Utils/File';
import useErrorBlob from 'Hooks/useErrorBlob';
import { formatPrice } from 'Utils/Numbers';

interface IValue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export default function TableList({ year }: { year: string }): JSX.Element {
  const { openMessageError } = useMessage();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [setPage] = useState<any>(1);
  const [listRecord, setListRecord] = useState<IValue>([]);
  const [setTotalItems] = useState<any>(0);
  const { openErrorBlob } = useErrorBlob();

  const [query, setQuery] = useQueryParams({
    page: StringParam,
    sort: StringParam,
    order: StringParam,
  });

  const handleDownloadExcel = async (tranferId: string, paymentDate: string) => {
    const formatDate = dayjs(paymentDate).format('YYMMDD');
    const dateParam = dayjs(paymentDate).format('YYYY-MM');
    const fileName = `${formatDate}_PF支払明細_platform`;
    setIsLoading(true);
    try {
      await exportExcel(ECApis.tranferDetailExportExcel(tranferId, dateParam), fileName);
    } catch (error) {
      openErrorBlob(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadPDF = async (tranferId: string, paymentDate: string) => {
    const formatDate = dayjs(paymentDate).format('YYMMDD');
    const dateParam = dayjs(paymentDate).format('YYYY-MM');
    const fileName = `${formatDate}_PF支払通知書`;
    setIsLoading(true);
    try {
      await exportExcel(ECApis.tranferDetailExportPDF(tranferId, dateParam), fileName, 'pdf');
    } catch (error) {
      openErrorBlob(error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'd-none',
        columns: [
          {
            Header: '入金ID',
            accessor: 'transferId',
            width: '300',
            disableSortBy: true,
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <Link
                  to={{ pathname: `/ec/payment-notification-detail/${row.original.transferId}` }}
                >
                  <div
                    className="text-truncate text-start payment-advice-padding"
                    title={row.original.transferId}
                  >
                    {row.original?.transferId}
                  </div>
                </Link>
              );
            },
          },
          {
            Header: '支払日',
            accessor: 'paymentDate',
            width: '150',
            Cell: ({ row }: Cell<IValue>) => {
              const formatDate = dayjs(row.original?.paymentDate).format(DATE_FORMAT_14);
              return <div className="text-truncate payment-advice-padding">{formatDate}</div>;
            },
          },
          {
            Header: '支払額',
            accessor: 'totalAmount',
            width: '100',
            disableSortBy: true,
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div className="text-truncate text-right line-33px mr-6px">
                  {row.original.totalAmount ? formatPrice(row.original.totalAmount) : 0}
                </div>
              );
            },
          },
          {
            Header: '通知書PDF',
            accessor: 'notificationPDF',
            width: '100',
            disableSortBy: true,
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div
                  className="text-truncate text-center payment-advice-padding"
                  title={row.original?.notificationPDF}
                >
                  <Link
                    to="#"
                    onClick={() =>
                      handleDownloadPDF(row.original?.transferId, row.original?.paymentDate)
                    }
                  >
                    <Download className="mr-4px"></Download>DL
                  </Link>
                </div>
              );
            },
          },
          {
            Header: '明細EXCEL',
            accessor: 'detailExcel',
            width: '100',
            disableSortBy: true,
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div
                  className="text-truncate text-center payment-advice-padding"
                  title={row.original?.detailExcel}
                >
                  <Link
                    to="#"
                    onClick={() =>
                      handleDownloadExcel(row.original?.transferId, row.original?.paymentDate)
                    }
                  >
                    <Download className="mr-4px"></Download>DL
                  </Link>
                </div>
              );
            },
          },
        ],
      },
    ],
    [],
  );

  useEffect(() => {
    getListRecord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.page, query.sort, query.order, year]);

  const getListRecord = async () => {
    setIsLoading(true);
    try {
      const formatYear = year.substring(0, 4);
      const res = await ECApis.getTranferList(formatYear, query?.order, query?.sort);
      const { data } = res.data;
      setListRecord(data);
      setTotalItems(data.meta.itemCount);
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onChangePage = async (pageChange: number) => {
    setPage(pageChange);
  };

  //* handle sort from server side
  const handleManualSort = useCallback(
    (sortBy: SortMethod[]) => {
      if (sortBy.length > 0) {
        const sortOption = sortBy[0];
        setQuery({ sort: sortOption.id, order: sortOption.desc ? 'DESC' : 'ASC' });
      } else {
        setQuery({ sort: '', order: '' });
      }
    },
    [setQuery],
  );

  return (
    <div className="overflow-hidden mt-4">
      <Container fluid className="px-4 d-flex justify-content-center">
        <SpinnerComponent isLoading={isLoading} />
        <TableBT
          heightBody="calc(100vh - 250px)"
          columns={columns}
          data={listRecord}
          getCellProps={() => ({
            className: 'p-0',
          })}
          manualSortBy
          onSort={handleManualSort}
        />
      </Container>
    </div>
  );
}
