import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { TableList } from 'Components/Page/RegisterCD/index';
import PaginationComponent from 'Components/Common/Pagination/Pagination';
import { PAGE_SIZE, PAGE_SIZE_ARTIST } from 'Constant';
import CDFilter from 'Components/Page/CD/CDFilter';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { CDApis } from 'Datasource/CD';
import useMessage from 'Hooks/useMessage';
import { SortMethod } from 'Components/Common/Table/table';

export default function ListCD(): JSX.Element {
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    queryArtist: StringParam,
    queryTitle: StringParam,
    queryCD: StringParam,
    artistIds: StringParam,
    titleIds: StringParam,
    cdIds: StringParam,
    companyIds: StringParam,
    sort: StringParam,
    order: StringParam,
  });
  const [loading, setLoading] = useState(false);
  const { openMessageError } = useMessage();

  const [data, setData] = useState({
    cds: [],
    total: 0,
  });

  const handleGetCDs = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await CDApis.getCDs({
        page: query.page ?? 1,
        limit: PAGE_SIZE,
        artistId: query.artistIds,
        titleId: query.titleIds,
        cdId: query.cdIds,
        companyId: query.companyIds,
        query: query?.queryCD || '',
        sort: query.sort || '',
        order: query.order || '',
      });

      setData({
        cds: data?.items,
        total: data?.meta?.totalItems,
      });
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetCDs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  //* handle sort from server side
  const handleManualSort = useCallback(
    (sortBy: SortMethod[]) => {
      if (sortBy.length > 0) {
        const sortOption = sortBy[0];
        setQuery({ sort: sortOption.id, order: sortOption.desc ? 'DESC' : 'ASC' });
      } else {
        setQuery({ sort: '', order: '' });
      }
    },
    [setQuery],
  );

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <Container fluid className="cd-register">
        <CDFilter />
        <Row>
          <Col md={12} className="d-flex justify-content-end p-0 px-4 mt-2">
            <PaginationComponent
              activePage={query?.page ?? 1}
              total={data?.total}
              perPage={PAGE_SIZE_ARTIST}
            />
          </Col>
        </Row>

        <TableList dataSource={data.cds || []} onSort={handleManualSort} />
      </Container>
    </>
  );
}
