import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Image, Button } from 'react-bootstrap';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import BoxForm from 'Components/Common/Form/BoxForm';
import { useParams, useHistory } from 'react-router-dom';
import { ArtistApi } from 'Datasource/ArtistApi';
import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import useMessage from 'Hooks/useMessage';

interface paramsURL {
  id: string;
}

interface DetailArtist {
  id?: number;
  localName?: string;
  furiganaName?: string;
  globalName?: string;
  image?: string;
  officialSite?: string;
}

export default function DetailArtist(): JSX.Element {
  const history = useHistory();
  const { openMessageError } = useMessage();

  const { id }: paramsURL = useParams();
  const [dataDetailArtist, setDataDetailArtist] = useState<DetailArtist>();
  const [loading, setLoading] = useState(false);

  const handleGetDetailArtist = async () => {
    try {
      setLoading(true);
      const idArtist = parseInt(id, 10);
      const {
        data: { data },
      } = await ArtistApi.getArtistById(idArtist);
      setDataDetailArtist(data);
      setLoading(false);
    } catch (error) {
      openMessageError(error);
    }
  };

  useEffect(() => {
    if (id) {
      handleGetDetailArtist();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <Container>
      <SpinnerComponent isLoading={loading} />
      <Row>
        <Col md="12" className="mt-3">
          <Form id="register-artist">
            <BoxForm title="アーティスト情報">
              <>
                <FormGroupInput
                  value={id ? dataDetailArtist?.localName : ''}
                  labelMd="3"
                  colMd="9"
                  label="アーティスト名（国内向け表記）"
                  disabled={true}
                />

                <FormGroupInput
                  value={id ? dataDetailArtist?.furiganaName : ''}
                  labelMd="3"
                  colMd="9"
                  label="アーティスト名（フリガナ）"
                  disabled={true}
                />

                <FormGroupInput
                  value={id ? dataDetailArtist?.globalName : ''}
                  labelMd="3"
                  colMd="9"
                  label="アーティスト名（海外向け表記）"
                  disabled={true}
                />

                <FormGroupInput
                  value={id ? dataDetailArtist?.officialSite : ''}
                  labelMd="3"
                  colMd="9"
                  label="オフィシャルサイト"
                  disabled={true}
                />

                <Form.Group as={Row} className="mb-2">
                  <Form.Label column md="3">
                    アーティストイメージ
                  </Form.Label>
                  <Col sm="9">
                    <div className="d-flex w-100 position-relative">
                      <div className="box-image d-flex justify-content-center align-items-center rounded-3">
                        <Image
                          className="w-100 h-100 rounded-3"
                          src={id ? handleConnectStaticEndPoint(dataDetailArtist?.image ?? '') : ''}
                          alt=""
                        />
                      </div>
                    </div>
                  </Col>
                </Form.Group>
              </>
            </BoxForm>
          </Form>
        </Col>
      </Row>
      <div className="text-center py-3">
        <Button className="btn-equal-width btn-focus-none" onClick={() => history.goBack()}>
          一覧へ戻る
        </Button>
      </div>
    </Container>
  );
}
