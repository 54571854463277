import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { passwordValidation } from 'Utils/Validation';
import MessageError from 'Components/Common/MessageError';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import _isEmpty from 'lodash/isEmpty';
import useMessage from 'Hooks/useMessage';
import { getParamsFromQueryString } from 'Utils/Params';
import { AuthApi } from 'Datasource/Auth';

export interface IResetPasswordForm {
  code?: string;
  password: string;
  confirmPassword: string;
}

const schema = yup.object().shape({
  password: passwordValidation({ min: 8 }),
  confirmPassword: yup.string().test({
    message: 'パスワードが一致しません。',
    test: function (value) {
      return value === this.parent.password;
    },
  }),
});

export default function ResetPasswordForm(): JSX.Element {
  const { openMessageError } = useMessage();
  const history = useHistory();
  const codeReset = getParamsFromQueryString(history.location.search).code;
  const [resetSuccess, setResetSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IResetPasswordForm>({ resolver: yupResolver(schema) });

  const onSubmit = handleSubmit((data: IResetPasswordForm) => {
    const handleResetPass = async (data: IResetPasswordForm) => {
      if (_isEmpty(errors)) {
        const dataReset: IResetPasswordForm = { ...data, code: codeReset };
        {
          try {
            setLoading(true);
            await AuthApi.resetPassword(dataReset);
            setLoading(false);
            setResetSuccess(true);
          } catch (error) {
            setLoading(false);
            openMessageError(error);
          }
        }
      }
    };
    handleResetPass(data);
  });

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <form className="public-card-width" onSubmit={onSubmit}>
        {!resetSuccess ? (
          <>
            <FormGroup className="mb-3">
              <FormLabel>新しいパスワード</FormLabel>
              <FormControl
                {...register('password')}
                placeholder="半角英数混合８文字"
                type="password"
                aria-label="password"
                aria-describedby="input--password"
                value={watch('password')}
                onChange={({ target: { value } }) => setValue('password', value.replace(/\s/g, ''))}
              />
              <MessageError message={errors.password?.message} />
            </FormGroup>

            <FormGroup className="mb-3">
              <FormLabel>新しいパスワード確認用</FormLabel>
              <FormControl
                {...register('confirmPassword')}
                placeholder="半角英数混合８文字"
                type="password"
                aria-describedby="input--re-password"
                value={watch('confirmPassword')}
                onChange={({ target: { value } }) =>
                  setValue('confirmPassword', value.replace(/\s/g, ''))
                }
              />
              <MessageError message={errors.confirmPassword?.message} />
            </FormGroup>

            <FormGroup className="mt-4 d-flex justify-content-center">
              <Button type="submit" className="bg-black border-0">
                パスワード登録
              </Button>
            </FormGroup>
          </>
        ) : (
          <>
            <p className="text-center mb-3">新しいパスワードの登録が完了しました。</p>
            <FormGroup className="mt-4 d-flex justify-content-center">
              <Button
                type="submit"
                className="bg-black border-0"
                onClick={() => history.push('/login')}
              >
                ログイン画面へ
              </Button>
            </FormGroup>
          </>
        )}
      </form>
    </>
  );
}
