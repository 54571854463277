import React, { useEffect, useState, useMemo } from 'react';
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { Cell } from 'react-table';
import { isEmpty } from 'lodash';
import PaginationComponent from 'Components/Common/Pagination/Pagination';
import TableCommon from 'Components/Common/Table/TableCommon';
import { PromotionManagerApi } from 'Datasource/PromotionManager';
import { PAGE_SIZE } from 'Constant';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { DATE_FORMAT_2, DATE_FORMAT_4 } from 'Constant/Date';
import { IDataPromotion } from 'Types/Page/PromotionManager';

export default function SaleSchedule(): JSX.Element {
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'pe-none',
        columns: [
          {
            Header: '特典名称',
            accessor: 'name',
            maxWidth: 500,
            width: 450,
            // eslint-disable-next-line react/display-name
            Cell: ({ row }: Cell<IDataPromotion>) => {
              return (
                <Link
                  title={row.original.name}
                  className="d-block text-truncate text-start"
                  to={`/promotion-manager/${row.original.id}`}
                >
                  {row.original.name}
                </Link>
              );
            },
          },
          {
            Header: '販売開始⽇時',
            accessor: 'saleStartDate',
            maxWidth: 250,
            width: 250,
            Cell: ({ row }: Cell<IDataPromotion>) => {
              return dayjs(row.original.saleStartDate).format(DATE_FORMAT_2);
            },
          },
          {
            Header: '販売終了日時',
            accessor: 'saleEndDate',
            maxWidth: 250,
            width: 250,
            Cell: ({ row }: Cell<IDataPromotion>) => {
              return dayjs(row.original.saleEndDate).format(DATE_FORMAT_2);
            },
          },
          {
            Header: 'DL可能開始⽇時',
            accessor: 'downloadDate',
            maxWidth: 250,
            width: 250,
            Cell: ({ row }: Cell<IDataPromotion>) => {
              return dayjs(row.original.downloadDate).format(DATE_FORMAT_2);
            },
          },
        ],
      },
    ],
    [],
  );
  const [loading, setLoading] = useState(false);
  const [digitalTokutens, setDigitalTokutens] = useState([]);
  const [total, setTotal] = useState(0);

  const [query] = useQueryParams({
    page: NumberParam,
    status: StringParam,
    queryCompany: StringParam,
    queryArtist: StringParam,
    queryTitle: StringParam,
    queryVariation: StringParam,
    companyId: StringParam,
    artistId: StringParam,
    titleId: StringParam,
    companyIds: StringParam,
    artistIds: StringParam,
    titleIds: StringParam,
    variationIds: StringParam,
    dateSearch: StringParam,
  });

  const handleOnchangeList = async (ids: any) => {
    setLoading(true);
    const {
      data: { data },
    } = await PromotionManagerApi.getAllPromotions({
      page: query.page ?? 1,
      limit: PAGE_SIZE,
      companyId: ids.companyIds,
      artistId: ids.artistIds,
      titleId: ids.titleIds,
      variationId: ids.variationIds,
      time: ids.dateSearch,
      startDate: ids.startDate,
      endDate: ids.endDate,
    });

    setDigitalTokutens(data.digitalTokutens);
    setTotal(data.totalCount);
    setLoading(false);
  };

  useEffect(() => {
    const dateSearch = query.dateSearch ? query.dateSearch : dayjs().format(DATE_FORMAT_4);
    const startOfMonth = dayjs(dateSearch).startOf('month').format();
    const startDate = dayjs(startOfMonth).utc().format();
    const endOfMonth = dayjs(dateSearch).endOf('month').format();
    const endDate = dayjs(endOfMonth).utc().format();
    const ids = {
      companyIds: query.companyIds ?? '',
      artistIds: query.artistIds ?? '',
      titleIds: query.titleIds ?? '',
      variationIds: query.variationIds ?? '',
      dateSearch: dateSearch,
      startDate,
      endDate,
    };

    handleOnchangeList(ids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    query.page,
    query.companyIds,
    query.artistIds,
    query.titleIds,
    query.variationIds,
    query.dateSearch,
  ]);

  return (
    <Row className="px-3">
      <SpinnerComponent isLoading={loading} />
      {!isEmpty(digitalTokutens) ? (
        <>
          <div className="d-flex justify-content-end">
            <PaginationComponent activePage={query.page ?? 1} total={total} perPage={PAGE_SIZE} />
          </div>
          <TableCommon
            classTable="table-digital-tokuten"
            heightBody="calc(100vh - 270px)"
            columns={columns}
            data={digitalTokutens}
            getHeaderProps={() => {
              return {
                className: 'text-white d-flex align-item-center bg-dark',
              };
            }}
            getColumnProps={() => {
              return {
                className: 'text-center',
              };
            }}
            getRowProps={() => {
              return {
                style: {},
              };
            }}
            getCellProps={() => {
              return {
                className: 'bg-white text-dark',
              };
            }}
          />
        </>
      ) : !loading ? (
        <div className="vh-100-final d-flex align-items-center justify-content-center">
          デジタル特典が登録されていません
        </div>
      ) : null}
    </Row>
  );
}
