import React, { FC, useEffect, useState } from 'react';
import { Button, Col, Container, Row, Image, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { find, isEmpty } from 'lodash';
import dayjs from 'dayjs';

import { PAGE_SIZE } from 'Constant';
import { DATE_FORMAT_3, DATE_FORMAT_EXPORT_EXCEL } from 'Constant/Date';

import { getUserGMT } from 'Utils/Params';

import { TitleApi } from 'Datasource/TitleApi';

import PaginationComponent from 'Components/Common/Pagination/Pagination';
import Filter from 'Components/Common/Filter/Filter';
import Tabs from 'Components/Common/Tabs/Tabs';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { exportExcel } from 'Utils/File';
import { handleConvertToDash } from 'Utils/IsDash';
import TitleSearch from './TitleSearch';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import useErrorBlob from 'Hooks/useErrorBlob';

interface BtnOptionType {
  name: string;
  value?: number | string;
}

interface TitleInterface {
  status: number | null;
  artistName: string | null;
  coverUrl: string | null;
  label: string | null;
  releaseDate: string | null;
  titleName: string | null;
  id: number | null;
  variationName: string | null;
}

const btnGroupOptions = [
  { name: 'すべて', value: '' },
  { name: '一時保存', value: 0 },
  { name: '登録済', value: 1 },
];

const TitleList: FC = () => {
  const history = useHistory();
  const [query] = useQueryParams({
    page: NumberParam,
    status: StringParam,
    queryCompany: StringParam,
    queryArtist: StringParam,
    queryTitle: StringParam,
    companyIds: StringParam,
    artistIds: StringParam,
    titleIds: StringParam,
    isSetEmpty: NumberParam,
  });
  const [tabActive, setTabActive] = useState(query.status ?? btnGroupOptions[0].name);
  const [titles, setTitles] = useState<TitleInterface[]>([]);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const activePage = query.page ?? 1;
  const [status, setStatus] = useState<number | string>(
    find(btnGroupOptions, (option: BtnOptionType) => option.name === query.status)?.value ?? '',
  );
  const [loading, setLoading] = useState(false);
  const [paramsQuery, setparamsQuery] = useState({});
  const [exporting, setexporting] = useState<boolean>(false);
  const { openErrorBlob } = useErrorBlob();

  const handleChangeTabs = (tab: string) => {
    if (!tab) return;
    const value = find(btnGroupOptions, (option: BtnOptionType) => option.name === tab)?.value;
    setTabActive(tab);
    setStatus(value ?? '');
  };

  const handleConvertReleaseDate = (date: string | null) => {
    return date ? dayjs(date).format(DATE_FORMAT_3) : '-';
  };

  const handleOnchangeList = async (ids: any) => {
    setLoading(true);
    const {
      data: { data },
    } = await TitleApi.getAllTitles({
      page: activePage,
      limit: PAGE_SIZE,
      query: '',
      status,
      companyId: ids.companyIds,
      artistId: ids.artistIds,
      titleId: ids.titleIds,
    });

    setTitles(data.titles);
    setNumberOfPages(data.totalCount);
    setLoading(false);
  };

  useEffect(() => {
    const ids = {
      companyIds: query.companyIds ?? '',
      artistIds: query.artistIds ?? '',
      titleIds: query.titleIds ?? '',
    };

    handleOnchangeList(ids);
    setparamsQuery({
      companyId: ids.companyIds,
      artistId: ids.artistIds,
      titleId: ids.titleIds,
      status,
      gmt: getUserGMT(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, status, query.companyIds, query.artistIds, query.titleIds]);

  const handleExportExcel = async () => {
    try {
      setexporting(true);
      await exportExcel(
        TitleApi.exportExcel(paramsQuery),
        `マスタタイトルリスト_${dayjs().format(DATE_FORMAT_EXPORT_EXCEL).toString()}`,
      );
    } catch (error) {
      openErrorBlob(error);
    } finally {
      setexporting(false);
    }
  };
  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <Container fluid className="pb-3">
        <Filter filterClass="shadow-sm">
          <Row>
            <Col md="10" className="px-0">
              <Row>
                <TitleSearch />
                <Col md="3" lg="3" className="ps-3 px-0 align-self-end">
                  <Tabs
                    options={btnGroupOptions}
                    tabActive={tabActive}
                    onChange={handleChangeTabs}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="2" className="px-0 d-flex justify-content-end align-self-end">
              <Button
                variant="primary"
                className={`btn-excel ${exporting ? 'pe-none' : ''}`}
                onClick={handleExportExcel}
              >
                {exporting ? (
                  <Spinner
                    animation="border"
                    variant="light"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  'Excelダウンロード'
                )}
              </Button>
            </Col>
          </Row>
        </Filter>
        <Container className="px-4 d-flex justify-content-end">
          <PaginationComponent activePage={activePage} total={numberOfPages} perPage={PAGE_SIZE} />
        </Container>
        <Container>
          {!isEmpty(titles) ? (
            <>
              <Row>
                {titles.map((item: TitleInterface, index: number) => (
                  <Col
                    onClick={() => history.push(`/detail-title/${item.id}`)}
                    key={index}
                    xs="12"
                    className="title-item mb-3 cursor-pointer"
                  >
                    <div className="d-flex border shadow text-truncate">
                      <div className="avatar-title">
                        {item.coverUrl ? (
                          <Image
                            key={item.coverUrl}
                            src={process.env.REACT_APP_STATIC_URL + item.coverUrl}
                            className="avatar-title"
                            alt="img-g"
                          />
                        ) : (
                          <div className="avatar-title bg-gray avatar-bank h-100 d-flex justify-content-center align-items-center">
                            <p className="gray-dark">No Image</p>
                          </div>
                        )}
                      </div>
                      <div className="p-2 flex-fill text-truncate">
                        <p className="pb-1 fs-12 text-truncate">{`${
                          item.artistName
                        } (${handleConvertToDash(item.label)})`}</p>
                        <div className="ps-3 pt-1 text-truncate">
                          <p className="fw-bold text-truncate">
                            {handleConvertToDash(item.titleName)}
                          </p>
                          <div className="fs-12 mt-1 text-truncate">
                            {handleConvertReleaseDate(item.releaseDate)}
                          </div>
                          <div className="fs-12 mt-1 text-truncate">
                            バリエーション : &nbsp;&nbsp;
                            {`${handleConvertToDash(
                              item.variationName
                                ? item.variationName
                                    .split(',')
                                    .map((value: string) => {
                                      return `[${value}]`;
                                    })
                                    .join('\u3000')
                                : '',
                            )}`}
                          </div>
                        </div>
                      </div>
                      <div className="align-items-center fs-12">
                        <div
                          className={`w-130 h-100 d-flex align-items-center justify-content-center px-5 ${
                            item.status ? 'bg-title-registered' : 'bg-title-draft'
                          }`}
                        >
                          {item.status ? '登録済' : '一時保存 '}
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </>
          ) : (
            <div className="vh-100-final d-flex align-items-center justify-content-center">
              タイトルが登録されていません
            </div>
          )}
        </Container>
      </Container>
    </>
  );
};

export default TitleList;
