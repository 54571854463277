import brAxios from 'Api/Interceptors';
import { AxiosResponse } from 'axios';

interface QueryParamsCompanys {
  page?: number;
  perPage?: number;
  queryCompany?: string;
  type?: string;
}

interface QueryParamsArtists extends Omit<QueryParamsCompanys, 'queryCompany'> {
  queryArtist?: string;
  companyId?: string;
}

interface QueryParamsTitles extends Omit<QueryParamsArtists, 'queryArtist'> {
  query?: string;
  companyId?: string;
  artistId?: string | number;
}

interface QueryParamsVariations extends QueryParamsTitles {
  titleId?: string;
}

export const SampleMasterApi = {
  getAllSuggestionArtistSample: ({
    page,
    perPage,
    queryArtist,
    companyId,
  }: QueryParamsArtists): Promise<AxiosResponse> => {
    const response = brAxios.get(
      `/bootrock-company/artists?page=${page ?? ''}&limit=${perPage ?? ''}&query=${
        queryArtist ?? ''
      }&companyId=${companyId}`,
    );
    return response;
  },

  getAllSuggestionTitlesSample: ({
    page,
    perPage,
    query,
    companyId,
    artistId,
  }: QueryParamsTitles): Promise<AxiosResponse> => {
    const response = brAxios.get(
      `/bootrock-company/titles/suggestion?page=${page ?? ''}&limit=${perPage ?? ''}&query=${
        query ?? ''
      }&companyId=${companyId ?? ''}&artistId=${artistId ?? ''}`,
    );
    return response;
  },

  getAllSuggestionVariationsSample: ({
    page,
    perPage,
    query,
    companyId,
    artistId,
    titleId,
  }: QueryParamsVariations): Promise<AxiosResponse> => {
    const response = brAxios.get(
      `/bootrock-company/variations?page=${page ?? ''}&limit=${perPage ?? ''}&query=${
        query ?? ''
      }&companyId=${companyId ?? ''}&artistId=${artistId ?? ''}&titleId=${titleId ?? ''}`,
    );
    return response;
  },

  getListCompanySuggestionSample: ({
    page,
    perPage,
    queryCompany,
  }: QueryParamsCompanys): Promise<AxiosResponse> => {
    return brAxios.get(
      `/bootrock-company/record-companies/suggestion?page=${page ?? ''}&limit=${
        perPage ?? ''
      }&query=${queryCompany ? encodeURIComponent(queryCompany) : ''}`,
    );
  },

  getListUserReleasedByVaritionId: ({
    variationId,
  }: {
    variationId: string;
  }): Promise<AxiosResponse> =>
    brAxios.get(
      `/bootrock-company/titles/users-belong-to-sample-master/variation/${variationId ?? ''}`,
    ),
};
