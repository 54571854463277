import React, { createElement, useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './tooltipsStyled.css';

interface TooltipsWithTruncateProps {
  children: React.ReactNode;
  tooltips: string;
  id?: string;
  overlayProps?: any;
  tooltipProps?: any;
  extraClientWidth?: number; // trừ thêm khi wrapper bên ngoài input quá rộng
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function TooltipsWithTruncate({
  children,
  tooltips,
  id,
  overlayProps = {},
  tooltipProps = {},
  extraClientWidth = 0,
}: TooltipsWithTruncateProps) {
  const contentRef = useRef<any>(null);
  const originalRef = useRef<any>(null);

  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    if (
      originalRef?.current?.clientWidth >
      contentRef?.current?.clientWidth - (extraClientWidth ? extraClientWidth : 0)
    ) {
      setIsShow(true);
      return;
    }

    setIsShow(false);
  }, [tooltips, extraClientWidth]);

  return (
    <>
      <OverlayTrigger
        {...overlayProps}
        overlay={
          isShow ? (
            <Tooltip id={id ? id : tooltips} className="tooltipsWithTruncate" {...tooltipProps}>
              {tooltips}
            </Tooltip>
          ) : (
            <></>
          )
        }
      >
        <div ref={contentRef}>{children}</div>
      </OverlayTrigger>

      {createElement('div', { className: 'origin-ref-content', ref: originalRef }, tooltips)}
    </>
  );
}

export default TooltipsWithTruncate;
