import React, { useState } from 'react';
import Select from 'react-select';
import { CompanyFields, CompanyLists } from 'Constant/Mockup';
import { Col, Row } from 'react-bootstrap';
import { ISelect } from 'Types/Common';
import FormBlock from 'Components/Common/Form/Block';

export default function CompanyInformation(): JSX.Element {
  const [companySelected, setCompanySelected] = useState<ISelect | null>();

  const searchCompany = [
    { label: 'cp1', value: 'cp1' },
    { label: 'cp2', value: 'cp2' },
    { label: 'cp3', value: 'cp3' },
  ];

  const getCompanyMock = (companyName?: string | number) => {
    if (!companyName || companyName === '') return null;
    return CompanyLists[companyName];
  };

  const getValueCompanyField = (fieldName?: string): string => {
    if (!fieldName) return '';
    if (!companySelected) return '';
    const values = getCompanyMock(companySelected.value);
    return values ? values[fieldName] : '';
  };

  return (
    <div className="py-4 mt-6">
      <Row className="justify-content-center">
        <Col xs="12" md="8">
          <Row className="company-info-list">
            <Col sm="12" className="mb-3">
              <FormBlock
                label="法人名"
                valueRender={
                  <Select
                    options={searchCompany}
                    className="w-100"
                    placeholder="選択または検索"
                    onChange={setCompanySelected}
                    defaultValue={companySelected}
                    components={{ IndicatorSeparator: () => null }}
                  />
                }
              />
            </Col>
            {CompanyFields.map((block, i) => (
              <Col xs="12" key={i} className="mb-5">
                {block.map((item, k) => (
                  <Col xs="12" key={k} className="mb-3">
                    <FormBlock
                      label={item.label}
                      value={getValueCompanyField(item.field)}
                      labelProps={{ sm: '4' }}
                      valueProps={{ sm: '8' }}
                    />
                  </Col>
                ))}
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
}
