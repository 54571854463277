/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import ModalComponent from 'Components/Common/Modal';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import React, { ChangeEvent, PropsWithChildren, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Toast, ToastContainer } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import BoxFormEc from 'Components/Common/EC/BoxForm';
import FormGroupFile from 'Components/Common/Form/FormGroupFile';
import ColorPickerEc from 'Components/Common/EC/ColorPicker';
import FormGroupTextArea from 'Components/Common/Form/FormGroupTextArea';
import { SiteBasicSettingEc } from 'Datasource/SettingEc';
import useMessage from 'Hooks/useMessage';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { checkIfFilesAreCorrectType } from 'Utils/Validation';

interface Iprop {
  onChangeTab: () => void;
}

const LABEL_INPUT = 3;
const COL_INPUT = 9;

const settingSchema = yup
  .object()
  .shape({
    title: yup.string().nullable(true).required('入力してください。').trim('入力してください。'),
    color: yup.string().nullable(true).required('入力してください。').trim('入力してください。'),
    email: yup.string().nullable(true).required('入力してください。').trim('入力してください。'),
    copywriter: yup
      .string()
      .nullable(true)
      .required('入力してください。')
      .trim('入力してください。'),
    jasracNumber: yup
      .string()
      .nullable(true)
      .required('入力してください。')
      .trim('入力してください。'),
    metaTag: yup.string().nullable(true).required('入力してください。').trim('入力してください。'),
  })
  .required();

export default function WebSetting(): JSX.Element {
  //   const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [color, setColor] = useState('');
  const [logoFile, setLogoFile] = useState<any>();
  const [logoPreview, setLogoPreview] = useState<string>();
  const [logoUploaded, setLogoUploaded] = useState<string>();
  const [jasracFile, setJasracFile] = useState<any>();
  const [jasracImgPreview, setJasracImgPreview] = useState<string>();
  const [jasracImgUploaded, setJasracImgUploaded] = useState<string>();
  const [faviconFile, setFaviconFile] = useState<any>();
  const [faviconPreview, setFaviconPreview] = useState<string>();
  const [faviconUploaded, setFaviconUploaded] = useState<string>();
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const { openMessageError } = useMessage();

  const {
    register,
    setError,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(settingSchema),
  });

  const onSubmit = handleSubmit(() => {
    if (watch('fileLogoCheck') == 0) {
      setError('fileLogo', {
        type: 'custom',
        message: '画像は縦 80ピクセル 横 700ピクセルの画像を登録してください。',
      });
      return;
    }

    if (watch('faviconCheck') == 0) {
      setError('faviconImg', {
        type: 'custom',
        message: '！32px×32px の「.ico」ファイルをアップロードしてください。',
      });
      return;
    }

    if (!logoFile && !logoUploaded) {
      setError('fileLogo', {
        type: 'custom',
        message: '入力してください。',
      });
      return;
    }

    if (!faviconFile && !faviconUploaded) {
      setError('faviconImg', {
        type: 'custom',
        message: '入力してください。',
      });
      return;
    }

    if (!jasracFile && !jasracImgUploaded) {
      setError('jasracImg', {
        type: 'custom',
        message: '入力してください。',
      });
      return;
    }

    if (isEmpty(errors)) {
      setShowModal(true);
    }
  });

  const onSubmitModal = async () => {
    setIsloading(true);
    const formData = new FormData();
    formData.append('title', watch('title'));
    formData.append('color', watch('color'));
    formData.append('email', watch('email'));
    formData.append('copywriter', watch('copywriter'));
    formData.append('jasracNumber', watch('jasracNumber'));
    formData.append('metaTag', watch('metaTag'));
    formData.append('logoImage', logoFile);
    formData.append('jasracImage', jasracFile);
    formData.append('favicon', faviconFile);
    try {
      setShowModal(false);
      await SiteBasicSettingEc.updateSetting(formData);
    } catch (error) {
      openMessageError(error, '登録が失敗しました。');
    } finally {
      setIsloading(false);
      setShowToast(true);
    }
  };

  const handleChangeLogo = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    } else {
      const imageFile = e.target.files[0];
      setLogoFile(imageFile);
      // take the given file (which should be an image) and return the width and height
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onload = function () {
        const img: any = new Image();
        img.src = reader.result;
        img.onload = function () {
          if (
            img.width != 700 ||
            img.height != 80 ||
            !['image/jpeg', 'image/png'].includes(imageFile.type)
          ) {
            setValue('fileLogoCheck', 0);
            setError('fileLogo', {
              type: 'custom',
              message: '画像は縦 80ピクセル 横 700ピクセルの画像を登録してください。',
            });
          } else {
            setValue('fileLogoCheck', 1);
          }
        };
      };
    }
  };

  const handleChangeFaviconImg = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    } else {
      const imageFile = e.target.files[0];
      setFaviconFile(imageFile);
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onload = function () {
        const img: any = new Image();
        img.src = reader.result;
        img.onload = function () {
          if (img.width != 32 || img.height != 32 || !['image/x-icon'].includes(imageFile.type)) {
            setValue('faviconCheck', 0);
            setError('favicon', {
              type: 'custom',
              message: '！32px×32px の「.ico」ファイルをアップロードしてください。',
            });
          } else {
            setValue('faviconCheck', 1);
          }
        };
      };
    }
  };

  const handleChangeJasracImg = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    setJasracFile(e.target.files[0]);
  };

  useEffect(() => {
    if (!logoFile) {
      setLogoPreview(undefined);
      return;
    }
    setLogoUploaded(undefined);
    const logoObjectUrl = URL.createObjectURL(logoFile);
    setLogoPreview(logoObjectUrl);
  }, [logoFile]);

  useEffect(() => {
    if (!jasracFile) {
      setJasracImgPreview(undefined);
      return;
    }
    setJasracImgUploaded(undefined);
    const jasracImgObjectUrl = URL.createObjectURL(jasracFile);
    setJasracImgPreview(jasracImgObjectUrl);
  }, [jasracFile]);

  useEffect(() => {
    if (!faviconFile) {
      setFaviconPreview(undefined);
      return;
    }
    setFaviconUploaded(undefined);
    const faviconObjectUrl = URL.createObjectURL(faviconFile);
    setFaviconPreview(faviconObjectUrl);
  }, [faviconFile]);

  const handleFetchSettingData = async () => {
    try {
      setIsloading(true);
      const {
        data: { data },
      } = await SiteBasicSettingEc.getSetting(0);
      const objectKeyArr = Object.keys(data);
      objectKeyArr.map((keyName) => {
        setValue(keyName, data[keyName]);
      });
      setColor(data.color);
      setLogoUploaded(data.logoImage);
      setFaviconUploaded(data.favicon);
      setJasracImgUploaded(data.jasracImage);
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    handleFetchSettingData();
  }, []);

  return (
    <Container className="py-4">
      <SpinnerComponent isLoading={isLoading} />
      <ModalComponent
        title="登録確認"
        show={showModal}
        onCloseModal={() => setShowModal(false)}
        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
        variantButtonSubmit="primary"
        submitIdForm="curatorManagement"
        onSubmitModal={onSubmitModal}
      >
        <div>入力した内容で登録します。よろしいですか。</div>
      </ModalComponent>
      <ToastContainer className="ml-105px mb-90px" position="top-center">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
          className="d-inline-block rounded-pill w-auto mt-100px"
          bg="success"
        >
          <Toast.Body className="text-white">クリップボードにコピー!</Toast.Body>
        </Toast>
      </ToastContainer>
      <Row>
        <Col className="m-auto" md="11">
          <Form onSubmit={onSubmit} id="web-setting">
            <BoxFormEc classBox="text-dark" title="-">
              <>
                <FormGroupInput
                  value={watch('title')}
                  onChange={(value) => setValue('title', value ?? '')}
                  labelMd={LABEL_INPUT}
                  colMd={COL_INPUT}
                  label="サイトタイトル*"
                  errorMessage={errors.title?.message}
                  register={{ ...register('title') }}
                />

                <FormGroupFile
                  fileId="web-logo"
                  innerText="画像をアップロード"
                  labelMd={LABEL_INPUT}
                  colMd={3}
                  label="ロゴ画像*"
                  hintText="推奨サイズ　横:80px 縦:700px"
                  hintTextClass="mt-1"
                  onChange={(e: any) => {
                    handleChangeLogo(e);
                  }}
                  filePreview={logoPreview}
                  imgUploaded={logoUploaded}
                  previewClass="h-40px w-350px fit-cover"
                  errorMessage={errors.fileLogo?.message}
                  messageWrapper="w-200"
                  register={{ ...register('fileLogo') }}
                />

                <FormGroupFile
                  fileId="faviconImg"
                  innerText="icoファイルをアップロード"
                  labelMd={LABEL_INPUT}
                  colMd={3}
                  label="ファビコン*"
                  hintText="推奨サイズ　横:32px 縦:32px"
                  hintTextClass="mt-1"
                  onChange={(e: any) => handleChangeFaviconImg(e)}
                  filePreview={faviconPreview}
                  imgUploaded={faviconUploaded}
                  previewClass="h-40px w-40px fit-cover"
                  messageWrapper="w-200"
                  errorMessage={errors.favicon?.message}
                  register={{ ...register('faviconImg') }}
                />

                <ColorPickerEc
                  labelMd={LABEL_INPUT}
                  colMd={6}
                  label="ベースカラー*"
                  classCol="d-flex"
                  color={color}
                  setColor={setColor}
                  classContent="h-40px w-80px rounded-4px mr-10px"
                />

                <FormGroupInput
                  value={watch('email')}
                  onChange={(value) => setValue('email', value ?? '')}
                  labelMd={LABEL_INPUT}
                  colMd={COL_INPUT}
                  label="お問合せメールアドレス*"
                  errorMessage={errors.email?.message}
                  register={{ ...register('email') }}
                />

                <FormGroupInput
                  value={watch('copywriter')}
                  onChange={(value) => setValue('copywriter', value ?? '')}
                  labelMd={LABEL_INPUT}
                  colMd={COL_INPUT}
                  label="コピーライト*"
                  errorMessage={errors.copywriter?.message}
                  register={{ ...register('copywriter') }}
                />

                <FormGroupFile
                  fileId="jasrac-img"
                  innerText="画像をアップロード"
                  labelMd={LABEL_INPUT}
                  colMd={3}
                  label="JASRAC許諾画像*"
                  hintText="推奨サイズ　横:100px 縦:100px"
                  hintTextClass="mt-1"
                  onChange={(e: any) => handleChangeJasracImg(e)}
                  imgUploaded={jasracImgUploaded}
                  filePreview={jasracImgPreview}
                  previewClass="h-100px w-100px fit-cover"
                  errorMessage={errors.jasracImg?.message}
                  register={{ ...register('jasracImg') }}
                />

                <FormGroupInput
                  value={watch('jasracNumber')}
                  onChange={(value) => setValue('jasracNumber', value ?? '')}
                  labelMd={LABEL_INPUT}
                  colMd={COL_INPUT}
                  label="JASRAC許諾番号*"
                  errorMessage={errors.jasracNumber?.message}
                  register={{ ...register('jasracNumber') }}
                />

                <FormGroupTextArea
                  labelMd={LABEL_INPUT}
                  onChange={(value) => setValue('metaTag', value ?? '')}
                  colMd={COL_INPUT}
                  label="METAタグ*"
                  register={register('metaTag')}
                  errorMessage={errors.metaTag?.message}
                  classInput="mt-2"
                  rows={8}
                  value={watch('metaTag')}
                />
              </>
              <div className="text-center mt-4">
                <Button type="submit" className="px-5 btn-equal-width" variant="primary">
                  登録する
                </Button>
              </div>
            </BoxFormEc>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
