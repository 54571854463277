import { debounce } from 'lodash';
import { PAGE_SIZE } from 'Constant';
import { ArtistApi } from 'Datasource/ArtistApi';
import { TitleApi } from 'Datasource/TitleApi';
import { CompanyApi } from 'Datasource/CompanyApi';
import { VariationApi } from 'Datasource/VariationApi';
import { ProductsApi } from 'Datasource/ProductsApi';
import { CDApis } from 'Datasource/CD';
import { CampaignApis } from 'Datasource/Campaign';
import { SampleMasterApi } from 'Datasource/SampleMasterApi';
import { AxiosResponse } from 'axios';

export interface ItemSearch {
  label: string;
  value: string | number;
}
interface IMeta {
  currentPage: number;
  itemCount: number;
  itemsPerPage: number;
  totalItems: number;
  totalPages: number;
}
export interface SuggestionResponse {
  items: any[];
  meta: IMeta;
}
export interface PageQuery {
  [key: string]: number;
}

const params = { page: 1, perPage: PAGE_SIZE };

const handleSearchCompany = async ({
  queryCompany = '',
  page = 1,
}: {
  queryCompany: string;
  page: number;
}): Promise<AxiosResponse> =>
  await CompanyApi.getListCompanySuggestion({
    ...params,
    page,
    queryCompany: queryCompany,
  });

const handleSearchArtist = async ({
  queryArtist = '',
  page = 1,
  companyId = '',
}: {
  queryArtist: string;
  page: number;
  companyId: string;
}): Promise<AxiosResponse> =>
  await ArtistApi.getAllSuggestionArtist({
    ...params,
    page,
    queryArtist: encodeURIComponent(queryArtist),
    companyId,
  });

const handleSearchTitle = async ({
  queryTitle = '',
  artistId = '',
  page = 1,
  companyId = '',
}: {
  queryTitle: string;
  artistId: string;
  page: number;
  companyId: string;
}): Promise<AxiosResponse> =>
  await TitleApi.getAllSuggestionTitles({
    ...params,
    page,
    query: encodeURIComponent(queryTitle),
    companyId,
    artistId,
  });

const handleSearchCampaigns = async ({
  queryCampaign = '',
  artistId = '',
  titleId = '',
  page = 1,
}: {
  queryCampaign: string;
  page: number;
  artistId: string;
  titleId: string;
}): Promise<any> =>
  await CampaignApis.getAllSuggestionCampaigns({
    ...params,
    page,
    query: encodeURIComponent(queryCampaign),
    artistId,
    titleId,
  });

const handleSearchVariation = async ({
  queryVariation = '',
  artistId = '',
  titleId = '',
  showDraft = false,
  page = 1,
  companyId = '',
}: {
  queryVariation: string;
  artistId: string;
  titleId: string;
  showDraft?: boolean;
  page: number;
  companyId: string;
}): Promise<AxiosResponse> =>
  await VariationApi.getAllSuggestionVariations({
    ...params,
    page,
    query: encodeURIComponent(queryVariation),
    companyId,
    artistId,
    titleId,
    showDraft,
  });

const handleSearchCD = async ({
  queryCD = '',
  artistId = '',
  titleId = '',
  page = 1,
  companyId = '',
  cdId = '',
}: {
  queryCD: string;
  artistId: string;
  titleId: string;
  cdId: string;
  page: number;
  companyId: string;
}): Promise<AxiosResponse> =>
  await CDApis.getCDs({
    ...params,
    page,
    query: encodeURIComponent(queryCD),
    artistId,
    titleId,
    companyId,
    cdId,
  });

const handleGetIds = (arrItem: ItemSearch[]): string => {
  const ids = arrItem.map((item: ItemSearch) => item.value);
  return ids.join(',');
};

const updateQuery = debounce((value, callback) => {
  callback(value);
}, 300);

const transformKey = (query: any): any => {
  const dataQuery = { ...query };
  Object.keys(dataQuery).map((key) => {
    dataQuery[key] = dataQuery[key] ?? '';
  });
  return dataQuery;
};

const handleSearchSaleChannels = async ({
  artistId = '',
  titleId = '',
  companyId = '',
  querySale = '',
  page = 1,
  showAll = 0,
}: {
  artistId?: string;
  titleId?: string;
  companyId: string;
  querySale: string;
  page: number;
  showAll?: number;
}): Promise<AxiosResponse> =>
  await ProductsApi.getSaleChannels({
    artistId,
    titleId,
    companyId,
    page,
    limit: PAGE_SIZE,
    query: encodeURIComponent(querySale),
    showAll: showAll,
  });
const handleSearchCourses = async ({
  queryCourse = '',
  campaignId = '',
  courseId = '',
  page = 1,
}: {
  queryCourse: string;
  campaignId: string;
  courseId: string;
  page: number;
}): Promise<AxiosResponse> => {
  return await CampaignApis.getAllSuggestionCourses({
    ...params,
    page,
    query: encodeURIComponent(queryCourse),
    campaignId,
    courseId,
  });
};

const handleSearchPrizes = async ({
  queryPrize = '',
  campaignId = '',
  courseId = '',
  page = 1,
}: {
  queryPrize: string;
  campaignId: string;
  courseId: string;
  page: number;
}): Promise<AxiosResponse> => {
  return await CampaignApis.getAllSuggestionPrizes({
    ...params,
    page,
    query: encodeURIComponent(queryPrize),
    campaignId,
    courseId,
  });
};

const handleSearchCompanySample = async ({
  queryCompany = '',
  page = 1,
}: {
  queryCompany: string;
  page: number;
}): Promise<AxiosResponse> =>
  await SampleMasterApi.getListCompanySuggestionSample({
    ...params,
    page,
    queryCompany: queryCompany,
  });

const handleSearchArtistSample = async ({
  queryArtist = '',
  page = 1,
  companyId = '',
}: {
  queryArtist: string;
  page: number;
  companyId: string;
}): Promise<AxiosResponse> =>
  await SampleMasterApi.getAllSuggestionArtistSample({
    ...params,
    page,
    queryArtist: encodeURIComponent(queryArtist),
    companyId,
  });

const handleSearchTitleSample = async ({
  queryTitle = '',
  artistId = '',
  page = 1,
  companyId = '',
}: {
  queryTitle: string;
  artistId: string;
  page: number;
  companyId: string;
}): Promise<AxiosResponse> =>
  await SampleMasterApi.getAllSuggestionTitlesSample({
    ...params,
    page,
    query: encodeURIComponent(queryTitle),
    companyId,
    artistId,
  });

const handleSearchVariationSample = async ({
  queryVariation = '',
  artistId = '',
  titleId = '',
  page = 1,
  companyId = '',
}: {
  queryVariation: string;
  artistId: string;
  titleId: string;
  page: number;
  companyId: string;
}): Promise<AxiosResponse> =>
  await SampleMasterApi.getAllSuggestionVariationsSample({
    ...params,
    page,
    query: encodeURIComponent(queryVariation),
    companyId,
    artistId,
    titleId,
  });

export {
  handleGetIds,
  handleSearchCompany,
  handleSearchArtist,
  handleSearchTitle,
  handleSearchVariation,
  handleSearchCampaigns,
  updateQuery,
  transformKey,
  handleSearchSaleChannels,
  handleSearchCD,
  handleSearchCourses,
  handleSearchPrizes,
  handleSearchArtistSample,
  handleSearchTitleSample,
  handleSearchVariationSample,
  handleSearchCompanySample,
};
