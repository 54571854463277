import { getNameBrower } from 'Utils/Brower';

export const handleConnectStaticEndPoint = (url: string | undefined, time?: number): string => {
  if (getNameBrower() === 'safari') {
    url = `${url}?v=${time ?? new Date().getTime()}`;
  }

  const staticUrl = process.env.REACT_APP_STATIC_URL;
  return `${staticUrl}${url ?? ''}`;
};
