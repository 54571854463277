import React, { useMemo } from 'react';
import { Row } from 'react-bootstrap';
import { formatCurrency } from 'Utils/Numbers';
import { ICost } from 'Types/Page/CompanyInformation/CompanyInformation';
import { COL_END_CLASS, COL_START_CLASS } from 'Constant';
import {
  renderArtistName,
  renderBuyFeeMoney,
  renderCardCd,
  renderDownloadDate,
  renderFeeName,
  renderUnitTaxFee,
  renderOptionTaxFee,
  renderPrice,
  renderProductId,
  // renderProductName,
  renderPurchaseDate,
  renderSnFee,
  renderSnFeeMoney,
  renderTaxFeeMoney,
  renderTitleName,
  renderTotalAmount,
  renderTotalMoney,
  renderVariationName,
} from 'Components/Page/InformationAccountant/renderReportColumn';
import AliTable, { AliColumn, AliSortType } from 'Components/Common/Table/AliTable';
// import { unionBy } from 'lodash';
interface Props {
  dataSource: ICost[];
  option: number;
  filters?: any;
  activeColumns?: any;
  setActiveColumns?: any;
  onSort?: (sortingState: AliSortType[]) => void;
}
export default function TableCostManagement({
  dataSource,
  option,
  activeColumns,
  // setActiveColumns,
  onSort,
  filters,
}: Props): JSX.Element {
  const isAllChecked = useMemo(() => {
    if (option !== 2) {
      return false;
    }
    const activeColumnsId = activeColumns?.map((col: any) => col?.purchaseId);
    const dataColumnsId = dataSource?.map((col: any) => col?.purchaseId);

    return dataColumnsId.every((colId: any) => activeColumnsId?.includes(colId));
  }, [dataSource, activeColumns, option]);

  // const addOrRemoveAllColumns = (e: any) => {
  //   const dataColumnsId = dataSource.map((col: any) => col?.purchaseId);

  //   if (e.target.checked) {
  //     const newActiveColumns = unionBy(dataSource, activeColumns, 'purchaseId');
  //     setActiveColumns(newActiveColumns);
  //   } else {
  //     const newActiveColumns = activeColumns.filter(
  //       (col: any) => !dataColumnsId.includes(col?.purchaseId),
  //     );
  //     setActiveColumns(newActiveColumns);
  //   }
  // };

  // const addOrRemoveColumns = (val: any) => {
  //   const foundSourceData = dataSource.find((col: any) => col?.purchaseId === val);
  //   const foundData = activeColumns.find((col: any) => col?.purchaseId === val);
  //   if (foundData) {
  //     const newActiveColumns = activeColumns.filter((col: any) => col?.purchaseId !== val);
  //     setActiveColumns(newActiveColumns);
  //   } else {
  //     const newActiveColumns = [...activeColumns, foundSourceData];
  //     setActiveColumns(newActiveColumns);
  //   }
  // };

  //* column month
  const columnMonth = useMemo(() => {
    const initColumnMonth = [
      renderProductId,
      // renderProductName,
      renderArtistName,
      renderTitleName,
      renderVariationName,
      renderCardCd,
      renderDownloadDate,
      renderPrice,
      renderFeeName,
      renderTotalAmount,
      {
        name: '定額料金',
        align: 'center',
        width: 160,
        children: [
          {
            name: '単価',
            width: 100,
            code: 'optionCardBuyFee',
            align: 'center',
            render: (value: any) => {
              return <div className={`${COL_END_CLASS}`}>{formatCurrency(value)}</div>;
            },
          },
          renderBuyFeeMoney,
        ],
      },
      {
        name: '定率料金',
        align: 'center',
        width: 160,
        children: [renderUnitTaxFee, renderOptionTaxFee, renderTaxFeeMoney],
      },
      {
        name: 'オプション料金',
        align: 'center',
        width: 160,
        children: [renderSnFee, renderSnFeeMoney],
      },
      renderTotalMoney,
    ];

    if (filters) {
      if (filters.isViewProductId) {
        renderProductId.hidden = false;
      }
      if (!filters.isViewProductId) {
        renderProductId.hidden = true;
      }

      if (filters.isViewArtistName) {
        renderArtistName.hidden = false;
      }

      if (!filters.isViewArtistName) {
        renderArtistName.hidden = true;
      }

      if (filters.isViewTitleName) {
        renderTitleName.hidden = false;
      }

      if (!filters.isViewTitleName) {
        renderTitleName.hidden = true;
      }

      if (filters.isViewVariationName) {
        renderVariationName.hidden = false;
      }

      if (!filters.isViewVariationName) {
        renderVariationName.hidden = true;
      }

      if (filters.isViewVariationProductCode) {
        renderCardCd.hidden = false;
      }

      if (!filters.isViewVariationProductCode) {
        renderCardCd.hidden = true;
      }

      if (filters.isViewDownloadDate) {
        renderDownloadDate.hidden = false;
      }

      if (!filters.isViewDownloadDate) {
        renderDownloadDate.hidden = true;
      }
    }
    return [...initColumnMonth] as AliColumn[];
  }, [filters]);

  //* column daily
  const columnDaily = useMemo(() => {
    const initColumnDaily = [
      renderProductId,
      renderPurchaseDate,
      // renderProductName,
      renderArtistName,
      renderTitleName,
      renderVariationName,
      renderCardCd,
      renderDownloadDate,
      renderPrice,
      renderFeeName,
      renderTotalAmount,
      {
        name: '定額料金',
        align: 'center',
        width: 160,
        children: [
          {
            name: '単価',
            width: 100,
            code: 'optionCardBuyFee',
            align: 'center',
            render: (value: any) => {
              return <div className={`${COL_END_CLASS}`}>{formatCurrency(value)}</div>;
            },
          },
          renderBuyFeeMoney,
        ],
      },
      {
        name: '定率料金',
        align: 'center',
        width: 160,
        children: [renderUnitTaxFee, renderOptionTaxFee, renderTaxFeeMoney],
      },
      {
        name: 'オプション料金',
        align: 'center',
        width: 160,
        children: [renderSnFee, renderSnFeeMoney],
      },
      renderTotalMoney,
    ];

    if (filters) {
      if (filters.isViewProductId) {
        renderProductId.hidden = false;
      }
      if (!filters.isViewProductId) {
        renderProductId.hidden = true;
      }

      if (filters.isViewArtistName) {
        renderArtistName.hidden = false;
      }

      if (!filters.isViewArtistName) {
        renderArtistName.hidden = true;
      }

      if (filters.isViewTitleName) {
        renderTitleName.hidden = false;
      }

      if (!filters.isViewTitleName) {
        renderTitleName.hidden = true;
      }

      if (filters.isViewVariationName) {
        renderVariationName.hidden = false;
      }

      if (!filters.isViewVariationName) {
        renderVariationName.hidden = true;
      }

      if (filters.isViewVariationProductCode) {
        renderCardCd.hidden = false;
      }

      if (!filters.isViewVariationProductCode) {
        renderCardCd.hidden = true;
      }

      if (filters.isViewDownloadDate) {
        renderDownloadDate.hidden = false;
      }

      if (!filters.isViewDownloadDate) {
        renderDownloadDate.hidden = true;
      }
    }

    return [...initColumnDaily] as AliColumn[];
  }, [filters]);

  //* column detail
  const columnDetail = useMemo(() => {
    const initColumnDetail = [
      // {
      //   name: (
      //     <Form.Check
      //       type="checkbox"
      //       id="isCheckAll"
      //       name="isCheckAll"
      //       // checked={false}
      //       className="ec_custom-checkbox"
      //       checked={isAllChecked}
      //       // onChange={() => setValue('isPublic', !watch('isPublic'))}
      //       onChange={addOrRemoveAllColumns}
      //     />
      //   ),
      //   lock: true,
      //   className: 'd-flex align-items-center justify-content-center',
      //   width: 50,
      //   code: 'purchaseId',
      //   render: (value: any) => {
      //     const foundCol = activeColumns?.find((col: any) => col?.purchaseId == value);
      //     return (
      //       <div className="d-flex align-items-center justify-content-center h-100 p-2">
      //         <Form.Check
      //           type="checkbox"
      //           id={value}
      //           name={value}
      //           // checked={false}
      //           className="ec_custom-checkbox"
      //           checked={!!foundCol}
      //           // onChange={() => setValue('isPublic', !watch('isPublic'))}
      //           // onChange={(e) => setIsShowOnlyMaster(e.target.checked)}
      //           onChange={() => addOrRemoveColumns(value)}
      //         />
      //       </div>
      //     );
      //   },
      // },
      renderProductId,
      renderPurchaseDate,
      {
        name: '購入者',
        width: 160,
        code: 'userName',
        features: { sortable: true },
        lock: true,
        align: 'center',
        render: (value: any) => {
          return <div className={`${COL_START_CLASS}`}>{value}</div>;
        },
      },

      // renderProductName,
      renderArtistName,
      renderTitleName,
      renderVariationName,
      renderCardCd,
      renderDownloadDate,
      renderPrice,
      renderFeeName,
      renderTotalAmount,
      {
        name: '定額料金',
        align: 'center',
        width: 160,
        children: [
          {
            name: '単価',
            width: 100,
            code: 'optionCardBuyFee',
            align: 'center',
            render: (value: any) => {
              return <div className={`${COL_END_CLASS}`}>{formatCurrency(value)}</div>;
            },
          },
          renderBuyFeeMoney,
        ],
      },
      {
        name: '定率料金',
        align: 'center',
        width: 160,
        children: [renderUnitTaxFee, renderOptionTaxFee, renderTaxFeeMoney],
      },
      {
        name: 'オプション料金',
        align: 'center',
        width: 160,
        children: [renderSnFee, renderSnFeeMoney],
      },
      renderTotalMoney,
    ];

    if (filters) {
      if (filters.isViewProductId) {
        renderProductId.hidden = false;
      }
      if (!filters.isViewProductId) {
        renderProductId.hidden = true;
      }

      if (filters.isViewArtistName) {
        renderArtistName.hidden = false;
      }

      if (!filters.isViewArtistName) {
        renderArtistName.hidden = true;
      }

      if (filters.isViewTitleName) {
        renderTitleName.hidden = false;
      }

      if (!filters.isViewTitleName) {
        renderTitleName.hidden = true;
      }

      if (filters.isViewVariationName) {
        renderVariationName.hidden = false;
      }

      if (!filters.isViewVariationName) {
        renderVariationName.hidden = true;
      }

      if (filters.isViewVariationProductCode) {
        renderCardCd.hidden = false;
      }

      if (!filters.isViewVariationProductCode) {
        renderCardCd.hidden = true;
      }

      if (filters.isViewDownloadDate) {
        renderDownloadDate.hidden = false;
      }

      if (!filters.isViewDownloadDate) {
        renderDownloadDate.hidden = true;
      }
    }
    return [...initColumnDetail] as AliColumn[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, activeColumns, isAllChecked]);

  const renderColumn =
    option === 0 ? columnMonth : option === 1 ? columnDaily : option === 2 ? columnDetail : [];

  return (
    <Row className="mt-3">
      <AliTable
        wrapperClass="ali_table-revenue_management"
        dataSource={dataSource}
        columns={renderColumn}
        heightBody="calc(100vh - 450px)"
        onSort={onSort}
      />
    </Row>
  );
}
