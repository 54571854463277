/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import PaginationComponent from 'Components/Common/Pagination/Pagination';
import { PAGE_SIZE } from 'Constant';
import TableSaleManagement from 'Components/Page/InformationAccountant/TableSaleManagement';
import TableCostMonth from 'Components/Page/InformationAccountant/TableCostMonth';
import TableSaleMonth from 'Components/Page/InformationAccountant/TableSaleMonth';
import { PurchaseResponse } from 'Types/Page/CompanyInformation/CompanyInformation';
import { AliSortType } from 'Components/Common/Table/AliTable';
import ECSwitch from 'Components/Page/EC/Form/ECSwitch';
import TableDailyAccount from 'Components/Page/InformationAccountant/TableDailyAccount';

import { useAppDispatch } from 'App/Store';
import {
  setSaleMonthlyFilters,
  setSaleDetailFilters,
  setSaleDailyFilters,
} from 'App/Features/Revenue';

import { useAppSelector } from 'App/Store';

interface ManagementTabProps {
  page?: number | null;
  purchaseData?: PurchaseResponse;
  option: number;
  activeColumns?: any;
  setActiveColumns?: any;
  onSort?: (sortingState: AliSortType[]) => void;
}
export default function SaleManagement({
  page,
  purchaseData,
  option,
  onSort,
}: ManagementTabProps): JSX.Element {
  const dispatch = useAppDispatch();

  const revenueFilters: any = useAppSelector((state) => {
    switch (option) {
      case 0:
        return state?.revenue?.filters?.sale?.monthly || {};
      case 1:
        return state?.revenue?.filters?.sale?.daily || {};
      case 2:
        return state?.revenue?.filters?.sale?.detail || {};
      default:
        return {};
    }
  });

  const handleChangeFilterOption = (isSwitch: boolean, field: string) => {
    const newRevenueFilters = { ...revenueFilters, [field]: isSwitch };

    if (option == 0) {
      dispatch(setSaleMonthlyFilters({ filters: newRevenueFilters }));
    }
    if (option == 1) {
      dispatch(setSaleDailyFilters({ filters: newRevenueFilters }));
    }

    if (option == 2) {
      dispatch(setSaleDetailFilters({ filters: newRevenueFilters }));
    }
  };

  if (option == 3) {
    return (
      <Container fluid className="px-4">
        <Row className="d-flex w-100">
          <div className="d-flex w-100 sale_table-dailyAccount">
            {purchaseData?.items &&
            Array.isArray(purchaseData?.items) &&
            purchaseData?.items.length > 0 ? (
              <TableDailyAccount
                dataTotalByMonth={purchaseData?.totalByMonth ? [purchaseData.totalByMonth] : []}
                dataSource={purchaseData?.items || []}
                saleChannels={purchaseData?.saleChannels || []}
                onSort={onSort}
              />
            ) : (
              <div className="vh-100-final d-flex align-items-center justify-content-center w-100">
                該当のデータはありません
              </div>
            )}
          </div>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid className="px-4">
      <Row className="my-4 d-flex w-100">
        {purchaseData?.items?.length ? (
          <>
            <div className="d-flex w-70">
              <TableCostMonth
                dataSource={purchaseData?.dataAmount ? [purchaseData.dataAmount] : []}
                title="販売金額合計"
              />
              <TableSaleMonth
                dataSource={purchaseData?.dataAmount ? [purchaseData.dataAmount] : []}
                saleChannels={purchaseData?.saleChannels || []}
              />
            </div>
            <div className="w-auto d-flex">
              <div>
                <ECSwitch
                  id="productId"
                  checked={revenueFilters?.isViewProductId}
                  labelForm="商品No."
                  onChange={(isSwitch) => handleChangeFilterOption(isSwitch, 'isViewProductId')}
                />
                <ECSwitch
                  id="titleLabel"
                  checked={revenueFilters?.isViewTitleLabel}
                  labelForm="レーベル名"
                  onChange={(isSwitch) => handleChangeFilterOption(isSwitch, 'isViewTitleLabel')}
                />
                <ECSwitch
                  id="artistName"
                  checked={revenueFilters?.isViewArtistName}
                  labelForm="アーティスト"
                  onChange={(isSwitch) => handleChangeFilterOption(isSwitch, 'isViewArtistName')}
                />
                <ECSwitch
                  id="titleName"
                  checked={revenueFilters?.isViewTitleName}
                  labelForm="タイトル"
                  onChange={(isSwitch) => handleChangeFilterOption(isSwitch, 'isViewTitleName')}
                />
                <ECSwitch
                  id="variationName"
                  checked={revenueFilters?.isViewVariationName}
                  labelForm="バリエーション"
                  onChange={(isSwitch) => handleChangeFilterOption(isSwitch, 'isViewVariationName')}
                />
              </div>
              <div>
                <ECSwitch
                  id="variationProductCode"
                  checked={revenueFilters?.isViewVariationProductCode}
                  labelForm="品番"
                  onChange={(isSwitch) =>
                    handleChangeFilterOption(isSwitch, 'isViewVariationProductCode')
                  }
                />
                <ECSwitch
                  id="variationJanCode"
                  checked={revenueFilters?.isViewVariationJanCode}
                  labelForm="JANコード"
                  onChange={(isSwitch) =>
                    handleChangeFilterOption(isSwitch, 'isViewVariationJanCode')
                  }
                />
                <ECSwitch
                  id="downloadDate"
                  checked={revenueFilters?.isViewDownloadDate}
                  labelForm="DL開始日"
                  onChange={(isSwitch) => handleChangeFilterOption(isSwitch, 'isViewDownloadDate')}
                />
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </Row>

      <div className="px-2 mx-1">
        <PaginationComponent
          activePage={page || 1}
          total={purchaseData?.meta.totalCount || 0}
          perPage={PAGE_SIZE}
        />
      </div>
      <TableSaleManagement
        dataSource={purchaseData?.items || []}
        saleChannels={purchaseData?.saleChannels || []}
        option={option}
        onSort={onSort}
        filters={revenueFilters}
      />
    </Container>
  );
}
