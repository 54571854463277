import {
  campaignOptionsType,
  CAMPAIGN_MEDIA_TYPE,
  distributionOptions,
  sitOptions,
} from 'Constant/Campaign';
import { DATE_FORMAT_13, DATE_FORMAT_14, TIME_FORMAT } from 'Constant/Date';
import { find, reduce } from 'lodash';
import React, { ReactNode } from 'react';
import { Form } from 'react-bootstrap';
import ViewOnlyField from 'Components/Page/Campaign/Detail/ViewOnlyField';
import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';
import { formatDate } from 'Utils/DateTime';

const CLASS_NAME = 'text-center align-middle';
const formSize = {
  labelSize: 3,
  valueSize: 9,
};

interface CheckboxType {
  className: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: any) => void;
}

const CheckBox = ({ checked = false, className, disabled, onChange }: CheckboxType) => {
  return (
    <div className="text-center">
      <Form.Check
        type="checkbox"
        defaultChecked={checked}
        className={className}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  );
};

export const columnId = {
  className: CLASS_NAME,
  text: 'No',
  dataIndex: 'id',
  width: 65,
  render: ({ item }: { item: any }): ReactNode => {
    return <p className="text-center">{item.index + 1}</p>;
  },
};

export const columnName = {
  className: CLASS_NAME,
  text: '賞の名称',
  dataIndex: 'name',
  width: 300,
  render: ({ item }: { item: any }): ReactNode => {
    return <p className="text-center text-break">{item.name}</p>;
  },
};

export const columnType = {
  className: CLASS_NAME,
  text: '種別',
  width: 120,
  dataIndex: 'type',
  render: ({ item }: { item: any }): ReactNode => {
    return (
      <p className="text-center">
        {find(campaignOptionsType, (option) => option.value === item?.type)?.label ?? ''}
      </p>
    );
  },
};

export const columnUserQuantity = {
  className: CLASS_NAME,
  text: '当選数上限',
  width: 150,
  dataIndex: 'userQuantity',
  render: ({ item, dataIndex }: any): ReactNode => {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <div className="text-truncate text-nowrap name-course">
          {item[dataIndex] ? `${item[dataIndex]} 名` : '上限なし'}
        </div>
      </div>
    );
  },
};

export const columnWinRate = {
  className: CLASS_NAME,
  text: '当選確率',
  width: 165,
  dataIndex: 'winRate',
  render: ({ item, dataIndex }: any): ReactNode => {
    return (
      <div className="d-flex align-items-center justify-content-center flex-column">
        <div className="text-truncate text-nowrap name-course">
          {item[dataIndex] ? `${item[dataIndex]} %` : '-'}
        </div>
      </div>
    );
  },
};

export const columnIsIncentive = {
  className: CLASS_NAME,
  text: '参加賞',
  width: 80,
  dataIndex: 'isIncentive',
  render: ({ item, dataIndex }: any): ReactNode => {
    return (
      <div className="d-flex align-items-center justify-content-center flex-column">
        {item[dataIndex] ? (
          <div className="view-only">
            <CheckBox checked className={'checkbox-no-label check-box-size check-box-ct pe-none'} />
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  },
};

export const columnInfo = {
  className: CLASS_NAME,
  text: '景品情報',
  dataIndex: 'info',
  render: ({ item }: { item: any }): ReactNode => {
    const totalCoefficient: number = reduce(
      item?.prizeDigitalTokutens,
      (sum, field) => {
        return sum + Number(field.percentage ?? 0);
      },
      0,
    );

    const handleCalculatePercent = (coefficient: number) => {
      if (!coefficient || !+coefficient) return 0;
      const percent = (Number(coefficient) / totalCoefficient) * 100;
      return Math.round(percent);
    };
    return (
      <>
        {item?.type === 0 && (
          <div style={{ maxWidth: 500 }}>
            <ViewOnlyField {...formSize} label="表示名称" value={item?.eventName} />
            <ViewOnlyField {...formSize} label="会場名" value={item?.placeName} />
            <ViewOnlyField
              {...formSize}
              label="日付"
              value={`${formatDate(item?.startDate, DATE_FORMAT_14)} ~ ${formatDate(
                item?.endDate,
                DATE_FORMAT_14,
              )}`}
            />
            <ViewOnlyField
              {...formSize}
              label="開場"
              value={formatDate(item?.openTime, TIME_FORMAT)}
            />
            <ViewOnlyField
              {...formSize}
              label="開演"
              value={formatDate(item?.startTime, TIME_FORMAT)}
            />
            <ViewOnlyField
              {...formSize}
              label="席順設定"
              value={
                find(sitOptions, (option) => option.value === item?.seatPickerType)?.labelConfirm ??
                ''
              }
            />
            <ViewOnlyField
              {...formSize}
              label="発行方法"
              value={
                find(sitOptions, (option) => option.value === item?.seatPickerType)?.label ?? ''
              }
            />
            <ViewOnlyField
              {...formSize}
              label="発行初番"
              value={`${item?.seatRow ?? '-'} ${item?.seatNumber ?? '-'} 番`}
            />
            <ViewOnlyField
              {...formSize}
              label="当日通知時刻"
              value={`${formatDate(item?.notificationDate, TIME_FORMAT) ?? '--:--'} に通知`}
            />
            <ViewOnlyField
              {...formSize}
              label="ユーザ表示用備考注意事項"
              value={item?.description}
              prevLine
            />
          </div>
        )}
        {item?.type === 1 && (
          <div style={{ maxWidth: 500 }}>
            <ViewOnlyField {...formSize} label="表示名称" value={item?.eventName} />
            <ViewOnlyField {...formSize} label="付与数" value={item?.productQuantity} />
            <ViewOnlyField {...formSize} label="会場名" value={item?.placeName} />
            <ViewOnlyField
              {...formSize}
              label="日付"
              value={`${formatDate(item?.startDate, DATE_FORMAT_14)} ~ ${formatDate(
                item?.endDate,
                DATE_FORMAT_14,
              )}`}
            />
            <ViewOnlyField
              {...formSize}
              label="開始時刻"
              labelAfter="(WEBアプリ表示用)"
              value={item?.startTime ? `${formatDate(item?.startTime, TIME_FORMAT)}` : '-'}
            />
            <ViewOnlyField
              {...formSize}
              label="当日通知時刻"
              value={`${formatDate(item?.notificationDate, TIME_FORMAT) ?? '--:--'} に通知`}
            />
            <ViewOnlyField
              {...formSize}
              label="ユーザ表示用備考注意事項"
              value={item?.description}
              prevLine
            />
          </div>
        )}
        {item?.type === 2 && (
          <div style={{ maxWidth: 500 }}>
            <ViewOnlyField {...formSize} label="表示名称" value={item?.eventName} />
            <ViewOnlyField {...formSize} label="付与数" value={item?.productQuantity} />
            <ViewOnlyField
              {...formSize}
              label="シリアルナンバーマスタ"
              value={item?.seriesNumberMasterName ?? '-'}
            />
            <ViewOnlyField {...formSize} label="URL" value={item?.url} />
            <ViewOnlyField
              {...formSize}
              label="有効期限"
              value={`${formatDate(item?.startDate, DATE_FORMAT_14)} ~ ${formatDate(
                item?.endDate,
                DATE_FORMAT_14,
              )}`}
            />
            <ViewOnlyField
              {...formSize}
              label="ユーザ表示用備考注意事項"
              value={item?.description}
              prevLine
            />
          </div>
        )}
        {item?.type === 3 && (
          <div style={{ maxWidth: 500 }}>
            <ViewOnlyField {...formSize} label="景品情報" value="景品情報" />
            <ViewOnlyField
              {...formSize}
              label="特典配布日時"
              value={`${formatDate(item?.digitalTokutenDistributionDate, DATE_FORMAT_13)}`}
            />
            <ViewOnlyField
              {...formSize}
              label="配布設定"
              value={
                find(
                  distributionOptions,
                  (option) => option.value === item?.digitalTokutenPrizeType,
                )?.label ?? ''
              }
            />
            {item?.prizeDigitalTokutens?.map((dpContent: any, dpIndex: number) => {
              return (
                <>
                  <ViewOnlyField {...formSize} label="タイトル名" value={dpContent?.name} />
                  <ViewOnlyField
                    key={dpIndex}
                    {...formSize}
                    label="当選係数"
                    value={
                      dpContent?.percentage
                        ? `${dpContent?.percentage} (${handleCalculatePercent(
                            dpContent?.percentage,
                          )}%)`
                        : '-'
                    }
                  />
                  {dpContent.type === CAMPAIGN_MEDIA_TYPE.Video && (
                    <video className="w-100" style={{ maxWidth: 250 }} controls>
                      <track kind="captions" />
                      <source src={handleConnectStaticEndPoint(dpContent.url)} type="video/mp4" />
                      Your browser does not support HTML video.
                    </video>
                  )}
                  {dpContent.type === CAMPAIGN_MEDIA_TYPE.Image && (
                    <img
                      src={handleConnectStaticEndPoint(dpContent.url)}
                      className="img-fluid rounded-start"
                      style={{ maxWidth: 250 }}
                      alt="..."
                    />
                  )}
                  {dpContent.type === CAMPAIGN_MEDIA_TYPE.Audio && (
                    <audio controls className="mt-4 h-35px">
                      <track kind="captions" />
                      <source src={handleConnectStaticEndPoint(dpContent.url)} type="audio/mpeg" />
                    </audio>
                  )}
                </>
              );
            })}
          </div>
        )}
        {item?.type === 4 && (
          <div style={{ maxWidth: 500 }}>
            <ViewOnlyField {...formSize} label="表示名称" value={item?.eventName} />
            <img
              src={handleConnectStaticEndPoint(item?.imageUrl)}
              className="img-fluid rounded-start"
              style={{ maxWidth: 250 }}
              alt="..."
            />
          </div>
        )}
      </>
    );
  },
};
