import Search from 'Components/Common/Search/Search';
import useSuggestion from 'Hooks/useSuggestion';
import React from 'react';
import { Col } from 'react-bootstrap';

export default function CampaignSearch(): JSX.Element {
  const {
    companyOptions,
    artistOptions,
    titleOptions,
    loadingArtist,
    loadingTitle,
    loadingCompany,
    handleLoadMore,
    handleFocusInput,
    handleEnterPrimary,
    handleClickItemPrimary,
  } = useSuggestion({
    fetchList: ['company', 'artist', 'title'],
  });

  return (
    <>
      <Col md="4" className="px-0">
        <Search
          label="レコード会社"
          labelMd="4"
          colMd="8"
          placeholder="検索"
          showIcon
          classLabel="px-0 artist-label"
          rowClass="w-100"
          colClass="px-0"
          searchKey="queryCompany"
          onEnter={() => handleEnterPrimary('queryCompany')}
          onClickResult={handleClickItemPrimary}
          options={companyOptions.options || []}
          loading={loadingCompany}
          onLoadMore={() => handleLoadMore('pageCompany')}
          onFocusInput={() => {
            handleFocusInput('queryCompany', 'pageCompany');
          }}
        />
      </Col>
      <Col md="4" className="px-1 ps-3">
        <Search
          label="アーティスト"
          labelMd="4"
          colMd="8"
          placeholder="検索"
          showIcon
          classLabel="px-0 artist-label"
          rowClass="w-100"
          colClass="px-0"
          searchKey="queryArtist"
          onEnter={() => handleEnterPrimary('queryArtist')}
          onClickResult={handleClickItemPrimary}
          options={artistOptions.options || []}
          loading={loadingArtist}
          onLoadMore={() => handleLoadMore('pageArtist')}
          onFocusInput={() => {
            handleFocusInput('queryArtist', 'pageArtist');
          }}
        />
      </Col>
      <Col md="4" className="px-1 ps-3">
        <Search
          label="タイトル"
          labelMd="4"
          colMd="8"
          placeholder="検索"
          showIcon
          classLabel="px-0 title-label"
          rowClass="w-100"
          colClass="px-0"
          searchKey="queryTitle"
          onEnter={() => handleEnterPrimary('queryTitle')}
          onClickResult={handleClickItemPrimary}
          options={titleOptions.options || []}
          loading={loadingTitle}
          onLoadMore={() => handleLoadMore('pageTitle')}
          onFocusInput={() => {
            handleFocusInput('queryTitle', 'pageTitle');
          }}
        />
      </Col>
    </>
  );
}
