import React, { PropsWithChildren } from 'react';
import DatePicker from 'react-datepicker';
import { DateSelectorProps } from 'Components/Common/DateSelector/date';
import { Col, Form } from 'react-bootstrap';
import { DATE_FORMAT_FOR_DATE_PICKER, TIME_FORMAT } from 'Constant/Date';
import { getNameBrower } from 'Utils/Brower';

function DateSelectorDashboard({
  selected,
  onChange,
  placeholder,
  dateFormat,
  showTime = true,
  register,
  startDate,
  endDate,
  minDate,
  maxDate,
  selectsStart,
  selectsEnd,
  disabled = false,
  isReview = false,
  showTimeSelectOnly,
  classNameDateSelect = '',
  label,
  classLabel,
  labelMd,
  labelLg,
  colMd,
  colLg,
  wrapperClass,
}: PropsWithChildren<DateSelectorProps>): JSX.Element {
  const filterPassedTime = (time: Date) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };
  const handleOnChange = (date: any, event: any) => {
    if (event?.currentTarget?.value?.length) {
      if (
        event?.currentTarget?.value?.length == 4 ||
        event?.currentTarget?.value?.length == 0 ||
        (event?.currentTarget?.value?.length > 5 && event?.currentTarget?.value?.length <= 10)
      ) {
        onChange && onChange(date);
      }
      return;
    }
    onChange && onChange(date);
  };

  return (
    <div className={`${wrapperClass ? wrapperClass : ''}`}>
      {label && (
        <Form.Label className={classLabel} column md={labelMd} lg={labelLg}>
          {label}
        </Form.Label>
      )}
      <Col
        md={colMd}
        lg={colLg}
        className={`position-relative custom-date-picker ${classNameDateSelect}`}
      >
        <DatePicker
          className="form-control custom-icon-date"
          {...register}
          disabledKeyboardNavigation
          showTimeSelect={showTime}
          selected={selected}
          onChange={(date, e) => {
            if (!isReview) {
              if (getNameBrower() === 'safari') {
                const value = e?.currentTarget?.value?.replaceAll('/', '-');
                const regexDay = /^\d{4}-+\d{2}-0$/;
                const regexMonth = /^\d{4}-0$/;

                if (regexDay.test(value) || regexMonth.test(value)) return;
              }

              handleOnChange(date, e);
            }
          }}
          dateFormat={dateFormat ?? DATE_FORMAT_FOR_DATE_PICKER}
          timeFormat={TIME_FORMAT}
          placeholderText={placeholder}
          startDate={startDate && startDate}
          endDate={endDate && endDate}
          minDate={minDate}
          maxDate={maxDate}
          selectsStart={selectsStart ? true : undefined}
          selectsEnd={selectsEnd ? true : undefined}
          showTimeSelectOnly={showTimeSelectOnly}
          disabled={disabled}
          filterTime={filterPassedTime}
          locale="ja"
        />
      </Col>
    </div>
  );
}
export default DateSelectorDashboard;
