import React, { useCallback, useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import Filter from 'Components/Common/Filter/Filter';
import PaginationComponent from 'Components/Common/Pagination/Pagination';
import SerialNumberTable from 'Components/Page/ManagerSeries/SerialNumberTable';
import SearchSerialNumber from 'Page/ManageSerialNumber/SearchSerialNumber';
import { SerialApis } from 'Datasource/Serial';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import useMessage from 'Hooks/useMessage';
import { SortMethod } from 'Components/Common/Table/table';

const perPage = 50;

export default function SerialNumberManagement(): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    queryArtist: StringParam,
    artistIds: StringParam,
    companyIds: StringParam,
    queryCompany: StringParam,
    sort: StringParam,
    order: StringParam,
  });

  const { openMessageError } = useMessage();

  const getSerialNumber = async () => {
    try {
      setIsLoading(true);
      const {
        data: { data },
      } = await SerialApis.getSerials({
        limit: perPage,
        page: query?.page,
        artistId: query.artistIds,
        companyId: query.companyIds,
        type: '',
        sort: query.sort || '',
        order: query.order || '',
      });
      setData(data);
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSerialNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  //* handle sort from server side
  const handleManualSort = useCallback(
    (sortBy: SortMethod[]) => {
      if (sortBy.length > 0) {
        const sortOption = sortBy[0];
        let sortKey = sortOption.id;
        if (sortOption.id === 'cd') {
          sortKey = 'cdName';
        }

        if (sortOption.id === 'codeCd') {
          sortKey = 'productCode';
        }
        setQuery({ sort: sortKey, order: sortOption.desc ? 'DESC' : 'ASC' });
      } else {
        setQuery({ sort: '', order: '' });
      }
    },
    [setQuery],
  );

  return (
    <>
      <Container fluid>
        <Filter filterClass="shadow-sm">
          <Row>
            <SearchSerialNumber />
          </Row>
        </Filter>
        <div>
          <SpinnerComponent isLoading={isLoading} />
          <Row className="px-4">
            <PaginationComponent
              activePage={query?.page ?? 1}
              total={data?.meta?.totalItems ?? 0}
              perPage={perPage}
            />
          </Row>
          <SerialNumberTable dataSource={data?.items ?? []} onSort={handleManualSort} />
        </div>
      </Container>
    </>
  );
}
