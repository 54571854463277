import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { CHART_BLUE } from 'Constant/Colors';
import { DATE_FORMAT_10, DATE_FORMAT_1 } from 'Constant/Date';
import dayjs from 'dayjs';
import React, { useState, useMemo, useEffect, useCallback } from 'react';

import { Bar } from 'react-chartjs-2';
import { IStandard } from 'Types/Page/Campaign/Campaign';
import { useQueryParams, StringParam } from 'use-query-params';
import { getDatesInRange, handleEndDate, handleStartDate } from 'Utils/DateTime';

interface Props {
  title?: string;
  dataIndex: string;
  dataSource: IStandard[];
}

export default function StandardChart({ title, dataIndex, dataSource }: Props): JSX.Element {
  const [query] = useQueryParams({
    startDate: StringParam,
    endDate: StringParam,
  });

  const [charts, setCharts] = useState<IStandard[]>([]);

  useEffect(() => {
    handleDataChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  const handleDataChart = useCallback(() => {
    const chartData = getDatesInRange(
      handleStartDate(query.startDate) || new Date(),
      handleEndDate(query.endDate) || new Date(),
    ).map((d) => ({
      date: d,
      applyNumber: 0,
      winningNumer: 0,
      usedNumber: 0,
    }));
    chartData.forEach((d: IStandard) => {
      const time1 = dayjs(d.date).format(DATE_FORMAT_1);

      const resItem = dataSource.find((item) => dayjs(item.date).format(DATE_FORMAT_1) === time1);
      if (resItem) {
        d.applyNumber = resItem.applyNumber;
        d.winningNumer = resItem.winningNumer;
        d.usedNumber = resItem.usedNumber;
      }
    });
    chartData.pop();
    setCharts(chartData);
  }, [dataSource, query.endDate, query.startDate]);

  const labels = useMemo(() => {
    return charts.map((item) => dayjs(item.date).format(DATE_FORMAT_10));
  }, [charts]);

  const dataChart = useMemo(() => {
    return charts.map((item: any) => item[dataIndex]);
  }, [charts, dataIndex]);

  const data = {
    labels,
    datasets: [
      {
        label: title,
        data: dataChart,
        backgroundColor: CHART_BLUE,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
    maintainAspectRatio: dataSource.length < 50,
  };

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
  return (
    <div className="mt-3">
      <p className="fw-bold fs-16 mb-2 mt-1">{title}</p>
      <div className="chartWrapper p-2">
        <div
          className="chartAreaWrapper"
          style={{ width: dataSource.length > 50 ? `${dataSource.length * 30}px` : '1500px' }}
        >
          <Bar options={options as any} data={data} />
        </div>
      </div>
    </div>
  );
}
