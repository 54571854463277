/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Table } from 'react-bootstrap';
import { useTable, useSortBy, useBlockLayout } from 'react-table';
import { includes } from 'lodash';
import { TableProps } from 'Components/Common/Table/table';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';

const defaultPropGetter = () => ({});
const defaultGetHeaderProps = () => ({
  className: 'text-white d-flex align-item-center bg-dark text-center',
});
const defaultGetCellProps = () => ({
  className: 'text-center bg-white text-dark',
});

const TableCommon = ({
  columns,
  data,
  heightBody,
  classTable,
  isNotShowSort,
  getHeaderProps = defaultGetHeaderProps,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultGetCellProps,
  bordered = true,
  noDataText = '【登録されていません】',
}: TableProps): JSX.Element => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    useBlockLayout,
  );

  // TO HIDDEN GROUP HEADER TABLE
  const __headerGroups = [...headerGroups];
  __headerGroups.shift();

  const handleRenderSortIcon = (sortby = '') => {
    return (
      <>
        <CaretUpFill className={sortby === 'asc' ? 'text-primary' : ''} />
        <CaretDownFill className={sortby === 'desc' ? 'text-primary' : ''} />
      </>
    );
  };

  const hideIconSort = (id = '') => {
    return includes(['empty', 'salesAmount', 'numberOfSheetsSold'], id);
  };

  return (
    <Table
      className={`${classTable ?? ''} table-radius`}
      bordered={bordered}
      responsive
      {...getTableProps()}
    >
      <thead style={{ display: 'block' }}>
        {__headerGroups.map((headerGroup, headerGroupKey) => {
          return (
            <React.Fragment key={headerGroupKey}>
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any, columnKey) => {
                  return (
                    <React.Fragment key={columnKey}>
                      <th
                        {...column.getHeaderProps([
                          {
                            className: (column as any).className,
                            style: (column as any).style,
                          },
                          getColumnProps(column),
                          getHeaderProps(column),
                          !isNotShowSort && column.getSortByToggleProps(),
                        ])}
                        title={!isNotShowSort && 'Toggle SortBy'}
                      >
                        <div className="w-100">{column.render('Header')}</div>
                        {isNotShowSort || (
                          <div
                            className={`${column?.accessor || 'd-none'} fs-12 d-flex flex-column`}
                          >
                            {column.isSorted
                              ? column.isSortedDesc
                                ? handleRenderSortIcon('desc')
                                : handleRenderSortIcon('asc')
                              : hideIconSort(column.originalId)
                              ? ''
                              : handleRenderSortIcon()}
                          </div>
                        )}
                      </th>
                    </React.Fragment>
                  );
                })}
              </tr>
            </React.Fragment>
          );
        })}
      </thead>
      <tbody
        {...getTableBodyProps()}
        style={{
          display: 'block',
          overflowX: 'hidden',
          overflowY: 'auto',
          height: heightBody,
        }}
      >
        {data.length > 0 ? (
          rows.map((row, i) => {
            prepareRow(row);
            return (
              <React.Fragment key={i}>
                <tr {...row.getRowProps(getRowProps(row))}>
                  {row.cells.map((cell, cellKey) => {
                    return (
                      <React.Fragment key={cellKey}>
                        <td
                          {...cell.getCellProps([
                            {
                              className: (cell.column as any).className,
                              style: (cell.column as any).style,
                            },
                            getColumnProps(cell.column),
                            getCellProps(cell),
                          ])}
                        >
                          {cell.render('Cell')}
                        </td>
                      </React.Fragment>
                    );
                  })}
                </tr>
              </React.Fragment>
            );
          })
        ) : (
          <div className="d-flex align-items-center justify-content-center mt-2">{noDataText}</div>
        )}
      </tbody>
    </Table>
  );
};

export default TableCommon;
