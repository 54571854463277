import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Page404 from 'Page/Exception/404';
import Layout from 'Components/Layouts/Layout';
import Login from 'Page/Login';
import ResetPassword from 'Page/ResetPassword';
import ChangePassword from 'Page/ChangePassword';
import JSCookie from 'js-cookie';

import Artist from 'Page/Artist';
import CompanyInformation from 'Page/Company/CompanyInfo';
import CompanyRegisterInfo from 'Page/Company/RegisterInfo';
import ApproveTitle from 'Page/FunctionSettings/ApproveTitle';
import RecordCompany from 'Page/RecordCompany';
// import ReportByTitle from 'Page/Reports/ReportByTitle';
import PromotionManager from 'Page/PromotionManager';
import CuratorManagement from 'Page/CuratorManagement';
import CuratorRegister from 'Page/CuratorManagement/Register';
import DetailArtist from 'Page/Artist/Detail';
import NFTList from 'Page/NFTList';
import SettingSystemInformation from 'Page/SystemInfo/SettingInfo';
import SettingSystem from 'Page/SystemInfo/settingSystem';
import RecordCompanyDetails from 'Page/RecordCompany/Details';
import TitleList from 'Page/Title/TitleList';
import TitleDetail from 'Page/Title/TitleDetail';
import { ReactNode } from 'Components/Layouts/common';
import { useAppSelector } from 'App/Store';
import ForgotPassword from 'Page/ForgotPassword';
import { NewRecordCompany } from 'Components/Page/RecordCompanyList';
import DigitalPrivilege from 'Page/PromotionManager/TokutenDigital';
import Policy from 'Page/TermAndPolicy/Policy';
import Terms from 'Page/TermAndPolicy/Terms';
import SaleChannel from 'Page/SaleChannel/SaleChannel';
import DashBoard from 'Page/Dashboard';
import ListProducts from 'Page/ListProducts';
import ProductsDetail from 'Page/ListProducts/ProductsDetail';
import CreateProducts from 'Page/ListProducts/CreateProducts';
import Jasrac from 'Page/InformationAccountant/Jasrac';
import TableCampaign from 'Page/Campaign/Campaigns';
import CampaignDetail from 'Page/Campaign/CampaignDetail';
import RevenueManagement from 'Page/InformationAccountant/RevenueManagement';
import SerialNumberManagement from 'Page/ManageSerialNumber';
import CreateSerialNumber from 'Page/ManageSerialNumber/CreateSerialNumber';
import RegisterCD from 'Page/ManagerCD';
import EmailManagement from 'Page/EC/EmailManagement';
import CreateCD from 'Page/ManagerCD/CreateCD';
import StatisticCampaign from 'Page/Campaign/StatisticCampaign';
import ProcessBoard from 'Page/ProcessBoard';

import ECListSaleProducts from 'Page/EC/ECListSaleProducts';
import ECSaleProductDetail from 'Page/EC/ECSaleProductDetail';
import ECListPurchase from 'Page/EC/ECListPurchase';
import ECPurchaseDetail from 'Page/EC/ECPurchaseDetail';

import WebSettingAndPolicy from 'Page/SettingSite';
import PurchaseSummaryList from 'Page/PurchaseSummary/PurchaseSummaryList';
import PurchaseSummaryDetail from 'Page/PurchaseSummary/PurchaseSummaryDetail';
import PaymentNotificationList from 'Page/EC/PaymentAdvice';
import PaymentNotificationDetail from 'Page/EC/PaymentAdvice/PaymentNotificationDetail';
import GuideDeleteAccount from 'Page/DeleteAccount/GuideDeleteAccount';
interface RouteProps {
  component: ReactNode;
  authed: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const PrivateRoute = ({ component: Component, authed, ...rest }: RouteProps) => {
  return <Route {...rest} render={() => (authed ? <Component /> : <Redirect to="/login" />)} />;
};

const PublicRoute = ({ component: Component, authed, ...rest }: RouteProps) => {
  return (
    <Route {...rest} render={() => (authed ? <Redirect to="/title/list" /> : <Component />)} />
  );
};

export default function Routing(): JSX.Element {
  const {
    auth: { status },
  } = useAppSelector((state) => state);
  const accessToken = JSCookie.get('token');

  const isAccept = !!accessToken && status === 'ACTIVE';
  const isAcceptChangePassword = !!accessToken;

  return (
    <Switch>
      <PublicRoute authed={isAccept} exact strict path="/login" component={Login} />
      <Route exact strict path="/terms" component={Terms} />
      <Route exact strict path="/policy" component={Policy} />
      <PublicRoute
        authed={isAccept}
        exact
        strict
        path="/forgot-password"
        component={ForgotPassword}
      />
      <PublicRoute
        authed={isAccept}
        exact
        strict
        path="/reset-password"
        component={ResetPassword}
      />
      <PublicRoute
        authed={false}
        exact
        strict
        path="/how-to-delete-account"
        component={GuideDeleteAccount}
      />
      <PrivateRoute
        authed={isAcceptChangePassword}
        exact
        strict
        path="/change-password"
        component={ChangePassword}
      />
      <Layout>
        <Switch>
          <PrivateRoute authed={isAccept} exact strict path="/" component={DashBoard} />
          {/* <PrivateRoute authed={isAccept} exact strict path="/" component={ReportByTitle} /> */}
          <PrivateRoute authed={isAccept} exact strict path="/artist" component={Artist} />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/artist/detail-artist/:id"
            component={DetailArtist}
          />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/company-information"
            component={CompanyInformation}
          />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/company/register-infomation"
            component={CompanyRegisterInfo}
          />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/approve-title"
            component={ApproveTitle}
          />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/record-company-list"
            component={RecordCompany}
          />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/register-record-company"
            component={NewRecordCompany}
          />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/promotion-manager"
            component={PromotionManager}
          />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/promotion-manager/:id"
            component={DigitalPrivilege}
          />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/curator-management"
            component={CuratorManagement}
          />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/detail-manager/:id"
            component={CuratorRegister}
          />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/register-manager"
            component={CuratorRegister}
          />
          <PrivateRoute authed={isAccept} exact strict path="/nfts-list" component={NFTList} />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/system-information"
            component={SettingSystemInformation}
          />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/system-setting"
            component={SettingSystem}
          />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/record-company/:id"
            component={RecordCompanyDetails}
          />
          <PrivateRoute authed={isAccept} exact strict path="/title/list" component={TitleList} />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/detail-title/:id"
            component={TitleDetail}
          />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/digital-tokuten"
            component={DigitalPrivilege}
          />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/sale-channel"
            component={SaleChannel}
          />
          <PrivateRoute authed={isAccept} exact strict path="/jasrac" component={Jasrac} />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/campaigns"
            component={TableCampaign}
          />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/campaigns/:id"
            component={CampaignDetail}
          />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/revenue-management"
            component={RevenueManagement}
          />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/serial-numbers"
            component={SerialNumberManagement}
          />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/serial-numbers/:id"
            component={CreateSerialNumber}
          />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/statistic-campaign/:id"
            component={StatisticCampaign}
          />
          <PrivateRoute authed={isAccept} exact strict path="/cd-master" component={RegisterCD} />
          <PrivateRoute authed={isAccept} exact strict path="/cd-master/:id" component={CreateCD} />

          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/list-products"
            component={ListProducts}
          />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/create-products/:id"
            component={CreateProducts}
          />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/products-detail/:id"
            component={ProductsDetail}
          />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/products-detail"
            component={ProductsDetail}
          />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/ec/list-sale-products"
            component={ECListSaleProducts}
          />

          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/ec/sale-product-detail/:id"
            component={ECSaleProductDetail}
          />

          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/ec/setting-site"
            component={WebSettingAndPolicy}
          />

          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/ec/purchase-detail/:id"
            component={ECPurchaseDetail}
          />

          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/ec/list-purchase"
            component={ECListPurchase}
          />

          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/ec/email-message-management"
            component={EmailManagement}
          />
          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/processboard"
            component={ProcessBoard}
          />

          {/* <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/ec/payment-advice-list"
            component={PaymentAdviceList}
          /> */}

          {/* <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/ec/payment-advice-detail/:id"
            component={PaymentAdviceDetail}
          /> */}

          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/ec/purchase-summary-list"
            component={PurchaseSummaryList}
          />

          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/ec/purchase-summary-detail/:id"
            component={PurchaseSummaryDetail}
          />

          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/ec/payment-notification-list"
            component={PaymentNotificationList}
          />

          <PrivateRoute
            authed={isAccept}
            exact
            strict
            path="/ec/payment-notification-detail/:id"
            component={PaymentNotificationDetail}
          />

          <PrivateRoute authed={isAccept} exact strict component={Page404} />
        </Switch>
      </Layout>
      <Route component={Page404} />
    </Switch>
  );
}
