import { reduce } from 'lodash';
import React from 'react';
import { useWatch } from 'react-hook-form';

const ConditionRow = ({
  option,
  length,
  keyRow,
}: {
  option: string[];
  length: number;
  keyRow: number;
}): JSX.Element => {
  const renderOption = () => {
    let txt = '';
    for (let i = 0; i < option.length; i++) {
      txt += `${option[i]}${option.length > 1 && i < option.length - 1 ? ',' : ''} `;
    }
    return txt;
  };
  return (
    <>
      {length ? (
        <p className="text-break">
          条件{keyRow}：{renderOption()}の{length}
          点のうち、いずれか一点の購入が必要です。
        </p>
      ) : (
        <></>
      )}
    </>
  );
};

const normalizeCoureseConditions = (info: any) => {
  const convertInfor = info?.filter((i: any) => i.enabled);
  const conditions = reduce(
    convertInfor,
    (result, value, key) => {
      const cd1 = value.option1 ? `No.${value.index ? value.index : key + 1}` : '';
      const cd2 = value.option2 ? `No.${value.index ? value.index : key + 1}` : '';
      const cd3 = value.option3 ? `No.${value.index ? value.index : key + 1}` : '';
      const cd4 = value.option4 ? `No.${value.index ? value.index : key + 1}` : '';
      result.option1 = cd1 ? [...result.option1, cd1] : [...result.option1];
      result.option2 = cd2 ? [...result.option2, cd2] : [...result.option2];
      result.option3 = cd3 ? [...result.option3, cd3] : [...result.option3];
      result.option4 = cd4 ? [...result.option4, cd4] : [...result.option4];

      return result;
    },
    { option1: [], option2: [], option3: [], option4: [] } as {
      option1: string[];
      option2: string[];
      option3: string[];
      option4: string[];
    },
  );

  return conditions;
};

const CourseConditions = ({
  courseIndex,
  control,
}: {
  courseIndex: number;
  control?: any;
}): JSX.Element => {
  const courseInfos = useWatch({
    control,
    name: `courses.${courseIndex}.courseInfos`,
  });
  const conditions = normalizeCoureseConditions(courseInfos);
  return (
    <>
      <ConditionRow option={conditions?.option1} length={conditions?.option1.length} keyRow={1} />
      <ConditionRow option={conditions?.option2} length={conditions?.option2.length} keyRow={2} />
      <ConditionRow option={conditions?.option3} length={conditions?.option3.length} keyRow={3} />
      <ConditionRow option={conditions?.option4} length={conditions?.option4.length} keyRow={4} />
    </>
  );
};

const CourseConditionsOnlyView = ({ info }: { info: any }): JSX.Element => {
  const conditions = normalizeCoureseConditions(info);
  return (
    <>
      <ConditionRow option={conditions?.option1} length={conditions?.option1.length} keyRow={1} />
      <ConditionRow option={conditions?.option2} length={conditions?.option2.length} keyRow={2} />
      <ConditionRow option={conditions?.option3} length={conditions?.option3.length} keyRow={3} />
      <ConditionRow option={conditions?.option4} length={conditions?.option4.length} keyRow={4} />
    </>
  );
};

export { CourseConditions, CourseConditionsOnlyView };
