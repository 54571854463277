/* eslint-disable jsx-a11y/click-events-have-key-events */
import { TableGroup, TableColumns } from 'Components/Common/Table/TableGroup';
import { COL_END_CLASS } from 'Constant';
import React, { useMemo, useState } from 'react';
import { formatCurrency, formatDecimal, getSum } from 'Utils/Numbers';
const TablechargeType = ({ dataSource, miimCostTotal, title }: any): JSX.Element => {
  const [isHideState, setIsHideState] = useState(false);

  const columns = useMemo(
    () =>
      [
        {
          columns: [
            { text: '料金種別', rowSpan: 2, dataIndex: 'feeName', width: 150, className: 'px-5' },
            {
              text: '数量',
              rowSpan: 2,
              className: 'px-3',
              dataIndex: 'totalAmount',
              render: ({ item }: any) => {
                return <div className={`${COL_END_CLASS}`}>{formatCurrency(item.totalAmount)}</div>;
              },
            },

            {
              text: '定額料金',
              colSpan: 2,
            },
            {
              text: '定率料金',
              colSpan: 2,
            },
            {
              text: 'オプション料金',
              colSpan: 2,
            },
            {
              text: '原価合計',
              width: 80,
              rowSpan: 2,
            },
          ],
        },
        {
          columns: [
            {
              text: '単価',
              width: 80,
              dataIndex: 'optionCardBuyFee',
              render: ({ item }: any) => {
                return (
                  <div className={`${COL_END_CLASS}`}>{formatCurrency(item.optionCardBuyFee)}</div>
                );
              },
            },
            {
              text: '金額',
              width: 80,
              dataIndex: 'buyFeeMoney',
              render: ({ item }: any) => {
                return <div className={`${COL_END_CLASS}`}>{formatCurrency(item.buyFeeMoney)}</div>;
              },
            },
            {
              text: '料率',
              width: 80,
              dataIndex: 'taxFee',
              render: ({ item }: any) => {
                return (
                  <div className={`${COL_END_CLASS}`}>
                    {item.taxFee ? `${formatDecimal(item.taxFee, 1)}%` : ''}
                  </div>
                );
              },
            },
            {
              text: '金額',
              width: 80,
              dataIndex: 'taxFeeMoney',
              render: ({ item }: any) => {
                return <div className={`${COL_END_CLASS}`}>{formatCurrency(item.taxFeeMoney)}</div>;
              },
            },
            {
              text: '単価',
              width: 80,
              dataIndex: 'snFee',
              render: ({ item }: any) => {
                return (
                  <div className={`${COL_END_CLASS}`}>
                    {item.snFee !== '0' ? formatDecimal(item.snFee, 1) : ''}
                  </div>
                );
              },
            },

            {
              text: '金額',
              width: 80,
              dataIndex: 'snFeeMoney',
              render: ({ item }: any) => {
                return <div className={`${COL_END_CLASS}`}>{formatCurrency(item.snFeeMoney)}</div>;
              },
            },
            {
              dataIndex: 'totalMoney',
              className: 'd-none',
              render: ({ item }: any) => {
                return <div className={`${COL_END_CLASS}`}>{formatCurrency(item.totalMoney)}</div>;
              },
            },
          ],
        },
      ] as TableColumns[],
    [],
  );

  return (
    <div className="table-month-price d-flex flex-column">
      <div className="text-center d-flex align-items-center fw-bold">
        <span className="me-2">{title}</span>
        {!isHideState ? (
          <div
            onClick={() => {
              setIsHideState(true);
            }}
            className="ec_minimize-icon"
          />
        ) : (
          <div
            onClick={() => {
              setIsHideState(false);
            }}
            className="ec_maximize-icon"
          />
        )}
      </div>
      <TableGroup
        tableClass={isHideState ? `table-charge-hidden` : ''}
        dataSource={dataSource}
        tableColumns={columns}
      >
        <tr className="table-charge-tr-visible">
          <td className="text-end fw-bold">合計</td>
          <td></td>
          <td></td>
          <td className="text-end fw-bold">{formatCurrency(getSum(dataSource, 'buyFeeMoney'))}</td>
          <td></td>
          <td className="text-end fw-bold">{formatCurrency(getSum(dataSource, 'taxFeeMoney'))}</td>
          <td></td>
          <td className="text-end fw-bold">{formatCurrency(getSum(dataSource, 'snFeeMoney'))}</td>
          <td className="text-end fw-bold">{formatCurrency(miimCostTotal[0].totalMoney || 0)}</td>
        </tr>
      </TableGroup>
    </div>
  );
};
export default TablechargeType;
