/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { SALES_STATUS } from 'Constant/EC';
import useSuggestion from 'Hooks/useSuggestion';
import Search from 'Components/Common/Search/Search';
import DateSearch from 'Components/Common/Search/DateSearch';

export default function ECListSaleProductSearch(): JSX.Element {
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    status: StringParam,
    queryArtist: StringParam,
    queryTitle: StringParam,
    queryCampaign: StringParam,
    queryStatus: StringParam,
    querySale: StringParam,
    artistId: StringParam,
    titleId: StringParam,
    statusId: StringParam,
    saleIds: StringParam,
    campaignId: StringParam,
    startDate: StringParam,
    endDate: StringParam,
  });

  const {
    artistOptions,
    titleOptions,
    campaignOptions,
    saleOptions,
    loadingArtist,
    loadingTitle,
    loadingCampaign,
    loadingSale,
    handleLoadMore,
    handleFocusInput,
    handleEnterPrimary,
  } = useSuggestion({ fetchList: ['artist', 'title', 'campaign', 'sale'], isEcSuggestion: true });

  const handleClickItem = useCallback(
    (id: string, type: string) => {
      if (type === 'queryArtist') {
        setQuery({ artistId: id, page: 1 });
      }

      if (type === 'queryTitle') {
        setQuery({ titleId: id, page: 1 });
      }

      if (type === 'queryStatus') {
        setQuery({ statusId: id, page: 1 });
      }

      if (type === 'querySale') {
        setQuery({ saleIds: id, page: 1 });
      }

      if (type === 'queryCampaign') {
        setQuery({ campaignId: id, page: 1 });
      }
    },
    [query],
  );

  const handleSelectDate = useCallback(
    (date: string, type: string) => {
      if (type === 'startDate') {
        setQuery({ startDate: date, page: 1 });
      }

      if (type === 'endDate') {
        setQuery({ endDate: date, page: 1 });
      }
    },
    [query],
  );

  useEffect(() => {
    if (!query?.queryStatus) {
      setQuery({ queryStatus: SALES_STATUS[0]?.label });
    }
  }, []);

  return (
    <Row className="align-items-end justify-content-end">
      <Col className="px-0 ec_col-md-1_5">
        <Search
          labelMd="12"
          colMd="12"
          placeholder="検索"
          label="販売チャネル"
          classLabel="px-0 fw-bold"
          rowClass="w-100"
          colClass="px-0"
          searchKey="querySale"
          onEnter={() => handleEnterPrimary('querySale')}
          onClickResult={handleClickItem}
          options={saleOptions.options || []}
          loading={loadingSale}
          onLoadMore={() => handleLoadMore('pageSale')}
          onFocusInput={() => {
            handleFocusInput('querySale', 'pageSale');
          }}
        />
      </Col>
      <Col className="pe-1 ps-3 ec_col-md-1_5">
        <Search
          label="アーティスト"
          labelMd="12"
          colMd="12"
          placeholder="検索"
          showIcon
          classLabel="px-0 artist-label fw-bold"
          rowClass="w-100"
          colClass="px-0"
          options={artistOptions.options || []}
          onClickResult={handleClickItem}
          searchKey="queryArtist"
          onEnter={() => handleEnterPrimary('queryArtist')}
          loading={loadingArtist}
          onLoadMore={() => handleLoadMore('pageArtist')}
          onFocusInput={() => {
            handleFocusInput('queryArtist', 'pageArtist');
          }}
        />
      </Col>
      <Col className="pe-1 ps-3 ec_col-md-1_5">
        <Search
          label="タイトル"
          labelMd="12"
          colMd="12"
          placeholder="検索"
          showIcon
          classLabel="px-0 title-label fw-bold"
          rowClass="w-100"
          colClass="px-0"
          options={titleOptions.options || []}
          onClickResult={handleClickItem}
          searchKey="queryTitle"
          onEnter={() => handleEnterPrimary('queryTitle')}
          loading={loadingTitle}
          onLoadMore={() => handleLoadMore('pageTitle')}
          onFocusInput={() => {
            handleFocusInput('queryTitle', 'pageTitle');
          }}
        />
      </Col>
      <Col className="pe-1 ps-3 ec_col-md-1_5">
        <Search
          label="キャンペーン"
          labelMd="12"
          colMd="12"
          placeholder="検索"
          showIcon
          classLabel="px-0 fw-bold"
          rowClass="w-100"
          colClass="px-0"
          options={campaignOptions.options || []}
          onClickResult={handleClickItem}
          searchKey="queryCampaign"
          onEnter={() => handleEnterPrimary('queryCampaign')}
          loading={loadingCampaign}
          onLoadMore={() => handleLoadMore('pageCampaign')}
          onFocusInput={() => {
            handleFocusInput('queryCampaign', 'pageCampaign');
          }}
        />
      </Col>
      <Col className="pe-1 ps-3 ec_col-md-1_5">
        <Search
          label="販売ステータス"
          labelMd="12"
          colMd="12"
          placeholder="検索"
          showIcon
          classLabel="px-0 fw-bold"
          rowClass="w-100"
          colClass="px-0"
          options={SALES_STATUS || []}
          onClickResult={handleClickItem}
          loading={false}
          searchKey="queryStatus"
          onEnter={() => handleEnterPrimary('queryStatus')}
        />
      </Col>

      <Col className="pe-1 ps-3 ec_col-md-1_5">
        <DateSearch
          selected={query?.startDate ? new Date(query?.startDate) : undefined}
          label="販売期間"
          labelMd="12"
          colMd="12"
          placeholder="開始日"
          showIcon
          maxDate={query?.endDate ? new Date(query?.endDate) : undefined}
          searchKey="startDate"
          classLabel="px-0 fw-bold"
          rowClass="w-100"
          colClass="px-0"
          onSelectDate={handleSelectDate}
        />
      </Col>
      <Col className="pe-1 ps-3 ec_col-md-1_5">
        <DateSearch
          selected={query?.endDate ? new Date(query?.endDate) : undefined}
          labelMd="12"
          colMd="12"
          placeholder="終了日"
          showIcon
          minDate={query?.startDate ? new Date(query?.startDate) : undefined}
          searchKey="endDate"
          classLabel="px-0"
          rowClass="w-100"
          colClass="px-0"
          onSelectDate={handleSelectDate}
        />
      </Col>
    </Row>
  );
}
