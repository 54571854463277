import React, { useCallback, useEffect, useState } from 'react';
import { IPullDownItem } from 'Types/Common';
import FormGroupSearch from 'Components/Common/Form/FormGroupSearch';
import { CompanyApi } from 'Datasource/CompanyApi';
import { PAGE_SIZE } from 'Constant';
import { FormGroupSearchProps } from '../Form/form';
import { SearchResponse } from 'Hooks/useSuggestion';
import useMessage from 'Hooks/useMessage';
import { updateQuery } from 'Utils/Search';

export interface Props extends Omit<FormGroupSearchProps, 'onInputChange' | 'onSearch'> {
  onInput?: (keyword: string) => void;
}

export default function PullDownCompany(props: Props): JSX.Element {
  const [page, setPage] = useState<number>(1);
  const [companies, setCompanies] = useState<SearchResponse>({ options: [], totalPages: 0 });
  const [loading, setLoading] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const { openMessageError } = useMessage();

  const { onInput } = props;

  const handleSearchCompany = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await CompanyApi.getListCompanySuggestion({
        page,
        perPage: PAGE_SIZE,
        queryCompany: searchKey || '',
      });

      const options = data?.items?.map((company: IPullDownItem) => ({
        label: company.name,
        value: company.id,
      }));
      setCompanies({
        options: page === 1 ? options : [...companies.options, ...options],
        totalPages: data?.meta?.totalPages,
      });
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleLoadmore = () => {
    if (page < companies.totalPages) {
      let p = page;
      p += 1;
      setPage(p);
    }
  };

  useEffect(() => {
    handleSearchCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, page]);

  const debouceRequest = useCallback(
    (value: string) =>
      updateQuery(value, () => {
        setSearchKey(value);
        setPage(1);
        onInput && onInput(value);
      }),
    [onInput],
  );

  return (
    <div className="wrap-aritist-list">
      <FormGroupSearch
        onLoadMore={() => handleLoadmore()}
        onMenuClose={() => {
          setSearchKey('');
          setPage(1);
        }}
        isLoading={loading}
        options={companies.options || []}
        onInputChange={(keyword: string) => debouceRequest(keyword)}
        onMenuFocus={() => {
          setCompanies({ options: [], totalPages: 0 });
          handleSearchCompany();
        }}
        showIconClear
        {...props}
      />
    </div>
  );
}
