import brAxios from 'Api/Interceptors';
import { IResetPasswordForm } from 'Components/Page/ResetPassword/Form';
interface UserLogin {
  email: string;
  password: string;
}

interface IEmail {
  email: string;
}

interface ChangePasswordParams {
  oldPassword: string;
  newPassword: string;
}

export const AuthApi = {
  loginApi: (params: UserLogin) => brAxios.post('/login', params),
  forgotPassword: (params: IEmail) => brAxios.post('/forgot-password', params),
  changePassword: (params: ChangePasswordParams) => brAxios.post('/change-password', params),
  resetPassword: (params: IResetPasswordForm) => brAxios.post('/reset-password', params),
};
