/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Container, Row, Button, Form } from 'react-bootstrap';
import CardContainer from 'Components/Common/CardContainer';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import BRLogo from 'Static/Images/br-logo.png';
import {
  stringRequired,
  stringRequiredUrlMatch,
  postCodeValidation,
  katakanaFullSizeValidation,
  maxMsg,
  ENGLISH_CHAR_ONLY,
} from 'Utils/Validation';
import { Ips, SettingCommonApi } from 'Datasource/Settings';
import useMessage from 'Hooks/useMessage';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import NumberFormat from 'react-number-format';
import { handleEnteringNumber, handleEnteringPhoneNumber } from 'Utils/Numbers';
import FormGroupInputEc from 'Components/Common/EC/FormGroupInput';
import CheckBoxs from 'Components/Common/Form/GroupCheckBox/CheckBoxs';
import { AccountTypeEcOption } from 'Constant/AccountType';
import { useAppSelector } from 'App/Store';
import { RoleNames } from 'Constant/Roles';
import { BankApi } from 'Datasource/Bank';
import { handleEnglishCharOnly } from 'Utils/TextField';
import { randomId } from 'Utils/Title';
import { isEmpty } from 'lodash';
import { PlusSquareDotted, DashSquareDotted } from 'react-bootstrap-icons';
import MessageError from 'Components/Common/MessageError';

type FormValues = {
  id: string;
  name: string;
  homePage: string;
  remista: string;
  postCode: string;
  address1: string;
  address2: string;
  phoneNumber: string;
  phoneNumberUrgent: string;
  master: string;
  directDial: string;
  email: string;
  registrationNumber: string;
  bankCode: string;
  bankName: string;
  branchCode: string;
  branchName: string;
  bankAccountType: any;
  bankAccountNumber: string;
  bankAccountOwner: string;
  rules: Ips[];
};

const schema = yup.object().shape({
  name: stringRequired({ required: '入力してください。' }),
  homePage: stringRequiredUrlMatch({}),
  remista: stringRequiredUrlMatch({}),
  postCode: postCodeValidation({}).nullable(),
  address1: stringRequired({ required: '入力してください。' }).nullable(),
  registrationNumber: yup
    .string()
    .trim()
    .matches(ENGLISH_CHAR_ONLY, '半角英数字で入力してください。')
    .max(14, maxMsg(14))
    .nullable(),
  bankCode: yup
    .string()
    .max(255, maxMsg(255))
    .required('入力してください。')
    .trim('入力してください。'),
  bankName: yup.string().max(255, maxMsg(255)),
  branchCode: yup
    .string()
    .max(255, maxMsg(255))
    .required('入力してください。')
    .trim('入力してください。'),
  branchName: yup.string().max(255, maxMsg(255)),
  bankAccountNumber: yup
    .string()
    .max(255, maxMsg(255))
    .required('入力してください。')
    .trim('入力してください。'),
  bankAccountOwner: katakanaFullSizeValidation({}).trim('入力してください。').nullable(true),
  rules: yup.array().of(
    yup.object().shape({
      source: yup
        .string()
        .trim()
        .test({
          name: 'source',
          message: '正しいIPアドレスを入力してください。',
          test: (value, context: any) => {
            const { createError, path } = context;
            if (value === undefined || value === null || value === '') return true;
            const IPv4SegmentFormat = '(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])';
            const IPv4AddressFormat = `(${IPv4SegmentFormat}[.]){3}${IPv4SegmentFormat}`;
            const IPv4AddressRegExp = new RegExp(`^${IPv4AddressFormat}$`);
            const result = IPv4AddressRegExp.test(value);
            return (
              result ||
              createError({
                message: '正しいIPアドレスを入力してください。',
                path,
              })
            );
          },
        }),
    }),
  ),
});

const bankNameError = '入力した銀行コードが一致する銀行名は存在しません。';
const branchNameError = '入力した支店コードが一致する支店名は存在しません。';

function SettingSystemInformation(): JSX.Element {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<FormValues>({ resolver: yupResolver(schema), mode: 'onSubmit' });

  const [isLoading, setIsloading] = useState<boolean>(false);

  const { role } = useAppSelector((state) => state.auth);

  const isMaster = useMemo(() => {
    return role === RoleNames[0];
  }, [role]);

  const { openMessageError } = useMessage();

  useEffect(() => {
    getInforCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInforCompany = async () => {
    setIsloading(true);
    try {
      const res = await SettingCommonApi.getInfo();
      const { data } = res.data;
      setValue('id', data?.id);
      setValue('name', data?.name);
      setValue('homePage', data?.homePage);
      setValue('remista', data?.remista);
      setValue('postCode', data.postCode);
      setValue('address1', data?.address1);
      setValue('address2', data?.address2);
      setValue('phoneNumber', data?.phoneNumber);
      setValue('phoneNumberUrgent', data?.phoneNumberUrgent);
      setValue('master', data?.master);
      setValue('directDial', data?.directDial);
      setValue('email', data?.email);
      setValue('registrationNumber', data?.registrationNumber);
      setValue('bankCode', data?.bankCode);
      setValue('bankName', data?.bankName);
      setValue('branchCode', data?.branchCode);
      setValue('branchName', data?.branchName);
      setValue('bankAccountType', data?.bankAccountType);
      setValue('bankAccountNumber', data?.bankAccountNumber);
      setValue('bankAccountOwner', data?.bankAccountOwner);
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsloading(false);
    }
  };

  const onRegister = handleSubmit(async (values) => {
    setIsloading(true);
    try {
      const param = {
        ...values,
        bankAccountType: watch('bankAccountType') ? watch('bankAccountType') : 0,
      };
      await SettingCommonApi.updateInfo(param);
      if (isMaster) {
        //* create or update white list ip
        const whitelistIps: Ips[] = watch('rules')
          ?.map((rule: Ips) => ({
            ...rule,
            id: rule?.id?.toString()?.includes('uuid') ? null : rule.id,
          }))
          .filter((ip: Ips) => !isEmpty(ip.source));

        const {
          data: { data: rules },
        } = await SettingCommonApi.createWhiteListIp(whitelistIps);
        setValue(
          'rules',
          isEmpty(rules) ? [{ id: randomId(), description: '', source: '' }] : rules,
        );
        //******************************* */
      }
      getInforCompany();
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsloading(false);
    }
  });

  const onFillInput = (fillName: any, content: string, isPhone = false) => {
    setValue(fillName, isPhone ? handleEnteringPhoneNumber(content) : content);
  };

  const getBankInfo = async (bankCode: string) => {
    const response = await BankApi.getBankInfo(bankCode);
    if (response.data.data[0]) {
      clearErrors('bankCode');
      setValue('bankName', response.data.data[0].name);
    } else {
      setError('bankCode', { type: 'custom', message: bankNameError });
      setValue('bankName', '');
    }
  };

  const getBankBranchInfo = async (bankCode: string, branchCode: string) => {
    const response = await BankApi.getBankBranchInfo(bankCode, branchCode);
    if (response.data.data[0]) {
      clearErrors('branchCode');
      setValue('branchName', response.data.data[0].name);
    } else {
      setValue('branchName', '');
      setError('branchCode', { type: 'custom', message: branchNameError });
    }
  };

  const getWhiteListIp = async () => {
    try {
      const {
        data: { data },
      } = await SettingCommonApi.getWhiteListIp();
      if (data?.length > 0) {
        setValue('rules', data);
      } else {
        const initData: Ips[] = [{ id: randomId(), description: '', source: '' }];
        setValue('rules', initData);
      }
    } catch (error) {
      openMessageError(error);
    }
  };

  useEffect(() => {
    getWhiteListIp();
  }, []);

  return (
    <Container className="py-5">
      <SpinnerComponent isLoading={isLoading} />
      <Row className="w-100 justify-content-center">
        <form onSubmit={onRegister}>
          <Col>
            <CardContainer className="system-card" title="運営会社情報" darkHeader>
              <div className="d-flex flex-column align-items-center">
                <div className="text-center mb-5">
                  <img src={BRLogo} alt="br logo" className="w-max-content" />
                </div>
                <Row>
                  <Col xs="12">
                    <FormGroupInput
                      label="運営会社*"
                      labelMd="2"
                      colMd="10"
                      register={register('name')}
                      errorMessage={errors.name?.message}
                      disabled={!isMaster}
                      onChange={(e) => onFillInput('name', e)}
                      value={watch('name')}
                    />
                  </Col>
                  <Col xs="12">
                    <FormGroupInput
                      label="運営会社URL*"
                      labelMd="2"
                      colMd="10"
                      register={register('homePage')}
                      errorMessage={errors.homePage?.message}
                      disabled={!isMaster}
                      onChange={(e) => onFillInput('homePage', e)}
                      value={watch('homePage')}
                    />
                  </Col>
                  <Col xs="12">
                    <FormGroupInput
                      label="リミスタURL*"
                      labelMd="2"
                      colMd="10"
                      register={register('remista')}
                      disabled={!isMaster}
                      errorMessage={errors.remista?.message}
                      onChange={(e) => onFillInput('remista', e)}
                      value={watch('remista')}
                    />
                  </Col>
                  <Col xs="12">
                    <FormGroupInput
                      label="郵便番号*"
                      labelMd="2"
                      colMd="4"
                      register={register('postCode')}
                      disabled={!isMaster}
                      errorMessage={errors.postCode?.message}
                      maxLength={7}
                      onChange={(e) => onFillInput('postCode', e)}
                      value={watch('postCode')}
                    />
                  </Col>
                  <Col xs="12">
                    <FormGroupInput
                      label="所在地1*"
                      labelMd="2"
                      colMd="10"
                      register={register('address1')}
                      errorMessage={errors.address1?.message}
                      disabled={!isMaster}
                      onChange={(e) => onFillInput('address1', e)}
                      value={watch('address1')}
                    />
                  </Col>
                  <Col xs="12">
                    <FormGroupInput
                      label="所在地2"
                      labelMd="2"
                      colMd="10"
                      register={register('address2')}
                      errorMessage={errors.address2?.message}
                      disabled={!isMaster}
                      onChange={(e) => onFillInput('address2', e)}
                      value={watch('address2')}
                    />
                  </Col>
                  <Col xs="12">
                    <FormGroupInput
                      label="代表電話番号"
                      labelMd="2"
                      colMd="4"
                      register={register('phoneNumber')}
                      maxLength={13}
                      disabled={!isMaster}
                      errorMessage={errors.phoneNumber?.message}
                      hintText="※「-」をつけて入力。（例）03-1234-5678 / 080-1234-5678"
                      onChange={(e) => onFillInput('phoneNumber', e, true)}
                      value={watch('phoneNumber')}
                    />
                  </Col>

                  <Col xs="12">
                    <FormGroupInput
                      label="緊急電話番号"
                      labelMd="2"
                      colMd="4"
                      disabled={!isMaster}
                      register={register('phoneNumberUrgent')}
                      maxLength={13}
                      errorMessage={errors.phoneNumberUrgent?.message}
                      hintText="※「-」をつけて入力。（例）03-1234-5678 / 080-1234-5678"
                      onChange={(e) => onFillInput('phoneNumberUrgent', e, true)}
                      value={watch('phoneNumberUrgent')}
                    />
                  </Col>

                  <Col xs="12">
                    <FormGroupInputEc
                      value={watch('registrationNumber')}
                      onChange={(value) =>
                        setValue('registrationNumber', handleEnglishCharOnly(value) ?? '')
                      }
                      labelMd={2}
                      colMd={4}
                      label="登録番号"
                      disabled={!isMaster}
                      maxLength={14}
                      errorMessage={errors.registrationNumber?.message}
                      register={{ ...register('registrationNumber') }}
                      subLabel="（適格請求書発行事業者登録）"
                      hintText="（14桁）"
                      classCol="d-flex align-items-center"
                      classForm="d-flex align-items-center"
                      hintTextClass=" ml-10px w-fill"
                      leftTextClass="mr-10px"
                      messageWrapper="mb-2px"
                    />
                  </Col>

                  <Row className="mb-2">
                    <Form.Label column md={2}>
                      マスタ担当者
                    </Form.Label>
                    <Form.Label className="ps-4" column md={10}>
                      {watch('master')}
                    </Form.Label>
                  </Row>
                  <Row className="mb-2">
                    <Form.Label column md={2}>
                      直通電話番号
                    </Form.Label>
                    <Form.Label className="ps-4" column md={10}>
                      <NumberFormat
                        value={watch('directDial')}
                        displayType="text"
                        format="###-####-####"
                        className="border-0 pe-none"
                      />
                    </Form.Label>
                  </Row>
                  <Row className="mb-2">
                    <Form.Label column md={2}>
                      メールアドレス
                    </Form.Label>
                    <Form.Label className="ps-4" column md={10}>
                      {watch('email')}
                    </Form.Label>
                  </Row>

                  <div className="d-flex justify-content-end txt-require mt-2">
                    ご注意：＊印は必須項目です。
                  </div>
                </Row>
              </div>
            </CardContainer>
            <CardContainer className="system-card mt-4" title="請求書記載振込口座" darkHeader>
              <>
                <FormGroupInput
                  value={watch('bankCode')}
                  onChange={(value) => setValue('bankCode', handleEnteringNumber(value) ?? '')}
                  onBlur={(e: any) => {
                    if (watch('bankCode') == '') {
                      setValue('bankName', '');
                    }
                    getBankInfo(e.target.value);
                    setValue('branchCode', '');
                    setValue('branchName', '');
                  }}
                  labelMd={2}
                  disabled={!isMaster}
                  colMd={9}
                  label="銀行コード*"
                  maxLength={4}
                  errorMessage={errors.bankCode?.message}
                  messageWrapper="mt-0 ml-6px"
                  register={{ ...register('bankCode') }}
                  classInput="w-60px"
                  classCol="d-flex align-items-center"
                  hintText={`銀行名 ${watch('bankName') != undefined ? `:` : ''}`}
                  hintTextClass="fs-14 ml-40px"
                  hintTextDes={`${watch('bankName') != undefined ? `${watch('bankName')}` : ''}`}
                  hintTextDesClass={`${
                    watch('bankName') == bankNameError ? 'text-error ml-6px fs-small' : 'ml-6px'
                  }`}
                />

                <FormGroupInput
                  value={watch('branchCode')}
                  onChange={(value) => setValue('branchCode', handleEnteringNumber(value) ?? '')}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onBlur={(e: any) => {
                    if (watch('branchCode') == '') {
                      setValue('branchName', '');
                    }
                    if (watch('branchCode') != '') {
                      getBankBranchInfo(watch('bankCode'), e.target.value);
                    }
                  }}
                  labelMd={2}
                  colMd={9}
                  label="支店コード*"
                  maxLength={3}
                  errorMessage={errors.branchCode?.message}
                  messageWrapper="mt-0 ml-6px"
                  register={{ ...register('branchCode') }}
                  classInput="w-60px"
                  disabled={
                    watch('bankName') == bankNameError ||
                    watch('bankName') == undefined ||
                    watch('bankName') == ''
                      ? true
                      : false || !isMaster
                  }
                  classCol="d-flex align-items-center"
                  hintText={`支店名 ${watch('branchName') != undefined ? `:` : ''}`}
                  hintTextClass="fs-14 ml-40px"
                  hintTextDes={`${
                    watch('branchName') != undefined ? `${watch('branchName')}` : ''
                  }`}
                  hintTextDesClass={`${
                    watch('branchName') == branchNameError ? 'text-error ml-6px fs-small' : 'ml-6px'
                  }`}
                />

                <CheckBoxs
                  id="account-ec-type"
                  name="account-ec-type"
                  labelMd={2}
                  colMd={9}
                  disabled={!isMaster}
                  checkboxClass="flex-nowrap"
                  labelForm="口座種別"
                  value={watch('bankAccountType')}
                  options={AccountTypeEcOption}
                  onChange={({ value }) => setValue('bankAccountType', value)}
                  classForm="mb-3"
                  colClass="ms-3"
                />

                <FormGroupInput
                  value={watch('bankAccountNumber')}
                  onChange={(value) =>
                    setValue('bankAccountNumber', handleEnteringNumber(value) ?? '')
                  }
                  labelMd={2}
                  colMd={3}
                  disabled={!isMaster}
                  label="口座番号*"
                  errorMessage={errors.bankAccountNumber?.message}
                  register={{ ...register('bankAccountNumber') }}
                />

                <FormGroupInput
                  value={watch('bankAccountOwner')}
                  onChange={(value) => setValue('bankAccountOwner', value ?? '')}
                  labelMd={2}
                  colMd={9}
                  disabled={!isMaster}
                  label="口座名義（カタカナ）*"
                  errorMessage={errors.bankAccountOwner?.message}
                  hintText="全角カタカナで入力してください（全角カタカナ、全角数字、全角スペース、記号（）．ー／）"
                  hintTextClass="mt-1"
                  register={{ ...register('bankAccountOwner') }}
                />
              </>
            </CardContainer>

            <CardContainer title={'管理画面アクセス制限'} className="mt-4" darkHeader>
              <p className="ms-4">許可IPアドレス</p>
              {watch('rules')?.map((item: Ips, index: number) => (
                <>
                  <Row className="align-items-center mt-2" key={item.id}>
                    <Col md="10">
                      <FormGroupInput
                        labelWrapperClass="d-none"
                        classForm="mb-0"
                        name="ip-form"
                        labelMd="12"
                        colMd="12"
                        label=""
                        value={item.source || ''}
                        onChange={(value) => {
                          const currentValueIndex = watch('rules')?.findIndex(
                            (rule: Ips) => rule.id === item.id,
                          );
                          if (currentValueIndex !== -1) {
                            const tmpRules: Ips[] = [...watch('rules')];
                            tmpRules[currentValueIndex].source = value?.trim();
                            setValue('rules', tmpRules);
                          }
                        }}
                        placeholder="0.0.0.0"
                        disabled={!isMaster}
                      />
                    </Col>
                    <Col md="2" className="px-0">
                      <div className="mb-1">
                        {index === watch('rules')?.length - 1 ? (
                          <Button variant="outline" className="ps-0" disabled={!isMaster}>
                            <PlusSquareDotted
                              size={28}
                              color="gray"
                              onClick={() => {
                                setValue('rules', [
                                  ...watch('rules'),
                                  { id: randomId(), description: '', source: '' },
                                ]);
                              }}
                            />
                          </Button>
                        ) : (
                          <></>
                        )}
                        {watch('rules')?.length > 1 ? (
                          <Button variant="outline" className="px-0" disabled={!isMaster}>
                            <DashSquareDotted
                              size={28}
                              color="gray"
                              onClick={() => {
                                const filterList = watch('rules')?.filter(
                                  (rule: Ips) => rule.id !== item.id,
                                );
                                setValue('rules', filterList);
                                clearErrors(`rules.${index}.source`);
                              }}
                            />
                          </Button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <MessageError
                    classWrapper="ms-4"
                    message={errors?.rules && errors?.rules[index]?.source?.message}
                  />
                </>
              ))}
            </CardContainer>
            {isMaster && (
              <Col className="mt-4 d-flex justify-content-center">
                <Button className="submit-btn btn-equal-width" type="submit">
                  登録する
                </Button>
              </Col>
            )}
          </Col>
        </form>
      </Row>
    </Container>
  );
}
export default SettingSystemInformation;
