import React, { useState } from 'react';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import { calcMb } from 'Utils/File';
import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';

type ItemType = {
  index: number;
  size: number;
  type: string;
  file: any;
  imageUrl: string;
  id: number;
};

const Booklet = ({ listBooklets = [] }: { listBooklets: any }): JSX.Element => {
  const [items, setItems] = useState<ItemType[]>([]);

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    setItems(arrayMove(items, oldIndex, newIndex));
  };

  const SortableItem = SortableElement(({ item }: { item: ItemType }) => (
    <div className="inner-item position-relative">
      <img src={handleConnectStaticEndPoint(item?.imageUrl)} alt="" />

      <p>{`${calcMb(item?.size)}MB`}</p>
    </div>
  ));

  const SortableList = SortableContainer(({ items }: { items: ItemType[] }) => (
    <div className="booklet-container rounded">
      {items.map((item: ItemType, index: number) => {
        return <SortableItem key={`${item.id}`} index={index} item={item} />;
      })}
    </div>
  ));

  return (
    <div>
      <div className="border rounded pe-none" style={{ minHeight: '200px' }} aria-hidden="true">
        <SortableList
          items={listBooklets}
          onSortEnd={onSortEnd}
          axis="xy"
          distance={2}
          helperClass={`SortableHelper`}
        />
      </div>
    </div>
  );
};

export default Booklet;
