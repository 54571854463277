import dayjs from 'dayjs';

const handleParseDate = ({
  date,
  format,
}: {
  date: string | null;
  format: string;
}): string | null => {
  if (!date) return null;
  return dayjs(date).format(`${format}`);
};

export { handleParseDate };
