/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import brAxios from 'Api/Interceptors';

export const CDApis = {
  getCDs: ({
    limit,
    page,
    artistId,
    titleId,
    cdId,
    query,
    companyId,
    sort,
    order,
  }: {
    limit?: number;
    page?: number;
    artistId?: null | string | undefined;
    titleId?: null | string | undefined;
    cdId?: null | string | undefined;
    query?: string | undefined;
    companyId?: null | string | undefined;
    sort?: string;
    order?: string;
  }) =>
    brAxios.get(
      `/bootrock-company/serial-number/cds?page=${page ?? 1}&limit=${limit ?? 20}${
        artistId ? `&artistId=${artistId}` : ''
      }${titleId ? `&titleId=${titleId}` : ''}${cdId ? `&CDId=${cdId}` : ''}${
        query ? `&query=${query}` : ''
      }${companyId ? `&companyId=${companyId}` : ''}&sort=${sort || 'id'}&order=${order || 'DESC'}`,
    ),

  getCD: ({ id }: { id: string | number | undefined }) =>
    brAxios.get(`bootrock-company/serial-number/cd/${id}`),
};
