/* eslint-disable no-irregular-whitespace */
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import { DATE_FORMAT_14 } from 'Constant/Date';
import { formatPrice } from 'Utils/Numbers';
interface Props {
  startDate: string;
  endDate: string;
  paymentDate: string;
  transferAmount: number;
  nextPaymentAmount: number;
  totalAmount: number;
  chargeFee: number;
  platformFee: number;
  carriedAmount: number;
  tax: number;
}

export default function DetailBox({
  startDate,
  endDate,
  paymentDate,
  // transferAmount,
  nextPaymentAmount,
  totalAmount,
  // chargeFee,
  platformFee,
  carriedAmount,
  tax,
}: Props): JSX.Element {
  const startDateJa = dayjs(startDate?.substring(0, 10)).format(DATE_FORMAT_14);
  const endDateJa = dayjs(endDate?.substring(0, 10)).format(DATE_FORMAT_14);
  const paymentDateJa = dayjs(paymentDate?.substring(0, 10)).format(DATE_FORMAT_14);
  return (
    <Row className="shadow-sm border mt-4 ml-30px mr-30px">
      <Col className="border-right-ec mt-8px mb-8px" sm={4}>
        <div className="border-bottom-ec mr-6px pb-6px">
          対象期間　{startDateJa}　～　{endDateJa}　
        </div>
        <div className="d-flex flex-column align-items-center mt-2">
          <div>
            <div>
              <div className="mb-8px">支払日</div>
              <div className="fs-18 font-bold mb-8px">{paymentDateJa}</div>
            </div>
            <div>
              <div>支払金額</div>
              <div className="fs-26 font-bold d-inline border-bottom-2px-ec pl-12px pr-12px ml-minus-12px">
                ¥{totalAmount ? formatPrice(totalAmount) : 0}
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col className="border-right-ec mt-8px mb-8px pl-40px pr-40px" sm={4}>
        <div className="border-bottom-1px-ec mb-4px">
          {/* <div className="space-between mb-4px">
            <p>決済金額合計</p>
            <p>{transferAmount?.toLocaleString()}</p>
          </div>
          <div className="space-between mb-4px">
            <p>　- 決済手数料合計</p>
            <p>{chargeFee?.toLocaleString()}</p>
          </div> */}
          <div className="space-between mb-4px">
            <p>プラットフォーム手数料</p>
            <p>{platformFee ? formatPrice(platformFee) : 0}</p>
          </div>
          <div className="space-between mb-4px">
            <p>前回までの保留額</p>
            <p>{carriedAmount ? formatPrice(carriedAmount) : 0}</p>
          </div>
        </div>
        <div className="space-between mb-4px">
          <p>お支払金額</p>
          <p>{totalAmount ? formatPrice(totalAmount) : 0}</p>
        </div>
        <div className="space-between mb-4px">
          <p>（内消費税　10%）</p>
          <p>{tax ? formatPrice(tax) : 0}</p>
        </div>
        <div className="space-between">
          <p>今回保留額</p>
          <p>{nextPaymentAmount ? formatPrice(nextPaymentAmount) : 0}</p>
        </div>
      </Col>
      <Col sm={4}></Col>
    </Row>
  );
}
