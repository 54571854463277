import brAxios from 'Api/Interceptors';
import { IQueryParamsNTFS } from 'Components/Page/NFTList/nftlist';

export const NFTApi = {
  getList: (params: IQueryParamsNTFS) => brAxios.get('/bootrock-company/cards', { params }),

  getCardDetail: (productId: number | string) => {
    const response = brAxios.get(`/record-company/cards/${productId}`);
    return response;
  },

  exportExcel: (params: IQueryParamsNTFS) => {
    const response = brAxios.get(`/bootrock-company/cards/export/download`, {
      params,
      responseType: 'blob',
    });
    return response;
  },
};
