import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface SearchState {
  order: string | undefined;
  page: number | undefined;
  sort: string | undefined;
  userId: string | undefined;
  selectedId: number | undefined;
  selectedUser?: {
    userId: string | number;
    name: string;
    email: string;
  };
}
const searchSlice = createSlice({
  name: 'search',
  initialState: {
    order: undefined,
    page: undefined,
    sort: undefined,
    userId: undefined,
    selectedId: undefined,
    selectedUser: undefined,
  } as SearchState,
  reducers: {
    setSearch(state, action: PayloadAction<any>) {
      const { order, page, sort, userId, selectedId, selectedUser } = action.payload;
      state.order = order;
      state.page = page;
      state.sort = sort;
      state.userId = userId;
      state.selectedId = selectedId;
      state.selectedUser = selectedUser;
    },
  },
});

export const { setSearch } = searchSlice.actions;
export default searchSlice.reducer;
