import { find } from 'lodash';
import { matchPath } from 'react-router-dom';
import * as H from 'history';

interface RouteInfo {
  name: string;
  title: string;
  parent: string;
}

const dynamicRoutes = [
  { name: '/artist/detail-artist/:id', title: 'アーティスト詳細', parent: '/artist' },
  { name: '/record-company/:id', title: 'アカウント詳細', parent: '/record-company-list' },
  { name: '/register-record-company', title: 'アカウント登録', parent: '/record-company-list' },
  { name: '/detail-manager/:id', title: '担当者詳細', parent: '/curator-management' },
  { name: '/detail-title/:id', title: 'タイトル詳細', parent: '/title/list' },
  { name: '/promotion-manager/:id', title: 'デジタル特典詳細', parent: '/promotion-manager' },
  { name: '/campaigns/:id', title: 'キャンペーンリスト', parent: '/campaigns' },
  { name: '/statistic-campaign/:id', title: 'キャンペーンリスト', parent: '/campaigns' },
  { name: '/products-detail/:id', title: '', parent: '/products-detail' },
  { name: '/create-products/:id', title: 'キャンペーン商品を作成', parent: '/list-products' },
  { name: '/serial-numbers/:id', title: 'シリアルコード詳細', parent: '/serial-numbers' },
  { name: '/cd-master/:id', title: 'CDマスタ詳細', parent: '/cd-master' },

  //ec
  {
    name: '/ec/sale-product-detail/:id',
    title: 'EC販売商品詳細',
    parent: '/ec/list-sale-products',
  },
  {
    name: '/ec/purchase-detail/:id',
    title: 'EC購入データ詳細',
    parent: '/ec/list-purchase',
  },
  {
    name: '/ec/purchase-summary-detail/:id',
    title: 'EC支払通知明細',
    parent: '/ec/purchase-summary-list',
  },
  {
    name: '/ec/payment-notification-detail/:id',
    title: 'PF利用料支払通知明細',
    parent: '/ec/payment-notification-list',
  },
  {
    name: '/ec/payment-advice-detail/:id',
    title: 'EC支払通知明細',
    parent: '/ec/payment-advice-list',
  },
];

export const handleCheckDynamicRoutes = (location: H.Location): RouteInfo | undefined => {
  const matchRoute = find(dynamicRoutes, (rt) => {
    const match = matchPath(location.pathname, {
      path: rt.name,
      exact: true,
      strict: true,
    });
    return !!match;
  });
  return matchRoute;
};
