import ButtonNextMonth from 'Components/Common/ButtonNextMonth';
import Search from 'Components/Common/Search/Search';
import dayjs from 'dayjs';
import React from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { IDatePicker } from 'Types/Common';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import MessageError from 'Components/Common/MessageError';
import useSuggestion from 'Hooks/useSuggestion';
import DateSearch from 'Components/Common/Search/DateSearch';
interface SearchRevenueProps {
  onExportExcel?: () => void;
  isMonth?: boolean;
  loadingExcel?: boolean;
}
export default function SearchRevenue({
  onExportExcel,
  isMonth,
  loadingExcel,
}: SearchRevenueProps): JSX.Element {
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    queryArtist: StringParam,
    queryTitle: StringParam,
    artistIds: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    isOption: NumberParam,
    queryVariation: StringParam,
    queryCompany: StringParam,
    companyId: StringParam,
    variationIds: StringParam,
    titleIds: StringParam,
    companyIds: StringParam,
    artistId: StringParam,
    titleId: StringParam,
    variationId: StringParam,
  });

  const {
    companyOptions,
    artistOptions,
    titleOptions,
    variationOptions,
    loadingArtist,
    loadingTitle,
    loadingVariation,
    loadingCompany,
    handleLoadMore,
    handleFocusInput,
    handleEnterPrimary,
    handleClickItemPrimary,
  } = useSuggestion({
    fetchList: ['company', 'artist', 'title', 'variation'],
    showDraftVariation: true,
  });

  const onNextMonth = (e: any) => {
    setQuery({
      startDate: dayjs(e.startDate).toString(),
      endDate: dayjs(e.endDate).toString(),
    });
  };
  const onChangeDate = (date: any, type: string) => {
    if (!date) {
      setQuery({ [type]: '', page: 1 });
      return;
    }
    setQuery({ [type]: dayjs(date), page: 1 });
  };

  return (
    <>
      <Row className="align-items-end px-0">
        <Col md="10">
          <Row>
            <Col md="2" className="px-1 pe-3">
              <Search
                label="レコード会社"
                labelMd="12"
                colMd="12"
                placeholder="検索"
                showIcon
                classLabel="px-0"
                rowClass="w-100"
                colClass="px-0"
                searchKey="queryCompany"
                onEnter={() => handleEnterPrimary('queryCompany')}
                onClickResult={handleClickItemPrimary}
                options={companyOptions.options || []}
                loading={loadingCompany}
                onLoadMore={() => handleLoadMore('pageCompany')}
                onFocusInput={() => {
                  handleFocusInput('queryCompany', 'pageCompany');
                }}
              />
            </Col>
            <Col md="2" className="px-1 pe-3">
              <Search
                label="アーティスト"
                labelMd="12"
                colMd="12"
                placeholder="検索"
                showIcon
                classLabel="px-0"
                rowClass="w-100"
                colClass="px-0"
                searchKey="queryArtist"
                onEnter={() => handleEnterPrimary('queryArtist')}
                onClickResult={handleClickItemPrimary}
                options={artistOptions.options || []}
                loading={loadingArtist}
                onLoadMore={() => handleLoadMore('pageArtist')}
                onFocusInput={() => {
                  handleFocusInput('queryArtist', 'pageArtist');
                }}
              />
            </Col>
            <Col md="2" className="px-1 pe-3">
              <Search
                label="タイトル"
                labelMd="12"
                colMd="12"
                placeholder="検索"
                showIcon
                classLabel="px-0"
                rowClass="w-100"
                colClass="px-0"
                searchKey="queryTitle"
                onEnter={() => handleEnterPrimary('queryTitle')}
                onClickResult={handleClickItemPrimary}
                options={titleOptions.options || []}
                loading={loadingTitle}
                onLoadMore={() => handleLoadMore('pageTitle')}
                onFocusInput={() => {
                  handleFocusInput('queryTitle', 'pageTitle');
                }}
              />
            </Col>
            <Col md="2" className="px-1 pe-3">
              <Search
                label="バリエーション"
                labelMd="12"
                colMd="12"
                placeholder="検索"
                showIcon
                classLabel="px-0"
                rowClass="w-100"
                colClass="px-0"
                searchKey="queryVariation"
                onEnter={() => handleEnterPrimary('queryVariation')}
                onClickResult={handleClickItemPrimary}
                options={variationOptions.options || []}
                loading={loadingVariation}
                onLoadMore={() => handleLoadMore('pageVariation')}
                onFocusInput={() => {
                  handleFocusInput('queryVariation', 'pageVariation');
                }}
              />
            </Col>
            {isMonth ? (
              <Col md="4" className="revenue-tab px-1 pe-3">
                <ButtonNextMonth
                  onClickResult={onNextMonth}
                  queryDateSearch={query.startDate || ''}
                />
              </Col>
            ) : (
              <>
                <Col md="2" className="px-1 pe-3">
                  <DateSearch
                    label="集計開始日"
                    labelMd="12"
                    placeholder="開始日"
                    maxDate={query.endDate ? new Date(query.endDate) : null}
                    onSelectDate={(dateStart: IDatePicker) => onChangeDate(dateStart, 'startDate')}
                    selected={query.startDate ? new Date(query.startDate) : null}
                    colMd={12}
                    searchKey="startDate"
                  />
                </Col>
                <Col md="2" className="px-1 pe-3">
                  <DateSearch
                    label="集計終了日"
                    labelMd="12"
                    placeholder="終了日"
                    minDate={query.startDate ? new Date(query.startDate) : null}
                    onSelectDate={(dateEnd: IDatePicker) => onChangeDate(dateEnd, 'endDate')}
                    selected={query.endDate ? new Date(query.endDate) : null}
                    colMd={12}
                    searchKey="endDate"
                  />
                </Col>
              </>
            )}
          </Row>
        </Col>
        <Col md="2">
          <Button
            className={`btn-focus-none prize-btn-excel float-right ${
              loadingExcel ? 'pe-none' : ''
            }`}
            onClick={onExportExcel}
          >
            {loadingExcel ? (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Excel ダウンロード'
            )}
          </Button>
        </Col>
      </Row>
      {!query.companyIds && (
        <MessageError
          classWrapper="search-company-error ms-2"
          isWarning={true}
          message="レコード会社を選択してください。"
        />
      )}
    </>
  );
}
