/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';

const bankAxios = axios.create({
  baseURL: 'https://apis.bankcode-jp.com/v2/banks',
  timeout: 100000,
});

const apiKey = '4POyLjxhVuFD9CGYhDIJwwai4N394iTtZijV2JH';

export const BankApi = {
  getBankInfo: (bankCode: string) => bankAxios.get(`/?&filter=code==${bankCode}&apikey=${apiKey}`),
  getBankBranchInfo: (bankCode: string, branchCode: string) =>
    bankAxios.get(`/${bankCode}/branches?&filter=code==${branchCode}&apikey=${apiKey}`),
};
