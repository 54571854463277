import React from 'react';
import { Col, FormGroup, Row, FormCheck } from 'react-bootstrap';
import { ApproveTitleList } from 'Constant/Mockup';

export default function ApproveTitle(): JSX.Element {
  const approveChange = (id: string, values: string) => {
    console.log(id, values);
  };

  return (
    <div className="approve">
      <Row className="pb-5 pt-5 ps-5 pe-5 approve-container">
        <Col xs="12" className="mb-5">
          <p className="fw-bold">登録プロセス</p>
        </Col>
        <Col xs="12" className="approve-content">
          <div className="approve-content-inner">
            <Row className="mb-5">
              <Col>
                <p className="m-0 text-center fw-bold">レコード会社</p>
              </Col>
              <Col>
                <div className="bg-dark p-3">
                  <p className="m-0 text-center fw-bold light">ブートロック登録</p>
                </div>
              </Col>
              <Col>
                <div className="bg-light p-3 border">
                  <p className="m-0 text-center fw-bold dark">レコード会社登録</p>
                </div>
              </Col>
            </Row>
            {ApproveTitleList.map((item, index) => (
              <Row key={index} className="mb-3">
                <Col xs="4">
                  <div className="border d-flex ps-2 align-items-center iblock approve-text-box w-75 offset-sm-2">
                    {item.name}
                  </div>
                </Col>
                <Col xs="8">
                  <form>
                    <FormGroup as={Row}>
                      <Col className="text-center">
                        <FormCheck
                          type="radio"
                          name="approved"
                          value="br"
                          defaultChecked={item.approved === 'br'}
                          onChange={(e) => approveChange(item.id, e.currentTarget.value)}
                        />
                      </Col>
                      <Col className="text-center">
                        <FormCheck
                          type="radio"
                          name="approved"
                          value="rc"
                          defaultChecked={item.approved === 'rc'}
                          onChange={(e) => approveChange(item.id, e.currentTarget.value)}
                        />
                      </Col>
                    </FormGroup>
                  </form>
                </Col>
              </Row>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
}
