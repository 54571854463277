/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Col, Form, Image, Row, Button } from 'react-bootstrap';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import DateSearch from 'Components/Common/Search/DateSearch';
import searchIcon from 'Static/Images/search-icon.svg';
import PurchaseSearch from 'Components/Page/EC/Form/PurchaseSearch/PurchaseSearch';
import PurchaseMultipleSearch from './Form/PurchaseSearch/PurchaseMultipleSearch';
import useSuggestionECPurchase from 'Hooks/useSuggestionECPurchase';
import { handleGetIds } from 'Utils/Search';

export default function ECListPurchaseSearch({ handleExportExcel }: any): JSX.Element {
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    queryArtist: StringParam,
    queryTitle: StringParam,
    querySale: StringParam,
    queryVariation: StringParam,
    queryCampaign: StringParam,
    queryVariationTitle: StringParam,
    queryCampaignTitle: StringParam,
    artistId: StringParam,
    titleId: StringParam,
    saleId: StringParam,
    variationId: StringParam,
    campaignId: StringParam,
    orderNumber: StringParam,
    miimId: StringParam,
    realName: StringParam,
    statusId: StringParam,
    startDate: StringParam,
    endDate: StringParam,
  });

  const [queryList, setQueryList] = useState<any>({
    page: 1,
    queryArtist: undefined,
    queryTitle: undefined,
    queryVariation: undefined,
    querySale: undefined,
    queryCampaign: undefined,
    queryVariationTitle: undefined,
    queryCampaignTitle: undefined,
    artistId: undefined,
    titleId: undefined,
    variationId: undefined,
    campaignId: undefined,
    saleId: undefined,
    orderNumber: undefined,
    miimId: undefined,
    realName: undefined,
    startDate: undefined,
    endDate: undefined,
  });
  const {
    artistOptions,
    titleOptions,
    campaignOptions,
    variationOptions,
    saleOptions,
    loadingArtist,
    loadingTitle,
    loadingCampaign,
    loadingVariation,
    loadingSale,
    handleLoadMore,
    handleFocusInput,
  } = useSuggestionECPurchase({
    fetchList: ['artist', 'title', 'campaign', 'variation', 'sale'],
    queryList: queryList,
    setQueryList: setQueryList,
  });

  const handleEnter = useCallback(
    (type: string) => {
      const res = [
        artistOptions.options,
        titleOptions.options,
        variationOptions.options,
        campaignOptions.options,
      ];
      const ids = res.map((item: any) => handleGetIds(item));

      if (type === 'queryArtist') {
        const artistId = queryList[type] ? (ids[0] ? ids[0] : 'no-data') : '';
        setQueryList((old: any) => ({ ...old, artistId: artistId, page: 1 }));
      }

      if (type === 'queryTitle') {
        const titleId = queryList[type] ? (ids[1] ? ids[1] : 'no-data') : '';
        setQueryList((old: any) => ({ ...old, titleId, page: 1 }));
      }

      if (type === 'queryCampaign') {
        const campaignId = queryList[type] ? (ids[3] ? ids[3] : 'no-data') : '';
        setQueryList((old: any) => ({ ...old, campaignId, page: 1 }));
      }
    },
    [setQueryList],
  );

  const handleClickItem = useCallback(
    (id: string, type: string) => {
      if (type === 'queryArtist') {
        setQueryList((old: any) => ({ ...old, artistId: id, page: 1 }));
      }

      if (type === 'queryTitle') {
        setQueryList((old: any) => ({ ...old, titleId: id, page: 1 }));
      }

      if (type === 'queryVariation') {
        setQueryList((old: any) => ({ ...old, variationId: id, page: 1 }));
      }

      if (type === 'querySale') {
        setQueryList((old: any) => ({ ...old, saleId: id, page: 1 }));
      }

      if (type === 'queryCampaign') {
        setQueryList((old: any) => ({ ...old, campaignId: id, page: 1 }));
      }
    },
    [setQueryList],
  );

  const handleSelectDate = useCallback(
    (date: string, type: string) => {
      if (type === 'startDate') {
        setQueryList((old: any) => ({ ...old, startDate: date, page: 1 }));
      }

      if (type === 'endDate') {
        setQueryList((old: any) => ({ ...old, endDate: date, page: 1 }));
      }
    },
    [queryList],
  );

  const handleSearchPurchase = useCallback(() => {
    setQuery(queryList);
  }, [queryList]);

  const variationQueryValue = useMemo(() => {
    const variationQueryValueResult: any = [];
    if (Array.isArray(queryList.variationId) && queryList.variationId.length > 0) {
      queryList.variationId.forEach((variation: any, index: number) => {
        variationQueryValueResult.push({
          id: variation,
          value: variation,
          label: queryList?.queryVariationTitle?.[index],
        });
      });
    }
    return variationQueryValueResult;
  }, [queryList.variationId, queryList.queryVariationTitle]);

  const campaignQueryValue = useMemo(() => {
    const campaignQueryValueResult: any = [];
    if (Array.isArray(queryList.campaignId) && queryList.campaignId.length > 0) {
      queryList.campaignId.forEach((campaign: any, index: number) => {
        campaignQueryValueResult.push({
          id: campaign,
          value: campaign,
          label: queryList?.queryCampaignTitle?.[index],
        });
      });
    }
    return campaignQueryValueResult;
  }, [queryList.campaignId, queryList.queryCampaignTitle]);

  useEffect(() => {
    const newQueryList = {
      ...query,
      queryVariationTitle: query?.queryVariationTitle
        ? query?.queryVariationTitle?.split(',')
        : undefined,
      variationId: query?.variationId ? query?.variationId?.split(',') : undefined,
      queryCampaignTitle: query?.queryCampaignTitle
        ? query?.queryCampaignTitle?.split(',')
        : undefined,
      campaignId: query?.campaignId ? query?.campaignId?.split(',') : undefined,
    };
    setQueryList(newQueryList);
  }, []);

  return (
    <>
      <Col md="6" className="p-0">
        <Row className="mb-2">
          <Col className="p-0" md="10">
            <div className="d-flex">
              <PurchaseSearch
                labelMd="12"
                colMd="12"
                placeholder="検索"
                label="販売チャネル"
                classLabel="px-0 fw-bold"
                rowClass="w-100"
                colClass="px-0"
                searchKey="querySale"
                onEnter={() => handleEnter('querySale')}
                onClickResult={handleClickItem}
                queryValue={queryList?.querySale}
                setQueryValue={(value: any) => {
                  setQueryList((old: any) => ({
                    ...old,
                    querySale: value,
                    page: 1,
                  }));
                }}
                options={saleOptions.options || []}
                loading={loadingSale}
                onLoadMore={() => handleLoadMore('pageSale')}
                onFocusInput={() => {
                  handleFocusInput('querySale', 'pageSale');
                }}
              />
              <PurchaseSearch
                label="アーティスト"
                labelMd="12"
                colMd="12"
                placeholder="検索"
                showIcon
                classLabel="px-0 artist-label fw-bold"
                rowClass="w-100 mx-3"
                colClass="px-0"
                options={artistOptions.options || []}
                // disabled={!queryList?.saleId}
                queryValue={queryList?.queryArtist}
                setQueryValue={(value: any) => {
                  setQueryList((old: any) => ({
                    ...old,
                    queryArtist: value,
                    queryTitle: undefined,
                    queryVariation: undefined,
                    queryVariationTitle: undefined,
                    queryCampaign: undefined,
                    queryCampaignTitle: undefined,
                    variationId: undefined,
                    campaignId: undefined,
                    page: 1,
                  }));
                }}
                onClickResult={handleClickItem}
                searchKey="queryArtist"
                onEnter={() => handleEnter('queryArtist')}
                loading={loadingArtist}
                onLoadMore={() => handleLoadMore('pageArtist')}
                onFocusInput={() => {
                  handleFocusInput('queryArtist', 'pageArtist');
                }}
              />

              <PurchaseSearch
                label="タイトル"
                labelMd="12"
                colMd="12"
                placeholder="検索"
                showIcon
                classLabel="px-0 artist-label fw-bold"
                rowClass="w-100 mx-3"
                colClass="px-0"
                disabled={!queryList?.artistId}
                options={titleOptions.options || []}
                queryValue={queryList?.queryTitle}
                setQueryValue={(value) =>
                  setQueryList((old: any) => ({
                    ...old,
                    queryTitle: value,
                    queryVariation: undefined,
                    queryVariationTitle: undefined,
                    queryCampaign: undefined,
                    queryCampaignTitle: undefined,
                    variationId: undefined,
                    campaignId: undefined,
                    page: 1,
                  }))
                }
                onClickResult={handleClickItem}
                searchKey="queryTitle"
                onEnter={() => handleEnter('queryTitle')}
                loading={loadingTitle}
                onLoadMore={() => handleLoadMore('queryTitle')}
                onFocusInput={() => {
                  handleFocusInput('queryTitle', 'pageTitle');
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="p-0" md="10">
            <div className="d-flex">
              <PurchaseMultipleSearch
                label="バリエーション"
                labelMd="12"
                colMd="12"
                placeholder="検索"
                showIcon
                disabled={!(queryList?.titleId && queryList?.artistId)}
                classLabel="px-0 artist-label fw-bold"
                rowClass="w-100"
                colClass="px-0"
                options={variationOptions.options || []}
                queryValue={variationQueryValue}
                setQueryValue={(value) =>
                  setQueryList((old: any) => ({ ...old, queryVariation: value, page: 1 }))
                }
                setQueryValueTitle={(value: any) =>
                  setQueryList((old: any) => ({ ...old, queryVariationTitle: value, page: 1 }))
                }
                onClickResult={handleClickItem}
                searchKey="queryVariation"
                onEnter={() => handleEnter('queryVariation')}
                loading={loadingVariation}
                onLoadMore={() => handleLoadMore('queryVariation')}
                onFocusInput={() => {
                  handleFocusInput('queryVariation', 'pageVariation');
                }}
              />

              <PurchaseMultipleSearch
                label="キャンペーン"
                labelMd="12"
                colMd="12"
                placeholder="検索"
                showIcon
                disabled={!(queryList?.titleId && queryList?.artistId)}
                classLabel="px-0 artist-label fw-bold"
                rowClass="w-100 mx-3"
                colClass="px-0 "
                options={campaignOptions.options || []}
                queryValue={campaignQueryValue}
                setQueryValue={(value) =>
                  setQueryList((old: any) => ({ ...old, queryCampaign: value, page: 1 }))
                }
                setQueryValueTitle={(value: any) =>
                  setQueryList((old: any) => ({ ...old, queryCampaignTitle: value, page: 1 }))
                }
                onClickResult={handleClickItem}
                searchKey="queryCampaign"
                onEnter={() => handleEnter('queryCampaign')}
                loading={loadingCampaign}
                onLoadMore={() => handleLoadMore('queryCampaign')}
                onFocusInput={() => {
                  handleFocusInput('queryCampaign', 'pageCampaign');
                }}
              />
            </div>
          </Col>
        </Row>
      </Col>
      <Col md="6" className="p-0">
        <Row className="mb-2">
          <Col md="4" className="position-relative input-search-wrapper pe-0">
            <Image src={searchIcon} className="icon-search position-absolute icon-searchId" />
            <Form.Label column className="fw-bold">
              注文番号
            </Form.Label>
            <Form.Control
              className="input-search ps-4"
              // onBlur={(e) => setQuery({ ...query, orderNumber: e.target.value })}
              value={queryList?.orderNumber}
              onChange={(e) =>
                setQueryList((old: any) => ({
                  ...old,
                  orderNumber: e.target.value || undefined,
                  page: 1,
                }))
              }
              placeholder="検索"
              type="search"
            />
          </Col>
          <Col md="4" className="position-relative input-search-wrapper pe-0">
            <Image src={searchIcon} className="icon-search position-absolute icon-searchId" />
            <Form.Label column className="fw-bold">
              ユーザーID
            </Form.Label>
            <Form.Control
              className="input-search ps-4"
              // onBlur={(e) => setQuery({ ...query, miimId: e.target.value })}
              value={queryList?.miimId}
              onChange={(e) =>
                setQueryList((old: any) => ({
                  ...old,
                  miimId: e.target.value || undefined,
                  page: 1,
                }))
              }
              placeholder="検索"
              type="search"
            />
          </Col>
          <Col md="4" className="position-relative input-search-wrapper pe-0">
            <Image src={searchIcon} className="icon-search position-absolute icon-searchId" />
            <Form.Label column className="fw-bold">
              本名
            </Form.Label>
            <Form.Control
              className="input-search ps-4"
              // onBlur={(e) => setQuery({ ...query, realName: e.target.value })}
              value={queryList?.realName}
              onChange={(e) =>
                setQueryList((old: any) => ({
                  ...old,
                  realName: e.target.value || undefined,
                  page: 1,
                }))
              }
              placeholder="検索"
              type="search"
            />
          </Col>
        </Row>
        <Row className="align-items-end">
          <Col md="3" className="pe-1 ps-3">
            <DateSearch
              selected={queryList?.startDate ? new Date(queryList?.startDate) : undefined}
              label="購入日時"
              labelMd="12"
              colMd="12"
              placeholder="開始日"
              showIcon
              maxDate={queryList?.endDate ? new Date(queryList?.endDate) : undefined}
              searchKey="startDate"
              classLabel="px-0 fw-bold"
              rowClass="w-100"
              colClass="px-0"
              onSelectDate={handleSelectDate}
            />
          </Col>
          <Col md="3" className="pe-1 ps-3">
            <DateSearch
              selected={queryList?.endDate ? new Date(queryList?.endDate) : undefined}
              labelMd="12"
              colMd="12"
              placeholder="終了日"
              showIcon
              minDate={queryList?.startDate ? new Date(queryList?.startDate) : undefined}
              searchKey="endDate"
              classLabel="px-0"
              rowClass="w-100"
              colClass="px-0"
              onSelectDate={handleSelectDate}
            />
          </Col>

          <Col md="3">
            <Button
              variant="primary"
              className="btn-excel w-100 btn-focus-none ms-3"
              onClick={handleSearchPurchase}
            >
              検索
            </Button>
          </Col>

          <Col md="3">
            <Button
              variant="primary"
              className="btn-excel w-100 btn-focus-none ms-3"
              onClick={handleExportExcel}
            >
              Excel ダウンロード
            </Button>
          </Col>
        </Row>
      </Col>
    </>
  );
}
