import axios from 'axios';
import DateSelector from 'Components/Common/DateSelector';
import Filter from 'Components/Common/Filter/Filter';
import FormGroupSelect from 'Components/Common/Form/FormGroupSelect';
import Search from 'Components/Common/Search/Search';
import { DATE_FORMAT_4, DATE_FORMAT_YMD } from 'Constant/Date';
import { NFTApi } from 'Datasource/NFT';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { IDatePicker, IPullDownItem } from 'Types/Common';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { exportExcel } from 'Utils/File';
import { getUserGMT } from 'Utils/Params';
import {
  handleGetIds,
  handleSearchArtist,
  handleSearchCompany,
  ItemSearch,
  transformKey,
  updateQuery,
} from 'Utils/Search';

export default function NFTFilter(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [res, setRes] = useState<Array<ItemSearch>>([]);
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    queryCompany: StringParam,
    queryArtist: StringParam,
    companyId: StringParam,
    artistId: StringParam,
    companyIds: StringParam,
    artistIds: StringParam,
    type: StringParam,
    downloadDateStart: StringParam,
    downloadDateEnd: StringParam,
  });
  const [queryObj, setQueryObj] = useState({
    queryCompany: query.queryCompany,
    queryArtist: query.queryArtist,
    downloadDateStart: query.downloadDateStart,
    downloadDateEnd: query.downloadDateEnd,
    type: query.type,
  });

  const handleEnter = useCallback(
    (type: string) => {
      const ids = res.map((item: any) => handleGetIds(item));
      if (type === 'queryCompany') {
        const companyIds = query[type] ? (ids[0] ? ids[0] : 'no-data') : '';
        setQuery({ companyIds: companyIds, page: 1 });
      }

      if (type === 'queryArtist') {
        const artistIds = query[type] ? (ids[1] ? ids[1] : 'no-data') : '';
        setQuery({ artistIds: artistIds, page: 1 });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [res, query, loading],
  );

  const handleClickItem = useCallback(
    (id: string, type: string) => {
      if (type === 'queryCompany') {
        setQuery({ companyId: id, companyIds: id, page: 1 });
      }

      if (type === 'queryArtist') {
        setQuery({ artistId: id, artistIds: id, page: 1 });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query],
  );

  const onChangeDate = (date: any, type: string) => {
    if (!date) {
      setQuery({ [type]: undefined, page: 1 });
      return;
    }
    setQuery({ [type]: dayjs(date).format(DATE_FORMAT_4), page: 1 });
  };

  const handleGets = ({ queryCompany = '', queryArtist = '', companyId = '' }) => {
    const companiesR = handleSearchCompany({ queryCompany, page: 1 });
    const artistsR = handleSearchArtist({ queryArtist, companyId, page: 1 });
    return axios.all([companiesR, artistsR]);
  };

  const handleSearchAll = async ({ queryCompany = '', queryArtist = '', companyId = '' }) => {
    setLoading(true);
    const res = await handleGets({ queryCompany, queryArtist, companyId });
    const companies = res[0].data.data.map((company: IPullDownItem) => ({
      label: company.companies_name,
      value: company.companies_id,
    }));
    const artists = res[1].data.data.map((artist: IPullDownItem) => ({
      label: artist.localName,
      value: artist.id,
    }));
    const dataSource = [companies, artists];
    setRes(dataSource);
    setLoading(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouceRequest = useCallback((value) => updateQuery(value, setQueryObj), []);

  useEffect(() => {
    debouceRequest({
      queryCompany: query.queryCompany,
      queryArtist: query.queryArtist,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.queryCompany, query.queryArtist]);

  useEffect(() => {
    setLoading(true);
    handleSearchAll(transformKey(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryObj]);

  useEffect(() => {
    let validQuery = { ...query } as any;
    if (!query.queryCompany) {
      validQuery = {
        queryCompany: undefined,
        companyId: undefined,
        companyIds: undefined,
        artistIds: undefined,
        artistId: undefined,
        queryArtist: undefined,
        queryTitle: undefined,
        titleIds: undefined,
      };

      setQuery(validQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.queryCompany]);

  return (
    <div>
      <Filter filterClass="shadow-sm">
        <Row>
          <Col md="5" className="px-0">
            <Row className="d-flex">
              <Col md="6" className="px-0 pe-1">
                <Search
                  label="レコード会社"
                  labelMd="12"
                  colMd="12"
                  placeholder="検索"
                  showIcon
                  classLabel="px-0"
                  rowClass="w-100"
                  colClass="px-0"
                  searchKey="queryCompany"
                  onEnter={() => handleEnter('queryCompany')}
                  onClickResult={handleClickItem}
                  options={res[0]}
                  loading={loading}
                />
              </Col>
              <Col md="6" className="px-0 pe-1">
                <Search
                  label="アーティスト"
                  labelMd="12"
                  colMd="12"
                  placeholder="検索"
                  showIcon
                  classLabel="px-0"
                  rowClass="w-100"
                  colClass="px-0"
                  searchKey="queryArtist"
                  onEnter={() => handleEnter('queryArtist')}
                  onClickResult={handleClickItem}
                  options={res[1]}
                  loading={loading}
                />
              </Col>
            </Row>
          </Col>
          <Col md="7" className="px-0 align-self-end">
            <Row>
              <Col md="3" className="px-1">
                <FormGroupSelect
                  colMd="12"
                  rowClass="w-100"
                  colClass="px-0"
                  classLabel="px-0"
                  labelMd="12"
                  label=""
                  placeholder=""
                  defaultValue={{ label: '発売日', value: '発売日' }}
                  options={[{ label: '発売日', value: '発売日' }]}
                  onChange={() => []}
                />
              </Col>
              <Col md="3" className="ps-0 pe-1">
                <DateSelector
                  placeholder="開始日"
                  dateFormat="yyyy/MM/dd"
                  showTime={false}
                  maxDate={query.downloadDateEnd ? new Date(query.downloadDateEnd) : null}
                  onChange={(dateStart: IDatePicker) =>
                    onChangeDate(dateStart, 'downloadDateStart')
                  }
                  selected={query.downloadDateStart ? new Date(query.downloadDateStart) : null}
                />
              </Col>
              <Col md="3" className="ps-0 pe-1">
                <DateSelector
                  placeholder="終了日"
                  dateFormat="yyyy/MM/dd"
                  showTime={false}
                  minDate={query.downloadDateStart ? new Date(query.downloadDateStart) : null}
                  onChange={(dateEnd: IDatePicker) => onChangeDate(dateEnd, 'downloadDateEnd')}
                  selected={query.downloadDateEnd ? new Date(query.downloadDateEnd) : null}
                />
              </Col>
              <Col md="3" className="px-0 text-end">
                <Button
                  variant="primary"
                  className="btn-excel"
                  onClick={() =>
                    exportExcel(
                      NFTApi.exportExcel({
                        companyId: query.companyIds ?? '',
                        artistId: query.artistIds ?? '',
                        downloadDateStart: query.downloadDateStart
                          ? dayjs(query.downloadDateStart).toISOString()
                          : undefined,
                        downloadDateEnd: query.downloadDateEnd
                          ? dayjs(query.downloadDateEnd).endOf('d').toISOString()
                          : undefined,
                        gmt: getUserGMT(),
                      }),
                      `商品リスト_${dayjs().format(DATE_FORMAT_YMD).toString()}`,
                    )
                  }
                >
                  Excelダウンロード
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Filter>
    </div>
  );
}
