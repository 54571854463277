import dayjs from 'dayjs';
import { DATE_FORMAT_7, DATE_FORMAT_10 } from 'Constant/Date';
const isAfterDate = (dateOne: Date, dateTwo: Date) => {
  return dateOne.getTime() > dateTwo.getTime();
};
const formatDate = (date: string | Date, format: string = DATE_FORMAT_10): string => {
  return date ? dayjs(date).format(format) : '-';
};

const getDatesInRange = (openTime: string | Date, closeTime: string | Date): any[] => {
  const dates = [];

  let currentDate = dayjs(openTime).subtract(1, 'day');

  while (
    currentDate.isBefore(closeTime) ||
    currentDate.isSame(dayjs(closeTime).subtract(1, 'day'))
  ) {
    currentDate = currentDate.add(1, 'day');
    dates.push(currentDate.format(DATE_FORMAT_7));
  }
  return dates;
};
const currentStartMonth = dayjs().startOf('month').format(DATE_FORMAT_7);
const currentEnDMonth = dayjs().endOf('month').format(DATE_FORMAT_7);

const normalizeDate = (date: string | Date | null): Date | null => {
  return date ? new Date(date) : null;
};

const countDays = (day1: string | Date | null, day2: string | Date | null): number => {
  const date1 = dayjs(day1);
  const date2 = dayjs(day2);
  const numberOfDays = date2.diff(date1, 'day');
  return numberOfDays < 0 ? 0 : numberOfDays;
};

const replaceTimeOfDate = (date: Date | null, time: Date | null) => {
  if (!time || !date) return null;
  const h = dayjs(time).get('hour');
  const m = dayjs(time).get('minute');
  const s = dayjs(time).get('second');
  const validDate = dayjs(date).set('hour', h).set('minute', m).set('second', s);
  return validDate as any;
};

const handleEndDate = (endDate: any) => {
  return endDate ? dayjs(endDate).endOf('day').format(DATE_FORMAT_7) : '';
};

const handleStartDate = (startDate: any) => {
  return startDate ? dayjs(startDate).startOf('day').format(DATE_FORMAT_7) : '';
};

const handleEndMonth = (day: any) => {
  return day ? dayjs(day).endOf('month').format(DATE_FORMAT_7) : '';
};

const handleStartMonth = (day: any) => {
  return day ? dayjs(day).startOf('month').format(DATE_FORMAT_7) : '';
};

const formatDateTimeUTCToLocalEndTime = (date: string) => {
  return new Date(dayjs(date).endOf('month').tz().toISOString()).toString();
};

const formatDateTimeUTCToLocalStartTime = (date: string) => {
  return new Date(dayjs(date).startOf('month').tz().toISOString()).toString();
};

const startOfDayToGMT = (day: any) => {
  // const hrs = Math.abs(new Date().getTimezoneOffset() / 60);
  return day ? dayjs(day).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]') : '';
};

const endOfDayToGMT = (day: any) => {
  // const hrs = Math.abs(new Date().getTimezoneOffset() / 60);
  return day ? dayjs(day).endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]') : '';
};

const getCurrentTimeZone = () => {
  return Math.abs(new Date().getTimezoneOffset() / 60);
};

export {
  isAfterDate,
  formatDate,
  getDatesInRange,
  currentStartMonth,
  currentEnDMonth,
  handleEndDate,
  handleStartDate,
  handleEndMonth,
  handleStartMonth,
  normalizeDate,
  countDays,
  replaceTimeOfDate,
  startOfDayToGMT,
  endOfDayToGMT,
  getCurrentTimeZone,
  formatDateTimeUTCToLocalEndTime,
  formatDateTimeUTCToLocalStartTime,
};
