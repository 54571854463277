export const PAGE_SIZE = 20;
export const PAGE_SIZE_ARTIST = 15;
export const EMAIL_REGEX = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/g;
export const NUMBER_ONLY_REGEX = /^\d+$/;
export const NUMBER_REGEX = /\d/;
export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
export const WHITESPACE = /^\S*$/;
export const PHONE_DETECT_HYPHEN_REGEX = /-/g;

export const TYPE_MEDIA = {
  VARIATIONS: 0,
  SONGS: 1,
  DIGITAL_TOKUTEN: 2,
};

export const TOKUTEN_TYPE_MEDIA = {
  IMAGE: 0,
  VIDEO: 1,
  AUDIO: 2,
};

export const BIG_PER_PAGE = 10000;

export const TABLE_PRODUCTS_STATUS = ['variation', 'course', 'target-campaign', 'products-applied'];
export const COLOR_TABLE_PRODUCTS = [
  '#E9967A',
  '#800080',
  '#DB7093',
  '#C0FF80',
  '#20B2AA',
  '#F4AF60',
  '#FFA500',
  '#4682B4',
  '#FAF0E6',
  '#FFE4E1',
];
export const COL_START_CLASS = 'text-truncate text-start mw-250';
export const COL_END_CLASS = 'text-truncate text-end mw-250';
export const COL_CENTER_CLASS = 'text-truncate text-center mw-250';
export const PER_PAGE = 20;
export const PER_PAGE_EC = 50;
export const MAX_INPUT = 255;
export const MAX_FEE = 100000;
export const MAX_MB = 1000000000;
