import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { CuratorManagerApi, Data } from 'Datasource/CuratorManager';
import useMessage from 'Hooks/useMessage';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';

export default function CuratorLists(): JSX.Element {
  const history = useHistory();
  const [curatorData, setData] = useState<Data[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { openMessageError } = useMessage();

  useEffect(() => {
    handleFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFetchData = async () => {
    try {
      setIsLoading(true);
      const {
        data: { data },
      } = await CuratorManagerApi.getList();
      setData(data);
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container className="pt-4">
      <SpinnerComponent isLoading={isLoading} />
      <Row>
        {curatorData.length
          ? curatorData.map((info, i) => (
              <Col key={i} xs="12" className="pb-4 px-0">
                <div className="border shadow p-2">
                  <Row
                    className="cursor-pointer"
                    onClick={() => history.push(`detail-manager/${info.id}`)}
                  >
                    <Col md="6">
                      <p className="pb-1 underline-100 position-relative fw-bold text-break">
                        {info.name}
                      </p>
                      <p className="pt-1 ps-3">{info.email}</p>
                      <NumberFormat
                        value={info.phone}
                        format="###-####-####"
                        className="pt-1 ps-3 border-0 pe-none"
                      />
                    </Col>
                    <Col md="6">
                      <p className="pb-1 underline-100 position-relative fw-bold">
                        担当レコード会社
                      </p>
                      {info.recordCompanies.map((item) => (
                        <p className="pt-1 ps-3 text-break" key={item.id}>
                          {item.name}
                        </p>
                      ))}
                    </Col>
                  </Row>
                </div>
              </Col>
            ))
          : null}
      </Row>
    </Container>
  );
}
