import React, { useEffect } from 'react';
import { Table as BTable } from 'react-bootstrap';
import { useTable, useSortBy, useBlockLayout } from 'react-table';
import { includes } from 'lodash';
import { SortMethod, TableProps } from 'Components/Common/Table/table';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { isEmpty } from 'lodash';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';

const defaultPropGetter = () => ({});
const defaultGetHeaderProps = () => ({
  className: 'd-flex align-item-center bg-dark text-white text-center',
});
const defaultGetCellProps = () => ({
  className: 'text-center bg-white text-dark',
});

const TableBT = ({
  columns,
  data,
  classTable,
  heightBody,
  getHeaderProps = defaultGetHeaderProps,
  getColumnProps = defaultPropGetter,
  getCellProps = defaultGetCellProps,
  manualSortBy = false,
  noDataText = '検索結果がありません。',
  onSort,
}: TableProps): JSX.Element => {
  const [query] = useQueryParams({
    sort: StringParam,
    order: withDefault(StringParam, 'DESC', true),
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      manualSortBy: manualSortBy,
      initialState: {
        sortBy: query.sort ? [{ id: query.sort, desc: query.order === 'DESC' }] : [],
      },
    },
    useSortBy,
    useBlockLayout,
  );

  //* for manual sort from server side
  useEffect(() => {
    if (manualSortBy && onSort) {
      onSort(sortBy as SortMethod[]);
    }
  }, [manualSortBy, onSort, sortBy]);

  const handleRenderSortIcon = (sortby = '') => {
    return (
      <>
        <CaretUpFill className={sortby === 'asc' ? 'text-primary' : ''} />
        <CaretDownFill className={sortby === 'desc' ? 'text-primary' : ''} />
      </>
    );
  };

  const hideIconSort = (id = '') => {
    return includes(['empty'], id);
  };

  return (
    <>
      {!isEmpty(data) ? (
        <BTable
          className={`${classTable ?? ''} table-radius`}
          bordered
          responsive
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup, headerGroupKey) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupKey}>
                {headerGroup.headers.map((column, columnKey) => {
                  return (
                    <th
                      {...column.getHeaderProps([
                        {
                          className: (column as any).className,
                          style: (column as any).style,
                        },
                        column.getSortByToggleProps(),
                        getColumnProps(column),
                        getHeaderProps(column),
                      ])}
                      key={columnKey}
                    >
                      <div className="w-100">{column.render('Header')}</div>
                      {!column?.disableSortBy && (
                        <div className="fs-12 d-flex flex-column">
                          {column.isSorted || query.sort === column.id
                            ? column.isSortedDesc ||
                              (query.order === 'DESC' && query.sort === column.id)
                              ? handleRenderSortIcon('desc')
                              : handleRenderSortIcon('asc')
                            : hideIconSort(column.originalId)
                            ? ''
                            : handleRenderSortIcon()}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody
            {...getTableBodyProps()}
            className="hidden-scroll-nft-table"
            style={{
              display: 'block',
              overflowX: 'hidden',
              overflowY: 'auto',
              height: heightBody,
            }}
          >
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <td {...cell.getCellProps([getCellProps(cell)])} key={cellIndex}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </BTable>
      ) : (
        <div className={`vh-100-final d-flex align-items-center justify-content-center `}>
          {noDataText}
        </div>
      )}
    </>
  );
};

export default TableBT;
