import brAxios from 'Api/Interceptors';
import { AxiosResponse } from 'axios';

interface ISettings {
  numberGiftContent?: number | null;
  numberGiftSent: number | null;
}

interface IUpdateInfo {
  id: string;
  homePage: string;
  remista: string;
  name: string;
  master: string;
  postCode: string;
  address1: string;
  address2: string;
  phoneNumber: string;
  phoneNumberUrgent: string;
  registrationNumber: string;
  bankCode: string;
  bankName: string;
  branchCode: string;
  branchName: string;
  bankAccountType: number;
  bankAccountNumber: string;
  bankAccountOwner: string;
  directDial: string;
  email: string;
}

export interface Ips {
  id?: number | null | string;
  source?: string;
  description?: string;
}

export const SettingCommonApi = {
  getSetting: (): Promise<AxiosResponse> => brAxios.get('/bootrock-company/settings/common'),
  updateSetting: (params: ISettings): Promise<AxiosResponse> =>
    brAxios.put('/bootrock-company/settings/common', params),

  getInfo: (): Promise<AxiosResponse> => brAxios.get('/bootrock-company/info'),
  updateInfo: (param: IUpdateInfo): Promise<AxiosResponse> =>
    brAxios.put('/bootrock-company/info', param),
  getWhiteListIp: (): Promise<AxiosResponse> => brAxios.get('/ip-filter'),
  createWhiteListIp: (params: Ips[]): Promise<AxiosResponse> =>
    brAxios.post('/ip-filter', { rules: params }),
};
