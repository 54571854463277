/* eslint-disable react-hooks/exhaustive-deps */
import Filter from 'Components/Common/Filter/Filter';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import MarketingCard from 'Components/Page/DashBoard/MarketingCard';
import SearchDashBoard from 'Components/Page/DashBoard/SearchDashBoard';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import StatisticChart from 'Components/Page/DashBoard/Charts/StatisticChart';

import useMessage from 'Hooks/useMessage';
import { DashboardApi, IDashboard, IDashboardChart } from 'Datasource/DashboardApi';
import { StringParam, useQueryParams } from 'use-query-params';
import dayjs from 'dayjs';
import { exportExcel } from 'Utils/File';
import {
  currentEnDMonth,
  currentStartMonth,
  getDatesInRange,
  handleEndDate,
  handleStartDate,
} from 'Utils/DateTime';
import { optionChartDashboard } from 'Constant/Mockup';
import { DATE_FORMAT_4, DATE_FORMAT_EXPORT_EXCEL_PROCESS_BOARD } from 'Constant/Date';
import useErrorBlob from 'Hooks/useErrorBlob';

export default function DashBoard(): JSX.Element {
  const { openMessageError } = useMessage();
  const { openErrorBlob } = useErrorBlob();

  const [query, setQuery] = useQueryParams({
    variationIds: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    titleIds: StringParam,
    artistIds: StringParam,
    titleId: StringParam,
    variationId: StringParam,
    companyIds: StringParam,
    queryStartDate: StringParam,
    queryEndDate: StringParam,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [dashboardData, setDashboardData] = useState<IDashboard>();
  const [charts, setCharts] = useState<IDashboardChart[]>([]);
  const [exporting, setExporting] = useState<boolean>(false);

  useEffect(() => {
    if (!query.startDate && !query.endDate) {
      setQuery({
        startDate: dayjs().startOf('month').toISOString(),
        endDate: dayjs().endOf('month').toISOString(),
      });
    }
  }, [query.startDate, query.endDate]);

  useEffect(() => {
    getDashboard();
  }, [query]);

  useEffect(() => {
    handleDataChart();
  }, [dashboardData]);

  const getDashboard = async () => {
    if (query.startDate && query.endDate) {
      setQuery({
        queryStartDate: '',
        queryEndDate: '',
      });
    }
    if (!query.startDate && query.endDate) {
      setQuery({
        queryStartDate: dayjs(query.endDate).add(-3, 'year').toISOString(),
      });
    }
    if (query.startDate && !query.endDate) {
      setQuery({
        queryEndDate: dayjs(query.startDate).add(3, 'year').toISOString(),
      });
    }

    if (!query.startDate || !query.endDate) {
      return;
    }
    try {
      setLoading(true);
      const {
        data: { data },
      } = await DashboardApi.getDashboard({
        companyId: query.companyIds || '',
        artistIds: query.artistIds || '',
        titleIds: query.titleIds || '',
        startDate: handleStartDate(query.startDate) || '',
        endDate: handleEndDate(query.endDate) || '',
        variationIds: query.variationIds || '',
      });

      const itemToArr = Object.values(data.items);
      setDashboardData({ ...data, items: itemToArr });
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDataChart = () => {
    const chartData = getDatesInRange(
      handleStartDate(query.queryStartDate ? query.queryStartDate : query.startDate) ??
        currentStartMonth,
      handleEndDate(query.queryEndDate ? query.queryEndDate : query.endDate) ?? currentEnDMonth,
    ).map((d) => ({
      date: d,
      saleNumber: 0,
      enjoyNumber: 0,
      auditionNumber: 0,
      giftNumber: 0,
    }));
    chartData.forEach((d: IDashboardChart) => {
      const time1 = dayjs(d.date).format(DATE_FORMAT_4);
      const resItem = dashboardData?.items.find(
        (item) => dayjs(item.date).format(DATE_FORMAT_4) === time1,
      );
      if (resItem) {
        d.auditionNumber = resItem.auditionNumber;
        d.enjoyNumber = resItem.enjoyNumber;
        d.saleNumber = resItem.saleNumber;
        d.giftNumber = resItem.giftNumber;
      }
    });
    chartData.pop();
    setCharts(chartData);
  };

  const cardLeftDashboard = () => {
    return [
      {
        title: 'Sale',
        price: dashboardData?.saleNumberTotal,
        percent: dashboardData?.saleRate ? +(dashboardData.saleRate * 100).toFixed(2) : 0,
      },
      {
        title: 'Enjoy',
        price: dashboardData?.enjoyNumberTotal,
        percent: dashboardData?.enjoyRate ? +(dashboardData?.enjoyRate * 100).toFixed(2) : 0,
      },
    ];
  };

  const cardRightDashboard = () => {
    return [
      {
        title: 'Gift',
        price: dashboardData?.giftNumberTotal,
        percent: dashboardData?.giftRate ? +(dashboardData?.giftRate * 100).toFixed(2) : 0,
      },
      {
        title: 'Audition',
        price: dashboardData?.auditionNumberTotal,
        percent: dashboardData?.auditionRate ? +(dashboardData?.auditionRate * 100).toFixed(2) : 0,
      },
    ];
  };

  const onExportExcel = async () => {
    try {
      setExporting(true);
      await exportExcel(
        DashboardApi.exportExcel({
          artistIds: query.artistIds ?? undefined,
          startDate: handleStartDate(query.startDate) ?? currentStartMonth,
          endDate: handleEndDate(query.endDate) ?? currentEnDMonth,
          titleIds: query.titleIds ?? undefined,
          variationIds: query.variationIds ?? undefined,
          companyId: query.companyIds ?? undefined,
        }),
        `ダッシュボード_${dayjs(new Date()).format(DATE_FORMAT_EXPORT_EXCEL_PROCESS_BOARD)}`,
      );
    } catch (error) {
      openErrorBlob(error);
    } finally {
      setExporting(false);
    }
  };

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <Filter filterClass="shadow-sm">
        <Row className="align-items-end">
          <SearchDashBoard onExportExcel={onExportExcel} loadingExcel={exporting} />
        </Row>
      </Filter>
      <Container fluid className="p-5">
        <Row>
          <Col md={{ offset: 1, span: 5 }} className="pe-4">
            <MarketingCard
              title="First Marketing"
              content={cardLeftDashboard()}
              description1="販売数"
              description2="購入者のダウンロードした数"
              bottomTextRight1="Sale / Sale"
              bottomTextRight2="Enjoy / Sale"
            />
          </Col>
          <Col md={{ order: 1, span: 5 }} className="ps-4">
            <MarketingCard
              title="Secondary Marketing"
              content={cardRightDashboard()}
              description1="購入者がギフトした数"
              description2="ギフトされた人のダウンロード数"
              bottomTextRight1="Gift / Sale"
              bottomTextRight2="Audition / Gift"
            />
          </Col>
        </Row>
      </Container>
      <Container className="py-5 px-4">
        <div className="d-flex flex-column mt-5">
          {optionChartDashboard.map((item) => (
            <StatisticChart
              key={item.dataIndex}
              title={item.title}
              subtitle={item.subtitle}
              data={charts}
              dataIndex={item.dataIndex}
            />
          ))}
        </div>
      </Container>
    </>
  );
}
