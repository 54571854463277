/* eslint-disable react-hooks/exhaustive-deps */
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { IPullDownItem } from 'Types/Common';
// import { useQueryParams, StringParam, NumberParam } from 'use-query-params';
import {
  handleGetIds,
  handleSearchArtist,
  handleSearchTitle,
  handleSearchCampaigns,
  handleSearchVariation,
  handleSearchSaleChannels,
  PageQuery,
  updateQuery,
} from 'Utils/Search';
import useMessage from './useMessage';

export interface SearchResponse {
  options: IPullDownItem[];
  totalPages: number;
}

interface ValidQueryCommon {
  [key: string]: string | number | undefined;
}
interface Props {
  validQueryObj?: {
    validArtist?: ValidQueryCommon;
    validTitle?: ValidQueryCommon;
    validVariation?: ValidQueryCommon;
    validCD?: ValidQueryCommon;
    validSale?: ValidQueryCommon;
    validCampaign?: ValidQueryCommon;
    validStatus?: ValidQueryCommon;
  };

  fetchList: string[];
  showDraftVariation?: boolean;
  queryList?: any;
  setQueryList?: any;
}

export default function useSuggestionECPurchase({
  validQueryObj = {
    validArtist: { artistIds: undefined, queryArtist: undefined, artistId: undefined },
    validTitle: { queryTitle: undefined, titleIds: undefined, titleId: undefined },
    validVariation: { queryVariation: undefined, variationIds: undefined, variationId: undefined },
    validCampaign: { queryCampaign: undefined, campaignIds: undefined, campaignId: undefined },
    validSale: { querySale: undefined, saleIds: undefined, saleId: undefined },
  },
  fetchList,
  showDraftVariation = false,
  queryList,
  setQueryList,
}: Props): {
  artistOptions: SearchResponse;
  titleOptions: SearchResponse;
  variationOptions: SearchResponse;
  campaignOptions: SearchResponse;
  saleOptions: SearchResponse;
  pageObj: PageQuery;
  loadingArtist: boolean;
  loadingTitle: boolean;
  loadingVariation: boolean;
  loadingCampaign: boolean;
  loadingSale: boolean;
  handleLoadMore: (pageKey: string) => void;
  handleFocusInput: (queryKey: string, pageKey: string) => void;
  handleEnterPrimary: (type: string) => void;
  handleClickItemPrimary: (id: string, type: string) => void;
} {
  //* some query clear with search key clear
  const currentValidQuery = {
    validArtist: {
      ...validQueryObj?.validArtist,
      artistIds: undefined,
      queryArtist: undefined,
      artistId: undefined,
    },
    validTitle: {
      ...validQueryObj?.validTitle,
      queryTitle: undefined,
      titleIds: undefined,
      titleId: undefined,
    },
    validVariation: {
      ...validQueryObj?.validVariation,
      queryVariation: undefined,
      variationIds: undefined,
      variationId: undefined,
    },
    validCampaign: {
      ...validQueryObj?.validCampaign,
      queryCampaign: undefined,
      campaignIds: undefined,
      campaignId: undefined,
    },
    validSale: {
      ...validQueryObj?.validSale,
      querySale: undefined,
      saleIds: undefined,
      saleId: undefined,
    },
  };

  // const [query, setQuery] = useQueryParams({
  //   queryArtist: StringParam,
  //   queryTitle: StringParam,
  //   artistIds: StringParam,
  //   titleIds: StringParam,
  //   variationIds: StringParam,
  //   queryVariation: StringParam,
  //   artistId: StringParam,
  //   titleId: StringParam,
  //   variationId: StringParam,
  //   queryCampaign: StringParam,
  //   campaignIds: StringParam,
  //   campaignId: StringParam,
  //   page: NumberParam,
  // });

  const { openMessageError } = useMessage();

  const [queryObj, setQueryObj] = useState({
    queryArtist: queryList.queryArtist,
    queryTitle: queryList.queryTitle,
    queryVariation: queryList.queryVariation,
    queryCampaign: queryList.queryCampaign,
    querySale: queryList.querySale,
  });

  const [pageObj, setPageObj] = useState<PageQuery>({
    pageArtist: 1,
    pageTitle: 1,
    pageVariation: 1,
    pageCampaign: 1,
    pageSale: 1,
  });

  const [artists, setArtists] = useState<SearchResponse>({ options: [], totalPages: 0 });
  const [titles, setTitles] = useState<SearchResponse>({ options: [], totalPages: 0 });
  const [variations, setVariations] = useState<SearchResponse>({ options: [], totalPages: 0 });
  const [sales, setSales] = useState<SearchResponse>({ options: [], totalPages: 0 });
  const [campaigns, setCampaigns] = useState<SearchResponse>({ options: [], totalPages: 0 });

  const [loadingArtist, setLoadingArtist] = useState<boolean>(false);
  const [loadingTitle, setLoadingTitle] = useState<boolean>(false);
  const [loadingVariation, setLoadingVariation] = useState<boolean>(false);
  const [loadingCampaign, setLoadingCampaign] = useState<boolean>(false);
  const [loadingSale, setLoadingSale] = useState<boolean>(false);

  const handleFetchSales = async () => {
    try {
      setLoadingSale(true);
      const {
        data: { data },
      } = await handleSearchSaleChannels({
        artistId: queryList.artistId || queryList.artistIds || '',
        companyId: queryList.companyId || queryList.companyIds || '',
        titleId: queryList.titleId || queryList.titleIds || '',
        page: pageObj.pageSale,
        querySale: queryObj.querySale || '',
      });

      const options = data?.items?.map((sale: IPullDownItem) => ({
        label: sale.channelName,
        value: sale.companyId,
      }));

      setSales({
        options: pageObj.pageSale === 1 ? options : [...sales.options, ...options],
        totalPages: data?.meta?.totalPages,
      });
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoadingSale(false);
    }
  };

  const handleFetchArtists = async () => {
    try {
      setLoadingArtist(true);
      const {
        data: { data },
      } = await handleSearchArtist({
        queryArtist: queryObj.queryArtist || '',
        page: pageObj.pageArtist,
        companyId: queryList.companyId || queryList.companyIds || '',
      });

      const options = data?.items?.map((artist: IPullDownItem) => ({
        label: artist.localName,
        value: artist.id,
      }));
      setArtists({
        options: pageObj.pageArtist === 1 ? options : [...artists.options, ...options],
        totalPages: data?.meta?.totalPages,
      });
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoadingArtist(false);
    }
  };

  const handleFetchTitles = async () => {
    try {
      setLoadingTitle(true);
      const {
        data: { data },
      } = await handleSearchTitle({
        queryTitle: queryObj.queryTitle || '',
        artistId: queryList.artistId || queryList.artistIds || '',
        page: pageObj.pageTitle,
        companyId: queryList.companyId || queryList.companyIds || '',
      });

      const options = data?.items?.map((title: IPullDownItem) => ({
        label: title.name,
        value: title.id,
      }));
      setTitles({
        options: pageObj.pageTitle === 1 ? options : [...titles.options, ...options],
        totalPages: data?.meta?.totalPages,
      });
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoadingTitle(false);
    }
  };

  const handleFetchVariations = async () => {
    try {
      setLoadingVariation(true);
      const {
        data: { data },
      } = await handleSearchVariation({
        queryVariation: queryObj.queryVariation || '',
        artistId: queryList.artistId || queryList.artistIds || '',
        titleId: queryList.titleId || queryList.titleIds || '',
        showDraft: showDraftVariation,
        page: pageObj.pageVariation,
        companyId: queryList.companyId || queryList.companyIds || '',
      });

      const options = data?.items?.map((title: IPullDownItem) => ({
        label: title.name,
        value: title.id,
      }));
      setVariations({
        options: pageObj.pageVariation === 1 ? options : [...variations.options, ...options],
        totalPages: data?.meta?.totalPages,
      });
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoadingVariation(false);
    }
  };

  const handleFetchCampaigns = async () => {
    try {
      setLoadingCampaign(true);
      const {
        data: { data },
      } = await handleSearchCampaigns({
        artistId: queryList.artistId || queryList.artistIds || '',
        titleId: queryList.titleId || queryList.titleIds || '',
        page: pageObj.pageCampaign,
        queryCampaign: queryObj.queryCampaign || '',
      });

      const options = data?.items?.map((campaign: IPullDownItem) => ({
        label: campaign.name,
        value: campaign.id,
      }));

      setCampaigns({
        options: pageObj.pageCampaign === 1 ? options : [...campaigns.options, ...options],
        totalPages: data?.meta?.totalPages,
      });
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoadingCampaign(false);
    }
  };

  const debouceRequest = useCallback((value) => updateQuery(value, setQueryObj), []);

  //* debouce typing text 300ms
  useEffect(() => {
    debouceRequest({
      queryArtist: queryList.queryArtist,
      queryTitle: queryList.queryTitle,
      queryVariation: queryList.queryVariation,
      querySale: queryList.querySale,
      queryCampaign: queryList.queryCampaign,
    });
  }, [
    queryList.queryArtist,
    queryList.queryTitle,
    queryList.queryVariation,
    queryList.querySale,
    queryList.queryCampaign,
  ]);

  //* only call api when match key search
  useEffect(() => {
    if (fetchList.includes('artist')) handleFetchArtists();
  }, [queryObj, pageObj.pageArtist]);

  useEffect(() => {
    if (fetchList.includes('title')) handleFetchTitles();
  }, [queryObj, pageObj.pageTitle]);

  useEffect(() => {
    if (fetchList.includes('variation')) handleFetchVariations();
  }, [queryObj, pageObj.pageVariation]);

  useEffect(() => {
    if (fetchList.includes('sale')) handleFetchSales();
  }, [queryObj, pageObj.pageSale]);

  //* clear some query if text search clear
  useEffect(() => {
    if (fetchList.includes('campaign')) handleFetchCampaigns();
  }, [queryObj, pageObj.pageCampaign]);

  useEffect(() => {
    if (!queryList.queryArtist && !isEmpty(currentValidQuery.validArtist)) {
      const validQuery = currentValidQuery.validArtist;
      setQueryList((old: any) => ({ ...old, ...validQuery }));
    }

    setPageObj({ pageArtist: 1, pageTitle: 1, pageVariation: 1, pageCampaign: 1 });
  }, [queryList.queryArtist]);

  useEffect(() => {
    if (!queryList.queryTitle && !isEmpty(currentValidQuery.validTitle)) {
      const validQuery = currentValidQuery.validTitle;
      setQueryList((old: any) => ({ ...old, ...validQuery }));
    }

    setPageObj({ ...pageObj, pageTitle: 1, pageVariation: 1, pageCampaign: 1 });
  }, [queryList.queryTitle]);

  useEffect(() => {
    let validQuery = { ...queryList } as any;
    if (!queryList.queryVariation && !isEmpty(currentValidQuery.validVariation)) {
      if (Array.isArray(queryList.variationId) && queryList.variationId.length > 0) {
        validQuery = { ...currentValidQuery.validVariation, variationId: queryList.variationId };
      } else {
        validQuery = currentValidQuery.validVariation;
      }
      setQueryList((old: any) => ({ ...old, ...validQuery }));
    }

    setPageObj({ ...pageObj, pageVariation: 1, pageCD: 1 });
  }, [queryList.queryVariation]);

  useEffect(() => {
    if (!queryList.querySale && !isEmpty(currentValidQuery.validSale)) {
      const validQuery = currentValidQuery.validSale;
      setQueryList((old: any) => ({ ...old, ...validQuery }));
    }

    setPageObj({ ...pageObj, pageArtist: 1, pageTitle: 1, pageVariation: 1, pageCampaign: 1 });
  }, [queryList.querySale]);

  useEffect(() => {
    let validQuery = { ...queryList } as any;
    if (!queryList.queryCampaign && !isEmpty(currentValidQuery.validCampaign)) {
      if (Array.isArray(queryList.campaignId) && queryList.campaignId.length > 0) {
        validQuery = {
          ...currentValidQuery.validCampaign,
          campaignId: queryList.campaignId,
        };
      } else {
        validQuery = currentValidQuery.validCampaign;
      }
      setQueryList((old: any) => ({ ...old, ...validQuery }));
    }
    setPageObj({ ...pageObj, pageCampaign: 1 });
  }, [queryList.queryCampaign]);

  const handleLoadMore = (pageKey: string) => {
    if (pageKey === 'pageArtist' && pageObj.pageArtist < artists.totalPages)
      setPageObj({ ...pageObj, pageArtist: (pageObj.pageArtist += 1) });

    if (pageKey === 'pageTitle' && pageObj.pageTitle < titles.totalPages)
      setPageObj({ ...pageObj, pageTitle: (pageObj.pageTitle += 1) });

    if (pageKey === 'pageVariation' && pageObj.pageVariation < variations.totalPages)
      setPageObj({ ...pageObj, pageVariation: (pageObj.pageVariation += 1) });

    if (pageKey === 'pageSale' && pageObj.pageSale < sales.totalPages)
      setPageObj({ ...pageObj, pageSale: (pageObj.pageSale += 1) });

    if (pageKey === 'pageCampaign' && pageObj.pageCampaign < campaigns.totalPages)
      setPageObj({ ...pageObj, pageCampaign: (pageObj.pageCampaign += 1) });
  };

  //* when open menu, reset list menu all data
  const handleFocusInput = (queryKey: string, pageKey: string) => {
    if (queryKey === 'queryArtist') setArtists({ options: [], totalPages: 0 });
    if (queryKey === 'queryTitle') setTitles({ options: [], totalPages: 0 });
    if (queryKey === 'queryVariation') setVariations({ options: [], totalPages: 0 });
    if (queryKey === 'querySale') setSales({ options: [], totalPages: 0 });
    if (queryKey === 'queryCampaign') setCampaigns({ options: [], totalPages: 0 });
    setQueryObj({ ...queryObj, [queryKey]: '' });
    setPageObj({ ...pageObj, [pageKey]: 1 });
  };

  const handleEnterPrimary = useCallback(
    (type: string) => {
      const res = [artists.options, titles.options, variations.options];
      const ids = res.map((item: any) => handleGetIds(item).split(','));

      if (type === 'queryArtist') {
        const artistIds = queryList[type] ? (ids[0] ? ids[0][0] : '') : '';
        setQueryList({
          artistIds: queryList.artistIds ? queryList.artistIds : artistIds,
          page: 1,
          artistId: queryList.artistIds ? queryList.artistIds : artistIds,
        });
      }

      if (type === 'queryTitle') {
        const titleIds = queryList[type] ? (ids[1] ? ids[1][0] : '') : '';
        setQueryList({
          titleIds: queryList.titleIds ? queryList.titleIds : titleIds,
          page: 1,
          titleId: queryList.titleIds ? queryList.titleIds : titleIds,
        });
      }

      if (type === 'queryVariation') {
        const variationIds = queryList[type] ? (ids[2] ? ids[2][0] : '') : '';
        setQueryList({
          variationIds: queryList.variationIds ? queryList.variationIds : variationIds,
          page: 1,
          variationId: queryList.variationIds ? queryList.variationIds : variationIds,
        });
      }
    },
    [artists.options, queryList, setQueryList, titles.options, variations.options],
  );

  const handleClickItemPrimary = useCallback(
    (id: string, type: string) => {
      if (type === 'queryArtist') {
        setQueryList({ artistId: id, artistIds: id, page: 1 });
      }

      if (type === 'queryTitle') {
        setQueryList({ titleId: id, titleIds: id, page: 1 });
      }

      if (type === 'queryVariation') {
        setQueryList({ variationId: id, variationIds: id, page: 1 });
      }
    },
    [setQueryList],
  );

  return {
    artistOptions: artists,
    titleOptions: titles,
    variationOptions: variations,
    campaignOptions: campaigns,
    saleOptions: sales,
    pageObj,
    loadingArtist,
    loadingTitle,
    loadingVariation,
    loadingCampaign,
    loadingSale,
    handleLoadMore,
    handleFocusInput,
    handleEnterPrimary,
    handleClickItemPrimary,
  };
}
