/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useMemo } from 'react';

import BoxForm from 'Components/Common/Form/BoxForm';
import { Col, Row, Form, Button } from 'react-bootstrap';

import { ECApis } from 'Datasource/EC';

import { useParams } from 'react-router-dom';

import ECTable from 'Components/Common/EC/Table';

import CalendarSwipper from 'Components/Page/Campaign/RegisterCampaign/Common/Swipper';
import { DateType } from 'Types/Page/Campaign/RegisterCampaign';

import _, { isEmpty } from 'lodash';

import SpinnerComponent from 'Components/Common/SpinnerComponent';
import ECListSaleStatus from 'Components/Page/EC/ECListSaleStatus';
import GroupCheckBox from 'Components/Common/Form/GroupCheckBox';
import FormGroupDate from 'Components/Common/Form/FormGroupDate';
import ECFormGroupDateRange from 'Components/Page/EC/Form/FormGroupDateRange/ECFormGroupDateRange';
import FormInputNumber from 'Components/Common/Form/FormInputNumber';
import { handleGetMonths } from 'Utils/Campaign';
import { COLOR_DEAFAULT, defaultDates } from 'Constant/Campaign';
import ECCkeditor from 'Components/Page/EC/ECCkeditor';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PatternFormat from 'react-number-format';
import { normalizeDate } from 'Utils/DateTime';
import { DATE_FORMAT_2, DATE_FORMAT_21 } from 'Constant/Date';
import { toast } from 'react-toastify';
import { ICheckBox } from 'Types/Common';
import { Link } from 'react-router-dom';
import { DATE_FORMAT_FOR_DATE_PICKER } from 'Constant/Date';
import { InfoCircleFill } from 'react-bootstrap-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useHistory } from 'react-router-dom';
import MessageError from 'Components/Common/MessageError';
import useTitlePage from 'Hooks/useTitlePage';
import dayjs from 'dayjs';

import ECSaleProductDetailSchema from 'Components/Page/EC/Schemas/ECSaleProductDetailSchema';

import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';

import useMessage from 'Hooks/useMessage';
import TooltipsWithTruncate from 'Components/Common/Tooltips/TooltipsWithTruncate';
import { renderShopName } from 'Constant/Shop';
import { formatCurrency } from 'Utils/Numbers';

interface CampaignEvents {
  dates: DateType[];
  events: any[];
}

const SongColumns = [
  {
    title: '',
    headerClass: 'w-10',
    key: 'index',
    dataIndex: 'index',
    render: (value: string, item: any) => (
      <div className="text-center">
        <p className="text-break">{item.index}</p>
      </div>
    ),
  },
  {
    title: '',
    headerClass: 'w-90',
    key: 'songName',
    dataIndex: 'songName',
    render: (value: string, item: any) => (
      <div className="text-start">
        <p className="text-break">{item.songName}</p>
      </div>
    ),
  },
];

const VideoColumns = [
  {
    title: '',
    headerClass: 'w-10',
    key: 'index',
    dataIndex: 'index',
    render: (value: string, item: any) => (
      <div className="text-center">
        <p className="text-break">{item.index}</p>
      </div>
    ),
  },
  {
    title: '',
    headerClass: 'w-90',
    key: 'mediaName',
    dataIndex: 'mediaName',
    render: (value: string, item: any) => (
      <div className="text-start">
        <p className="text-break">{item.videoName}</p>
      </div>
    ),
  },
];

const ImageColumns = [
  {
    title: '',
    headerClass: 'w-10',
    key: 'index',
    dataIndex: 'index',
    render: (value: string, item: any) => (
      <div className="text-center">
        <p className="text-break">{item.index}</p>
      </div>
    ),
  },
  {
    title: '',
    headerClass: 'w-90',
    key: 'bookletUrl',
    dataIndex: 'bookletUrl',
    render: (value: string, item: any) => (
      <div className="text-start">
        <p className="text-break">{item.dpMediaImageUrl}</p>
      </div>
    ),
  },
];

const DesignateDrawType = [
  {
    label: '抽選なし',
    value: 0,
    checked: true,
  },
  {
    label: '抽選あり',
    value: 1,
    checked: false,
  },
];

const initDesignateDrawType = (drawVal: number | string | null) => {
  if (!drawVal) {
    return DesignateDrawType;
  }

  return DesignateDrawType.map((designateDraw) => {
    if (designateDraw.value === drawVal) {
      return { ...designateDraw, checked: true };
    }
    return { ...designateDraw, checked: false };
  });
};

export default function ECSaleProductDetail(): JSX.Element | null {
  const [ECProductDetailData, setECProductDetailData] = useState<any>(null);
  const [isSellProduct, setIsSellProduct] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [designateDrawType, setDesignateDrawType] = useState<Array<any>>(DesignateDrawType);

  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { id }: { id: string } = useParams();

  const { openMessageError } = useMessage();

  const methods = useForm<any>({
    defaultValues: {
      designateDraw: null,
      drawDate: null,
      isPublic: null,
      maximumDraw: null,
      publicStartDate: null,
      publicEndDate: null,
      saleStartDate: null,
      saleEndDate: null,
      aboveDetailNote: null,
      belowDetailNote: null,
      limitMemberTotalPurchase: null,
      isLimitMemberTotalPurchase: false,
      limitTotalOnetimePurchase: null,
      isLimitTotalOnetimePurchase: false,
      timeBetweenPurchase: null,
      isTimeBetweenPurchase: false,
    },
    resolver: yupResolver(ECSaleProductDetailSchema),
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const { setMenuTitle } = useTitlePage();

  const isDisabledPublicAndSaleDatePicker = useMemo(() => {
    if (ECProductDetailData && ECProductDetailData.saleTokenDate) {
      const dateNow = new Date();
      const dateBooking = new Date(ECProductDetailData.saleTokenDate);
      if (dateNow < dateBooking) {
        return true;
      }
    }
    return false;
  }, [ECProductDetailData]);

  const isOpenToolTipPublic = useMemo(() => {
    const dateNow = new Date();

    if (watch('publicStartDate') && watch('publicEndDate')) {
      const newPublicStartDate = new Date(watch('publicStartDate'));
      const newPublicEndDate = new Date(watch('publicEndDate'));
      if (dateNow > newPublicStartDate && dateNow < newPublicEndDate) {
        return true;
      }
    }

    return false;
  }, [watch('publicStartDate'), watch('publicEndDate')]);

  const isOpenToolTipSale = useMemo(() => {
    const dateNow = new Date();

    if (watch('saleStartDate') && watch('saleEndDate')) {
      const newSaleStartDate = new Date(watch('saleStartDate'));
      const newSaleEndDate = new Date(watch('saleEndDate'));
      if (dateNow > newSaleStartDate && dateNow < newSaleEndDate) {
        return true;
      }
    }

    return false;
  }, [watch('saleStartDate'), watch('saleEndDate')]);

  const maxSaleStartDate = useMemo(() => {
    if (watch('publicEndDate')) {
      const newPublicEndDate = new Date(watch('publicEndDate'));
      const newSaleEndDate = watch('saleEndDate') ? new Date(watch('saleEndDate')) : null;

      if (newSaleEndDate) {
        if (newPublicEndDate < newSaleEndDate) {
          return normalizeDate(watch('publicEndDate'));
        }
      } else {
        return normalizeDate(watch('publicEndDate'));
      }
    }

    return normalizeDate(watch('saleEndDate'));
  }, [watch('publicEndDate'), watch('saleEndDate')]);

  const minPublicStartDate = useMemo(() => {
    if (ECProductDetailData?.saleTokenDate) {
      return normalizeDate(new Date(ECProductDetailData.saleTokenDate));
    }
    return new Date();
  }, [ECProductDetailData?.saleTokenDate]);

  const handleGetSaleProductDetail = async (ids: any) => {
    try {
      setIsLoading(true);
      const {
        data: { data },
      } = await ECApis.getSaleProductDetail(ids);

      setECProductDetailData(data);
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeDesignateDraw = (value: Array<ICheckBox>) => {
    setDesignateDrawType(value);
    value.forEach((v) => {
      if (v.value === 0 && v.checked) {
        setValue('maximumDraw', null);
        setValue('drawDate', null);
      }
    });
    setValue('designateDraw', !!value.find((item) => item.checked)?.value, {
      shouldValidate: true,
    });
  };

  const onAboveDetailNoteChange = (event: any) => {
    setValue('aboveDetailNote', event.editor.getData());
  };

  const onBelowDetailNoteChange = (event: any) => {
    setValue('belowDetailNote', event.editor.getData());
  };

  const campaignRelations = useMemo(() => {
    if (ECProductDetailData?.relatedCampaigns) {
      const eventArr = _.reduce(
        ECProductDetailData?.relatedCampaigns,
        (result, value, key) => {
          const dates: DateType[] = [
            ...result?.dates,
            new Date(value.startDate),
            new Date(value.endDate),
          ];
          const events: any[] = [
            ...result?.events,
            {
              title: value?.name,
              id: `e-${key}`,
              start: new Date(value.startDate),
              end: new Date(value.endDate),
              backgroundColor: COLOR_DEAFAULT[Number(key) % COLOR_DEAFAULT.length],
              borderColor: COLOR_DEAFAULT[Number(key) % COLOR_DEAFAULT.length],
              description: value?.description,
            },
          ];
          result = { dates, events };
          return result;
        },
        { dates: [], events: [] } as CampaignEvents,
      );
      return eventArr;
    }
    return {
      dates: [],
      events: [],
    };
  }, [ECProductDetailData]);

  useEffect(() => {
    if (id) {
      handleGetSaleProductDetail(id);
    }
  }, [id]);

  useEffect(() => {
    setMenuTitle('EC販売商品詳細');
  }, []);

  useEffect(() => {
    //init data
    if (ECProductDetailData) {
      setValue(
        'isPublic',
        (!!ECProductDetailData?.isPublic && !!ECProductDetailData?.hasSettingPublic) ||
          !ECProductDetailData?.hasSettingPublic,
      );
      if (ECProductDetailData?.publicStartDate) {
        setValue(
          'publicStartDate',
          dayjs(ECProductDetailData?.publicStartDate).format(DATE_FORMAT_2),
        );
      }

      if (ECProductDetailData?.publicEndDate) {
        setValue('publicEndDate', dayjs(ECProductDetailData?.publicEndDate).format(DATE_FORMAT_2));
      }

      if (ECProductDetailData?.saleStartDate) {
        setValue('saleStartDate', dayjs(ECProductDetailData?.saleStartDate).format(DATE_FORMAT_2));
      }

      if (ECProductDetailData?.saleEndDate) {
        setValue('saleEndDate', dayjs(ECProductDetailData?.saleEndDate).format(DATE_FORMAT_2));
      }

      setDesignateDrawType(initDesignateDrawType(ECProductDetailData?.designateDraw));
      setValue('designateDraw', !!ECProductDetailData?.designateDraw);

      if (ECProductDetailData?.maximumDraw) {
        setValue('maximumDraw', ECProductDetailData?.maximumDraw);
      }

      if (ECProductDetailData?.drawDate) {
        setValue('drawDate', dayjs(ECProductDetailData?.drawDate).format(DATE_FORMAT_2));
      }

      if (ECProductDetailData?.limitMemberTotalPurchase) {
        setValue('isLimitMemberTotalPurchase', true);
        setValue('limitMemberTotalPurchase', ECProductDetailData?.limitMemberTotalPurchase);
      }

      if (ECProductDetailData?.limitTotalOnetimePurchase) {
        setValue('isLimitTotalOnetimePurchase', true);
        setValue('limitTotalOnetimePurchase', ECProductDetailData?.limitTotalOnetimePurchase);
      }

      if (ECProductDetailData?.timeBetweenPurchase) {
        setValue('isTimeBetweenPurchase', true);
        setValue('timeBetweenPurchase', ECProductDetailData?.timeBetweenPurchase);
      }

      if (ECProductDetailData?.aboveDetailNote) {
        setValue('aboveDetailNote', ECProductDetailData?.aboveDetailNote);
      }

      if (ECProductDetailData?.belowDetailNote) {
        setValue('belowDetailNote', ECProductDetailData?.belowDetailNote);
      }
    }
  }, [ECProductDetailData]);

  return (
    <div className="pt-4 px-4">
      <Form>
        <BoxForm title="タイトル情報">
          <SpinnerComponent isLoading={isLoading} />

          <div className="p-2">
            <Row>
              <Col md="4">
                <div className="d-flex mb-4">
                  {ECProductDetailData?.coverImageUrl ? (
                    <img
                      className="ec_image-sale_product border"
                      alt="preview"
                      src={handleConnectStaticEndPoint(ECProductDetailData?.coverImageUrl)}
                    />
                  ) : (
                    <div className="avatar-title bg-gray avatar-bank d-flex justify-content-center align-items-center">
                      <p className="gray-dark">No Image</p>
                    </div>
                  )}

                  <div className="d-flex flex-column w-100 overflow-hidden ms-3">
                    <div className="mb-2">
                      <ECListSaleStatus status={ECProductDetailData?.status} />
                    </div>
                    <div className="mb-1">
                      <div>
                        <span className="ec_fs-10">タイトル</span>
                      </div>
                      <div>
                        <div className="fw-bold ec_fs-18 text-truncate">
                          {ECProductDetailData?.titleName}
                        </div>
                        <div className="ms-3">
                          <div className="ec_fs-12 text-truncate">
                            {ECProductDetailData?.artistName}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-1">
                      <div>
                        <span className="ec_fs-10">バリエーション</span>
                      </div>
                      <div>
                        <div className="fw-bold ec_fs-18 text-truncate">
                          {ECProductDetailData?.variationName}
                        </div>
                      </div>
                    </div>

                    <div className="d-flex">
                      <div className="d-flex flex-column me-3">
                        <span className="ec_fs-10">販売価格</span>
                        <span className="fw-bold ec_fs-18">
                          ¥{formatCurrency(ECProductDetailData?.variationPrice)}
                        </span>
                      </div>
                      <div className="d-flex flex-column">
                        <span className="ec_fs-10">品番</span>
                        <span className="fw-bold ec_fs-18 text-truncate">
                          {ECProductDetailData?.productCode}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-4">
                  <div className="d-flex mb-1">
                    <CopyToClipboard
                      onCopy={() => {
                        toast('クリップボードにコピーしました。', { type: 'success' });
                      }}
                      text={ECProductDetailData?.urlProduction}
                    >
                      <div>
                        <svg
                          style={{ marginRight: '5px', cursor: 'pointer' }}
                          xmlns="http://www.w3.org/2000/svg"
                          width={22}
                          height={22}
                          viewBox="0 0 32 32"
                        >
                          <g fill="none" fillRule="evenodd">
                            <path
                              fill="#005CD9"
                              fillRule="nonzero"
                              d="M4,8 L4,28 L24,28 L24,8 L4,8 Z M4,6 L24,6 C25.1045695,6 26,6.8954305 26,8 L26,28 C26,29.1045695 25.1045695,30 24,30 L4,30 C2.8954305,30 2,29.1045695 2,28 L2,8 C2,6.8954305 2.8954305,6 4,6 Z"
                            />
                            <path
                              fill="#005CD9"
                              fillRule="nonzero"
                              d="M8,6 L6,6 L6,4 C6,2.8954305 6.8954305,2 8,2 L28,2 C29.1045695,2 30,2.8954305 30,4 L30,24 C30,25.1045695 29.1045695,26 28,26 L26,26 L26,24 L28,24 L28,4 L8,4 L8,6 Z"
                            />
                          </g>
                        </svg>
                      </div>
                    </CopyToClipboard>
                    <a
                      href={`${ECProductDetailData?.urlProduction}`}
                      onClick={(e) => {
                        e.preventDefault();
                        // eslint-disable-next-line no-extra-boolean-cast
                        if (
                          !ECProductDetailData?.isPublic ||
                          [0, 1, 5].includes(ECProductDetailData?.status)
                        ) {
                          toast('商品は非公開中です。', { type: 'error' });
                        } else {
                          window.open(`${ECProductDetailData?.urlProduction}`, '_blank');
                        }
                      }}
                      className="d-block text-break"
                    >
                      <span className="text-start">{`${ECProductDetailData?.urlProduction}`}</span>
                    </a>
                  </div>

                  <div className="d-flex">
                    <CopyToClipboard
                      onCopy={() => {
                        toast('クリップボードにコピーしました。', { type: 'success' });
                      }}
                      text={ECProductDetailData?.preview}
                    >
                      <div>
                        <svg
                          style={{ marginRight: '5px', cursor: 'pointer' }}
                          xmlns="http://www.w3.org/2000/svg"
                          width={22}
                          height={22}
                          viewBox="0 0 32 32"
                        >
                          <g fill="none" fillRule="evenodd">
                            <path
                              fill="#005CD9"
                              fillRule="nonzero"
                              d="M4,8 L4,28 L24,28 L24,8 L4,8 Z M4,6 L24,6 C25.1045695,6 26,6.8954305 26,8 L26,28 C26,29.1045695 25.1045695,30 24,30 L4,30 C2.8954305,30 2,29.1045695 2,28 L2,8 C2,6.8954305 2.8954305,6 4,6 Z"
                            />
                            <path
                              fill="#005CD9"
                              fillRule="nonzero"
                              d="M8,6 L6,6 L6,4 C6,2.8954305 6.8954305,2 8,2 L28,2 C29.1045695,2 30,2.8954305 30,4 L30,24 C30,25.1045695 29.1045695,26 28,26 L26,26 L26,24 L28,24 L28,4 L8,4 L8,6 Z"
                            />
                          </g>
                        </svg>
                      </div>
                    </CopyToClipboard>
                    <a
                      href={`${ECProductDetailData?.preview}`}
                      target="_blank"
                      rel="noreferrer"
                      className="d-block text-break"
                    >
                      <span className="text-start">preview</span>
                    </a>
                  </div>
                </div>

                {/* 店舗名 */}
                <div className="mb-4 w-100">
                  <Form.Group className="mb-3 d-flex align-items-center">
                    <Form.Label className="fw-bold mb-0 me-3 text-nowrap">販売店舗</Form.Label>

                    <div className="flex-fill">
                      <TooltipsWithTruncate tooltips={renderShopName(ECProductDetailData)}>
                        <Form.Control
                          type="text"
                          value={renderShopName(ECProductDetailData)}
                          disabled
                          className="text-truncate"
                        />
                      </TooltipsWithTruncate>
                    </div>
                  </Form.Group>
                </div>

                <div className="mb-4">
                  <Form.Check
                    type="checkbox"
                    id="isPublic"
                    name="isPublic"
                    label="非公開にする"
                    // checked={false}
                    className="mb-1 ec_custom-checkbox-red"
                    checked={!watch('isPublic')}
                    // onChange={() => setValue('isPublic', !watch('isPublic'))}
                    // onChange={(e) => setIsShowOnlyMaster(e.target.checked)}
                  />
                  <div className="ec_desc-sale_product">
                    <span>※公開中または販売期間中であってもECサイトには表示されません</span>
                  </div>
                </div>
                {/* 
                <div className="d-flex align-items-center mb-4">
                  {isSellProduct ? (
                    <Button variant="success" onClick={() => setIsSellProduct(false)}>
                      販売停止
                    </Button>
                  ) : (
                    <>
                      <span className="me-3 d-flex align-items-center ec_desc-textdanger-sale_product ec_text_blink">
                        <div
                          style={{
                            width: '15px',
                            height: '15px',
                            marginBottom: '10px',
                            marginRight: '5px',
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="Layer_1"
                            data-name="Layer 1"
                            viewBox="0 0 122.89 111.55"
                          >
                            <defs>
                              <style
                                dangerouslySetInnerHTML={{
                                  __html:
                                    '.cls-1{fill:#b71616;}.cls-2{fill:#e21b1b;fill-rule:evenodd;}.cls-3{fill:#fff;}',
                                }}
                              />
                            </defs>
                            <title>red-alert</title>
                            <path
                              className="cls-1"
                              d="M2.35,84.43,45.29,10.2l.17-.27h0a22.92,22.92,0,0,1,7-7.23A17,17,0,0,1,61.58,0a16.78,16.78,0,0,1,9.11,2.69,22.79,22.79,0,0,1,7,7.26c.13.21.25.42.36.64l42.24,73.34.23.44h0a22.22,22.22,0,0,1,2.37,10.19,17.59,17.59,0,0,1-2.16,8.35,16,16,0,0,1-6.94,6.61l-.58.26a21.34,21.34,0,0,1-9.11,1.74v0H17.62c-.23,0-.44,0-.66,0a18.07,18.07,0,0,1-6.2-1.15A16.46,16.46,0,0,1,3,104.26a17.59,17.59,0,0,1-3-9.58,23,23,0,0,1,1.57-8.74,8.24,8.24,0,0,1,.77-1.51Z"
                            />
                            <path
                              className="cls-2"
                              d="M9,88.76l43.15-74.6c5.23-8.25,13.53-8.46,18.87,0l42.44,73.7c3.38,6.81,1.7,16-9.34,15.77H17.62c-7.27.18-12-6.19-8.64-14.87Z"
                            />
                            <path
                              className="cls-3"
                              d="M57.57,82.7a5.51,5.51,0,0,1,3.48-1.58,5.75,5.75,0,0,1,2.4.35,5.82,5.82,0,0,1,2,1.31,5.53,5.53,0,0,1,1.62,3.55,6.05,6.05,0,0,1-.08,1.4,5.54,5.54,0,0,1-5.64,4.6,5.67,5.67,0,0,1-2.27-.52,5.56,5.56,0,0,1-2.82-2.94,5.65,5.65,0,0,1-.35-1.27,5.83,5.83,0,0,1-.06-1.31h0a6.19,6.19,0,0,1,.57-2,4.57,4.57,0,0,1,1.13-1.56Zm8.16-10.24c-.2,4.79-8.31,4.8-8.5,0-.82-8.21-2.92-29.39-2.85-37.1.07-2.38,2-3.79,4.56-4.33a12.83,12.83,0,0,1,5,0c2.61.56,4.65,2,4.65,4.44v.24L65.73,72.46Z"
                            />
                          </svg>
                        </div>
                        イベントスタッフにより販売が停止されています
                      </span>
                      <Button variant="danger" onClick={() => setIsSellProduct(true)}>
                        販売停止解除
                      </Button>
                    </>
                  )}
                </div> */}
              </Col>
              <Col md="4">
                <div className="mb-4 position-relative">
                  <ECFormGroupDateRange
                    label="ページ公開タイマー"
                    classForm="mb-0"
                    labelMd="12"
                    colMd="12"
                    classLabel="fw-bold"
                    placeholderStart="開始"
                    placeholderEnd="終了"
                    showTimeSelect
                    startDate={normalizeDate(watch('publicStartDate'))}
                    endDate={normalizeDate(watch('publicEndDate'))}
                    onChangeStartDate={(date: any) =>
                      setValue('publicStartDate', dayjs(date).format(DATE_FORMAT_2))
                    }
                    onChangeEndDate={(date: any) =>
                      setValue('publicEndDate', dayjs(date).format(DATE_FORMAT_2))
                    }
                    // startDateDisabled={isDisabledPublicAndSaleDatePicker}
                    startDateDisabled={true}
                    endDateDisabled={true}
                    desc="期間中は商品ページがECサイトで公開中となります"
                    minStartDate={minPublicStartDate}
                    maxStartDate={watch('publicEndDate')}
                    errorStartDate={errors?.publicStartDate?.message}
                    errorEndDate={errors?.publicEndDate?.message}
                  />
                  {isOpenToolTipPublic ? (
                    <div className="position-absolute d-flex align-items-center ec_form_date_range_tooltip-sale_product">
                      <InfoCircleFill style={{ marginRight: '3px' }} color="#005cd9" />
                      公開期間中です
                    </div>
                  ) : null}
                </div>

                <div className="mb-5 position-relative">
                  <ECFormGroupDateRange
                    label="販売制御タイマー"
                    classForm="mb-0"
                    labelMd="12"
                    colMd="12"
                    classLabel="fw-bold"
                    placeholderStart="開始"
                    placeholderEnd="終了"
                    showTimeSelect
                    startDate={normalizeDate(watch('saleStartDate'))}
                    endDate={normalizeDate(watch('saleEndDate'))}
                    onChangeStartDate={(date: any) =>
                      setValue('saleStartDate', dayjs(date).format(DATE_FORMAT_2))
                    }
                    onChangeEndDate={(date: any) => {
                      setValue('saleEndDate', dayjs(date).format(DATE_FORMAT_2));
                      if (watch('drawDate')) {
                        toast('抽選日時をクリアしました。', { type: 'warning' });
                        setValue('drawDate', null);
                      }
                    }}
                    startDateDisabled={true}
                    endDateDisabled={true}
                    // startDateDisabled={isDisabledPublicAndSaleDatePicker}
                    // endDateDisabled={!watch('saleStartDate') || isDisabledPublicAndSaleDatePicker}
                    desc="期間中はECサイトで購入が可能となります"
                    minStartDate={normalizeDate(watch('publicStartDate'))}
                    maxStartDate={maxSaleStartDate}
                    maxEndDate={normalizeDate(watch('publicEndDate'))}
                    errorStartDate={errors?.saleStartDate?.message}
                    errorEndDate={errors?.saleEndDate?.message}
                  />
                  {isOpenToolTipSale ? (
                    <div className="position-absolute d-flex align-items-center ec_form_date_range_tooltip-sale_product">
                      <InfoCircleFill style={{ marginRight: '3px' }} color="#005cd9" />
                      販売期間中です
                    </div>
                  ) : null}
                </div>

                <div>
                  <GroupCheckBox
                    id="bank-acount-type"
                    classForm="mb-3 d-flex align-items-center"
                    labelForm="抽選購入"
                    classLabel="fw-bold lg-fs-12px"
                    classOption="lg-fs-12px lg-pt-3px"
                    options={designateDrawType}
                    labelMd="3"
                    colMd="8"
                    // onChange={onChangeDesignateDraw}
                  />
                </div>

                {watch('designateDraw') ? (
                  <>
                    <div>
                      <FormInputNumber
                        name="maximumDraw"
                        minValue={1}
                        maxValue={999999}
                        maxLength={7}
                        value={watch('maximumDraw')}
                        onChange={(value) => setValue('maximumDraw', value)}
                        labelMd="2"
                        colMd="6"
                        label="上限数"
                        isReview={true}
                        classLabel="fw-bold"
                        errorMessage={errors.maximumDraw?.message}
                      />
                    </div>

                    <div>
                      <FormGroupDate
                        label="抽選日時"
                        classLabel="fw-bold"
                        colMd="6"
                        labelMd="2"
                        disabled
                        minDate={
                          watch('saleEndDate') ? normalizeDate(watch('saleEndDate')) : new Date()
                        }
                        selected={normalizeDate(watch('drawDate'))}
                        onChange={(date: any) => {
                          if (!date) {
                            setValue('drawDate', null);
                            return;
                          }
                          setValue('drawDate', dayjs(date).format(DATE_FORMAT_2));
                        }}
                        dateFormat={DATE_FORMAT_FOR_DATE_PICKER}
                        placeholder="yyyy/mm/dd hh:mm"
                        register={register('drawDate')}
                        errorMessage={errors.drawDate?.message}
                        subContent="販売制御タイマー終了日時以降に設定いただけます"
                        classSubcontent="ec_word_break-keep"
                        // disabled={isReviewTitle('saleTokenDate', variationCurrent[variationIndex])}
                      />
                      {/* <div>販売制御タイマー終了日時以降に設定いただけます</div> */}
                    </div>
                  </>
                ) : null}
              </Col>
              <Col md="4">
                <div className="ec_sale_control_header-sale_product">販売制御設定</div>
                <div className="ec_sale_control_desc-sale_product mb-4">
                  １人のmiimユーザが購入できる数量を制限することができます。
                </div>

                <div className="d-flex">
                  <Form.Check
                    type="checkbox"
                    name="sale_control_check_1"
                    id="sale_control_check_1"
                    label={
                      <div>
                        <div className="ec_sale_control_check_header-sale_product">
                          １ユーザが購入できる最大数を
                        </div>
                        <div className="ec_sale_control_check_desc-sale_product">
                          「お一人様５点まで」のような設定が可能です
                        </div>
                      </div>
                    }
                    // checked={false}
                    className="mb-1 ec_custom-checkbox"
                    checked={watch('isLimitMemberTotalPurchase')}
                    // onChange={() =>
                    //   setValue('isLimitMemberTotalPurchase', !watch('isLimitMemberTotalPurchase'))
                    // }
                  />
                  <div className="d-flex ec_custom-number_format mx-1">
                    <PatternFormat
                      format={'###'}
                      className="form-control rounded position-relative text-center mx-1"
                      value={watch('limitMemberTotalPurchase')}
                      disabled
                      // disabled={!watch('isLimitMemberTotalPurchase')}
                      onValueChange={({ value }) => {
                        if (+value > 0) {
                          setValue('limitMemberTotalPurchase', +value);
                        } else {
                          setValue('limitMemberTotalPurchase', null);
                        }
                      }}
                    />
                  </div>
                  <div className="ec_sale_control_check_header-sale_product">点に制限します。</div>
                </div>

                {errors?.limitMemberTotalPurchase && (
                  <div>
                    <MessageError message={errors?.limitMemberTotalPurchase?.message} />
                  </div>
                )}

                <div className="d-flex mt-4">
                  <Form.Check
                    type="checkbox"
                    name="sale_control_check_2"
                    id="sale_control_check_2"
                    label={
                      <div>
                        <div className="ec_sale_control_check_header-sale_product">
                          一度の決済で購入可能な数量を
                        </div>
                        <div className="ec_sale_control_check_desc-sale_product">
                          カートに入れられる数量を制限します
                        </div>
                      </div>
                    }
                    // checked={false}
                    className="mb-1 ec_custom-checkbox"
                    checked={watch('isLimitTotalOnetimePurchase')}
                    // onChange={() =>
                    //   setValue('isLimitTotalOnetimePurchase', !watch('isLimitTotalOnetimePurchase'))
                    // }
                    // onChange={(e) => setIsShowOnlyMaster(e.target.checked)}
                  />
                  <div className="d-flex ec_custom-number_format mx-1">
                    <PatternFormat
                      format="###"
                      className="form-control rounded position-relative text-center mx-1"
                      value={watch('limitTotalOnetimePurchase')}
                      disabled
                      // disabled={!watch('isLimitTotalOnetimePurchase')}
                      onValueChange={({ value }) => {
                        if (+value > 0) {
                          setValue('limitTotalOnetimePurchase', +value);
                        } else {
                          setValue('limitTotalOnetimePurchase', null);
                        }
                      }}
                    />
                  </div>
                  <div className="ec_sale_control_check_header-sale_product">点に制限します。</div>
                </div>

                {errors?.limitTotalOnetimePurchase && (
                  <div>
                    <MessageError message={errors?.limitTotalOnetimePurchase?.message} />
                  </div>
                )}

                <div className="d-flex mt-4">
                  <Form.Check
                    type="checkbox"
                    name="sale_control_check_3"
                    id="sale_control_check_3"
                    label={
                      <div>
                        <div className="ec_sale_control_check_header-sale_product">
                          前回購入時から
                        </div>
                        <div className="ec_sale_control_check_desc-sale_product">
                          時間的購入制限を行います
                        </div>
                      </div>
                    }
                    // checked={false}
                    className="mb-1 ec_custom-checkbox"
                    checked={watch('isTimeBetweenPurchase')}
                    // onChange={() =>
                    //   setValue('isTimeBetweenPurchase', !watch('isTimeBetweenPurchase'))
                    // }
                    // onChange={(e) => setIsShowOnlyMaster(e.target.checked)}
                  />
                  <div className="d-flex ec_custom-number_format mx-1">
                    <PatternFormat
                      format="###"
                      disabled
                      className="form-control rounded position-relative text-center mx-1"
                      value={watch('timeBetweenPurchase')}
                      // disabled={!watch('isTimeBetweenPurchase')}
                      onValueChange={({ value }) => {
                        if (+value > 0) {
                          setValue('timeBetweenPurchase', +value);
                        } else {
                          setValue('timeBetweenPurchase', null);
                        }
                      }}
                    />
                  </div>
                  <div className="ec_sale_control_check_header-sale_product">
                    分間は購入できないよう制限します。
                  </div>
                </div>

                {errors?.timeBetweenPurchase && (
                  <div>
                    <MessageError message={errors?.timeBetweenPurchase?.message} />
                  </div>
                )}
                {/* 
                <div className="d-flex justify-content-end mt-5">
                  <Button onClick={handleSubmit(onSubmit)}>保存</Button>
                </div> */}
              </Col>
            </Row>

            <Row>
              <Col md="4">
                <BoxForm
                  title={
                    <div className="d-flex align-items-center">
                      <svg
                        className="me-3"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 16 16"
                      >
                        <path
                          id="music-solid_1_"
                          data-name="music-solid (1)"
                          d="M15.972,11.472A2.775,2.775,0,0,1,13,13.972,2.774,2.774,0,0,1,10.028,11.5,2.754,2.754,0,0,1,13,9.028a3.509,3.509,0,0,1,1,.153V4.628L6,7v6.5A2.812,2.812,0,0,1,2.972,16,2.739,2.739,0,0,1,0,13.5a2.754,2.754,0,0,1,2.972-2.472,3.505,3.505,0,0,1,1,.153V3.953A1.064,1.064,0,0,1,4.672,3L14.644.047A1.909,1.909,0,0,1,15,0a1,1,0,0,1,1,1Z"
                          fill="#fff"
                        ></path>
                      </svg>
                      楽曲コンテンツ
                    </div>
                  }
                >
                  <div className="ec_table_header-sale_product">
                    {ECProductDetailData?.titleName} {ECProductDetailData?.variationName}
                  </div>
                  <ECTable
                    tableKey="table-box-song-sale_product"
                    columns={SongColumns}
                    dataSource={ECProductDetailData?.songs}
                  />
                </BoxForm>
              </Col>

              <Col md="4">
                <BoxForm
                  title={
                    <div className="d-flex align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={20}
                        className="me-3"
                        viewBox="0 0 21 14"
                      >
                        <path
                          id="video-solid"
                          d="M12.257,64H1.743A1.743,1.743,0,0,0,0,65.743V76.257A1.743,1.743,0,0,0,1.743,78H12.257A1.743,1.743,0,0,0,14,76.257V65.743A1.743,1.743,0,0,0,12.257,64Zm6.905,1.374-4,2.756v5.739l4,2.753A1.169,1.169,0,0,0,21,75.681V66.315A1.17,1.17,0,0,0,19.162,65.374Z"
                          transform="translate(0 -64)"
                          fill="#fff"
                        />
                      </svg>
                      動画コンテンツ
                    </div>
                  }
                >
                  {ECProductDetailData?.dpMedias?.map((dpMedia: any) => {
                    return (
                      <>
                        <div className="ec_table_header-sale_product">
                          {ECProductDetailData?.titleName} {dpMedia?.mediaName}
                        </div>
                        <ECTable
                          tableKey="table-box-video-sale_product"
                          columns={VideoColumns}
                          dataSource={dpMedia?.videos}
                        />
                      </>
                    );
                  })}
                </BoxForm>
              </Col>

              <Col md="4">
                <BoxForm
                  title={
                    <div className="d-flex align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={20}
                        className="me-3"
                        viewBox="0 0 18.286 16"
                      >
                        <path
                          id="camera-solid"
                          d="M18.286,36V46.286A1.715,1.715,0,0,1,16.571,48H1.714A1.715,1.715,0,0,1,0,46.286V36a1.715,1.715,0,0,1,1.714-1.714H4.857L5.3,33.111A1.712,1.712,0,0,1,6.9,32h4.482a1.712,1.712,0,0,1,1.6,1.111l.443,1.175h3.143A1.715,1.715,0,0,1,18.286,36Zm-4.857,5.143a4.286,4.286,0,1,0-4.286,4.286A4.289,4.289,0,0,0,13.429,41.143Zm-1.143,0A3.143,3.143,0,1,1,9.143,38,3.147,3.147,0,0,1,12.286,41.143Z"
                          transform="translate(0 -32)"
                          fill="#fff"
                        />
                      </svg>
                      画像コンテンツ
                    </div>
                  }
                >
                  <div className="ec_table_header-sale_product"></div>
                  <ECTable
                    tableKey="table-box-video-sale_image"
                    columns={ImageColumns}
                    dataSource={ECProductDetailData?.dpMediaImages}
                  />
                </BoxForm>
              </Col>
            </Row>
          </div>
        </BoxForm>

        <BoxForm title="キャンペーン情報" classBox="mt-4">
          <>
            <CalendarSwipper
              campaignRelations={{
                ...campaignRelations,
                dates: isEmpty(handleGetMonths(campaignRelations))
                  ? defaultDates
                  : handleGetMonths(campaignRelations),
              }}
            />

            {campaignRelations?.events?.length ? (
              <div className="mt-2 d-flex">
                <div className="me-4">
                  {campaignRelations?.events.map((event) => (
                    <div key={event.title} className="mb-1 d-flex">
                      <div className="d-flex align-items-center">
                        <div
                          className="ec_campaignEvent-sale_product me-4"
                          style={{ background: event.backgroundColor }}
                        ></div>
                        <div className="me-3">{event.title}</div>
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  {campaignRelations?.events.map((event) => (
                    <div key={event.title} className="mb-1 d-flex">
                      <div>
                        {dayjs(event.start).format(DATE_FORMAT_21)} -{' '}
                        {dayjs(event.end).format(DATE_FORMAT_21)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </>
        </BoxForm>

        {ECProductDetailData ? (
          <BoxForm title="商品詳細特記事項" classBox="mt-4 mb-4">
            <div className="w-100">
              <div className="ec_table_header-sale_product mb-2">商品詳細上部に表示する記載</div>
              <Row className="w-100">
                <ECCkeditor
                  initData={ECProductDetailData?.aboveDetailNote}
                  // onEditorChange={onAboveDetailNoteChange}
                  disabled={true}
                  name="aboveDetailNote"
                />
              </Row>
            </div>

            <div className="w-100 mt-4">
              <div className="ec_table_header-sale_product mb-2">商品詳細下部に表示する記載</div>
              <Row className="w-100">
                <ECCkeditor
                  initData={ECProductDetailData?.belowDetailNote}
                  disabled={true}
                  // onEditorChange={onBelowDetailNoteChange}
                  name="belowDetailNote"
                />
              </Row>
            </div>

            {/* <div className="d-flex justify-content-center mt-4">
              <Button onClick={handleSubmit(onSubmit)}>保存</Button>
            </div> */}
          </BoxForm>
        ) : null}
      </Form>
    </div>
  );
}
