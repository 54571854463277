import React from 'react';
import TableCommon from 'Components/Page/Campaign/Detail/TableCommon';

import {
  columnId,
  columnInfo,
  columnIsIncentive,
  columnName,
  columnType,
  columnUserQuantity,
  columnWinRate,
} from './ColumnPrizes';

const PrizesTable = ({
  dataSource,
  showWinRate = false,
}: {
  dataSource: any;
  titleName?: string;
  showWinRate?: boolean;
}): JSX.Element => {
  const headerTables1 = [
    {
      columns: [
        columnId,
        columnName,
        columnType,
        columnUserQuantity,
        columnWinRate,
        columnIsIncentive,
        columnInfo,
      ],
    },
  ];

  const headerTables2 = [
    {
      columns: [
        columnId,
        columnName,
        columnType,
        columnUserQuantity,
        columnIsIncentive,
        columnInfo,
      ],
    },
  ];
  return (
    <div>
      <TableCommon
        headerTables={showWinRate ? headerTables1 : headerTables2}
        dataSource={dataSource}
      />
    </div>
  );
};

export default PrizesTable;
