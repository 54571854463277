export enum OriginalType {
  Domestic,
}

export enum IVTType {
  SongOnly,
  SongAndLyrics,
  LyricsOnly,
}
export enum ShowType {
  Studio,
  Live,
}
export enum ContentType {
  No,
  Yes,
}
export enum MedleyType {
  No,
  Yes,
}
export enum CollectCode {
  No,
  Yes,
  Change,
}
export enum LyricsClassify {
  Original,
  Translated,
  Unknown,
}

export type Step = string;

export interface Title {
  [key: string]: any;
  artistId: number;
  name: string;
  type: string | null;
  label: string;
  variationList: Array<{
    variationName: string;
    songQuantity: number | null;
    productionCode: string;
    openDate: string;
    openTime: string;
    preorderDate: string;
    preorderTime: string;
    JANCode: string;
    price: number | null;
    eventTokuten: number | null;
    totalSale: number | null;
    thumbImage: string;
    description: string;
    tokutenTrading: number | null;
    lyricCard: string;
    musicArray: Array<{
      index: number | null;
      name: string;
      furiganaName: string;
      englishName: string;
      musicianName: string;
      musicianFuriganaName: string;
      musicianEnglishName: string;
      composerName: string;
      composerFuriganaName: string;
      composerEnglishName: string;
      arrangerName: string;
      arrangerFuriganaName: string;
      arrangerEnglishName: string;
      showTime: string;
      type: {
        originalType: OriginalType;
        IVTType: IVTType;
        showType: ShowType;
        authoziredGroup: string;
        ISRCCode: string;
        contentType: ContentType;
        contentCode: string;
        medleyType: MedleyType;
        medleyCode: string;
        lyricsClassify: LyricsClassify;
        collectCode: CollectCode;
        JASRACCode: string;
        otherCode: string;
        informationFee: number | null;
        sourceAudioUrl: string;
      };
    }>;
  }>;
}

export enum CapacityTypes {
  Image = 'Image',
  Video = 'Video',
  Song = 'Song',
  Thumbnail = 'Thumbnail',
  Booklet = 'Booklet',
}

export type Capacity = {
  used: number;
  type: CapacityTypes;
  index: number | string;
};
