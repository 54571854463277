/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-irregular-whitespace */
import Filter from 'Components/Common/Filter/Filter';
import PaginationComponent from 'Components/Common/Pagination/Pagination';
// import { SortMethod } from 'Components/Common/Table/table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Container, Row, Spinner } from 'react-bootstrap';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import PurchaseSummaryDetailBox from './PurchaseSummaryDetailBox';
// import TableCommon from 'Components/Common/Table/TableCommon';
// import { Cell } from 'react-table';
import { ECApis } from 'Datasource/EC';
import { Link, useParams } from 'react-router-dom';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import useMessage from 'Hooks/useMessage';
import dayjs from 'dayjs';
import useErrorBlob from 'Hooks/useErrorBlob';
import AliTable, { AliColumn, AliSortType } from 'Components/Common/Table/AliTable';
import { COL_END_CLASS, COL_START_CLASS } from 'Constant';
import useTitlePage from 'Hooks/useTitlePage';
import { exportExcel } from 'Utils/File';
import { formatDecimal, formatPrice } from 'Utils/Numbers';
import { round } from 'lodash';
// import { SortMethod } from 'Components/Common/Table/table';
// import { useLocation } from 'react-router-dom';

// interface IValue {
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   [key: string]: any;
// }

const ITEM_LIMIT = 20;

export default function PaymentNotificationDetail(): JSX.Element {
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    queryArtist: StringParam,
    queryTitle: StringParam,
    artistId: StringParam,
    titleId: StringParam,
    campaignId: StringParam,
    statusId: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    type: StringParam,
    sort: StringParam,
    order: StringParam,
    date: StringParam,
    code: StringParam,
    companyName: StringParam,
  });
  const { setMenuTitle } = useTitlePage();
  const { openErrorBlob } = useErrorBlob();
  const [activePage, setActivePage] = useState(query.page ?? 1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalItem, setTotalItem] = useState(100);
  const [dataDetailDate, setDataDetailDate] = useState<any>();
  const [dataDetailCode, setDataDetailCode] = useState<any>();
  const [companyName, setCompanyName] = useState<any>('');
  const [totalTax, setTotalTax] = useState<any>();
  const [paymentDateRange, setPaymentDateRange] = useState<any>('');
  const [paymentDate, setPaymentDate] = useState<any>('');
  const [detailBoxData, setDetailBoxData] = useState<any>();
  const [detailListData, setDetailListData] = useState<any>();
  const { openMessageError } = useMessage();
  const [exporting, setExporting] = useState<boolean>(false);

  const { id }: { id: string } = useParams();

  const columnDetail = useMemo(
    () =>
      [
        {
          name: '品番',
          width: 60,
          code: 'variationProductCode',
          features: { sortable: true },
          lock: true,
          align: 'center',
          render: (value: any) => {
            return <div className={`${COL_START_CLASS} text-center`}>{value}</div>;
          },
        },
        {
          name: 'アーティスト',
          width: 80,
          code: 'artistName',
          features: { sortable: true },
          lock: true,
          align: 'center',
          render: (value: any) => {
            return <div className={`${COL_START_CLASS}`}>{value}</div>;
          },
        },
        {
          name: 'タイトル',
          width: 80,
          code: 'titleName',
          features: { sortable: true },
          lock: true,
          align: 'center',
          render: (value: any, row: any) => {
            return (
              <Link to={{ pathname: `/detail-title/${row.titleId}` }}>
                <div className={`${COL_START_CLASS}`}>{value}</div>
              </Link>
            );
          },
        },
        {
          name: 'バリエーション',
          width: 80,
          code: 'variationName',
          features: { sortable: true },
          lock: true,
          align: 'center',
          render: (value: any) => {
            return <div className={`${COL_START_CLASS}`}>{value}</div>;
          },
        },
        {
          name: '販売価格（税別）',
          width: 40,
          code: 'price',
          features: { sortable: true },
          lock: true,
          align: 'center',
          render: (value: any) => {
            return <div className={`${COL_END_CLASS}`}>{value && formatPrice(value)}</div>;
          },
        },
        {
          name: '料金種別',
          width: 50,
          code: 'feeName',
          lock: true,
          align: 'center',
          render: (value: any) => {
            return <div className={`${COL_START_CLASS}`}>{value && formatPrice(value)}</div>;
          },
        },
        {
          name: '数量',
          width: 35,
          code: 'totalAmount',
          features: { sortable: true },
          lock: true,
          align: 'center',
          render: (value: any) => {
            return <div className={`${COL_END_CLASS}`}>{value && formatPrice(value)}</div>;
          },
        },
        {
          name: '定額料金',
          align: 'center',
          width: 80,
          children: [
            {
              name: '単価',
              width: 35,
              code: 'optionCardBuyFee',
              align: 'center',
              render: (value: any) => {
                return <div className={`${COL_END_CLASS}`}>{value ? formatPrice(value) : ''}</div>;
              },
            },
            {
              name: '金額',
              width: 45,
              code: 'buyFeeMoney',
              align: 'center',
              render: (value: any) => {
                return <div className={`${COL_END_CLASS}`}>{value ? formatPrice(value) : ''}</div>;
              },
            },
          ],
        },
        {
          name: '定率料金',
          align: 'center',
          width: 115,
          children: [
            {
              name: '定率',
              width: 40,
              code: 'unitTaxFee',
              align: 'center',
              render: (value: any) => {
                return (
                  <div className={`${COL_END_CLASS}`}>
                    {value ? `${formatDecimal(value, 1)}%` : ''}
                  </div>
                );
              },
            },
            {
              name: '単価',
              width: 35,
              code: 'optionTaxFee',
              align: 'center',
              render: (value: any) => {
                return <div className={`${COL_END_CLASS}`}>{value ? formatPrice(value) : ''}</div>;
              },
            },
            {
              name: '金額',
              width: 45,
              code: 'taxFeeMoney',
              align: 'center',
              render: (value: any) => {
                return <div className={`${COL_END_CLASS}`}>{value ? formatPrice(value) : ''}</div>;
              },
            },
          ],
        },
        {
          name: 'オプション料金',
          align: 'center',
          width: 80,
          children: [
            {
              name: '単価',
              width: 35,
              code: 'snFee',
              align: 'center',
              render: (value: any) => {
                return <div className={`${COL_END_CLASS}`}>{value != 0 ? value : ''}</div>;
              },
            },
            {
              name: '金額',
              width: 45,
              code: 'snFeeMoney',
              align: 'center',
              render: (value: any) => {
                return <div className={`${COL_END_CLASS}`}>{value != 0 ? value : ''}</div>;
              },
            },
          ],
        },
        {
          name: '原価合計',
          width: 40,
          code: 'totalMoney',
          lock: true,
          align: 'center',
          render: (value: any) => {
            return <div className={`${COL_END_CLASS}`}>{value && formatPrice(value)}</div>;
          },
        },
      ] as AliColumn[],
    [],
  );

  const fetchPaymentDetail = async () => {
    setIsLoading(true);
    try {
      const response = await ECApis.getPaymentMonthlyDetail(
        id,
        query.page,
        ITEM_LIMIT,
        query.date,
        query.order,
        query.sort,
      );
      const { data } = response.data;
      const cardBuyFeeTotalArr = Object.entries(data.amountCardBuyFeeTotal);
      const optionFeeTotalArr = Object.entries(data.amountOptionFeeTotal);
      const taxFeeMoneyTotalArr = Object.entries(data.amountTaxFeeMoneyTotal);
      const amountTotalArr = Object.entries(data.amountTotal);
      const detailBoxArr = data.companyOptions;
      setTotalTax(round(data.total * 1.1));
      setPaymentDate(data.dateOfPayment);
      setPaymentDateRange(data.targetPeriod);

      detailBoxArr.map((item: any) => {
        cardBuyFeeTotalArr.map((key) => {
          if (key[0] == item.optionId) {
            item.amountCardBuyFeeTotal = key[1];
          }
        });
        optionFeeTotalArr.map((key) => {
          if (key[0] == item.optionId) {
            item.amountOptionFeeTotal = key[1];
          }
        });
        taxFeeMoneyTotalArr.map((key) => {
          if (key[0] == item.optionId) {
            item.amountTaxFeeMoneyTotal = key[1];
          }
        });
        amountTotalArr.map((key) => {
          if (key[0] == item.optionId) {
            item.amountTotal = key[1];
          }
        });
        if (item.amountCardBuyFeeTotal != undefined && item.amountTaxFeeMoneyTotal != undefined) {
          item.totalFee = item.amountCardBuyFeeTotal + item.amountTaxFeeMoneyTotal;
        }
      });
      const amountCardBuyFeeTotalSum = detailBoxArr.reduce((initialValue: any, value: any) => {
        if (value.amountCardBuyFeeTotal != undefined) {
          return initialValue + value.amountCardBuyFeeTotal;
        } else {
          return initialValue;
        }
      }, 0);

      const amountTaxFeeMoneyTotalSum = detailBoxArr.reduce((initialValue: any, value: any) => {
        if (value.amountTaxFeeMoneyTotal != undefined) {
          return initialValue + value.amountTaxFeeMoneyTotal;
        } else {
          return initialValue;
        }
      }, 0);

      const detailBoxCdSerial = [
        {
          titleType: 'CDシリアル出力',
          amountTotal: data.amountTotal.CDシリアル出力,
          amountOptionFeeTotal: data.amountOptionFeeTotal.CDシリアル出力,
          totalFee: data.amountOptionFeeTotal.CDシリアル出力,
          fee: data.snFee,
        },
        {
          titleType: 'CDシリアル読取り',
          amountTotal: data.amountTotal.CDシリアル読取り,
          amountOptionFeeTotal: data.amountOptionFeeTotal.CDシリアル読取り,
          totalFee: data.amountOptionFeeTotal.CDシリアル読取り,
          fee: data.snImportFee,
        },
      ];

      detailBoxCdSerial.map((item: any) => {
        detailBoxArr.push(item);
      });

      const amountOptionFeeTotalSum = detailBoxCdSerial.reduce((prevVal: any, currVal: any) => {
        return currVal.amountOptionFeeTotal ? prevVal + currVal.amountOptionFeeTotal : prevVal;
      }, 0);
      const detailBoxTotal = {
        titleType: '合計',
        amountCardBuyFeeTotal: amountCardBuyFeeTotalSum,
        amountTaxFeeMoneyTotal: amountTaxFeeMoneyTotalSum,
        totalFee: amountCardBuyFeeTotalSum + amountTaxFeeMoneyTotalSum + amountOptionFeeTotalSum,
        amountOptionFeeTotal: amountOptionFeeTotalSum,
      };
      detailBoxArr.push(detailBoxTotal);

      setTotalItem(data?.paginateData?.total);
      setDetailBoxData(detailBoxArr);
      setDetailListData(data.items);
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadExcel = async (companyId: string) => {
    const formatDate = dayjs(query.date).format('YYMM');

    try {
      setExporting(true);
      await exportExcel(
        ECApis.paymentMonthyExportExcel(companyId, query.date),
        `${formatDate}_請求明細_${query.companyName?.replaceAll(' ', '_')}`,
      );
    } catch (error) {
      openErrorBlob(error);
    } finally {
      setExporting(false);
    }
  };

  useEffect(() => {
    setMenuTitle('レコード会社請求明細');
    fetchPaymentDetail();
  }, [query.page, query.sort, query.order]);

  useEffect(() => {
    if (query.date) {
      const dateArr = query.date.split('-');
      const formatDate = `${dateArr[0]}年${dayjs(dateArr[1]).format('MM')}月`;
      setDataDetailDate(formatDate);
      setDataDetailCode(query.code);
      setCompanyName(query.companyName);
    }
  }, []);

  // const onSort = () => {
  //   setQuery({ ...query, order: 'ASC' });
  // };

  const onSort = useCallback(
    (sortBy: AliSortType[]) => {
      if (sortBy.length > 0) {
        const sortOption = sortBy[0];
        setQuery({ sort: sortOption.code, order: sortOption.order === 'desc' ? 'DESC' : 'ASC' });
      } else {
        setQuery({ sort: '', order: '' });
      }
    },
    [setQuery],
  );

  return (
    <Container fluid>
      <SpinnerComponent isLoading={isLoading} />
      <Filter filterClass={`shadow-sm d-flex justify-content-between`}>
        <div className="font-bold line-35">
          {dataDetailDate} 【{companyName}】　　　　　　伝票番号　{dataDetailCode}
          　　　　　　発行日　
          {paymentDateRange.substr(-11, 11)}
        </div>
        <div className="d-flex">
          <Button
            onClick={() => handleDownloadExcel(id)}
            className={`pl-40px prize-btn-excel pr-40px ${exporting ? 'pe-none' : ''}`}
            variant="primary"
          >
            {exporting ? (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'EXCELダウンロード'
            )}
          </Button>
        </div>
      </Filter>
      <div>
        {detailBoxData && (
          <PurchaseSummaryDetailBox
            tableData={detailBoxData}
            paymentDate={paymentDate}
            paymentDateRange={paymentDateRange}
            totalTax={totalTax}
          />
        )}
      </div>
      <Row className="space-between mt-3 ml-30px mr-30px">
        <div className="w-100 pr-0px">
          <PaginationComponent
            activePage={activePage}
            total={totalItem}
            perPage={ITEM_LIMIT}
            onClick={(newPage) => setActivePage(newPage)}
          />
        </div>
      </Row>
      <Row className="w-98 mt-3 ml-18px mr-30px overflow-x-scroll">
        {detailListData && (
          <AliTable
            wrapperClass="payment-notification-table"
            heightBody="auto"
            dataSource={detailListData}
            columns={columnDetail}
            onSort={onSort}
          ></AliTable>
        )}
      </Row>
    </Container>
  );
}
