type PageType = number | null | undefined;

const countNo = (index: number, page: PageType, limit = 50): number => {
  const cPage = page ? page : 1;
  const count = (cPage - 1) * limit + index + 1;
  return count;
};

const getMusicianName = (row: any): string => {
  const { musicianName, contentType, contentCode } = row.original;
  let text = '';
  if (contentType !== 'Q') text = musicianName;
  if (contentType === 'Q' && contentCode === '000') text = '*';
  if (contentType === 'Q' && contentCode !== '000') text = musicianName;
  return text;
};

const getComposerName = (row: any): string => {
  const { composerName, contentType, contentCode } = row.original;
  let text = '';
  if (contentType !== 'Q') text = composerName;
  if (contentType === 'Q' && contentCode === '000') text = '*';
  if (contentType === 'Q' && contentCode !== '000') text = composerName;
  return text;
};

const getInformationFee = (row: any, mediaType = '歌詞'): string => {
  const { informationFee, contentType, contentCode, lyricInformationFee } = row.original;
  const fee = new Intl.NumberFormat('en-US', {
    currency: 'USD',
  });
  return contentType === 'Q' && contentCode === '000'
    ? ''
    : fee.format(mediaType === '歌詞' ? lyricInformationFee : informationFee);
};

const getIvtType = (row: any): string => {
  const { ivtType, contentType, contentCode } = row.original;
  return contentType === 'Q' && contentCode === '000' ? '' : ivtType;
};

const getRequestCount = (row: any): string => {
  const { requestCount, contentType, contentCode } = row.original;
  return contentType === 'Q' && contentCode === '000' ? '' : requestCount;
};

const getOtherCode = (row: any): string => {
  const { otherCode } = row.original;
  return otherCode;
};
export {
  countNo,
  getMusicianName,
  getComposerName,
  getInformationFee,
  getIvtType,
  getRequestCount,
  getOtherCode,
};
