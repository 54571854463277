import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { InputEvent } from 'Components/Common/Form/form';
import MessageError from 'Components/Common/MessageError';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';
import { labelRequire } from 'Components/Common/Form/FormLabel';
import { InputProps } from 'Components/Common/Form/FormGroupInput/input';
import { MAX_INPUT } from 'Constant';

export default function FormGroupInputEc(props: InputProps): JSX.Element {
  const {
    label,
    subLabel,
    classForm,
    leftTextClass,
    hintTextClass,
    hintTextDesClass,
    errorMessage,
    placeholder,
    labelMd,
    colMd,
    value,
    register,
    onChange,
    onBlur,
    hintText,
    hintTextDes,
    leftText,
    disabled = false,
    isReview = false,
    maxLength = MAX_INPUT,
    type = 'text',
    errMessageCol,
    messageWrapper,
    classInput,
    onKeyPress,
    classCol,
  } = props;

  const handleOnchange = (e: InputEvent) => {
    if (onChange && !isReview) {
      onChange(e.target.value);
    }
  };

  return (
    <Form.Group as={Row} className={`mb-3 ${classForm}`}>
      <Form.Label column md={labelMd}>
        {labelRequire(label)}
        {<p className="fs-12">{subLabel}</p>}
      </Form.Label>
      <Col md={colMd} className={classCol}>
        {leftText && <div className={`${leftTextClass}`}>{leftText}</div>}
        <Form.Control
          {...register}
          value={value ?? ''}
          onChange={handleOnchange}
          onBlur={onBlur}
          type={type}
          placeholder={placeholder}
          disabled={disabled || isReview}
          maxLength={maxLength}
          className={classInput}
          autoComplete="off"
          onKeyPress={onKeyPress}
        />
        {hintText && (
          <div className={`fs-12 ${hintTextClass}`}>
            {hintText}
            {hintTextDes && (
              <span className={`${hintTextDesClass}`}>
                {hintTextDes == '入力した支店コードが一致する支店名は存在しません。' ||
                hintTextDes == '入力した銀行コードが一致する銀行名は存在しません。' ? (
                  <ExclamationTriangleFill className="mr-6px mb-2px" />
                ) : (
                  ''
                )}
                {hintTextDes}
              </span>
            )}
          </div>
        )}
      </Col>
      <Col md={errMessageCol}>
        <MessageError classWrapper={messageWrapper} message={errorMessage} />
      </Col>
    </Form.Group>
  );
}
