import Search from 'Components/Common/Search/Search';
import useSuggestion from 'Hooks/useSuggestion';
import React from 'react';
import { Col } from 'react-bootstrap';

export default function JasacSearch(): JSX.Element {
  const {
    companyOptions,
    loadingCompany,
    handleLoadMore,
    handleFocusInput,
    handleEnterPrimary,
    handleClickItemPrimary,
  } = useSuggestion({
    fetchList: ['company'],
  });

  return (
    <>
      <Col md={4} className="ps-0 pe-3 company-search-report">
        <Search
          label="レコード会社"
          labelMd="4"
          colMd="8"
          placeholder="検索"
          showIcon
          classLabel="px-0 artist-label"
          rowClass="w-100"
          colClass="px-0"
          searchKey="queryCompany"
          onEnter={() => handleEnterPrimary('queryCompany')}
          onClickResult={handleClickItemPrimary}
          options={companyOptions.options || []}
          loading={loadingCompany}
          onLoadMore={() => handleLoadMore('pageCompany')}
          onFocusInput={() => {
            handleFocusInput('queryCompany', 'pageCompany');
          }}
        />
      </Col>
    </>
  );
}
