import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import PaginationComponent from 'Components/Common/Pagination/Pagination';
import { PAGE_SIZE } from 'Constant';
import useMessage from 'Hooks/useMessage';
// import { RecordCompanyApi } from 'Datasource/RecordCompany';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { Cell } from 'react-table';
// import NumberFormat from 'react-number-format';
import TableCommon from 'Components/Common/Table/TableCommon';
import { Link } from 'react-router-dom';
import { StringParam, useQueryParams } from 'use-query-params';
import { SortMethod } from 'Components/Common/Table/table';
import { SaleChannelApi } from 'Datasource/SaleChannelApi';
import { formatFixedDecimalAndNumb } from 'Utils/Numbers';

interface IValue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export default function TableList(): JSX.Element {
  const { openMessageError } = useMessage();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<any>(1);
  const [listRecord, setListRecord] = useState<IValue>([]);
  const [totalItems, setTotalItems] = useState(0);

  const [query, setQuery] = useQueryParams({
    page: StringParam,
    sort: StringParam,
    order: StringParam,
  });

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'pe-none',
        columns: [
          {
            Header: '契約会社',
            accessor: 'companyName',
            className: 'company-name',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <Link
                  to={`/record-company/${row.original.companyId}?role=${row.original.companyType}`}
                >
                  <div className="text-truncate text-start" title={row.original.companyName}>
                    {row.original?.companyName}
                  </div>
                </Link>
              );
            },
          },
          {
            Header: '契約種別',
            accessor: 'companyType',
            width: '150',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div className="text-truncate">
                  {row.original?.companyType == 'RECORD_COMPANY'
                    ? 'レコード会社'
                    : row.original?.companyType == 'SALE_CHANNEL_COMPANY'
                    ? '外部EC'
                    : row.original?.companyType == 'EC_COMPANY'
                    ? 'miim-EC'
                    : '-'}
                </div>
              );
            },
          },
          {
            Header: 'PF利用料',
            accessor: 'platformUsageFee',
            width: '150',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div className="text-truncate">
                  {row.original?.companyType != 'RECORD_COMPANY'
                    ? `${formatFixedDecimalAndNumb(row.original?.platformUsageFee || 0, 2)}%`
                    : '-'}
                </div>
              );
            },
          },
          {
            Header: 'ステータス',
            accessor: 'status',
            width: '150',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div className="text-truncate" title={row.original?.status}>
                  {row.original?.status == 0
                    ? 'システム対応中'
                    : row.original?.status == 1
                    ? '稼働中'
                    : '-'}
                </div>
              );
            },
          },
          {
            Header: 'チャネル名',
            accessor: 'channelName',
            width: '150',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                // <NumberFormat
                //   displayType="text"
                //   value={row.original?.channelName ? row.original?.channelName : '-'}
                //   format="###-####-####"
                //   className="pt-1 ps-3 border-0 pe-none"
                // />
                <div className="text-truncate" title={row.original?.channelName}>
                  {row.original?.channelName ? row.original?.channelName : '-'}
                </div>
              );
            },
          },
          {
            Header: 'サイト名',
            accessor: 'siteName',
            width: '250',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <Button
                  variant={`${row.original?.siteName ? 'link' : ''}`}
                  aria-hidden="true"
                  className={`${row.original?.siteName ? 'link-primary' : ''}`}
                  href={`${row.original?.siteUrl}`}
                  target="_blank"
                >
                  {row.original?.siteName ? row.original?.siteName : '-'}
                </Button>
              );
            },
          },
        ],
      },
    ],
    [],
  );

  useEffect(() => {
    getListRecord();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.page, query.sort, query.order]);

  const getListRecord = async () => {
    setIsLoading(true);
    try {
      const res = await SaleChannelApi.getSaleAccount({
        page: query.page || page,
        limit: PAGE_SIZE,
        sort: query.sort || '',
        order: query.order || '',
      });
      const { data } = res.data;
      setListRecord(data.items);
      setTotalItems(data.meta.itemCount);
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onChangePage = async (pageChange: number) => {
    setPage(pageChange);
  };

  //* handle sort from server side
  const handleManualSort = useCallback(
    (sortBy: SortMethod[]) => {
      if (sortBy.length > 0) {
        const sortOption = sortBy[0];
        setQuery({ sort: sortOption.id, order: sortOption.desc ? 'DESC' : 'ASC' });
      } else {
        setQuery({ sort: '', order: '' });
      }
    },
    [setQuery],
  );

  return (
    <div className="overflow-hidden">
      <Container fluid className="px-4">
        <SpinnerComponent isLoading={isLoading} />
        <Row>
          <Col md={12} className="d-flex justify-content-end p-0">
            <PaginationComponent
              activePage={page}
              total={totalItems}
              perPage={PAGE_SIZE}
              onClick={(pageChange) => onChangePage(pageChange)}
            />
          </Col>
        </Row>
        <div className="record-company-table">
          <TableCommon
            heightBody="calc(100vh - 240px)"
            columns={columns}
            data={listRecord}
            getHeaderProps={() => {
              return {
                className: 'text-white d-flex align-item-center bg-dark',
              };
            }}
            getColumnProps={() => {
              return {
                className: 'text-center',
              };
            }}
            getRowProps={() => {
              return {
                style: {},
              };
            }}
            getCellProps={() => {
              return {
                className: 'bg-white text-dark line-35',
              };
            }}
            manualSortBy
            onSort={handleManualSort}
          />
        </div>
      </Container>
    </div>
  );
}
