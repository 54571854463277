import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import queryString from 'query-string';

export interface IQueryParam {
  page?: number;
  artistSearch?: number | string;
  variationSearch?: number | string;
  titleSearch?: number | string;
  companySearch?: number | string;
  dateSearch?: string;
  artistSelected?: number | string;
  variationSelected?: number | string;
  titleSelected?: number | string;
  companySelected?: number | string;
  perPage?: number;
}

function isJSON(str: string) {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
}

export const addQueryParams = (url: any, query: any) => {
  if (url) {
    let search = '?';
    for (const [key, value] of Object.entries(query)) {
      if (Array.isArray(value)) {
        search = `${search}${key}=${JSON.stringify(value)}&`;
      } else if (value === '') {
        search = `${search}${key}=&`;
      } else if (value || value === 0) {
        search = `${search}${key}=${value}&`;
      }
    }
    return url + search;
  }
  return '';
};

export const getParamsFromQueryString = (search: any) => {
  const params = queryString.parse(search);
  const result: any = {};
  for (const [key, value] of Object.entries(params)) {
    const newValue: any = value;
    if (key) {
      if (isJSON(newValue)) {
        result[key] = JSON.parse(newValue);
      } else {
        result[key] =
          /^\d+$/.test(newValue) && newValue[0] != 0 ? parseInt(newValue, 10) : newValue;
      }
    }
  }
  return result;
};

export const getUserGMT = () => dayjs().utcOffset() / 60;

export const transformQuery = (params: any): string => {
  let strParam = '';
  if (!isEmpty(params)) {
    strParam = Object.entries(params)
      .filter(([, val]: any) => !!val || val === 0)
      .map(([key, val]: any) => `${key}=${encodeURIComponent(val)}`)
      .join('&');
    strParam = `?${strParam}`;
  }
  return strParam;
};
