import brAxios from 'Api/Interceptors';

interface QueryParams {
  page?: number;
  limit?: number;
  query?: string;
  titleId?: string;
  artistId?: string;
  channelId?: string;
  status?: number | null;
  cardCD?: string;
  productId?: string;
  params?: any;
  companyId?: string;
  isMaster?: string;
  sort?: string;
  order?: string;
  showAll?: number;
}

export const ProductsApi = {
  getCards: (params: QueryParams) => brAxios.get('/bootrock-company/cards', { params }),
  downloadExcel: (params: any) => {
    const response = brAxios.get(`/bootrock-company/cards/export/download`, {
      params,
      responseType: 'blob',
    });
    return response;
  },
  getSaleChannels: ({
    titleId,
    artistId,
    productId,
    companyId,
    page,
    limit,
    query,
    showAll,
  }: QueryParams) => {
    const response = brAxios.get(
      `/suggestion/sale-channels?titleId=${titleId ?? ''}&artistId=${artistId ?? ''}${
        productId ? `&productId=${productId}` : ''
      }${companyId ? `&companyId=${companyId}` : ''}&page=${page ?? ''}&limit=${
        limit ?? ''
      }&query=${query ?? ''}&showAll=${showAll ?? 0}`,
    );
    return response;
  },
  getProductInfo: ({ titleId }: QueryParams) => {
    const response = brAxios.get(
      `/bootrock-company/campaigns/product-info?titleId=${titleId ?? ''}`,
    );
    return response;
  },
  getProcessManagement: ({ titleId, artistId, companyId }: QueryParams) => {
    const response = brAxios.get(
      `/bootrock-company/process-management?titleId=${titleId ?? ''}&artistId=${
        artistId ?? ''
      }&companyId=${companyId ?? ''}`,
    );
    return response;
  },
  getCampaignTitle: ({ titleId }: QueryParams) => {
    const response = brAxios.get(`/bootrock-company/campaigns/title/${titleId ?? ''}`);
    return response;
  },
};
