import React from 'react';
import TableCommon from 'Components/Page/Campaign/Detail/TableCommon';
const CLASS_NAME = 'text-center align-middle';

const BlackListUsersTable = ({ dataSource }: { dataSource: any }): JSX.Element => {
  const headerTables = [
    {
      columns: [
        {
          className: CLASS_NAME,
          text: 'ユーザーID',
          width: 100,
          dataIndex: 'userId',
        },
        {
          className: CLASS_NAME,
          text: '対象者',
          width: 100,
          dataIndex: 'userName',
        },
      ],
    },
  ];
  return (
    <div>
      <TableCommon headerTables={headerTables} dataSource={dataSource} />
    </div>
  );
};

export default BlackListUsersTable;
