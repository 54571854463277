import React, { useEffect } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
// import { find } from 'lodash';
// import { useQueryParams, StringParam, NumberParam } from 'use-query-params';
import { TabProps } from 'Components/Common/Tabs/tabs-types';

// interface BtnOptionType {
//   name: string;
//   value?: number | string;
// }

export default function Tabs({
  tabActive,
  groupClass,
  btnClass,
  options,
  onChange,
  redirect,
  disabledSetQuery,
}: // indexDefault,
// clickOnly = false,
TabProps): JSX.Element {
  const history = useHistory();
  // const [query, setQuery] = useQueryParams({
  //   status: StringParam,
  //   page: NumberParam,
  // });
  // const [active, setActive] = useState(tabActive);
  // const activeClass = 'bg-dark text-white';
  const onchangeTab = (tab: string) => {
    // setActive(tab);
    if (onChange) onChange(tab);
    if (redirect) history.push(redirect);
  };

  // const handleSetQueryParam = (option: BtnOptionType) => {
  //   if (disabledSetQuery) return;
  //   setQuery({ status: option.name, page: 1 });
  // };

  // useEffect(() => {
  //   if (disabledSetQuery) return;
  //   if (!clickOnly) {
  //     const status = query.status ?? '';
  //     let option = find(options, (option) => option.name === status);
  //     if (!option) {
  //       option = options[indexDefault || 0];
  //     }
  //     onchangeTab(option?.name ?? '');
  //   }

  // }, [query.status, clickOnly]);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (disabledSetQuery) {
      onchangeTab(tabActive ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabActive]);

  return (
    <>
      <ButtonGroup className={groupClass} aria-label="basic-tab">
        <Button
          variant="primary"
          className={`border ${btnClass ?? ''}`}
          onClick={() => {
            history.push('/register-record-company');
            // onchangeTab(options[1].name);
            // handleSetQueryParam(options[1]);
          }}
          // disabled={option.disabled}
        >
          {options[1].name}
        </Button>
      </ButtonGroup>
    </>
  );
}
