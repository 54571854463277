import React, { useMemo } from 'react';

import { TableGroup, TableColumns } from 'Components/Common/Table/TableGroup';
import dayjs from 'dayjs';

import { DATE_FORMAT_11, DATE_FORMAT_12 } from 'Constant/Date';
import 'dayjs/locale/ja';
import StastiticRow from 'Components/Page/Campaign/StatisticCampaign/StastiticRow';
import { RealTimeInit } from 'Types/Page/Campaign/Campaign';

interface TableProps {
  dataSource: RealTimeInit[];
}

export default function RealtimeInitTable({ dataSource }: TableProps): JSX.Element {
  const dayOfMonth = useMemo(() => {
    const dateList = dataSource.map((item) => item.date);
    return dateList.map((day) => ({
      text: dayjs(day).format(DATE_FORMAT_12),
      colSpan: 1,
    }));
  }, [dataSource]);

  const getColClass = (day: string) => {
    let classCol = 'bg-smoke';
    if (day === '土') {
      classCol = 'satColor';
    }
    if (day === '日') {
      classCol = 'sunColor';
    }
    return classCol;
  };
  const dayOfWeek = useMemo(() => {
    const dateList = dataSource.map((item) => item.date);
    return dateList.map((day) => ({
      text: dayjs(day).locale('ja').format(DATE_FORMAT_11),
      dataIndex: day?.toString(),
      className: getColClass(dayjs(day).locale('ja').format(DATE_FORMAT_11)),
    }));
  }, [dataSource]);
  const columns = useMemo(
    () =>
      [
        {
          columns: [
            { rowSpan: 2, text: '初期想定値', width: 100, className: 'standard-name' },
            ...dayOfMonth,
          ],
        },
        {
          columns: [
            {
              text: '',
              dataIndex: 'index',
              className: 'standard-name d-none',
            },
            ...dayOfWeek,
          ],
        },
      ] as TableColumns[],
    [dayOfMonth, dayOfWeek],
  );

  return (
    <>
      <TableGroup tableColumns={columns} dataSource={dataSource} tableClass="standardTable">
        <StastiticRow
          title="当選確率"
          dataIndex="winningRate"
          dataTable={dataSource}
          render={({ row }: { row: RealTimeInit }) => (
            <div>
              {row.winningRate && typeof row.winningRate === 'number'
                ? `${row.winningRate.toFixed(2)}%`
                : row.winningRate}
            </div>
          )}
        />

        <StastiticRow
          title="前日増減"
          dataTable={dataSource}
          dataIndex="yesterdayChange"
          render={({ row }: { row: RealTimeInit }) => (
            <div className={`${row.yesterdayChange && row.yesterdayChange < 0 && 'txt-require'}`}>
              {row?.yesterdayChange === 0 || !row?.yesterdayChange
                ? ''
                : Math.round(row.yesterdayChange || 0)}
            </div>
          )}
        />

        <StastiticRow title="応募数" dataTable={dataSource} dataIndex="applyNumber" />

        <StastiticRow
          title="応募累積数"
          dataTable={dataSource}
          dataIndex="applyAccumulationNumber"
        />

        <StastiticRow title="当選枠残数" dataTable={dataSource} dataIndex="remainWinningNumber" />

        <StastiticRow title="当選数" dataTable={dataSource} dataIndex="winningNumber" />

        <StastiticRow
          title="当選数累積"
          dataTable={dataSource}
          dataIndex="winningAccumulationNumber"
        />

        <StastiticRow
          title="景品残数"
          dataTable={dataSource}
          dataIndex="remainPrizeNumber"
          render={({ row, index }) => {
            return (
              <div className={`${index === dataSource.length - 1 ? 'txt-require' : ''}`}>
                {index === dataSource.length - 1 ? '-0' : Math.round(row?.remainPrizeNumber)}
              </div>
            );
          }}
        />
      </TableGroup>
    </>
  );
}
