import CardContainer from 'Components/Common/CardContainer';
import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';
import { calcMb } from 'Utils/File';
import GroupTextProps from 'Components/Page/Title/FormTextGroup';
interface DigitalImageProps {
  data?: any;
}

export default function DigitalImage(props: DigitalImageProps): JSX.Element {
  const { data } = props;

  return (
    <CardContainer title="画像コンテンツ" className="mb-3 mt-4 p-0">
      {data?.length === 0 && <p>登録なし</p>}
      {data?.map((image: any, index: number) => {
        return (
          <Row key={index} className="w-100">
            <Col md="3" className="ps-0">
              <Image src={handleConnectStaticEndPoint(image?.url)} className="rounded" />
            </Col>
            <Col md={9} className="mt-4">
              <Col md={12} className="mb-2">
                <GroupTextProps
                  label="タイトル名:"
                  value={image?.name || ''}
                  mdLable={2}
                  mdValue={10}
                />
              </Col>
              <Col md={12} className="mb-2">
                <GroupTextProps
                  label="EC説明⽂:"
                  value={image?.introEc || ''}
                  mdLable={2}
                  mdValue={10}
                />
              </Col>
              <Col md={7}>
                <GroupTextProps label={`${calcMb(image?.size)}MB`} value={'  '} mdLable={8} />
              </Col>
            </Col>
            <hr className="my-4" />
          </Row>
        );
      })}
    </CardContainer>
  );
}
