import TableCommon from 'Components/Common/Table/TableCommon';
import React, { useMemo } from 'react';
import { Cell } from 'react-table';
import { formatPrice } from 'Utils/Numbers';

export default function PurchaseDetailTable({
  totalCurPrice,
  totalLicensePrice,
  totalPrevPrice,
  totalTotalPrice,
  totalTotalPriceAfterApplyBuyingRate,
}: {
  totalCurPrice: number;
  totalLicensePrice: number;
  totalPrevPrice: number;
  totalTotalPrice: number;
  totalTotalPriceAfterApplyBuyingRate: number;
}): JSX.Element {
  const purchaseData = [
    {
      totalCurPrice,
      totalLicensePrice,
      totalPrevPrice,
      totalTotalPrice,
      totalTotalPriceAfterApplyBuyingRate,
    },
  ];

  const screenWidth = window?.innerWidth;
  const column = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'pe-none',
        columns: [
          {
            Header: '請求金額',
            accessor: 'totalTotalPrice',
            width: screenWidth <= 1500 ? '100' : '120',
            className: 'lg-header-table-padding-3px',
            disableSortBy: true,
            Cell: ({ row }: Cell<any>) => {
              return (
                <div className="text-truncate text-start payment-advice-padding text-right lg-fs-14px">
                  {row.original.totalTotalPrice ? formatPrice(row.original.totalTotalPrice) : 0}
                </div>
              );
            },
          },
          {
            Header: '定額料金',
            accessor: 'totalTotalPriceAfterApplyBuyingRate',
            width: screenWidth <= 1500 ? '100' : '120',
            className: 'lg-header-table-padding-3px',
            disableSortBy: true,
            Cell: ({ row }: Cell<any>) => {
              return (
                <div className="text-truncate text-start payment-advice-padding text-right lg-fs-14px">
                  {row.original.totalTotalPriceAfterApplyBuyingRate
                    ? formatPrice(row.original.totalTotalPriceAfterApplyBuyingRate)
                    : 0}
                </div>
              );
            },
          },
          {
            Header: '定率料金',
            accessor: 'totalPrevPrice',
            width: screenWidth <= 1500 ? '100' : '120',
            className: 'lg-header-table-padding-3px',
            disableSortBy: true,
            Cell: ({ row }: Cell<any>) => {
              return (
                <div className="text-truncate text-start payment-advice-padding text-right lg-fs-14px">
                  {row.original?.totalPrevPrice ? formatPrice(row.original?.totalPrevPrice) : 0}
                </div>
              );
            },
          },
          {
            Header: 'オプション料金',
            accessor: 'totalCurPrice',
            width: screenWidth <= 1500 ? '100' : '120',
            className: 'lg-fs-12px lg-header-table-padding-3px',
            disableSortBy: true,
            Cell: ({ row }: Cell<any>) => {
              return (
                <div className="text-truncate text-start payment-advice-padding text-right lg-fs-14px">
                  {row.original.totalCurPrice ? formatPrice(row.original.totalCurPrice) : 0}
                </div>
              );
            },
          },
          {
            Header: '消費税',
            accessor: 'totalLicensePrice',
            width: screenWidth <= 1500 ? '100' : '120',
            className: 'lg-header-table-padding-3px',
            disableSortBy: true,
            Cell: ({ row }: Cell<any>) => {
              return (
                <div className="text-truncate text-start payment-advice-padding text-right lg-fs-14px">
                  {row.original.totalLicensePrice ? formatPrice(row.original.totalLicensePrice) : 0}
                </div>
              );
            },
          },
        ],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <>
      <TableCommon
        heightBody="calc(100vh - 250px)"
        columns={column}
        data={purchaseData}
        getCellProps={() => ({
          className: 'p-0',
        })}
        manualSortBy
        isNotShowSort={true}
      />
    </>
  );
}
