import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';

interface ProductAritistProps {
  artistInfo: ArtistInfo;
}

interface ArtistInfo {
  localName?: string;
  image?: string;
  id?: number;
}

export default function ProductAritist({ artistInfo }: ProductAritistProps): JSX.Element {
  const history = useHistory();
  const { image, localName, id } = artistInfo;
  return (
    <Card className="w-100 box-content">
      <div className="box-ava">
        <Card.Img
          variant="top"
          className="ava-product h-100"
          src={image && handleConnectStaticEndPoint(image)}
        />
      </div>
      <Card.Body className="pe-0 p-0">
        <Button
          className="w-100 rounded-0 border-1-ct btn-dark-ct h-40px ellipsis text-nowrap"
          variant="dark"
          data-tip
          data-for={localName}
          onClick={() => {
            history.push(`/artist/detail-artist/${id}`);
          }}
        >
          {localName}
        </Button>
        <ReactTooltip
          id={localName}
          place="bottom"
          effect="solid"
          className="w-15 h-35 text-center"
        >
          {localName}
        </ReactTooltip>
      </Card.Body>
    </Card>
  );
}
