import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import DateRangePicker from 'Components/Common/DateRangePicker/DateRangePicker';
import { FormGroupDateRangeProps } from 'Components/Common/Form/FormGroupDateRange/date-range';

export default function FormGroupDateRange(props: FormGroupDateRangeProps): JSX.Element {
  const {
    label,
    subLabel,
    classForm,
    labelMd,
    colMd,
    placeholderStart,
    placeholderEnd,
    registerStartDate,
    registerEndDate,
    onChangeStartDate,
    onChangeEndDate,
    minDate,
    startDate,
    endDate,
    errorStartDate,
    errorEndDate,
    register,
    endDateDisabled = false,
    startDateDisabled = false,
  } = props;

  return (
    <Form.Group as={Row} className={`mb-3 ${classForm}`}>
      <Form.Label column md={labelMd}>
        {label}
        {<p className="fs-12">{subLabel}</p>}
      </Form.Label>
      <Col md={colMd}>
        <DateRangePicker
          startDateDisabled={startDateDisabled}
          endDateDisabled={endDateDisabled}
          minDate={minDate}
          startDate={startDate}
          endDate={endDate}
          errorStartDate={errorStartDate}
          errorEndDate={errorEndDate}
          placeholderStart={placeholderStart}
          placeholderEnd={placeholderEnd}
          registerStartDate={registerStartDate}
          registerEndDate={registerEndDate}
          onChangeStartDate={onChangeStartDate}
          onChangeEndDate={onChangeEndDate}
          register={register}
        />
      </Col>
    </Form.Group>
  );
}
