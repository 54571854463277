import React, { useMemo } from 'react';
import { PURCHASE_STATUS } from 'Constant/EC';
interface ECListSaleStatusProps {
  status?: number | string;
}

export default function ECPurchaseStatus({ status }: ECListSaleStatusProps): JSX.Element {
  const foundPurchaseSatus: any = useMemo(() => {
    return PURCHASE_STATUS.find((s) => s.label == status) || {};
  }, [status]);

  return (
    <div className="ec-salestatus-tag" style={{ backgroundColor: foundPurchaseSatus.bgColor }}>
      <span>{foundPurchaseSatus?.label}</span>
    </div>
  );
}
