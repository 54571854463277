import DateSelectorDashboard from 'Components/Common/DateSelector/DateSelectorDashboard';
import Search from 'Components/Common/Search/Search';
import { DATE_FORMAT_4 } from 'Constant/Date';
import dayjs from 'dayjs';
import useSuggestion from 'Hooks/useSuggestion';
import React, { useCallback } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { IDatePicker } from 'Types/Common';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { updateQuery } from 'Utils/Search';

interface SearchRevenueProps {
  onExportExcel?: () => void;
  loadingExcel?: boolean;
}
export default function SearchDashBoard({
  onExportExcel,
  loadingExcel,
}: SearchRevenueProps): JSX.Element {
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    queryArtist: StringParam,
    queryTitle: StringParam,
    artistId: StringParam,
    artistIds: StringParam,
    titleId: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    variationId: StringParam,
    isOption: NumberParam,
    queryVariation: StringParam,
    queryCompany: StringParam,
    companyId: StringParam,
    variationIds: StringParam,
    titleIds: StringParam,
    companyIds: StringParam,
  });
  const {
    companyOptions,
    artistOptions,
    titleOptions,
    variationOptions,
    loadingArtist,
    loadingTitle,
    loadingVariation,
    loadingCompany,
    handleLoadMore,
    handleFocusInput,
    handleEnterPrimary,
    handleClickItemPrimary,
  } = useSuggestion({
    fetchList: ['company', 'artist', 'title', 'variation'],
    showDraftVariation: true,
  });

  const onChangeDate = useCallback((date: any, type: string) => {
    const checkDate = dayjs(date).format(DATE_FORMAT_4);
    if (!date) {
      updateQuery('', () => {
        setQuery({ [type]: '', page: 1 });
      });
      return;
    } else if (dayjs(checkDate, DATE_FORMAT_4, true).isValid()) {
      updateQuery(date, () => {
        setQuery({ [type]: dayjs(date), page: 1 });
      });
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const subtractMinYears = (date: any) => {
    if (date) {
      const dateCopy = new Date(date);
      dateCopy.setFullYear(date.getFullYear() - 3);
      return dateCopy;
    }
    return;
  };
  const subtractMaxYears = (date: any) => {
    if (date) {
      const dateCopy = new Date(date);
      dateCopy.setFullYear(date.getFullYear() + 3);
      return dateCopy;
    }
    return;
  };

  return (
    <>
      <Row className="align-items-end px-0">
        <Col md="10">
          <Row>
            <Col md="2" className="px-1 pe-3">
              <Search
                label="レコード会社"
                labelMd="12"
                colMd="12"
                placeholder="検索"
                showIcon
                classLabel="px-0"
                rowClass="w-100"
                colClass="px-0"
                searchKey="queryCompany"
                onEnter={() => handleEnterPrimary('queryCompany')}
                onClickResult={handleClickItemPrimary}
                options={companyOptions.options || []}
                loading={loadingCompany}
                onLoadMore={() => handleLoadMore('pageCompany')}
                onFocusInput={() => {
                  handleFocusInput('queryCompany', 'pageCompany');
                }}
              />
            </Col>
            <Col md="2" className="px-1 pe-3">
              <Search
                label="アーティスト"
                labelMd="12"
                colMd="12"
                placeholder="検索"
                showIcon
                classLabel="px-0"
                rowClass="w-100"
                colClass="px-0"
                searchKey="queryArtist"
                onEnter={() => handleEnterPrimary('queryArtist')}
                onClickResult={handleClickItemPrimary}
                options={artistOptions.options || []}
                loading={loadingArtist}
                onLoadMore={() => handleLoadMore('pageArtist')}
                onFocusInput={() => {
                  handleFocusInput('queryArtist', 'pageArtist');
                }}
              />
            </Col>
            <Col md="2" className="px-1 pe-3">
              <Search
                label="タイトル"
                labelMd="12"
                colMd="12"
                placeholder="検索"
                showIcon
                classLabel="px-0"
                rowClass="w-100"
                colClass="px-0"
                searchKey="queryTitle"
                onEnter={() => handleEnterPrimary('queryTitle')}
                onClickResult={handleClickItemPrimary}
                options={titleOptions.options || []}
                loading={loadingTitle}
                onLoadMore={() => handleLoadMore('pageTitle')}
                onFocusInput={() => {
                  handleFocusInput('queryTitle', 'pageTitle');
                }}
              />
            </Col>
            <Col md="2" className="px-1 pe-3">
              <Search
                label="バリエーション"
                labelMd="12"
                colMd="12"
                placeholder="検索"
                showIcon
                classLabel="px-0"
                rowClass="w-100"
                colClass="px-0"
                searchKey="queryVariation"
                onEnter={() => handleEnterPrimary('queryVariation')}
                onClickResult={handleClickItemPrimary}
                options={variationOptions.options || []}
                loading={loadingVariation}
                onLoadMore={() => handleLoadMore('pageVariation')}
                onFocusInput={() => {
                  handleFocusInput('queryVariation', 'pageVariation');
                }}
              />
            </Col>

            <>
              <Col md="2" className="px-1 pe-3">
                <DateSelectorDashboard
                  label="開始日"
                  labelMd="12"
                  placeholder="開始日"
                  dateFormat="yyyy/MM/dd"
                  showTime={false}
                  minDate={query.endDate ? subtractMinYears(new Date(query.endDate)) : null}
                  maxDate={query.endDate ? new Date(query.endDate) : null}
                  onChange={(dateStart: IDatePicker) => onChangeDate(dateStart, 'startDate')}
                  selected={query.startDate ? new Date(query.startDate) : null}
                />
              </Col>
              <Col md="2" className="px-1 pe-3">
                <DateSelectorDashboard
                  label="終了日"
                  labelMd="12"
                  placeholder="終了日"
                  dateFormat="yyyy/MM/dd"
                  showTime={false}
                  minDate={query.startDate ? new Date(query.startDate) : null}
                  maxDate={query.startDate ? subtractMaxYears(new Date(query.startDate)) : null}
                  onChange={(dateEnd: IDatePicker) => onChangeDate(dateEnd, 'endDate')}
                  selected={query.endDate ? new Date(query.endDate) : null}
                />
              </Col>
            </>
          </Row>
        </Col>
        <Col md="2">
          <Button
            className={`btn-focus-none prize-btn-excel float-right ${
              loadingExcel ? 'pe-none' : ''
            }`}
            onClick={onExportExcel}
          >
            {loadingExcel ? (
              <Spinner
                animation="border"
                variant="light"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              'Excelダウンロード'
            )}
          </Button>
        </Col>
      </Row>
    </>
  );
}
