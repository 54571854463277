import CardContainer from 'Components/Common/CardContainer';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import FormGroupSearch from 'Components/Common/Form/FormGroupSearch';
import FormInputNumber from 'Components/Common/Form/FormInputNumber';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { PAGE_SIZE, PER_PAGE } from 'Constant';
import { ArtistApi } from 'Datasource/ArtistApi';
import { TitleApi } from 'Datasource/TitleApi';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { IPullDownItem } from 'Types/Common';

import { useHistory, useLocation } from 'react-router-dom';
import { CDApis } from 'Datasource/CD';
import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';
import { useDropzone } from 'react-dropzone';
import { checkIfFilesAreCorrectType, checkImgSquareAndDimension } from 'Utils/File';
import MessageError from 'Components/Common/MessageError';

interface LocationState {
  id: string;
}

export default function CreateCD(): JSX.Element {
  const location = useLocation();
  const state = location?.state as LocationState;
  const history = useHistory();

  const [cdId] = useState(state?.id);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    setValue,
    getValues,
    watch,
    reset,
    setError,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      name: '',
      titleId: '',
      titleName: '',
      artistId: '',
      artistName: '',
      price: '',
      productCode: '',
      file: null,
    },
  });

  const handleSearchArtist = async (query: string) => {
    const {
      data: { data },
    } = await ArtistApi.getAllSuggestionArtist({
      page: 1,
      perPage: PAGE_SIZE,
      queryArtist: encodeURIComponent(query),
    });
    const { artists } = data;

    const result = artists.map((item: { id: number; localName: string }) => ({
      label: item.localName,
      value: item.id,
    }));

    return result;
  };

  const handleSearchTitle = async (query: string, artistID: number | string) => {
    let titles: IPullDownItem[] = [];
    if (artistID) {
      const {
        data: { data },
      } = await TitleApi.getAllTitleByArtistID({
        artistId: artistID,
        page: 1,
        perPage: PER_PAGE,
        query,
      });
      titles = data;
    } else {
      const {
        data: { data },
      } = await TitleApi.getAllSuggestionTitles({
        page: 1,
        perPage: PER_PAGE,
        query: encodeURIComponent(query),
      });
      titles = data;
    }
    titles = titles.map((title) => ({
      label: title.titles_name || title.name,
      value: title.titles_id || title.id,
    }));
    return titles;
  };

  const handleImageToFile = (url: any, fileName: string) => {
    let resFile: File;
    fetch(handleConnectStaticEndPoint(url)).then(async (response: any) => {
      const blob = await response.blob();
      resFile = new File([blob], fileName);
      setValue('file', resFile);
    });
  };

  const handleGetDetailCD = async () => {
    setIsLoading(true);
    const {
      data: { data },
    } = await CDApis.getCD({ id: state?.id });
    if (data?.coverURL) {
      handleImageToFile(data?.coverURL, 'image-cd');
    }
    reset({
      name: data?.name,
      titleId: data?.titleId,
      titleName: data?.titleName,
      artistId: data?.artistId,
      artistName: data?.artistName,
      price: data?.price,
      productCode: data?.productCode,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    if (state?.id) {
      handleGetDetailCD();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnDrop = async (acceptedFiles: File[]) => {
    setError('file', {
      type: 'manual',
      message: '',
    });
    const file = acceptedFiles[0];
    const isValidType = checkIfFilesAreCorrectType(file);
    if (!isValidType) {
      setError('file', {
        type: 'manual',
        message: '画像タイプはjpeg, pngまたはfgifの画像を登録してください。',
      });
      return;
    }
    const isSquareImg = await checkImgSquareAndDimension(file, 600);
    if (!isSquareImg) {
      setError('file', {
        type: 'manual',
        message: '画像サイズは縦横６００ｘ６００ピクセルの画像を登録してください。',
      });
      return;
    }
    setValue('file', file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg,image/png,image/gif',
    maxFiles: 1,
    onDrop: handleOnDrop,
  });

  const thumbs = (file: File) => {
    const preview = file ? URL.createObjectURL(file) : '';
    return (
      <div>
        <img className="border rounded file-box" src={preview} alt="" />
      </div>
    );
  };

  return (
    <div className="mt-4 cd-register pe-none">
      <Container>
        <SpinnerComponent isLoading={isLoading} />
        <CardContainer title={cdId ? 'CDマスタ情報' : 'CDマスタ登録'} darkHeader>
          <Form id="curatorManagement">
            <FormGroupSearch
              rowClass="mt-3"
              labelMd="2"
              colMd="9"
              label="アーティスト"
              placeholder="アーティストを選択してください。"
              inputSearchString={watch('artistName')}
              onSearch={handleSearchArtist}
              onSelect={(selectedItem) => {
                reset({
                  ...getValues(),
                  artistId: selectedItem.value,
                  artistName: selectedItem.label,
                });
              }}
              errorMessage={errors.artistId?.message}
            />
            <FormGroupSearch
              rowClass="my-3"
              labelMd="2"
              colMd="9"
              label="タイトル"
              inputSearchString={watch('titleName')}
              placeholder="タイトル名・品番を選択してください。"
              disabled={!watch('artistId')}
              onSearch={(query) => handleSearchTitle(query, watch('artistId'))}
              onSelect={(selectedItem) => {
                reset({
                  ...getValues(),
                  titleId: selectedItem.value,
                  titleName: selectedItem.label,
                });
              }}
              errorMessage={errors.titleId?.message}
            />

            <FormGroupInput
              label="CD商品名 (バリエーション)"
              labelMd="2"
              colMd="9"
              register={{ ...register('name') }}
              value={watch('name')}
              onChange={(value) => {
                reset({
                  ...getValues(),
                  name: value,
                });
              }}
              errorMessage={errors.name?.message}
            />

            <FormInputNumber
              name="price"
              labelMd="2"
              colMd="3"
              label="販売価格"
              classCol="position-relative price-end"
              pureType={true}
              decimalScale={0}
              allowNegative={false}
              maxLength={10}
              thousandSeparator={true}
              value={watch('price')}
              onChange={(value) => {
                reset({
                  ...getValues(),
                  price: value,
                });
              }}
              errorMessage={errors.price?.message}
            />

            <FormGroupInput
              label="品番"
              labelMd="2"
              colMd="3"
              maxLength={20}
              register={{ ...register('productCode') }}
              value={watch('productCode')}
              onChange={(value) => {
                reset({
                  ...getValues(),
                  productCode: value,
                });
              }}
              errorMessage={errors.productCode?.message}
            />
            <Row>
              <Col md={2}>
                ジャケット画像 <br />
                （600px X 600px)
              </Col>
              <Col md={3}>
                <section>
                  <div
                    {...getRootProps({
                      className:
                        'dropzone file-box d-flex align-items-center justify-content-center border rounded',
                    })}
                  >
                    {watch('file') ? (
                      <div>{thumbs(watch('file'))}</div>
                    ) : (
                      <>
                        <input {...getInputProps()} />
                        <p className="gray">画像ファイルをアップロードしてください</p>
                      </>
                    )}
                  </div>
                </section>
                <MessageError classWrapper="w-max-content" message={errors.file?.message} />
              </Col>
            </Row>
          </Form>
          <hr className="mt-5" />

          <div className="d-flex align-items-center justify-content-end mt-2"></div>
        </CardContainer>
        <div className="pe-auto d-flex justify-content-center align-items-center pb-4">
          <Button
            className="btn-equal-width btn-focus-none my-4"
            onClick={() => history.push('/cd-master')}
          >
            一覧へ戻る
          </Button>
        </div>
      </Container>
    </div>
  );
}
