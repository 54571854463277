import brAxios from 'Api/Interceptors';
import { AxiosResponse } from 'axios';
import { transformQuery } from 'Utils/Params';

export interface ICreateCompany {
  companyName: string;
  masterName: string;
  sectionName: string;
  masterEmail: string;
  masterPhone: string;
  snFee: string;
  snImportFee: string;
  companyRecordOptions: IRecordCompanyOptions[];
  channelIds: number[];
}

export interface IProxy {
  userId: number | string;
  companyId: number | string;
}

export interface IRecordCompanyOptions {
  titleType: string;
  maxSongCount: string;
  minPrice: string;
  maxVariationStorage: string;
  limitTokutenStorage: string;
  cardBuyFee: string;
  taxFee: string;
  id?: number;
  deletable: boolean;
}
export interface IUpdateRecordCompany {
  snFee: string;
  snImportFee: string;
  companyRecordOptions: IRecordCompanyOptions[];
  channelIds: number[];
}

interface IQueryParams {
  startDate?: string;
  endDate?: string;
  titleId?: string | number;
  artistId?: string | number;
  variationId?: string | number;
  isOption?: string | number;
}

export const RecordCompanyApi = {
  getRecordCompany: ({
    page,
    limit,
    sort,
    order,
  }: {
    page: number;
    limit: number;
    sort?: string;
    order?: string;
  }): Promise<AxiosResponse> =>
    brAxios.get(
      `/bootrock-company/record-companies?page=${page}&limit=${limit}&sort=${
        sort || 'companyName'
      }&order=${order || 'DESC'}`,
    ),

  getRecordCompanyDetail: (id: string) => brAxios.get(`/bootrock-company/record-companies/${id}`),
  onLoginProxy: (param: IProxy) => brAxios.post(`run-as`, param),
  createRecordCompany: (param: ICreateCompany) =>
    brAxios.post(`/bootrock-company/record-companies/`, param),
  updateRecordCompany: (id: string, param: IUpdateRecordCompany) =>
    brAxios.put(`/bootrock-company/record-companies/${id}`, param),

  getAllReportPurchase: (params: any) => {
    const strParam = transformQuery(params);
    const response = brAxios.get(`/bootrock-company/accounting-report/report-purchase${strParam}`);
    return response;
  },

  exportExcelPurchase: (params: IQueryParams) => {
    const response = brAxios.get(
      '/bootrock-company/accounting-report/report-purchase/export-excel',
      {
        params,
        responseType: 'blob',
      },
    );
    return response;
  },

  getAllReportCost: (params: any) => {
    const strParam = transformQuery(params);
    const response = brAxios.get(`/bootrock-company/accounting-report/report-cost${strParam}`);
    return response;
  },

  exportExcelCost: (params: IQueryParams) => {
    const response = brAxios.get('/bootrock-company/accounting-report/report-cost/export-excel', {
      params,
      responseType: 'blob',
    });
    return response;
  },
};
