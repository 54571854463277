import Search from 'Components/Common/Search/Search';
import useSuggestion from 'Hooks/useSuggestion';
import { IPullDownItem } from 'Types/Common';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import { StringParam, useQueryParams } from 'use-query-params';

export default function SearchProductsDetail(): JSX.Element {
  const [query, setQuery] = useQueryParams({
    queryArtist: StringParam,
    queryTitle: StringParam,
    artistId: StringParam,
    artistIds: StringParam,
    titleIds: StringParam,
    queryCompany: StringParam,
    companyIds: StringParam,
    companyId: StringParam,
  });

  const {
    companyOptions,
    artistOptions,
    loadingCompany,
    titleOptions,
    loadingArtist,
    loadingTitle,
    handleLoadMore,
    handleFocusInput,
    handleEnterPrimary,
    handleClickItemPrimary,
  } = useSuggestion({
    fetchList: ['company', 'artist', 'title'],
  });

  useEffect(() => {
    let validQuery = { ...query } as any;
    validQuery = {
      artistIds: undefined,
      artistId: undefined,
      queryArtist: undefined,
      queryTitle: undefined,
      titleIds: undefined,
    };

    setQuery(validQuery);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.companyIds]);

  useEffect(() => {
    if (titleOptions.options.length > 0 && query.titleIds) {
      const currentTitle = titleOptions.options.find(
        (item: IPullDownItem) => item.value?.toString() === query.titleIds,
      );

      if (currentTitle) {
        setQuery({ queryTitle: currentTitle?.label });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query?.titleIds, titleOptions.options]);

  return (
    <Row className="px-0">
      <Col md="4" className="ps-2">
        <Search
          label="レコード会社"
          labelMd="12"
          colMd="12"
          placeholder="検索"
          showIcon
          classLabel="px-0"
          rowClass="w-100"
          colClass="px-0"
          searchKey="queryCompany"
          onEnter={() => handleEnterPrimary('queryCompany')}
          onClickResult={handleClickItemPrimary}
          options={companyOptions.options || []}
          loading={loadingCompany}
          onLoadMore={() => handleLoadMore('pageCompany')}
          onFocusInput={() => {
            handleFocusInput('queryCompany', 'pageCompany');
          }}
        />
      </Col>
      <Col md="4" className="ps-2">
        <Search
          labelMd="12"
          colMd="12"
          placeholder="検索"
          showIcon
          label="アーティスト"
          classLabel="px-0"
          rowClass="w-100"
          colClass="px-0"
          searchKey="queryArtist"
          onEnter={() => handleEnterPrimary('queryArtist')}
          onClickResult={handleClickItemPrimary}
          options={artistOptions.options || []}
          loading={loadingArtist}
          onLoadMore={() => handleLoadMore('pageArtist')}
          onFocusInput={() => {
            handleFocusInput('queryArtist', 'pageArtist');
          }}
        />
      </Col>
      <Col md="4" className="ps-2">
        <Search
          labelMd="12"
          colMd="12"
          placeholder="検索"
          label="タイトル"
          showIcon
          classLabel="px-0"
          rowClass="w-100"
          colClass="px-0"
          searchKey="queryTitle"
          onEnter={() => handleEnterPrimary('queryTitle')}
          onClickResult={handleClickItemPrimary}
          options={titleOptions.options || []}
          loading={loadingTitle}
          onLoadMore={() => handleLoadMore('pageTitle')}
          onFocusInput={() => {
            handleFocusInput('queryTitle', 'pageTitle');
          }}
        />
      </Col>
    </Row>
  );
}
