import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import jscookie from 'js-cookie';
import { LoginResponse } from 'Types/App/auth';
import jwt_decode from 'jwt-decode';

interface InitialStateTypes {
  company?: string | null;
  accessToken?: string | Record<string, unknown> | null;
  name?: string | null;
  role?: string | null;
  status?: string | null;
  email?: string | null;
}

const initialState: InitialStateTypes = {
  company: null,
  accessToken: null,
  name: null,
  role: null,
  status: null,
  email: null,
};

interface IDecoder {
  email?: string;
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, data: PayloadAction<LoginResponse>) => {
      const payload = data.payload;
      state.accessToken = payload.access_token;
      state.company = payload.company;
      state.name = payload.name;
      state.role = payload.role;
      state.status = payload.status;
      if (payload.access_token) {
        const decoded: IDecoder = jwt_decode(`${payload.access_token}`);
        state.email = decoded.email;
        jscookie.set('token', payload.access_token);
      }
    },
    logout: (state) => {
      state.accessToken = null;
      state.company = null;
      state.name = null;
      state.role = null;
      state.status = null;
      jscookie.remove('token');
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
