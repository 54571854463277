import React, { memo, useCallback, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import dayjs from 'dayjs';
import ReactDOM from 'react-dom';
import { DATE_FORMAT_17 } from 'Constant/Date';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { countDays } from 'Utils/DateTime';

const calendarConfig = {
  contentHeight: 'auto',
  height: 500,
  headerToolbar: {
    center: '',
    end: '',
  },
  themeSystem: 'bootstrap5',
  plugins: [dayGridPlugin],
  initialView: 'dayGridMonth',
  locale: 'ja',
};

const MonthCalendar = ({ events, month }: { events: any; month: any }): JSX.Element => {
  const [show, setShow] = useState<boolean>(false);
  useEffect(() => {
    return () => {
      if (show) {
        setShow(false);
      }
    };
  }, [show]);

  const getDateEvent = useCallback(
    (item: any) => {
      let startDate = new Date();
      let endDate = new Date();
      const eventArr = Array.isArray(events) ? events : [];
      const realityDate = eventArr.find((el: any) => el.id === item.id);
      if (realityDate) {
        startDate = realityDate.start;
        endDate = realityDate.end;
      }
      return { startDate, endDate };
    },
    [events],
  );

  return (
    <>
      <FullCalendar
        {...calendarConfig}
        initialDate={month}
        events={events}
        eventContent={(info) => {
          return (
            <div style={{ height: 10 }} className="ps-1 invisible position-relative">
              {info.event.title}
            </div>
          );
        }}
        eventMouseEnter={(info) => {
          setShow(true);
          const popover = (
            <Popover id={`tooltip-${info.event.id}`} show={show}>
              <Popover.Header className="p-1">
                <div className="d-flex align-items-center">
                  <div
                    style={{ background: info.event.backgroundColor, width: 10, height: 10 }}
                    className="me-2"
                  />
                  {info.event.title}
                </div>
              </Popover.Header>
              <Popover.Body className="p-2">
                {info?.event?.allDay ? (
                  <div>
                    販売期間: {dayjs(getDateEvent(info.event).startDate).format(DATE_FORMAT_17)}
                    {' ~ '}
                    {dayjs(getDateEvent(info.event).endDate).format(DATE_FORMAT_17)}（終了まで
                    {countDays(new Date(), getDateEvent(info.event).endDate)}日）
                  </div>
                ) : (
                  <div>
                    販売期間: {dayjs(info.event.start).format(DATE_FORMAT_17)}
                    {' ~ '}
                    {dayjs(info.event.end).format(DATE_FORMAT_17)}（終了まで
                    {countDays(new Date(), info.event.end)}日）
                  </div>
                )}
                <div>キャンペーン概要:</div>
                <div>{info.event.extendedProps.description}</div>
              </Popover.Body>
            </Popover>
          );

          const content = (
            <OverlayTrigger placement="bottom" overlay={popover}>
              <div style={{ height: 10 }} className="ps-1 position-relative" />
            </OverlayTrigger>
          );

          if (show) {
            ReactDOM.render(content, info.el);
          }
        }}
      />
    </>
  );
};

export default memo(MonthCalendar);
