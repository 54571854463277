/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import brAxios from 'Api/Interceptors';

interface QueryParams {
  page?: number;
  perPage?: number;
  query?: string;
  type?: string;
  companyId?: string;
  artistId?: string | number;
}

interface IGetAllTitlesQueryParams {
  page?: number;
  limit?: number;
  query?: string | number;
  status?: number | string;
  companyId?: string;
  artistId?: string;
  titleId?: string;
}

export const TitleApi = {
  getAllTitles: (params: IGetAllTitlesQueryParams) => {
    const response = brAxios.get(`/bootrock-company/titles`, { params });
    return response;
  },
  getOneTitle: (id: string | number) => {
    const response = brAxios.get(`/bootrock-company/titles/${id}`);
    return response;
  },
  getAllSuggestionTitles: ({ page, perPage, query, companyId, artistId }: QueryParams) => {
    const response = brAxios.get(
      `/suggestion/titles?page=${page ?? ''}&limit=${perPage ?? ''}&query=${
        query ?? ''
      }&companyId=${companyId ?? ''}&artistId=${artistId ?? ''}`,
    );
    return response;
  },
  exportExcel: (params: any) => {
    const response = brAxios.get('/bootrock-company/titles/export/download', {
      params,
      responseType: 'blob',
    });
    return response;
  },
  getAllTitleByArtistID: ({ artistId, page, perPage, query }: QueryParams) => {
    const response = brAxios.get(
      `/suggestion/title/${artistId}?page=${page ?? ''}&limit=${perPage ?? ''}&query=${
        query ?? ''
      }`,
    );
    return response;
  },
};
