/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { emailValidation } from 'Utils/Validation';
import * as yup from 'yup';
import MessageError from 'Components/Common/MessageError';
import { AuthApi } from 'Datasource/Auth';
import { AxiosError } from 'axios';
import useMessage from 'Hooks/useMessage';
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
interface FormEmail {
  email: string;
}

const schema = yup.object().shape({
  email: emailValidation({
    required: 'メールアドレスを入力してください。',
    email: '有効なメールアドレスを入力してください。',
  }),
});

export default function ForgotPasswordForm(): JSX.Element {
  const { openMessageError } = useMessage();
  const [isSuccess, setIsSuccess] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{ email: string }>({ mode: 'onSubmit', resolver: yupResolver(schema) });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = handleSubmit(async (data: FormEmail) => {
    try {
      setIsLoading(true);
      const res = await AuthApi.forgotPassword(data);
      setIsSuccess(true);
      return res;
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  });

  return isSuccess ? (
    <div className="text-center">
      入力されたメールアドレスへパスワード再設定用のURLを送信しました。
      <br />
      そのURLにアクセスしてパスワードを再設定ください。
    </div>
  ) : (
    <form onSubmit={onSubmit} className="public-card-width">
      <SpinnerComponent isLoading={isLoading} />
      <FormGroup className="mb-3">
        <FormLabel>ログインメールアドレス</FormLabel>
        <FormControl
          {...register('email')}
          type="text"
          aria-label="email"
          aria-describedby="input--email"
        />
        <MessageError message={errors.email?.message} />
        <div className="mt-2 w-max-content">
          入力されたメールアドレスへパスワード再設定用のURLを送信します。
        </div>
      </FormGroup>

      <FormGroup className="mt-5 d-flex justify-content-center">
        <Button type="submit" className="bg-black border-0 pe-5 ps-5">
          送信
        </Button>
      </FormGroup>
    </form>
  );
}
