import React from 'react';
import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';

const BoxDropZone = ({
  coverImageUrl = '',
}: {
  coverImageUrl?: string | undefined | null;
}): JSX.Element => {
  return (
    <div>
      <section>
        <div className="dropzone file-box d-flex align-items-center justify-content-center border rounded">
          {coverImageUrl && (
            <img
              className="border rounded file-box"
              src={handleConnectStaticEndPoint(coverImageUrl || '')}
              alt=""
            />
          )}
        </div>
      </section>
    </div>
  );
};

export default BoxDropZone;
