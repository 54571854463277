/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import TableCommon from 'Components/Common/Table/TableCommon';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { Row, Col, Button } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { Cell } from 'react-table';
import { ISaleChannel } from 'Datasource/SaleChannelApi';
import { Trash } from 'react-bootstrap-icons';
import useCustomSort from 'Hooks/useCustomSort';
interface TableSaleChannelProps {
  dataSource: any[];
  loading: boolean;
  onDelete?: (id: number) => void;
  onRowClick?: (param: ISaleChannel) => void;
}

const saleActive = 1;

export default function TableSaleChannel({
  dataSource,
  loading,
  onDelete,
  onRowClick,
}: TableSaleChannelProps): JSX.Element {
  const { customStringSort } = useCustomSort();

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'index',
        className: 'pe-none',
        columns: [
          {
            Header: '販売チャネル',
            accessor: 'name',
            isSortedDesc: true,
            isSorted: true,
            Cell: ({ row }: Cell<ISaleChannel>) => {
              return (
                <div className="button-a">
                  <Button
                    className="text-truncate"
                    variant="link"
                    onClick={() => onRowClick && onRowClick(row.original)}
                  >
                    {row.original.name}
                  </Button>
                </div>
              );
            },
            sortType: customStringSort,
          },
          {
            Header: '操作',
            accessor: 'empty',
            className: 'hidden-sort',
            Cell: ({ row }: Cell<any>) => {
              return (
                <Button
                  variant="secondary"
                  onClick={() => handleDeleteRow(row.original.id)}
                  disabled={row.original.isSale === saleActive}
                >
                  <Trash size="16" />
                </Button>
              );
            },
          },
        ],
      },
    ],
    [],
  );

  const handleDeleteRow = (id: number) => {
    onDelete && onDelete(id);
  };
  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <div className="py-4">
        {!isEmpty(dataSource) ? (
          <Row>
            <Col md={12} className="p-0 my-1" style={{ maxWidth: '1300px' }}>
              <TableCommon
                classTable="table-sale-channel"
                columns={columns}
                data={dataSource}
                getHeaderProps={() => {
                  return {
                    className: 'text-white d-flex align-item-center bg-dark',
                  };
                }}
                getColumnProps={() => {
                  return {
                    className: 'text-center',
                  };
                }}
                getRowProps={() => {
                  return {
                    style: {},
                  };
                }}
                getCellProps={() => {
                  return {
                    className: 'bg-white text-dark',
                  };
                }}
              />
            </Col>
          </Row>
        ) : (
          <div className="vh-100-final d-flex align-items-center justify-content-center">
            商品が登録されていません
          </div>
        )}
      </div>
    </>
  );
}
