import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import Filter from 'Components/Common/Filter/Filter';
import DropdownMenu from 'Components/Common/EC/DropdownMenu';
import OrderCancellation from './EcSendBulkEmailForm';
import { EC_EMAIL_TEMPLATE } from 'Constant/EC';

export default function EmailManagement(): JSX.Element {
  const groupOptions = [
    { name: EC_EMAIL_TEMPLATE.TITLE_REGISTER_COMPLETE },
    { name: EC_EMAIL_TEMPLATE.TITLE_CONTENT_CHANGE_NOTIFICATION },
    { name: EC_EMAIL_TEMPLATE.CAMPAIGN_REGISTER_COMPLETION_NOTIFICATION },
    { name: EC_EMAIL_TEMPLATE.NOTIFICATION_OF_CHANGE_IN_CAMPAIGN_CONTENT },
    { name: EC_EMAIL_TEMPLATE.REQUEST_FOR_MASTER_CONTENT_REGISTER_2_WEEKS_BEFORE },
    { name: EC_EMAIL_TEMPLATE.REQUEST_FOR_MASTER_CONTENT_REGISTER_1_WEEK_BEFORE },
    { name: EC_EMAIL_TEMPLATE.REQUEST_FOR_MASTER_CONTENT_REGISTER_4_DAYS_BEFORE },
    { name: EC_EMAIL_TEMPLATE.REQUEST_FOR_CAMPAIGN_CONTENT_REGISTRATION_1_WEEK_BEFORE },
    { name: EC_EMAIL_TEMPLATE.REQUEST_FOR_CAMPAIGN_CONTENT_REGISTRATION_4_DAYS_BEFORE },
    { name: EC_EMAIL_TEMPLATE.RECORD_COMPANY_MASTER_ACCOUNT_INSSUANCE_NOTIFICATION },
    {
      name: EC_EMAIL_TEMPLATE.RECORD_COMPANY_ARTIST_PERSON_IN_CHARGE_ACCOUNT_ISSUANCE_NOTIFICATION,
    },
    {
      name: EC_EMAIL_TEMPLATE.BOOTROCK_RECORD_COMPANY_REPRESENTATIVE_ACCOUNT_ISSUANCE_NOTIFICATION,
    },
    {
      name: EC_EMAIL_TEMPLATE.PASSWORD_RESET_NOTIFICATION,
    },
    {
      name: EC_EMAIL_TEMPLATE.MIIM_EC_MASTER_REGISTER_COMPLETE,
    },
    {
      name: EC_EMAIL_TEMPLATE.MIIM_EC_REPRESENTATIVE_REGISTER_COMPLETE,
    },
    {
      name: EC_EMAIL_TEMPLATE.EXTERNAL_MIIM_EC_MASTER_REGISTER_COMPLETE,
    },
    {
      name: EC_EMAIL_TEMPLATE.EXTERNAL_MIIM_EC_REPRESENTATIVE_REGISTER_COMPLETE,
    },
    {
      name: EC_EMAIL_TEMPLATE.FOOTER,
    },
  ];

  const [activeOption, setActiveOption] = useState(groupOptions[0].name);

  return (
    <Container fluid>
      <Filter filterClass={`shadow-sm `}>
        <div>メールテンプレート</div>
        <DropdownMenu
          options={groupOptions}
          activeOption={activeOption}
          onChange={setActiveOption}
          classButton="ec_custom_dropdown-menu_btn"
        />
      </Filter>
      {activeOption == EC_EMAIL_TEMPLATE.TITLE_REGISTER_COMPLETE ? (
        <OrderCancellation name={EC_EMAIL_TEMPLATE.TITLE_REGISTER_COMPLETE} />
      ) : activeOption == EC_EMAIL_TEMPLATE.TITLE_CONTENT_CHANGE_NOTIFICATION ? (
        <OrderCancellation name={EC_EMAIL_TEMPLATE.TITLE_CONTENT_CHANGE_NOTIFICATION} />
      ) : activeOption == EC_EMAIL_TEMPLATE.CAMPAIGN_REGISTER_COMPLETION_NOTIFICATION ? (
        <OrderCancellation name={EC_EMAIL_TEMPLATE.CAMPAIGN_REGISTER_COMPLETION_NOTIFICATION} />
      ) : activeOption == EC_EMAIL_TEMPLATE.NOTIFICATION_OF_CHANGE_IN_CAMPAIGN_CONTENT ? (
        <OrderCancellation name={EC_EMAIL_TEMPLATE.NOTIFICATION_OF_CHANGE_IN_CAMPAIGN_CONTENT} />
      ) : activeOption == EC_EMAIL_TEMPLATE.REQUEST_FOR_MASTER_CONTENT_REGISTER_2_WEEKS_BEFORE ? (
        <OrderCancellation
          name={EC_EMAIL_TEMPLATE.REQUEST_FOR_MASTER_CONTENT_REGISTER_2_WEEKS_BEFORE}
        />
      ) : activeOption == EC_EMAIL_TEMPLATE.REQUEST_FOR_MASTER_CONTENT_REGISTER_1_WEEK_BEFORE ? (
        <OrderCancellation
          name={EC_EMAIL_TEMPLATE.REQUEST_FOR_MASTER_CONTENT_REGISTER_1_WEEK_BEFORE}
        />
      ) : activeOption == EC_EMAIL_TEMPLATE.REQUEST_FOR_MASTER_CONTENT_REGISTER_4_DAYS_BEFORE ? (
        <OrderCancellation
          name={EC_EMAIL_TEMPLATE.REQUEST_FOR_MASTER_CONTENT_REGISTER_4_DAYS_BEFORE}
        />
      ) : activeOption ==
        EC_EMAIL_TEMPLATE.REQUEST_FOR_CAMPAIGN_CONTENT_REGISTRATION_1_WEEK_BEFORE ? (
        <OrderCancellation
          name={EC_EMAIL_TEMPLATE.REQUEST_FOR_CAMPAIGN_CONTENT_REGISTRATION_1_WEEK_BEFORE}
        />
      ) : activeOption ==
        EC_EMAIL_TEMPLATE.REQUEST_FOR_CAMPAIGN_CONTENT_REGISTRATION_4_DAYS_BEFORE ? (
        <OrderCancellation
          name={EC_EMAIL_TEMPLATE.REQUEST_FOR_CAMPAIGN_CONTENT_REGISTRATION_4_DAYS_BEFORE}
        />
      ) : activeOption == EC_EMAIL_TEMPLATE.RECORD_COMPANY_MASTER_ACCOUNT_INSSUANCE_NOTIFICATION ? (
        <OrderCancellation
          name={EC_EMAIL_TEMPLATE.RECORD_COMPANY_MASTER_ACCOUNT_INSSUANCE_NOTIFICATION}
        />
      ) : activeOption ==
        EC_EMAIL_TEMPLATE.RECORD_COMPANY_ARTIST_PERSON_IN_CHARGE_ACCOUNT_ISSUANCE_NOTIFICATION ? (
        <OrderCancellation
          name={
            EC_EMAIL_TEMPLATE.RECORD_COMPANY_ARTIST_PERSON_IN_CHARGE_ACCOUNT_ISSUANCE_NOTIFICATION
          }
        />
      ) : activeOption ==
        EC_EMAIL_TEMPLATE.BOOTROCK_RECORD_COMPANY_REPRESENTATIVE_ACCOUNT_ISSUANCE_NOTIFICATION ? (
        <OrderCancellation
          name={
            EC_EMAIL_TEMPLATE.BOOTROCK_RECORD_COMPANY_REPRESENTATIVE_ACCOUNT_ISSUANCE_NOTIFICATION
          }
        />
      ) : activeOption == EC_EMAIL_TEMPLATE.PASSWORD_RESET_NOTIFICATION ? (
        <OrderCancellation name={EC_EMAIL_TEMPLATE.PASSWORD_RESET_NOTIFICATION} />
      ) : activeOption == EC_EMAIL_TEMPLATE.MIIM_EC_MASTER_REGISTER_COMPLETE ? (
        <OrderCancellation name={EC_EMAIL_TEMPLATE.MIIM_EC_MASTER_REGISTER_COMPLETE} />
      ) : activeOption == EC_EMAIL_TEMPLATE.MIIM_EC_REPRESENTATIVE_REGISTER_COMPLETE ? (
        <OrderCancellation name={EC_EMAIL_TEMPLATE.MIIM_EC_REPRESENTATIVE_REGISTER_COMPLETE} />
      ) : activeOption == EC_EMAIL_TEMPLATE.EXTERNAL_MIIM_EC_MASTER_REGISTER_COMPLETE ? (
        <OrderCancellation name={EC_EMAIL_TEMPLATE.EXTERNAL_MIIM_EC_MASTER_REGISTER_COMPLETE} />
      ) : activeOption == EC_EMAIL_TEMPLATE.EXTERNAL_MIIM_EC_REPRESENTATIVE_REGISTER_COMPLETE ? (
        <OrderCancellation
          name={EC_EMAIL_TEMPLATE.EXTERNAL_MIIM_EC_REPRESENTATIVE_REGISTER_COMPLETE}
        />
      ) : activeOption == EC_EMAIL_TEMPLATE.FOOTER ? (
        <OrderCancellation name={EC_EMAIL_TEMPLATE.FOOTER} />
      ) : null}
    </Container>
  );
}
