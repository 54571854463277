/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useRef } from 'react';
import { DropdownIcon } from 'Components/Common/Select/CustomSelect/DropdownIcon';
import { SearchIcon } from 'Components/Common/Select/CustomSelect/SearchIcon';

interface SelectOptionProps {
  searchString?: string;
  setSearchString: ({ target }: { target: any }) => void;
  onBlur?: () => void;
  onEnter?: (e: React.KeyboardEvent<EventTarget>) => void;
  onFocus?: () => void;
  onClear?: () => void;
  placeholder?: string;
  showIcon?: boolean;
  showIconClear?: boolean;
}

const SelectOption = (props: SelectOptionProps) => {
  const { searchString, onBlur, onFocus, showIcon = true } = props;
  const ref = useRef<HTMLInputElement>(null);

  const handleOnFocus = () => {
    onFocus && onFocus();
    if (ref) {
      ref.current?.focus();
    }
  };

  return (
    <div className="search-input pe-5" onClick={handleOnFocus} aria-hidden>
      <SearchIcon showIcon={showIcon} />
      <input
        ref={ref}
        spellCheck={false}
        value={searchString ?? ''}
        onBlur={onBlur}
        type={'submit'}
        className="text-start"
      />
      <DropdownIcon />
    </div>
  );
};

export default SelectOption;
