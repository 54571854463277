import React, { useEffect } from 'react';
import { ErrorApi } from 'Datasource/Error';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

export default function Home(): JSX.Element {
  const test = async () => {
    const res = await ErrorApi.getError();
    return res;
  };

  useEffect(() => {
    test();
  }, []);

  return (
    <Container className="page-404">
      <Row>
        <Col sm={12}>
          <Col sm={12} className="justify-content-sm-center text-center">
            <div className="four-zero-four-bg" />
            <div className="mt-4">
              <h3 className="h2 fs-3">あなたが迷子になっているように見えます</h3>
              <p className="fs-6">お探しのページはご利用いただけません！</p>
              <Link to="/" className="link-404 p-2 mt-1 inline-block">
                家に帰る
              </Link>
            </div>
          </Col>
        </Col>
      </Row>
    </Container>
  );
}
