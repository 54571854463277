import React, { useEffect, useState } from 'react';
import BoxForm from 'Components/Common/Form/BoxForm';
import { Button, Col, Row } from 'react-bootstrap';
import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';
import { ProductsApi } from 'Datasource/ProductsApi';
import { StringParam, useQueryParams } from 'use-query-params';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import dayjs from 'dayjs';
import { DATE_FORMAT_CMT } from 'Constant/Date';
import useMessage from 'Hooks/useMessage';

export default function ConfirmationComment(): JSX.Element {
  const [processManagement, setProcessManagement] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listCampaignName, setListCampaignName] = useState<Array<string>>([]);
  const [listComment, setListComment] = useState<any>([]);
  const { openMessageError } = useMessage();

  const {
    image,
    artistName,
    titleName,
    variationNames = [],
    listCampaignProcess = [],
    listTitleProcess = [],
  } = processManagement;
  const [onSelect, setOnSelect] = useState<string>('');

  const [query] = useQueryParams({
    queryArtist: StringParam,
    queryTitle: StringParam,
    artistIds: StringParam,
    titleIds: StringParam,
    companyIds: StringParam,
  });

  useEffect(() => {
    const ids = {
      titleId: query.titleIds || '',
      companyIds: query.companyIds || '',
    };

    handleGetProductDetail(ids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.artistIds, query.titleIds, query.companyIds]);

  const handleGetProductDetail = async (ids: any) => {
    if (!ids?.titleId) return;

    const queryArr = Object.values(query);
    const arrLookupNoData = queryArr.filter((q: any) => {
      return !!q && q === 'no-data';
    });

    if (arrLookupNoData.length > 0) {
      setProcessManagement({});
      return;
    }

    try {
      setIsLoading(true);
      const {
        data: { data },
      } = await ProductsApi.getProcessManagement({
        titleId: ids.titleId,
        companyId: ids.companyIds,
      });
      setProcessManagement(data);
      const listNameCampign = data?.listCampaignProcess?.map((item: any) => {
        return item.campaignName;
      });
      setListCampaignName(Array.from(new Set(listNameCampign)));

      setOnSelect(data?.titleName);
      setListComment(data?.listTitleProcess);
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (onSelect === titleName) {
      setListComment(listTitleProcess);
      return;
    }
    const dataComment =
      listCampaignProcess.filter((element: any) => element.campaignName === onSelect) || [];
    setListComment(dataComment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSelect, processManagement]);

  const renderComent = (item: any) => {
    return (
      <Row className="pt-2 mb-2">
        <Col>
          <span>{item.authorName}</span>
          <span className="float-end">{dayjs(item.createdAt).format(DATE_FORMAT_CMT)}</span>
          <hr className="m-0 mt-1" />
          <div className="pe-1">
            <blockquote className="border-0 bg-transparent  mt-1 w-100 pe-none text-prev-wrap">
              {item.note}
            </blockquote>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <BoxForm classBox={`${titleName || 'd-none'}`} title="コメント">
        <>
          <SpinnerComponent isLoading={isLoading} />

          <div className="d-flex">
            {image ? (
              <img
                className="img-products border ms-3"
                alt="preview"
                src={handleConnectStaticEndPoint(image)}
              />
            ) : (
              <div className="avatar-title bg-gray avatar-bank d-flex justify-content-center align-items-center">
                <p className="gray-dark">No Image</p>
              </div>
            )}
            <Col md={7} className="ms-4">
              <p className="fs-11">タイトル</p>
              <div>
                <span className="fw-bold">{titleName}</span>
                <span className="ps-4 fs-11">{artistName}</span>
              </div>
              <p className="fs-11 mt-2">バリエーション</p>
              {variationNames?.map((name: string, index: number) => {
                return (
                  <span className={`fw-bold ${index > 0 && 'ps-3'}`} key={index}>
                    {name}
                  </span>
                );
              })}
            </Col>
          </div>
          <Row className="mt-4">
            <Col md={2} className="p-0">
              <Row>
                <Button
                  onClick={() => setOnSelect(titleName)}
                  variant="light"
                  className={`custom-border mb-2 ${
                    onSelect === titleName && 'bg-dark text-white opacity-100'
                  }`}
                >
                  {titleName}
                </Button>
                {listCampaignName.map((nameCampaign: string, index: number) => {
                  return (
                    <Button
                      key={index}
                      onClick={() => setOnSelect(nameCampaign)}
                      variant="light"
                      className={`custom-border mb-2 ${
                        onSelect === nameCampaign && 'bg-dark text-white opacity-100'
                      }`}
                    >
                      {nameCampaign}
                    </Button>
                  );
                })}
              </Row>
            </Col>

            <Col>
              <div className="layout-comment rounded border overflow-auto py-1">
                {listComment.map((el: any) => {
                  return renderComent(el);
                })}
              </div>
            </Col>
          </Row>
        </>
      </BoxForm>
    </>
  );
}
