import React, { memo, useMemo } from 'react';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import TableCommon from 'Components/Common/Table/TableCommon';
import { SortMethod } from 'Components/Common/Table/table';
import { formatCurrency } from 'Utils/Numbers';

interface IListCD {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const TableList = ({
  dataSource,
  onSort,
}: {
  dataSource: any;
  onSort?: (sortingState: SortMethod[]) => void;
}): JSX.Element => {
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'pe-none',
        columns: [
          {
            Header: 'ID',
            accessor: 'id',
          },
          {
            Header: 'アーティスト',
            accessor: 'artistName',
          },
          {
            Header: 'タイトル',
            accessor: 'titleName',
          },
          {
            Header: 'CD商品名 (バリエーション)',
            accessor: 'name',
            Cell: ({ row }: Cell<IListCD>) => {
              return (
                <Link
                  to={{
                    pathname: `/cd-master/${row.original.id}`,
                    state: { id: row.original.id },
                  }}
                  className={`${!row.original.id && 'text-decoration-none text-black pe-none'}`}
                >
                  <span className="d-block text-truncate text-start">{row.original.name}</span>
                </Link>
              );
            },
          },
          {
            Header: '品番',
            accessor: 'productCode',
            Cell: ({ row }: Cell<IListCD>) => {
              return <span className="d-block text-break">{row?.original?.productCode}</span>;
            },
          },
          {
            Header: '販売価格',
            accessor: 'price',
            Cell: ({ row }: any) => {
              const { price } = row?.original;
              return (
                <div className="d-flex justify-content-center">
                  <p>{formatCurrency(price)}</p>
                </div>
              );
            },
          },
        ],
      },
    ],
    [],
  );

  return (
    <Row className="pb-3">
      <SpinnerComponent isLoading={false} />
      <Col className="px-4">
        <TableCommon
          classTable="table-list-cd"
          heightBody="calc(100vh - 205px)"
          data={dataSource}
          columns={columns}
          noDataText="検索結果がありません。"
          manualSortBy
          onSort={onSort}
        />
      </Col>
    </Row>
  );
};

export default memo(TableList);
