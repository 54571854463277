/* eslint-disable react-hooks/exhaustive-deps */
import TableSaleChannelProps from 'Components/Page/SaleChannel/TableSaleChannel';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Form } from 'react-bootstrap';
import ModalComponent from 'Components/Common/Modal';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { stringRequiredTrim } from 'Utils/Validation';
import { useForm } from 'react-hook-form';
import { ISaleChannel, SaleChannelApi } from 'Datasource/SaleChannelApi';
import useMessage from 'Hooks/useMessage';
import { isEmpty, isEqual } from 'lodash';
import useTitlePage from 'Hooks/useTitlePage';
const schema = yup
  .object()
  .shape({
    name: stringRequiredTrim({}),
  })
  .required();

export default function SaleChannel(): JSX.Element {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [isDeleted, setIsDelete] = useState<boolean>(false);
  const [list, setList] = useState<ISaleChannel[]>([]);
  const [channelId, setChannelId] = useState<number | null>(null);

  const { openMessageError } = useMessage();
  const { setMenuTitle } = useTitlePage();

  useEffect(() => {
    setMenuTitle('販売チャネルリスト');
    getSaleChannels();
  }, []);

  const getSaleChannels = async () => {
    try {
      const res = await SaleChannelApi.getTableSaleChannels();
      const { data } = res.data;
      setList(data);
    } catch (error) {
      openMessageError(error);
    }
  };
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ mode: 'onSubmit', resolver: yupResolver(schema) });
  const onSubmitModal = handleSubmit((values) => {
    if (channelId) {
      handleUpdateChannel();
    } else {
      handleCreateChannel(values);
    }
  });

  const handleCreateChannel = async (values: any) => {
    if (isEmpty(errors)) {
      setIsloading(true);
      try {
        setShowModal(false);
        await SaleChannelApi.createSaleChannel(values.name);
        getSaleChannels();
      } catch (error) {
        openMessageError(error);
      } finally {
        setIsloading(false);
      }
    } else {
      throw new Error(`Unknown error: ${JSON.stringify(errors)}`);
    }
  };

  const handleUpdateChannel = async () => {
    if (isEmpty(errors) && channelId) {
      setIsloading(true);
      try {
        setShowModal(false);
        const data: ISaleChannel = { id: channelId, name: watch('name') };
        const updatedList = [...list];
        const updatedItem = updatedList.find((item) => item.id === channelId);
        if (updatedItem) {
          // oldData === newData stop call Api
          if (!isEqual(data.name, updatedItem?.name)) {
            await SaleChannelApi.updateSaleChannel(data);
            updatedItem.name = data.name;
            setList(updatedList);
          }
        }
      } catch (error) {
        openMessageError(error);
      } finally {
        setIsloading(false);
      }
    } else {
      throw new Error(`Unknown error: ${JSON.stringify(errors)}`);
    }
  };

  const handleDeleteChannel = async () => {
    setIsloading(true);
    try {
      setShowModal(false);
      if (channelId) {
        await SaleChannelApi.deleteSaleChannel(channelId);
        const deletedArr = list.filter((item) => item.id !== channelId);
        setList(deletedArr);
      }
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsloading(false);
    }
  };

  const showAddModal = () => {
    setIsDelete(false);
    if (channelId) {
      setChannelId(null);
    }
    setValue('name', '');
    setShowModal(true);
  };

  const showUpdateModal = (data: ISaleChannel) => {
    setIsDelete(false);
    setValue('name', data.name);
    setChannelId(data.id);
    setShowModal(true);
  };

  const showDeleteModal = (id: number) => {
    setIsDelete(true);
    setChannelId(id);
    setShowModal(true);
  };

  return (
    <Container className="py-4">
      <ModalComponent
        title={isDeleted ? '削除確認' : '登録確認'}
        show={showModal}
        onCloseModal={() => setShowModal(false)}
        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
        variantButtonSubmit="primary"
        submitIdForm="sale-channel-form"
        onSubmitModal={() => (isDeleted ? handleDeleteChannel() : onSubmitModal)}
        confirmText={isDeleted ? '削除する' : '登録する'}
      >
        {isDeleted ? (
          <div>チャネルを削除します。よろしいですか。</div>
        ) : (
          <Form
            onSubmit={onSubmitModal}
            id="sale-channel-form"
            className="d-flex flex-column w-100 align-items-center"
          >
            <FormGroupInput
              value={watch('name')}
              onChange={(value) => setValue('name', value ?? '')}
              labelMd={12}
              colMd={12}
              label="チャネル名"
              errorMessage={errors.name?.message}
              register={{ ...register('name') }}
              classForm="w-100"
            />
            <div>入力した内容で登録します。よろしいですか。</div>
          </Form>
        )}
      </ModalComponent>
      <div className="d-flex mx-3 justify-content-end">
        <Button className="px-5 btn-equal-width" variant="primary" onClick={showAddModal}>
          新規登録
        </Button>
      </div>
      <Row>
        <Col md="12">
          <TableSaleChannelProps
            dataSource={list}
            loading={isLoading}
            onDelete={showDeleteModal}
            onRowClick={showUpdateModal}
          />
        </Col>
      </Row>
    </Container>
  );
}
