/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import brAxios from 'Api/Interceptors';

interface QueryParams {
  page?: number;
  perPage?: number;
  query?: string;
  type?: string;
  companyId?: string;
  artistId?: string;
  titleId?: string;
  showDraft?: boolean;
}

export const VariationApi = {
  getAllSuggestionVariations: ({
    page,
    perPage,
    query,
    companyId,
    artistId,
    titleId,
    showDraft,
  }: QueryParams) => {
    const response = brAxios.get(
      `/suggestion/variations?page=${page ?? ''}&limit=${perPage ?? ''}&query=${
        query ?? ''
      }&companyId=${companyId ?? ''}&artistId=${artistId ?? ''}&titleId=${
        titleId ?? ''
      }&showDraft=${showDraft}`,
    );
    return response;
  },
};
