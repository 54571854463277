import React, { useEffect, useState } from 'react';
import { Button, Col, Container } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { IParamsURL } from 'Types/Page/Title/Review';
import { TitleApi } from 'Datasource/TitleApi';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import CardContainer from 'Components/Common/CardContainer';
import TitleInformation from 'Components/Page/Title/Copm/TitleInformation';
import VariationInformation from 'Components/Page/Title/Copm/VariationInformation';
import { handleConvertTitleInfo } from 'Utils/Title';
import useMessage from 'Hooks/useMessage';

export default function Review(): JSX.Element {
  const { id }: IParamsURL = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  const history = useHistory();

  const { openMessageError } = useMessage();

  const getTitleDetail = async (id: string | undefined) => {
    if (!id) return;
    try {
      setLoading(true);
      const {
        data: { data },
      } = await TitleApi.getOneTitle(id);
      setData(handleConvertTitleInfo(data));
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTitleDetail(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      {data?.id && (
        <Container className="mb-2" id="review">
          <CardContainer title="タイトル情報" darkHeader className="mb-3 mt-2">
            <Col className="mx-4 mt-3">
              <span>登録状況</span>

              <Button className={`btn me-2 h-35px mw-35 ms-4 ${!data.status && 'btn-gradients'}`}>
                <p>{!data.status ? '一時保存' : '登録済'}</p>
              </Button>
            </Col>
            <TitleInformation titleForm={data} />
            {data?.variations?.map((item: any, index: number) => (
              <VariationInformation
                data={item}
                key={index}
                index={index}
                countVariation={data?.variations?.length}
              />
            ))}
          </CardContainer>
          <div className="d-flex justify-content-center align-items-center pb-4">
            <Button
              className="btn-equal-width btn-focus-none my-3"
              onClick={() => history.push('/title/list')}
            >
              一覧へ戻る
            </Button>
          </div>
        </Container>
      )}
    </>
  );
}
