import React from 'react';
import { Container } from 'react-bootstrap';
import { FilterProps } from 'Components/Common/Filter/filter-types';

export default function Filter({ children, filterClass }: FilterProps): JSX.Element {
  return (
    <Container fluid className={`px-4 py-3 border-bottom ${filterClass}`}>
      {children}
    </Container>
  );
}
