/* eslint-disable react-hooks/exhaustive-deps */
import PaginationComponent from 'Components/Common/Pagination/Pagination';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { PAGE_SIZE, PER_PAGE } from 'Constant';
import { CampaignApis } from 'Datasource/Campaign';
import useMessage from 'Hooks/useMessage';
import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { PrizeResponse } from 'Types/Page/Campaign/Campaign';
import { useQueryParams, NumberParam, StringParam } from 'use-query-params';
import TablePrize from 'Components/Page/Campaign/StatisticCampaign/Tables/TablePrize';
import { statisticTab } from 'Constant/Campaign';
import { SortMethod } from 'Components/Common/Table/table';
import { handleEndDate, handleStartDate } from 'Utils/DateTime';
interface Props {
  campaignId: string;
}
export default function WinningResult({ campaignId }: Props): JSX.Element {
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    courseId: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    courseType: NumberParam,
    prizeId: StringParam,
    status: StringParam,
    sort: StringParam,
    order: StringParam,
  });

  const { openMessageError } = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [dataWinning, setDataWinning] = useState<PrizeResponse>({ data: [], total: 0 });

  useEffect(() => {
    if (query.status === statisticTab.WINNING_RESULT) getWinningResult();
  }, [query]);

  const getWinningResult = async () => {
    if (!query.courseId && !query.prizeId) {
      setDataWinning({ data: [], total: 0 });
      return;
    }

    try {
      setLoading(true);
      const res = await CampaignApis.getPrizes({
        ...query,
        page: query.page ?? 1,
        limit: PER_PAGE,
        campaignId,
        sort: query.sort || 'id',
        order: query.order || 'DESC',
        startDate: handleStartDate(query.startDate) || '',
        endDate: handleEndDate(query.endDate) || '',
      });
      const { data } = res.data;
      setDataWinning(data);
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  //* handle sort from server side
  const handleManualSort = useCallback(
    (sortBy: SortMethod[]) => {
      if (sortBy.length > 0) {
        const sortOption = sortBy[0];
        setQuery({ sort: sortOption.id, order: sortOption.desc ? 'DESC' : 'ASC' });
      } else {
        setQuery({ sort: '', order: '' });
      }
    },
    [setQuery],
  );

  return (
    <>
      <SpinnerComponent isLoading={loading}></SpinnerComponent>
      <Row className="p-4">
        <Col className="d-flex justify-content-end mt-2">
          <PaginationComponent
            activePage={query.page || 1}
            total={dataWinning?.total || 0}
            perPage={PAGE_SIZE}
          />
        </Col>
      </Row>
      <Row className="px-4 pb-5">
        <TablePrize dataSource={dataWinning.data} onSort={handleManualSort} />
      </Row>
    </>
  );
}
