import React, { PropsWithChildren } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import MessageError from 'Components/Common/MessageError';
import DateSelector from 'Components/Common/DateSelector';
import { FormGroupDateProps } from 'Components/Common/Form/FormGroupDate/group-date';
import { labelRequire } from 'Components/Common/Form/FormLabel';

function FormGroupDate({
  label,
  labelMd,
  colMd,
  classForm,
  classLabel,
  classSubcontent,
  onChange,
  children,
  classCol,
  errorMessage,
  selected,
  register,
  minDate = new Date(),
  disabled = false,
  showTimeSelectOnly = false,
  subContent,
  minTime,
  maxTime,
  colDP = 12,
  ...props
}: PropsWithChildren<FormGroupDateProps>): JSX.Element {
  return (
    <Form.Group as={Row} className={`mb-3 ${classForm || ''}`}>
      <Form.Label column md={labelMd}>
        {label && labelRequire(label, classLabel)}
      </Form.Label>
      <Col md={colMd} className={classCol}>
        <Row>
          <Col md={colDP} className="px-0">
            {children ?? (
              <DateSelector
                selected={selected}
                {...props}
                {...register}
                onChange={(date) => onChange && onChange(date)}
                disabled={disabled}
                minDate={minDate}
                showTimeSelectOnly={showTimeSelectOnly}
                minTime={minTime}
                maxTime={maxTime}
              />
            )}
            {subContent && <p className={`fs-12 mt-1 ${classSubcontent}`}>{subContent}</p>}
          </Col>
          <MessageError message={errorMessage} />
        </Row>
      </Col>
    </Form.Group>
  );
}
export default FormGroupDate;
