import React, { useEffect, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { find } from 'lodash';
import CardWrapper from 'Components/Common/Card/Card';
import ViewOnlyField from 'Components/Page/Campaign/Detail/ViewOnlyField';
import { DATE_FORMAT_13 } from 'Constant/Date';
import TableCampaignView from 'Components/Page/Campaign/Detail/TableCampaignView';
import TableWinningConditions from 'Components/Page/Campaign/Detail/TableWinningConditions';
import PrizesTable from 'Components/Page/Campaign/Detail/PrizesTable';
import TableUserResult from 'Components/Page/Campaign/Detail/TableUserResult';
import {
  attachedBonus,
  awardSettings,
  CourseTypes,
  distributionSettingOptions,
  methodOptions,
  subTypeOptions,
} from 'Constant/Campaign';
import { formatDate } from 'Utils/DateTime';
import { CampaignApis } from 'Datasource/Campaign';
import { useHistory, useParams } from 'react-router-dom';
import { CourseConditionsOnlyView } from 'Page/Campaign/ConditionRow';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import useMessage from 'Hooks/useMessage';
import { CourseCampaign } from 'Types/Page/Campaign/RegisterCampaign';
import { formatCurrency } from 'Utils/Numbers';

const FIELD_SIZE = {
  label: 2,
  value: 10,
};

const CampaignDetail = (): JSX.Element => {
  const formSize = {
    labelSize: FIELD_SIZE.label,
    valueSize: FIELD_SIZE.value,
  };
  const { openMessageError } = useMessage();
  const { id }: { id: string } = useParams();

  const [campaignRes, setCampaignRes] = useState<any>();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleGetCampaign = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await CampaignApis.getCampaign({ id });
      const overview = {
        artistId: data?.artistId,
        artistName: data?.artistName,
        titleId: data?.titleId,
        titleName: data?.titleName,
        name: data?.name,
        startDate: data?.startDate,
        endDate: data?.endDate,
        description: data?.description,
        dataSize: +data?.dataSize ?? 0,
        status: data?.status,
      };

      const tmpCourses = data?.courses.map((course: any) => {
        const prizes = course.prizes.map((prize: any, prizeIndex: number) => {
          const conditionArrValue = Array.from(prize.matrix);
          const conditionArr = [...Array(course.prizes.length)].map((n, i) => ({
            [`condition${i}`]: Number(conditionArrValue[i]),
          }));
          const conditionObj = conditionArr.reduce((map, item) => {
            return { ...map, ...item };
          }, {});

          return {
            ...prize,
            index: prizeIndex,
            abandoned: prize.abandoned ? prize.abandoned : 0,
            onlyOne: prize.onlyOne ? prize.onlyOne : 0,
            ...conditionObj,
          };
        });
        return { ...course, prizes };
      });
      setCampaignRes({ overview, courses: tmpCourses });
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      handleGetCampaign();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const renderCourseType = (course: CourseCampaign) => {
    const courseTypeName =
      find(methodOptions, (option) => option.value === course.type)?.label ?? '';
    const courseSubTypeName =
      find(subTypeOptions, (option) => option.value === course.subType)?.label ?? '';
    return course?.type === CourseTypes.REALTIME
      ? `${courseTypeName} - ${courseSubTypeName}`
      : courseTypeName;
  };

  return (
    <div className="pb-3">
      <SpinnerComponent isLoading={loading} />
      <CardWrapper title="キャンペーン概要" className="mb-3 px-4">
        <ViewOnlyField
          {...formSize}
          label="対象選択"
          value={`${campaignRes?.overview?.artistName ?? '-'}  ${
            campaignRes?.overview?.titleName ?? '-'
          }`}
        />
        <ViewOnlyField {...formSize} label="キャンペーン名称" value={campaignRes?.overview?.name} />
        <ViewOnlyField
          {...formSize}
          label="販売期間"
          value={
            campaignRes?.overview?.startDate &&
            `${formatDate(campaignRes?.overview?.startDate, DATE_FORMAT_13)} ~ ${formatDate(
              campaignRes?.overview?.endDate,
              DATE_FORMAT_13,
            )}`
          }
        />
        <ViewOnlyField
          {...formSize}
          label="キャンペーン概要"
          value={campaignRes?.overview?.description || '-'}
          prevLine
        />
      </CardWrapper>
      {campaignRes?.courses?.map((course: any, courseIndex: number) => {
        return (
          <CardWrapper
            title={`応募コース設定内容 (${courseIndex + 1}/${campaignRes?.courses?.length})`}
            className="mb-3 px-4 view-only"
            key={courseIndex}
          >
            <div className="mb-5">
              <ViewOnlyField {...formSize} label="応募コース名称" value={course?.name} />
              <ViewOnlyField
                {...formSize}
                label="応募方法"
                value={
                  find(distributionSettingOptions, (option) => option.value === course.option)
                    ?.label ?? ''
                }
              />
              <ViewOnlyField {...formSize} label="抽選方法" value={renderCourseType(course)} />

              <ViewOnlyField
                {...formSize}
                label="抽選日時"
                value={formatDate(course?.standardTime, DATE_FORMAT_13)}
              />
              <ViewOnlyField
                {...formSize}
                label="応募期間"
                value={
                  (course?.timeJoinStart &&
                    `${formatDate(course?.timeJoinStart, DATE_FORMAT_13)} ~ ${formatDate(
                      course?.timeJoinEnd,
                      DATE_FORMAT_13,
                    )}`) ||
                  '-'
                }
              />
              {course.type === CourseTypes.ALL && (
                <ViewOnlyField
                  {...formSize}
                  label="景品付与設定"
                  value={
                    find(attachedBonus, (option) => option.value === course.receiveType)?.label ??
                    ''
                  }
                  isMessageInside
                />
              )}
              {course.type === CourseTypes.REALTIME && (
                <>
                  <ViewOnlyField
                    {...formSize}
                    label="初日の応募予想数"
                    value={course?.countJoinStart ?? '-'}
                    isMessageInside
                  />
                  <ViewOnlyField
                    {...formSize}
                    label="最終日の応募予想数"
                    value={course?.countJoinEnd ?? '-'}
                    isMessageInside
                  />
                </>
              )}
              <ViewOnlyField
                {...formSize}
                label="賞の指定可否"
                value={
                  find(awardSettings, (option) => option.value === course.prizeOption)?.label ?? ''
                }
              />
              <ViewOnlyField
                {...formSize}
                label="応募一口金額"
                value={`${
                  course?.ticketPrice && course.option === distributionSettingOptions[1].value
                    ? `¥ ${formatCurrency(course?.ticketPrice)}`
                    : '-'
                }`}
                isMessageInside
              />
              <ViewOnlyField
                {...formSize}
                label="販売数上限"
                value={`${
                  course.subType === subTypeOptions[1].value
                    ? '上限なし'
                    : course?.prizeQuantity && course.subType === subTypeOptions[0].value
                    ? course?.prizeQuantity
                    : '-'
                }`}
                isMessageInside
              />
            </div>
            <TableCampaignView
              dataSource={course?.courseInfos}
              nestIndex={courseIndex}
              isShowConditions={course?.option === distributionSettingOptions[2].value}
              titleName={campaignRes?.overview?.titleName}
            />
            {course?.option === distributionSettingOptions[2].value && (
              <Row className="mb-3">
                <div>応募権利取得条件</div>
                <div className="mt-2 ps-3 fs-12">
                  <p>条件1～条件4をすべて満たしている必要があります。</p>
                  <CourseConditionsOnlyView info={course?.courseInfos} />
                </div>
              </Row>
            )}
            <PrizesTable
              dataSource={course?.prizes}
              titleName={campaignRes?.overview?.titleName}
              showWinRate={course?.type === 2 && [0, 1].includes(course?.subType)}
            />
            <TableWinningConditions prizes={course.prizes} courseIndex={courseIndex} isReview />
            <CardWrapper title="ブラックリスト" className="card-wrapper-gray card-blacklist px-0">
              <TableUserResult dataSource={course?.blackListUsers ?? []} />
            </CardWrapper>
          </CardWrapper>
        );
      })}
      <div className="pe-auto d-flex justify-content-center align-items-center pb-4">
        <Button
          className="btn-equal-width btn-focus-none my-3"
          onClick={() => history.push('/campaigns')}
        >
          一覧へ戻る
        </Button>
      </div>
    </div>
  );
};

export default CampaignDetail;
