import React, { useState } from 'react';
import CuratorLists from 'Page/CuratorManagement/List';
import Tabs from 'Components/Common/Tabs/Tabs';
import Filter from 'Components/Common/Filter/Filter';
import CuratorRegister from 'Page/CuratorManagement/Register';
import { includes } from 'lodash';
import { RoleNames } from 'Constant/Roles';
import { useAppSelector } from 'App/Store';

interface ListItem {
  id: number;
  label: string;
  value: string;
}

export interface Curator {
  curatorManagementName?: string;
  email?: string;
  katakanaName: string;
  id: number | null;
  managerEmail?: string;
  managerName?: string;
  managerPermisson?: string;
  managerPhone?: string;
  listChannelSelected?: ListItem[];
  listCompanySelected: ListItem[];
}

export default function CuratorManagement(): JSX.Element {
  const { role } = useAppSelector((state) => state.auth);

  const btnGroupOptions = [
    { name: '担当者リスト' },
    { name: '担当者登録', disabled: !includes(RoleNames[0], role) },
  ];

  const [tabActive, setTabActive] = useState(btnGroupOptions[0].name);

  return (
    <div>
      <Filter filterClass="shadow-sm">
        <Tabs
          options={btnGroupOptions}
          tabActive={tabActive}
          onChange={setTabActive}
          disabledSetQuery={true}
          btnClass="tab-width"
        />
      </Filter>
      <div>
        {tabActive === btnGroupOptions[0].name ? (
          <CuratorLists />
        ) : (
          <CuratorRegister goBackToList={() => setTabActive(btnGroupOptions[0].name)} />
        )}
      </div>
    </div>
  );
}
