import React, { PropsWithChildren } from 'react';
import { Accordion, Card, useAccordionButton } from 'react-bootstrap';
import { Dash, Plus } from 'react-bootstrap-icons';
import { CardContainerProps } from 'Components/Common/CardContainer/card';

function CardContainer({
  children,
  className,
  title,
  darkHeader,
  eventKey,
  activated,
  onCollapse,
  headerClass,
  renderTitleHeader,
  notCollapseBody = false,
}: PropsWithChildren<CardContainerProps>): JSX.Element {
  const decoratorOnClick = useAccordionButton(
    eventKey || '',
    () => onCollapse && onCollapse(eventKey || ''),
  );

  const renderHeaderClass = () => {
    let txtClass = 'black';
    if (headerClass) {
      txtClass = headerClass;
    }
    if (!headerClass && darkHeader) {
      txtClass = 'bg-dark white';
    }
    return txtClass;
  };

  return (
    <Card className={`shadow-sm ${className}`}>
      {title && (
        <Card.Header className={renderHeaderClass()}>
          <div className="d-flex justify-content-between align-items-center">
            <p className="mb-0 text-truncate w-85">
              {title}
              {renderTitleHeader}
            </p>
            {eventKey && (
              <button
                className="d-flex align-items-center bg-transparent border-0 collapse-btn"
                onClick={decoratorOnClick}
              >
                <span className="d-flex justify-content-center align-items-center rounded collapse-minus-icon white">
                  {activated ? <Dash /> : <Plus />}
                </span>
                <p className="ms-2 white">{activated ? '縮⼩表⽰' : '詳細表示'}</p>
              </button>
            )}
          </div>
        </Card.Header>
      )}
      {eventKey && !notCollapseBody ? (
        <Accordion.Collapse eventKey={eventKey}>
          <Card.Body>{children}</Card.Body>
        </Accordion.Collapse>
      ) : (
        <Card.Body>{children}</Card.Body>
      )}
    </Card>
  );
}
export default CardContainer;
