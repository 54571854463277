import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Filter from 'Components/Common/Filter/Filter';
import TableList from './TableList';
import useMessage from 'Hooks/useMessage';
import { ECApis } from 'Datasource/EC';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import dayjs from 'dayjs';
import DropdownYearMenu from 'Components/Common/EC/DropdownYearMenu';

interface GroupDate {
  year: string;
}
const currentYear = dayjs().year();

export default function PaymentNotificationList(): JSX.Element {
  const { openMessageError } = useMessage();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [groupOptions, setGroupOptions] = useState<Array<GroupDate>>([
    { year: `${currentYear}年` },
  ]);

  const [activeOption, setActiveOption] = useState(groupOptions[0]?.year);

  const fetchGroupDate = async () => {
    setIsLoading(true);
    try {
      const res = await ECApis.getTranferGroupDate();
      const { data } = res.data;
      setGroupOptions(data);
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchGroupDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container fluid>
      <SpinnerComponent isLoading={isLoading} />
      <Filter filterClass={`shadow-sm `}>
        <div>表示年</div>
        <DropdownYearMenu
          options={groupOptions}
          activeOption={activeOption}
          onChange={setActiveOption}
          classButton="payment-advice-dropdown-menu"
        />
      </Filter>
      <TableList year={activeOption}></TableList>
    </Container>
  );
}
