import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NFTState {
  companyId?: string;
}

const initialState: NFTState = {
  companyId: '',
};

export const nftSlice = createSlice({
  name: 'nft',
  initialState,
  reducers: {
    setProductsFilter: (state, data: PayloadAction<{ companyId: string }>) => {
      const { payload } = data;
      return { ...payload };
    },
  },
});

export const { setProductsFilter } = nftSlice.actions;
export default nftSlice.reducer;
