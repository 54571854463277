/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import TableCommon from 'Components/Common/Table/TableCommon';
import { formatCurrency } from 'Utils/Numbers';
import { Cell } from 'react-table';
import AliTable, { AliColumn } from 'Components/Common/Table/AliTable';

//* TAX = 1 + 10%
const TAX = 1.1;
interface TableCostMiniProps {
  dataSource: any[];
  title?: string;
}
const TableCdTotalReport = ({ dataSource, title }: TableCostMiniProps): JSX.Element => {
  const column = useMemo(
    () => [
      {
        Header: '',
        accessor: 'index',
        className: 'pe-none',
        columns: [
          {
            Header: '原価合計',
            accessor: 'totalCost',
            width: '100',
            className: 'hidden-sort',
            Cell: ({ row }: Cell<any>) => {
              return (
                <div>
                  {row?.original?.totalCost ? formatCurrency(row?.original?.totalCost) : ''}
                </div>
              );
            },
          },
        ],
      },
    ],
    [],
  );

  const aliColumns = useMemo(
    () =>
      [
        {
          name: '原価合計',
          width: 100,
          code: 'dateTotalCost',
          features: { sortable: false },
          align: 'center',
          render: (value: any) => {
            return <div className={`text-end`}>{value ? formatCurrency(value) : ''}</div>;
          },
        },
      ] as AliColumn[],
    [],
  );
  return (
    <div className="d-flex flex-column me-3">
      <div className="text-center d-flex align-items-center fw-bold">{title}</div>
      <TableCommon
        data={dataSource || []}
        columns={column}
        getHeaderProps={() => {
          return {
            className: 'text-white d-flex align-items-center bg-dark text-center',
          };
        }}
        getColumnProps={() => {
          return {
            className: 'text-end',
          };
        }}
        getRowProps={() => {
          return {
            style: { minHeight: '32px' },
          };
        }}
        getCellProps={() => {
          return {
            className: 'bg-white text-dark',
          };
        }}
      />
      <AliTable
        wrapperClass="max-w-240px table-daily_total"
        heightBody="auto"
        dataSource={dataSource[0]?.days || []}
        columns={aliColumns}
        noDataText={null}
      ></AliTable>
    </div>
  );
};

export default TableCdTotalReport;
