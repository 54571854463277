import React from 'react';
import { Col } from 'react-bootstrap';
import CardContainer from 'Components/Common/CardContainer';
import GroupTextProps from 'Components/Page/Title/FormTextGroup';
import { titleText } from 'Constant/Texts/Title';

interface TitleInformationProps {
  titleForm?: any;
}

export default function TitleInformation(props: TitleInformationProps): JSX.Element {
  const { titleForm } = props;
  return (
    <>
      <CardContainer title="タイトル情報" darkHeader className="mx-4 mt-5 border-0 shadow-none">
        <Col md={{ span: 10, offset: 0 }}>
          <GroupTextProps label="アーティスト" value={titleForm?.artistName} />
          <GroupTextProps label="タイトル名" value={titleForm?.name} />
          <GroupTextProps label="タイトル名（フリガナ）" value={titleForm?.furiganaName} />
          <GroupTextProps label="タイトル名（英語表記）" value={titleForm?.globalName} />
          <GroupTextProps
            label="音楽ジャンル"
            value={titleForm?.musicGenreName || titleForm?.musicGenreId}
          />
          <GroupTextProps label="レーベル名" value={titleForm?.label} />
          <GroupTextProps
            label={titleText.iap.label}
            value={titleForm?.sellIap === 1 ? '販売する' : ''}
          />
        </Col>
      </CardContainer>
    </>
  );
}
