/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';
import BoxForm from 'Components/Common/Form/BoxForm';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import GroupCheckBox from 'Components/Common/Form/GroupCheckBox';
import { IDigitalUploadFile } from 'Types/Common';
import FormGroupDateRange from 'Components/Common/Form/FormGroupDateRange';
import FormGroupDate from 'Components/Common/Form/FormGroupDate';
import 'Page/PromotionManager/index.scss';
import { useHistory } from 'react-router-dom';
import { PromotionManagerApi } from 'Datasource/PromotionManager';
import { TOKUTEN_TYPE_MEDIA } from 'Constant';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import ItemDigital from 'Components/Page/DigitalPrivilege/ItemDigital';
import useMessage from 'Hooks/useMessage';
import { ICheckBox, ISateForm, IListTokutenCommon } from 'Types/Page/PromotionManager';
const defaultCheckboxOptions: ICheckBox[] = [
  {
    label: '共通コンテンツ',
    subLabel: '選択しているバリエーション全てに設定される特典です',
    checked: false,
    value: '0',
  },
  {
    label: '個別コンテンツ',
    subLabel: '個別コンテンツの追加により販売商品の組み合わせを増やすことができます',
    checked: false,
    value: '1',
  },
];

export default function DigitalPrivilege(): JSX.Element {
  const history = useHistory();
  const { openMessageError } = useMessage();
  const { id } = useParams<{ id: string }>();
  const convertTypeMedia = (objMedias: IDigitalUploadFile[], typeMedia: string) => {
    return objMedias
      .filter((item: IDigitalUploadFile) => item.type == typeMedia)
      .map((item: IDigitalUploadFile) => {
        return {
          content: item.description,
          URL: `${process.env.REACT_APP_STATIC_URL}${item?.url}`,
          type: item.type,
          id: item.id,
          title: item?.title,
        };
      });
  };
  const [stateForm, setStateForm] = useState<ISateForm>({
    artistName: '',
    titleName: '',
    variationName: '',
    tokutenName: '',
    ECName: '',
    type: '',
    dateDownload: '',
    startSaleDate: '',
    endSaleDate: '',
    digitalImage: [],
    digitalAudio: [],
    digitalVideo: [],
    digitalCommons: [],
  });
  useEffect(() => {
    const handleGetDataDetail = async () => {
      if (id) {
        try {
          setLoading(true);
          const reponseDataDetail = await PromotionManagerApi.getDetailPromotion(id);
          const {
            artists_name,
            titles_name,
            variations_name,
            digital_tokutens_name,
            intro_ec,
            download_date,
            sale_end_date,
            sale_start_date,
            content_type,
            digitalTokutenMedia,
            digitalTokutenCommons,
          } = reponseDataDetail.data.data;
          const listImageDigitals = convertTypeMedia(
            digitalTokutenMedia,
            TOKUTEN_TYPE_MEDIA.IMAGE.toString(),
          );

          const listAudioDigitals = convertTypeMedia(
            digitalTokutenMedia,
            TOKUTEN_TYPE_MEDIA.AUDIO.toString(),
          );

          const listVideoDigitals = convertTypeMedia(
            digitalTokutenMedia,
            TOKUTEN_TYPE_MEDIA.VIDEO.toString(),
          );
          setStateForm({
            ...stateForm,
            artistName: artists_name,
            titleName: titles_name,
            variationName: variations_name,
            tokutenName: digital_tokutens_name,
            ECName: intro_ec,
            type: content_type.toString(),
            dateDownload: new Date(download_date),
            startSaleDate: new Date(sale_start_date),
            endSaleDate: new Date(sale_end_date),
            digitalImage: listImageDigitals,
            digitalAudio: listAudioDigitals,
            digitalVideo: listVideoDigitals,
            digitalCommons: digitalTokutenCommons,
          });

          setLoading(false);
        } catch (error) {
          setLoading(false);
          openMessageError(error);
        }
      }
    };
    handleGetDataDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const [loading, setLoading] = useState<boolean>(false);

  const ElementDigitalComponent = (type: string, data: IDigitalUploadFile) => {
    switch (type) {
      case 'image':
        return (
          <div>
            {data.URL ? (
              <Image src={data?.URL} rounded />
            ) : (
              <Form.Control id={`file-image-${data.id}`} type="file" />
            )}
          </div>
        );
      case 'audio':
        return (
          <div>
            {data.URL ? (
              <>
                <audio controls src={data.URL}>
                  Your browser does not support the
                  <code>audio</code> element.
                </audio>
              </>
            ) : (
              <Form.Control id={`file-audio-${data.id}`} type="file" />
            )}
          </div>
        );
      case 'video':
        return (
          <div>
            {data.URL ? (
              <div className="w-100">
                <video className="w-100" controls>
                  <source src={data.URL} type="video/mp4" />
                  Your browser does not support HTML video.
                </video>
              </div>
            ) : (
              <Form.Control id={`file-video-${data.id}`} type="file" />
            )}
          </div>
        );
      default:
        break;
    }
  };
  return (
    <div className={`main-wrap-digital mt-4`}>
      <SpinnerComponent isLoading={loading} />
      <Container>
        <Row>
          <Col md="8" className="w-100">
            <Form className="pb-5">
              <BoxForm title="デジタル特典情報">
                <>
                  <div className="mb-4">
                    <FormGroupInput
                      labelMd="2"
                      colMd="10"
                      value={stateForm.artistName}
                      label="アーティスト"
                      disabled
                    />
                  </div>
                  <div className="mb-4">
                    <FormGroupInput
                      labelMd="2"
                      colMd="10"
                      value={stateForm.titleName}
                      label="タイトル"
                      disabled
                    />
                  </div>

                  <div className="mb-4">
                    <FormGroupInput
                      labelMd="2"
                      colMd="10"
                      value={stateForm.variationName}
                      label="バリエーション"
                      disabled
                    />
                  </div>

                  <div className="mb-4">
                    <FormGroupInput
                      labelMd="2"
                      colMd="10"
                      value={stateForm.tokutenName}
                      label="特典名称"
                      disabled
                    />
                  </div>

                  <div className="mb-4">
                    <FormGroupInput
                      labelMd="2"
                      colMd="10"
                      value={stateForm.ECName}
                      label="EC用宣伝文"
                      disabled
                    />
                  </div>

                  <div className="mb-4">
                    <GroupCheckBox
                      id="registration-type"
                      labelMd="2"
                      colMd="10"
                      classBoxErr="w-100"
                      labelForm="登録タイプ"
                      value={stateForm.type}
                      disabled
                      options={defaultCheckboxOptions}
                      classForm="d-flex"
                    />
                  </div>
                  <div className="mb-4">
                    <FormGroupDateRange
                      label="販売期間指定*"
                      labelMd="2"
                      colMd="10"
                      startDate={
                        stateForm.startSaleDate ? new Date(stateForm.startSaleDate) : new Date()
                      }
                      endDate={stateForm.endSaleDate ? new Date(stateForm.endSaleDate) : new Date()}
                      onChangeEndDate={() => void 0}
                      onChangeStartDate={() => void 0}
                      startDateDisabled={true}
                      endDateDisabled={true}
                    />
                  </div>
                  <div className="mb-4">
                    <FormGroupDate
                      colDP={5}
                      label="DL可能開始⽇時*"
                      labelMd="2"
                      colMd="10"
                      selected={
                        stateForm.dateDownload ? new Date(stateForm.dateDownload) : new Date()
                      }
                      onChange={() => void 0}
                      classForm="group-date"
                      disabled={true}
                    />
                  </div>
                  <div className="mb-4 ms-2">
                    {stateForm.digitalCommons.length ? <p>登録済みの共通デジタルコンテンツ</p> : ''}
                    {stateForm.digitalCommons.length
                      ? stateForm.digitalCommons.map((item: IListTokutenCommon, index: number) => {
                          return (
                            <p
                              key={index}
                              style={{ marginLeft: '16.6666666667%', paddingLeft: '5px' }}
                            >
                              {item}
                            </p>
                          );
                        })
                      : ''}
                  </div>
                </>
              </BoxForm>
              <div className="mt-5">
                <BoxForm title={`画像コンテンツ（登録可能数：3点）`}>
                  <div className="box-item-main">
                    <div className="box-item-content">
                      {stateForm.digitalImage &&
                        stateForm.digitalImage.map((item: IDigitalUploadFile, index: number) => {
                          return (
                            <div key={index}>
                              <div className="pt-4 pb-3 border-bottom-gray">
                                <ItemDigital
                                  element={ElementDigitalComponent('image', item)}
                                  disabledAllField={true}
                                  labelMd={4}
                                  colMd={8}
                                  titleLeft="EC説明⽂ "
                                  value={item?.content}
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </BoxForm>
              </div>
              <div className="mt-5">
                <BoxForm title={`音源コンテンツ（登録可能数：2点）`}>
                  <div className="box-item-main">
                    <div className="box-item-content">
                      {stateForm.digitalAudio &&
                        stateForm.digitalAudio.map((item: IDigitalUploadFile, index: number) => {
                          return (
                            <div key={index}>
                              <div className="pt-4 pb-3 border-bottom-gray">
                                <ItemDigital
                                  element={ElementDigitalComponent('audio', item)}
                                  disabledAllField={true}
                                  labelMd={4}
                                  colMd={8}
                                  titleLeft="EC説明文"
                                  value={item.content}
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </BoxForm>
              </div>
              <div className="mt-5">
                <BoxForm title={`映像コンテンツ（登録可能数：1点）`}>
                  <div className="box-item-main">
                    <div className="box-item-content">
                      {stateForm.digitalVideo &&
                        stateForm.digitalVideo.map((item: IDigitalUploadFile, index: number) => {
                          return (
                            <div key={index}>
                              <div className="pt-4 pb-3 border-bottom-gray">
                                <ItemDigital
                                  element={ElementDigitalComponent('video', item)}
                                  disabledAllField={true}
                                  labelMd={4}
                                  colMd={8}
                                  titleLeft="EC説明文"
                                  value={item.content}
                                  titleTop="タイトル名"
                                  valueTop={item?.title || ''}
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </BoxForm>
              </div>

              <div className="w-100 text-center">
                <Button
                  className="m-auto my-4 btn-equal-width btn-focus-none"
                  onClick={() => {
                    history.push('/promotion-manager');
                  }}
                >
                  一覧へ戻る
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
