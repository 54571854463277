import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { FormGroupSearchProps } from 'Components/Common/Form/form';
import AsyncSearchComponent from 'Components/Common/Select/CustomSelect/AsyncSearchComponent';
import MessageError from 'Components/Common/MessageError';

export default function FormGroupSearch(props: FormGroupSearchProps): JSX.Element {
  const {
    label,
    classLabel,
    onSearch,
    onSelect,
    placeholder,
    labelMd,
    colMd,
    colLg,
    labelLg,
    rowClass,
    colClass,
    showIcon = false,
    onEnter,
    onInputChange,
    defaultValue,
    errorMessage,
    inputSearchString = '',
    disabled,
    onLoadMore,
    onMenuClose,
    isLoading,
    options,
    onMenuFocus,
    showIconClear = false,
  } = props;
  return (
    <Form.Group as={Row} className={rowClass}>
      {label && (
        <Form.Label className={classLabel} column md={labelMd} lg={labelLg}>
          {label}
        </Form.Label>
      )}
      <Col md={colMd} lg={colLg} className={colClass}>
        <AsyncSearchComponent
          onInputChange={onInputChange}
          defaultValue={defaultValue}
          onEnter={onEnter}
          placeholder={placeholder}
          onSearch={onSearch}
          onSelect={onSelect}
          showIcon={showIcon}
          inputSearchString={inputSearchString}
          disabled={disabled}
          onLoadMore={onLoadMore}
          onMenuClose={onMenuClose}
          isLoading={isLoading}
          options={options}
          onMenuFocus={onMenuFocus}
          showIconClear={showIconClear}
        />
        <MessageError message={errorMessage} />
      </Col>
    </Form.Group>
  );
}
