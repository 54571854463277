/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import brAxios from 'Api/Interceptors';

const CancelTokenGetList = brAxios.CancelToken;
let sourceGetList: any;

export const jasracApi = {
  getList: (params: any) => {
    const query = new URLSearchParams(params).toString();
    // cancel axios request previous
    if (typeof sourceGetList != typeof undefined) {
      sourceGetList.cancel('Operation canceled due to new request.');
    }

    sourceGetList = CancelTokenGetList.source();

    return brAxios.get(`/bootrock-company/titles/export/jasrac/list?${query}`, {
      cancelToken: sourceGetList.token,
    });
  },
  exportTSV: (params: any) => {
    const query = new URLSearchParams(params).toString();
    return brAxios.get(`/bootrock-company/titles/export/jasrac/tsv?${query}`, {
      responseType: 'arraybuffer',
    });
  },
  exportExcel: (params: any) => {
    const query = new URLSearchParams(params).toString();
    return brAxios.get(`/bootrock-company/titles/export/jasrac/excel?${query}`, {
      responseType: 'arraybuffer',
    });
  },
};
