import React from 'react';

export default function useCustomSort() {
  const customStringSort: any = React.useCallback(
    (rowA: any, rowB: any, columnId: string, desc: boolean) => {
      const defaultVal = desc ? 'A' : 'Z';
      return (rowA?.values[columnId]?.trim() ?? defaultVal).localeCompare(
        rowB?.values[columnId]?.trim() ?? defaultVal,
      );
    },
    [],
  );
  return { customStringSort };
}
