import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { InputEvent } from 'Components/Common/Form/form';
import { IDigital } from 'Types/Common';
import MessageError from 'Components/Common/MessageError';
export default function ItemDigital(props: IDigital): JSX.Element {
  const {
    element,
    labelMd,
    colMd,
    titleLeft,
    value,
    valueTop,
    onChange,
    placeholder,
    errorMessage,
    register,
    onSubmit,
    maxLength,
    disabledAllField = false,
    registerName,
    titleTop,
    errorMessageName,
  } = props;
  const [ValueInput, setValueInput] = useState(value ?? '');
  const [valueInputTop, setValueInputTop] = useState(valueTop ?? '');

  useEffect(() => {
    setValueInput(value ?? '');
    setValueInputTop(valueInputTop ?? '');
  }, [value, valueInputTop]);

  const handleOnchange = (e: InputEvent) => {
    setValueInput(e.target.value);
    if (onChange) {
      return onChange(e.target.value);
    }
    return setValueInput(e.target.value);
  };

  const hanlderConfirmModal = () => {
    if (onSubmit) {
      return onSubmit();
    }
    return;
  };

  return (
    <div className="item-digital">
      <Form.Group as={Row} className="mb-2">
        <Col md={labelMd}>
          <div className="box-image">{element}</div>
        </Col>
        <Col md={colMd}>
          <Row className="d-flex flex-wrap align-items-center">
            {titleTop && (
              <>
                <div className="title-left w-15">{titleTop}</div>
                <div className="box-inp w-85">
                  <Form.Control
                    {...registerName}
                    value={valueInputTop && valueInputTop}
                    type="text"
                    disabled={disabledAllField}
                  />
                </div>
                <div className="w-85 ms-auto">
                  <MessageError message={errorMessageName} />
                </div>
                <div className="mt-4" />
              </>
            )}
            <>
              <div className="title-left w-15">{titleLeft}</div>
              <div className="box-inp w-85">
                <Form.Control
                  {...register}
                  value={ValueInput && ValueInput}
                  onChange={handleOnchange}
                  type="text"
                  placeholder={placeholder}
                  maxLength={maxLength}
                  disabled={disabledAllField}
                />
              </div>
              <div className="w-85 ms-auto">
                <MessageError message={errorMessage} />
              </div>
            </>

            <div className="w-100 d-flex justify-content-end mt-3">
              {!disabledAllField && (
                <Button className="ms-auto" variant="danger" onClick={hanlderConfirmModal}>
                  削除する
                </Button>
              )}
            </div>
          </Row>
        </Col>
      </Form.Group>
    </div>
  );
}
