import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import PaginationComponent from 'Components/Common/Pagination/Pagination';
import Filter from 'Components/Common/Filter/Filter';
import ProductArtist from 'Components/Page/Artist/Item';
import { ArtistApi } from 'Datasource/ArtistApi';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import useMessage from 'Hooks/useMessage';
import { PAGE_SIZE_ARTIST } from 'Constant';
import ArtistSearch from 'Page/Artist/ArtistSearch';

type ArtistType = {
  image: string;
  name: string;
};

const perPage = PAGE_SIZE_ARTIST;

export default function Artist(): JSX.Element {
  const [query] = useQueryParams({
    page: NumberParam,
    status: StringParam,
    queryCompany: StringParam,
    queryArtist: StringParam,
    companyIds: StringParam,
    artistIds: StringParam,
  });
  const { openMessageError } = useMessage();
  const [page, setPage] = useState(query.page ?? 1);
  const [total, setTotal] = useState(0);
  const [artists, setArtists] = useState<ArtistType[]>([]);
  const [loading, setLoading] = useState(false);
  const onChangePage = (page: number) => {
    setPage(page);
  };

  const handleGetArtists = async (ids: any) => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await ArtistApi.getArtists({
        page,
        perPage,
        companyIds: ids.companyIds,
        artistIds: ids.artistIds,
      });
      setArtists(data.artists);
      setTotal(data.totalCount);
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const ids = {
      companyIds: query.companyIds ?? '',
      artistIds: query.artistIds ?? '',
    };
    handleGetArtists(ids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, query.companyIds, query.artistIds]);

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <div className="wrap-artist-list">
        <Filter filterClass="d-flex align-items-center shadow-sm">
          <ArtistSearch />
          <Col md={4} className="px-0">
            <PaginationComponent
              onClick={onChangePage}
              activePage={page}
              total={total}
              perPage={perPage}
            />
          </Col>
        </Filter>
        <Container>
          <Row className="list-item mt-4">
            {!isEmpty(artists) ? (
              artists.map((item, index) => {
                return (
                  <Col className="col-item" key={index}>
                    <ProductArtist artistInfo={item} />
                  </Col>
                );
              })
            ) : (
              <div className="vh-100-final d-flex align-items-center justify-content-center">
                検索結果がありません。
              </div>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
}
