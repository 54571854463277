import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import BoxForm from 'Components/Common/Form/BoxForm';
import { useForm } from 'react-hook-form';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import FormInputNumber from 'Components/Common/Form/FormInputNumber';
import FormGroupTextArea from 'Components/Common/Form/FormGroupTextArea';
import { useHistory, useLocation } from 'react-router-dom';
import CheckBoxs from 'Components/Common/Form/GroupCheckBox/CheckBoxs';
import { SerialTypeOptions } from 'Constant/SerialNumber';
import FormGroupSearch from 'Components/Common/Form/FormGroupSearch';
import { PAGE_SIZE, PER_PAGE } from 'Constant';
import { ArtistApi } from 'Datasource/ArtistApi';
import { TitleApi } from 'Datasource/TitleApi';
import { IPullDownItem } from 'Types/Common';
import { SerialApis } from 'Datasource/Serial';
import MessageError from 'Components/Common/MessageError';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
interface LocationState {
  id: string;
}

export default function CreateSerialNumber(): JSX.Element {
  const location = useLocation();
  const state = location?.state as LocationState;
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);

  const [serialNumberId] = useState<string | number>(state?.id);

  const {
    register,
    setValue,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      name: '',
      type: 0,
      cdId: null,
      cdName: '',
      quantity: null,
      file: null,
      description: '',
      titleId: '',
      titleName: '',
      artistId: '',
      artistName: '',
    },
  });

  const handleChangeInput = (nameFill: string, value: string | number | undefined) => {
    if (value) {
      setValue(nameFill as any, value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleSearchArtist = async (query: string) => {
    const {
      data: { data },
    } = await ArtistApi.getAllSuggestionArtist({
      page: 1,
      perPage: PAGE_SIZE,
      queryArtist: encodeURIComponent(query),
    });
    const { artists } = data;

    const result = artists.map((item: { id: number; localName: string }) => ({
      label: item.localName,
      value: item.id,
    }));

    return result;
  };

  const handleSearchTitle = async (query: string, artistID: number | string) => {
    let titles: IPullDownItem[] = [];
    if (artistID) {
      const {
        data: { data },
      } = await TitleApi.getAllTitleByArtistID({
        artistId: artistID,
        page: 1,
        perPage: PER_PAGE,
        query,
      });
      titles = data;
    } else {
      const {
        data: { data },
      } = await TitleApi.getAllSuggestionTitles({
        page: 1,
        perPage: PER_PAGE,
        query: encodeURIComponent(query),
      });
      titles = data;
    }
    titles = titles.map((title) => ({
      label: title.titles_name || title.name,
      value: title.titles_id || title.id,
    }));
    return titles;
  };

  const handleSearchCD = async (query: string, titleId: number | string) => {
    const {
      data: { data },
    } = await SerialApis.getCdByTitle({
      titleId,
      page: 1,
      limit: PER_PAGE,
      query,
    });

    const cds = data.items.map((cd: any) => {
      return {
        label: cd.name,
        value: cd.id,
      };
    });
    return cds ?? [];
  };

  const handleGetDetailSerialNumber = async () => {
    setLoading(true);
    const {
      data: { data },
    } = await SerialApis.getSerialNumber({ id: state?.id });
    reset({
      name: data.name,
      type: data.type,
      cdId: data.CDId,
      cdName: data.cd?.name,
      quantity: data.quantity,
      file: null,
      description: data.description,
      titleId: data.cd?.title?.id,
      titleName: data.cd?.title?.name,
      artistId: data.cd?.title?.artist?.id,
      artistName: data.cd?.title?.artist?.localName,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (state?.id) {
      handleGetDetailSerialNumber();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <Container className="serial-number-form pe-none">
        <Row>
          <Col md="12" className="my-4">
            <BoxForm title="シリアルコード発行">
              <Form id="create-serial">
                <FormGroupInput
                  label="シリアルコード名"
                  labelMd="2"
                  colMd="10"
                  value={watch('name')}
                />
                <div
                  className={`${
                    serialNumberId ? 'pe-none user-select-none' : 'pe-auto user-select-auto'
                  }`}
                >
                  <CheckBoxs
                    id="type-serial"
                    labelMd="2"
                    colMd="10"
                    checkboxClass="flex-nowrap"
                    labelForm="用途種別"
                    value={watch('type')}
                    options={SerialTypeOptions}
                  />

                  <Row className="mt-3 p-0" hidden={watch('type') === SerialTypeOptions[1].value}>
                    <Form.Label md={2} column className="m-0">
                      対象商品
                    </Form.Label>
                    <Col md={10} as={Row} className="px-0">
                      <FormGroupSearch
                        colClass="px-0"
                        rowClass="mt-3 px-0"
                        labelMd="3"
                        colMd="5"
                        label="アーティスト"
                        placeholder="アーティストを選択してください。"
                        inputSearchString={watch('artistName')}
                        onSearch={handleSearchArtist}
                      />
                      <FormGroupSearch
                        colClass="px-0"
                        rowClass="mt-3 px-0"
                        labelMd="3"
                        colMd="5"
                        label="タイトル"
                        inputSearchString={watch('titleName')}
                        placeholder="タイトルを選択してください。"
                        disabled={!watch('artistId')}
                        onSearch={(query) => handleSearchTitle(query, watch('artistId'))}
                      />

                      <FormGroupSearch
                        colClass="px-0"
                        rowClass="mt-3 px-0"
                        labelMd="3"
                        colMd="5"
                        disabled={!watch('titleId')}
                        inputSearchString={watch('cdName')}
                        label="CD商品名（バリエーション）・品番"
                        onSearch={(query) => handleSearchCD(query, watch('titleId'))}
                        onSelect={(selectedItem) =>
                          reset({
                            ...getValues(),
                            cdId: selectedItem.value,
                            cdName: selectedItem.label,
                          })
                        }
                        placeholder="CD商品名（バリエーション）・品番を選択してください。"
                        errorMessage={errors.cdId?.message}
                      />
                    </Col>
                  </Row>
                  <div hidden={watch('type') === SerialTypeOptions[1].value}>
                    <FormInputNumber
                      classForm="mt-5 p-0"
                      name="quantity"
                      labelMd="2"
                      colMd="2"
                      label="発行数"
                      allowNegative={false}
                      maxValue={200000}
                      value={watch('quantity')}
                    />
                  </div>
                  {watch('type') === SerialTypeOptions[1].value && (
                    <>
                      <Form.Group
                        as={Row}
                        className="mt-5 p-0"
                        hidden={watch('type') === SerialTypeOptions[0].value}
                      >
                        <Form.Label md={2} column className="m-0">
                          シリアルコード
                        </Form.Label>
                        <Col md={10} as={Row}>
                          <Col md={6} className="px-0">
                            <div>外部キャンペーンがアップロードされました。</div>
                          </Col>
                          <p className="fs-12 my-1">10,000件を取り込みました。</p>
                        </Col>
                      </Form.Group>
                      <Row md={2}>
                        <Col md={2} />
                        <Col md={10}>
                          <MessageError
                            classWrapper="w-max-content"
                            message={errors.file?.message}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </div>

                <FormGroupTextArea
                  labelMd="2"
                  colMd="7"
                  label="管理用備考"
                  register={register('description')}
                  classInput="mt-2"
                  rows={4}
                  value={watch('description')}
                  onChange={(value) => handleChangeInput('description', value)}
                />
                <hr />
                <div
                  className={`d-flex m-auto ${
                    watch('type') === SerialTypeOptions[1].value
                      ? 'justify-content-end'
                      : 'justify-content-between'
                  }`}
                ></div>
              </Form>
            </BoxForm>
            <div className="pe-auto d-flex justify-content-center align-items-center pb-4">
              <Button
                className="btn-equal-width btn-focus-none my-4"
                onClick={() => history.push('/serial-numbers')}
              >
                一覧へ戻る
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
