import { SortMethod } from 'Components/Common/Table/table';
import TableCommon from 'Components/Common/Table/TableCommon';
import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Cell } from 'react-table';
import {
  renderArtist,
  renderSaleDate,
  renderId,
  renderTitle,
  renderTitleStatus,
  renderVariation,
  renderDownloadDate,
  renderCampaignsDate,
  renderCampaignsId,
  renderCampaignsArtist,
  renderCampaignsTitle,
  renderCampaignsTitleStatus,
  renderCampaignsCourse,
  renderCampaignsCardPurchase,
  renderCampaignsName,
  renderCampaignsCoursesName,
  renderCampaignsStandardTime,
  renderCampaignsCourseType,
  renderSaleChannels,
  renderCapacityDP,
  renderTotalMasterSale,
  renderRecordCompany,
  renderSaleChannelsDownload,
  renderManager,
  renderCampaignsManager,
} from './ColumnTableProcessBoard';

interface IListProcessBoard {
  [key: string]: any;
}

interface ITableProcessBoard {
  data: any;
  tab: any;
  onSort?: (sortingState: SortMethod[]) => void;
}

export default function TableListProcessBoard({
  data,
  tab,
  onSort,
}: ITableProcessBoard): JSX.Element {
  const columnsSaleData = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',

        className: 'pe-none',
        columns: [
          {
            Header: 'ID',
            width: '100',
            accessor: 'titleId',
            className: 'd-flex align-items-center justify-content-center',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderId(row);
            },
          },
          {
            Header: '情報公開解禁日時',
            width: '200',
            className: 'd-flex align-items-center justify-content-center',
            accessor: 'timeSale',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderSaleDate(row);
            },
          },
          {
            Header: 'アーティスト',
            width: '200',
            className: 'd-flex align-items-center justify-content-start',
            accessor: 'artistName',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderArtist(row);
            },
          },
          {
            Header: 'タイトル',
            width: '275',
            className: 'd-flex align-items-center',
            accessor: 'titleName',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderTitle(row);
            },
          },
          {
            Header: 'バリエーション',
            width: '250',
            className: 'd-flex align-items-center',
            accessor: 'variationName',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderVariation(row);
            },
          },
          {
            Header: 'ステータス',
            width: '100',
            className: 'd-flex align-items-center justify-content-center',
            accessor: 'titleStatus',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderTitleStatus(row);
            },
          },
          {
            Header: '販売チャネル',
            width: '200',
            className: 'd-flex align-items-center justify-content-center',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderSaleChannels(row);
            },
          },
          {
            Header: 'レコード会社',
            accessor: 'companyName',
            width: '200',
            className: 'd-flex align-items-center justify-content-center',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderRecordCompany(row);
            },
          },
          {
            Header: '担当者',
            width: '100',
            className: 'd-flex align-items-center justify-content-center',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderManager(row);
            },
          },
        ],
      },
    ],
    [],
  );
  const columnsDownloadData = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',

        className: 'pe-none',
        columns: [
          {
            Header: 'ID',
            width: '100',
            accessor: 'titleId',
            className: 'd-flex align-items-center justify-content-center',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderId(row);
            },
          },
          {
            Header: 'DL開始日時',
            width: '150',
            className: 'd-flex align-items-center justify-content-center',
            accessor: 'timeDownload',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderDownloadDate(row);
            },
          },
          {
            Header: 'アーティスト',
            width: '175',
            className: 'd-flex align-items-center',
            accessor: 'artistName',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderArtist(row);
            },
          },
          {
            Header: 'タイトル',
            width: '225',
            className: 'd-flex align-items-center',
            accessor: 'titleName',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderTitle(row);
            },
          },
          {
            Header: 'バリエーション',
            width: '175',
            className: 'd-flex align-items-center',
            accessor: 'variationName',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderVariation(row);
            },
          },
          {
            Header: 'DPデータ容量',
            accessor: 'capacityDP',
            className: 'd-flex align-items-center justify-content-end',
            width: '150',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderCapacityDP(row);
            },
          },
          {
            Header: '販売数',
            accessor: 'totalMasterSale',
            className: 'd-flex align-items-center justify-content-end',
            width: '100',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderTotalMasterSale(row);
            },
          },
          {
            Header: '販売チャネル',
            width: '200',
            className: 'd-flex align-items-center justify-content-center',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderSaleChannelsDownload(row);
            },
          },
          {
            Header: 'ステータス',
            accessor: 'titleStatus',
            width: '100',
            className: 'd-flex align-items-center justify-content-center',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderTitleStatus(row);
            },
          },
          {
            Header: 'レコード会社',
            accessor: 'companyName',
            width: '150',
            className: 'd-flex align-items-center justify-content-center',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderRecordCompany(row);
            },
          },
          {
            Header: '担当者',
            width: '100',
            className: 'd-flex align-items-center justify-content-center',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderManager(row);
            },
          },
        ],
      },
    ],
    [],
  );
  const columnsCampainData = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',

        className: 'pe-none',
        columns: [
          {
            Header: 'ID',
            width: '100',
            accessor: 'campaignId',
            className: 'd-flex align-items-center justify-content-center',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderCampaignsId(row);
            },
          },
          {
            Header: '販売期間',
            width: '300',
            accessor: 'saleDate',
            className: 'd-flex align-items-center justify-content-center',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderCampaignsDate(row);
            },
          },
          {
            Header: 'アーティスト',
            width: '200',
            accessor: 'artistName',
            className: 'd-flex align-items-center',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderCampaignsArtist(row);
            },
          },
          {
            Header: 'タイトル',
            width: '275',
            accessor: 'titleName',
            className: 'd-flex align-items-center',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderCampaignsTitle(row);
            },
          },
          {
            Header: 'キャンペーン名称',
            width: '250',
            accessor: 'campaignName',
            className: 'd-flex align-items-center',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderCampaignsName(row);
            },
          },
          {
            Header: '応募コース',
            width: '250',
            className: 'pt-0 pb-0 align-items-center',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderCampaignsCoursesName(row);
            },
          },
          {
            Header: '抽選方法',
            width: '150',
            className: 'pt-0 pb-0 align-items-center',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderCampaignsCourseType(row);
            },
          },
          {
            Header: '景品',
            width: '400',
            className: 'pt-0 pb-0 align-items-center',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderCampaignsCourse(row);
            },
          },
          {
            Header: '抽選日時',
            width: '150',
            className: 'pt-0 pb-0 align-items-center',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderCampaignsStandardTime(row);
            },
          },
          {
            Header: '販売数',
            width: '100',
            accessor: 'saleAmount',
            className: 'd-flex align-items-center justify-content-end',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderCampaignsCardPurchase(row);
            },
          },
          {
            Header: 'ステータス',
            width: '100',
            accessor: 'titleStatus',
            className: 'd-flex align-items-center justify-content-center',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderCampaignsTitleStatus(row);
            },
          },
          {
            Header: '担当者',
            width: '100',
            className: 'd-flex align-items-center justify-content-center',
            Cell: ({ row }: Cell<IListProcessBoard>) => {
              return renderCampaignsManager(row);
            },
          },
        ],
      },
    ],
    [],
  );
  let render;
  if (tab === '販売開始タイトル一覧') {
    render = (
      <TableCommon
        heightBody="calc(100vh - 280px)"
        data={data}
        columns={columnsSaleData}
        noDataText="該当のデータはありません"
        manualSortBy
        onSort={onSort}
      />
    );
  }
  if (tab === 'DL開始情報一覧') {
    render = (
      <TableCommon
        heightBody="calc(100vh - 280px)"
        data={data}
        columns={columnsDownloadData}
        noDataText="該当のデータはありません"
        manualSortBy
        onSort={onSort}
      />
    );
  }
  if (tab === 'キャンペーン情報一覧') {
    render = (
      <TableCommon
        heightBody="calc(100vh - 280px)"
        data={data}
        columns={columnsCampainData}
        noDataText="該当のデータはありません"
        manualSortBy
        getCellProps={() => ({
          className: 'px-0',
        })}
        onSort={onSort}
      />
    );
  }

  return (
    <Row className="mt-3">
      <div className="table-list-products">
        <Row>
          <Col md={12} className="d-flex justify-content-start p-0">
            {render}
          </Col>
        </Row>
      </div>
    </Row>
  );
}
