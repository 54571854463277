import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { findIndex } from 'lodash';
import { Col, Container, Button, Row, Form } from 'react-bootstrap';
import CardContainer from 'Components/Common/CardContainer';
import FormInputNumber from 'Components/Common/Form/FormInputNumber';
import { SettingCommonApi } from 'Datasource/Settings';
import useMessage from 'Hooks/useMessage';
import { stringRequiredCharacter } from 'Utils/Validation';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import NumberFormat from 'react-number-format';
import MessageError from 'Components/Common/MessageError';

const schema = yup.object().shape({
  numberGiftSent: stringRequiredCharacter({}),
  limitListenGiftType: yup.number().required('入力してください。'),
  countListenGift: yup.string().when('limitListenGiftType', {
    is: (limitListenGiftType: number) => limitListenGiftType === 1,
    then: yup.string().required('入力してください。').nullable(),
    otherwise: yup.string().notRequired().nullable(),
  }),
});

type FormValues = {
  numberGiftSent: string;
  countListenGift: string;
  limitListenGiftType: number;
};

interface Option {
  label: string;
  checked: boolean;
  value: number;
}

function SettingSystem(): JSX.Element {
  const {
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<FormValues>({ resolver: yupResolver(schema), mode: 'onSubmit' });

  const { openMessageError } = useMessage();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [configOptions, setConfigOptions] = useState([
    {
      label: '制限なし',
      value: 0,
      checked: true,
    },
    {
      label: '制限あり',
      value: 1,
      checked: false,
    },
  ]);

  useEffect(() => {
    getSetting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSetting = async () => {
    setIsLoading(true);
    try {
      const {
        data: { data },
      } = await SettingCommonApi.getSetting();
      setValue('numberGiftSent', data?.numberGiftSent);
      setValue('countListenGift', data?.countListenGift || '');
      const index = findIndex(
        configOptions,
        (option: Option) => option.value === data?.limitListenGiftType,
      );
      handleOnchangeCheckbox(index);
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onRegister = handleSubmit(async (values) => {
    setIsLoading(true);
    try {
      const param = {
        numberGiftSent: +values?.numberGiftSent || null,
        countListenGift: watch('limitListenGiftType') === 1 ? values?.countListenGift : null,
        limitListenGiftType: watch('limitListenGiftType'),
      };
      await SettingCommonApi.updateSetting(param);
      getSetting();
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  });

  const handleOnchange = (values: { value: string }) => {
    setValue('countListenGift', values.value ?? '');
  };

  const handleOnchangeCheckbox = (index: number) => {
    const config = configOptions.map((option, k: number) => ({
      ...option,
      checked: k === index ? true : false,
    }));
    clearErrors('countListenGift');
    setValue('limitListenGiftType', index);
    if (!index) {
      setValue('countListenGift', '');
    }
    setConfigOptions(config);
    if (!index) {
      setValue('countListenGift', '');
    }
  };

  return (
    <Container>
      <SpinnerComponent isLoading={isLoading} />
      <Row className="justify-content-center">
        <form onSubmit={onRegister}>
          <Col>
            <CardContainer
              title="システム設定"
              darkHeader
              className="system-card ms-auto me-auto mt-5"
            >
              <Row className="justify-content-center">
                <Col xs="10">
                  <FormInputNumber
                    name="numberGiftSent"
                    label="一度の送信できるギフト数*"
                    labelMd="4"
                    colMd="2"
                    classInput="text-end w-90px"
                    errorMessage={errors.numberGiftSent?.message}
                    value={watch('numberGiftSent')}
                    onChange={(value) => setValue('numberGiftSent', value ?? '')}
                    thousandsGroupStyle="thousand"
                    maxLength={8}
                    classMessage="vw-100"
                    classCol="indivual d-flex"
                    pureType
                  />
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs="10">
                  <Row>
                    <Col md={4} className="form-label">
                      <span>
                        ギフトコンテンツの可能再生回数 <span className="text-danger">*</span>
                      </span>
                    </Col>
                    <Col md={8} className="px-0">
                      <Row className="align-items-center">
                        <div className="d-flex align-items-end">
                          <div className="w-110px">
                            {configOptions.map((option: Option, index: number) => (
                              <Form.Check
                                name="limitListenGiftType"
                                inline
                                key={index}
                                type="radio"
                                value={option.checked ? option.value : 0}
                                id={`config-setting-${index}`}
                                label={option.label}
                                checked={option.checked}
                                onChange={() => handleOnchangeCheckbox(index)}
                                className="mb-1"
                              />
                            ))}
                          </div>
                          <div className="times d-flex">
                            <NumberFormat
                              className="form-control rounded text-end w-90px"
                              name="countListenGift"
                              thousandSeparator={true}
                              disabled={configOptions[0].checked}
                              value={watch('countListenGift')}
                              maxLength={8}
                              onValueChange={handleOnchange}
                            />
                            <MessageError
                              classWrapper="ps-2"
                              message={errors.countListenGift?.message}
                            />
                          </div>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="d-flex justify-content-end txt-require mt-2">
                ご注意：＊印は必須項目です。
              </div>
            </CardContainer>
            <Row className="justify-content-center mt-4">
              <Col md="4" className="text-center">
                <Button className="btn-equal-width" type="submit">
                  登録する
                </Button>
              </Col>
            </Row>
          </Col>
        </form>
      </Row>
    </Container>
  );
}
export default SettingSystem;
