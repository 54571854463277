import React, { memo } from 'react';
import { ReactComponent as NumberSvg } from 'Static/Icons/Campaign/12345678.svg';
import { ReactComponent as TicketSvg } from 'Static/Icons/Campaign/ticket-alt-solid.svg';
import { ReactComponent as CreditCardSvg } from 'Static/Icons/Campaign/credit-card-regular.svg';
import { ReactComponent as MusicSvg } from 'Static/Icons/Campaign/music-solid.svg';
import { ReactComponent as VideoSvg } from 'Static/Icons/Campaign/video-solid.svg';
import { ReactComponent as CameraSvg } from 'Static/Icons/Campaign/camera-solid.svg';

import { ReactComponent as GiftSvg } from 'Static/Icons/Campaign/gift-solid.svg';

const PrizeItem = ({
  label,
  imageComponent,
  value,
}: {
  label: string;
  imageComponent: React.ReactNode;
  value: string | number;
}): JSX.Element => {
  return (
    <div className={`d-flex align-items-center opacity-${value ? 100 : 50}`}>
      <div className="text-center">
        {imageComponent}
        <p className="fs-8">{label}</p>
      </div>
    </div>
  );
};

const PrizeRow = ({ course }: { course: any }): JSX.Element => {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <PrizeItem
        imageComponent={<TicketSvg width="20" height="20" />}
        label="入場券"
        value={course?.entranceTicketPrizeCount}
      />
      <PrizeItem
        imageComponent={<CreditCardSvg width="20" height="20" />}
        label="特典券"
        value={course?.tokutenTicketPrizeCount}
      />
      <PrizeItem
        imageComponent={<NumberSvg width="25" height="20" />}
        label="シリアル"
        value={course?.seriesNumberPrizeCount}
      />
      <PrizeItem
        imageComponent={<MusicSvg width="20" height="20" />}
        label="音源"
        value={course?.musicPrizeCount}
      />
      <PrizeItem
        imageComponent={<VideoSvg width="20" height="20" />}
        label="映像"
        value={course?.videoPrizeCount}
      />
      <PrizeItem
        imageComponent={<CameraSvg width="20" height="20" />}
        label="画像"
        value={course?.imagePrizeCount}
      />
      <PrizeItem
        imageComponent={<GiftSvg width="20" height="20" />}
        label="グッズ"
        value={course?.goodPrizeCount}
      />
    </div>
  );
};

export default memo(PrizeRow);
