/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-irregular-whitespace */
import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import { DATE_FORMAT_8 } from 'Constant/Date';
import AliTable, { AliColumn } from 'Components/Common/Table/AliTable';
import { COL_END_CLASS, COL_START_CLASS } from 'Constant';
import { formatDecimal, formatPrice } from 'Utils/Numbers';

export default function DetailBox({
  tableData,
  paymentDate,
  paymentDateRange,
  totalTax,
}: any): JSX.Element {
  const columnDetail = useMemo(
    () =>
      [
        {
          name: '料金種別',
          width: 70,
          code: 'titleType',
          //   features: { sortable: true },
          lock: true,
          align: 'center',
          render: (value: any) => {
            return <div className={`${COL_START_CLASS} text-center`}>{value}</div>;
          },
        },
        {
          name: '数量',
          width: 50,
          code: 'amountTotal',
          lock: true,
          align: 'center',
          render: (value: any) => {
            return <div className={`${COL_END_CLASS}`}>{value != 0 && formatPrice(value)}</div>;
          },
        },
        {
          name: '定額料金',
          align: 'center',
          width: 100,
          children: [
            {
              name: '単価',
              width: 50,
              code: 'cardBuyFee',
              align: 'center',
              render: (value: any) => {
                return <div className={`${COL_END_CLASS}`}>{value != 0 && formatPrice(value)}</div>;
              },
            },
            {
              name: '金額',
              width: 50,
              code: 'amountCardBuyFeeTotal',
              align: 'center',
              render: (value: any) => {
                return <div className={`${COL_END_CLASS}`}>{value != 0 && formatPrice(value)}</div>;
              },
            },
          ],
        },
        {
          name: '定率料金',
          align: 'center',
          width: 100,
          children: [
            {
              name: '料率',
              width: 50,
              code: 'taxFee',
              align: 'center',
              render: (value: any) => {
                return (
                  <div className={`${COL_END_CLASS}`}>
                    {value != 0 && value != undefined && `${parseFloat(value).toFixed(2)} %`}
                  </div>
                );
              },
            },
            {
              name: '金額',
              width: 50,
              code: 'amountTaxFeeMoneyTotal',
              align: 'center',
              render: (value: any) => {
                return <div className={`${COL_END_CLASS}`}>{value != 0 && formatPrice(value)}</div>;
              },
            },
          ],
        },
        {
          name: 'オプション料金',
          align: 'center',
          width: 100,
          children: [
            {
              name: '単価',
              width: 50,
              code: 'fee',
              align: 'center',
              render: (value: any) => {
                return (
                  <div className={`${COL_END_CLASS}`}>{value != 0 && formatDecimal(value, 1)}</div>
                );
              },
            },
            {
              name: '金額',
              width: 50,
              code: 'amountOptionFeeTotal',
              align: 'center',
              render: (value: any) => {
                return <div className={`${COL_END_CLASS}`}>{value != 0 && formatPrice(value)}</div>;
              },
            },
          ],
        },
        {
          name: '',
          width: 1,
          code: 'blankCell',
          lock: true,
          align: 'center',
          render: (value: any) => {
            return <div className={`${COL_START_CLASS}`}></div>;
          },
        },
        {
          name: '原価合計',
          width: 50,
          code: 'totalFee',
          lock: true,
          align: 'center',
          render: (value: any) => {
            return <div className={`${COL_END_CLASS}`}>{formatPrice(value)}</div>;
          },
        },
      ] as AliColumn[],
    [],
  );

  return (
    <Row className="w-97 mt-4 ml-30px mr-30px">
      <Col className="shadow-sm border detailBox-padding" sm={4}>
        <div className="border-bottom-ec mr-6px pb-16px pl-20px pr-20px text-center font-bold date-range-responsive">
          対象期間　{paymentDateRange}
        </div>
        <div className="d-flex flex-column align-items-center mt-5">
          <div>
            <div>
              <div className="mb-8px">支払日</div>
              <div className="fs-18 font-bold mb-8px">{paymentDate}</div>
            </div>
            <div>
              <div>請求金額</div>
              <div className="fs-26 font-bold d-inline border-bottom-2px-ec pl-12px pr-12px ml-minus-12px">
                {totalTax ? formatPrice(totalTax) : 0}
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col className="overflow-x-scroll" sm={8}>
        <AliTable
          wrapperClass="payment-summary-box-table min-w-1000px"
          dataSource={tableData}
          heightBody="auto"
          columns={columnDetail}
        />
      </Col>
    </Row>
  );
}
