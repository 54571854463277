import CardContainer from 'Components/Common/CardContainer';
import Booklet from 'Components/Common/Dropzone/Booklet';
import BoxDropZone from 'Components/Common/Dropzone/BoxDropZone';
import DigitalSong from 'Components/Page/Title/Copm/DigitalSong';
import GroupTextProps from 'Components/Page/Title/FormTextGroup';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import DigitalImage from 'Components/Page/Title/Copm/DigitalImage';
import DigitalVideo from 'Components/Page/Title/Copm/DigitalVideo';
import { convertPriceApple, handleConvertJmdCooperation, LINK_JMD_TYPE_CONTENT } from 'Utils/Title';
import dayjs from 'dayjs';
import { DATE_FORMAT_2 } from 'Constant/Date';
import { formatPrice } from 'Utils/Numbers';
import { INCLUDE_JARSAC_CONTENT } from 'Constant/Title';
interface VariationInformationProps {
  data?: any;
  countVariation?: number;
  index?: number;
}

export default function VariationInformation(props: VariationInformationProps): JSX.Element {
  const { data, countVariation = 0, index = 0 } = props;

  return (
    <>
      <CardContainer
        title={`バリエーション情報 (${index + 1}/${countVariation})`}
        darkHeader
        className="mx-4 mt-3 border-0 shadow-none"
      >
        <Row>
          <Col>
            <Row className="mt-2">
              <Col md="5">収録タイプ</Col>
              <Col md="7">{data?.option?.titleType || ''}</Col>
            </Row>
            <GroupTextProps mdLable={5} label="バリエーション名" value={data?.name} />
            <GroupTextProps
              mdLable={5}
              label="著作権管理"
              value={
                data?.includeJasracContent === INCLUDE_JARSAC_CONTENT.INCLUDE
                  ? 'バリエーション内の楽曲または動画に著作権管理コンテンツを含む'
                  : data?.includeJasracContent === INCLUDE_JARSAC_CONTENT.NOT_INCLUDE
                  ? '含まない'
                  : ''
              }
            />
            <GroupTextProps
              mdLable={5}
              label="jmd連携"
              value={handleConvertJmdCooperation(data?.jmdCooperation) ?? ''}
            />
            {data?.jmdCooperation === LINK_JMD_TYPE_CONTENT.WORK_TOGETHER && (
              <>
                <GroupTextProps
                  mdLable={5}
                  label="日本レコード協会ジャンル"
                  value={data?.riajGenre?.nameJp ?? ''}
                />
                <GroupTextProps
                  mdLable={5}
                  label="jmd音楽ジャンル"
                  value={data?.jmdMusicGenre?.nameJp ?? ''}
                />
              </>
            )}
            <GroupTextProps
              mdLable={5}
              label="楽曲フォーマット"
              value={!data?.musicFormat ? 'MP3 192kbps' : 'WAV 24bit 96kHz (ハイレゾ)'}
            />
            <GroupTextProps mdLable={5} label="品番" value={data?.productCode} />
            <GroupTextProps mdLable={5} label="JANコード" value={data?.janCode} />
            <GroupTextProps
              mdLable={5}
              label="販売価格"
              value={`${data?.price ? `${formatPrice(data?.price)}円（税込）` : ''}`}
            />
            <GroupTextProps
              mdLable={5}
              label="情報公開解禁日時"
              value={data?.saleTokenDate && dayjs(data?.saleTokenDate).format(DATE_FORMAT_2)}
            />
            <GroupTextProps
              mdLable={5}
              label="DL開始日時（発売日時）"
              value={data?.downloadDate && dayjs(data?.downloadDate).format(DATE_FORMAT_2)}
            />
            <GroupTextProps
              mdLable={5}
              label="DL開始通知時刻"
              value={data?.notiDownloadTime ? dayjs(data?.notiDownloadTime).format('HH:mm') : ''}
            />
            <GroupTextProps
              mdLable={5}
              label="Apple経由販売価格"
              value={`${data?.price ? `${formatPrice(convertPriceApple(data?.price))}円` : ''}`}
            />
          </Col>
          <Col>
            <Row className="mt-2">
              <Col md="3">ジャケット画像</Col>
              <Col className="justify-content-center d-flex align-items-end pe-none">
                <BoxDropZone
                  key={`cover-image-${data?.index}`}
                  coverImageUrl={data?.coverImageUrl}
                />
              </Col>
            </Row>
          </Col>
          <Row className="mt-2">
            <Col md="2">
              ブックレット画像<p>(600px X 600px)</p>
            </Col>
            <Col md="10">
              <Booklet listBooklets={data.booklets} />
            </Col>
          </Row>
          <DigitalSong data={data?.songs} variationData={data} />
          <DigitalVideo data={data?.videos} variationData={data} />
          <DigitalImage data={data?.images} />
        </Row>
      </CardContainer>
    </>
  );
}
