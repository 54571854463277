import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CollectCode,
  ContentType,
  IVTType,
  LyricsClassify,
  MedleyType,
  OriginalType,
  ShowType,
  Step,
  Title,
} from 'Types/App/title';
import dayjs from 'dayjs';
import { trim } from 'lodash';

interface TitleState {
  currentStep: Step;
  titleForm: Title;
  role: number;
}

const initialState: TitleState = {
  currentStep: 'step3',
  role: 1,
  titleForm: {
    artistId: 1,
    name: '',
    type: 'single',
    label: '',
    variationList: [
      {
        JANCode: '',
        description: '',
        eventTokuten: null,
        lyricCard: '',
        openDate: dayjs().format('YYYY/MM/DD'),
        openTime: dayjs().format('hh:mm'),
        preorderDate: dayjs().format('YYYY/MM/DD'),
        preorderTime: dayjs().format('hh:mm'),
        price: null,
        productionCode: '',
        songQuantity: null,
        thumbImage: '',
        tokutenTrading: null,
        totalSale: null,
        variationName: '',
        musicArray: [
          {
            index: 0,
            name: '',
            arrangerEnglishName: '',
            arrangerFuriganaName: '',
            arrangerName: '',
            composerEnglishName: '',
            composerFuriganaName: '',
            composerName: '',
            englishName: '',
            furiganaName: '',
            musicianEnglishName: '',
            musicianFuriganaName: '',
            musicianName: '',
            showTime: dayjs().format('hh:mm:ss').toString(),
            type: {
              ISRCCode: '',
              authoziredGroup: '',
              IVTType: IVTType.SongOnly,
              contentType: ContentType.No,
              contentCode: '',
              medleyCode: '',
              medleyType: MedleyType.No,
              originalType: OriginalType.Domestic,
              lyricsClassify: LyricsClassify.Original,
              showType: ShowType.Studio,
              JASRACCode: '',
              collectCode: CollectCode.No,
              informationFee: null,
              otherCode: '',
              sourceAudioUrl: '',
            },
          },
        ],
      },
    ],
  },
};

export const titleSlice = createSlice({
  name: 'title',
  initialState,
  reducers: {
    setForm: (state, data: PayloadAction<TitleState>) => {
      const { payload } = data;
      return { ...payload };
    },
    setCurrentStep: (state, data: PayloadAction<Step>) => {
      const payload = data.payload;
      state.currentStep = payload;
    },
    setTitleForm: (state, data: PayloadAction<Partial<Title>>) => {
      const payload = data.payload;
      const titleForm: Title = { ...state.titleForm };
      Object.keys(payload).map((key) => {
        if (typeof payload[key] !== 'undefined') {
          titleForm[key] = typeof payload[key] === 'string' ? trim(payload[key]) : payload[key];
        }
      });
      state.titleForm = titleForm;
    },
  },
});

export const { setTitleForm, setCurrentStep, setForm } = titleSlice.actions;
export default titleSlice.reducer;
