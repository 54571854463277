import React, { memo } from 'react';
import { ReactComponent as NumberSvg } from 'Static/Icons/Campaign/12345678.svg';
import { ReactComponent as TicketSvg } from 'Static/Icons/Campaign/ticket-alt-solid.svg';
import { ReactComponent as CreditCardSvg } from 'Static/Icons/Campaign/credit-card-regular.svg';
import { ReactComponent as MusicSvg } from 'Static/Icons/Campaign/music-solid.svg';
import { ReactComponent as VideoSvg } from 'Static/Icons/Campaign/video-solid.svg';
import { ReactComponent as CameraSvg } from 'Static/Icons/Campaign/camera-solid.svg';

import { ReactComponent as GiftSvg } from 'Static/Icons/Campaign/gift-solid.svg';
import { isEmpty } from 'lodash';
import { campaignOptionsType } from 'Constant/Campaign';
import { Prize } from 'Types/Page/Campaign/RegisterCampaign';

const PrizeItem = ({
  label,
  imageComponent,
  value,
  isGood,
}: {
  label: string;
  imageComponent: React.ReactNode;
  value: string | number;
  isGood: boolean;
}): JSX.Element => {
  return (
    <div className={`d-flex align-items-center opacity-${value && isGood ? 100 : 50}`}>
      <div className="text-center">
        {imageComponent}
        <p className="fs-8">{label}</p>
      </div>
    </div>
  );
};

const CampaignCourses = ({ course }: { course: any }): JSX.Element => {
  const isNoDigital = () => {
    const disabled = [...course?.prizes].some((prize: Prize) => {
      const isEmptyFile = isEmpty(prize?.prizeDigitalTokutens)
        ? true
        : prize.prizeDigitalTokutens.some((prizeDT: any) => {
            return !prizeDT.url || prizeDT.url === '';
          });
      return isEmptyFile && prize.type === campaignOptionsType[3].value;
    });
    return disabled;
  };

  return (
    <div className="d-flex align-items-center justify-content-between">
      <PrizeItem
        imageComponent={<TicketSvg width="20" height="20" />}
        label="入場券"
        value={course?.entranceTicketPrizeCount}
        isGood
      />
      <PrizeItem
        imageComponent={<CreditCardSvg width="20" height="20" />}
        label="特典券"
        value={course?.tokutenTicketPrizeCount}
        isGood
      />
      <PrizeItem
        imageComponent={<NumberSvg width="25" height="20" />}
        label="シリアル"
        value={course?.seriesNumberPrizeCount}
        isGood
      />
      <PrizeItem
        imageComponent={
          <MusicSvg width="20" height="20" className={`${isNoDigital() ? 'icon-red' : ''}`} />
        }
        label="音源"
        value={course?.musicPrizeCount}
        isGood={!isNoDigital()}
      />
      <PrizeItem
        imageComponent={
          <VideoSvg width="20" height="20" className={`${isNoDigital() ? 'icon-red' : ''}`} />
        }
        label="映像"
        value={course?.videoPrizeCount}
        isGood={!isNoDigital()}
      />
      <PrizeItem
        imageComponent={
          <CameraSvg width="20" height="20" className={`${isNoDigital() ? 'icon-red' : ''}`} />
        }
        label="画像"
        value={course?.imagePrizeCount}
        isGood={!isNoDigital()}
      />
      <PrizeItem
        imageComponent={<GiftSvg width="20" height="20" />}
        label="グッズ"
        value={course?.goodPrizeCount}
        isGood
      />
    </div>
  );
};

export default memo(CampaignCourses);
