import { subTypeOptions } from 'Constant/Campaign';
import { Prize } from 'Types/Page/Campaign/RegisterCampaign';

const useCampaignDraft = () => {
  const handleDecodeMatrixPrizes = (courses: any) => {
    const tmpCourses = courses.map((course: any) => {
      const prizes = course?.prizes
        .sort((prizeA: any, prizeB: any) => prizeA.index - prizeB.index)
        .map((prize: any, prizeIndex: number) => {
          const conditionArrValue = Array.from(prize.matrix ? prize.matrix : '0');
          const conditionArr = [...Array(course?.prizes.length)].map((n, i) => {
            return {
              [`condition${i}`]: conditionArrValue[i] ? Number(conditionArrValue[i]) : 0,
            };
          });
          const conditionObj = conditionArr.reduce((map, item) => {
            return { ...map, ...item };
          }, {});
          const prizeDigitalTokutens = prize?.prizeDigitalTokutens.map((d: any) => {
            return d?.percentage
              ? { ...d, percentage: `${d.percentage}` }
              : { ...d, percentage: d.percentage ? d.percentage : null };
          });

          const parseField = {
            productQuantity: prize?.productQuantity
              ? `${prize?.productQuantity}`
              : prize?.productQuantity,
            seatNumber: prize?.seatNumber ? `${prize?.seatNumber}` : prize?.seatNumber,
            prizeDigitalTokutens,
          };

          return {
            ...prize,
            ...parseField,
            index: prizeIndex,
            abandoned: prize.abandoned ? prize.abandoned : 0,
            onlyOne: prize.onlyOne ? prize.onlyOne : 0,
            winRate: +prize?.winRate || 0,
            isIncentive: prize?.isIncentive || false,
            ...conditionObj,
          };
        });

      const totalWinRate = course?.prizes?.reduce((total: number, currentValue: Prize) => {
        const currentWinRate = currentValue.winRate ? +currentValue.winRate : 0;
        return total + currentWinRate;
      }, 0);
      let slipRate = 0;
      if (!course?.prizes?.length || totalWinRate === 0) {
        slipRate = 100;
      }

      if (100 - +totalWinRate > 0) {
        slipRate = +(100 - +totalWinRate).toFixed(2);
      } else {
        slipRate = 0;
      }

      const hasIncentive = course.prizes.some(
        (prize: Prize) => !prize?.userQuantity && prize.isIncentive,
      );

      if (hasIncentive && totalWinRate > 0) {
        slipRate = 0;
      }

      const prizeSorted = () => {
        let tmpPrize: Prize[] = [];
        if (prizes.length) {
          const prizeNotIncentive = prizes.filter((p: Prize) => !p.isIncentive);
          const prizeIncentive = prizes.filter((p: Prize) => p.isIncentive);
          tmpPrize = [...prizeNotIncentive, ...prizeIncentive].map((item, i) => ({
            ...item,
            index: i,
          }));
        }
        return tmpPrize;
      };

      return {
        ...course,
        subType: course?.subType ? +course?.subType : subTypeOptions[0].value,
        slipRate: slipRate,
        prizeQuantity: course?.prizeQuantity ? course?.prizeQuantity : '',
        prizes: prizeSorted(),
      };
    });
    return tmpCourses;
  };
  return {
    handleDecodeMatrixPrizes,
  };
};

export default useCampaignDraft;
