import CardContainer from 'Components/Common/CardContainer';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { calcMb } from 'Utils/File';
import GroupTextProps from 'Components/Page/Title/FormTextGroup';
import { handleConnectStaticEndPoint } from 'Utils/ConnectEndpointStatic';
import { formatPrice } from 'Utils/Numbers';
import { INCLUDE_COPY_RIGHT, VIDEO_TYPES } from 'Constant/Title';
import { LINK_JMD_TYPE_CONTENT } from 'Utils/Title';
interface DigitalVideoProps {
  data?: any;
  variationData?: any;
}

export default function DigitalVideo(props: DigitalVideoProps): JSX.Element {
  const { data, variationData } = props;

  const convertCopyRightSong = (type: number) => {
    switch (type) {
      case INCLUDE_COPY_RIGHT.INCLUDE:
        return '著作権管理楽曲を含む';
      case INCLUDE_COPY_RIGHT.NOT_INCLUDE:
        return '含まない';
      default:
        return '';
    }
  };

  const convertVideoType = (type: number) => {
    switch (type) {
      case VIDEO_TYPES.MUSIC_VIDEO:
        return 'Music Video';
      case VIDEO_TYPES.OTHER:
        return 'Music Video以外（LIVEなど）';
      default:
        return '';
    }
  };

  const convertAuthorizedType = (type: number, songDesc?: string) => {
    switch (type) {
      case 1:
        return 'JASRAC';
      case 2:
        return 'NexTone';
      case 3:
        return '自己管理';
      case 4:
        return songDesc ?? '';

      default:
        return '';
    }
  };

  const convertLyricsClassifyType = (type: number) => {
    switch (type) {
      case 1:
        return '１（原詞）';
      case 2:
        return '２（訳詞）';
      case 3:
        return '３（不明）';
      case 4:
        return ' ブランク（Vocalなし）';

      default:
        return '';
    }
  };

  const convertIvtType = (type: number) => {
    switch (type) {
      case 0:
        return 'I（Vocalなし）';
      case 1:
        return 'V（Vocalあり）';
      case 2:
        return 'T（詞のみ）';
      default:
        return '';
    }
  };

  const convertVersionIdentificationType = (type: number) => {
    switch (type) {
      case 0:
        return 'L（国内原版）';
      case 1:
        return 'I（外国原版）';
      default:
        return '';
    }
  };

  const convertLiveType = (type: number) => {
    switch (type) {
      case 0:
        return 'スタジオ';
      case 1:
        return 'ライブ';
      default:
        return '';
    }
  };

  return (
    <CardContainer title="動画コンテンツ" className="mb-3 mt-4 p-0">
      {data?.length === 0 && <p>登録なし</p>}
      {data?.map((video: any, index: number) => {
        return (
          <div key={index} className="mb-4">
            <Row>
              <Col md={3} className="mt-4">
                <video className="w-100" controls>
                  <track kind="captions" />
                  <source src={handleConnectStaticEndPoint(video?.url)} type="video/mp4" />
                  Your browser does not support HTML video.
                </video>
              </Col>
              <Col md={9} className="mt-4">
                <Col md={12} className="mb-2">
                  <GroupTextProps
                    label="タイトル名:"
                    value={video?.name || ''}
                    mdLable={2}
                    mdValue={10}
                  />
                </Col>
                {variationData?.jmdCooperation === LINK_JMD_TYPE_CONTENT.WORK_TOGETHER && (
                  <Col md={12} className="mb-2">
                    <GroupTextProps
                      label="jmd動画ジャンル:"
                      value={variationData?.jmdVideoGenre?.nameJp ?? ''}
                      mdLable={2}
                      mdValue={10}
                    />
                  </Col>
                )}
                <Col md={12} className="mb-2">
                  <GroupTextProps
                    label="EC用宣伝文:"
                    value={video?.introEc || ''}
                    mdLable={2}
                    mdValue={10}
                  />
                  <GroupTextProps
                    label="著作権管理:"
                    value={
                      video?.includeCopyRightSong !== null
                        ? convertCopyRightSong(+video?.includeCopyRightSong)
                        : ''
                    }
                    mdLable={2}
                    mdValue={10}
                  />
                  <GroupTextProps
                    label="動画種別:"
                    value={video?.videoType !== null ? convertVideoType(+video?.videoType) : ''}
                    mdLable={2}
                    mdValue={10}
                  />
                  <GroupTextProps
                    label={'情報料:\n（みなし上代）'}
                    value={`${
                      video?.mediaInformationFee
                        ? `${formatPrice(video?.mediaInformationFee)}円(税抜)`
                        : '0円(税抜)'
                    }`}
                    mdLable={2}
                    mdValue={10}
                    labelClass="text-prev-wrap"
                  />
                </Col>
                <Col md={7}>
                  <GroupTextProps label={`${calcMb(video?.size)}MB`} value={'  '} mdLable={8} />
                </Col>
              </Col>
            </Row>
            {video?.songs?.map((el: any, index: number) => {
              if (!el.name) return;
              return (
                <>
                  <hr />
                  <Row key={index}>
                    <Col>
                      <GroupTextProps mdLable={5} label="曲名" value={el?.name} />
                      <GroupTextProps
                        mdLable={5}
                        label="曲名（フリガナ）"
                        value={el?.furiganaName}
                      />
                      <GroupTextProps
                        mdLable={5}
                        label="曲名（英語表記）"
                        value={el?.englishName}
                      />
                      {variationData?.jmdCooperation === LINK_JMD_TYPE_CONTENT.WORK_TOGETHER && (
                        <GroupTextProps
                          mdLable={5}
                          label="日本レコード協会ジャンル"
                          value={el?.riajGenre?.nameJp ?? ''}
                        />
                      )}
                      <GroupTextProps
                        mdLable={5}
                        label="IVT識別"
                        value={convertIvtType(el?.ivtType)}
                      />
                      <GroupTextProps mdLable={5} label="作詞者名" value={el?.musicianName} />
                      <GroupTextProps
                        mdLable={5}
                        label="作詞者名（フリガナ）"
                        value={el?.musicianFuriganaName}
                      />
                      <GroupTextProps
                        mdLable={5}
                        label="作詞者名（英語表記）"
                        value={el?.musicianEnglishName}
                      />
                      <GroupTextProps mdLable={5} label="作曲者名" value={el?.composerName} />
                      <GroupTextProps
                        mdLable={5}
                        label="作曲者名（フリガナ）"
                        value={el?.composerFuriganaName}
                      />
                      <GroupTextProps
                        mdLable={5}
                        label="作曲者名（英語表記）"
                        value={el?.composerEnglishName}
                      />
                      <GroupTextProps mdLable={5} label="編曲者名" value={el?.arrangerName} />
                      <GroupTextProps
                        mdLable={5}
                        label="編曲者名（フリガナ）"
                        value={el?.arrangerFuriganaName}
                      />
                      <GroupTextProps
                        mdLable={5}
                        label="編曲者名（英語表記）"
                        value={el?.arrangerEnglishName}
                      />
                    </Col>
                    <Col>
                      <GroupTextProps mdLable={5} label="演奏時間" value={el?.showTime} />
                      <GroupTextProps
                        mdLable={5}
                        label="原盤識別"
                        value={convertVersionIdentificationType(el?.versionIndent)}
                      />

                      <GroupTextProps
                        mdLable={5}
                        label="ライブ区分"
                        value={convertLiveType(el?.liveType)}
                      />
                      <GroupTextProps
                        mdLable={5}
                        label="著作権信託先"
                        value={convertAuthorizedType(el?.authorizedGroupType, el?.authorizedGroup)}
                      />
                      <GroupTextProps mdLable={5} label="ISRC番号" value={el?.isrcCode} />
                      <GroupTextProps mdLable={5} label="JASRAC作品コード" value={el?.jasracCode} />
                      <GroupTextProps
                        mdLable={5}
                        label="JASRAC以外の作品コード"
                        value={el?.otherJasracCode}
                      />
                      <GroupTextProps
                        mdLable={5}
                        label="情報料(みなし上代)"
                        value={`${
                          el?.informationFee
                            ? `${formatPrice(el?.informationFee)}円(税抜)`
                            : '0円(税抜)'
                        }`}
                      />
                      <GroupTextProps
                        mdLable={5}
                        label="原詞訳詞区分"
                        value={convertLyricsClassifyType(el?.lyricsClassifyType)}
                      />
                    </Col>
                  </Row>
                </>
              );
            })}
          </div>
        );
      })}
    </CardContainer>
  );
}
