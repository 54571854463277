import { Menu } from 'Components/Layouts/common';
export const MENUS: Menu[] = [
  {
    title: 'トップ',
    slug: '#',
    children: [
      {
        title: 'レポーティング',
        slug: '/',
      },
      {
        title: '進行ボード',
        slug: '/processboard?queryTitleStatus=すべて',
      },
    ],
  },
  {
    title: 'タイトル管理',
    slug: '#',
    children: [
      {
        title: 'マスタタイトルリスト',
        slug: '/title/list',
      },
    ],
  },
  {
    title: 'キャンペーン管理',
    slug: '#',
    children: [
      {
        title: 'キャンペーンリスト',
        slug: '/campaigns',
      },
      {
        title: 'シリアルコードマスタ',
        slug: '/serial-numbers',
      },
      {
        title: 'CDマスタ',
        slug: '/cd-master',
      },
    ],
  },
  // {
  //   title: 'プロモーション管理',
  //   slug: '#',
  //   children: [
  //     {
  //       title: 'デジタル特典リスト',
  //       slug: '/promotion-manager',
  //     },
  //   ],
  // },
  {
    title: '販売管理',
    slug: '#',
    children: [
      {
        title: '販売商品リスト',
        slug: '/list-products',
      },
      {
        title: '販売商品詳細',
        slug: '/products-detail',
      },
    ],
  },
  {
    title: 'ECサイト管理',
    slug: '#',
    children: [
      {
        title: 'EC販売商品リスト',
        slug: '/ec/list-sale-products',
      },
      {
        title: '購入リスト',
        slug: '/ec/list-purchase',
      },
      // {
      //   title: 'サイト設定',
      //   slug: '/ec/setting-site',
      // },
      // {
      //   title: 'メールメッセージ管理',
      //   slug: '/ec/email-message-management',
      // },
    ],
  },
  {
    title: '会計情報',
    slug: '#',
    children: [
      {
        title: '売上管理',
        slug: '/revenue-management',
      },
      // {
      //   title: 'EC支払通知リスト',
      //   slug: '/ec/payment-advice-list',
      // },
      {
        title: 'PF利用料支払通知リスト',
        slug: '/ec/payment-notification-list',
      },
      {
        title: 'レコード会社請求サマリ',
        slug: '/ec/purchase-summary-list',
      },
      {
        title: '著作権レポート',
        slug: '/jasrac',
      },
    ],
  },
  {
    title: 'アーティスト管理',
    slug: '#',
    children: [
      {
        title: 'アーティストリスト',
        slug: '/artist',
      },
    ],
  },
  {
    title: 'アカウント管理',
    slug: '#',
    children: [
      {
        title: 'アカウントリスト',
        slug: '/record-company-list',
      },
    ],
  },
  {
    title: '担当者情報',
    slug: '#',
    children: [
      {
        title: '担当者管理',
        slug: '/curator-management',
      },
    ],
  },
  {
    title: 'システム情報',
    slug: '#',
    children: [
      {
        title: '運営会社情報設定',
        slug: '/system-information',
      },
      {
        title: 'メールメッセージ管理',
        slug: '/ec/email-message-management',
      },
      {
        title: 'システム設定',
        slug: '/system-setting',
      },
    ],
  },
];
