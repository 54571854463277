import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Routing from 'Router';
import { QueryParamProvider } from 'use-query-params';
import Message from 'Components/Common/Message';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-multi-carousel/lib/styles.css';
import 'Static/Styles/index.scss';

function App(): JSX.Element {
  return (
    <>
      <Message />
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Routing />
        </QueryParamProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
