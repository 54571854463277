/* eslint-disable no-useless-escape */
/*eslint @typescript-eslint/explicit-module-boundary-types: 0*/
import _ from 'lodash';
import * as Yup from 'yup';
// Messages definition
export const minMsg = (min: number) => `${min}文字以上で入力してください  `;
export const maxMsg = (max: number) => `${max}文字以内で入力してください。`;
// regex email
export const MailRegExp = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,10}$/g;
// messenger Alphabet
export const AlphabetMsg = '英字を入力してください';
// regex password
export const PWDRegExp = /^[\w-.!@#$%^&*()_+-=:;,.?/\\|<>'" ]+$/g;
const numberRegExp = /^[^0-9]+$/;
export const regexValidatePassword = /^(?=.*[A-Za-z])(?=.*[0-9])(?=.{8,})/;

export const REGEX_POST_CODE = /^\d{1,7}$/;
export const REGEX_PHONE_JAPAN = /^[-\d\s+()]{10,20}$/;

// regex all space password
export const spaceAllRegExp =
  /^(?:(?=.*[a-zA-Z0-9])(?:(?=.*[\d\W])|(?=.*\W))|(?=.*[!@#$%^&*()_+-=:;,.?/\\|<>'"([a-zA\])])).{8,8}$/;
export const spaceAllMsg = 'スペースだけを入れてはいけません。';

export const katakanaFullSizeReg = /^[ァ-ンー０-９（）．−／\u3000]+$/u;

export const katakanaFullSizeRegBankAccountOwner =
  /^([ァ-ン０-９“‘”’\u3000-\u303f\u30a0-\u30ff\uffe0-\uffef\u3400-\u4dbf（）．ー／])*$/u;

export const halfsizeJP =
  /^[\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFFEF\u4E00-\u9FAF\u2605-\u2606\u2190-\u2195\u203B]+$/;

export const REGEX_URL =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;

export const REGEX_ENGLISH_CHARACTER = /^[a-zA-Z0-9$@$!%*?&#^-_. +]+$/;

export const ENGLISH_CHAR_ONLY = /^[A-Za-z0-9]*$/;

export const PWD_MINIMUM_8 = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

// regex phone number
export const REGEX_PHONE_NUMBER = /[^0-9\-]/g;

export const REGEX_NUMBER = /[^0-9]/g;

export const REGEX_DECIMAL = /[^0-9\.]/g;

export const REGEX_IP = /[^0-9\.]/g;

export const stringOnly = () =>
  stringNotRequired().matches(numberRegExp, '数字が含まれてはいけません。');

export const stringRequiredTrim = ({ required = '入力してください。' }) =>
  Yup.string().required(required).trim(required);

export const stringRequired = ({ required = '入力してください。' }) =>
  Yup.string().required(required);

export const booleanRequired = ({ required = '入力してください。' }) =>
  Yup.boolean().required(required);

export const numberRequired = ({ required = '入力してください。' }) =>
  Yup.number().required(required);

export const zipCodeValidation = (message: string) =>
  Yup.string().max(100, maxMsg(100)).matches(REGEX_POST_CODE, message);

export const stringNotRequired = () => Yup.string();

export const maxLengthInput = (maxLength: number) =>
  stringNotRequired().max(maxLength, maxMsg(maxLength));

export const phoneValidation = ({ required = '入力してください。' }) =>
  stringRequired({ required });

export const phoneValidationNotRequire = () =>
  stringNotRequiredValidation()
    .max(100, maxMsg(100))
    .matches(REGEX_PHONE_JAPAN, '電話番号の形式が正しくありません。');

export const postCodeValidation = ({ required = '入力してください。' }) =>
  stringRequired({ required })
    .max(100, maxMsg(100))
    .matches(REGEX_POST_CODE, '半角数字で入力してください。');

export const postCodeValidationNotRequire = () =>
  stringNotRequiredValidation()
    .max(7, maxMsg(7))
    .matches(REGEX_POST_CODE, '半角数字で入力してください。');

export const emailValidation = ({
  email = ' 有効なメールアドレスで入力してください。',
  required = '入力してください。',
}) => stringRequired({ required }).email(email).max(100, maxMsg(100)).matches(MailRegExp, email);

export const passwordValidation = ({ required = '入力してください。', min = 8, max = 100 }) =>
  stringRequired({ required })
    .min(min, 'パスワードは半角英数混合８文字以上を入力してください。')
    .max(max, maxMsg(max))
    .matches(regexValidatePassword, 'パスワードは半角英数混合８文字以上を入力してください。');

export const confirmPasswordValidation = ({
  ref = 'password',
  matched = 'パスワードと、確認用のパスワードが一致しません。',
  required = '入力してください。',
}) =>
  stringRequired({ required })
    .oneOf([Yup.ref(ref)], matched)
    .matches(regexValidatePassword, 'パスワードは半角英数混合８文字以上を入力してください。');

export const inputValidation = ({ required = '入力してください。', max = 100 }) =>
  stringRequired({ required }).max(max, maxMsg(max));

export const stringRequiredWithMaxLength = (
  maxLength: number,
  { required = '入力してください。' },
) => Yup.string().required(required).max(maxLength, maxMsg(maxLength));

export const stringRequiredMaxLengthTrim = (
  maxLength: number,
  { required = '入力してください。' },
) => Yup.string().required(required).max(maxLength, maxMsg(maxLength)).trim(required);

export const emailNotRequiredValidation = ({ email = 'メール形式が正しくありません。' }) =>
  Yup.string().email(email).max(100, maxMsg(100)).matches(MailRegExp, AlphabetMsg);

export const regexNumberPositive = /^(?:\d+)?$/g;

export const numberPositiveValidation = ({
  number = '数字形式が正しくありません。',
  required = '入力してください。',
}) => Yup.string().required(required).max(100, maxMsg(100)).matches(regexNumberPositive, number);

export const regexLinkURL = /(https?:\/\/[^\s]+)/g;
export const URLNotRequiredValidation = ({ URL = 'URL形式が正しくありません。' }) =>
  Yup.string().matches(regexLinkURL, URL);

export const requiredValidation = ({ required = '入力してください。' }) =>
  Yup.string().required(required);

export const stringNotRequiredValidation = () => Yup.string().max(100, maxMsg(100));

export const postCodeValidationNoRequired = () =>
  Yup.string().max(100, maxMsg(100)).matches(REGEX_POST_CODE, '郵便番号の形式が正しくありません。');

export const katakanaFullSizeValidationInBankAccountOwner = ({ required = '入力してください。' }) =>
  Yup.string()
    .required(required)
    .matches(katakanaFullSizeRegBankAccountOwner, '全角カタカナで入力してください。');

export const katakanaFullSizeValidation = ({ required = '入力してください。' }) =>
  Yup.string().required(required).matches(katakanaFullSizeReg, '全角カタカナで入力してください。');

export const katakanaFullSizeNotReqValidation = () =>
  Yup.string().test('notKatakana', '全角カタカナで入力してください。', (value: any) =>
    value ? katakanaFullSizeReg.test(value) : true,
  );

export const maximumValueValidation = () =>
  Yup.string().test(
    'notValidValue',
    'プラットフォーム利用料は95％以下に設定してください。',
    (value: any) => (value <= 95 ? true : false),
  );

export const objectRequiredValidation = ({ required = '入力してください。' }) =>
  Yup.object().required(required);

export const arrayRequiredValidation = ({ required = '入力してください。' }) =>
  Yup.array().required(required);

export const saleChannelValidation = ({ required = '入力してください。' }) =>
  Yup.array().required(required).min(1, required).nullable();

export const stringRequiredCharacter = ({
  requiredMsg = '入力してください。',
}: {
  requiredMsg?: string;
  englishCharsMsg?: string;
}) => Yup.string().required(requiredMsg);

export const stringRequiredEnglishCharacter = ({
  requiredMsg = '入力してください。',
  englishCharsMsg = '半角数字で入力してください。',
}: {
  requiredMsg?: string;
  englishCharsMsg?: string;
}) => Yup.string().required(requiredMsg).matches(REGEX_ENGLISH_CHARACTER, englishCharsMsg);

export const stringRequiredUrlMatch = ({
  requiredMsg = '入力してください。',
  urlMsg = 'URLを入力してください。',
}: {
  requiredMsg?: string;
  urlMsg?: string;
}) => Yup.string().required(requiredMsg).matches(REGEX_URL, urlMsg);

export const stringNotRequiredUrlMatch = () =>
  Yup.string().test('urlNotCorrect', 'URLの末尾に「/」をつけないでください。', (value: any) =>
    value ? !!value?.match(REGEX_URL) : true,
  );

export const passwordMinimumEnglishCharOnly = ({
  required = '新しいパスワードを入力してください。',
  match = '新しいパスワードは半角英数混合8文字で入力してください。',
  matchSpecialCharacter = '半角英数字で入力してください。',
}) =>
  Yup.string().required(required).matches(ENGLISH_CHAR_ONLY, matchSpecialCharacter).min(8, match);

export function validateEmailsStringWithSemicolons(emails: any) {
  const emailList = emails?.split(/;|；/);
  for (const email of emailList) {
    if (!email.trim().match(MailRegExp)) {
      return false;
    }
  }
  return true;
}

export const notOfZero = () =>
  Yup.string()
    .required('入力してください。')
    .test('', 'ゼロ以外の値を設定してください。', (value) => {
      return value !== '0';
    });

export function checkIfFilesAreCorrectType(files?: [File]): boolean {
  let valid = true;
  if (files) {
    files.map((file) => {
      if (!['image/jpeg', 'image/png'].includes(file.type)) {
        valid = false;
      }
    });
  }
  return valid;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const fillValueObject = (o1: Object, o2: Object, value: any) => {
  const objectMerge = { ...o2 };
  Object.keys(o1).forEach((i: string) => {
    if (objectMerge[i as keyof typeof o2] === undefined) {
      objectMerge[i as keyof typeof o2] = value;
    }
  });

  return _.pick(objectMerge, Object.keys(o1));
};
