/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import brAxios from 'Api/Interceptors';

export const SerialApis = {
  getSerials: ({
    limit,
    page,
    artistId,
    type,
    companyId,
    sort,
    order,
  }: {
    limit?: number;
    page?: number | null;
    artistId?: null | string | undefined;
    companyId?: null | string | undefined;
    type?: null | string | undefined;
    sort?: string;
    order?: string;
  }) =>
    brAxios.get(
      `/bootrock-company/serial-number/sns?page=${page ?? 1}&limit=${limit ?? 20}${
        artistId ? `&artistIds=${artistId}` : ''
      }${companyId ? `&companyId=${companyId}` : ''}${type ? `&type=${type}` : ''}&sort=${
        sort || 'id'
      }&order=${order || 'DESC'}`,
    ),

  getCdByTitle: ({
    titleId,
    page,
    limit,
    query,
  }: {
    titleId: null | number | string | undefined;
    limit?: number;
    page?: number | null;
    query?: string | null;
  }) =>
    brAxios.get(
      `/bootrock-company/serial-number/cds?page=${page ?? 1}&limit=${limit ?? 20}${
        titleId ? `&titleId=${titleId}` : ''
      }${query ? `&query=${query}` : ''}`,
    ),
  exportCSV: ({ id }: { id: null | number | string | undefined }) =>
    brAxios.get(`bootrock-company/serial-number/sn/${id}/export`),

  getSerialNumber: ({ id }: { id: string | number | undefined }) =>
    brAxios.get(`bootrock-company/serial-number/sn/${id}`),
};
