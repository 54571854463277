import React, { forwardRef, useImperativeHandle } from 'react';
import { Form, Image } from 'react-bootstrap';
import { useQueryParams, StringParam, ArrayParam, withDefault } from 'use-query-params';
import searchIcon from 'Static/Images/search-icon.svg';
import DownIcon from 'Static/Images/down-icon.svg';
import { InputSearchProps } from 'Components/Common/Search/ISearch';

const InputSearch = forwardRef<{ handleSetQuery: (query: string) => void }, InputSearchProps>(
  (
    {
      placeholder,
      params,
      searchKey,
      setShow,
      onEnter,
      onFocusInput,
      isDisabled,
    }: InputSearchProps,
    ref,
  ): JSX.Element => {
    const objParams = { ...params };
    Object.keys(objParams).map((key) => {
      objParams[key] = StringParam;
    });

    const [query, setQuery] = useQueryParams({
      ...objParams,
      filters: withDefault(ArrayParam, []),
    });

    const handleOnchange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setShow(true);
      const value = event.target.value;
      setQuery({ [searchKey]: value });
    };

    useImperativeHandle(ref, () => ({
      handleSetQuery(query: string) {
        setQuery({ [searchKey]: query, page: 1 });
      },
    }));

    return (
      <>
        <div className="position-relative input-search-wrapper">
          <Image src={searchIcon} className="icon-search position-absolute" />
          <Form.Control
            disabled={isDisabled}
            className="input-search"
            onFocus={(e) => {
              setShow(true);
              onFocusInput && onFocusInput(e);
            }}
            value={query[searchKey] ?? ''}
            onChange={handleOnchange}
            placeholder={placeholder}
            onKeyDown={(e: any) => {
              onEnter(e);
              setQuery({ page: 1 });
            }}
            type="search"
          />
          <Image src={DownIcon} className="icon-down position-absolute" />
        </div>
      </>
    );
  },
);

export default InputSearch;
