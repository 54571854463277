/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { TableGroup, TableColumns } from 'Components/Common/Table/TableGroup';
import FormInputNumber from 'Components/Common/Form/FormInputNumber';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import { Row, Button } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import { cloneDeep } from 'lodash';
import { IRecordCompanyOptions } from 'Datasource/RecordCompany';
import ReactTooltip from 'react-tooltip';
import { MAX_FEE, MAX_MB } from 'Constant';

const SLICE_COUNT = 5;
interface CostTableProps {
  dataTable: IRecordCompanyOptions[];
  onDataChange?: (data: any) => void;
  sliceCount?: number;
}

const TableCost = forwardRef(
  ({ dataTable, onDataChange, sliceCount }: CostTableProps, ref: any): JSX.Element => {
    const [deepTable, setDeepTable] = useState<any[]>([]);

    //* emit data table to parent component
    const handleTableInput = (value: string, index: number, key: string) => {
      const tempTable = [...dataTable] as any[];

      if (onDataChange && index !== -1) {
        tempTable[index][key] = value;

        onDataChange && onDataChange(tempTable);
      }
    };

    //* handle valid input table
    // const handleValidTable = (table: any, index: number, key: string) => {
    // const keyArrs = ['maxSongCount', 'minPrice', 'maxVariationStorage', 'limitTokutenStorage'];
    // const blankKeys = ['cardBuyFee', 'taxFee'];
    // const blurTable = [...table];
    // const prevKey = blurTable[index - 1];
    // const nextKey = blurTable[index + 1];
    // const midKey = blurTable[index];
    // const isValidPrev = typeof prevKey !== 'undefined';
    // const isValidNext = typeof nextKey !== 'undefined';
    // const isValidMid = typeof midKey !== 'undefined';
    // let isError = false;
    // if (includes(keyArrs, key)) {
    //   //* first position
    //   if (index === 0 && isValidNext && isValidMid) {
    //     const nextItem = +blurTable[index + 1][key];
    //     const midItem = +blurTable[index][key];
    //     if (midItem >= nextItem || midItem === 0) {
    //       blurTable[index][key] = deepTable?.[index]?.[key];
    //       isError = true;
    //     }
    //   }
    //   //* last postition
    //   if (index === table.length - 1 && isValidPrev && isValidMid) {
    //     const prevItem = +blurTable[index - 1][key];
    //     const midItem = +blurTable[index][key];
    //     if (midItem <= prevItem) {
    //       blurTable[index][key] = deepTable[index][key];
    //       isError = true;
    //     }
    //   }
    //   //* mid position
    //   if (isValidMid && isValidPrev && isValidNext) {
    //     const prevItem = +blurTable[index - 1][key];
    //     const nextItem = +blurTable[index + 1][key];
    //     const midItem = +blurTable[index][key];
    //     if (midItem <= prevItem || midItem >= nextItem) {
    //       blurTable[index][key] = deepTable[index][key];
    //       isError = true;
    //     }
    //   }
    // }
    // if (includes(blankKeys, key)) {
    //   if (!blurTable[index][key] || +blurTable[index][key] === 0) {
    //     blurTable[index][key] = deepTable[index][key];
    //     isError = true;
    //   }
    // }
    // console.log(isError);
    // onDataChange && onDataChange(blurTable);
    // if (!isError) {
    // setDeepTable(cloneDeep(dataTable));
    // }
    // };

    useEffect(() => {
      setDeepTable(cloneDeep(dataTable));
    }, []);

    // const handleBlur = (index: number, key: string) => {
    //   if (onDataChange) {
    //     handleValidTable(dataTable, index, key);
    //   }
    // };

    const handleBlur = () => {
      if (onDataChange) {
        setDeepTable(cloneDeep(dataTable));
      }
    };

    const handleDeleteRow = (index: number) => {
      const newData = [...dataTable];
      const newDeepData = [...deepTable];
      if (index !== -1) {
        newData.splice(index, 1);
        newDeepData.splice(index, 1);
        setDeepTable(newDeepData);
        onDataChange && onDataChange(newData);
      }
    };

    useImperativeHandle(ref, () => ({
      handleUpdateDeepTable(data: any) {
        setDeepTable(cloneDeep(data));
      },
    }));

    const headerTables = [
      {
        columns: [
          {
            text: '収録タイプ',
            rowSpan: 2,
            dataIndex: 'titleType',
            className: 'px-5',
            render: ({ item, index }) => {
              return (
                <>
                  <div data-tip data-for={`title${index}`}>
                    <FormGroupInput
                      value={item?.titleType}
                      onChange={(value) => handleTableInput(value || '', index, 'titleType')}
                      labelMd="1"
                      colMd="12"
                      label=""
                      classInput={`${!item.deletable && 'text-overflow-ellipsis'}`}
                    />
                  </div>
                  {item?.titleType.trim().length > 10 && (
                    <ReactTooltip
                      id={`title${index}`}
                      place="bottom"
                      effect="solid"
                      className="h-35 text-center"
                    >
                      {item?.titleType}
                    </ReactTooltip>
                  )}
                </>
              );
            },
          },

          {
            text: 'DPコンテンツ',
            colSpan: 4,
          },
          {
            text: '費用',
            colSpan: 3,
          },
        ],
      },
      {
        columns: [
          {
            text: '収録曲数上限\n（映像の収録曲を含む）',
            dataIndex: 'maxSongCount',
            render: ({ item, index }) => {
              return (
                <FormInputNumber
                  label=""
                  labelMd="1"
                  colMd="12"
                  name="maxSongCount"
                  classInput="me-2 text-end"
                  classCol="d-flex align-items-baseline"
                  hintText="曲"
                  value={item.maxSongCount}
                  onChange={(value) => handleTableInput(value || '', index, 'maxSongCount')}
                  onBlur={() => handleBlur()}
                  pureType
                  maxValue={MAX_MB}
                />
              );
            },
          },
          {
            text: '最低販売価格\n（税込）',
            dataIndex: 'minPrice',
            className: 'px-4',
            render: ({ item, index }) => {
              return (
                <FormInputNumber
                  label=""
                  labelMd="1"
                  colMd="12"
                  name="minPrice"
                  classInput="me-2 text-end"
                  classCol="d-flex align-items-baseline"
                  hintText="円"
                  value={item.minPrice}
                  onChange={(value) => handleTableInput(value || '', index, 'minPrice')}
                  onBlur={() => handleBlur()}
                  decimalScale={0}
                  pureType
                  maxValue={MAX_FEE}
                />
              );
            },
          },
          {
            text: 'DPデータ容量上限',
            dataIndex: 'maxVariationStorage',
            render: ({ item, index }) => {
              return (
                <div className="d-flex align-items-baseline">
                  <FormInputNumber
                    label=""
                    labelMd="1"
                    colMd="12"
                    name="maxVariationStorage"
                    classInput="me-2 text-end"
                    classCol="d-flex align-items-baseline"
                    hintText="MB"
                    value={item.maxVariationStorage}
                    onChange={(value) =>
                      handleTableInput(value || '', index, 'maxVariationStorage')
                    }
                    onBlur={() => handleBlur()}
                    pureType
                    maxValue={MAX_MB}
                  />
                </div>
              );
            },
          },
          {
            text: 'キャンペーン\nコンテンツ',
            dataIndex: 'limitTokutenStorage',
            className: 'px-4',
            render: ({ item, index }) => {
              return (
                <FormInputNumber
                  label=""
                  labelMd="1"
                  colMd="12"
                  name="limitTokutenStorage"
                  classInput="me-2 text-end"
                  classCol="d-flex align-items-baseline"
                  hintText="MB"
                  value={item.limitTokutenStorage}
                  onChange={(value) => handleTableInput(value || '', index, 'limitTokutenStorage')}
                  onBlur={() => handleBlur()}
                  pureType
                  maxValue={MAX_MB}
                />
              );
            },
          },
          {
            text: 'miim生成費',
            dataIndex: 'cardBuyFee',
            className: 'px-5',
            render: ({ item, index }) => {
              return (
                <FormInputNumber
                  label=""
                  labelMd="1"
                  colMd="12"
                  name="cardBuyFee"
                  classInput="me-2 text-end"
                  classCol="d-flex align-items-baseline"
                  hintText="円"
                  value={item.cardBuyFee}
                  onChange={(value) => handleTableInput(value || '', index, 'cardBuyFee')}
                  onBlur={() => handleBlur()}
                  decimalScale={0}
                  pureType
                  maxValue={MAX_MB}
                />
              );
            },
          },
          {
            text: 'サーバ\nトラフィック費',
            dataIndex: 'taxFee',
            render: ({ item, index }) => {
              return (
                <FormInputNumber
                  label=""
                  labelMd="1"
                  colMd="12"
                  name="taxFee"
                  classInput="me-2 text-end"
                  classCol="d-flex align-items-baseline"
                  hintText="%"
                  value={item.taxFee}
                  onChange={(value) => handleTableInput(value || '', index, 'taxFee')}
                  onBlur={() => handleBlur()}
                  decimalScale={1}
                  pureType
                  maxValue={100}
                />
              );
            },
          },
          {
            text: '削除',
            dataIndex: 'deletable',
            render: ({ item, index }) => {
              return (
                <div className="text-center line-58">
                  {item.deletable && (
                    <Button size="sm" variant="secondary" onClick={() => handleDeleteRow(index)}>
                      <Trash size="16" />
                    </Button>
                  )}
                </div>
              );
            },
          },
        ],
      },
    ] as TableColumns[];

    return (
      <Row className="px-1 pt-4">
        <TableGroup
          dataSource={dataTable}
          tableColumns={headerTables}
          hintText="追加収録タイプ"
          sliceCount={sliceCount || SLICE_COUNT}
        />
      </Row>
    );
  },
);

export default TableCost;
