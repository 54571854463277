import React, { useState, useEffect } from 'react';
import { Pagination } from 'react-bootstrap';
import { ceil } from 'lodash';
import { useQueryParams, NumberParam } from 'use-query-params';
import { PaginationProps } from 'Components/Common/Pagination/pagination-types';

export default function PaginationComponent({
  activePage,
  total,
  perPage,
  onClick,
}: PaginationProps): JSX.Element {
  const pages = ceil(total / perPage);
  const [active, setActive] = useState(activePage);
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
  });

  const handleClickItem = (page: number) => {
    setActive(page);
    setQuery({ page: page });
    if (onClick) {
      return onClick(page);
    }
  };

  const items = [];
  for (let number = 1; number <= pages; number++) {
    items.push(
      <Pagination.Item
        onClick={() => handleClickItem(number)}
        key={number}
        active={number === active}
      >
        {number}
      </Pagination.Item>,
    );
  }

  useEffect(() => {
    if (!query.page) return;
    handleClickItem(query.page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.page]);

  return (
    <div className={`d-flex justify-content-end my-2 ${total <= 0 && `invisible`}`}>
      <span className="me-2 align-self-end custom-text-small">
        {total}件中{1 + ((active || 1) - 1) * perPage}～
        {perPage * (active || 1) < total ? perPage * (active || 1) : total}件を表示
      </span>
      <div>
        <Pagination>
          {pages < 6 ? (
            <>{items}</>
          ) : (
            <>
              {active > 0 && active < 4 ? (
                <>
                  <Pagination.Item onClick={() => handleClickItem(1)} key={1} active={1 === active}>
                    {1}
                  </Pagination.Item>
                  <Pagination.Item onClick={() => handleClickItem(2)} key={2} active={2 === active}>
                    {2}
                  </Pagination.Item>
                  <Pagination.Item onClick={() => handleClickItem(3)} key={3} active={3 === active}>
                    {3}
                  </Pagination.Item>
                  <Pagination.Ellipsis
                    onClick={() => handleClickItem(4)}
                    key={4}
                    active={4 === active}
                  />
                </>
              ) : (
                <>
                  {active < pages ? (
                    <>
                      <Pagination.Ellipsis
                        onClick={() => handleClickItem(active - 2)}
                        key={active - 2}
                        active={active - 2 === active}
                      />
                      <Pagination.Item
                        onClick={() => handleClickItem(active - 1)}
                        active={active - 1 === active}
                      >
                        {active - 1}
                      </Pagination.Item>
                      <Pagination.Item
                        onClick={() => handleClickItem(active)}
                        active={active === active}
                      >
                        {active}
                      </Pagination.Item>
                      <Pagination.Item
                        onClick={() => handleClickItem(active + 1)}
                        active={active + 1 === active}
                      >
                        {active + 1}
                      </Pagination.Item>
                      <Pagination.Ellipsis
                        onClick={() =>
                          handleClickItem(active + 2 > pages ? active + 1 : active + 2)
                        }
                        key={active + 2 > pages ? active + 1 : active + 2}
                        active={active + 2 > pages ? active + 1 === active : active + 2 === active}
                      />
                    </>
                  ) : (
                    <>
                      <Pagination.Ellipsis
                        onClick={() => handleClickItem(active - 2)}
                        key={active - 2}
                        active={active - 2 === active}
                      />
                      <Pagination.Item
                        onClick={() => handleClickItem(pages - 1)}
                        key={pages - 1}
                        active={active - 1 === active}
                      >
                        {pages - 1}
                      </Pagination.Item>
                      <Pagination.Item key={active} active={active === active}>
                        {pages}
                      </Pagination.Item>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Pagination>
      </div>
    </div>
  );
}
