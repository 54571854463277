/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-irregular-whitespace */
import Filter from 'Components/Common/Filter/Filter';
import PaginationComponent from 'Components/Common/Pagination/Pagination';
// import { SortMethod } from 'Components/Common/Table/table';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Container, Row, Spinner } from 'react-bootstrap';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import DetailBox from './DetailBox';
import TableCommon from 'Components/Common/Table/TableCommon';
import { Cell } from 'react-table';
import { ECApis } from 'Datasource/EC';
import { useParams } from 'react-router-dom';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import useMessage from 'Hooks/useMessage';
import dayjs from 'dayjs';
import { DATE_FORMAT_14 } from 'Constant/Date';
import { exportExcel } from 'Utils/File';
import useErrorBlob from 'Hooks/useErrorBlob';
import { formatFixedDecimalAndNumb, formatPrice } from 'Utils/Numbers';
import { PER_PAGE_EC } from 'Constant';

interface IValue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export default function PaymentNotificationDetail(): JSX.Element {
  const [query] = useQueryParams({
    page: NumberParam,
    queryArtist: StringParam,
    queryTitle: StringParam,
    artistId: StringParam,
    titleId: StringParam,
    campaignId: StringParam,
    statusId: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    type: StringParam,
    sort: StringParam,
    order: StringParam,
  });
  const [activePage, setActivePage] = useState(query.page ?? 1);
  const [totalItem, setTotalItem] = useState(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { openMessageError } = useMessage();
  const [transferDetail, setTransferDetail] = useState<any>();
  const [transferList, setTransferList] = useState<any>([]);
  const { openErrorBlob } = useErrorBlob();
  const { id }: { id: string } = useParams();
  const [exporting, setExporting] = useState<boolean>(false);

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'pe-none',
        columns: [
          {
            Header: '行番',
            accessor: 'id',
            width: '70',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <p className="text-truncate">
                  {(row.original?.currentPage - 1) * 50 + row.index + 1}
                </p>
              );
            },
          },
          {
            Header: '注文番号',
            accessor: 'cardCD',
            width: '300',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <p
                  className="text-truncate"
                  data-toggle="tooltip"
                  data-placement="top"
                  title={row.original?.cardCD}
                >
                  {row.original?.cardCD}
                </p>
              );
            },
          },
          {
            Header: '注文日時',
            accessor: 'paymentDate',
            Cell: ({ row }: Cell<IValue>) => {
              const formatDate = dayjs(row.original?.paymentDate).format('YYYY-MM-DD HH:mm');
              return <div className="text-truncate">{formatDate}</div>;
            },
          },
          {
            Header: '支払方法',
            accessor: 'paymentMethod',
            Cell: ({ row }: Cell<IValue>) => {
              return <div className="text-truncate">{row.original?.paymentMethod}</div>;
            },
          },
          {
            Header: 'ブランド',
            accessor: 'cardBrand',
            width: '100',
            Cell: ({ row }: Cell<IValue>) => {
              return <div className="text-truncate">{row.original?.cardBrand}</div>;
            },
          },
          {
            Header: '決済金額',
            accessor: 'paidAmount',
            width: '100',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div
                  className={
                    row.original?.paymentStatus == 2
                      ? 'text-truncate text-error text-right'
                      : 'text-truncate text-right'
                  }
                >
                  {row.original.paidAmount &&
                    formatPrice(formatFixedDecimalAndNumb(row.original.paidAmount, 2))}
                </div>
              );
            },
          },
          {
            Header: '料率',
            accessor: 'feeRate',
            width: '80',
            Cell: ({ row }: Cell<IValue>) => {
              return <div className="text-truncate text-right">{row.original?.feeRate}%</div>;
            },
          },
          {
            Header: '決済手数料',
            accessor: 'platformFee',
            width: '110',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div
                  className={
                    row.original?.paymentStatus == 2 ? 'text-error text-right' : 'text-right'
                  }
                >
                  {row.original.platformFee &&
                    formatPrice(formatFixedDecimalAndNumb(row.original.platformFee, 2))}
                </div>
              );
            },
          },
          {
            Header: 'プラットフォーム利用料',
            accessor: 'platformUsageFee',
            width: '200',
            Cell: ({ row }: Cell<IValue>) => {
              return (
                <div
                  className={
                    row.original?.paymentStatus == 2 ? 'text-error text-right' : ' text-right'
                  }
                >
                  {row.original?.platformUsageFee != null &&
                    formatPrice(formatFixedDecimalAndNumb(row.original?.platformUsageFee, 2))}
                </div>
              );
            },
          },
        ],
      },
    ],
    [],
  );

  const handleDownloadExcel = async (tranferId: string) => {
    const dateParam = dayjs(transferDetail?.paymentDate).format('YYYY-MM');
    const formatDate = dayjs(transferDetail?.paymentDate).format('YYMMDD');
    const fileName = `${formatDate}_PF支払明細_platform`;

    try {
      setExporting(true);
      await exportExcel(ECApis.tranferDetailExportExcel(tranferId, dateParam), fileName);
    } catch (error) {
      openErrorBlob(error);
    } finally {
      setExporting(false);
    }
  };

  // const handleManualSort = useCallback(
  //   (sortBy: SortMethod[]) => {
  //     if (sortBy.length > 0) {
  //       const sortOption = sortBy[0];
  //       const sortKey = sortOption.id;
  //       setQuery({ sort: sortKey, order: sortOption.desc ? 'DESC' : 'ASC' });
  //     } else {
  //       setQuery({ sort: '', order: '' });
  //     }
  //   },
  //   [setQuery],
  // );

  const fetchPaymentDetail = async () => {
    setIsLoading(true);
    try {
      const response = await ECApis.getTranferDetail(id, query.page || 1, PER_PAGE_EC);
      const { data } = response.data;
      const tranferData = data.purchases.map((item: any) => {
        return { ...item, currentPage: data.meta.currentPage };
      });
      setTotalItem(data.meta.totalItems);
      setTransferDetail(data.transfer);
      setTransferList(tranferData);
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPaymentDetail();
  }, [query.page]);

  return (
    <Container fluid>
      <SpinnerComponent isLoading={isLoading} />
      <Filter filterClass={`shadow-sm d-flex justify-content-between`}>
        <div>支払明細</div>
        <div className="d-flex">
          <div>伝票ID　{transferDetail?.transferId}</div>
          <div className="ml-40px">
            発行日　{dayjs(transferDetail?.endDate?.substring(0, 10)).format(DATE_FORMAT_14)}
          </div>
        </div>
      </Filter>
      <DetailBox
        startDate={transferDetail?.startDate}
        endDate={transferDetail?.endDate}
        paymentDate={transferDetail?.paymentDate}
        transferAmount={transferDetail?.transferAmount}
        nextPaymentAmount={transferDetail?.nextPaymentAmount}
        totalAmount={transferDetail?.totalAmount}
        chargeFee={transferDetail?.chargeFee}
        platformFee={transferDetail?.platformFee}
        carriedAmount={transferDetail?.carriedAmount}
        tax={transferDetail?.tax}
      />
      <Row className="space-between mt-3 ml-30px mr-30px">
        <Button
          onClick={() => handleDownloadExcel(id)}
          className={`w-180px h-34px ${exporting ? 'pe-none' : ''}`}
          variant="primary"
        >
          {exporting ? (
            <Spinner
              animation="border"
              variant="light"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            'EXCELダウンロード'
          )}
        </Button>
        <div className="w-50 pr-0px">
          <PaginationComponent
            activePage={activePage}
            total={totalItem}
            perPage={50}
            onClick={(newPage) => setActivePage(newPage)}
          />
        </div>
      </Row>
      <Row className="mt-3 ml-18px mr-30px">
        <TableCommon
          classTable="table-payment-detail pl-0px"
          data={transferList}
          columns={columns}
          getHeaderProps={() => {
            return {
              className: `text-white d-flex align-item-center bg-dark text-center`,
            };
          }}
          isNotShowSort={true}
        />
      </Row>
    </Container>
  );
}
