/* eslint-disable react/prop-types */
import { flatMap, filter } from 'lodash';
import React, { useMemo } from 'react';
import { Form, Table } from 'react-bootstrap';

interface ColumnsTypes {
  className?: string;
  width?: number;
  text: string;
  dataIndex: string;
  rowSpan?: number;
  colSpan?: number;
  render?: (data: any) => void;
  left?: string | number;
}

interface TableColumns {
  columns: ColumnsTypes[];
}

const TableWinningConditions = ({
  prizes,
  tableWrapperClass,
  isReview,
}: {
  prizes: any[];
  courseIndex: number;
  tableWrapperClass?: string;
  isReview?: boolean;
}): JSX.Element => {
  const handleRenderCheckbox = (item: any, dataIndex: string, label: string) => {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Form.Check
          type="checkbox"
          label={label}
          className={`check-box-size check-box-ct ${isReview ? 'pe-none user-select-none' : ''}`}
          checked={item[dataIndex]}
        />
      </div>
    );
  };

  const handleRenderCondition = (prizes: any) => {
    const length = prizes.length;
    return prizes.map((prize: any, prizeIndex: number) => {
      return {
        className: 'text-center align-middle',
        text: `${prizeIndex + 1}`,
        width: length > 1 ? 80 : 400,
        dataIndex: `condition${prizeIndex}`,
        render: ({ item, dataIndex }: { item: any; dataIndex: string }) => {
          return (
            <>
              {item.index + 1 === prizeIndex + 1 ? (
                <div className="diagonal-crossline">&nbsp;</div>
              ) : (
                <></>
              )}
              {item.index < prizeIndex && (
                <div className="checkbox-no-label d-flex align-items-center justify-content-center">
                  <Form.Check
                    type="checkbox"
                    className={`check-box-size check-box-ct ${
                      isReview ? 'pe-none user-select-none' : ''
                    }`}
                    checked={item[dataIndex]}
                  />
                </div>
              )}
            </>
          );
        },
      };
    });
  };

  const headerTables = useMemo(
    () =>
      [
        {
          columns: [
            {
              className: 'text-center align-middle position-sticky header-sticky',
              text: 'No',
              rowSpan: 2,
              width: prizes.length ? 92 : 65,
              dataIndex: 'id',
              left: '0px',
              render: ({ item }) => {
                return (
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="">{item.index + 1}</div>
                  </div>
                );
              },
            },
            {
              className: 'align-middle position-sticky header-sticky',
              text: '重複当選制限設定',
              rowSpan: 2,
              width: prizes.length ? '10%' : 'auto',
              dataIndex: 'name',
              left: prizes.length ? 92 : 65,
              render: ({ item, dataIndex }) => {
                return (
                  <div className="d-flex align-items-center">
                    <div className="text-truncate text-nowrap name-course d-block ms-2">
                      {item[dataIndex]}
                    </div>
                  </div>
                );
              },
            },
            {
              className: 'text-center align-middle',
              text: '当選数上限',
              rowSpan: 2,
              width: prizes.length ? 200 : 'auto',
              dataIndex: 'userQuantity',
              render: ({ item, dataIndex }) => {
                return (
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="text-truncate text-nowrap name-course">
                      {item[dataIndex] ? `${item[dataIndex]} 名` : '上限なし'}
                    </div>
                  </div>
                );
              },
            },
            {
              className: 'text-center align-middle',
              text: 'ブラックリスト',
              rowSpan: 2,
              width: prizes.length ? 200 : 'auto',
              dataIndex: 'abandoned',
              render: ({ item, dataIndex }) => handleRenderCheckbox(item, dataIndex, '適用する'),
            },
            {
              className: 'text-center align-middle',
              text: '同一ユーザ複数当選',
              rowSpan: 2,
              width: prizes.length ? 250 : 'auto',
              dataIndex: 'onlyOne',
              render: ({ item, dataIndex }) =>
                handleRenderCheckbox(item, dataIndex, '複数当選不可'),
            },
            {
              className: 'text-center align-middle',
              text: '他の賞との重複当選制限',
              colSpan: prizes.length || 5,
            },
          ],
        },
        {
          columns: handleRenderCondition(prizes),
        },
      ] as TableColumns[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prizes],
  );

  const headerFields = flatMap(headerTables, ({ columns }) =>
    filter(columns, (column) => !!column.dataIndex),
  );

  return (
    <div className={`px-0 ${tableWrapperClass ?? ''}`}>
      <Table
        className="header-light-gray table-winning-conditions"
        bordered
        responsive
        style={{ minWidth: `${1000 + prizes.length * 50}px` }}
      >
        <thead>
          {headerTables.map((row, rIndex) => {
            return (
              <tr key={rIndex}>
                {row.columns.map((column, cIndex) => {
                  return (
                    <th
                      key={cIndex}
                      colSpan={column.colSpan}
                      rowSpan={column.rowSpan}
                      className={column.className}
                      style={{ width: column.width, left: column.left ? column.left : 'auto' }}
                    >
                      {column.text}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody>
          {prizes.map((row, rIndex) => {
            return (
              <tr key={rIndex}>
                {headerFields.map((cell, cellIndex) => {
                  return (
                    <td
                      key={cellIndex}
                      className={
                        cellIndex === 0 || cellIndex === 1 ? 'position-sticky body-sticky' : ''
                      }
                      style={{
                        left: cell.left ? cell.left : 'auto',
                      }}
                    >
                      {cell.render
                        ? cell.render({
                            item: { ...row, cellIndex },
                            dataIndex: cell.dataIndex,
                          })
                        : (row as any)[cell.dataIndex]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default TableWinningConditions;
