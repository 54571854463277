import * as yup from 'yup';
import { stringRequired, numberRequired, booleanRequired, maxMsg, minMsg } from 'Utils/Validation';
// import { some } from 'lodash';
// import { getBool } from 'Utils/Permission';

const ECSaleProductDetailSchema = yup.object().shape({
  isPublic: booleanRequired({}),
  publicStartDate: yup.string().nullable(),
  publicEndDate: yup
    .string()
    .nullable()
    .when('publicStartDate', {
      is: (publicStartDate: string) => !!publicStartDate,
      then: stringRequired({}).nullable(),
    }),
  saleStartDate: yup.string().nullable(),
  saleEndDate: yup
    .string()
    .nullable()
    .when('saleStartDate', {
      is: (saleStartDate: string) => !!saleStartDate,
      then: stringRequired({}).nullable(),
    }),
  designateDraw: booleanRequired({}),
  maximumDraw: yup
    .number()
    .nullable()
    .when('designateDraw', {
      is: (designateDraw: boolean) => !!designateDraw,
      then: numberRequired({}).nullable(),
    }),
  drawDate: yup
    .string()
    .nullable()
    .when('designateDraw', {
      is: (designateDraw: boolean) => !!designateDraw,
      then: stringRequired({}).nullable(),
    }),
  aboveDetailNote: yup.string().nullable().max(1000, maxMsg(1000)),
  belowDetailNote: yup.string().nullable().max(1000, maxMsg(1000)),
  limitMemberTotalPurchase: yup
    .number()
    .nullable()
    .when('isLimitMemberTotalPurchase', {
      is: (isLimitMemberTotalPurchase: boolean) => !!isLimitMemberTotalPurchase,
      then: numberRequired({}).nullable(),
    })
    .min(1, minMsg(1))
    .max(999, maxMsg(999))
    .test({
      name: 'limitMemberTotalPurchase',
      message: '有効な値を入力してください。',
      test(value, ctx) {
        const isLimitTotalOnetimePurchase = ctx.parent.isLimitTotalOnetimePurchase;

        const isLimitMemberTotalPurchase = ctx.parent.isLimitMemberTotalPurchase;

        const limitTotalOnetimePurchase = ctx.parent.limitTotalOnetimePurchase;

        if (
          isLimitTotalOnetimePurchase &&
          isLimitMemberTotalPurchase &&
          limitTotalOnetimePurchase &&
          value
        ) {
          if (Number(value) < Number(limitTotalOnetimePurchase)) {
            return false;
          }
        }

        return true;
      },
    }),
  limitTotalOnetimePurchase: yup
    .number()
    .nullable()
    .when('isLimitTotalOnetimePurchase', {
      is: (isLimitTotalOnetimePurchase: boolean) => !!isLimitTotalOnetimePurchase,
      then: numberRequired({}).nullable(),
    })
    .min(1, minMsg(1))
    .max(999, maxMsg(999))
    .test({
      name: 'limitTotalOnetimePurchase',
      message: '１ユーザが購入できる最大数より小さい数字を入力してください',
      test(value, ctx) {
        const isLimitTotalOnetimePurchase = ctx.parent.isLimitTotalOnetimePurchase;

        const isLimitMemberTotalPurchase = ctx.parent.isLimitMemberTotalPurchase;

        const limitMemberTotalPurchase = ctx.parent.limitMemberTotalPurchase;

        if (
          isLimitTotalOnetimePurchase &&
          isLimitMemberTotalPurchase &&
          limitMemberTotalPurchase &&
          value
        ) {
          if (Number(value) > Number(limitMemberTotalPurchase)) {
            return false;
          }
        }

        return true;
      },
    }),
  timeBetweenPurchase: yup
    .number()
    .nullable()
    .when('isTimeBetweenPurchase', {
      is: (isTimeBetweenPurchase: boolean) => !!isTimeBetweenPurchase,
      then: numberRequired({}).nullable(),
    })
    .min(1, minMsg(1))
    .max(999, maxMsg(999)),
});

export default ECSaleProductDetailSchema;
