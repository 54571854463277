const AccountTypeOption = [
  {
    label: '普通口座',
    checked: true,
    value: 0,
  },
  {
    label: '当座口座',
    checked: false,
    value: 1,
  },
];

const AccountTypeEcOption = [
  {
    label: '普通口座',
    checked: true,
    value: 0,
  },
  {
    label: '当座口座',
    checked: false,
    value: 1,
  },
];

export { AccountTypeOption, AccountTypeEcOption };
