import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import { find, isEmpty } from 'lodash';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import Filter from 'Components/Common/Filter/Filter';
import PaginationComponent from 'Components/Common/Pagination/Pagination';
import TableBT from 'Components/Common/Table/TableBT';
import { DATE_FORMAT_1, DATE_FORMAT_2 } from 'Constant/Date';
import CampaignSearch from 'Components/Page/Campaign/List/Search';
import { CampaignApis } from 'Datasource/Campaign';
import PrizeRow from 'Components/Page/Campaign/List/PrizeRow';
import { handleParseDate } from 'Utils/ParseDate';
import {
  campaignOptionsType,
  CampaignStatus,
  methodOptions,
  statisticTab,
} from 'Constant/Campaign';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { PER_PAGE } from 'Constant';
import { transformQuery } from 'Utils/Params';
import { currentStartMonth, currentEnDMonth } from 'Utils/DateTime';
import MessageError from 'Components/Common/MessageError';
import useMessage from 'Hooks/useMessage';
import { SortMethod } from 'Components/Common/Table/table';
import { CourseCampaign, Prize } from 'Types/Page/Campaign/RegisterCampaign';

export default function TableCampaign(): JSX.Element {
  const [campaigns, setCampaigns] = useState([]);
  const [campaignTotal, setCampaignTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    companyIds: StringParam,
    artistIds: StringParam,
    titleIds: StringParam,
    sort: StringParam,
    order: StringParam,
  });

  const [activePage, setActivePage] = useState(query.page ?? 1);

  const { openMessageError } = useMessage();

  const noMedia = (courses: any) => {
    return [...courses].some((course: CourseCampaign) => {
      const disabled = [...course.prizes].some((prize: Prize) => {
        const isEmptyFile = isEmpty(prize?.prizeDigitalTokutens)
          ? true
          : prize.prizeDigitalTokutens.some((prizeDT) => {
              return !prizeDT.url || prizeDT.url === '';
            });
        return isEmptyFile && prize.type === campaignOptionsType[3].value;
      });

      return disabled;
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        className: 'd-flex align-items-center justify-content-center',
        accessor: 'id',
        width: 100,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="d-flex align-items-center justify-content-center h-100">
              {row.original?.id}
            </div>
          );
        },
      },
      {
        Header: 'キャンペーン',
        accessor: 'name',
        width: 300,
        Cell: ({ row }: Cell<any>) => {
          const params = {
            startDate: currentStartMonth,
            endDate: currentEnDMonth,
            status: statisticTab.APPLY_STATUS,
          };
          const strParam = transformQuery(params);
          return (
            <div className="p-2">
              <Link to={`/campaigns/${row.original?.id}`} className="d-block text-break">
                <span className="text-start">{row.original?.name}</span>
              </Link>
              {row.original?.status === CampaignStatus.Draft && (
                <MessageError message=" 一時保存中です" />
              )}
              {noMedia(row?.original?.courses) && <MessageError message="未登録コンテンツあり" />}
              <div className="d-flex align-items-center mt-2">
                <Link
                  to={`/statistic-campaign/${row.original?.id}${strParam}`}
                  className="text-white text-decoration-none w-100 h-100"
                >
                  <Button className="fs-12" variant="secondary">
                    応募/抽選状況確認
                  </Button>
                </Link>
              </div>
            </div>
          );
        },
      },
      {
        Header: '販売期間',
        accessor: 'occurrenceTime',
        width: 200,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="p-2 h-100 d-flex flex-column justify-content-center">
              <p>
                開始：
                {handleParseDate({
                  date: row.original?.startDate,
                  format: DATE_FORMAT_2,
                }) ?? '-'}
              </p>
              <p>
                終了：
                {handleParseDate({
                  date: row.original?.endDate,
                  format: DATE_FORMAT_2,
                }) ?? '-'}
              </p>
            </div>
          );
        },
      },
      {
        Header: '対象商品(タイトル/バリエーション)',
        accessor: 'artistTitle',
        width: 300,
        Cell: ({ row }: Cell<any>) => {
          const { artistName, titleName, titleId } = row.original;
          return (
            <div className="p-2 h-100 d-flex flex-column justify-content-center">
              <div>
                <Link title="#" to={`/detail-title/${titleId}`}>
                  <span className="text-start text-break d-block">{`${artistName}/${titleName}`}</span>
                </Link>
              </div>
              {row.original?.variations.map((variation: any, vIndex: number) => (
                <div key={vIndex} className="text-break d-block">
                  {variation?.name}
                </div>
              ))}
              {row.original?.cds && row.original?.cds.length > 0 ? (
                row.original?.cds.map((cd: any) => (
                  <div style={{ maxWidth: 600 }} key={cd.id}>
                    {cd?.name}&nbsp;
                    <span className="badge bg-secondary">CD</span>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          );
        },
      },
      {
        Header: '応募コース',
        accessor: 'courses',
        disableSortBy: true,
        width: 300,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="h-100 d-flex flex-column justify-content-center">
              {row.original?.courses.length ? (
                row.original?.courses.map((course: any, cIndex: number) => {
                  return (
                    <div
                      key={cIndex}
                      className={`p-2 ${
                        cIndex === row.original?.courses.length - 1 ? '' : 'border-bottom'
                      } text-truncate`}
                      style={{ height: `calc(100%/${row.original?.courses.length})` }}
                    >
                      {course?.name}
                    </div>
                  );
                })
              ) : (
                <div className="text-center">-</div>
              )}
            </div>
          );
        },
      },
      {
        Header: '抽選方法 ',
        accessor: 'type',
        disableSortBy: true,
        width: 300,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div
              className={`h-100 ${
                !row.original?.courses.length && 'd-flex flex-column justify-content-center'
              }`}
            >
              {row.original?.courses.length ? (
                row.original?.courses.map((course: any, cIndex: number) => {
                  const typeLabel =
                    find(methodOptions, (option) => option.value === course.type)?.label ?? '';

                  return (
                    <div
                      key={cIndex}
                      className={`p-2 ${
                        cIndex === row.original?.courses.length - 1 ? '' : 'border-bottom'
                      }`}
                      style={{ height: `calc(100%/${row.original?.courses.length})` }}
                    >
                      {typeLabel}
                    </div>
                  );
                })
              ) : (
                <div className="text-center">-</div>
              )}
            </div>
          );
        },
      },
      {
        Header: '景品',
        accessor: 'prize',
        disableSortBy: true,
        width: 400,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div
              className={`h-100 ${
                !row.original?.courses.length && 'd-flex flex-column justify-content-center'
              }`}
            >
              {row.original?.courses.length ? (
                row.original?.courses.map((course: any, cIndex: number) => {
                  return (
                    <div
                      key={cIndex}
                      className={`p-2 ${
                        cIndex === row.original?.courses.length - 1 ? '' : 'border-bottom'
                      }`}
                      style={{ height: `calc(100%/${row.original?.courses.length})` }}
                    >
                      <PrizeRow course={course} />
                    </div>
                  );
                })
              ) : (
                <div className="text-center">-</div>
              )}
            </div>
          );
        },
      },
      {
        Header: '抽選日時',
        accessor: 'standardTime',
        disableSortBy: true,
        width: 200,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="h-100">
              {row.original?.courses.map((course: any, cIndex: number) => {
                return (
                  <div
                    key={cIndex}
                    className={`p-2 ${
                      cIndex === row.original?.courses.length - 1 ? '' : 'border-bottom'
                    } text-center d-flex justify-content-center flex-column`}
                    style={{ height: `calc(100%/${row.original?.courses.length})` }}
                  >
                    {handleParseDate({
                      date: course?.standardTime,
                      format: DATE_FORMAT_2,
                    }) ?? '-'}
                  </div>
                );
              })}
            </div>
          );
        },
      },
      {
        Header: '登録更新日',
        accessor: 'updatedAt',
        width: 150,
        Cell: ({ row }: Cell<any>) => {
          return (
            <div className="d-flex align-items-center justify-content-center p-2 h-100">
              {handleParseDate({
                date: row.original?.updatedAt,
                format: DATE_FORMAT_1,
              }) ?? '-'}
            </div>
          );
        },
      },
    ],
    [],
  );

  const handleGetCampaigns = async () => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await CampaignApis.getCampaigns({
        limit: PER_PAGE,
        page: activePage,
        artistId: query.artistIds,
        titleId: query.titleIds,
        companyId: query.companyIds,
        sort: query.sort || '',
        order: query.order || '',
      });
      setCampaigns(data.campaigns);
      setCampaignTotal(data.totalCount);
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, query.artistIds, query.titleIds, query.companyIds, query.sort, query.order]);

  //* handle sort from server side
  const handleManualSort = useCallback(
    (sortBy: SortMethod[]) => {
      if (sortBy.length > 0) {
        const sortOption = sortBy[0];
        let sortKey = sortOption.id;
        if (sortOption.id === 'occurrenceTime') {
          sortKey = 'startDate';
        }

        if (sortOption.id === 'artistTitle') {
          sortKey = 'titleName';
        }
        setQuery({ sort: sortKey, order: sortOption.desc ? 'DESC' : 'ASC' });
      } else {
        setQuery({ sort: '', order: '' });
      }
    },
    [setQuery],
  );

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <Container fluid>
        <Filter filterClass="shadow-sm">
          <Row>
            <CampaignSearch />
          </Row>
        </Filter>
        <Row>
          <Col md={12} className="d-flex justify-content-end p-0 px-4">
            <PaginationComponent
              activePage={activePage}
              total={campaignTotal}
              perPage={PER_PAGE}
              onClick={(newPage) => setActivePage(newPage)}
            />
          </Col>
        </Row>
        <div className="table-nft-container">
          <Row className="px-4">
            <Col md={12} className="d-flex justify-content-center p-0">
              <TableBT
                heightBody="calc(100vh - 230px)"
                columns={columns}
                data={campaigns}
                getCellProps={() => ({
                  className: 'p-0',
                })}
                manualSortBy
                onSort={handleManualSort}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}
