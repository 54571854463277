import React from 'react';

import Review from 'Components/Page/Title/Review';

export default function TitleDetail(): JSX.Element {
  return (
    <div className="title-register">
      <Review />
    </div>
  );
}
