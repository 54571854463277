const STEPS = {
  CREATE_TITLE_INFO: 1,
  CREATE_VARIATION: 2,
  REVIEW: 3,
  FINAL: 4,
};

export const TitleTypes = [
  {
    label: 'シングル',
    value: 0,
  },
  {
    label: 'ミニアルバム',
    value: 1,
  },
  {
    label: 'アルバム',
    value: 2,
  },
];

export default STEPS;

export const DEFAULT_VARIATION_NAME = '通常盤';

export const PRICE_JPY = [
  0, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200, 210, 220, 230, 240,
  250, 260, 270, 280, 290, 300, 310, 320, 330, 340, 350, 360, 370, 380, 390, 400, 410, 420, 430,
  440, 450, 460, 470, 480, 490, 500, 510, 520, 530, 540, 550, 560, 570, 580, 590, 600, 610, 620,
  630, 640, 650, 660, 670, 680, 690, 700, 710, 720, 730, 740, 750, 760, 770, 780, 790, 800, 810,
  820, 830, 840, 850, 860, 870, 880, 890, 900, 910, 920, 930, 940, 950, 960, 970, 980, 990, 1000,
  1010, 1020, 1030, 1040, 1050, 1060, 1070, 1080, 1090, 1100, 1110, 1120, 1130, 1140, 1150, 1160,
  1170, 1180, 1190, 1200, 1210, 1220, 1230, 1240, 1250, 1260, 1270, 1280, 1290, 1300, 1310, 1320,
  1330, 1340, 1350, 1360, 1370, 1380, 1390, 1400, 1410, 1420, 1430, 1440, 1450, 1460, 1470, 1480,
  1490, 1500, 1510, 1520, 1530, 1540, 1550, 1560, 1570, 1580, 1590, 1600, 1610, 1620, 1630, 1640,
  1650, 1660, 1670, 1680, 1690, 1700, 1710, 1720, 1730, 1740, 1750, 1760, 1770, 1780, 1790, 1800,
  1810, 1820, 1830, 1840, 1850, 1860, 1870, 1880, 1890, 1900, 1910, 1920, 1930, 1940, 1950, 1960,
  1970, 1980, 1990, 2000, 2080, 2090, 2100, 2180, 2190, 2200, 2280, 2290, 2300, 2380, 2390, 2400,
  2480, 2490, 2500, 2580, 2590, 2600, 2680, 2690, 2700, 2780, 2790, 2800, 2880, 2890, 2900, 2980,
  2990, 3000, 3080, 3090, 3100, 3180, 3190, 3200, 3280, 3290, 3300, 3380, 3390, 3400, 3480, 3490,
  3500, 3580, 3590, 3600, 3680, 3690, 3700, 3780, 3790, 3800, 3880, 3890, 3900, 3980, 3990, 4000,
  4080, 4090, 4100, 4180, 4190, 4200, 4280, 4290, 4300, 4380, 4390, 4400, 4480, 4490, 4500, 4580,
  4590, 4600, 4680, 4690, 4700, 4780, 4790, 4800, 4880, 4890, 4900, 4980, 4990, 5000, 5080, 5090,
  5100, 5180, 5190, 5200, 5280, 5290, 5300, 5380, 5390, 5400, 5480, 5490, 5500, 5580, 5590, 5600,
  5680, 5690, 5700, 5780, 5790, 5800, 5880, 5890, 5900, 5980, 5990, 6000, 6080, 6090, 6100, 6180,
  6190, 6200, 6280, 6290, 6300, 6380, 6390, 6400, 6480, 6490, 6500, 6580, 6590, 6600, 6680, 6690,
  6700, 6780, 6790, 6800, 6880, 6890, 6900, 6980, 6990, 7000, 7080, 7090, 7100, 7180, 7190, 7200,
  7280, 7290, 7300, 7380, 7390, 7400, 7480, 7490, 7500, 7580, 7590, 7600, 7680, 7690, 7700, 7780,
  7790, 7800, 7880, 7890, 7900, 7980, 7990, 8000, 8080, 8090, 8100, 8180, 8190, 8200, 8280, 8290,
  8300, 8380, 8390, 8400, 8480, 8490, 8500, 8580, 8590, 8600, 8680, 8690, 8700, 8780, 8790, 8800,
  8880, 8890, 8900, 8980, 8990, 9000, 9080, 9090, 9100, 9180, 9190, 9200, 9280, 9290, 9300, 9380,
  9390, 9400, 9480, 9490, 9500, 9580, 9590, 9600, 9680, 9690, 9700, 9780, 9790, 9800, 9880, 9890,
  9900, 9980, 9990, 10000, 10100, 10200, 10300, 10400, 10500, 10600, 10700, 10800, 10900, 10980,
  11000, 11100, 11200, 11300, 11400, 11500, 11600, 11700, 11800, 11900, 11980, 12000, 12100, 12200,
  12300, 12400, 12500, 12600, 12700, 12800, 12900, 12980, 13000, 13100, 13200, 13300, 13400, 13500,
  13600, 13700, 13800, 13900, 13980, 14000, 14100, 14200, 14300, 14400, 14500, 14600, 14700, 14800,
  14900, 14980, 15000, 15400, 15800, 15980, 16000, 16400, 16800, 16980, 17000, 17400, 17800, 17980,
  18000, 18400, 18800, 18980, 19000, 19400, 19800, 19900, 19980, 20000, 20400, 20800, 20980, 21000,
  21400, 21800, 21980, 22000, 22400, 22800, 22980, 23000, 23400, 23800, 23980, 24000, 24400, 24800,
  24980, 25000, 25400, 25800, 25980, 26000, 26400, 26800, 26980, 27000, 27400, 27800, 27980, 28000,
  28400, 28800, 28980, 29000, 29400, 29800, 29980, 30000, 30400, 30800, 30980, 31000, 31400, 31800,
  31980, 32000, 32400, 32800, 32980, 33000, 33400, 33800, 33980, 34000, 34400, 34800, 34980, 35000,
  35400, 35800, 35980, 36000, 36400, 36800, 36980, 37000, 37400, 37800, 37980, 38000, 38400, 38800,
  38980, 39000, 39400, 39800, 39980, 40000, 40400, 40800, 40980, 41000, 41400, 41800, 41980, 42000,
  42400, 42800, 42980, 43000, 43400, 43800, 43980, 44000, 44400, 44800, 44980, 45000, 45400, 45800,
  45980, 46000, 46400, 46800, 46980, 47000, 47400, 47800, 47980, 48000, 48400, 48800, 48980, 49000,
  49400, 49800, 49980, 50000, 50800, 50980, 51000, 51800, 51980, 52000, 52800, 52980, 53000, 53800,
  53980, 54000, 54800, 54980, 55000, 55800, 55980, 56000, 56800, 56980, 57000, 57800, 57980, 58000,
  58800, 58980, 59000, 59800, 59980, 60000,
];

export const VIDEO_TYPES = {
  MUSIC_VIDEO: 0,
  OTHER: 1,
};

export const INCLUDE_COPY_RIGHT = {
  INCLUDE: 1,
  NOT_INCLUDE: 0,
};

//* if not include -> auto caculate informationFee
export const INCLUDE_JARSAC_CONTENT = {
  INCLUDE: 1,
  NOT_INCLUDE: 0,
};
