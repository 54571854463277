import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Filter from 'Components/Common/Filter/Filter';
import BoxForm from 'Components/Common/Form/BoxForm';
import TableCommonProducts from 'Components/Page/ListProducts/TableCommonProducts';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ProductsApi } from 'Datasource/ProductsApi';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import dayjs from 'dayjs';
import { DATE_FORMAT_1, DATE_FORMAT_17 } from 'Constant/Date';
import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import { useForm } from 'react-hook-form';
import { PAGE_SIZE, TABLE_PRODUCTS_STATUS } from 'Constant';
import { COLOR_DEAFAULT, defaultDates } from 'Constant/Campaign';
import CalendarSwipper from 'Components/Common/Swipper/Swipper';
import { countDays } from 'Utils/DateTime';
import { isEmpty, reduce } from 'lodash';
import { DateType } from 'Types/Page/Campaign/Campaign';
import { handleGetMonths } from 'Utils/Campaign';
import { ProductItem } from 'App/Features/Product';
import { NFTApi } from 'Datasource/NFT';
import useMessage from 'Hooks/useMessage';

interface CampaignEvents {
  dates: DateType[];
  events: any[];
}

export default function CreateProducts(): JSX.Element {
  const { id }: { id: string } = useParams();
  const { openMessageError } = useMessage();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState({
    listCampaigns: [],
    productChannels: [],
    titleName: '',
    artistName: '',
  });
  const [listChannels, setListChannels] = useState<any>([]);
  const [product, setProduct] = useState<any>([]);
  const [dataChoose, setDataChoose] = useState<any>([]);
  const variationProductInfos = dataChoose.flatMap((a: any) => a?.variationProductInfos || []);
  const location = useLocation<any>();
  const history = useHistory();
  const [productCurrent, setProductCurrent] = useState<ProductItem | null>(null);
  const [productId, setProductId] = useState<string | null>(null);
  const searchParams = new URLSearchParams(location.search);
  const productIdFromUrl = searchParams.get('productId') ?? null;

  useEffect(() => {
    setProductId(productIdFromUrl);
  }, [location, productIdFromUrl]);

  const { listCampaigns } = data;
  const dataListCampaigns = listCampaigns?.map((el: any, key: number) => {
    const convertProducts = el?.courseProductInfos?.map((productsInfo: any) => {
      return { ...productsInfo, color: COLOR_DEAFAULT[Number(key) % COLOR_DEAFAULT.length] };
    });
    return {
      ...el,
      color: COLOR_DEAFAULT[Number(key) % COLOR_DEAFAULT.length],
      courseProductInfos: convertProducts,
    };
  });

  const handleFetchCardDetail = async (prodId: string) => {
    try {
      setIsLoading(true);
      const {
        data: { data },
      } = await NFTApi.getCardDetail(prodId);
      setProductCurrent(data);
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!productId) return;
    handleFetchCardDetail(productId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  useEffect(() => {
    if (!productCurrent) return;
    const { productChannel, name, info, contactName, email } = productCurrent;
    const campaignAndCards = info?.campaignAndCards ?? [];

    setProduct(productChannel);
    setValue('campaignProducts', campaignAndCards);
    setValue('name', name);
    setValue('contactName', contactName);
    setValue('email', email);

    if (dataListCampaigns.length > 0) {
      const listCampaign = campaignAndCards.map((el: any) => {
        const idCampaign = el?.campaignInfo?.id;
        return dataListCampaigns.find((campaign: any) => campaign.campaignId === idCampaign);
      });
      setDataChoose(listCampaign);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCurrent, listCampaigns]);

  const { setValue, watch, register } = useForm({});

  const handleGetSaleChannels = async () => {
    setIsLoading(true);
    const {
      data: { data },
    } = await ProductsApi.getSaleChannels({
      titleId: id,
      productId: productIdFromUrl ?? '',
      page: 1,
      showAll: 1,
      query: '',
      limit: PAGE_SIZE,
    });
    setListChannels(data?.items || []);
    setIsLoading(false);
  };

  const handleGetProductInfo = async () => {
    setIsLoading(true);
    const {
      data: { data },
    } = await ProductsApi.getProductInfo({
      titleId: id,
    });
    setData(data);
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetProductInfo();
    handleGetSaleChannels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, productIdFromUrl]);

  const isSelectCampaign = useCallback(
    (item: any) => {
      const idx = dataChoose.findIndex((i: any) => i.campaignId == item.campaignId);
      return idx < 0;
    },
    [dataChoose],
  );

  const isSelectChannels = (item: any) => {
    const idx = product.findIndex((i: any) => i.id == item.id);
    return idx < 0;
  };

  const dataEventsCalender = () => {
    const eventArr = reduce(
      dataChoose,
      (result, item, index) => {
        const dates: DateType[] = [
          ...result?.dates,
          new Date(item.startDate),
          new Date(item.endDate),
        ];
        const chooseIndex = listCampaigns.findIndex((el: any) => el.campaignId === item.campaignId);
        const events: any[] = [
          ...result?.events,
          {
            title: item.campaignName,
            id: `e-${index}`,
            start: new Date(item.startDate),
            end: new Date(item.endDate),
            backgroundColor: COLOR_DEAFAULT[Number(chooseIndex) % COLOR_DEAFAULT.length],
            borderColor: COLOR_DEAFAULT[Number(chooseIndex) % COLOR_DEAFAULT.length],
            description: item.description,
            allDay:
              dayjs(item.startDate).format(DATE_FORMAT_1) ===
              dayjs(item.endDate).format(DATE_FORMAT_1),
          },
        ];
        result = { dates, events };
        return result;
      },
      { dates: [], events: [] } as CampaignEvents,
    );
    return eventArr;
  };

  const listCampaignMemo = useMemo(() => {
    return dataListCampaigns.filter((item: any) => !isSelectCampaign(item));
  }, [isSelectCampaign, dataListCampaigns]);

  return ((productIdFromUrl && productCurrent) || (!productIdFromUrl && !productCurrent)) &&
    !isLoading ? (
    <>
      <Filter filterClass="d-flex align-items-center shadow-sm">
        <span className="fw-bold fs-20 me-4">{data?.titleName}</span>
        <span className="fw-bold fs-12">{data?.artistName}</span>
      </Filter>
      <Container fluid className="p-3">
        <Form>
          <SpinnerComponent isLoading={isLoading} />
          <BoxForm title="複数のキャンペーンを組み合わせて販売商品を作成します">
            <>
              <p className="fw-bold fs-16">販売チャネル</p>
              <hr className="m-0" />
              <Row className="mt-3">
                {((productIdFromUrl && product.length > 0) || !productIdFromUrl) &&
                  listChannels.map((item: any, index: number) => {
                    return (
                      <Col key={index} md="2" className="px-2">
                        <OverlayTrigger
                          key={index}
                          placement="top"
                          overlay={
                            item?.channelName?.trim()?.length > 32 ? (
                              <Tooltip id={`tooltip-${index}`}>{item.channelName}</Tooltip>
                            ) : (
                              <></>
                            )
                          }
                        >
                          <Button
                            className={`w-100 h-50px mb-3 p-1 text-truncate ${
                              isSelectChannels(item) && 'bg-white text-dark custom-border'
                            }`}
                            disabled
                          >
                            {item.channelName}
                          </Button>
                        </OverlayTrigger>
                      </Col>
                    );
                  })}
              </Row>
              <p className="fw-bold fs-16 mt-4">組み合わせるキャンペーンを選択してください</p>
              <hr className="m-0 mb-1" />
              {listCampaignMemo.map((el: any, index: number) => {
                const { campaignName, variationProductInfos, endDate, startDate } = el;
                return (
                  <BoxForm
                    key={index}
                    colorBox={el.color}
                    title={campaignName}
                    classBox="mt-4 label-box-product"
                    extraText={`販売期間: ${dayjs(startDate).format(DATE_FORMAT_17)} ~
                    ${dayjs(endDate).format(DATE_FORMAT_17)}（終了まで
                    ${countDays(new Date(), endDate)}日）`}
                  >
                    <Row>
                      <Col>
                        <TableCommonProducts
                          className="tb-products-variation"
                          data={variationProductInfos}
                          type={TABLE_PRODUCTS_STATUS[0]}
                        />
                      </Col>
                      <Col>
                        <TableCommonProducts
                          className="tb-products-course"
                          data={el?.courseProductInfos}
                          type={TABLE_PRODUCTS_STATUS[1]}
                        />
                      </Col>
                      <Col md={1} className="align-items-center d-flex justify-content-center">
                        <Button
                          className={`h-45px w-90px ${
                            isSelectCampaign(el) && 'bg-white text-dark custom-border'
                          } `}
                          disabled
                        >
                          選択
                        </Button>
                      </Col>
                    </Row>
                  </BoxForm>
                );
              })}

              <hr className="my-4" />
              <BoxForm title="キャンペーン商品">
                <>
                  <FormGroupInput
                    value={watch('name')}
                    labelMd="2"
                    colMd="10"
                    disabled
                    label="管理名称*"
                    register={{ ...register('name') }}
                  />
                  <FormGroupInput
                    value={watch('contactName')}
                    onChange={(e) => setValue('contactName', e)}
                    labelMd="2"
                    colMd="10"
                    disabled
                    label="問合せ先名称*"
                    register={{ ...register('contactName') }}
                  />
                  <FormGroupInput
                    value={watch('email')}
                    onChange={(e) => setValue('email', e)}
                    labelMd="2"
                    disabled
                    colMd="10"
                    label="メールアドレス*"
                    register={{ ...register('email') }}
                  />
                  <Form.Group as={Row}>
                    <Form.Label column md={2}>
                      販売チャネル
                    </Form.Label>
                    <Col md={10} className="">
                      {product.map((field: any, index: number) => {
                        return (
                          <span className="pe-4" key={index}>
                            {field?.channelName}
                          </span>
                        );
                      })}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label column md={2}>
                      対象キャンペーン
                    </Form.Label>
                    <Col md={10} className="">
                      <TableCommonProducts
                        className="table-target-campaign"
                        data={dataChoose}
                        type={TABLE_PRODUCTS_STATUS[2]}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row}>
                    <Form.Label column md={2}>
                      応募商品
                    </Form.Label>
                    <Col md={10} className="">
                      <TableCommonProducts
                        className="table-applied"
                        data={variationProductInfos}
                        type={TABLE_PRODUCTS_STATUS[4]}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group className="mt-2" as={Row}>
                    <Form.Label column md={12}>
                      販売スケジュール
                    </Form.Label>
                    <Col md={12} className="">
                      <hr />
                      <CalendarSwipper
                        campaignRelations={{
                          ...dataEventsCalender(),
                          dates: isEmpty(handleGetMonths(dataEventsCalender()))
                            ? defaultDates
                            : handleGetMonths(dataEventsCalender()),
                        }}
                      />
                    </Col>
                  </Form.Group>
                </>
              </BoxForm>
              <div className="pe-auto d-flex justify-content-center align-items-center pb-4">
                <Button
                  className="btn-equal-width btn-focus-none mt-4"
                  onClick={() => history.push('/list-products')}
                >
                  一覧へ戻る
                </Button>
              </div>
            </>
          </BoxForm>
        </Form>
      </Container>
    </>
  ) : (
    <></>
  );
}
