/* eslint-disable no-unused-vars */
import React from 'react';
import Select, { ActionMeta, GroupTypeBase } from 'react-select';
import { SelectComponents } from 'react-select/src/components';
import { ISelect, SelectValue } from 'Types/Common';

interface ISelectValue {
  id: number;
  value: string;
  label: string;
}
interface Props {
  options: ISelect[];
  className?: string;
  placeholder?: string | Element;
  defaultMenuIsOpen?: boolean;
  components?: Partial<SelectComponents<ISelect, boolean, GroupTypeBase<ISelect>>>;
  onChange: (value: SelectValue, actionMeta: ActionMeta<ISelect>) => void;
  defaultValue?: SelectValue;
  value?: ISelectValue | null;
  isMulti?: boolean;
  styles?: any;
  onMenuScrollToBottom?: any;
  isLoading?: boolean;
  noOptionsMessage?: any;
  minMenuHeight?: number;
  maxMenuHeight?: number;
  isDisabled?: boolean;
  onSearch?: (value: string) => void;
}

export default function SelectComponent(props: Props): JSX.Element {
  const { options, className, placeholder, onChange, ...rest } = props;

  return (
    <Select
      options={options}
      className={className}
      placeholder={placeholder}
      onChange={onChange}
      components={{ IndicatorSeparator: () => null }}
      {...rest}
    />
  );
}
