import React from 'react';
import Search from 'Components/Common/Search/Search';
import { Col } from 'react-bootstrap';
import useSuggestion from 'Hooks/useSuggestion';

export default function SearchSerialNumber(): JSX.Element {
  const {
    companyOptions,
    artistOptions,
    loadingCompany,
    loadingArtist,
    handleLoadMore,
    handleFocusInput,
    handleEnterPrimary,
    handleClickItemPrimary,
  } = useSuggestion({
    validQueryObj: {
      validCompany: {
        artistIds: undefined,
        artistId: undefined,
        queryArtist: undefined,
        queryCompany: undefined,
        companyIds: undefined,
      },
    },
    fetchList: ['company', 'artist'],
  });

  return (
    <>
      <Col md="4" className="px-0 pe-3">
        <Search
          label="レコード会社"
          labelMd="4"
          colMd="8"
          placeholder="検索"
          showIcon
          classLabel="px-0 artist-label"
          rowClass="w-100"
          colClass="px-0"
          searchKey="queryCompany"
          onEnter={() => handleEnterPrimary('queryCompany')}
          onClickResult={handleClickItemPrimary}
          options={companyOptions.options || []}
          loading={loadingCompany}
          onLoadMore={() => handleLoadMore('pageCompany')}
          onFocusInput={() => {
            handleFocusInput('queryCompany', 'pageCompany');
          }}
        />
      </Col>
      <Col md="4" className="px-1">
        <Search
          label="アーティスト"
          labelMd="4"
          colMd="8"
          placeholder="検索"
          showIcon
          classLabel="px-0 artist-label"
          rowClass="w-100"
          colClass="px-0"
          searchKey="queryArtist"
          onEnter={() => handleEnterPrimary('queryArtist')}
          onClickResult={handleClickItemPrimary}
          options={artistOptions.options || []}
          loading={loadingArtist}
          onLoadMore={() => handleLoadMore('pageArtist')}
          onFocusInput={() => {
            handleFocusInput('queryArtist', 'pageArtist');
          }}
        />
      </Col>
    </>
  );
}
