/* eslint-disable react-hooks/exhaustive-deps */
import FormInputNumber from 'Components/Common/Form/FormInputNumber';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import RealTimeChart from 'Components/Page/Campaign/StatisticCampaign/Charts/RealTimeChart';
import RealtimeActualTable from 'Components/Page/Campaign/StatisticCampaign/RealtimeActualTable';
import RealtimeInitTable from 'Components/Page/Campaign/StatisticCampaign/RealtimeInitTable';
import StandardTable from 'Components/Page/Campaign/StatisticCampaign/StandardTable';
import { DATE_FORMAT_10 } from 'Constant/Date';
import { CampaignApis } from 'Datasource/Campaign';
import dayjs from 'dayjs';
import useMessage from 'Hooks/useMessage';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { IStaistic, IStandard, RealTimeActual, RealTimeInit } from 'Types/Page/Campaign/Campaign';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import StandardChart from 'Components/Page/Campaign/StatisticCampaign/Charts/StandardChart';
import { CourseTypes, initialCourse, statisticTab, subTypeOptions } from 'Constant/Campaign';
import { handleEndDate, handleStartDate } from 'Utils/DateTime';
import { CourseCampaign, Prize } from 'Types/Page/Campaign/RegisterCampaign';
import { useAppSelector } from 'App/Store';
import CardWrapper from 'Components/Common/Card/Card';
import TableAwardView from 'Components/Page/Campaign/StatisticCampaign/Tables/TableAwardView';
import PieChart from 'Components/Page/Campaign/Charts/PieChart';
import { toNumber } from 'lodash';
import { getNameBrower } from 'Utils/Brower';
interface Props {
  campaignId: string;
}
export default function ApplyStatus({ campaignId }: Props): JSX.Element {
  const { openMessageError } = useMessage();

  const [query, setQuery] = useQueryParams({
    courseId: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    courseType: NumberParam,
    countJoinEnd: StringParam,
    status: StringParam,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [standardData, setStandardData] = useState<IStandard[]>([]);
  const [realtimeInit, setRealtimeInit] = useState<RealTimeInit[]>([]);
  const [realtimeActual, setRealtimeIActual] = useState<RealTimeActual[]>([]);
  const [statistic, setStatistic] = useState<IStaistic>();
  const [countJoinEnd, setCountJoinEnd] = useState<string>('');
  const [course, setCourse] = useState<CourseCampaign>(initialCourse);
  const [courseIndex, setCourseIndex] = useState<number>(0);

  const { courses } = useAppSelector((state) => state.campaign);

  useEffect(() => {
    const currentCourse = courses.find(
      (item: CourseCampaign) => query.courseId && item.id === +query.courseId,
    );
    const index = courses.findIndex(
      (item: CourseCampaign) => query.courseId && item.id === +query.courseId,
    );
    if (currentCourse) {
      setCourse(currentCourse);
      setCourseIndex(index);
    }
  }, [courses, query.courseId, query.courseType]);

  useEffect(() => {
    if (query.courseId && query.status === statisticTab.APPLY_STATUS) {
      getStatisticData();
    }
  }, [query.courseId, query.status, query.startDate, query.endDate, query.countJoinEnd]);

  const getStatisticData = async () => {
    if (!query.startDate || !query.endDate) return;
    try {
      setLoading(true);
      const {
        data: { data },
      } = await CampaignApis.getStatisticData({
        campaignId,
        courseId: query.courseId || '',
        startDate: handleStartDate(query.startDate) || '',
        endDate: handleEndDate(query.endDate) || '',
        countJoinEnd: query.countJoinEnd || '',
      });
      if (data.type === 0) {
        setStatistic(data);
        setRealtimeInit(data.initialAssumption);
        setRealtimeIActual(data.actualValue);
        setCountJoinEnd(data.countJoinEnd);
        setQuery({ countJoinEnd: data.countJoinEnd });
      } else {
        setStatistic(data);
        setStandardData(data.items);
      }
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  const renderChartData = useCallback((): {
    data: { name: string; rate: number }[];
    onlyLimited: boolean;
  } => {
    const winRates = [...course?.prizes].map((prize: Prize) => ({
      name: prize?.name || '',
      rate: prize?.winRate && toNumber(prize?.winRate) !== 0 ? toNumber(prize.winRate) : 0,
    }));

    const totalWinRate = [...course?.prizes]?.reduce((total: number, currentValue: Prize) => {
      const currentWinRate = currentValue.winRate ? toNumber(currentValue.winRate) : 0;
      return total + currentWinRate;
    }, 0);
    const hasIncentive = [...course?.prizes].some(
      (prize: Prize) => !prize?.userQuantity && prize.isIncentive,
    );

    const data = [
      ...winRates,
      ...[
        {
          name: '不当選',
          rate: course?.slipRate && toNumber(course?.slipRate) > 0 ? toNumber(course.slipRate) : 0,
        },
      ],
    ];

    const type1Disabled = toNumber(course?.subType) === subTypeOptions[0].value && hasIncentive;

    const type2Disabled = toNumber(course?.subType) === subTypeOptions[1].value && hasIncentive;
    if ((type1Disabled || type2Disabled) && totalWinRate > 0 && !course?.slipRate) {
      data.pop();
    }
    return {
      data: data,
      onlyLimited: (type1Disabled || type2Disabled) && totalWinRate > 0 && !course?.slipRate,
    };
  }, [course]);

  const showApplyTime = () => {
    return (
      <div className="d-flex">
        <p>応募期間</p>
        <p className="ms-3 me-2">{`${dayjs(statistic?.timeJoinStart).format(
          DATE_FORMAT_10,
        )}~${dayjs(statistic?.timeJoinEnd).format(DATE_FORMAT_10)}`}</p>
        <p>{`(${realtimeActual.length}日間)`}</p>
      </div>
    );
  };

  const showCountJoinStart = () => (
    <div className="d-flex">
      <p>初日の応募予想数</p>
      <p className="ms-2">{statistic?.countJoinStart}人</p>
    </div>
  );

  const showCountJoinEnd = () => (
    <div className="d-flex">
      <FormInputNumber
        name="countJoinEnd"
        labelMd="6"
        colMd="3"
        label="最終日の応募予想数"
        decimalScale={0}
        allowLeadingZeros={true}
        value={countJoinEnd}
        classCol="position-relative people-amount"
        classForm="label-right"
        onChange={(number) => setCountJoinEnd(number || '')}
        isReview
      />
    </div>
  );
  return (
    <>
      <SpinnerComponent isLoading={loading}></SpinnerComponent>
      {query.courseId && (
        <CardWrapper title={course?.name ?? '景品いろいろ！よくばりコース！'}>
          {query.courseType === CourseTypes.REALTIME &&
            course?.subType !== subTypeOptions[2].value && (
              <div className="text-center m-auto mw-300 pb-3">
                <Row className="px-0">
                  <Col md="5">販売数上限</Col>
                  <Col md="5">
                    {course?.subType === subTypeOptions[0].value
                      ? `${course?.prizeQuantity} 点`
                      : '上限なし'}
                  </Col>
                </Row>
              </div>
            )}
          <Row className="px-0">
            <Col
              md={
                query.courseType === CourseTypes.REALTIME &&
                course?.subType !== subTypeOptions[2].value
                  ? 9
                  : 12
              }
            >
              <TableAwardView courseIndex={courseIndex} course={course} />
            </Col>
            {query.courseType === CourseTypes.REALTIME &&
            course?.subType !== subTypeOptions[2].value ? (
              <Col md="3">
                <PieChart
                  dataChart={renderChartData().data}
                  onlyLimited={renderChartData().onlyLimited}
                />
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </CardWrapper>
      )}

      <Container className="p-4">
        <Row className="p-2">
          {query.courseId && (
            <div className="main-wrap-form p-4">
              {query.courseType !== CourseTypes.REALTIME && (
                <>
                  <StandardTable dataSource={standardData} />
                  <div>
                    <StandardChart
                      title="応募数"
                      dataIndex="applyNumber"
                      dataSource={standardData}
                    />
                    <StandardChart
                      title="当選数"
                      dataIndex="winningNumer"
                      dataSource={standardData}
                    />
                    <StandardChart
                      title="利用数"
                      dataIndex="usedNumber"
                      dataSource={standardData}
                    />
                  </div>
                </>
              )}
              {query.courseType === CourseTypes.REALTIME && (
                <div>
                  <Col
                    md="12"
                    xxl={getNameBrower() === 'safari' ? 12 : 10}
                    className="d-flex justify-content-between align-items-baseline ps-3"
                  >
                    {showApplyTime()}
                    {course.subType === subTypeOptions[2].value && (
                      <>
                        {showCountJoinStart()}
                        {showCountJoinEnd()}
                      </>
                    )}
                  </Col>
                  {course.subType === subTypeOptions[2].value && (
                    <Col md="12" className="mb-4 mt-2">
                      <RealtimeInitTable dataSource={realtimeInit} />
                    </Col>
                  )}
                  <Col md="12">
                    <RealtimeActualTable dataSource={realtimeActual} course={course} />
                  </Col>
                  {course.subType === subTypeOptions[2].value && (
                    <Col md="12" className="mt-4">
                      <RealTimeChart realtimeInit={realtimeInit} realtimeActual={realtimeActual} />
                    </Col>
                  )}
                </div>
              )}
            </div>
          )}
        </Row>
      </Container>
    </>
  );
}
