import brAxios from 'Api/Interceptors';

export type ItemSelect = {
  id: number;
  label: string;
  value: string;
};

export type Data = {
  id?: number;
  name: string;
  furiganaManagerName: string;
  email: string;
  phone: string;
  recordCompanies: { id: number; name: string }[];
};

interface IFormInputs {
  id?: number;
  name: string;
  furiganaName: string;
  email: string;
  phone: string;
  companies: number[];
}

export const CuratorManagerApi = {
  getList: () => brAxios.get('/bootrock-company/managers'),
  getDetail: (id: number) => brAxios.get(`/bootrock-company/managers/${id}`),
  create: (data: IFormInputs) => brAxios.post('/bootrock-company/managers', data),
  update: (id: number, data: IFormInputs) => brAxios.put(`/bootrock-company/managers/${id}`, data),
  delete: (id: number) => brAxios.delete(`/bootrock-company/managers/${id}`),
};
