import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import PaginationComponent from 'Components/Common/Pagination/Pagination';
import TableCommon from 'Components/Common/Table/TableCommon';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { Cell } from 'react-table';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { PAGE_SIZE } from 'Constant';
import { NFTApi } from 'Datasource/NFT';
import dayjs from 'dayjs';
import { IDataCard } from 'Components/Page/NFTList/nftlist';
import { TitleTypes } from 'Constant/Title';
import { DATE_FORMAT_2 } from 'Constant/Date';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import useMessage from 'Hooks/useMessage';

const handleAddIndex = (cards: IDataCard[], pageIndex: number) => {
  cards = cards.map((card, key) => {
    const index = (pageIndex - 1) * PAGE_SIZE + 1 + key;
    return { ...card, index };
  });
  return cards;
};

export default function TableList(): JSX.Element {
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'empty',
        className: 'pe-none',
        columns: [
          {
            Header: 'No.',
            accessor: 'index',
            maxWidth: 100,
            width: 100,
          },
          {
            Header: '商品ID',
            accessor: 'cardCD',
            width: 360,
            Cell: ({ row }: Cell<IDataCard>) => {
              return (
                <div className="text-truncate text-start" title={row.original.cardCD}>
                  {row.original.cardCD}
                </div>
              );
            },
          },
          {
            Header: 'タイトル',
            accessor: 'title',
            maxWidth: 500,
            width: 400,
            Cell: ({ row }: Cell<IDataCard>) => {
              return (
                <div className="text-overflow-ellipsis text-start" title={row.original.title}>
                  {row.original.title}
                </div>
              );
            },
          },
          {
            Header: 'アーティスト',
            accessor: 'artistName',
            maxWidth: 300,
            width: 250,
            Cell: ({ row }: Cell<IDataCard>) => {
              return (
                <div className="text-overflow-ellipsis text-start" title={row.original.artistName}>
                  {row.original.artistName}
                </div>
              );
            },
          },
          {
            Header: 'タイプ',
            accessor: 'type',
            maxWidth: 200,
            width: 150,
            Cell: ({ row }: Cell<IDataCard>) => {
              return (
                <div
                  className="text-overflow-ellipsis text-start"
                  title={TitleTypes[row.original.type].label}
                >
                  {TitleTypes[row.original.type].label}
                </div>
              );
            },
          },
          {
            Header: '曲数',
            accessor: 'songQuantity',
            maxWidth: 150,
            width: 100,
          },
          {
            Header: '特典名称',
            accessor: 'digitalTokutenName',
            maxWidth: 300,
            width: 250,
            Cell: ({ row }: Cell<IDataCard>) => {
              return (
                <Link to={`/promotion-manager/${row.original.digitalTokutenId}`}>
                  <div
                    className="text-overflow-ellipsis text-start"
                    title={row.original.digitalTokutenName}
                  >
                    {row.original.digitalTokutenName}
                  </div>
                </Link>
              );
            },
          },
          {
            Header: '形態',
            accessor: 'form',
            maxWidth: 200,
            width: 150,
            Cell: ({ row }: Cell<IDataCard>) => {
              return row.original.digitalTokutenId ? '特典付き' : 'オリジナル';
            },
          },
          {
            Header: '販売価格',
            accessor: 'price',
            maxWidth: 200,
            width: 150,
            Cell: ({ row }: Cell<IDataCard>) => {
              return (
                <div className="text-end">
                  {row.original.price && Number(row.original.price).toLocaleString()}
                </div>
              );
            },
          },
          {
            Header: '数量限定',
            accessor: 'limitedQuantity',
            maxWidth: 200,
            width: 200,
            Cell: () => {
              return <div className="text-overflow-ellipsis" />;
            },
          },
          {
            Header: '特典販売',
            accessor: 'saleTokenDate',
            maxWidth: 300,
            width: 200,
            Cell: ({ row }: Cell<IDataCard>) => {
              return row.original.saleStartDate
                ? dayjs(row.original.saleStartDate).format(DATE_FORMAT_2)
                : row.original.saleTokenDate
                ? dayjs(row.original.saleTokenDate).format(DATE_FORMAT_2)
                : '';
            },
          },
          {
            Header: '発売⽇',
            accessor: 'downloadDate',
            maxWidth: 300,
            width: 200,
            Cell: ({ row }: Cell<IDataCard>) => {
              return row.original.downloadDate
                ? dayjs(row.original.downloadDate).format(DATE_FORMAT_2)
                : '';
            },
          },
          {
            Header: '登録⽇',
            accessor: 'createdAt',
            maxWidth: 300,
            width: 200,
            Cell: ({ row }: Cell<IDataCard>) => {
              return row.original.createdAt
                ? dayjs(row.original.createdAt).format(DATE_FORMAT_2)
                : '';
            },
          },
          {
            Header: 'JANコード',
            accessor: 'janCode',
            maxWidth: 300,
            width: 200,
          },
        ],
      },
    ],
    [],
  );

  const { openMessageError } = useMessage();

  const [query] = useQueryParams({
    page: NumberParam,
    queryCompany: StringParam,
    queryArtist: StringParam,
    companyId: StringParam,
    artistId: StringParam,
    companyIds: StringParam,
    artistIds: StringParam,
    type: StringParam,
    downloadDateStart: StringParam,
    downloadDateEnd: StringParam,
  });
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState<IDataCard[]>([]);
  const [total, setTotal] = useState(0);

  const handleGetCards = async (ids: any) => {
    try {
      setLoading(true);
      const {
        data: { data },
      } = await NFTApi.getList({
        page: query.page ?? 1,
        limit: PAGE_SIZE,
        companyId: ids.companyIds,
        artistId: ids.artistIds,
        downloadDateStart: ids.downloadDateStart,
        downloadDateEnd: ids.downloadDateEnd,
      });
      const cards = handleAddIndex(data.cards, query.page ?? 1);

      setCards(cards);
      setTotal(data.totalCount);
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const downloadDateStart = query.downloadDateStart
      ? dayjs(query.downloadDateStart).toISOString()
      : null;

    const downloadDateEnd = query.downloadDateEnd
      ? dayjs(query.downloadDateEnd).endOf('d').toISOString()
      : null;

    const ids = {
      companyIds: query.companyIds ?? '',
      artistIds: query.artistIds ?? '',
      downloadDateStart: downloadDateStart,
      downloadDateEnd: downloadDateEnd,
    };
    handleGetCards(ids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    query.page,
    query.companyIds,
    query.artistIds,
    query.downloadDateStart,
    query.downloadDateEnd,
  ]);

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <div className="container-fluid">
        <Row>
          <Col md={12} className="d-flex justify-content-end p-0 px-4">
            <PaginationComponent activePage={query.page ?? 1} total={total} perPage={PAGE_SIZE} />
          </Col>
        </Row>
      </div>
      <div className="px-4 table-nft-container">
        {!isEmpty(cards) ? (
          <>
            <Row>
              <Col md={12} className="d-flex justify-content-center p-0">
                <TableCommon
                  heightBody="calc(100vh - 270px)"
                  columns={columns}
                  data={cards}
                  getHeaderProps={() => {
                    return {
                      className: 'text-white d-flex align-item-center bg-dark',
                    };
                  }}
                  getColumnProps={() => {
                    return {
                      className: 'text-center',
                    };
                  }}
                  getRowProps={() => {
                    return {
                      style: {},
                    };
                  }}
                  getCellProps={() => {
                    return {
                      className: 'bg-white text-dark',
                    };
                  }}
                />
              </Col>
            </Row>
          </>
        ) : (
          <div className="vh-100-final d-flex align-items-center justify-content-center">
            商品が登録されていません
          </div>
        )}
      </div>
    </>
  );
}
