/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { PropsWithChildren, useState, useEffect, useRef } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { isEmpty, filter } from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';
import {
  stringRequiredTrim,
  emailValidation,
  saleChannelValidation,
  maxMsg,
  katakanaFullSizeValidation,
  stringNotRequiredUrlMatch,
  katakanaFullSizeNotReqValidation,
  katakanaFullSizeValidationInBankAccountOwner,
  maximumValueValidation,
  ENGLISH_CHAR_ONLY,
} from 'Utils/Validation';

import FormGroupInput from 'Components/Common/Form/FormGroupInput';
import FormGroupInputEc from 'Components/Common/EC/FormGroupInput';
import BoxForm from 'Components/Common/Form/BoxForm';
import ModalComponent from 'Components/Common/Modal';
import { RecordCompanyApi, IRecordCompanyOptions } from 'Datasource/RecordCompany';
import useMessage from 'Hooks/useMessage';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import {
  handleEnteringPhoneNumber,
  handleEnteringNumber,
  handleEnteringIp,
  handleEnteringDecimal,
} from 'Utils/Numbers';
import 'Components/Page/RecordCompanyList/index.scss';
import MessageError from 'Components/Common/MessageError';
import TableCost from 'Components/Page/RecordCompanyList/TableCost';
import FormInputNumber from 'Components/Common/Form/FormInputNumber';
import { costInformationList, CARDBUYFREE, TAXFEE } from 'Constant/Mockup';
import { MAX_FEE } from 'Constant';
// import { handleSearchSaleChannels } from 'Utils/Search';
import { SaleChannelApi } from 'Datasource/SaleChannelApi';
import CheckBoxs from 'Components/Common/Form/GroupCheckBox/CheckBoxs';
import { FormTypeOptions } from 'Constant/FormType';
import { AccountTypeOption, AccountTypeEcOption } from 'Constant/AccountType';
import FormGroupSelect from 'Components/Common/EC/FormGroupSelect';
import { BankApi } from 'Datasource/Bank';
import { handleEnglishCharOnly } from 'Utils/TextField';

const LABEL_INPUT = 3;
const COL_INPUT = 9;

const recordSchema = yup
  .object()
  .shape({
    companyName: yup
      .string()
      .max(255, maxMsg(255))
      .required('入力してください。')
      .trim('入力してください。'),
    agentName: yup
      .string()
      .max(255, maxMsg(255))
      .required('入力してください。')
      .trim('入力してください。'),
    zipCode: yup
      .string()
      .max(255, maxMsg(255))
      .required('入力してください。')
      .trim('入力してください。'),
    firstAddress: yup
      .string()
      .max(255, maxMsg(255))
      .required('入力してください。')
      .trim('入力してください。'),
    secondAddress: yup.string().max(255, maxMsg(255)),
    phoneNumber: yup
      .string()
      .max(255, maxMsg(255))
      .required('入力してください。')
      .trim('入力してください。'),
    bankCode: yup
      .string()
      .max(255, maxMsg(255))
      .required('入力してください。')
      .trim('入力してください。'),
    bankName: yup.string().max(255, maxMsg(255)),
    branchCode: yup
      .string()
      .max(255, maxMsg(255))
      .required('入力してください。')
      .trim('入力してください。'),
    branchName: yup
      .string()
      .max(255, maxMsg(255))
      .test(
        'codeErr',
        '入力した支店コードが一致する支店名は存在しません。',
        (value) => value !== '入力した支店コードが一致する支店名は存在しません。',
      ),
    bankAccountType: yup.number().max(255, maxMsg(255)),
    bankAccountNumber: yup
      .string()
      .max(255, maxMsg(255))
      .required('入力してください。')
      .trim('入力してください。'),
    bankAccountOwner: katakanaFullSizeValidation({}).trim('入力してください。'),
    registrationNumber: yup
      .string()
      .matches(ENGLISH_CHAR_ONLY, '半角英数字で入力してください。')
      .max(14, maxMsg(14))
      .nullable(),
    masterName: yup
      .string()
      .max(255, maxMsg(255))
      .required('入力してください。')
      .trim('入力してください。'),
    sectionName: yup
      .string()
      .max(255, maxMsg(255))
      .required('入力してください。')
      .trim('入力してください。'),
    masterPhone: yup
      .string()
      .required('入力してください。')
      .max(255, maxMsg(255))
      .required('入力してください。')
      .trim('入力してください。'),
    masterEmail: emailValidation({}),
    channelIds: saleChannelValidation({}),
    snFee: stringRequiredTrim({}),
    snImportFee: stringRequiredTrim({}),
    companyRecordOptions: yup.array().of(
      yup.object().shape({
        maxSongCount: stringRequiredTrim({}),
        titleType: stringRequiredTrim({}),
        cardBuyFee: stringRequiredTrim({}),
        taxFee: stringRequiredTrim({}),
      }),
    ),
  })
  .required();

const miimEcSchema = yup
  .object()
  .shape({
    companyName: yup
      .string()
      .max(255, maxMsg(255))
      .required('入力してください。')
      .trim('入力してください。'),
    agentName: yup
      .string()
      .max(255, maxMsg(255))
      .required('入力してください。')
      .trim('入力してください。'),
    zipCode: yup
      .string()
      .max(255, maxMsg(255))
      .required('入力してください。')
      .trim('入力してください。'),
    firstAddress: yup
      .string()
      .max(255, maxMsg(255))
      .required('入力してください。')
      .trim('入力してください。'),
    secondAddress: yup.string().max(255, maxMsg(255)),
    phoneNumber: yup
      .string()
      .max(255, maxMsg(255))
      .required('入力してください。')
      .trim('入力してください。'),
    bankCode: yup
      .string()
      .max(255, maxMsg(255))
      .required('入力してください。')
      .trim('入力してください。'),
    bankName: yup.string().max(255, maxMsg(255)),
    branchCode: yup
      .string()
      .max(255, maxMsg(255))
      .required('入力してください。')
      .trim('入力してください。'),
    branchName: yup.string().max(255, maxMsg(255)),
    saleChannelDetailBankCode: yup.string().nullable(true).max(255, maxMsg(255)),
    saleChannelDetailBranchCode: yup.string().nullable(true).max(255, maxMsg(255)),
    saleChannelDetailBranchName: yup
      .string()
      .nullable()
      .max(255, maxMsg(255))
      .test(
        'codeErr',
        '入力した支店コードが一致する支店名は存在しません。',
        (value) => value !== '入力した支店コードが一致する支店名は存在しません。',
      ),
    bankAccountType: yup.number().max(255, maxMsg(255)),
    bankAccountNumber: yup
      .string()
      .max(255, maxMsg(255))
      .required('入力してください。')
      .trim('入力してください。'),
    bankAccountOwner: katakanaFullSizeValidationInBankAccountOwner({}).trim('入力してください。'),
    registrationNumber: yup
      .string()
      .matches(ENGLISH_CHAR_ONLY, '半角英数字で入力してください。')
      .max(14, maxMsg(14))
      .nullable(),
    masterName: yup
      .string()
      .max(255, maxMsg(255))
      .required('入力してください。')
      .trim('入力してください。'),
    sectionName: yup
      .string()
      .max(255, maxMsg(255))
      .required('入力してください。')
      .trim('入力してください。'),
    masterPhone: yup
      .string()
      .max(255, maxMsg(255))
      .required('入力してください。')
      .trim('入力してください。'),
    masterEmail: emailValidation({}),
    siteName: yup.string().max(255, maxMsg(255)),
    siteUrl: stringNotRequiredUrlMatch()
      .test('lastSlashForbided', 'URLの末尾に「/」をつけないでください。', (value: any) =>
        value.slice(-1) !== '/' ? true : false,
      )
      .nullable(),
    saleChannelDetailPlatformUsageFee: yup
      .string()
      .when('formType', {
        is: 0,
        then: maximumValueValidation(),
      })
      .nullable()
      .max(255, maxMsg(255)),
    channelName: yup
      .string()
      .max(255, maxMsg(255))
      .required('入力してください。')
      .trim('入力してください。'),
    status: yup.number(),
    ipAddress: yup.string().max(255, maxMsg(255)),
    platformUsageFee: yup.string().max(255, maxMsg(255)),
    bankAccountEcNumber: yup.string().max(255, maxMsg(255)),
    bankAccountEcOwner: katakanaFullSizeNotReqValidation(),
  })
  .required();

interface Iprop {
  onChangeTab: () => void;
}

interface ListSaleChannel {
  id: number;
  companyName: string;
  channelName: string;
  roleCd?: string;
  siteName?: string;
  siteUrl?: string;
}

export default function NewRecordCompany({ onChangeTab }: PropsWithChildren<Iprop>): JSX.Element {
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);

  const [isLoading, setIsloading] = useState<boolean>(false);
  const [formTypeValue, setFormTypeValue] = useState<number>(10);
  const [paymentMethodChecked, setPaymentMethodChecked] = useState<boolean>(false);
  const [listSaleChannel, setListSaleChannel] = useState<ListSaleChannel[]>([]);
  const handleFetchSales = async () => {
    try {
      setIsloading(true);
      const {
        data: { data },
      } = await SaleChannelApi.getSaleChannelsEc(1, 100);
      setListSaleChannel(data?.items || []);
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsloading(false);
    }
  };

  const { openMessageError } = useMessage();

  const callbackRef = useRef<{ handleUpdateDeepTable: (newRow: any) => void }>(null);

  useEffect(() => {
    handleFetchSales();
  }, []);

  const handleCheckChannel = (value: string) => {
    let tempChannel = [...watch('channelIds')];
    const valueNumber = Number(value);
    if (tempChannel.indexOf(valueNumber) === -1) {
      tempChannel.push(valueNumber);
    } else {
      tempChannel = filter(tempChannel, (item) => item !== valueNumber);
    }

    setValue('channelIds', tempChannel);
  };

  const handleCheckPaymentUsed = () => {
    setPaymentMethodChecked(!paymentMethodChecked);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeTable = (childData: any) => {
    setValue('companyRecordOptions', childData);
  };

  const handleAddRowTable = () => {
    const dataTable = [...watch('companyRecordOptions')];
    const lastRow = dataTable.length > 5 ? dataTable[dataTable.length - 1] : dataTable[4];
    const newRow: IRecordCompanyOptions = {
      titleType: '',
      maxSongCount: incrementValue(lastRow.maxSongCount),
      minPrice: incrementValue(lastRow.minPrice),
      maxVariationStorage: incrementValue(lastRow.maxVariationStorage),
      limitTokutenStorage: incrementValue(lastRow.limitTokutenStorage),
      cardBuyFee: CARDBUYFREE,
      taxFee: TAXFEE,
      deletable: true,
    };
    const tempData = [...dataTable, newRow];
    setValue('companyRecordOptions', tempData);
    callbackRef && callbackRef?.current?.handleUpdateDeepTable(tempData);
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(formTypeValue == 10 ? recordSchema : miimEcSchema),
  });

  const onSubmit = handleSubmit(() => {
    setShowModal(true);
  });

  const onSubmitModal = async () => {
    setIsloading(true);
    if (isEmpty(errors) && formTypeValue == 10) {
      const recordOptions = [...watch('companyRecordOptions')];
      recordOptions.forEach((item) => {
        item.deletable = false;
        item.maxSongCount = item.maxSongCount.toString();
        item.minPrice = item.minPrice.toString();
        item.maxVariationStorage = item.maxVariationStorage.toString();
        item.limitTokutenStorage = item.limitTokutenStorage.toString();
        item.cardBuyFee = item.cardBuyFee.toString();
      });
      const param = {
        companyName: watch('companyName').trim(),
        agentName: watch('agentName'),
        zipCode: watch('zipCode'),
        firstAddress: watch('firstAddress'),
        secondAddress: watch('secondAddress'),
        phoneNumber: watch('phoneNumber'),
        bankCode: watch('bankCode'),
        bankName: watch('bankName'),
        branchCode: watch('branchCode'),
        branchName: watch('branchName'),
        bankAccountType: watch('bankAccountType') ? watch('bankAccountType') : 0,
        bankAccountNumber: watch('bankAccountNumber'),
        bankAccountOwner: watch('bankAccountOwner'),
        registrationNumber: watch('registrationNumber'),
        masterName: watch('masterName'),
        sectionName: watch('sectionName'),
        masterPhone: watch('masterPhone'),
        masterEmail: watch('masterEmail').trim(),
        channelIds: watch('channelIds'),
        snFee: watch('snFee'),
        snImportFee: watch('snImportFee'),
        companyRecordOptions: recordOptions,
      };
      try {
        setShowModal(false);
        await RecordCompanyApi.createRecordCompany(param);
        if (onChangeTab) onChangeTab();
        history.push('/record-company-list?status=レコード会社リスト');
      } catch (error) {
        openMessageError(error);
      } finally {
        setIsloading(false);
      }
    } else if (isEmpty(errors) && formTypeValue != 10) {
      const param = {
        type: formTypeValue,
        company: {
          name: watch('companyName').trim(),
          agentName: watch('agentName'),
          zipCode: watch('zipCode'),
          firstAddress: watch('firstAddress'),
          secondAddress: watch('secondAddress'),
          phoneNumber: watch('phoneNumber'),
          bankCode: watch('bankCode'),
          bankName: watch('bankName'),
          branchCode: watch('branchCode'),
          branchName: watch('branchName'),
          bankAccountType: watch('bankAccountType') ? watch('bankAccountType') : 0,
          bankAccountNumber: watch('bankAccountNumber'),
          bankAccountOwner: watch('bankAccountOwner'),
          registrationNumber: watch('registrationNumber'),
          sectionName: watch('sectionName'),
        },
        account: {
          name: watch('masterName'),
          phone: watch('masterPhone'),
          email: watch('masterEmail').trim(),
        },
        saleChannel: {
          siteName: watch('siteName'),
          siteUrl: watch('siteUrl'),
          channelName: watch('channelName'),
          status: watch('siteStatus'),
          ipAddress: watch('ipAddress'),
          platformUsageFee: watch('saleChannelDetailPlatformUsageFee')
            ? watch('saleChannelDetailPlatformUsageFee')
            : 0,
          creditCardPayment: paymentMethodChecked,
          bankCode: watch('saleChannelDetailBankCode'),
          bankName: watch('saleChannelDetailBankName'),
          branchCode: watch('saleChannelDetailBranchCode'),
          branchName: watch('saleChannelDetailBranchName'),
          bankAccountType: watch('bankAccountEcType') ? watch('bankAccountEcType') : 0,
          bankAccountNumber: watch('bankAccountEcNumber'),
          bankAccountOwner: watch('bankAccountEcOwner'),
        },
      };
      try {
        setShowModal(false);
        await SaleChannelApi.createSaleChannelEc(param);
        if (onChangeTab) onChangeTab();
        history.push('/record-company-list?status=レコード会社リスト');
      } catch (error) {
        openMessageError(error);
      } finally {
        setIsloading(false);
      }
    } else {
      throw new Error(`Unknown error: ${JSON.stringify(errors)}`);
    }
  };

  const incrementValue = (value: string) => {
    return String(+value + 1);
  };

  const errorTable = () => {
    let err = '';
    if (errors.companyRecordOptions) {
      errors.companyRecordOptions.forEach((item: any) => {
        Object.values(item).forEach((el: any) => {
          err = el.message as string;
        });
      });
    }
    return err;
  };
  useEffect(() => {
    reset({
      snFee: '1.0',
      snImportFee: '10.0',
      companyRecordOptions: costInformationList,
      channelIds: [],
    });
    callbackRef && callbackRef?.current?.handleUpdateDeepTable(costInformationList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    clearErrors();
    reset({
      snFee: '1.0',
      snImportFee: '10.0',
      companyRecordOptions: costInformationList,
      channelIds: [],
    });
    callbackRef && callbackRef?.current?.handleUpdateDeepTable(costInformationList);
  }, [formTypeValue]);

  const bankNameError = '入力した銀行コードが一致する銀行名は存在しません。';
  const branchNameError = '入力した支店コードが一致する支店名は存在しません。';

  const getBankInfo1 = async (bankCode: string) => {
    const response = await BankApi.getBankInfo(bankCode);
    if (response.data.data[0]) {
      clearErrors('bankCode');
      setValue('bankName', response.data.data[0].name);
    } else {
      setValue('bankName', '');
      setError('bankCode', { type: 'custom', message: bankNameError });
    }
  };

  const getBankInfo2 = async (bankCode: string) => {
    const response = await BankApi.getBankInfo(bankCode);
    if (response.data.data[0]) {
      clearErrors('saleChannelDetailBankCode');
      setValue('saleChannelDetailBankName', response.data.data[0].name);
    } else {
      setValue('saleChannelDetailBankName', '');
      setError('saleChannelDetailBankCode', { type: 'custom', message: bankNameError });
    }
  };

  const getBankBranchInfo1 = async (bankCode: string, branchCode: string) => {
    const response = await BankApi.getBankBranchInfo(bankCode, branchCode);
    if (response.data.data[0]) {
      clearErrors('branchCode');
      setValue('branchName', response.data.data[0].name);
    } else {
      setValue('branchName', '');
      setError('branchCode', { type: 'custom', message: branchNameError });
    }
  };

  const getBankBranchInfo2 = async (bankCode: string, branchCode: string) => {
    const response = await BankApi.getBankBranchInfo(bankCode, branchCode);
    if (response.data.data[0]) {
      clearErrors('saleChannelDetailBranchCode');
      setValue('saleChannelDetailBranchName', response.data.data[0].name);
    } else {
      setValue('saleChannelDetailBranchName', '');
      setError('saleChannelDetailBranchCode', { type: 'custom', message: branchNameError });
    }
  };

  return (
    <Container className="py-4">
      <SpinnerComponent isLoading={isLoading} />
      <ModalComponent
        title="登録確認"
        show={showModal}
        onCloseModal={() => setShowModal(false)}
        classNameContent="d-flex align-items-center content-body-modal justify-content-center"
        variantButtonSubmit="primary"
        submitIdForm="curatorManagement"
        onSubmitModal={onSubmitModal}
      >
        <div>入力した内容で登録します。よろしいですか。</div>
      </ModalComponent>
      <Row>
        <Col className="m-auto" md="12">
          <Form onSubmit={onSubmit} id="register-artist">
            <BoxForm title="基本設定">
              <>
                <CheckBoxs
                  id="form-type"
                  name="form-type"
                  labelMd={LABEL_INPUT}
                  colMd={COL_INPUT}
                  checkboxClass="flex-nowrap"
                  classOption="mr-80px"
                  labelForm="契約種別*"
                  value={watch('formType')}
                  options={FormTypeOptions}
                  onChange={({ value }) => {
                    setValue('formType', value);
                    setFormTypeValue(watch('formType'));
                  }}
                  classForm="mb-3"
                />

                <FormGroupInput
                  value={watch('companyName')}
                  onChange={(value) => setValue('companyName', value ?? '')}
                  labelMd={LABEL_INPUT}
                  colMd={COL_INPUT}
                  label="法人名*"
                  errorMessage={errors.companyName?.message}
                  register={{ ...register('companyName') }}
                />

                <FormGroupInput
                  value={watch('agentName')}
                  onChange={(value) => setValue('agentName', value ?? '')}
                  labelMd={LABEL_INPUT}
                  colMd={COL_INPUT}
                  label="代表者名*"
                  errorMessage={errors.agentName?.message}
                  register={{ ...register('agentName') }}
                />

                <FormGroupInput
                  value={watch('zipCode')}
                  onChange={(value) => setValue('zipCode', handleEnteringNumber(value) ?? '')}
                  labelMd={LABEL_INPUT}
                  colMd={3}
                  label="郵便番号*"
                  maxLength={16}
                  errorMessage={errors.zipCode?.message}
                  register={{ ...register('zipCode') }}
                />

                <FormGroupInput
                  value={watch('firstAddress')}
                  onChange={(value) => setValue('firstAddress', value ?? '')}
                  labelMd={LABEL_INPUT}
                  colMd={COL_INPUT}
                  label="住所1*"
                  errorMessage={errors.firstAddress?.message}
                  register={{ ...register('firstAddress') }}
                />

                <FormGroupInput
                  value={watch('secondAddress')}
                  onChange={(value) => setValue('secondAddress', value ?? '')}
                  labelMd={LABEL_INPUT}
                  colMd={COL_INPUT}
                  label="住所2"
                  errorMessage={errors.secondAddress?.message}
                  register={{ ...register('secondAddress') }}
                />

                <FormGroupInput
                  value={watch('phoneNumber')}
                  onChange={(value) => setValue('phoneNumber', handleEnteringNumber(value) ?? '')}
                  labelMd={LABEL_INPUT}
                  colMd={3}
                  label="電話番号*"
                  errorMessage={errors.phoneNumber?.message}
                  register={{ ...register('phoneNumber') }}
                />

                <FormGroupInput
                  value={watch('bankCode')}
                  onChange={(value) => {
                    setValue('bankCode', handleEnteringNumber(value) ?? '');
                  }}
                  onBlur={(e: any) => {
                    if (watch('bankCode') == '') {
                      setValue('bankName', '');
                    }
                    getBankInfo1(e.target.value);
                    setValue('branchCode', '');
                    setValue('branchName', '');
                  }}
                  labelMd={LABEL_INPUT}
                  colMd={9}
                  label="銀行コード*"
                  errorMessage={errors.bankCode?.message}
                  messageWrapper="mt-0 ml-6px"
                  maxLength={4}
                  register={{ ...register('bankCode') }}
                  classInput="w-60px"
                  classCol="d-flex align-items-center"
                  hintText={`銀行名 ${watch('bankName') != undefined ? `:` : ''}`}
                  hintTextClass="fs-14 ml-40px"
                  hintTextDes={`${watch('bankName') != undefined ? `${watch('bankName')}` : ''}`}
                  hintTextDesClass={`${
                    watch('bankName') == bankNameError ? 'text-error ml-6px fs-small' : 'ml-6px'
                  }`}
                />

                <FormGroupInput
                  value={watch('branchCode')}
                  onChange={(value) => setValue('branchCode', handleEnteringNumber(value) ?? '')}
                  onBlur={(e: any) => {
                    if (watch('branchCode') == '') {
                      setValue('branchName', '');
                    }
                    if (watch('bankName') != '') {
                      getBankBranchInfo1(watch('bankCode'), e.target.value);
                    }
                  }}
                  labelMd={LABEL_INPUT}
                  colMd={9}
                  label="支店コード*"
                  maxLength={3}
                  errorMessage={errors.branchCode?.message}
                  messageWrapper="mt-0 ml-6px"
                  register={{ ...register('branchCode') }}
                  classInput="w-60px"
                  disabled={
                    watch('bankName') == bankNameError ||
                    watch('bankName') == undefined ||
                    watch('bankName') == ''
                      ? true
                      : false
                  }
                  classCol="d-flex align-items-center"
                  hintText={`支店名 ${
                    watch('branchName') != undefined && watch('branchName') != '' ? `:` : ''
                  }`}
                  hintTextClass="fs-14 ml-40px"
                  hintTextDes={`${
                    watch('branchName') != undefined ? `${watch('branchName')}` : ''
                  }`}
                  hintTextDesClass={`${
                    watch('branchName') == branchNameError ? 'text-error ml-6px fs-small' : 'ml-6px'
                  }`}
                />

                <CheckBoxs
                  id="bankAccountType"
                  name="bankAccountType"
                  labelMd={LABEL_INPUT}
                  colMd={COL_INPUT}
                  checkboxClass="flex-nowrap"
                  labelForm="口座種別*"
                  value={watch('bankAccountType')}
                  options={AccountTypeOption}
                  onChange={({ value }) => setValue('bankAccountType', value)}
                  classForm="mb-3"
                />

                <FormGroupInput
                  value={watch('bankAccountNumber')}
                  onChange={(value) =>
                    setValue('bankAccountNumber', handleEnteringNumber(value) ?? '')
                  }
                  labelMd={LABEL_INPUT}
                  colMd={3}
                  label="口座番号*"
                  errorMessage={errors.bankAccountNumber?.message}
                  register={{ ...register('bankAccountNumber') }}
                />

                <FormGroupInput
                  value={watch('bankAccountOwner')}
                  onChange={(value) => setValue('bankAccountOwner', value ?? '')}
                  labelMd={LABEL_INPUT}
                  colMd={COL_INPUT}
                  label="口座名義（カタカナ）*"
                  errorMessage={errors.bankAccountOwner?.message}
                  register={{ ...register('bankAccountOwner') }}
                  hintText="全角カタカナで入力してください（全角カタカナ、全角数字、全角スペース、記号（）．ー／）"
                  hintTextClass="mt-1"
                />

                <FormGroupInputEc
                  value={watch('registrationNumber')}
                  onChange={(value) =>
                    setValue('registrationNumber', handleEnglishCharOnly(value) ?? '')
                  }
                  labelMd={LABEL_INPUT}
                  colMd={4}
                  label="登録番号"
                  maxLength={14}
                  errorMessage={errors.registrationNumber?.message}
                  register={{ ...register('registrationNumber') }}
                  subLabel="（適格請求書発行事業者登録）"
                  hintText="（14桁）"
                  classCol="d-flex align-items-center"
                  classForm="d-flex align-items-center"
                  hintTextClass=" ml-10px min-w-50px"
                  leftTextClass="mr-10px"
                  messageWrapper="mb-2px"
                />

                <FormGroupInput
                  value={watch('masterName')}
                  onChange={(value) => setValue('masterName', value ?? '')}
                  labelMd={LABEL_INPUT}
                  colMd={COL_INPUT}
                  label="マスター担当者*"
                  errorMessage={errors.masterName?.message}
                  register={{ ...register('masterName') }}
                />

                <FormGroupInput
                  value={watch('sectionName')}
                  onChange={(value) => setValue('sectionName', value ?? '')}
                  labelMd={LABEL_INPUT}
                  colMd={COL_INPUT}
                  label="所属部署*"
                  errorMessage={errors.sectionName?.message}
                  register={{ ...register('sectionName') }}
                />

                <FormGroupInput
                  value={watch('masterPhone')}
                  onChange={(value) => setValue('masterPhone', handleEnteringPhoneNumber(value))}
                  labelMd={LABEL_INPUT}
                  colMd={COL_INPUT}
                  label="マスター直通電話番号*"
                  errorMessage={errors.masterPhone?.message}
                  register={{ ...register('masterPhone') }}
                  maxLength={13}
                  hintText="※「-」をつけて入力。（例）03-1234-5678 / 080-1234-5678"
                  hintTextClass="mt-1"
                />

                <FormGroupInput
                  value={watch('masterEmail')}
                  onChange={(value) => setValue('masterEmail', value ?? '')}
                  labelMd={LABEL_INPUT}
                  colMd={COL_INPUT}
                  label="メールアドレス*"
                  errorMessage={errors.masterEmail?.message}
                  register={{ ...register('masterEmail') }}
                  hintText="※登録されるメールアドレスはログインに使われます。登録後にメールアドレスを編集できません。"
                  hintTextClass="mt-1"
                />
              </>
            </BoxForm>

            {formTypeValue == 10 && (
              <>
                <div className="mt-4">
                  <BoxForm title="販売チャネル設定">
                    <div className="mb-4px ps-3">
                      <div className="mb-30px">
                        商品を提供する販売チャネルを選択してください。（複数可）
                      </div>
                      <Form.Group className="max-h-410 pr-4px overflow-y-scroll listSale-scrollbar">
                        {listSaleChannel.map((item, index) => (
                          <div className="d-flex list-sale-channel" key={index}>
                            <div>
                              <Form.Check
                                key={index}
                                type="checkbox"
                                id={`sale-channel-${index}`}
                                value={item.id}
                                className="custom-checkbox"
                                checked={watch('channelIds')?.includes(item.id)}
                                onChange={() => handleCheckChannel(item.id.toString())}
                              />
                            </div>
                            <Row className="d-flex w-100">
                              <Col md="6" className="d-flex flex-column justify-content-center">
                                <div className="mb-4px">{item.channelName}</div>
                                <div>
                                  {item.roleCd == 'EC_COMPANY_MASTER' ? 'miim-EC' : '外部EC'}
                                </div>
                              </Col>
                              <Col md="6" className="d-flex flex-column justify-content-center">
                                <div className="mb-4px overflow-hidden">{item.siteName}</div>
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  className="overflow-hidden"
                                  href={item.siteUrl}
                                >
                                  {item.siteUrl}
                                </a>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </Form.Group>

                      <div>
                        <MessageError message={errors.channelIds?.message} />
                      </div>
                    </div>
                  </BoxForm>
                </div>

                <div className="mt-4">
                  <BoxForm title="料金設定">
                    <div className="py-3">
                      <div className="mx-3 border-bottom-gray d-flex justify-content-between align-items-end">
                        <p className="fw-bold mb-1">miimご利用基本料金</p>
                        <Button
                          className="mb-2 btn-action"
                          variant="primary"
                          onClick={handleAddRowTable}
                        >
                          料金テーブル追加
                        </Button>
                      </div>
                      <div className="mt-2 pt-1 pb-5">
                        <TableCost
                          ref={callbackRef}
                          dataTable={watch('companyRecordOptions') || []}
                          onDataChange={handleChangeTable}
                        />
                        <p className="ms-3 fs-11">
                          メガバイトを基準に容量計算を行います。1GBは1024MB換算で登録してください。
                        </p>
                        <div>
                          {errors.companyRecordOptions && (
                            <MessageError classWrapper="ms-4" message={errorTable()} />
                          )}
                        </div>
                      </div>
                      <p className="pb-1 mt-5 underline-100 position-relative fw-bold text-break">
                        シリアルコードオプション料金
                      </p>
                      <div className="mt-2   d-flex">
                        <div className="d-flex flex-column align-items-end">
                          <FormInputNumber
                            name="snFee"
                            labelMd={5}
                            colMd={5}
                            label="1件発行に付き"
                            hintText="円"
                            classCol="d-flex align-items-baseline"
                            classInput="me-2 text-end"
                            classForm="serialForm ms-3"
                            value={watch('snFee')}
                            decimalScale={1}
                            onChange={(value) => setValue('snFee', value ?? '')}
                            pureType
                            maxValue={MAX_FEE}
                          />
                          <MessageError classWrapper="ms-0 me-1" message={errors.snFee?.message} />
                        </div>
                        <div className="d-flex flex-column align-items-end">
                          <FormInputNumber
                            name="snImportFee"
                            labelMd={6}
                            colMd={5}
                            label="1件読み込みに付き"
                            classCol="d-flex align-items-baseline"
                            decimalScale={1}
                            hintText="円"
                            classInput="me-2 text-end"
                            classForm="serialForm ms-5"
                            value={watch('snImportFee')}
                            onChange={(value) => setValue('snImportFee', value ?? '')}
                            pureType
                            maxValue={MAX_FEE}
                          />
                          <MessageError message={errors.snImportFee?.message} />
                        </div>
                      </div>
                    </div>
                  </BoxForm>
                </div>
              </>
            )}

            {/* EC */}
            {(formTypeValue == 0 || formTypeValue == 1) && (
              <>
                <div className="mt-4">
                  <BoxForm title="ECサイト設定">
                    <>
                      <FormGroupInput
                        value={watch('siteName')}
                        onChange={(value) => setValue('siteName', value ?? '')}
                        labelMd={LABEL_INPUT}
                        colMd={COL_INPUT}
                        label="サイト名"
                        errorMessage={errors.siteName?.message}
                        register={{ ...register('siteName') }}
                      />

                      <FormGroupInput
                        value={watch('siteUrl')}
                        onChange={(value) => {
                          setValue('siteUrl', value ?? '');
                        }}
                        labelMd={LABEL_INPUT}
                        colMd={COL_INPUT}
                        label="サイトURL"
                        errorMessage={errors.siteUrl?.message}
                        register={{ ...register('siteUrl') }}
                      />

                      <FormGroupInput
                        value={watch('channelName')}
                        onChange={(value) => setValue('channelName', value ?? '')}
                        labelMd={LABEL_INPUT}
                        colMd={COL_INPUT}
                        label="省略チャネル名*"
                        maxLength={10}
                        errorMessage={errors.channelName?.message}
                        register={{ ...register('channelName') }}
                      />

                      <FormGroupSelect
                        colMd="3"
                        rowClass="w-100 mb-3"
                        labelMd="3"
                        label="ステータス"
                        placeholder=""
                        defaultValue={{ label: 'システム対応中', value: 0 }}
                        options={[
                          { label: 'システム対応中', value: '0' },
                          { label: '稼働中', value: '1' },
                        ]}
                        disabled
                        onChange={() => []}
                        register={{ ...register('siteStatus') }}
                      />

                      <FormGroupInput
                        value={watch('ipAddress')}
                        onChange={(value) => setValue('ipAddress', handleEnteringIp(value) ?? '')}
                        labelMd={LABEL_INPUT}
                        colMd={3}
                        label="IPアドレス"
                        errorMessage={errors.ipAddress?.message}
                        hintText="miim-ECの場合はシステム保守チームから受取ったIPアドレスを登録してください。
                        外部サイトの場合はAPI接続先のIPアドレスを登録してください。"
                        hintTextClass="mt-1 w-460px"
                        register={{ ...register('ipAddress') }}
                      />

                      {formTypeValue == 0 && (
                        <FormGroupInputEc
                          value={
                            watch('saleChannelDetailPlatformUsageFee')
                              ? watch('saleChannelDetailPlatformUsageFee')
                              : ''
                          }
                          onChange={(value) => {
                            setValue(
                              'saleChannelDetailPlatformUsageFee',
                              handleEnteringDecimal(value) ?? 0,
                            );
                          }}
                          labelMd={LABEL_INPUT}
                          colMd={2}
                          label="プラットフォーム利用料"
                          errorMessage={errors.saleChannelDetailPlatformUsageFee?.message}
                          messageWrapper="mt-8px ml-6px"
                          register={{ ...register('saleChannelDetailPlatformUsageFee') }}
                          hintText="%"
                          hintTextClass="ml-6px"
                          classInput="text-end"
                          classCol="d-flex align-items-center"
                        />
                      )}

                      <Form.Group as={Row}>
                        <Form.Label column md={3}>
                          <p>利用決済方法</p>
                        </Form.Label>
                        <Col md="3" className="col-padding">
                          <Form.Check
                            type="checkbox"
                            id={`payment-method-used`}
                            label="クレジットカード決済"
                            value={1}
                            className="custom-checkbox1"
                            checked={paymentMethodChecked}
                            onChange={() => handleCheckPaymentUsed()}
                          />
                        </Col>
                      </Form.Group>
                    </>
                  </BoxForm>
                </div>

                <div className="mt-4">
                  <BoxForm title="ECサイト売上振込口座">
                    <>
                      <FormGroupInput
                        value={watch('saleChannelDetailBankCode')}
                        onChange={(value) =>
                          setValue('saleChannelDetailBankCode', handleEnteringNumber(value) ?? '')
                        }
                        onBlur={(e: any) => {
                          if (watch('saleChannelDetailBankCode') == '') {
                            setValue('saleChannelDetailBankName', '');
                          }
                          getBankInfo2(e.target.value);
                          setValue('saleChannelDetailBranchCode', '');
                          setValue('saleChannelDetailBranchName', '');
                        }}
                        labelMd={LABEL_INPUT}
                        colMd={9}
                        label="銀行コード"
                        maxLength={4}
                        errorMessage={errors.saleChannelDetailBankCode?.message}
                        messageWrapper="mt-0 ml-6px"
                        register={{ ...register('saleChannelDetailBankCode') }}
                        classInput="w-60px"
                        classCol="d-flex align-items-center"
                        hintText={`銀行名 ${
                          watch('saleChannelDetailBankName') != undefined ? `:` : ''
                        }`}
                        hintTextClass="fs-14 ml-40px"
                        hintTextDes={`${
                          watch('saleChannelDetailBankName') != undefined
                            ? `${watch('saleChannelDetailBankName')}`
                            : ''
                        }`}
                        hintTextDesClass={`${
                          watch('saleChannelDetailBankName') == bankNameError
                            ? 'text-error ml-6px fs-small'
                            : 'ml-6px'
                        }`}
                      />

                      <FormGroupInput
                        value={watch('saleChannelDetailBranchCode')}
                        onChange={(value) =>
                          setValue('saleChannelDetailBranchCode', handleEnteringNumber(value) ?? '')
                        }
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        onBlur={(e: any) => {
                          if (watch('saleChannelDetailBranchCode') == '') {
                            setValue('saleChannelDetailBranchName', '');
                          }
                          if (watch('saleChannelDetailBranchCode') != '') {
                            getBankBranchInfo2(watch('saleChannelDetailBankCode'), e.target.value);
                          }
                        }}
                        labelMd={LABEL_INPUT}
                        colMd={9}
                        label="支店コード"
                        maxLength={3}
                        errorMessage={errors.saleChannelDetailBankCode?.message}
                        messageWrapper="mt-0 ml-6px"
                        register={{ ...register('saleChannelDetailBranchCode') }}
                        classInput="w-60px"
                        disabled={
                          watch('saleChannelDetailBankName') == bankNameError ||
                          watch('saleChannelDetailBankName') == undefined ||
                          watch('saleChannelDetailBankName') == ''
                            ? true
                            : false
                        }
                        classCol="d-flex align-items-center"
                        hintText={`支店名 ${
                          watch('saleChannelDetailBranchName') != undefined ? `:` : ''
                        }`}
                        hintTextClass="fs-14 ml-40px"
                        hintTextDes={`${
                          watch('saleChannelDetailBranchName') != undefined
                            ? `${watch('saleChannelDetailBranchName')}`
                            : ''
                        }`}
                        hintTextDesClass={`${
                          watch('saleChannelDetailBranchName') == branchNameError
                            ? 'text-error ml-6px fs-small'
                            : 'ml-6px'
                        }`}
                      />

                      <CheckBoxs
                        id="account-ec-type"
                        name="account-ec-type"
                        labelMd={LABEL_INPUT}
                        colMd={COL_INPUT}
                        checkboxClass="flex-nowrap"
                        labelForm="口座種別"
                        value={watch('bankAccountEcType')}
                        options={AccountTypeEcOption}
                        onChange={({ value }) => setValue('bankAccountEcType', value)}
                        classForm="mb-3"
                      />

                      <FormGroupInput
                        value={watch('bankAccountEcNumber')}
                        onChange={(value) =>
                          setValue('bankAccountEcNumber', handleEnteringNumber(value) ?? '')
                        }
                        labelMd={LABEL_INPUT}
                        colMd={3}
                        label="口座番号"
                        errorMessage={errors.bankAccountEcNumber?.message}
                        register={{ ...register('bankAccountEcNumber') }}
                      />

                      <FormGroupInput
                        value={watch('bankAccountEcOwner')}
                        onChange={(value) => setValue('bankAccountEcOwner', value ?? '')}
                        labelMd={LABEL_INPUT}
                        colMd={COL_INPUT}
                        label="口座名義（カタカナ）"
                        errorMessage={errors.bankAccountEcOwner?.message}
                        hintText="全角カタカナで入力してください（全角カタカナ、全角数字、全角スペース、記号（）．ー／）"
                        hintTextClass="mt-1"
                        register={{ ...register('bankAccountEcOwner') }}
                      />
                    </>
                  </BoxForm>
                </div>
              </>
            )}

            <div className="text-center mt-4">
              <div className="d-flex justify-content-end txt-require mt-2">
                ご注意：＊印は必須項目です。
              </div>
              <p className="mb-3 text-center">
                登録すると入力した担当者へログイン情報が記載されたメールが送信されます。
              </p>
              <Button type="submit" className="px-5 btn-equal-width" variant="primary">
                登録する
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
