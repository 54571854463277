import React from 'react';
import { Container, Row, Col, Form, FormGroup, FormLabel, Button } from 'react-bootstrap';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from 'lodash';

interface IFormInputs {
  '1': string;
  '2': string;
  '3': string;
  '4': string;
  '5': string;
  '6': string;
  '7': string;
  '8': string;
  '9': string;
  '10': string;
}
const schema = yup.object().shape({
  '1': yup.string().required(),
  '2': yup.string().required(),
  '3': yup.string().required(),
  '4': yup.string().required(),
  '5': yup.string().required(),
  '6': yup.string().required(),
  '7': yup.string().required(),
  '8': yup.string().required(),
  '9': yup.string().required(),
  '10': yup.string().required(),
});
// validate feild cap nhap vao trong obj va se duoc chuyen vao trong ham constants khi co api
const feildLabelForm = [
  {
    id: 1,
    labelName: 'ホームページ',
    registerFeild: '1',
    required: true,
    type: 'text',
    placeholder: '',
    messageErr: 'feil err',
    classNameCol: '',
  },
  {
    id: 2,
    labelName: 'リミスタ',
    registerFeild: '2',
    required: true,
    type: 'text',
    placeholder: '',
    messageErr: 'feil err',
    classNameCol: '',
  },
  {
    id: 3,
    labelName: '代表者名',
    registerFeild: '3',
    required: true,
    type: 'text',
    placeholder: '',
    messageErr: 'feil err',
    classNameCol: '',
  },
  {
    id: 4,
    labelName: '郵便番号',
    registerFeild: '4',
    required: true,
    type: 'text',
    placeholder: '',
    messageErr: 'feil err',
    classNameCol: '',
  },
  {
    id: 5,
    labelName: '住所',
    registerFeild: '5',
    required: true,
    type: 'text',
    placeholder: '',
    messageErr: 'feil err',
    classNameCol: '',
  },
  {
    id: 6,
    labelName: '電話番号',
    registerFeild: '6',
    required: true,
    type: 'text',
    placeholder: '',
    messageErr: 'feil err',
    classNameCol: '',
  },
  {
    id: 7,
    labelName: '緊急電話番号',
    registerFeild: '7',
    required: true,
    type: 'text',
    placeholder: '',
    messageErr: 'feil err',
    classNameCol: '',
  },
  {
    id: 8,
    labelName: 'マスター担当者',
    registerFeild: '8',
    required: true,
    type: 'text',
    placeholder: '',
    messageErr: 'feil err',
    classNameCol: 'mt-5',
  },
  {
    id: 9,
    labelName: '直通電話番号',
    registerFeild: '9',
    required: true,
    type: 'text',
    placeholder: '',
    messageErr: 'feil err',
    classNameCol: '',
  },
  {
    id: 10,
    labelName: 'メールアドレス',
    registerFeild: '10',
    required: true,
    type: 'text',
    placeholder: '',
    messageErr: 'feil err',
    classNameCol: '',
  },
];

export default function RegisterInfo(): JSX.Element {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const formGroup = (label: string, render?: JSX.Element) => (
    <FormGroup as={Row} className="w-100">
      <FormLabel column sm="4" className="fw-bold">
        {label}
      </FormLabel>
      <Col sm="8">{render}</Col>
    </FormGroup>
  );

  const onSubmit: SubmitHandler<IFormInputs> = (data) => console.log(data);

  return (
    <div className="main-wrap">
      <Container fluid>
        <Row>
          <Col md="7" className="mt-5 m-auto">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Col md="12" className="mb-3">
                {formGroup(
                  '法人名',
                  <Form.Control
                    type="text"
                    placeholder="株式会社ブートロック"
                    disabled
                    className="bg-transparent border-0"
                  />,
                )}
              </Col>
              {feildLabelForm &&
                feildLabelForm.map((val, index) => {
                  return (
                    <Col md="12" key={index} className={`mb-2 ${val.classNameCol}`}>
                      {formGroup(
                        `${val.labelName}`,
                        <Form.Control
                          type={`${val.type}`}
                          placeholder={`${val.placeholder}`}
                          className="w-100"
                          {...register(`${val.registerFeild}`, {
                            required: val.required,
                            maxLength: 20,
                          })}
                        />,
                      )}
                      <Col md="8" className="ms-auto ps-3 text-danger">
                        <div style={{ height: 20 }}>
                          {_.get(errors, `${val.registerFeild}.message`)}
                        </div>
                      </Col>
                    </Col>
                  );
                })}
              <Form.Group as={Row} className="mb-2 mt-6" controlId="">
                <Col md="8" className="mt-3 text-center ms-auto">
                  <Button className="w-100" variant="dark" type="submit">
                    プレビュー
                  </Button>
                </Col>
                <Col md="8" className="mt-3 text-center ms-auto">
                  <Button className="w-100" variant="danger" type="submit">
                    保存
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
