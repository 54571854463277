/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import Search from 'Components/Common/Search/Search';
import {
  handleGetIds,
  handleSearchCourses,
  handleSearchPrizes,
  PageQuery,
  updateQuery,
} from 'Utils/Search';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import Tabs from 'Components/Common/Tabs/Tabs';
import dayjs from 'dayjs';
import { IPullDownItem } from 'Types/Common';

import {
  CAMPAIGN_INITIAL_STATE,
  CourseTypes,
  initialCourse,
  statisticTab,
} from 'Constant/Campaign';
import useMessage from 'Hooks/useMessage';
import useCampaign from 'Hooks/useCampaign';
import { CampaignApis } from 'Datasource/Campaign';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { SearchResponse } from 'Hooks/useSuggestion';
import { useAppDispatch } from 'App/Store';
import { updateStep2State } from 'App/Features/Campaign';
interface Props {
  tabs: any[];
  onExportExcel?: () => void;
  loadingExcel?: boolean;
}
export default function SearchCourse({ tabs, onExportExcel, loadingExcel }: Props): JSX.Element {
  const { id }: { id: string } = useParams();
  const [courseOptions, setCourseOptions] = useState<SearchResponse>({
    options: [],
    totalPages: 0,
  });

  const [prizeOptions, setPrizeOptions] = useState<SearchResponse>({
    options: [],
    totalPages: 0,
  });

  const [loadingCourse, setLoadingCourse] = useState<boolean>(false);
  const [loadingPrize, setLoadingPrize] = useState<boolean>(false);

  const [campaign, setCampaign] = useState<any>({
    overview: CAMPAIGN_INITIAL_STATE.overview,
    courses: CAMPAIGN_INITIAL_STATE.courses,
  });

  const { handleDecodeMatrixPrizes } = useCampaign();
  const { openMessageError } = useMessage();

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    courseId: StringParam,
    courseIds: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    queryCourse: StringParam,
    countJoinEnd: StringParam,
    prizeId: StringParam,
    prizeIds: StringParam,
    queryPrize: StringParam,
    status: StringParam,
    courseType: NumberParam,
  });

  const dispatch = useAppDispatch();
  const [pageObj, setPageObj] = useState<PageQuery>({
    pageCourse: 1,
    pagePrize: 1,
  });

  const [queryObj, setQueryObj] = useState({
    queryCourse: query.queryCourse,
    queryPrize: query.queryPrize,
  });

  const isFristTab = query.status === statisticTab.CAMPAIGN_DETAIL;

  const isSecondTab = query.status === statisticTab.APPLY_STATUS;

  const isThirdTab = query.status === statisticTab.WINNING_RESULT;

  const handleFetchCourses = async () => {
    try {
      setLoadingCourse(true);
      const {
        data: { data },
      } = await handleSearchCourses({
        queryCourse: queryObj.queryCourse || '',
        page: pageObj.pageCourse,
        campaignId: id,
        courseId: query.courseId || '',
      });

      const options = data?.items?.map((course: IPullDownItem) => ({
        label: course.name,
        value: course.id,
      }));
      setCourseOptions({
        options: pageObj.pageCourse === 1 ? options : [...courseOptions.options, ...options],
        totalPages: data?.meta?.totalPages,
      });
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoadingCourse(false);
    }
  };

  const handleFetchPrizes = async () => {
    try {
      setLoadingPrize(true);
      const {
        data: { data },
      } = await handleSearchPrizes({
        queryPrize: queryObj.queryPrize || '',
        page: pageObj.pageCourse,
        campaignId: id,
        courseId: query.courseId || '',
      });

      const options = data?.items?.map((prize: IPullDownItem) => ({
        label: prize.name,
        value: prize.id,
      }));
      setPrizeOptions({
        options: pageObj.pagePrize === 1 ? options : [...prizeOptions.options, ...options],
        totalPages: data?.meta?.totalPages,
      });
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoadingPrize(false);
    }
  };

  const handleGetCampaign = async () => {
    try {
      const {
        data: { data },
      } = await CampaignApis.getCampaign({ id });
      const overview = {
        artistId: data?.artistId,
        artistName: data?.artistName,
        titleId: data?.titleId,
        titleName: data?.titleName,
        name: data?.name,
        startDate: data?.startDate,
        endDate: data?.endDate,
        description: data?.description,
        dataSize: +data?.dataSize ?? 0,
        status: data?.status,
        isDeletable: data?.isDeletable,
        earliestSaleDate: data?.earliestSaleDate,
        isAuthor: data?.isAuthor,
      };

      let courses = handleDecodeMatrixPrizes(data?.courses);
      if (isEmpty(courses)) {
        courses = [initialCourse];
      }
      dispatch(
        updateStep2State({
          courses,
        }),
      );
      setCampaign({ overview, courses });
    } catch (error) {
      openMessageError(error);
    }
  };

  useEffect(() => {
    handleGetCampaign();
  }, [id, query.courseId]);

  const handleEnter = useCallback(
    (type: string) => {
      const res = [courseOptions.options, prizeOptions.options];
      const ids = res.map((item: any) => handleGetIds(item).split(','));

      if (type === 'queryCourse') {
        const courseIds = query[type] ? (ids[0] ? ids[0][0] : '') : '';
        const { overview, courses } = campaign;
        const currentCourse = courses.find((item: any) => +item.id === +ids[0][0]);
        if (currentCourse) {
          const courseType = currentCourse.type;
          setQuery({ courseType: courseType });
          switch (courseType) {
            case CourseTypes.ALL:
              if (!overview.startDate || !overview.endDate) {
                break;
              }
              setQuery({
                startDate: overview?.startDate,
                endDate: dayjs(overview?.endDate).add(1, 'month').toISOString(),
              });
              break;
            case CourseTypes.STANDARD:
              if (!currentCourse.timeJoinStart || !currentCourse.standardTime) {
                break;
              }
              setQuery({
                startDate: currentCourse?.timeJoinStart,
                endDate: dayjs(currentCourse?.standardTime).add(1, 'month').toISOString(),
              });
              break;
            case CourseTypes.REALTIME:
              if (!currentCourse.timeJoinStart || !currentCourse.timeJoinEnd) {
                break;
              }
              setQuery({
                startDate: currentCourse?.timeJoinStart,
                endDate: dayjs(currentCourse?.timeJoinEnd).add(1, 'month').toISOString(),
              });
              break;
            default:
              break;
          }
        }
        setQuery({ courseId: courseIds, page: 1 });
      }

      if (type === 'queryPrize') {
        const prizeIds = query[type] ? (ids[1] ? ids[1][0] : '') : '';
        setQuery({ prizeId: prizeIds, page: 1 });
      }
    },

    [campaign, courseOptions.options, prizeOptions.options, query, setQuery],
  );

  const handleClickItem = useCallback(
    (id: string, type: string) => {
      if (type === 'queryCourse') {
        const { overview, courses } = campaign;
        const currentCourse = courses.find((item: any) => item.id === id);
        if (currentCourse) {
          const courseType = currentCourse.type;
          setQuery({ courseType: courseType });
          switch (courseType) {
            case CourseTypes.ALL:
              if (!overview.startDate || !overview.endDate) {
                break;
              }
              setQuery({
                startDate: overview?.startDate,
                endDate: dayjs(overview?.endDate).add(1, 'month').toISOString(),
              });
              break;
            case CourseTypes.STANDARD:
              if (!currentCourse.timeJoinStart || !currentCourse.standardTime) {
                break;
              }
              setQuery({
                startDate: currentCourse?.timeJoinStart,
                endDate: dayjs(currentCourse?.standardTime).add(1, 'month').toISOString(),
              });
              break;
            case CourseTypes.REALTIME:
              if (!currentCourse.timeJoinStart || !currentCourse.timeJoinEnd) {
                break;
              }
              setQuery({
                startDate: currentCourse?.timeJoinStart,
                endDate: dayjs(currentCourse?.timeJoinEnd).add(1, 'month').toISOString(),
              });
              break;
            default:
              break;
          }
        }
        setQuery({ courseId: id, page: 1 });
      }

      if (type === 'queryPrize') {
        setQuery({ prizeId: id, page: 1 });
      }
    },
    [campaign, setQuery],
  );

  const debouceRequest = useCallback((value) => updateQuery(value, setQueryObj), []);

  useEffect(() => {
    debouceRequest({
      queryCourse: query.queryCourse,
      queryPrize: query.queryPrize,
    });
  }, [query.queryCourse, query.queryPrize]);

  useEffect(() => {
    handleFetchCourses();
  }, [queryObj, pageObj.pageCourse]);

  useEffect(() => {
    handleFetchPrizes();
  }, [queryObj, pageObj.pagePrize]);

  useEffect(() => {
    if (!query.status) {
      setQuery({ status: statisticTab.APPLY_STATUS });
    }
  }, [query.status]);

  useEffect(() => {
    if (!query.queryCourse) {
      setQuery({
        courseId: undefined,
        queryCourse: undefined,
        courseIds: undefined,
        courseType: undefined,
        startDate: undefined,
        endDate: undefined,
      });
    }
    setPageObj({ pageCourse: 1, pagePrize: 1 });
  }, [query.queryCourse]);

  useEffect(() => {
    if (!query.queryPrize) {
      setQuery({ prizeId: undefined, prizeIds: undefined, queryPrize: undefined });
    }
    setPageObj({ ...pageObj, pagePrize: 1 });
  }, [query.queryPrize]);

  const handleLoadMore = (pageKey: string) => {
    if (pageKey === 'pageCourse' && pageObj.pageCourse < courseOptions.totalPages)
      setPageObj({ ...pageObj, pageCourse: (pageObj.pageCourse += 1) });

    if (pageKey === 'pagePrize' && pageObj.pagePrize < prizeOptions.totalPages)
      setPageObj({ ...pageObj, pagePrize: (pageObj.pagePrize += 1) });
  };

  const handleFocusInput = (queryKey: string, pageKey: string) => {
    if (queryKey === 'queryCourse') setCourseOptions({ options: [], totalPages: 0 });
    if (queryKey === 'queryPrize') setPrizeOptions({ options: [], totalPages: 0 });

    setQueryObj({ ...queryObj, [queryKey]: '' });
    setPageObj({ ...pageObj, [pageKey]: 1 });
  };

  return (
    <>
      {isFristTab && (
        <Row className="align-items-end px-0">
          <Col md={{ offset: 8, span: 4 }}>
            <Tabs
              groupClass="btn-group-statistic float-right"
              options={tabs}
              tabActive={query.status}
            />
          </Col>
        </Row>
      )}
      {isSecondTab && (
        <Row className="align-items-end px-0">
          <Col md="3" className="px-1 pe-3">
            <Search
              label="コース"
              labelMd="12"
              colMd="12"
              placeholder="検索"
              showIcon
              classLabel="px-0"
              rowClass="w-100"
              colClass="px-0"
              searchKey="queryCourse"
              onClickResult={handleClickItem}
              options={courseOptions.options || []}
              loading={loadingCourse}
              onLoadMore={() => handleLoadMore('pageCourse')}
              onFocusInput={() => {
                handleFocusInput('queryCourse', 'pageCourse');
              }}
              onEnter={() => handleEnter('queryCourse')}
            />
          </Col>
          <Col md="9" className="px-1 ps-3 d-flex justify-content-end">
            <Tabs groupClass="btn-group-statistic mx-4" options={tabs} tabActive={query.status} />
            <Button
              className={`btn-focus-none px-3 prize-btn-excel float-right ${
                loadingExcel ? 'pe-none' : ''
              }`}
              onClick={onExportExcel}
            >
              {loadingExcel ? (
                <Spinner
                  animation="border"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                'Excelダウンロード'
              )}
            </Button>
          </Col>
        </Row>
      )}
      {isThirdTab && (
        <div className="align-items-end px-0 d-flex flex-row justify-content-between w-100">
          <Row className="px-0 w-30">
            <Col md="6" className="px-1 pe-3">
              <Search
                label="コース"
                labelMd="12"
                colMd="12"
                placeholder="検索"
                showIcon
                classLabel="px-0"
                rowClass="w-100"
                colClass="px-0"
                searchKey="queryCourse"
                onClickResult={handleClickItem}
                options={courseOptions.options || []}
                loading={loadingCourse}
                onLoadMore={() => handleLoadMore('pageCourse')}
                onFocusInput={() => {
                  handleFocusInput('queryCourse', 'pageCourse');
                }}
                onEnter={() => handleEnter('queryCourse')}
              />
            </Col>
            <Col md="6" className="px-1 pe-3">
              <Search
                label="賞"
                labelMd="12"
                colMd="12"
                placeholder="検索"
                showIcon
                classLabel="px-0"
                rowClass="w-100"
                colClass="px-0"
                searchKey="queryPrize"
                onClickResult={handleClickItem}
                options={prizeOptions.options || []}
                loading={loadingPrize}
                onLoadMore={() => handleLoadMore('pagePrize')}
                onFocusInput={() => {
                  handleFocusInput('queryPrize', 'pagePrize');
                }}
                onEnter={() => handleEnter('queryPrize')}
              />
            </Col>
          </Row>

          <div className=" d-flex justify-content-end">
            <Tabs groupClass="btn-group-statistic mx-4" options={tabs} tabActive={query.status} />
            <Button
              className={`btn-focus-none px-3  prize-btn-excel float-right ${
                loadingExcel ? 'pe-none' : ''
              }`}
              onClick={onExportExcel}
            >
              {loadingExcel ? (
                <Spinner
                  animation="border"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                'Excelダウンロード'
              )}
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
