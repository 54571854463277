import Filter from 'Components/Common/Filter/Filter';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { SortMethod } from 'Components/Common/Table/table';
import Tabs from 'Components/Common/Tabs/Tabs';
import { DATE_FORMAT_EXPORT_EXCEL_PROCESS_BOARD } from 'Constant/Date';
import { ProcessBoardApi } from 'Datasource/ProcessBoardApi';
import dayjs from 'dayjs';
import useErrorBlob from 'Hooks/useErrorBlob';
import React, { useCallback, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { exportExcel } from 'Utils/File';
import SearchProcessBoard from './Components/SearchProcessBoard';
import TableListProcessBoard from './Components/TableListProcessBoard';
import Time from './Components/Time';
import 'Static/Styles/processboard.scss';
import useMessage from 'Hooks/useMessage';
import { handleEndDate, handleStartDate } from 'Utils/DateTime';
import useTitlePage from 'Hooks/useTitlePage';

function ProcessBoard(): JSX.Element {
  const [query, setQuery] = useQueryParams({
    artistId: StringParam,
    titleId: StringParam,
    titleStatus: StringParam,
    fromDate: withDefault(StringParam, '', true),
    toDate: withDefault(StringParam, '', true),
    sort: StringParam,
    order: StringParam,
    progressVariationType: StringParam,
    campaignId: StringParam,
    saleDate: StringParam,
    artistName: StringParam,
    titleName: StringParam,
    campaignName: StringParam,
    saleAmount: StringParam,
    companyId: NumberParam,
    reload: withDefault(BooleanParam, false, true),
    queryArtist: StringParam,
    queryTitle: StringParam,
    queryCompany: StringParam,
    // checkReload: withDefault(BooleanParam, true, false),
  });

  const { openMessageError } = useMessage();
  const { openErrorBlob } = useErrorBlob();

  const { setMenuTitle } = useTitlePage();

  const btnGroupOptions = [
    { name: '販売開始タイトル一覧', value: '' },
    { name: 'DL開始情報一覧', value: 0 },
    { name: 'キャンペーン情報一覧', value: 1 },
  ];
  const [tabActive, setTabActive] = useState(btnGroupOptions[0].name);
  const [data, setData] = useState([]);
  const [queryTime, setQueryTime] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const [exporting, setExporting] = useState<boolean>(false);

  const onExportExcel = async () => {
    try {
      setExporting(true);
      if (tabActive === '販売開始タイトル一覧') {
        await exportExcel(
          ProcessBoardApi.ExportGetDataExcel({
            artistId: query.artistId,
            titleId: query.titleId,
            titleStatus: query.titleStatus,
            recordCompanyId: query.companyId,
            fromDate: query?.fromDate ? handleStartDate(query.fromDate) : '',
            toDate: query?.toDate ? handleEndDate(query.toDate) : '',
            sort: query.sort,
            order: query.order,
            progressVariationType: 'sale',
          }),
          `販売開始タイトル一覧_${dayjs(new Date()).format(
            DATE_FORMAT_EXPORT_EXCEL_PROCESS_BOARD,
          )}`,
        );
      }
      if (tabActive === 'DL開始情報一覧') {
        await exportExcel(
          ProcessBoardApi.ExportGetDataExcel({
            artistId: query.artistId,
            titleId: query.titleId,
            titleStatus: query.titleStatus,
            recordCompanyId: query.companyId,
            fromDate: query?.fromDate ? handleStartDate(query.fromDate) : '',
            toDate: query?.toDate ? handleEndDate(query.toDate) : '',
            sort: query.sort,
            order: query.order,
            progressVariationType: 'download',
          }),
          `DL開始情報一覧_${dayjs(new Date()).format(DATE_FORMAT_EXPORT_EXCEL_PROCESS_BOARD)}`,
        );
      }
      if (tabActive === 'キャンペーン情報一覧') {
        await exportExcel(
          ProcessBoardApi.ExportCampaignExcel({
            artistId: query.artistId,
            titleId: query.titleId,
            titleStatus: query.titleStatus,
            fromDate: query?.fromDate ? handleStartDate(query.fromDate) : '',
            toDate: query?.toDate ? handleEndDate(query.toDate) : '',
            sort: query.sort,
            order: query.order,
          }),
          `キャンペーン情報一覧_${dayjs(new Date()).format(
            DATE_FORMAT_EXPORT_EXCEL_PROCESS_BOARD,
          )}`,
        );
      }
    } catch (error: any) {
      openErrorBlob(error);
    } finally {
      setExporting(false);
    }
  };

  const handleChangeTabs = (tab: string) => {
    if (!tab) return;
    setTabActive(tab);
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('sort') || queryParams.has('order')) {
      queryParams.delete('sort');
      queryParams.delete('order');
      queryParams.has('order');
      history.replace({
        search: queryParams.toString(),
      });
    }
    setQuery({
      progressVariationType: tab,
    });
  };

  const handleManualSort = useCallback(
    (sortBy: SortMethod[]) => {
      if (sortBy.length > 0) {
        const sortOption = sortBy[0];
        setQuery({ sort: sortOption.id, order: sortOption.desc ? 'DESC' : 'ASC' });
      } else {
        setQuery({ sort: '', order: '' });
      }
    },
    [setQuery],
  );

  const getData = async (status: string) => {
    try {
      if (status === '販売開始タイトル一覧') {
        if (!query.sort) {
          const res = await ProcessBoardApi.getData({
            artistId: query.artistId,
            titleId: query.titleId,
            titleStatus: query.titleStatus,
            recordCompanyId: query.companyId,
            fromDate: query?.fromDate ? handleStartDate(query.fromDate) : '',
            toDate: query?.toDate ? handleEndDate(query.toDate) : '',
            progressVariationType: 'sale',
          });
          setData(res.data?.data?.items);
          setQueryTime(res?.data?.data?.queryTime);
        } else {
          const res = await ProcessBoardApi.getSortData({
            artistId: query.artistId,
            titleId: query.titleId,
            titleStatus: query.titleStatus,
            fromDate: query?.fromDate ? handleStartDate(query.fromDate) : '',
            toDate: query?.toDate ? handleEndDate(query.toDate) : '',
            sort: query.sort,
            order: query.order,
            progressVariationType: 'sale',
            recordCompanyId: query.companyId,
          });
          setData(res.data?.data?.items);
        }
      }
      if (status === 'DL開始情報一覧') {
        if (!query.sort) {
          const res = await ProcessBoardApi.getData({
            artistId: query.artistId,
            titleId: query.titleId,
            titleStatus: query.titleStatus,
            recordCompanyId: query.companyId,
            fromDate: query?.fromDate ? handleStartDate(query.fromDate) : '',
            toDate: query?.toDate ? handleEndDate(query.toDate) : '',
            progressVariationType: 'download',
          });
          setData(res.data?.data?.items);
          setQueryTime(res?.data?.data?.queryTime);
        } else {
          const res = await ProcessBoardApi.getSortData({
            artistId: query.artistId,
            titleId: query.titleId,
            titleStatus: query.titleStatus,
            fromDate: query?.fromDate ? handleStartDate(query.fromDate) : '',
            toDate: query?.toDate ? handleEndDate(query.toDate) : '',
            sort: query.sort,
            order: query.order,
            progressVariationType: 'download',
            recordCompanyId: query.companyId,
          });
          setData(res.data?.data?.items);
        }
      }
      if (status === 'キャンペーン情報一覧') {
        if (!query.sort) {
          const res = await ProcessBoardApi.getCampaignsData({
            artistId: query.artistId,
            titleId: query.titleId,
            titleStatus: query.titleStatus,
            fromDate: query?.fromDate ? handleStartDate(query.fromDate) : '',
            toDate: query?.toDate ? handleEndDate(query.toDate) : '',
            recordCompanyId: query?.companyId,
            progressVariationType: 'campaigns',
          });
          setData(res.data?.data?.items);
          setQueryTime(res?.data?.data?.queryTime);
        } else {
          const res = await ProcessBoardApi.getSortCampaignsData({
            artistId: query.artistId,
            titleId: query.titleId,
            titleStatus: query.titleStatus,
            fromDate: query?.fromDate ? handleStartDate(query.fromDate) : '',
            toDate: query?.toDate ? handleEndDate(query.toDate) : '',
            sort: query.sort,
            order: query.order,
            recordCompanyId: query?.companyId,
            progressVariationType: 'campaigns',
          });
          setData(res.data?.data?.items);
        }
      }
      setLoading(false);
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!query.sort) {
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.has('sort') || queryParams.has('order')) {
        queryParams.delete('sort');
        queryParams.delete('order');
        queryParams.has('order');
        history.replace({
          search: queryParams.toString(),
        });
      }
    }
    if (!query.artistId && query.queryArtist) {
      setData([]);
      return;
    }
    if (!query.titleId && query.queryTitle) {
      setData([]);
      return;
    }
    if (!query.companyId && query.queryCompany) {
      setData([]);
      return;
    }

    if (tabActive === '販売開始タイトル一覧') {
      setLoading(true);
      getData(tabActive);
      // setQuery({ reload: false });
    } else {
      setData([]);
    }
    if (tabActive === 'DL開始情報一覧') {
      setLoading(true);
      getData(tabActive);
      // setQuery({ reload: false });
    } else {
      setData([]);
    }
    if (tabActive === 'キャンペーン情報一覧') {
      setLoading(true);
      getData(tabActive);
      // setQuery({ reload: false });
    } else {
      setData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.reload, query.progressVariationType, query.sort, query.order]);

  useEffect(() => {
    setMenuTitle('進行ボード');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="group-item-content pt-3">
        <Tabs
          options={btnGroupOptions}
          disabledSetQuery={true}
          tabActive={tabActive}
          onChange={handleChangeTabs}
          groupClass="process-board-tab"
        />
      </div>
      <Filter filterClass="shadow-sm">
        <SpinnerComponent isLoading={loading} />
        <Row>
          <SearchProcessBoard onExportExcel={onExportExcel} loadingExcel={exporting} />
        </Row>
      </Filter>
      <div className="container-fluid">
        <div className="px-4 pt-3">
          {queryTime && <Time time={queryTime} />}
          <TableListProcessBoard data={data || []} tab={tabActive} onSort={handleManualSort} />
        </div>
      </div>
    </>
  );
}

export default ProcessBoard;
