import DropdownMenuEC from 'Components/Common/EC/DropdownMenuEC';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import { ECApis } from 'Datasource/EC';
import useMessage from 'Hooks/useMessage';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

import PurchaseDetailTable from './PurchaseDetailTable';
import PurchaseSummaryListTable from './PurchaseSummaryListTable';
import dayjs from 'dayjs';
import { formatPrice } from 'Utils/Numbers';
import { exportExcel } from 'Utils/File';
import useErrorBlob from 'Hooks/useErrorBlob';
import { StringParam, useQueryParams } from 'use-query-params';

const currentYear = dayjs().year();

const getCurrentMonth = () => {
  const currentMonth = dayjs().month() + 1;
  if (currentMonth < 10) {
    return `0${currentMonth}`;
  } else {
    return `${currentMonth}`;
  }
};
interface Options {
  yearMonth: string;
  disabled?: boolean;
}

export default function PurchaseSummaryList(): JSX.Element {
  const { openMessageError } = useMessage();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [purchaseSummaryData, setPurchaseSummaryData] = useState<any>();
  const [groupOptions, setGroupOptions] = useState<Array<Options>>([
    { yearMonth: `${currentYear}年${getCurrentMonth()}月` },
  ]);
  const { openErrorBlob } = useErrorBlob();
  const [activeOption, setActiveOption] = useState(groupOptions[0]?.yearMonth);

  const [query] = useQueryParams({
    page: StringParam,
    sort: StringParam,
    order: StringParam,
  });

  const fetchPurchaseSummaryDate = async () => {
    try {
      setIsLoading(true);
      const res = await ECApis.getPaymentSummaryDate();
      const { data } = res.data;
      const formatData = data.map((item: any) => {
        const normalDate = item.yearMonth.replace('年', '-').replace('月', '');
        const formatDate = dayjs(normalDate).format('YYYY年MM月');
        return { yearMonth: formatDate };
      });
      setGroupOptions(formatData);
    } catch (error) {
      openMessageError(error);
    } finally {
      // setIsLoading(false);
    }
  };

  const fetchPurchaseSummaryData = async () => {
    const date = activeOption.split('年');
    const year = parseInt(date[0], 10);
    const month = parseInt(date[1].substring(0, 2), 10);
    try {
      setIsLoading(true);
      const res = await ECApis.getPaymentMonthly(`${year}-${month}`, query?.order, query?.sort);
      const { data } = res.data;
      setPurchaseSummaryData(data);
    } catch (error) {
      openMessageError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickPrevMonth = () => {
    groupOptions.map((option, index) => {
      if (activeOption === option.yearMonth && index < groupOptions.length - 1) {
        setActiveOption(groupOptions[index + 1].yearMonth);
      }
    });
  };

  const handleClickNextMonth = () => {
    groupOptions.map((option, index) => {
      if (activeOption === option.yearMonth && index > 0) {
        setActiveOption(groupOptions[index - 1].yearMonth);
      }
    });
  };

  const handleDownloadZipFile = async () => {
    setIsLoading(true);
    const yearParam = activeOption.substring(0, 4);
    const monthParam = activeOption.substring(5, 7);
    try {
      await exportExcel(
        ECApis.paymentMonthyExportZipFile(`${yearParam}-${monthParam}`),
        `${yearParam.substring(2, 4)}${monthParam}_レコード会社請求サマリ`,
        'zip',
      );
    } catch (error) {
      openErrorBlob(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPurchaseSummaryDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchPurchaseSummaryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOption, query.order, query.sort]);

  return (
    <div>
      <Container fluid>
        <SpinnerComponent isLoading={isLoading} />
        <Row className="container-padding max-h-120px">
          <Col className="d-flex" sm>
            <div className="mr-23px lg-mr-0px">
              <div className="mb-4px fs-12 font-bold">対象月</div>
              <div className="purchase-summary-dropdown d-flex">
                <Button
                  onClick={handleClickPrevMonth}
                  variant="light"
                  className="purchase-summary-prev-btn"
                >
                  前月
                </Button>
                <DropdownMenuEC
                  options={groupOptions}
                  activeOption={activeOption}
                  onChange={setActiveOption}
                  classButton=""
                />
                <Button
                  onClick={handleClickNextMonth}
                  variant="light"
                  className="purchase-summary-next-btn"
                >
                  次月
                </Button>
              </div>
            </div>

            <div className="min-w-161px d-inline-table mr-30px lg-mr-0px text-center">
              <div className="fs-12 font-bold">{activeOption} 請求総額</div>
              <div className="fs-26 font-bold d-inline border-bottom-2px-ec pl-12px pr-12px">
                {purchaseSummaryData?.totalRevenueIncludesTax
                  ? formatPrice(purchaseSummaryData.totalRevenueIncludesTax)
                  : 0}
              </div>
            </div>

            <div className="pt-18px mr-30px lg-mr-40px">
              <PurchaseDetailTable
                totalCurPrice={purchaseSummaryData?.totalAmountOptionFeeTotal}
                totalLicensePrice={purchaseSummaryData?.totalFeeTax}
                totalPrevPrice={purchaseSummaryData?.totalAmountTaxFeeMoneyTotal}
                totalTotalPrice={purchaseSummaryData?.totalRevenueIncludesTax}
                totalTotalPriceAfterApplyBuyingRate={
                  purchaseSummaryData?.totalAmountCardBuyFeeTotal
                }
              />
            </div>

            <div className="pt-20px">
              <Button
                onClick={() => handleDownloadZipFile()}
                className="lg-pl-8px lg-pr-8px lg-w-max-content"
                disabled={!purchaseSummaryData?.canDownload}
              >
                一括ダウンロード
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <PurchaseSummaryListTable
            data={purchaseSummaryData?.results}
            canDownload={purchaseSummaryData?.canDownload}
          />
        </Row>
      </Container>
    </div>
  );
}
