import MessageError from 'Components/Common/MessageError';
import React, { PropsWithChildren } from 'react';
import { Col, Row } from 'react-bootstrap';

interface FormTextGroupProps {
  label?: string;
  value?: any;
  className?: string;
  mdLable?: number;
  mdValue?: number;
  classNameVal?: string;
  errorContent?: any;
  onClickError?: () => void;
  labelClass?: string;
}

export default function GroupTextProps({
  label,
  value,
  className,
  classNameVal,
  mdLable = 3,
  mdValue = 7,
  errorContent,
  onClickError,
  labelClass,
}: PropsWithChildren<FormTextGroupProps>): JSX.Element {
  return (
    <Row className={`mt-2 lg-mt-4px ${className}`}>
      <Col md={mdLable} className={`${labelClass ? labelClass : ''}`}>
        {label}
      </Col>
      <Col md={mdValue} className={classNameVal}>
        {value || <p className="text-cherry d-flex align-items-center">【登録されていません】</p>}
        <div onClick={onClickError} onKeyDown={onClickError}>
          <MessageError message={errorContent?.message} />
        </div>
      </Col>
    </Row>
  );
}
