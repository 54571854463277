import React from 'react';
import { CKEditor } from 'ckeditor4-react';

const baseUrl = process.env.REACT_APP_BASE_URL;
// const s3Url = process.env.REACT_APP_STATIC_URL;

export default function ECCkeditor({
  name,
  initData,
  onEditorChange,
  disabled,
}: {
  name?: string;
  initData?: any;
  disabled?: boolean;
  onEditorChange?: (event: any) => void;
}): JSX.Element {
  const config = {
    toolbar: [
      {
        name: 'document',
        items: ['Source', '-'],
      },
      {
        name: 'clipboard',
        items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'],
      },
      { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
      {
        name: 'insert',
        items: [
          'Image',
          // 'base64image',
          'Iframe',
          'Youtube',
          'Flash',
          'Table',
          'HorizontalRule',
          'SpecialChar',
        ],
      },
      '/',
      {
        name: 'paragraph',
        items: [
          'NumberedList',
          'BulletedList',
          '-',
          'Outdent',
          'Indent',
          '-',
          'Blockquote',
          'CreateDiv',
          '-',
          'JustifyLeft',
          'JustifyCenter',
          'JustifyRight',
          'JustifyBlock',
        ],
      },
      '/',
      { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize'] },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
      {
        name: 'basicstyles',
        items: [
          'Bold',
          'Italic',
          'Underline',
          'Strike',
          'Subscript',
          'Superscript',
          '-',
          'CopyFormatting',
          'RemoveFormat',
        ],
      },
      { name: 'tools', items: ['Maximize'] },
    ],
    readOnly: disabled,
    fontSize_sizes: '16/16px;24/24px;48/48px;',
    language: 'ja',
    font_names:
      'Arial/Arial, Helvetica, sans-serif;' +
      'Times New Roman/Times New Roman, Times, serif;' +
      'Verdana',
    allowedContent: true,
    removePlugins: 'elementspath',
    // disableNativeSpellChecker: false
    // skin: "moono",
    extraPlugins: 'youtube,justify,div,font,colorbutton,a11yhelp,iframe,basicstyles,base64image',
    //   plugins:
    //     'dialogui,dialog,about,a11yhelp,dialogadvtab,basicstyles,bidi,blockquote,notification,button,toolbar,clipboard,panelbutton,panel,floatpanel,colorbutton,colordialog,templates,menu,contextmenu,copyformatting,div,resize,elementspath,enterkey,entities,popup,filetools,filebrowser,find,fakeobjects,flash,floatingspace,listblock,richcombo,font,forms,format,horizontalrule,htmlwriter,iframe,wysiwygarea,image,indent,indentblock,indentlist,smiley,justify,menubutton,language,link,list,liststyle,magicline,maximize,newpage,pagebreak,pastetext,pastefromword,preview,print,removeformat,save,selectall,showblocks,showborders,sourcearea,specialchar,scayt,stylescombo,tab,table,tabletools,tableselection,undo,lineutils,widgetselection,widget,notificationaggregator,uploadwidget,uploadimage',
    filebrowserImageUploadUrl: `${baseUrl}ec-site/settings/terms-service/upload`,
  };
  return (
    <CKEditor
      name={name}
      config={config}
      style={{ width: '100%' }}
      onChange={onEditorChange}
      initData={initData}
      onBeforeLoad={(CKEDITOR) => {
        CKEDITOR.plugins.addExternal('youtube', '/ckeditor-plugin/youtube/', 'plugin.js');
        CKEDITOR.plugins.addExternal('justify', '/ckeditor-plugin/justify/', 'plugin.js');
        CKEDITOR.plugins.addExternal('div', '/ckeditor-plugin/div/', 'plugin.js');
        CKEDITOR.plugins.addExternal('font', '/ckeditor-plugin/font/', 'plugin.js');
        CKEDITOR.plugins.addExternal('colorbutton', '/ckeditor-plugin/colorbutton/', 'plugin.js');
        CKEDITOR.plugins.addExternal('a11yhelp', '/ckeditor-plugin/a11yhelp/', 'plugin.js');
        CKEDITOR.plugins.addExternal('iframe', '/ckeditor-plugin/iframe/', 'plugin.js');
        CKEDITOR.plugins.addExternal('basicstyles', '/ckeditor-plugin/basicstyles/', 'plugin.js');
        CKEDITOR.plugins.addExternal('base64image', '/ckeditor-plugin/base64image/', 'plugin.js');
        CKEDITOR.addCss('.cke_editable p { margin-block-start: 0em; margin-block-end: 0em;}');
      }}
    />
  );
}
