import React, { useState, useEffect } from 'react';
import BoxForm from 'Components/Common/Form/BoxForm';
import SearchSampleMasterDp from 'Page/ListProducts/SearchSampleMasterDp';
import { Col, Row } from 'react-bootstrap';
import TableSampleMasterDp from 'Components/Page/ListProducts/TableSampleMasterDp';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import useMessage from 'Hooks/useMessage';
import { useQueryParams, StringParam } from 'use-query-params';
import { SampleMasterApi } from 'Datasource/SampleMasterApi';

interface ISampleMaster {
  deleted: DeleteType;
  email?: string;
  id: number | string;
  miimID: string;
  realName: string;
}

export enum DeleteType {
  NOT_DELETE = 0,
  DELETED = 1,
}

export default function SampleMasterDp(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataUserReleased, setUserReleased] = useState<ISampleMaster[]>([]);

  const [query] = useQueryParams({ variationId: StringParam });
  const { openMessageError } = useMessage();

  const fetchDataReleased = async () => {
    if (!query.variationId) {
      setUserReleased([]);
      return;
    }
    try {
      setLoading(true);
      const {
        data: { data },
      } = await SampleMasterApi.getListUserReleasedByVaritionId({
        variationId: query.variationId,
      });
      setUserReleased(data);
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataReleased();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.variationId]);

  return (
    <>
      <SpinnerComponent isLoading={loading} />
      <BoxForm title="" classBox="sample-master-box">
        <Col md="10">
          <SearchSampleMasterDp />
          <Row>
            <Col md="12">
              <p className="pt-3 pb-2 underline-100 position-relative">配布済みユーザ</p>
              <TableSampleMasterDp dataSource={dataUserReleased || []} />
            </Col>
          </Row>
        </Col>
      </BoxForm>
    </>
  );
}
