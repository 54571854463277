import CardWrapper from 'Components/Common/Card/Card';
import SpinnerComponent from 'Components/Common/SpinnerComponent';
import BlackListUsersTable from 'Components/Page/Campaign/Detail/BlackListUsersTable';
import PrizesTable from 'Components/Page/Campaign/Detail/PrizesTable';
import TableCampaignView from 'Components/Page/Campaign/Detail/TableCampaignView';
import TableWinningConditions from 'Components/Page/Campaign/Detail/TableWinningConditions';
import ViewOnlyField from 'Components/Page/Campaign/Detail/ViewOnlyField';
import {
  attachedBonus,
  awardSettings,
  CourseTypes,
  distributionSettingOptions,
  methodOptions,
  subTypeOptions,
} from 'Constant/Campaign';
import { DATE_FORMAT_13 } from 'Constant/Date';
import { ProductsApi } from 'Datasource/ProductsApi';
import useMessage from 'Hooks/useMessage';
import { find } from 'lodash';
import { CourseConditionsOnlyView } from 'Page/Campaign/ConditionRow';
import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { CourseCampaign } from 'Types/Page/Campaign/RegisterCampaign';
import { StringParam, useQueryParams } from 'use-query-params';
import { formatDate } from 'Utils/DateTime';
import { formatCurrency } from 'Utils/Numbers';

const FIELD_SIZE = {
  label: 2,
  value: 10,
};

const formSize = {
  labelSize: FIELD_SIZE.label,
  valueSize: FIELD_SIZE.value,
};

export default function CampaignInformation(): JSX.Element {
  const [loading, setLoading] = useState<any>(false);
  const [data, setData] = useState<any>([]);
  const [indexShow, setIndexShow] = useState<any>([]);
  const { openMessageError } = useMessage();

  const [query] = useQueryParams({
    queryArtist: StringParam,
    queryTitle: StringParam,
    artistIds: StringParam,
    titleIds: StringParam,
  });

  const handleGetCampaign = async (id: any) => {
    const queryArr = Object.values(query);
    const arrLookupNoData = queryArr.filter((q: any) => {
      return !!q && q === 'no-data';
    });

    if (arrLookupNoData.length > 0) {
      setData([]);
      return;
    }
    try {
      setLoading(true);
      const {
        data: { data },
      } = await ProductsApi.getCampaignTitle({ titleId: id });
      setData(data);
    } catch (error) {
      openMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    query?.titleIds && handleGetCampaign(query.titleIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.titleIds]);

  const isIndexShow = (key: string) => {
    return indexShow.includes(key);
  };

  const removeItem = (key: string) => {
    const tmpIndex = indexShow.filter((item: string) => item != key);
    setIndexShow(tmpIndex);
  };

  const renderCourseType = (course: CourseCampaign) => {
    const courseTypeName =
      find(methodOptions, (option) => option.value === course.type)?.label ?? '';
    const courseSubTypeName =
      find(subTypeOptions, (option) => option.value === course.subType)?.label ?? '';
    return course?.type === CourseTypes.REALTIME
      ? `${courseTypeName} - ${courseSubTypeName}`
      : courseTypeName;
  };

  return (
    <div>
      <SpinnerComponent isLoading={loading} />
      {data.map((el: any, key: number) => {
        const overview = el;
        const { courses } = el;
        return (
          <div key={key}>
            <>
              <CardWrapper
                eventKey={`${key}`}
                onCollapse={(key) =>
                  !isIndexShow(key) ? setIndexShow([...indexShow, key]) : removeItem(key)
                }
                activated={isIndexShow(`${key}`)}
                title={overview?.name || '-'}
                className="mb-4"
              >
                <ViewOnlyField
                  {...formSize}
                  label="対象選択"
                  value={`${overview?.artistName ?? '-'}  ${overview?.titleName ?? '-'}`}
                />
                <ViewOnlyField
                  {...formSize}
                  label="キャンペーン名称"
                  value={overview?.name}
                  isMessageInside
                />
                <ViewOnlyField
                  {...formSize}
                  label="販売期間"
                  value={
                    overview?.startDate &&
                    `${formatDate(overview?.startDate, DATE_FORMAT_13)} ~ ${formatDate(
                      overview?.endDate,
                      DATE_FORMAT_13,
                    )}`
                  }
                  isMessageInside
                />
                <ViewOnlyField
                  {...formSize}
                  label="キャンペーン概要"
                  value={overview?.description || '-'}
                  isMessageInside
                  prevLine
                />
              </CardWrapper>
              {isIndexShow(`${key}`) &&
                courses?.map((course: any, courseIndex: number) => {
                  return (
                    <CardWrapper
                      title={`応募コース設定内容 (${courseIndex + 1}/${courses.length})`}
                      className="mb-3 view-only"
                      key={courseIndex}
                    >
                      <div className="mb-5">
                        <ViewOnlyField
                          {...formSize}
                          label="応募コース名称"
                          value={course?.name}
                          isMessageInside
                        />
                        <ViewOnlyField
                          {...formSize}
                          label="応募方法"
                          value={
                            find(
                              distributionSettingOptions,
                              (option) => option.value === course.option,
                            )?.label ?? ''
                          }
                          isMessageInside
                        />
                        <ViewOnlyField
                          {...formSize}
                          label="抽選方法"
                          value={renderCourseType(course)}
                          isMessageInside
                        />

                        <ViewOnlyField
                          {...formSize}
                          label="抽選日時"
                          value={formatDate(course?.standardTime, DATE_FORMAT_13)}
                          isMessageInside
                        />
                        <ViewOnlyField
                          {...formSize}
                          label="応募期間"
                          value={
                            course?.timeJoinStart
                              ? `${formatDate(
                                  course?.timeJoinStart,
                                  DATE_FORMAT_13,
                                )} ~ ${formatDate(course?.timeJoinEnd, DATE_FORMAT_13)}`
                              : '-'
                          }
                          isMessageInside
                        />
                        {course.type === CourseTypes.ALL && (
                          <ViewOnlyField
                            {...formSize}
                            label="景品付与設定"
                            value={
                              find(attachedBonus, (option) => option.value === course.receiveType)
                                ?.label ?? ''
                            }
                            isMessageInside
                          />
                        )}
                        {course.type === CourseTypes.REALTIME && (
                          <>
                            <ViewOnlyField
                              {...formSize}
                              label="初日の応募予想数"
                              value={course?.countJoinStart ?? '-'}
                              isMessageInside
                            />
                            <ViewOnlyField
                              {...formSize}
                              label="最終日の応募予想数"
                              value={course?.countJoinEnd ?? '-'}
                              isMessageInside
                            />
                          </>
                        )}
                        <ViewOnlyField
                          {...formSize}
                          label="賞の指定可否"
                          value={
                            find(awardSettings, (option) => option.value === course.prizeOption)
                              ?.label ?? ''
                          }
                          isMessageInside
                        />
                        <ViewOnlyField
                          {...formSize}
                          label="応募一口金額"
                          value={`${
                            course?.ticketPrice &&
                            course.option === distributionSettingOptions[1].value
                              ? `¥ ${formatCurrency(course?.ticketPrice)}`
                              : '-'
                          }`}
                          isMessageInside
                        />
                        <ViewOnlyField
                          {...formSize}
                          label="販売数上限"
                          value={`${
                            course.subType === subTypeOptions[1].value
                              ? '上限なし'
                              : course?.prizeQuantity && course.subType === subTypeOptions[0].value
                              ? course?.prizeQuantity
                              : '-'
                          }`}
                          isMessageInside
                        />
                      </div>
                      <TableCampaignView
                        dataSource={course?.courseInfos}
                        nestIndex={courseIndex}
                        isShowConditions={course?.option === distributionSettingOptions[2].value}
                        titleName={overview?.titleName}
                      />
                      {course?.option === distributionSettingOptions[2].value && (
                        <Row className="mb-3">
                          <div>応募権利取得条件</div>
                          <div className="mt-2 ps-3 fs-12">
                            <p>条件1～条件4をすべて満たしている必要があります。</p>
                            <CourseConditionsOnlyView info={course?.courseInfos} />
                          </div>
                        </Row>
                      )}
                      <PrizesTable
                        dataSource={course?.prizes}
                        titleName={overview?.titleName}
                        showWinRate={course?.type === 2 && [0, 1].includes(course?.subType)}
                      />
                      <TableWinningConditions
                        prizes={course.prizes}
                        courseIndex={courseIndex}
                        isReview
                      />
                      <BlackListUsersTable dataSource={course?.blackListUsers ?? []} />
                    </CardWrapper>
                  );
                })}
            </>
          </div>
        );
      })}
    </div>
  );
}
