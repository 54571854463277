import React, { PropsWithChildren } from 'react';
import { Modal, Button } from 'react-bootstrap';

interface Props {
  onCloseModal: () => void;
  show: boolean;
  classNameContent?: string;
  submitIdForm?: string;
  variantButtonSubmit?: string;
  onSubmitModal?: () => void;
  dialogClassName?: string;
  fullscreen?: true | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down';
  footerClassName?: string;
  cancelText?: string;
  confirmText?: string;
  isReviewModal?: boolean;
  title?: string;
  showCancelButton?: boolean;
}

function ModalComponent(props: PropsWithChildren<Props>): JSX.Element {
  const {
    children,
    onCloseModal,
    show,
    classNameContent,
    submitIdForm,
    variantButtonSubmit,
    onSubmitModal,
    dialogClassName,
    fullscreen,
    footerClassName,
    cancelText,
    confirmText,
    isReviewModal = false,
    title = '登録確認',
    showCancelButton = true,
  } = props;
  return (
    <Modal
      dialogClassName={`${!fullscreen ? 'main-wrap-modal' : ''} ${dialogClassName ?? ''}`}
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      fullscreen={fullscreen}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <p className="title-header mb-0">{title}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={classNameContent}>{children}</Modal.Body>
      <Modal.Footer className={`d-flex ${footerClassName ?? 'justify-content-around'}`}>
        {showCancelButton && (
          <Button
            className={`${isReviewModal ? 'btn' : 'button-submit'}`}
            variant="secondary"
            type="button"
            onClick={onCloseModal}
          >
            {isReviewModal ? '×' : cancelText ?? 'キャンセル'}
          </Button>
        )}
        {!isReviewModal && (
          <Button
            className="button-submit"
            variant={variantButtonSubmit ?? 'primary'}
            type="submit"
            onClick={onSubmitModal}
            form={submitIdForm}
          >
            {confirmText ?? '登録する'}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ModalComponent;
