import { setTitlePage } from 'App/Features/Layout';
import { useAppDispatch } from 'App/Store';

export default function useTitlePage() {
  const dispatch = useAppDispatch();
  const setMenuTitle = (title: string) => {
    dispatch(setTitlePage(title));
  };

  return {
    setMenuTitle,
  };
}
